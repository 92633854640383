import Api from '../Api';

class UserRolesApi extends Api {
  constructor() {
    super('users/roles', 'userroles');
  }
}

const userRolesApi = new UserRolesApi();
export default userRolesApi;
