<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col
          class="ma-2 mb-0 pb-0"
          style="display: grid; align-content: center;"
        >
          <div
            style="display: grid; align-content: center;"
          >
            <span
              class="font-weight-bold text-h5"
              style="margin-bottom: 5px;"
            >{{ title }}</span>
          </div>
        </v-col>
      </v-row>
      <v-row
        v-for="program in charity.charityPrograms"
        :key="program.id"
        class="ml-1"
      >
        <v-col
          v-if="program.name"
          cols="1"
        >
          <font-awesome-icon
            class="mt-3"
            size="2xl"
            :icon="['fabs', 'layer-group']"
          />
        </v-col>
        <v-col
          clos="9"
        >
          <!-- <div class="white widget">
            <general-text-widget
              :title=" program.name"
              :html-text="program.details"
            /> -->
          <v-row>
            <v-col
              cols="12"
              class="pb-0"
            >
              <span
                class="font-weight-bold dark-grey"
              >
                {{ program.name }}
              </span>
            </v-col>
            <v-col cols="12">
              <div
                style="line-height: 150%;"
                v-html="program.details"
              /><br>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';
// import GeneralTextWidget from '@/components/general/GeneralTextWidget.vue';

export default {
  components: {
    // GeneralTextWidget,
  },

  props:
  {
    charity: {
      type: Object,
      default: () => {},
    },
    editing: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    charitySubmit: null,
    title: 'Programs',
  }),
  computed: {
    ...mapState({
      storeCharity: (state) => state.charity.charity,
    }),
  },
  watch: {
    charity: {
      immediate: true,
      handler(value) {
        if (!value && !this.editable) return;
        this.charitySubmit = value;
      },
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
    },
  },
};
</script>
<style>
  .profile-picture-button{
    background-color: rgb(0,0,0,0.2) !important;
  }
  button.profile-picture-button-hover span i.v-icon.notranslate.mdi {
    width: 100px !important;
    height: 101px !important;
    font-size: 70px;
    background-color: transparent;
    color: rgb(0,0,0,0.5);
  }
  button.profile-picture-button span i.v-icon.notranslate.mdi {
    background-color: transparent;
    color: transparent;
  }
  .dark-grey {
    color: rgb(0,0,0,0.6)
  }
</style>
