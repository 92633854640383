var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0 mx-0 pa-12 pb-2",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-tabs',{staticClass:"hahaha",attrs:{"center-active":""},model:{value:(_vm.isDisabledTab),callback:function ($$v) {_vm.isDisabledTab=$$v},expression:"isDisabledTab"}},[_c('v-tab',[_vm._v("Active")]),_c('v-tab',[_vm._v("Inactive")])],1)],1),_c('v-col',{staticClass:"text-end"},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.openNewPositionDialog()}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" mdi-plus ")]),_vm._v(" New Position ")],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.dataHeaders,"items":_vm.positions,"footer-props":_vm.dataPerPageProps,"items-per-page":_vm.dataPerPage,"loading":_vm.loading,"dense":"","option":_vm.options},on:{"update:options":_vm.updateOptions,"pagination":_vm.updatePagination},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.isNotBoard",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":_vm.chipColor(item.isNotBoard),"text-color":"white"}},[_vm._v(" "+_vm._s(item.isNotBoard ? "NOT BOARD MEMBER" : "BOARD MEMBER")+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!item.isDefault)?_c('a',{staticClass:"mr-2",on:{"click":function($event){return _vm.openUpdateForm(item)}}},[_vm._v(" EDIT ")]):_vm._e(),(_vm.isDisabledTab)?_c('a',{staticClass:"mr-2",on:{"click":function($event){return _vm.deactivate(item)}}},[_vm._v(" ACTIVATE ")]):_c('a',{staticClass:"mr-2",on:{"click":function($event){return _vm.deactivate(item)}}},[_vm._v(" DEACTIVATE ")]),_c('a',{staticClass:"mr-2",on:{"click":function($event){return _vm.remove(item)}}},[_vm._v(" DELETE ")])]}}],null,true)})],1)],1),_c('v-slide-y-transition',[_c('v-container',{directives:[{name:"show",rawName:"v-show",value:(_vm.showForm),expression:"showForm"}]},[_c('zigzag'),_c('v-row',[_c('v-col',[_c('span',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.formTitle)+" ")])])],1),_c('validation-observer',{ref:"positionValObserver"},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"Position Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"required":"","error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Position Name"),_c('red-asterisk')]},proxy:true}],null,true),model:{value:(_vm.position.name),callback:function ($$v) {_vm.$set(_vm.position, "name", $$v)},expression:"position.name"}})]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"Is Board","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"label":"This is not a Board Position","error-messages":errors},model:{value:(_vm.position.isNotBoard),callback:function ($$v) {_vm.$set(_vm.position, "isNotBoard", $$v)},expression:"position.isNotBoard"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{staticClass:"text-end"},[_c('v-btn',{staticClass:"mr-4",attrs:{"text":""},on:{"click":_vm.closeForm}},[_vm._v(" cancel ")]),_c('v-btn',{staticClass:"mr-4",attrs:{"type":"submit","color":"success"},on:{"click":_vm.submit}},[_vm._v(" submit ")])],1)],1)],1)],1)],1),_c('general-dialog',{ref:"newPositionDialog",attrs:{"title":"New Position","dialog-width":"500px"},on:{"submit":_vm.submitNewPosition}},[_c('charity-new-position-form',{ref:"newPositionForm",attrs:{"charity":_vm.charity},on:{"closeNewPositionDialog":_vm.closeNewPositionDialog}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }