var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[(_vm.title)?_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_c('v-col',[_c('v-data-table',{staticClass:"education-table",staticStyle:{"width":"100%"},attrs:{"headers":_vm.headers,"items":_vm.courseEnrollments,"footer-props":_vm.footerProps,"items-per-page":_vm.itemsPerPage,"loading":_vm.loading,"server-items-length":_vm.total,"dense":"","option":_vm.options},on:{"pagination":_vm.updatePagination,"update:options":_vm.updateOptions},scopedSlots:_vm._u([{key:"item.courseName",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{staticClass:"font-weight-bold",attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.course_name)+" ")]),(_vm.requiredCourseIds.findIndex(function (x) { return x == item.course_id; }) > -1)?[_c('v-chip',{staticClass:"ml-1",staticStyle:{"margin-top":"-2px"},attrs:{"color":"error","small":""}},[_vm._v(" Required ")])]:_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item != null)?_c('v-chip',{attrs:{"small":"","color":_vm.setStatus(item).color,"text-color":"white"}},[_vm._v(" "+_vm._s(_vm.setStatus(item).name)+" ")]):_vm._e()]}},{key:"item.percentageCompleted",fn:function(ref){
var item = ref.item;
return [_c('v-progress-linear',{attrs:{"value":item.percentage_completed * 100,"height":"25","color":"success"}},[_c('strong',[_vm._v(_vm._s(Math.round(item.percentage_completed * 100))+"%")])])]}},{key:"item.startedDate",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.started_at ? _vm.moment(item.started_at).format("YYYY-MMM-DD") : "-")+" ")])]}},{key:"item.completedDate",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.completed_at ? _vm.moment(item.completed_at).format("YYYY-MMM-DD") : "-")+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_vm._t("default",null,{"item":item})]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }