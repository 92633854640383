<template>
  <v-container fluid>
    <validation-observer ref="positionValObserver">
      <v-row>
        <v-col cols="3">
          <validation-provider
            v-slot="{ errors }"
            name="Board Position"
            rules="required"
          >
            <v-select
              v-model="positionData.charityPositionId"
              :items="charityPositionTypes"
              :error-messages="errors"
              label="Position"
              data-vv-name="select"
              :item-text="(item) => `${item.name}`"
              item-value="id"
            >
              <template #label>
                Board Position<red-asterisk />
              </template>
            </v-select>
          </validation-provider>
        </v-col>
        <v-col cols="1" />
        <v-col cols="3">
          <v-autocomplete
            v-model="positionData.charityRepresentative"
            :items="charityReps"
            label="Charity Representative"
            data-vv-name="select"
            :disabled="addNewRep"
            :item-text="item => item.user && item.user.userInformation ? item.user.userInformation.firstName +' '+ item.user.userInformation.lastName : '-'"
            return-object
          >
            <!-- <template #prepend-item>
              <v-list-item
                ripple
                style="cursor: pointer;"
                @mousedown.prevent
                @click="modalInviteMember()"
              >
                <v-list-item-content>
                  <v-list-item-title>Invite Board Member</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2" />
            </template> -->
          </v-autocomplete>
        </v-col>
        <v-col cols="3">
          <v-checkbox
            v-model="addNewRep"
            label="Add New Representative"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <span style="font-size: x-large; font-weight: 450;">Board Position Mandate</span>
        </v-col>
        <v-col
          cols="2"
          sm="2"
          xs="6"
        >
          <v-menu
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="positionData.startDate"
                label="Start Date"
                persistent-hint
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="positionData.startDate"
              no-title
            />
          </v-menu>
        </v-col>
        <v-col cols="1" />
        <v-col
          cols="2"
          sm="2"
          xs="6"
        >
          <v-menu
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="positionData.endDate"
                label="End Date"
                persistent-hint
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="positionData.endDate"
              no-title
            />
          </v-menu>
        </v-col>
      </v-row>
      <v-row v-if="addNewRep">
        <v-col cols="12">
          <zigzag class="mb-4" />
        </v-col>
        <v-col
          cols="12"
        >
          <span style="font-size: x-large; font-weight: 450;">New Charity Representative</span>
        </v-col>
        <v-col
          cols="3"
          sm="3"
          xs="6"
        >
          <validation-provider
            v-slot="{ errors }"
            name="First Name"
            rules="required"
          >
            <v-text-field
              v-model="inviteeData.firstName"
              required
              :error-messages="errors"
            >
              <template #label>
                First Name<red-asterisk />
              </template>
            </v-text-field>
          </validation-provider>
        </v-col>
        <v-col
          cols="3"
          sm="3"
          xs="6"
        >
          <validation-provider
            v-slot="{ errors }"
            name="Last Name"
            rules="required"
          >
            <v-text-field
              v-model="inviteeData.lastName"
              :error-messages="errors"
              required
            >
              <template #label>
                Last Name<red-asterisk />
              </template>
            </v-text-field>
          </validation-provider>
        </v-col>
        <v-col
          cols="3"
          sm="3"
          xs="6"
        >
          <validation-provider
            v-slot="{ errors }"
            name="Email"
            :rules="'required|email'"
          >
            <v-text-field
              v-model="inviteeData.email"
              :error-messages="errors"
              required
            >
              <template #label>
                Email<red-asterisk />
              </template>
            </v-text-field>
          </validation-provider>
        </v-col>
      </v-row>

      <v-row v-if="positionData.charityRepresentative" />
    </validation-observer>
    <!--<validation-observer ref="inviteValObserver">
      <general-dialog
        ref="inviteMember"
        :title="`Invite Board Member`"
        dialog-width="1100"
        dialog-height="250"
        :submit-button-enabled="true"
        :suppress-footer="false"
        @submit="submitInvitation"
      >
        <v-row>
          <v-col cols="4">
            <validation-provider
              v-slot="{ errors }"
              name="First Name"
              rules="required"
            >
              <v-text-field
                v-model="inviteeData.firstName"
                required
                :error-messages="errors"
              >
                <template #label>
                  First Name<red-asterisk />
                </template>
              </v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="4">
            <validation-provider
              v-slot="{ errors }"
              name="Last Name"
              rules="required"
            >
              <v-text-field
                v-model="inviteeData.lastName"
                :error-messages="errors"
                required
              >
                <template #label>
                  Last Name<red-asterisk />
                </template>
              </v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="4">
            <validation-provider
              v-slot="{ errors }"
              name="Email"
              :rules="'required|email'"
            >
              <v-text-field
                v-model="inviteeData.email"
                :error-messages="errors"
                required
              >
                <template #label>
                  Email<red-asterisk />
                </template>
              </v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
      </general-dialog>
    </validation-observer>-->
  </v-container>
</template>

<script>

import { required } from 'vee-validate/dist/rules';

import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from 'vee-validate';
import { mapState, mapGetters } from 'vuex';
import { createPaginationObject } from '@/helpers/PaginationHelper.js';
// import GeneralDialog from '@/components/general/GeneralDialog';

setInteractionMode('eager');

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
});

export default {
  components:
    {
      ValidationProvider,
      ValidationObserver,
      // GeneralDialog,
    },
  props:
  {
    position: {
      type: Object,
      default: () => {},
    },
    charity: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    positionData: null,
    temporarySelectedRepresentative: null,
    inviteeData: {
      charityId: null,
      firstName: '',
      lastName: '',
      email: '',
      charityRepresentativeRoleId: 5,
    },
    charityReps: [],
    charityPositionTypes: [],
    addNewRep: false,
    isNotBoard: false,
  }),
  computed: {
    ...mapState({
    }),
    ...mapGetters({
      selectedInv: 'user/getSelectedInv',
      getUserInfo: 'user/getUserInfo',
    }),
    filters() {
      const returnFilters = [];

      if (this.charity && this.charity.id) {
        returnFilters.push({ name: 'charityId', values: [this.charity.id.toString()] });
      }

      // if (!this.isNotBoard) {
      returnFilters.push({ name: 'boardPosition', values: [this.isNotBoard] });
      // }
      const isDeactivated = 0;
      returnFilters.push({ name: 'isDisabled', values: [isDeactivated] });
      return returnFilters;
    },
  },
  watch: {
    position: {
      immediate: true,
      handler(value) {
        this.positionData = JSON.parse(JSON.stringify(value))
          || {
            id: 0,
            charityId: 0,
            charityRepresentativeId: null,
            charityRepresentative: null,
            charityPositionId: null,
            startDate: '',
            endDate: '',
          };
        this.positionData.startDate = this.formatDate(this.positionData.startDate);
        this.positionData.endDate = this.formatDate(this.positionData.endDate);
      },
    },
    charity: {
      handler() {
        this.getCharityReps();
      },
    },
    'positionData.charityRepresentative': function () {
      if (this.positionData.charityRepresentative === null) {
        this.positionData.startDate = '';
        this.positionData.endDate = '';
      }
    },
    addNewRep: {
      immediate: true,
      handler(value) {
        if (value && this.positionData.charityRepresentative) {
          this.temporarySelectedRepresentative = this.positionData.charityRepresentative;
          this.positionData.charityRepresentative = null;
        } else if (!value) {
          this.positionData.charityRepresentative = this.temporarySelectedRepresentative;
          this.temporarySelectedRepresentative = null;
        }
      },

    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getCharityReps();
      this.getCharityPositions();
      this.initializeData();
    },
    initializeData() {
      this.inviteeData.charityId = this.selectedInv.charityId;
    },
    async getCharityReps() {
      if (!this.charity) return;
      this.charityReps = await this.$api.charityRep.getCharityRepsByCharityId(this.charity.id);
    },

    async getCharityPositions() {
      if (!this.charity) return;
      const res = await this.$api.charityPositions.read(createPaginationObject(null, null, this.filters));
      this.charityPositionTypes = res.positions;
      // let parameters = [];
      // parameters.push({name: 'isDisabled', value: isDeactivated});
      // this.charityPositionTypes = await this.$api.charityPositions.get(`${this.charity.id}`, parameters)
    },

    async validate() {
      const res = await this.$refs.positionValObserver.validate();
      return res;
    },

    async submit() {
      if (!this.charity) return null;
      if (this.addNewRep) {
        const rep = await this.$api.charityRep.sendInvite(this.inviteeData);
        this.charityReps.push(rep);
        this.positionData.charityRepresentative = rep;
      }
      if (this.positionData.charityId === 0) this.positionData.charityId = this.charity.id;
      this.positionData.charityRepresentativeId = this.positionData.charityRepresentative ? this.positionData.charityRepresentative.id : null;
      const res = this.positionData.id !== 0 ? await this.$api.charityMandate.update(this.positionData) : await this.$api.charityMandate.create(this.positionData);
      return res;
    },
    reset() {
      this.$refs.positionValObserver.reset();
      this.positionData = {
        id: 0,
        charityId: 0,
        charityRepresentativeId: null,
        charityRepresentative: null,
        charityPositionId: null,
        startDate: '',
        endDate: '',
      };
      this.inviteeData = {
        charityId: this.selectedInv.charityId,
        firstName: '',
        lastName: '',
        email: '',
      };
    },
    formatDate(date) {
      if (!date) return '';
      return this.$moment(date).format('YYYY-MM-DD');
    },
    toggleInviteForm() {

    },
    /* modalInviteMember() {
      if (this.addNewRep) {
        this.$refs.inviteMember.openForm();
       }
    }, */
    // async submitInvitation() {
    //   const valid = await this.$refs.inviteValObserver.validate();
    //   if (valid) {
    //     const rep = await this.$api.charityRep.sendInvite(this.inviteeData);
    //     this.charityReps.push(rep);
    //     this.positionData.charityRepresentative = rep;
    //     this.$refs.inviteMember.closeForm();
    //   } else {
    //     this.$bus.$emit('showError', 'You must fill all the required fields.');
    //   }
    // },
  },
};
</script>
