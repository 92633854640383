import Api from '../Api';

class CandidateQualificationsApi extends Api {
  constructor() {
    super('candidates/qualifications', 'candidatesQualifications');
  }

  async getEducationTypes() {
    const res = await this.get('education-types');
    return res;
  }

  async getExperienceContractTypes() {
    const res = await this.get('experience-contract-types');
    return res;
  }

  async getExperienceLocationTypes() {
    const res = await this.get('experience-location-types');
    return res;
  }
}

const candidateQualificationsApi = new CandidateQualificationsApi();
export default candidateQualificationsApi;
