<template>
  <v-row justify="center">
    <v-dialog
      v-model="charityDialog"
      fullscreen
      hide-overlay
    >
      <v-card fill-height>
        <v-container fluid>
          <v-row>
            <v-col class="text-h4">
              {{ title }}
            </v-col>
            <v-col class="text-right">
              <v-btn
                icon
                @click="closeCharityForm"
              >
                <v-icon large>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <charity-edit-template
            ref="charityEditTemplate"
            :charity="charity"
            @charity-success="notifySuccess"
          />

          <v-row>
            <v-col
              class="text-end"
            >
              <v-btn
                class="mr-4"
                text
                @click="closeCharityForm"
              >
                cancel
              </v-btn>
              <v-btn
                class="mr-4"
                type="submit"
                color="success"
                @click="submit"
              >
                submit
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import CharityEditTemplate from '@/components/charity/CharityEditTemplate';

export default {
  components: {
    CharityEditTemplate,
  },
  props:
  {
    charity: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    charityDialog: false,
  }),

  methods: {
    openCharityForm() {
      this.charityDialog = true;
    },
    closeCharityForm() {
      this.charityDialog = false;
    },
    submit() {
      this.$refs.charityEditTemplate.submitCharity();
    },
    notifySuccess(msg) {
      this.closeDialog();
      this.$emit('charity-success', msg);
    },
  },
};
</script>
