<template>
  <v-row>
    <v-col
      class="mb-3"
    >
      <v-row>
        <v-col
          class="ma-2 mb-0 pb-0"
          style="display: grid; align-content: center;"
        >
          <div
            style="display: grid; align-content: center;"
          >
            <span
              class="font-weight-bold text-h5"
              style="margin-bottom: 5px;"
            >Education</span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-for="(education, educationIndex) in candidate.candidateQualifications.candidateEducation"
          :key="education.id"
          cols="12"
        >
          <v-divider class="mb-3" />
          <v-row>
            <v-col
              cols="2"
              style="display: grid; align-content: center;"
            >
              <!-- <v-img
                :src="require('@/assets/education-logo.png')"
                style="border-radius: 50% !important;"
                max-height="60px"
                max-width="60px"
                class="ml-2"
              /> -->
              <font-awesome-icon class="ml-5" size="2xl" :icon="['fabs', 'user-graduate']" />
            </v-col>
            <v-col cols="10" class="pl-0">
              <span
                class="subtitle-1 font-weight-bold"
                style="color: rgb(100,100,100)"
              >
                {{ title(education) }}
              </span>
              <template v-if="education.designation">
                <br>
                <span
                  class="caption"
                  style="color: rgb(100,100,100); margin-top: 0px;"
                >
                  {{ education.designation }}
                </span>
              </template>
              <template v-if="education.areaOfStudy">
                <br>
                <span
                  class="subtitle-2"
                  style="color: rgb(100,100,100); margin-top: 0px;"
                >
                  {{ education.areaOfStudy }}
                </span>
              </template>
              <template v-if="education.start && education.start != '0001-01-01T00:00:00'">
                <br>
                <span
                  class="subtitle-2 font-weight-regular"
                  style="color: rgb(100,100,100); margin-top: 0px;"
                >
                  {{ timeframe(education) }} -
                </span>
                <span
                  class="subtitle-2 font-weight-bold"
                  style="color: rgb(100,100,100); margin-top: 0px;"
                >{{ duration(education) }}
                </span>
              </template>
              <template v-if="education.skills && education.skills.length">
                <br>
                <span
                  class="subtitle-2 font-weight-bold"
                  style="color: rgb(100,100,100); margin-top: 0px;"
                >Skills:</span>
                <v-chip
                  v-for="(skill, skillIndex) in education.skills"
                  :key="'edu-' + educationIndex + '-skill-' + skillIndex"
                  small
                  color="primary"
                  class="ml-2 mb-1"
                >
                  {{ skill.name }}
                </v-chip>
              </template>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>

export default {
  components: {
  },

  props:
  {
    candidate: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
  }),
  computed: {
  },
  watch: {
  },
  methods: {
    duration(education) {
      // show duration in years and months
      let end = null;
      if (education.end === null) end = this.$moment();
      else end = this.$moment(education.end);
      const start = this.$moment(education.start);
      const years = this.$moment(end).diff(start, 'years');
      if (years === 0) {
        const months = this.$moment(end).diff(start, 'months');
        return `${months} ${months === 1 ? 'month' : 'months'}`;
      }
      return `${years} ${years === 1 ? 'year' : 'years'}`;
    },
    timeframe(education) {
      // show timeframe in a readable format
      const isCurrent = education.end === null;
      return `${this.$moment(education.start).format('MMM YYYY')} - ${isCurrent ? 'Present' : this.$moment(education.end).format('MMM YYYY')}`;
    },
    title(education) {
      if (!education) return '';
      if (education && education.institution) return `${education.institution}`;
      return education.candidateEducationTypeName;
    },
  },
};
</script>
<style>
</style>
