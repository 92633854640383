<template>
  <v-container fluid>
    <v-row class="text-left">
      <v-col cols="8">
        <h1>{{ userTitle }}</h1>
      </v-col>
      <v-col class="text-end">
        <v-btn
          v-if="isDisabled"
          color="primary"
          outlined
          class="mt-3"
          @click="openUserUpdate"
        >
          <v-icon
            small
            class="mr-1"
          >
            mdi-square-edit-outline
          </v-icon>
          EDIT
        </v-btn>
      </v-col>
    </v-row>
    <user-template
      ref="userTemplate"
      :is-disabled="isDisabled"
      :is-password-required="false"
      :user="user"
      @user-success="notifySuccess"
    />
    <v-row v-if="!isDisabled">
      <v-col
        class="text-end"
      >
        <v-btn
          class="mr-4"
          text
          @click="cancel"
        >
          cancel
        </v-btn>
        <v-btn
          class="mr-4"
          color="success"
          @click="submit"
        >
          submit
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import UserTemplate from '@/components/user/UserTemplate';

export default {
  components: {
    UserTemplate,
  },

  data: () => ({
    candidate: {},
    userTitle: 'User',
    user: null,
    isDisabled: true,
  }),

  computed: {
    ...mapState({
      userId: (state) => state.user.nameId,
    }),
  },

  created() {
    this.init();
  },

  methods: {
    init() {
      this.getUser();
    },
    async getUser() {
      if (!this.userId) return;
      const response = await this.$api.user.getById(this.userId);
      if (!response) return;
      this.user = response;
    },
    openUserUpdate() {
      this.userTitle = 'Update User';
      this.isDisabled = false;
    },
    notifySuccess(message) {
      this.$bus.$emit('showSuccess', message);
      this.$store.dispatch('user/setUserInfo');
      this.getUser();
      this.isDisabled = true;
      this.userTitle = 'User';
    },
    submit() {
      this.$refs.userTemplate.submit();
    },
    cancel() {
      this.userTitle = 'User';
      this.getUser();
      this.isDisabled = true;
    },
  },
};
</script>
