/* eslint-disable consistent-return */
/* eslint-disable no-async-promise-executor */
/* eslint-disable no-param-reassign */

export default {
  namespaced: true,

  state: () => ({
    selectedPositionId: '',
    selectedPostingId: '',
    selectedTab: 0,
  }),
  mutations: {
    storeSelectedPositionId(state, selectedPositionId) {
      state.selectedPositionId = selectedPositionId;
    },
    storeSelectedPostingId(state, selectedPostingId) {
      state.selectedPostingId = selectedPostingId;
    },
    storeSelectedTab(state, selectedTab) {
      state.selectedTab = selectedTab;
    },
  },
  actions: {
    setSelectedPositionId({ commit }, id) {
      commit('storeSelectedPositionId', id);
    },
    setSelectedPostingId({ commit }, id) {
      commit('storeSelectedPostingId', id);
    },
    setSelectedTab({ commit }, tab) {
      commit('storeSelectedTab', tab);
    },
  },
  getters: {
    getSelectedPositionId: (state) => () => {
      const data = state.selectedPositionId;
      return data;
    },
    getSelectedPostingId: (state) => () => {
      const data = state.selectedPostingId;
      return data;
    },
    getSelectedTab: (state) => () => {
      const data = state.selectedTab;
      return data;
    },
  },
};
