<template>
  <v-row justify="center">
    <v-dialog
      v-model="charityRepsTableDialog"
      width="1000"
    >
      <v-card
        fill-height
      >
        <v-container fluid>
          <v-row>
            <v-col>
              <span class="text-h4">
                {{ title }}
              </span>
            </v-col>
            <v-col class="text-right">
              <v-btn
                icon
                @click="closeDialog"
              >
                <v-icon large>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <charity-rep-table-template
            ref="charityRepTableTemplate"
            :charity-id="charityId"
            :is-board-match-admin="isBoardMatchAdmin"
          />
          <v-row>
            <v-col
              class="text-end"
            >
              <v-btn
                type="submit"
                text
                @click="closeDialog"
              >
                cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import CharityRepTableTemplate from '@/components/charity/charityRep/CharityRepTableTemplate';
// import UserAuthenticateAsDialog from '@/components/user/UserAuthenticateAsDialog';

export default {
  components: {
    CharityRepTableTemplate,
    // UserAuthenticateAsDialog,
  },
  props:
  {
    title: {
      type: String,
      default: '',
    },
    isBoardMatchAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    charityRepsTableDialog: false,
    charityId: 0,
  }),
  methods: {
    openCharityRepTableForm(id) {
      this.charityId = id;
      this.charityRepsTableDialog = true;
    },
    closeDialog() {
      this.charityRepsTableDialog = false;
    },
  },
};
</script>
