<template>
  <v-container
    fluid
  >
    <div
      class="pa-2 pb-2 ma-2 mt-12"
      style="background-color: white; border-radius: 8px;"
    >
      <v-row class="text-left">
        <v-col
          cols="12"
          class="pa-6"
        >
          <h1>Search</h1>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field
            v-model.lazy="search"
            placeholder="Search for Candidates &amp; Charities.."
            filled
            rounded
            hide-details=""
            large
            prepend-inner-icon="mdi-magnify"
          />
        </v-col>
      </v-row>
    </div>
    <div
      v-show="isShow"
      class="pa-2 pb-2 ma-2 mt-12"
      style="background-color: white; border-radius: 8px;"
    >
      <candidate-table

        :search="search"
        :filters="filters"
        title="Candidates"
      >
        <template slot-scope="{item}">
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <font-awesome-icon
                class="icon-hover mr-2"
                :icon="['fabs', 'user-pen']"
                v-bind="attrs"
                v-on="on"
                @click="authAs(item)"
              />
            </template>
            <span>Edit candidate details</span>
          </v-tooltip>
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <font-awesome-icon
                class="icon-hover mr-3"
                v-bind="attrs"
                :icon="['fabs', 'xmark']"
                v-on="on"
                @click="disableCandidate(item.id)"
              />
            </template>
            <span>Deactivate candidate</span>
          </v-tooltip>
          <v-tooltip top>
            <template #activator="{on, attrs}">
              <font-awesome-icon
                class="icon-hover"
                :icon="['fabs', 'trash-can']"
                v-bind="attrs"
                v-on="on"
                @click="softDelete(item.id)"
              />
            </template>
            <span>Delete candidate</span>
          </v-tooltip>
        </template>
      </candidate-table>
    </div>
    <div
      v-show="isShow"
      class="pa-2 pb-2 ma-2 mt-12"
      style="background-color: white; border-radius: 8px;"
    >
      <charity-table
        :search="search"
        :filters="filters"
        title="Charities"
      >
        <template slot-scope="{item}">
          <!-- <v-tooltip top>
            <template #activator="{ on, attrs }">
              <font-awesome-icon
                class="icon-hover mr-3"
                v-bind="attrs"
                :icon="['fabs','user-pen']"
                v-on="on"
                @click="openCharityUpdateForm(item)"
              />
            </template>
            <span>EDIT charity</span>
          </v-tooltip> -->
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <font-awesome-icon
                class="icon-hover mr-3"
                v-bind="attrs"
                :icon="['fabs', 'users']"
                v-on="on"
                @click="openCharityRepForm(item)"
              />
            </template>
            <span>Users</span>
          </v-tooltip>
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <font-awesome-icon
                class="icon-hover mr-3"
                v-bind="attrs"
                :icon="['fabs', 'chalkboard']"
                v-on="on"
                @click="openCharityPositionDialog(item)"
              />
            </template>
            <span>Board</span>
          </v-tooltip>
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <font-awesome-icon
                class="icon-hover mr-3"
                v-bind="attrs"
                :icon="['fabs', 'layer-group']"
                v-on="on"
                @click="openCharityPostingDialog(item)"
              />
            </template>
            <span>Postings</span>
          </v-tooltip>
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <font-awesome-icon
                class="icon-hover mr-3"
                v-bind="attrs"
                :icon="['fabs', 'trash-can']"
                v-on="on"
                @click="softDelete(item.id)"
              />
            </template>
            <span>Delete charity</span>
          </v-tooltip>
        </template>
      </charity-table>
    </div>
    <candidate-template-dialog
      ref="candidateTemplateDialog"
      :title="candidateDialogTitle"
      :user="user"
      @candidate-success="notifySuccess"
    >
      <template slot-scope="{item}">
        <v-btn
          v-if="currentUser != null && item != null && currentUser.id != item.id"
          class="mr-4"
          color="primary"
          @click="authAs(item)"
        >
          Log In As User
        </v-btn>
      </template>
    </candidate-template-dialog>
    <charity-rep-table-template-dialog
      ref="charityRepTableTemplateDialog"
      :title="charityRepsTableFormTitle"
      :is-board-match-admin="true"
    />
    <charity-position-template-dialog
      ref="charityPositionTemplateDialog"
      :title="charityPositionDialogTitle"
      :charity="charity"
    />
    <charity-posting-template-dialog
      ref="charityPostingTemplateDialog"
      :title="charityPostingDialogTitle"
      :charity="charity"
    />
    <user-authenticate-as-dialog
      ref="authenticateAsDialog"
    />
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import CandidateTemplateDialog from '@/components/candidate/CandidateTemplateDialog';
import CandidateTable from '@/components/candidate/CandidateTable';
import CharityTable from '@/components/charity/CharityTable';
import UserAuthenticateAsDialog from '@/components/user/UserAuthenticateAsDialog';
import CharityPositionTemplateDialog from '@/components/charity/charityPosition/CharityPositionTemplateDialog';
import CharityPostingTemplateDialog from '@/components/charity/charityPosting/CharityPostingTemplateDialog';
import CharityRepTableTemplateDialog from '@/components/charity/charityRep/CharityRepTableTemplateDialog';

export default {
  components: {
    CandidateTable,
    CharityTable,
    CandidateTemplateDialog,
    UserAuthenticateAsDialog,
    CharityPositionTemplateDialog,
    CharityPostingTemplateDialog,
    CharityRepTableTemplateDialog,
  },
  data: () => ({
    search: '',
    filters: [{ name: 'AdminOverride', values: [1] }],
    user: null,
    candidateDialogTitle: 'Create Candidate',
  }),
  computed: {
    isShow() {
      return !!this.search;
    },
    ...mapState({
      currentUser: (state) => state.user,
    }),
  },
  methods: {
    openCandidateUpdateForm(candidate) {
      this.candidateDialogTitle = 'Update Candidate';
      this.user = JSON.parse(JSON.stringify(candidate.user));
      this.user.candidate = JSON.parse(JSON.stringify(candidate));
      this.$refs.candidateTemplateDialog.openCandidateForm();
    },
    openCharityPositionDialog(charity) {
      this.charityPositionDialogTitle = `Board of ${charity.name}`;
      this.charity = charity;
      this.$refs.charityPositionTemplateDialog.openPositionDialog();
    },
    async disableCandidate(candidateId) {
      if (!confirm('Are you sure you would like to deactivate this candidate?')) return;
      const response = await this.$api.candidate.disableCandidate(candidateId);
      if (response === false) return;
      this.notifySuccess('Candidate disabled');
    },
    openCharityPostingDialog(charity) {
      this.charityPostingDialogTitle = `Postings of ${charity.name}`;
      this.charity = charity;
      this.$refs.charityPostingTemplateDialog.openPostingDialog();
    },
    openCharityRepForm(charity) {
      this.charityRepsTableFormTitle = 'Charity Users';
      this.$refs.charityRepTableTemplateDialog.openCharityRepTableForm(charity.id);
    },
    async softDelete(candidateId) {
      if (!confirm('Are you sure you would like to delete this candidate?')) return;
      const response = await this.$api.candidate.softDelete(candidateId);
      if (response === true) {
        this.notifySuccess('Candidate deleted');
      } else {
        this.$bus.$emit('showError');
      }
    },
    authAs(user) {
      this.$refs.authenticateAsDialog.openDialog(user);
    },
    notifySuccess(message) {
      this.$bus.$emit('showSuccess', message);
      this.$refs.candidateTable.refresh();
    },
  },
};
</script>

<style></style>
