<template>
  <v-container
    fluid
    class="pa-2 pb-2 ma-2 mt-12"
    style="background-color: white; border-radius: 8px;"
  >
    <v-row class="text-left pl-6 pt-5">
      <v-col cols="8">
        <h1>{{ title }}</h1>
      </v-col>
    </v-row>
    <v-row
      class="text-right pr-6"
    >
      <v-col>
        <v-btn
          color="primary"
          outlined
          @click="openThinkificCourse()"
        >
          Altruvest Academy
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pr-5 pl-5">
        <training-course-enrollment-table :filters="filters">
          <template slot-scope="{item}">
            <span><a
              class="mr-2"
              @click="openThinkificCourse(item.course_name)"
            >Go To Course</a>
            </span>
          </template>
        </training-course-enrollment-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import { createPaginationObject } from '@/helpers/PaginationHelper.js';
import TrainingCourseEnrollmentTable from '@/components/training/TrainingCourseEnrollmentTable.vue';
import { openThinkificCourse } from '@/helpers/ThinkificHelper.js';

export default {
  components: {
    TrainingCourseEnrollmentTable,
  },

  data: () => ({
    courseEnrollments: [],
    title: 'Training',
    courseTypeId: 1, // as per ThinkificCourseTypes table, 1 = Candidate
  }),

  computed: {
    ...mapState({
      userId: (state) => state.user.nameId,
    }),
    filters() {
      const filters = [];
      filters.push({ name: 'UserId', values: [this.userId] });
      filters.push({ name: 'Type', values: [this.courseTypeId] });
      return filters;
    },
  },

  created() {
    this.init();
  },

  methods: {
    init() {
      this.getCourseEnrollments();
    },
    async getCourseEnrollments() {
      const response = await this.$api.thinkificEnrollments.read(
        createPaginationObject(null, null, this.filters),
      );
      if (!response) return;
      this.courseEnrollments = response;
    },
    async openThinkificCourse(courseName = null) {
      openThinkificCourse(courseName);
    },
  },
};
</script>
