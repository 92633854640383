<template>
  <div class="ma-0 pa-0">
    <v-row
      class="pt-0 mt-0"
    >
      <v-col cols="12">
        <training-courses-table
          ref="trainingCoursesTable"
        >
          <template slot-scope="{item}">
            <span><a
              class="mr-2"
              @click="openCourseForm(item)"
            >Edit</a>
            </span>
          </template>
        </training-courses-table>
      </v-col>
    </v-row>
    <general-dialog
      ref="courseFormDialog"
      :title="editingCourse.id == null ? 'Add New Course' : 'Edit Course'"
      submit-button-label="Save"
      dialog-width="650px"
      :submit-button-enabled="false"
      @submit="submit()"
    >
      <thinkific-course-form
        ref="thinkificCourseForm"
        :course="editingCourse"
        @close="closeDialogs()"
        @backgroundChanged="loadBackgroundImage()"
      />
    </general-dialog>
  </div>
</template>

<script>
import TrainingCoursesTable from '@/components/thinkific/TrainingCoursesTable';
import GeneralDialog from '@/components/general/GeneralDialog';
import ThinkificCourseForm from '@/components/thinkific/ThinkificCourseForm';

export default {
  components: {
    TrainingCoursesTable,
    GeneralDialog,
    ThinkificCourseForm,
  },

  props:
    {
    },
  data: () => ({
    editingCourse: {
      id: null,
      courseName: '',
      courseId: null,
      thinkificCourseTypeId: 1,
    },
  }),
  methods: {
    openCourseForm(course = null) {
      if (course) this.editingCourse = course;
      else {
        this.editingCourse = {
          id: null,
          courseName: '',
          courseId: null,
          thinkificCourseTypeId: 1,
        };
      }
      this.$refs.courseFormDialog.openForm();
    },
    closeDialogs() {
      this.$refs.courseFormDialog.closeForm();
      this.editingCourse = {
        id: null,
        courseName: '',
        courseId: null,
        thinkificCourseType: 1,
      };
    },
    async submit() {
      const res = await this.$refs.thinkificCourseForm.submit();
      if (!res || !res.valid) this.$bus.$emit('showError', 'Please enter all required details marked in red.');
      else if (!res.successful) this.$bus.$emit('showError', 'Failed to save changes.');
      else {
        this.$bus.$emit('showSuccess', 'Successfully saved changes.');
        this.$refs.trainingCoursesTable.refresh();
        this.closeDialogs();
      }
    },
  },
};
</script>
