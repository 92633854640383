import Vue from 'vue';
import moment from 'moment';
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/* import specific icons */
import {
  faUserSecret,
  faBriefcase,
  faUserGraduate,
  faLayerGroup,
  faCirclePlus,
  faUserPen,
  faXmark,
  faTrashCan,
  faChalkboard,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import {
  faTwitter,
  faFacebook,
  faFacebookSquare,
  faInstagram,
  faLinkedin,
  faGithub,
} from '@fortawesome/free-brands-svg-icons';

import TheSnackbar from '@/components/app/TheSnackbar';
import TheRedAsterisk from '@/components/app/TheRedAsterisk';
import TheZigZag from '@/components/app/TheZigZag';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import './plugins/axios';
import '@/api/ApiFactories.js';
import store from './store';
// eslint-disable-next-line
import router from './router';

// import 'medium-editor/dist/css/medium-editor.css';
// for the code highlighting
// import 'highlight.js/styles/ocean.css';
/* add icons to the library */
library.add(faUserSecret, faBriefcase, faUserGraduate, faCirclePlus, faLayerGroup, faTwitter, faFacebook, faFacebookSquare, faInstagram, faLinkedin, faGithub, faUserPen);
library.add(faXmark, faTrashCan, faChalkboard, faUsers);

Vue.config.productionTip = false;
// eslint-disable-next-line vue/component-definition-name-casing
Vue.component('red-asterisk', TheRedAsterisk);
// eslint-disable-next-line vue/component-definition-name-casing
Vue.component('zigzag', TheZigZag);
// eslint-disable-next-line vue/component-definition-name-casing
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('Snackbar', TheSnackbar);
Vue.prototype.$bus = new Vue();
Vue.prototype.$moment = moment;

new Vue({
  router,
  store,
  vuetify,
  created() {
    this.init();
  },
  methods: {
    async init() {
      // var urlVar = await EnvironmentApi.getUrl()
      // console.log('CHECK')
      // console.log(urlVar)
    },
  },
  render: (h) => h(App),
}).$mount('#app');
