<template>
  <v-container
    fluid
    class="px-0 mx-0"
  >
    <v-data-table
      :headers="dataHeaders"
      :items="roles"
      :footer-props="dataPerPageProps"
      :items-per-page="dataPerPage"
      dense
    >
      <template #[`item.roleName`]="{ item }">
        {{ item.name }}
      </template>
      <template #[`item.description`]="{ item }">
        {{ item.description }}
      </template>
      <!-- <template #[`item.actions`]="{ item }">
        <a
          class="mr-2"
          @click="openClaimsTableDialog(item)"
        > PERMISSIONS </a>
      </template> -->
    </v-data-table>

    <v-slide-y-transition>
      <v-row v-show="isBoardmatchOpen || isCharityRepOpen">
        <v-col>
          <v-row>
            <v-col>
              <zigzag />
            </v-col>
          </v-row>
          <charity-rep-admin-claims-table
            v-if="isCharityRepOpen"
            :role="role"
          />
          <boardmatch-rep-claims-table
            v-if="isBoardmatchOpen"
            :role="role"
          />
          <v-row>
            <v-col
              class="text-end"
            >
              <v-btn
                class="mr-4"
                text
                @click="closeForm"
              >
                close
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-slide-y-transition>
  </v-container>
</template>

<script>
import CharityRepAdminClaimsTable from '@/components/charity/charityRep/CharityRepAdminClaimsTable';
import BoardmatchRepClaimsTable from '@/components/boardmatchRep/BoardmatchRepClaimsTable';

export default {
  components: {
    CharityRepAdminClaimsTable,
    BoardmatchRepClaimsTable,
  },
  props:
  {
    charityId: {
      type: Number,
      default: 0,
    },
    roles: {
      type: Array,
      default: () => [],
    },
    isBoardmatch: {
      type: Boolean,
      default: false,
    },
    isCharityRep: {
      type: Boolean,
      default: false,
    },

  },
  data: () => ({
    dataPerPageProps: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    dataPerPage: 20,
    charityRepFormTitle: 'Create Charity User',
    role: {
      id: 0,
      name: '',
      charityRepresentativeRolePermission: [],
    },
    isCharityRepOpen: false,
    isBoardmatchOpen: false,
  }),
  computed: {
    dataHeaders() {
      const headers = [];

      headers.push({ text: 'Role Name', value: 'roleName' });
      headers.push({ text: 'Description', value: 'description' });
      // headers.push({ text: 'Actions', value: 'actions', align: 'right' });

      return headers;
    },
  },
  methods: {

    openClaimsTableDialog(role) {
      this.role = role;

      if (this.isBoardmatch) {
        this.isCharityRepOpen = false;
        this.isBoardmatchOpen = true;
      } else if (this.isCharityRep) {
        this.isBoardmatchOpen = false;
        this.isCharityRepOpen = true;
      }
    },

    closeForm() {
      this.isBoardmatchOpen = false;
      this.isCharityRepOpen = false;
    },
  },
};
</script>
