import axios from 'axios';
import Api from '../Api';

class CandidateApi extends Api {
  constructor() {
    super('candidates', 'candidates');
  }

  async getDisabledCandidates() {
    let list = [];
    const errorText = `Could not fetch ${this.model} list`;
    try {
      const url = `/${this.endpoint}/disabled`;
      list = await axios.get(url);
      if (list.status === 200) {
        list = list.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      list = [];
    }
    return list;
  }

  async disableCandidate(candidateId) {
    let res;
    const errorText = `Could not update ${this.model}`;
    try {
      res = await axios.put(`/${this.endpoint}/${candidateId}`);
      if (res.status === 200) {
        res = true;
      } else {
        console.error(errorText);
        res = false;
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = false;
    }
    return res;
  }

  async getCandidateByUserId(id) {
    const res = await this.getByUserId(id);
    return res;
  }

  async getMinorityGroups() {
    let res;
    const errorText = 'Could not fetch minority groups';
    try {
      const url = `/${this.endpoint}/minority-groups`;
      res = await axios.get(url);
      if (res.status === 200) {
        res = res.data;
      } else {
        console.error(errorText);
        res = false;
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = false;
    }
    return res;
  }

  async getAgeGroups() {
    let res;
    const errorText = 'Could not fetch minority groups';
    try {
      const url = `/${this.endpoint}/age-groups`;
      res = await axios.get(url);
      if (res.status === 200) {
        res = res.data;
      } else {
        console.error(errorText);
        res = false;
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = false;
    }
    return res;
  }

  async getBackgroundImage(candidateId) {
    let res;
    let errorText = 'Could not fetch candidate\'s background imagee';
    try {
      const url = `/${this.endpoint}/${candidateId}/background-image`;
      res = await axios.get(url);
      if (res.status === 200) {
        res = res.data;
      } else if (res.status === 204) {
        errorText = `server is missing photo for candidate id ${candidateId}`;
        console.error(errorText);
      } else {
        console.error(errorText);
        res = false;
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = false;
    }
    return res;
  }

  async getBackgroundImageThumbnails(candidateId) {
    let res;
    let errorText = 'Could not fetch candidate\'s background image thumbnails';
    try {
      const url = `/${this.endpoint}/${candidateId}/background-image-thumbnails`;
      res = await axios.get(url);
      if (res.status === 200) {
        res = res.data;
      } else if (res.status === 204) {
        errorText = `server is missing thumbnails for candidate id ${candidateId}`;
        console.error(errorText);
      } else {
        console.error(errorText);
        res = false;
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = false;
    }
    return res;
  }

  async setBackgroundImage(candidateId, imageId) {
    let res;
    let errorText = 'Could not set background image';
    try {
      const url = `/${this.endpoint}/${candidateId}/set-background/${imageId}`;
      res = await axios.get(url);
      if (res.status === 200) {
        res = res.data;
      } else if (res.status === 204) {
        errorText = 'Could not set background image';
        console.error(errorText);
      } else {
        console.error(errorText);
        res = false;
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = false;
    }
    return res;
  }

  async suppressWelcome(id) {
    const res = await this.get('suppress-welcome', [{ name: 'id', value: id }]);
    return res;
  }

  async getWelcomeStatus(id) {
    const res = await this.get('get-welcome', [{ name: 'id', value: id }]);
    return res;
  }
}
const candidateApi = new CandidateApi();
export default candidateApi;
