<template>
  <div>
    <user-information-form
      v-if="!loggedInUser && showUserInformation"
      ref="userInformationForm"
      :user="user"

      :is-password-required="isPasswordRequired"
      :is-disabled="isDisabled"
      :show-pronoun="true"
    />
    <v-row v-if="!loggedInUser && showUserInformation">
      <v-col
        cols="6"
        class="pr-0"
      >
        <general-phone-form
          ref="generalPhoneForm"
          :phone="getPhone('Main')"
          :is-required="isPhoneRequired"
          :is-disabled="isDisabled"
        />
      </v-col>
      <v-col
        cols="6"
        class="pl-0"
      >
        <general-phone-form
          ref="eveningPhoneForm"
          :phone="getPhone('Evening')"
          :is-required="false"
          :is-disabled="isDisabled"
          phone-type="evening"
        />
      </v-col>
    </v-row>
    <user-used-title
      v-else-if="showUserInformation"
      :username="loggedInUser ? `${loggedInUser.userInformation.firstName} ${loggedInUser.userInformation.lastName}` : ''"
    />
    <v-row v-if="showSectionTitles">
      <v-col class="text-h5 font-weight-bold mb-6 mt-1 ml-3">
        Residence Address
      </v-col>
    </v-row>
    <general-address-form
      v-if="showUserInformation"
      ref="generalAddressForm"
      :address="loggedInUser ? loggedInUser.userInformation.address : user && user.userInformation ? user.userInformation.address : null"
      :is-required="isAddressRequired"
      :is-disabled="isDisabled"
    />
    <candidate-information-form
      ref="candidateInformationForm"
      :candidate="candidate"
      :is-disabled="isDisabled"
      :show-section-titles="showSectionTitles"
    />
  </div>
</template>

<script>
import UserInformationForm from '@/components/user/UserInformationForm.vue';
import GeneralPhoneForm from '@/components/general/GeneralPhoneForm';
import GeneralAddressForm from '@/components/general/GeneralAddressForm';
import CandidateInformationForm from '@/components/candidate/CandidateInformationForm';
import UserUsedTitle from '@/components/user/UserUsedTitle.vue';

export default {
  components: {
    UserInformationForm,
    GeneralPhoneForm,
    GeneralAddressForm,
    CandidateInformationForm,
    UserUsedTitle,
  },
  props:
    {
      // user is required if showUserInformation is false
      user: {
        type: Object,
        default: () => {},
      },
      candidate: {
        type: Object,
        default: () => {},
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
      isPasswordRequired: {
        type: Boolean,
        default: false,
      },
      showSectionTitles: {
        type: Boolean,
        default: false,
      },
      isPhoneRequired: {
        type: Boolean,
        default: false,
      },
      isAddressRequired: {
        type: Boolean,
        default: false,
      },
      // user is required if showUserInformation is false
      showUserInformation: {
        type: Boolean,
        default: true,
      },
      showTitle: {
        type: Boolean,
        default: true,
      },
      title: {
        type: String,
        default: 'User Information',
      },
    },

  data: () => ({
    loggedInUser: null,
  }),

  methods: {
    async submit() {
      const result = {
        success: false,
        completed: false,
      };
      result.completed = await this.validate();
      if (!result.completed) return result;

      this.$bus.$emit('loading', true);
      await this.$refs.candidateInformationForm.validate();

      let userResponse = this.loggedInUser || !this.showUserInformation ? null : await this.$refs.userInformationForm.submit(1);
      if (!userResponse && this.user != null) userResponse = { ...this.user };
      if (!userResponse) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading', false);
        return result;
      }
      const userInformationId = this.loggedInUser ? this.loggedInUser.userInformation.id : userResponse.userInformation.id;

      const candidateResponse = this.loggedInUser ? await this.$refs.candidateInformationForm.submit(this.loggedInUser.id) : await this.$refs.candidateInformationForm.submit(userResponse.id);

      // if we aren't showing the user information form, we aren't updating user details
      const addressResponse = this.loggedInUser || !this.showUserInformation ? true : await this.$refs.generalAddressForm.submit(null, userInformationId);
      const phoneResponse = this.loggedInUser || !this.showUserInformation ? true : await this.$refs.generalPhoneForm.submit(null, userInformationId);
      const phone2Response = this.loggedInUser || !this.showUserInformation ? true : await this.$refs.eveningPhoneForm.submit(null, userInformationId);

      if (!candidateResponse && (this.showUserInformation === false || (!addressResponse && !phoneResponse && !phone2Response))) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading', false);
        return result;
      }
      if (this.user && this.user.id) {
        this.$emit('candidate-success', 'Candidate Updated');
      } else {
        this.$emit('candidate-success', 'Candidate Created');
      }
      result.success = true;
      this.$bus.$emit('loading', false);
      return result;
    },
    async validate(showMessage = true) {
      const res = await Promise.all([
        this.loggedInUser || !this.showUserInformation ? true : this.$refs.candidateInformationForm.validate(showMessage),
        this.loggedInUser || !this.showUserInformation ? true : this.$refs.userInformationForm.validate(showMessage),
        !this.showUserInformation ? true : this.$refs.generalAddressForm.validate(showMessage),
        this.loggedInUser || !this.showUserInformation ? true : this.$refs.generalPhoneForm.validate(showMessage)])
        .then((values) => !values.includes(false));
      return res;
    },
    userFormSelected(data) {
      if (!data) return;
      if (data.candidate) {
        this.$bus.$emit('showError', 'Candidate for this user is already created.');
        return;
      }
      this.loggedInUser = data;
    },

    resetForm() {
      this.$refs.candidateInformationForm.reset();
      if (!this.loggedInUser) this.$refs.userInformationForm.reset();
      this.$refs.generalAddressForm.reset();
      if (!this.loggedInUser) this.$refs.generalPhoneForm.reset();
      this.loggedInUser = null;
    },
    getPhone(type) {
      if (!this.user || !this.user.userInformation || !this.user.userInformation.phones || this.user.userInformation.phones.length === 0) return null; // || !this.user.userInformation.phones.phoneType
      return this.user.userInformation.phones.find((x) => x.phoneType.name.toLowerCase() === type.toLowerCase());
    },
  },
};
</script>
