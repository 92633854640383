import Api from '@/api/Api';

class ThinkificCoursesApi extends Api {
  constructor() {
    super('thinkific/courses', 'Thinkific Courses');
  }

  async getTypes() {
    const res = await this.get('types');
    return res;
  }
}
const thinkificCoursesApi = new ThinkificCoursesApi();
export default thinkificCoursesApi;
