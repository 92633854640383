<template>
  <v-container
    fluid
    class="mb-2"
  >
    <v-row class="text-left">
      <v-col cols="8">
        <h1>{{ candidateTitle }}</h1>
      </v-col>
    </v-row>
    <v-row class="white-background">
      <v-col cols="8">
        <v-tabs
          v-model="selectedTab"
          center-active
          @change="isDisabled = true"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.tab"
            :value="tab.tab"
          >
            {{ tab.name }}
          </v-tab>
        </v-tabs>
      </v-col>
      <v-col
        v-if="isDisabled"
        class="text-end"
      >
        <v-btn
          color="primary"
          outlined
          class="mt-3"
          @click="openCandidateUpdate()"
        >
          <v-icon
            small
            class="mr-1"
          >
            mdi-square-edit-outline
          </v-icon>
          EDIT
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="white-background">
      <v-col>
        <user-template
          v-if="tabs[selectedTab].tab === 'userTemplate' && !loadingUserInfo"
          ref="userTemplate"
          :is-disabled="isDisabled"
          :is-password-required="false"
          :is-address-required="true"
          :is-phone-required="true"
          :user="user"
          @user-success="notifySuccess"
        />
        <candidate-template
          v-else-if="tabs[selectedTab].tab === 'candidateTemplate' && !loadingCandidateInfo"
          ref="candidateTemplate"
          title="Optional Details"
          :is-disabled="isDisabled"
          :is-password-required="false"
          :show-user-information="false"
          :user="user"
          :candidate="candidate"
          @candidate-success="notifySuccess"
        />
        <candidate-qualifications-template
          v-else-if="tabs[selectedTab].tab === 'candidateQualificationsTemplate' && !loadingCandidateInfo"
          ref="candidateQualificationsTemplate"
          :read-only="isDisabled"
        />
        <candidate-position-matching-template
          v-else-if="tabs[selectedTab].tab === 'candidateMatchingPreferencesTemplate' && !loadingCandidateInfo"
          ref="candidateMatchingPreferencesTemplate"
          :read-only="isDisabled"
          :candidate="candidate"
        />
        <candidate-release-template
          v-else-if="tabs[selectedTab].tab === 'candidateReleaseTemplate' && !loadingCandidateInfo"
          ref="candidateReleaseTemplate"
          :candidate="candidate"
          :read-only="isDisabled"
        />
        <v-row
          v-else
        >
          <v-col cols="5" />
          <v-col cols="2">
            <v-card
              color="primary"
              dark
            >
              <v-card-text>
                Loading, Please stand by
                <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="!isDisabled">
          <v-col
            class="text-end"
          >
            <v-btn
              class="mr-4"
              text
              @click="cancel"
            >
              cancel
            </v-btn>
            <v-btn
              class="mr-4"
              color="success"
              @click="submit"
            >
              submit
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { mapState } from 'vuex';
import CandidateTemplate from '@/components/candidate/CandidateTemplate';
import UserTemplate from '@/components/user/UserTemplate';
import CandidateQualificationsTemplate from '@/components/candidate/qualifications/CandidateQualificationsTemplate.vue';
import CandidatePositionMatchingTemplate from '@/components/candidate/positionMatching/CandidatePositionMatchingTemplate.vue';
import CandidateReleaseTemplate from '@/components/candidate/CandidateReleaseTemplate';

export default {
  components: {
    CandidateTemplate,
    UserTemplate,
    CandidateQualificationsTemplate,
    CandidatePositionMatchingTemplate,
    CandidateReleaseTemplate,
  },

  data: () => ({
    candidate: {},
    candidateTitle: 'Candidate Profile',
    user: null,
    isDisabled: true,
    selectedTab: 0,
    // onboarding steps in the following array as per onboarding database table
    tabs: [
      { tab: 'userTemplate', name: 'User Information', onboardingStep: 1 },
      { tab: 'candidateTemplate', name: 'Candidate Details', onboardingStep: 1 },
      { tab: 'candidateQualificationsTemplate', name: 'Qualifications', onboardingStep: 2 },
      { tab: 'candidateMatchingPreferencesTemplate', name: 'Matching Preferences', onboardingStep: 3 },
      { tab: 'candidateReleaseTemplate', name: 'Release Form', onboardingStep: 5 },
    ],
  }),

  computed: {
    ...mapState({
      userId: (state) => state.user.nameId,
      // qualifications
      educationTypes: (state) => state.candidate.educationTypes,
      candidateQualifications: (state) => state.candidate.candidateQualifications,
      // position matching
      candidateSkills: (state) => state.candidate.candidateSkills,
      candidateFocusAreas: (state) => state.candidate.candidateFocusAreas,
      candidateIntroductionTypes: (state) => state.candidate.candidateIntroductionTypes,
      volunteerProgramTypes: (state) => state.candidate.volunteerProgramTypes,
      volunteerExperienceTypes: (state) => state.candidate.volunteerExperienceTypes,
      boardExperienceTypes: (state) => state.candidate.boardExperienceTypes,
      candidateMatchingPreferences: (state) => state.candidate.candidateMatchingPreferences,
      // optional candidate
      ageGroups: (state) => state.candidate.ageGroups,
      minorityGroups: (state) => state.candidate.minorityGroups,
      socialMediaTypes: (state) => state.general.socialMediaTypes,
    }),
    loadingUserInfo() {
      return this.user === null;
    },
    loadingCandidateInfo() {
      return this.user === null
        || this.candidate === null
        || this.ageGroups == null || this.ageGroups.length === 0
        || this.minorityGroups == null || this.minorityGroups.length === 0
        || this.socialMediaTypes == null || this.socialMediaTypes.length === 0;
    },
    loadingQualifications() {
      return this.user === null
        || this.candidate === null
        || this.educationTypes == null || this.educationTypes.length === 0
        || this.candidateQualifications === null;
    },
    loadingPositionMatching() {
      return this.user === null
        || this.candidate === null
        || this.candidateSkills == null || this.candidateSkills.length === 0
        || this.candidateFocusAreas == null || this.candidateFocusAreas.length === 0
        || this.candidateIntroductionTypes == null || this.candidateIntroductionTypes.length === 0
        || this.volunteerProgramTypes == null || this.volunteerProgramTypes.length === 0
        || this.volunteerExperienceTypes == null || this.volunteerExperienceTypes.length === 0
        || this.boardExperienceTypes == null || this.boardExperienceTypes.length === 0
        || this.candidateMatchingPreferences == null || this.candidateMatchingPreferences.length === 0;
    },
    loadingReleaseForm() {
      return this.user === null
        || this.candidate === null;
    },
  },

  created() {
    this.init();
  },

  methods: {
    init() {
      this.getCandidate();
      this.loadTypes();
    },
    async getCandidate() {
      const userId = this.$store.state.user.nameId;
      this.$store.dispatch('candidate/getCandidate', userId);
      const response = await this.$api.candidate.getCandidateByUserId(userId);
      if (!response) return;
      this.candidate = response;
      this.user = JSON.parse(JSON.stringify(this.candidate.user));
      this.user.candidate = JSON.parse(JSON.stringify(this.candidate));
    },
    async loadTypes() {
      // qualifications
      if (!this.educationTypes.length) this.$store.dispatch('candidate/getEducationTypes');
      // position matching
      if (!this.candidateSkills.length) this.$store.dispatch('candidate/getCandidateSkills');
      if (!this.candidateFocusAreas.length) this.$store.dispatch('candidate/getCandidateFocusAreas');
      if (!this.candidateIntroductionTypes.length) this.$store.dispatch('candidate/getCandidateIntroductionTypes');
      if (!this.volunteerExperienceTypes.length) this.$store.dispatch('candidate/getVolunteerExperienceTypes');
      if (!this.boardExperienceTypes.length) this.$store.dispatch('candidate/getBoardExperienceTypes');
      if (!this.volunteerProgramTypes.length) this.$store.dispatch('candidate/getVolunteerProgramTypes');
      if (!this.ageGroups.length) this.$store.dispatch('candidate/getAgeGroups');
      if (!this.minorityGroups.length) this.$store.dispatch('candidate/getMinorityGroups');
      if (!this.socialMediaTypes.length) this.$store.dispatch('general/getSocialMediaTypes');
    },
    openCandidateUpdate() {
      this.candidateTitle = 'Update Candidate';
      this.isDisabled = false;
    },
    notifySuccess(message) {
      this.$bus.$emit('showSuccess', message);
      this.$store.dispatch('user/setUserInfo');
      this.getCandidate();
      this.isDisabled = true;
      this.candidateTitle = 'Candidate';
    },
    async submit() {
      const res = await this.$refs[this.tabs[this.selectedTab].tab].submit();
      if (res.success === true) {
        const stepId = this.tabs[this.selectedTab].onboardingStep;
        await this.$api.candidateOnboarding.complete(this.candidate.id, stepId, res.completed);
        this.$store.dispatch('candidate/getCandidateOnboarding', this.candidate.id);
      }
      this.getCandidate();
      this.isDisabled = true;
    },
    cancel() {
      this.candidateTitle = 'Candidate';
      this.getCandidate();
      this.isDisabled = true;
    },
  },
};
</script>
<style scoped>
.white-background {
  background-color: white;
}
</style>
