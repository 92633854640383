<template>
  <v-row>
    <v-col>
      <v-row>
        <!-- <v-col
          class="ma-2 mb-0 pb-0"
          style="display: grid; align-content: center;"
        >
          <div
            style="display: grid; align-content: center;"
          >
            <span
              class="font-weight-bold text-h5"
              style="margin-bottom: 5px;"
            >{{ title }}</span>
          </div>
        </v-col> -->
      </v-row>
      <v-row>
        <v-col
          cols="4"
          class="pr-1 pb-1"
        >
          <div class="indicator">
            <v-row>
              <v-col class="indicator-text dark-grey">
                <span style="font-weight: bolder;">{{ charity.yearOfIncorporation }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="indicator-title dark-grey">
                <span style="font-weight: lighter;">Incorporated</span>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col
          cols="4"
          class="pl-1 pr-1 pb-1"
        >
          <div class="indicator">
            <v-row>
              <v-col class="indicator-text dark-grey">
                <span style="font-weight: bolder;">{{ charity.charityPositionMatching.numberOfPaidStaff ? charity.charityPositionMatching.numberOfPaidStaff : 0 }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="indicator-title dark-grey">
                <span style="font-weight: lighter;">Staff</span>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col
          cols="4"
          class="pl-1 pb-1"
        >
          <div class="indicator">
            <v-row>
              <v-col class="indicator-text dark-grey">
                <span style="font-weight: bolder;">{{ charity.charityPositionMatching.numberOfVolunteers ? charity.charityPositionMatching.numberOfVolunteers : 0 }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="indicator-title dark-grey">
                <span style="font-weight: lighter;">Volunteers</span>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="charity.charityPositionMatching.charityBoardType">
        <v-col
          cols="12"
          class="pt-1 pb-1"
        >
          <div class="indicator">
            <v-row>
              <v-col class="indicator-text dark-grey">
                <span style="font-weight: bolder;">{{ charity.charityScope ? charity.charityScope.name : '' }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="indicator-title dark-grey">
                <span style="font-weight: lighter;">Scope</span>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="pt-1 pb-1"
        >
          <div class="indicator">
            <v-row>
              <v-col class="indicator-text dark-grey">
                <span style="font-weight: bolder;">{{ charity.charityPositionMatching.charityBoardType.name }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="indicator-title dark-grey">
                <span style="font-weight: lighter;">Charity Board Type</span>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-1">
          <div class="indicator">
            <v-row>
              <v-col class="indicator-text dark-grey">
                <span style="font-weight: bolder;">{{ charity.chariableNumber }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="indicator-title dark-grey">
                <span style="font-weight: lighter;">Registered Charitable Number</span>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';

export default {
  components: {
  },

  props:
  {
    charity: {
      type: Object,
      default: () => {},
    },
    editing: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    charitySubmit: null,
    title: 'Information',
  }),
  computed: {
    ...mapState({
      storeCharity: (state) => state.charity.charity,
    }),
  },
  watch: {
    charity: {
      immediate: true,
      handler(value) {
        if (!value && !this.editable) return;
        this.charitySubmit = value;
      },
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
    },
  },
};
</script>
<style>
  .profile-picture-button{
    background-color: rgb(0,0,0,0.2) !important;
  }
  button.profile-picture-button-hover span i.v-icon.notranslate.mdi {
    width: 100px !important;
    height: 101px !important;
    font-size: 70px;
    background-color: transparent;
    color: rgb(0,0,0,0.5);
  }
  button.profile-picture-button span i.v-icon.notranslate.mdi {
    background-color: transparent;
    color: transparent;
  }
  .dark-grey {
    color: rgb(0,0,0,0.6)
  }
  .indicator{
    border-radius: 8px;
    padding: 12px;
    background-color: rgb(239, 239, 239);
  }
  .indicator-text{
    font-size: 22px;
    justify-content: center;
    display: flex;
    padding-bottom: 0px !important;
  }
  .indicator-title{
    font-weight: bold;
    justify-content: center;
    display: flex;
    padding-top: 0px !important;
  }
</style>
