<template>
  <v-row class="pl-4">
    <v-col
      class="mt-4"
      cols="9"
    >
      <v-btn
        color="primary"
        @click="resendVerification()"
      >
        Re-send Verification Email
      </v-btn>
    </v-col>
    <v-col
      cols="3"
      class="pb-"
    >
      <v-img
        :src="require('@/assets/Altruvest Official Logo.svg')"
        width="100px"
        height="100px"
        contain
      />
    </v-col>
  </v-row>
</template>

<script>
// import Snackbar from '@/components/app/TheSnackbar';

export default {
  name: 'Verification',
  components: {
    // Snackbar,
  },
  props: {
    userName: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    token: null,
    text: '',
  }),
  methods: {
    async resendVerification() {
      // call api that takes the username and resends the verificatrion email
      const res = await this.$api.auth.resendVerification(this.userName);
      if (!res) this.$bus.$emit('showError', 'Unable to send an email.');
      else {
        this.$bus.$emit('showSuccess', 'Email has been sent.');
        this.$emit('closeForm');
      }
    },
  },
};
</script>
