/* eslint-disable consistent-return */
/* eslint-disable no-async-promise-executor */
/* eslint-disable no-param-reassign */
import { createPaginationObject } from '@/helpers/PaginationHelper.js';
import candidateSkillsApi from '../api/candidates/CandidateSkillsApi';
import candidateFocusAreasApi from '../api/candidates/CandidateFocusAreasApi';
import candidateOnboardingApi from '../api/candidates/CandidateOnboardingApi';
import CandidateMatchingPreferencesApi from '../api/candidates/CandidateMatchingPreferencesApi';
import CandidateQualificationsApi from '../api/candidates/CandidateQualificationsApi';
import candidateApi from '../api/candidates/CandidateApi';

export default {
  namespaced: true,

  state: () => ({
    candidateSkills: [],
    candidateFocusAreas: [],
    candidateMatchingPreferences: null,
    candidate: null,
    permissions: [],
    onboarding: [],
    onboarded: false,
    volunteerProgramTypes: [],
    candidateIntroductionTypes: [],
    volunteerExperienceTypes: [],
    boardExperienceTypes: [],
    educationTypes: [],
    experienceContractTypes: [],
    experienceLocationTypes: [],
    candidateQualifications: null,
    minorityGroups: [],
    ageGroups: [],
  }),
  mutations: {

    clearData(state) {
      state.candidate = null;
      state.permissions = [];
      state.onboarding = [];
      state.onboarded = false;
      state.candidateSkills = [];
      state.candidateFocusAreas = [];
      state.candidateMatchingPreferences = null;
      state.volunteerProgramTypes = [];
      state.candidateIntroductionTypes = [];
      state.volunteerExperienceTypes = [];
      state.boardExperienceTypes = [];
      state.educationTypes = [];
      state.experienceContractTypes = [];
      state.experienceLocationTypes = [];
      state.candidateQualifications = null;
      state.minorityGroups = [];
      state.ageGroups = [];
    },

    storeCandidateSkills(state, list) {
      const sorted = list.sort((a, b) => {
        const x = a.name; const y = b.name;
        if (x < y) return -1;
        if (x > y) return 1;
        return 0;
      });
      state.candidateSkills = sorted;
    },

    storeCandidateFocusAreas(state, list) {
      const sorted = list.sort((a, b) => {
        const x = a.name; const y = b.name;
        if (x < y) return -1;
        if (x > y) return 1;
        return 0;
      });
      state.candidateFocusAreas = sorted;
    },

    storeCandidate(state, data) {
      state.candidate = data;
    },
    storeCandidateMatchingPreferences(state, data) {
      state.candidateMatchingPreferences = data;
    },
    storeCandidateOnboarding(state, data) {
      state.onboarding = data;
    },
    storeOnboardingState(state, data) {
      state.onboarded = data;
    },
    storeVolunteerProgramTypes(state, data) {
      state.volunteerProgramTypes = data;
    },
    storeCandidateIntroductionTypes(state, data) {
      state.candidateIntroductionTypes = data;
    },
    storeVolunteerExperienceTypes(state, data) {
      state.volunteerExperienceTypes = data;
    },
    storeBoardExperienceTypes(state, data) {
      state.boardExperienceTypes = data;
    },
    storeCandidateQualifications(state, data) {
      state.candidateQualifications = data;
    },
    storeEducationTypes(state, data) {
      state.educationTypes = data;
    },
    storeExperienceContractTypes(state, data) {
      state.experienceContractTypes = data;
    },
    storeExperienceLocationTypes(state, data) {
      state.experienceLocationTypes = data;
    },
    storeMinorityGroups(state, data) {
      state.minorityGroups = data;
    },
    storeAgeGroups(state, data) {
      state.ageGroups = data;
    },

  },
  actions: {
    getCandidateSkills({ commit }) {
      return new Promise(async (resolve) => {
        const list = await candidateSkillsApi.get();

        commit('storeCandidateSkills', list);
        resolve();
      });
    },
    getCandidateFocusAreas({ commit }) {
      return new Promise(async (resolve) => {
        const list = await candidateFocusAreasApi.get();

        commit('storeCandidateFocusAreas', list);
        resolve();
      });
    },
    getCandidateIntroductionTypes({ commit }) {
      return new Promise(async (resolve) => {
        const list = await CandidateMatchingPreferencesApi.getIntroductionTypes();
        commit('storeCandidateIntroductionTypes', list);
        resolve();
      });
    },
    getVolunteerProgramTypes({ commit }) {
      return new Promise(async (resolve) => {
        const list = await CandidateMatchingPreferencesApi.getVolunteerProgramTypes();
        commit('storeVolunteerProgramTypes', list);
        resolve();
      });
    },
    getVolunteerExperienceTypes({ commit }) {
      return new Promise(async (resolve) => {
        const list = await CandidateMatchingPreferencesApi.getVolunteerExperienceTypes();
        commit('storeVolunteerExperienceTypes', list);
        resolve();
      });
    },
    getBoardExperienceTypes({ commit }) {
      return new Promise(async (resolve) => {
        const list = await CandidateMatchingPreferencesApi.getBoardExperienceTypes();
        commit('storeBoardExperienceTypes', list);
        resolve();
      });
    },
    getEducationTypes({ commit }) {
      return new Promise(async (resolve) => {
        const list = await CandidateQualificationsApi.getEducationTypes();
        commit('storeEducationTypes', list);
        resolve();
      });
    },
    getExperienceContractTypes({ commit }) {
      return new Promise(async (resolve) => {
        const list = await CandidateQualificationsApi.getExperienceContractTypes();
        commit('storeExperienceContractTypes', list);
        resolve();
      });
    },
    getExperienceLocationTypes({ commit }) {
      return new Promise(async (resolve) => {
        const list = await CandidateQualificationsApi.getExperienceLocationTypes();
        commit('storeExperienceLocationTypes', list);
        resolve();
      });
    },
    getAgeGroups({ commit }) {
      return new Promise(async (resolve) => {
        const list = await candidateApi.getAgeGroups();
        commit('storeAgeGroups', list);
        resolve();
      });
    },
    getMinorityGroups({ commit }) {
      return new Promise(async (resolve) => {
        const list = await candidateApi.getMinorityGroups();
        commit('storeMinorityGroups', list);
        resolve();
      });
    },

    getCandidate({ commit }, id) {
      return new Promise(async (resolve) => {
        const res = await candidateApi.getCandidateByUserId(id);
        commit('storeCandidate', res);
        if (res.candidateMatchingPreferences != null) commit('storeCandidateMatchingPreferences', res.candidateMatchingPreferences);
        else {
          const filters = [{ name: 'UserId', values: [id] }];
          const search = createPaginationObject(null, null, filters);
          const prefsRes = await CandidateMatchingPreferencesApi.read(search);
          if (prefsRes != null && prefsRes.preferences != null) { commit('storeCandidateMatchingPreferences', prefsRes.preferences[0]); }
        }
        if (res.candidateQualifications != null) commit('storeCandidateQualifications', res.candidateQualifications);
        else {
          const filters = [{ name: 'UserId', values: [id] }];
          const search = createPaginationObject(null, null, filters);
          const qualRes = await CandidateQualificationsApi.read(search);
          if (qualRes != null && qualRes.preferences != null) { commit('storeCandidateQualifications', qualRes.preferences[0]); }
        }
        resolve();
      });
    },
    getCandidateOnboarding({ commit }, id) {
      return new Promise(async (resolve) => {
        const filters = [{ name: 'CandidateId', values: [id] }, { name: 'IsDisabled', values: [0] }];
        const search = createPaginationObject(null, null, filters);
        const onboarding = await candidateOnboardingApi.read(search);
        commit('storeCandidateOnboarding', onboarding);
        resolve();
      });
    },

    storeCandidate({ commit }, data) {
      return new Promise(async (resolve) => {
        commit('storeCandidate', data);
        resolve();
      });
    },
    saveOnboardingState({ commit }, status) {
      return new Promise(async (resolve) => {
        commit('storeOnboardingState', status);
        resolve();
      });
    },

  },
  getters: {
    findClaim: (state) => (claimName) => {
      const claim = state.permissions.find((r) => r.candidateClaim.name === claimName);
      return claim;
    },
    pullCandidate: (state) => state.candidate,
    onboardingCompleted: (state) => () => {
      const completed = state.onboarded;
      return completed;
    },
  },
};
