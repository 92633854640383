var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[(_vm.title)?_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_c('v-col',[_c('v-data-table',{staticStyle:{"width":"100%"},attrs:{"headers":_vm.headers,"items":_vm.applications,"footer-props":_vm.footerProps,"items-per-page":_vm.itemsPerPage,"loading":_vm.loading,"server-items-length":_vm.total,"dense":"","option":_vm.options},on:{"pagination":_vm.updatePagination,"update:options":_vm.updateOptions},scopedSlots:_vm._u([{key:"item.fullName",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"queries":_vm.search},on:{"click":function($event){return _vm.showCandidate(item.candidate.id, item.postingId)}}},[_vm._v(" "+_vm._s(item.candidate && item.candidate.user && item.candidate.user.userInformation ? item.candidate.user.userInformation.firstName + " " + item.candidate.user.userInformation.lastName : '-')+" ")])]}},{key:"item.charityName",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"queries":_vm.search},on:{"click":function($event){return _vm.showCharity(item.posting.charity.id)}}},[_vm._v(" "+_vm._s(item.posting && item.posting.charity ? item.posting.charity.name : '-')+" ")])]}},{key:"item.positionName",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"font-weight-bold",attrs:{"queries":_vm.search},on:{"click":function($event){return _vm.showCharityPosting(item.postingId)}}},[_vm._v(" "+_vm._s(item.posting && item.posting.charityPosition ? item.posting.charityPosition.name : "-")+" ")])]}},{key:"item.statusName",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":_vm.chipColor(item.applicationStatus),"text-color":"white"}},[_vm._v(" "+_vm._s(item.applicationStatus ? _vm.statusMask(item.applicationStatus.name) : "-")+" ")])]}},{key:"item.applicationDate",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(_vm.formatDate(item.applicationDate))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_vm._t("default",null,{"item":item})]}}],null,true)})],1)],1),_c('general-dialog',{ref:"postingSummaryDialog",attrs:{"dialog-width":"1280px","hide-submit":"","show-title":false}},[_c('posting-summary-widget',{staticClass:"pa-4",attrs:{"charity-posting":_vm.selectedPosting,"applied":true,"is-candidate-view":false}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }