<template>
  <v-row justify="center">
    <v-dialog
      v-model="profileDialog"
      fullscreen
      hide-overlay
    >
      <v-card
        fill-height
      >
        <v-container fluid>
          <v-row>
            <v-col class="text-h4">
              Privacy Policy
            </v-col>
            <v-col class="text-right">
              <v-btn
                icon
                @click="toggleDialog"
              >
                <v-icon large>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <p>Last Revised: July 2, 2008</p>
              <h3>
                Introduction
              </h3>
              <p>
                We at Altruvest Charitable Services (“ <span class="font-weight-bold">Altruvest”</span> , “<span class="font-weight-bold">we</span>”  or “<span class="font-weight-bold">us</span>”) understand the sensitivity with which your personal information should be handled. We are committed to safeguarding your privacy in order to earn and maintain your trust. We will use our best efforts to ensure that the information you submit to us is used only for the purposes set forth herein or as otherwise communicated to you at the time you provide us with personal information.
              </p>
              <h3>
                Application
              </h3>
              <p>
                This privacy policy applies to all personally identifying information (i.e. personal information by which you can be identified) which you provide to us through the web site located at <a
                  target="_blank"
                  href="https://altruvest.org/"
                >www.altruvest.org</a>  (including <a
                  target="_blank"
                  href="http://boardmatch2.altruvest.org/"
                >www.boardmatch.org</a>) (the “<span class="font-weight-bold">Site</span>” ) or otherwise, including all personal information which you provide to us in connection with BoardMatch® Fundamentals, BoardMatch Leaders or BoardWorx Executive Director (each, a “<span class="font-weight-bold">Program</span>”) described on the Site. The Programs are operated in Toronto by Altruvest, and certain of the Programs are operated in other cities by other organizations in association with Altruvest. Each of such other organizations have privacy policies which are substantially the same as this privacy policy, and each of such organizations is solely responsible for (and Altruvest has no responsibility for) the collection, use and disclosure of personal information by such organizations. The privacy policies of the organizations in other cities currently operating one or more Programs in association with Altruvest are posted on such organizations’ web sites at the following addresses:
              </p>
              <p>
                Volunteer Calgary: <a
                  href="http://www.volunteercalgary.ab.ca/privacy.html"
                  target="_blank"
                >http://www.volunteercalgary.ab.ca/privacy.html</a>
              </p>
              <p>
                Volunteer Thunder Bay: <a
                  href="http://www.volunteerthunderbay.ca/content.asp?pg=privacy"
                  target="_blank"
                >http://www.volunteerthunderbay.ca/content.asp?pg=privacy</a>
              </p>
              <h3>
                Consent
              </h3>
              <p>
                By using the Site and/or participating in a Program you are agreeing to the terms of this privacy policy and consenting to the collection, use and/or disclosure of your personal information for the purposes outlined herein. If you do not agree to the terms of this privacy policy you should discontinue your use of the Site and the Program and/or not provide us with any personal information.
              </p>
              <h3>
                Collection of Personal Information
              </h3>
              <p>Personal information is collected by specifically requesting it from you. We collect personal information from you in the following circumstances:</p>

              <ul>
                <li>
                  When you sign up for a Program you will be asked to provide us with certain personal information including: your name, mailing address, telephone(s) number and e-mail address and the types of charitable organizations in which you are interested;
                </li>
                <li>
                  When you register for the Candidate Orientation and online learning modules described on the Site, we require basic personal information including name, telephone number(s), mailing address and e-mail address; and
                </li>
                <li>
                  In connection with a Program, prior to applying to open positions, you are required to submit your education and work experience, fax number, previous and current information related to volunteerism, your preference for the type of organization for which you are interested in submitting your application and your optional consent to permit participating organizations to review your background in order to invite you to interview for a board position.
                </li>
              </ul>
              <p>
                We also collect demographic information relevant to the Site and users’ interest.
              </p>
              <p>
                By collecting this information, we hope to deliver to you the best possible Site experience and to make the Programs effective for you. However, you always have the choice of whether to provide us with any information.  Unfortunately, in some instances, if you do not provide us with certain information, we will be unable to permit you to participate in a particular Program.
              </p>

              <h3>Use and Disclosure</h3>
              <p>When personal information is collected from you, it may be used for one or more of the following purposes:</p>
              <ul>
                <li>
                  Disclosure to one or more of the charitable organizations which participate in a Program;
                </li>
                <li>
                  Contacting you on behalf of one of the charitable organizations which participate in a Program;
                </li>
                <li>
                  If applicable, to provide your mentor in a Program with your contact information and other information to assist them in mentoring you;
                </li>
                <li>
                  With your consent, posting your profile for viewing on the Site by charitable organizations participating in a Program for the purpose of searching for individuals with the requisite skills required on their boards;
                </li>
                <li>
                  Reporting aggregate numbers for Site usage, numbers registered, numbers ‘matched’, and other related aggregated information and providing such reports to sponsors and funders, and evaluating the Programs outcomes and performance measures (i.e. demographic);
                </li>
                <li>
                  In circumstances where an employer is sponsoring an employee to participate in a Program, the employee’s progress may be reported to the employer;
                </li>
                <li>
                  Contacting you for feedback and surveying your needs in connection with your use of the Site; and
                </li>
                <li>
                  contacting you about new initiatives at Altruvest, including opportunities to support and/or volunteer with Altruvest.
                </li>
              </ul>
              <p>Your personal information will not be used by us and we will not authorize any third party to use your personal information other than in accordance with the terms of this privacy policy.</p>
              <p>Except as described in this privacy policy, we will not disclose your personal information to third parties without first obtaining your consent to do so.</p>
              <p>We employ other companies and individuals to perform services on our behalf related to the Site and may be required to disclose your personal information to them, solely for the purposes of performing services on our behalf related to the site. Such services include the hosting of the Site and e-Learning programs.</p>
              <h3>Removal of or Changes to Information</h3>
              <p>You are allowed to change, correct or remove your personal information from our database at any time. If, at any time, you wish to have your personal information removed from our database, if you wish to opt out of receiving any particular communication and/or if you wish to change and/or correct any information which you have previously provided to us, please contact us and we will make the necessary notation, removal, change and/or correction.  However, due to technical constraints and the fact that Altruvest and/or its website host back up its systems, personal information may continue to reside in the computer systems after deletion.  Individuals, therefore, should not expect that their personal information would be completely removed from its system in response to a request for deletion.  Our contact particulars are set out below.</p>
              <h3>Cookies</h3>
              <p>A “cookie” is a small piece of data that is sent to your browser from a web server and stored on your computer’s hard drive. Cookies do not damage your system or impair its functioning in any way. We use cookies to tailor your experience at the Site, to show you content that you might be interested in, and to display the content according to your preferences. Cookies do not contain any personally identifying information. You can choose whether to accept cookies by changing the settings of your browser. You can reset your browser to refuse all cookies or to allow your browser to show you when a cookie is sent. However, if you choose not to accept cookies some part of the Programs may not function properly.</p>
              <h3>System Information</h3>
              <p>We collect system information from all visitors to the Site. Your system information (IP address, browser version and resolution) will allow us to enhance the functionality that is provided to you.</p>
              <h3>Security</h3>
              <p>We maintain security measures in place in order to attempt to protect against the loss, misuse and alteration of the personal information under our control. We take reasonable precautions to attempt to ensure that data stored in our system is secure. Please be advised, however, that the Internet and other various networking communication media are not entirely secure, unless explicitly specified as such, and your information may therefore be subject to interception or loss which is beyond our control. The information and data exchanged or transmitted via the Site is not encrypted and therefore the security, integrity and privacy of any and all such data and information cannot be guaranteed.</p>
              <h3>Children Guidelines</h3>
              <p>Neither this Site nor any of the Programs is intended for children. We will not knowingly collect information from or about children. We believe that children should get their parents’ consent before giving out personal information.</p>
              <h3>Changes to this Privacy Policy</h3>
              <p>We will periodically review the appropriateness of this privacy policy and may make, in our discretion, such changes as are necessary. If we decide to change this privacy policy, we will post those changes here so that you will always know what information we gather, how we might use that information, and whether we will disclose it to anyone.</p>
              <h3>Contact Us</h3>
              <p>If you wish to contact us regarding this privacy policy, please click on the following “Contact Us” link to obtain our contact information or e-mail us at <a href="mailto:information@altruvest.org">information@altruvest.org</a>. The name of our privacy officer is Norm King. We would be pleased to receive any feedback you may have in connection with this Site and/or the Programs.</p>
              <h3>Our Privacy Principles:</h3>
              <p>In formulating and adhering to this privacy policy, we are guided by the following principles:</p>
              <ol type="a">
                <li> <span class="font-weight-bold font-italic">Accountability</span>: We are responsible for personal information under our control and have designated one or more persons who are accountable for compliance with this privacy policy.</li>
                <li> <span class="font-weight-bold font-italic">Identifying Purposes for Collection of Personal Information</span>: We will identify the purposes for which personal information is collected at or before the time the informationis collected.</li>
                <li> <span class="font-weight-bold font-italic">Obtaining Consent for Collection, Use or Disclosure of Personal Information</span>: We will make a reasonable effort to ensure that you understand how your information is used and disclosed by us. We will not collect, use or disclose your information without your consent, except as permitted or required by law.</li>
                <li> <span class="font-weight-bold font-italic">Limiting Collection of Personal Information</span>: We will limit the amount and type of information we collect from you. We will only collect information from you as necessary for the purposes that we have identified. We will collect personal information by fair and lawful means.</li>
                <li> <span class="font-weight-bold font-italic">Limiting Use, Disclosure and Retention of Personal Information</span>: We will only use or disclose your information for the purposes for which it was originally collected, unless you give us your consent to use or disclose it for another purpose. However, under certain exceptional circumstances, we may be permitted or required by law to disclose or use your information without your consent.</li>
                <li> <span class="font-weight-bold font-italic">Accuracy of Personal Information</span>: We will strive to keep your information as accurate, complete, current and relevant as necessary. If you identify any errors in yourinformation, please contact us and we will correct the errors.</li>
                <li> <span class="font-weight-bold font-italic">Security Safeguards</span>: We will protect your information with safeguards that are appropriate to the sensitivity of the information.</li>
                <li> <span class="font-weight-bold font-italic">Openness Concerning Policies and Practises</span>: We are open about the policies and practises that we employ to manage your information, and will provide you with information about these policies and practises in a manner that is easy to understand.</li>
                <li> <span class="font-weight-bold font-italic">Access to Personal Information</span>: Upon request, we will advise you what information we have about you, what we are using it for, and to whom it has been disclosed or transferred for processing.</li>
                <li> <span class="font-weight-bold font-italic">Challenging Compliance</span>: We will act on any issues you may identify to us with respect to our compliance with this policy and/or our practises relating to the handling of your information.</li>
              </ol>
              <v-divider style="background-color: black;" />
              <v-col class="text-h4 pl-0">
                Terms and Conditions
              </v-col>
              <p>Please read the following carefully.</p>
              <p>Welcome to the Altruvest Charitable Services website (which includes www.altruvest.org and www.boardmatch.org (the “<span class="font-weight-bold">Site</span>” ), owned and operated by Altruvest Charitable Services (“<span class="font-weight-bold">We</span>”, “<span class="font-weight-bold">Our</span>”, “<span class="font-weight-bold">Altruvest</span>”, “<span class="font-weight-bold">BoardMatch</span>” or <span class="font-weight-bold">Us</span>) and the following are the Terms of Service (the “TOS”).  These TOS define the terms and conditions that you (“<span class="font-weight-bold">You</span>”, “<span class="font-weight-bold">Your</span>” or “<span class="font-weight-bold">User</span>”) agree to when You use and/or register with Us to make use of the Site and the Services.  Please read these terms and conditions carefully before using the Site and the Services.  BY CONTINUING TO USE THE SITE AND THE SERVICES, YOU ARE CONSENTING TO BE BOUND BY THESE TOS, AND ALL RELATED POLICIES AND GUIDELINES OF ALTRUVEST, INCLUDING THE <a href="">PRIVACY POLICY</a>, AS INDICATED IN THESE TOS ARE INCORPORATED BY REFERENCE.</p>
              <p>Altruvest reserves the exclusive right to revise, change or amend any portion of these TOS, or any other policies or guidelines governing the Site and the Services, at any time, in its sole discretion and without advance notice to You.  You agree to comply with all policies regarding permitted and prohibited uses of the Site and the Services that may be posted by Altruvest from time to time on the Site.  Any changes will be effective upon posting of the revisions on the Site.  Your use of the Site and the Services will be subject to the most current version of these TOS posted on the Site at the time of such use.  You may view the most current version of this policy <a href="">here</a>.  YOUR CONTINUED USE OF THE SITE AND THE SERVICES FOLLOWING OUR POSTING OF ANY CHANGES WILL CONSTITUTE YOUR ACCEPTANCE OF SUCH CHANGES OR MODIFICATIONS.  IF YOU DO NOT AGREE TO ANY CHANGES TO THESE TOS, PLEASE IMMEDIATELY DISCONTINUE USE OF THE SITE AND THE SERVICES.</p>
              <p>At the sole discretion of Altruvest, a User in violation of these TOS may have: (a) his or her account suspended immediately; (b) his or her activities in connection with the Site investigated, and/or; (c) his or her account terminated and/or, if appropriate, be subject to a civil action and/or criminal prosecution.</p>
              <h3>1.DEFINITIONS</h3>
              <p>In these TOS, capitalized terms shall have the meanings set out in this Section 1:</p>
              <v-row>
                <v-col>
                  <p class="ml-5">
                    “<span class="font-weight-bold">Claims</span>” means any claim, demand, liability, damage, loss, suit, action, or cause of action and all related costs and expenses, including legal fees, if any;
                  </p>
                  <p class="ml-5">
                    “<span class="font-weight-bold">Content</span>” includes information made available, displayed or transmitted in connection with the Site and the Services including, but not limited to, all trade-marks, domain names, information made available by means of an HTML “hyper link”, a third party posting or similar means and all updates, upgrades, modifications and other versions of any of the foregoing;
                  </p>
                  <p class="ml-5">
                    “<span class="font-weight-bold">Services</span>” means the volunteer board matching service, governance training sessions, peer learning circles and mentoring, subject to these TOS and any and other policies and procedures of Altruvest, provided to You by Altruvest;  and
                  </p>
                  <p class="ml-5">
                    “<span class="font-weight-bold">TOS</span>” means the terms and conditions contained in these terms of service in respect of use of the Site and the Services, as the same may be amended or replaced from time to time in accordance with the provisions of these TOS.
                  </p>
                </v-col>
              </v-row>
              <h3>2. INTERPRETATION</h3>
              <p>The provisions contained in these TOS are intended to be guidelines for You as it relates to acceptable behaviour and conduct in connection with Your use of the Site and the Services.  THESE PROVISIONS ARE NOT MEANT TO BE EXHAUSTIVE.  In general, any conduct that violates any laws and regulations, whether or not expressly mentioned in these TOS, is prohibited.  Altruvest reserves the exclusive right, at any and all times, to prohibit any of Your activities, in connection with Your use of the Site and the Services, that in any way damage the reputation and goodwill of Altruvest and any Altruvest-related initiatives and programs or Altruvest program co-operators.</p>
              <p class="ml-5">
                Unless otherwise specified, words importing the singular include the plural and vice versa and words importing gender include all genders.
              </p>
              <h3>3. SYSTEM AND NETWORK SECURITY</h3>
              <p>In connection with Your use of the Services, You agree not to engage in the following activities:</p>
              <ol type="a">
                <li>download, post, e-mail or otherwise transmit any content that is unlawful, harmful, threatening, abusive, harassing, tortuous, defamatory, vulgar, obscene, libellous, invasive of another's privacy, hateful, or racially, ethnically or otherwise objectionable;</li>
                <li>download, post, e-mail or otherwise transmit any content that You do not have a right to transmit under any law or under contractual or fiduciary relationships;</li>
                <li>download, post, e-mail or otherwise transmit any content that infringes, misappropriates, or otherwise violates any third party’s copyright, patent, trade-mark, or other proprietary right of publicity or privacy, or encourages or enables any other party to do so;</li>
                <li>use any content downloaded through the Site to post, e-mail or otherwise transmit any unsolicited or unauthorized advertising, promotional materials, "junk mail," "spam," "chain letters," "pyramid schemes," or any other form of solicitation;</li>
                <li>upload, post, e-mail or otherwise transmit any material to the Site that contains software viruses or any other computer code, files or programs designed to interrupt, destroy, alter, or limit the functionality of any computer software or hardware or telecommunications equipment;</li>
                <li>interfere with or disrupt the Site or servers or networks connected to the Site, including attempting to interfere with the access of any other user, host or network, including without limitation, overloading, engaging in “denial of service” attacks, “spamming”, “crashing”, or “mail-bombing” the Site or disobeying any requirements, procedures, policies or regulations of networks connected to the Site;</li>
                <li>use the Site for any public or commercial purposes, without the express prior written permission of Altruvest;</li>
                <li>modify, adapt, sublicense, translate, sell, reverse engineer, decompile or disassemble any portion of the Site;</li>
                <li>collect or store personal data about other users.</li>
                <li>tamper with accounts of other users, or committing unauthorized intrusions into any part of the Altruvest system or related systems;</li>
                <li>use accounts not provided or intended for use by You or circumventing security provisions in order to access the Site and/or the Services;</li>
              </ol>
              <h3>4. DISCLAIMER</h3>
              <p>The Site and all Services provided by Altruvest, is provided to You “as is” and “as available” without warranty or condition of any kind, either express or implied, including, but not limited to, the implied warranties and conditions of merchantability, fitness for a particular purpose, quiet enjoyment, or non-infringement.</p>
              <p>Altruvest makes no representations or warranties that: (i) the Site and the Services will meet Your requirements; (ii) the Site and the Services will be uninterrupted, timely, secure, or error-free; and (iii) the results that may be obtained from the use of the Site and the Services will be accurate or reliable.</p>
              <p>Altruvest is not responsible for any damages arising from Your use of the Site and any Services provided by Altruvest or Your inability to use the Site and any Services for any reason. </p>
              <h3>5. LIMITATION OF LIABILITY</h3>
              <p>You expressly understand and agree that in no event shall Altruvest, its directors, officers, employees, agents or advisors be liable to You for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses (even if Altruvest has been advised of the possibility of such damages under contract, warranty, negligence, strict liability or any other theory), resulting from: (i) the use or the inability to use the Services, the Site or any Content; (ii) any third party claims that the use by You of the Services, the Site or the Content violates any intellectual property right; or (iii) any other matters relating to the Service, the Site or the Content.  You specifically acknowledge that Altruvest shall have no liability to You for any Content downloaded from the Site and/or the Service and/or in connection with your use of the Site and/or the Service.</p>
              <h3>6. GENERAL</h3>
              <p>You agree to abide by the following general provisions:</p>
              <ol type="a">
                <li>
                  Altruvest reserves the right, in its sole discretion, to deactivate Your account(s) upon an indication that portions of these TOS are, in the sole and absolute discretion of Altruvest, being violated by You in any way.
                </li>
                <li>
                  Altruvest has no obligation to monitor Content or Your use of the Site and/or the Services.  However You agree that We have the right to monitor and investigate Your use of the Site and/or the Services electronically from time to time and to disclose any information as necessary to:
                  <ol type="i">
                    <li>satisfy any law, regulation or other governmental request or to assist in the pursuit of any legal action against You;</li>
                    <li>operate the Site and Services properly;</li>
                    <li>ensure or enforce compliance with these TOS and the Privacy Policy; or</li>
                    <li>protect Altruvest or our affiliates, program co-operators, sponsors and other users.</li>
                  </ol>
                </li>
                <li>Due to the public nature of the Internet, all information should be considered generally accessible to the public and important or private information should be treated carefully.  Except as specifically set out in the <a href="">Privacy Policy</a>, Altruvest is not liable for protection or privacy of Your e-mail or Your other information transferred through the Internet or any other network Altruvest or its other users may utilize.</li>
                <li>Your use of any Content obtained via the Site and the Services is at Your own risk.  Altruvest specifically denies any responsibility for the accuracy or quality of information obtained through the Site and the Services.</li>
                <li>If Altruvest cancels Your account(s) for any reason, You agree not to reregister with the Site without Altruvest’s prior written consent. In the event You reregister without Altruvest’s prior written consent, Altruvest will cancel Your account(s).</li>
                <li>Altruvest requires that You be at least eighteen (18) years of age to create an account and use our Services.  Any misrepresentation made by You in respect of any data and/or information supplied by You during the registration process will be grounds for Altruvest to terminate Your account.</li>
                <li>You agree to defend, hold harmless and indemnify Altruvest from any and all Claims (including, without limitation, reasonable legal fees) arising out of: (i) Your breach or violation of any covenant contained in these TOS; or (ii) Your use of the Site and/or the Services.</li>
                <li>You agree to waive and hold Altruvest harmless from any Claims relating to any action taken by Altruvest as part of an investigation into a suspected violation of these TOS or as a result of its conclusion that a violation of these TOS has occurred.</li>
                <li>We have a strong policy to respect and protect the privacy of its users and their information that is stored on Our servers.  This policy is available <a href="">here</a>.  Altruvest will only use and disclose information in accordance with the Privacy Policy.</li>
              </ol>
              <h3>7. POLICY ENFORCEMENT</h3>
              <p>When Altruvest becomes aware of any alleged violation of these TOS, Altruvest will immediately initiate an investigation.  Altruvest may, at its own discretion, anytime during an investigation, restrict Your access to the Site and/or Services in order to prevent any further possible unauthorized activity. Depending on the severity of the violation, Altruvest may, at its sole discretion, restrict, suspend, or terminate Your account and/or pursue other legal remedies.  If such violation is a criminal offence, Altruvest may promptly notify the appropriate law enforcement officials of such violation.  You hereby authorize Altruvest, its affiliates, suppliers and agents to cooperate with: (i) law enforcement authorities in the investigation of suspected criminal violations; and (ii) system administrators at other Internet service providers or other network or computing facilities in order to enforce these TOS.  Such cooperation may include Altruvest providing the username, IP address or other identifying information about a subscriber.</p>
              <p>Notwithstanding any of the above, Altruvest reserves the right, in its sole and absolute discretion, to terminate Your access to any Services, at any time.</p>
              <h3>8. CONTENT</h3>
              <p>All Services and the Site may be used for lawful purposes only.</p>
              <p>Altruvest will be the sole arbiter as to what constitutes a violation of this provision.</p>
              <h3>9. REPORTING ABUSE OF THIS POLICY</h3>
              <p>
                Any party seeking to report any violations of this policy may send an e-mail to: <a
                  target="_blank"
                  href="mailto:information@altruvest.org"
                >information@altruvest.org</a>
              </p>
              <h3>10. (a) APPLICABLE LAW</h3>
              <v-row>
                <v-col class="ml-8">
                  <p>You agree that all matters relating to the use of the Site, the Services and the Content shall be governed, controlled, interpreted and defined by and under the laws of the Province of Ontario and the federal laws of Canada applicable therein without giving effect to any principles of conflicts of laws.  You also agree that any action at law or in equity arising out of or relating to these TOS or Your use of the Site, the Services or the Content will be filed only in a court located in Toronto, Ontario, Canada and You hereby irrevocably and unconditionally consent and submit to the exclusive jurisdiction of such courts for the purpose of any such action.</p>
                  <h3>(b) ENTIRE AGREEMENT</h3>
                  <p>These TOS constitutes the entire agreement between You and Altruvest relating to the subject matter hereof and supersede any prior understandings or agreements (whether oral or written), claims, representations, and understandings of the parties regarding such subject matter.</p>
                  <h3>(c) NO AGENCY; THIRD-PARTY BENEFICIARY</h3>
                  <p>Altruvest is not Your agent, fiduciary, trustee, or other representative. Nothing expressed or mentioned in or implied from these TOS is intended or will be construed to give to any person other than the parties hereto any legal or equitable right, remedy, or claim under or in respect to these TOS.  These TOS and all of the representations, warranties, covenants, conditions, and provisions hereof are intended to be and are for the sole and exclusive benefit of Altruvest and You.</p>
                  <h3>(d) ASSIGNMENT</h3>
                  <p>You may not assign, convey, subcontract or delegate any of Your rights, duties or obligations hereunder.</p>
                  <h3>(e) SEVERABILITY</h3>
                  <p>If any provision of these TOS is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavour to give effect to the parties’ intentions as reflected in the provision, and the other provisions of these TOS remain in full force and effect.</p>
                  <h3>(f) LIMITATION</h3>
                  <p>You agree that regardless of any statute or law to the contrary, any Claim arising out of or related to use of the Site and/or the Services or these TOS must be filed within one (1) year after such claim or cause of action arose or be forever barred.</p>
                  <h3>(g) NO WAIVER</h3>
                  <p>Altruvest will not be considered to have waived any of its rights or remedies described in these TOS unless the waiver is in writing and signed by Altruvest.  No delay or omission by Altruvest in exercising its rights or remedies will impair or be construed as a waiver. Any single or partial exercise of a right or remedy will not preclude further exercise of any other right or remedy.  Altruvest’s failure to enforce the strict performance of any provision of these TOS herein will not constitute a waiver of Altruvest’s right to subsequently enforce such provision or any other provisions of these TOS.</p>
                  <h3>(h) HEADINGS</h3>
                  <p>The headings used in these TOS are included for convenience only and have no legal or contractual effect.</p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

export default {

  data: () => ({
    profileDialog: false,
  }),

  methods: {
    toggleDialog() {
      this.profileDialog = !this.profileDialog;
    },

  },
};
</script>

<style scoped>
h3{
  margin-bottom: 15px;
}
li{
  margin-bottom: 15px;
}
ul{
  margin-left: 15px;
  margin-bottom: 15px;
}
ol{
  margin-left: 15px;
  margin-bottom: 15px;
}
</style>
