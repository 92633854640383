<template>
  <v-row>
    <v-col>
      <v-card
        :class="{ 'grey lighten-2': dragover }"
        @drop.prevent="onDrop($event)"
        @dragover.prevent="dragover = true"
        @dragenter.prevent="dragover = true"
        @dragleave.prevent="dragover = false"
      >
        <v-card-text>
          <template v-if="uploading">
            <v-row>
              <v-col class="d-flex justify-center pb-0 mt-4">
                <v-progress-circular
                  color="gray"
                  :size="80"
                  :width="10"
                  :value="!uploadProgress || !uploadProgress.total || !uploadProgress.loaded ? 0 : uploadProgress.loaded / uploadProgress.total * 100"
                >
                  <span class="font-weight-bold">
                    {{ !uploadProgress || !uploadProgress.total || !uploadProgress.loaded ? 0 : uploadProgress.loaded / uploadProgress.total * 100 }}%
                  </span>
                </v-progress-circular>
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <v-img
              v-if="existingImagePreview != null && existingImagePreview.mimeType != null"
              :src="'data:' + existingImagePreview.mimeType + ';base64,' + existingImagePreview.file"
              :max-height="imagePreviewMaxHeight"
              contain
            />
            <a
              v-else-if="uploadedFiles.length == 0"
              @click="clicked()"
            >
              <v-row
                class="d-flex flex-column"
                dense
                align="center"
                justify="center"
              >
                <v-icon
                  :class="[dragover ? 'mt-2, mb-6' : 'mt-5']"
                  size="60"
                >
                  mdi-cloud-upload
                </v-icon>
                <p>
                  Drop your {{ isImage ? 'image' : '' }} file{{ multiple ? '(s)' : '' }} here, or click to select {{ multiple ? 'them' : 'it' }}.
                </p>
              </v-row>
            </a>
            <template v-else-if="returnFile == true">
              <v-img
                v-if="(multiple == false && isImage == true)"
                :src="imageUrl"
                :max-height="imagePreviewMaxHeight"
                contain
              />
              <v-virtual-scroll
                v-else
                :items="uploadedFiles"
                height="150"
                item-height="50"
              >
                <template #default="{ item }">
                  <v-list-item :key="item.name">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.name }}
                        <span class="ml-3 text--secondary">
                          {{ item.size }} bytes</span>
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-btn
                        icon
                        @click.stop="removeFile(item.name)"
                      >
                        <v-icon> mdi-close-circle </v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>

                  <v-divider />
                </template>
              </v-virtual-scroll>
            </template>
          </template>
        </v-card-text>
        <v-card-actions v-if="!suppressActions">
          <v-spacer />

          <v-btn
            icon
            @click="removeFile()"
          >
            <v-icon id="close-button">
              mdi-close
            </v-icon>
          </v-btn>

          <v-btn
            v-if="!hideUpload"
            icon
            @click="upload"
          >
            <v-icon id="upload-button">
              mdi-upload
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

export default {
  components: {
  },

  props:
  {
    multiple: {
      type: Boolean,
      default: false,
    },
    isImage: {
      type: Boolean,
      default: false,
    },
    imagePreviewMaxHeight: {
      type: Number,
      default: 400,
    },
    entityType: {
      type: String,
      default: 'user',
    },
    entityId: {
      type: Number,
      default: 0,
    },
    fileType: {
      type: String,
      default: 'resume',
    },
    suppressActions: {
      type: Boolean,
      default: false,
    },
    returnFile: {
      type: Boolean,
      default: false,
    },
    existingImage: {
      type: File,
      default: null,
    },
    hideUpload: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dragover: false,
    uploadedFiles: [],
    uploading: false,
    uploadProgress: null,
    supportedFileFormats: [
      {
        type: 'image',
        extension: 'jpg',
        mimeType: 'image/jpeg',
      },
      {
        type: 'image',
        extension: 'png',
        mimeType: 'image/png',
      },
      {
        type: 'file',
        extension: 'doc',
        mimeType: 'application/msword',
      },
      {
        type: 'file',
        extension: 'docx',
        mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      },
      {
        type: 'file',
        extension: 'pdf',
        mimeType: 'application/pdf',
      },
    ],
    tempFile: null,
    existingImagePreview: null,
  }),
  computed: {
    imageUrl() {
      let imageUrl = '';
      // if (this.existingImagePreview != null) imageUrl = URL.createObjectURL(this.existingImagePreview);
      if (this.uploadedFiles.length > 0) imageUrl = URL.createObjectURL(this.uploadedFiles[0]);
      return imageUrl;
    },
  },
  watch: {
    existingImage: {
      immediate: true,
      handler(value) {
        this.existingImagePreview = { ...value };
      },
    },
  },
  methods: {
    removeFile(fileName) {
      if (fileName == null) {
        this.uploadedFiles = [];
        this.existingImagePreview = null;
      } else {
        this.existingImagePreview = null;
        const index = this.uploadedFiles.findIndex(
          (file) => file.name === fileName,
        );
        // If file is in uploaded files remove it
        if (index > -1) this.uploadedFiles.splice(index, 1);
      }
    },
    onDrop(e) {
      this.dragover = false;
      // Check if there are already uploaded files
      if (this.uploadedFiles.length > 0) this.uploadedFiles = [];
      const files = Array.from(e.dataTransfer.files);
      this.processFiles(files);
    },
    clicked() {
      const input = document.createElement('input');
      input.type = 'file';
      input.onchange = () => {
        const files = Array.from(input.files);
        this.processFiles(files);
      };
      input.click();
    },
    processFiles(files) {
      const supportedFileFormats = this.isImage ? this.supportedFileFormats.filter((sff) => sff.type === 'image') : this.supportedFileFormats.filter((sff) => sff.type === 'file');
      let fileExtensions = '';
      let first = true;
      supportedFileFormats.forEach((sff, index, self) => {
        if (!first) {
          if (self.length === index + 1) fileExtensions += ` or .${sff.extension}`;
          else fileExtensions += `, .${sff.extension}`;
        } else {
          fileExtensions += `.${sff.extension}`;
        }
        first = false;
      });
      if (!this.multiple && files.length > 1) {
        this.$bus.$emit('showError', 'Can only upload one file at a time');
      } else if (files.findIndex((f) => supportedFileFormats.findIndex((sff) => sff.mimeType === f.type) === -1) > -1) {
        this.$bus.$emit('showError', `Only ${fileExtensions} files are supported`);
      } else {
        files.forEach((element) => this.uploadedFiles.push(element));
        if (this.returnFile) this.$emit('filesAttached', this.uploadedFiles);
        else this.upload();
      }
    },
    async upload() {
      this.uploading = true;
      let response = null;
      if (this.isImage && !this.multiple) {
        response = await this.$api.image.upload(this.entityType, this.entityId, this.fileType, this.uploadedFiles[0], (event) => {
          this.uploadProgress = event;
        });
      } else if (!this.isImage && !this.multiple) {
        response = await this.$api.file.upload(this.entityType, this.entityId, this.fileType, this.uploadedFiles[0], (event) => {
          this.uploadProgress = event;
        });
      }
      if (response === true || response.id != null) {
        this.$emit('success', response);
        this.$bus.$emit('showSuccess', `File${this.uploadedFiles.length > 1 ? 's' : ''} Uploaded`);
        this.uploadedFiles = [];
      } else this.$bus.$emit('showError', `Failed to upload File${this.uploadedFiles.length > 1 ? 's' : ''}`);
      this.uploading = false;
    },
    returnFiles() {
      return this.uploadedFiles;
    },
  },
};
</script>
<style>
</style>
