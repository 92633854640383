<template>
  <general-dialog
    ref="charityPositionDialog"
    :title="title"
    :title-icon="titleIcon"
    dialog-width="1100"
    :suppress-footer="false"
    @submit="submitPosition"
  >
    <charity-position-information-form
      ref="positionInfoForm"
      :position="position"
      :charity="charity"
      @position-success="notifySuccess"
    />
  </general-dialog>
</template>

<script>
import CharityPositionInformationForm from '@/components/charity/charityPosition/CharityPositionInformationForm';
import GeneralDialog from '@/components/general/GeneralDialog';

export default {
  components: {
    CharityPositionInformationForm,
    GeneralDialog,
  },
  props:
    {
      position: {
        type: Object,
        default: () => {},
      },
      title: {
        type: String,
        default: '',
      },
      charity: {
        type: Object,
        default: () => {},
      },
      titleIcon: {
        type: Array,
        default: () => [],
      },
    },

  data: () => ({
    positionDialog: false,
  }),

  methods: {
    openPositionDialog() {
      // this.positionDialog = true;
      this.$refs.charityPositionDialog.openForm();
    },
    closePositionDialog() {
      this.$refs.positionInfoForm.reset();
      this.positionDialog = false;
      this.$refs.charityPositionDialog.closeForm();
    },

    async submitPosition() {
      const validateResult = await Promise.all([
        this.$refs.positionInfoForm.validate(),
      ])
        .then((values) => !values.includes(false));

      if (!validateResult) return;
      this.$bus.$emit('loading', true);

      const res = await this.$refs.positionInfoForm.submit();
      this.$bus.$emit('loading', false);

      if (!res) {
        this.$bus.$emit('showError');
        return;
      }

      this.notifySuccess('Board member has been invited.');
      this.$refs.positionInfoForm.reset();
    },
    notifySuccess(msg) {
      // this.$refs.positionInfoForm.reset()
      this.closePositionDialog();
      this.$emit('position-success', msg);
    },

  },
};
</script>
