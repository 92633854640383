<template>
  <div>
    <v-row v-if="hideTitle == false">
      <v-col class="text-h5 font-weight-bold mb-6 mt-1 ml-3">
        Contact Information
      </v-col>
    </v-row>
    <user-information-form
      ref="userInformationForm"
      :user="user"
      :is-password-required="isPasswordRequired"
      :is-disabled="isDisabled"
      :hide-password="hidePassword"
      :show-password-reset="showPasswordReset"
    />
    <v-row>
      <v-col cols="6">
        <general-phone-form
          ref="generalPhoneForm"
          :phone="getPhone('Main')"
          :is-required="isPhoneRequired"
          :is-disabled="isDisabled"
        />
      </v-col>
      <v-col cols="6">
        <general-phone-form
          ref="eveningPhoneForm"
          :phone="getPhone('Evening')"
          :is-required="false"
          phone-type="evening"
          :is-disabled="false"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-h5 font-weight-bold mb-6 mt-1 ml-3">
        Residence Address
      </v-col>
    </v-row>
    <general-address-form
      ref="generalAddressForm"
      :address="user && user.userInformation ? user.userInformation.address : null"
      :is-required="isAddressRequired"
      :is-disabled="isDisabled"
    />
  </div>
</template>

<script>
import UserInformationForm from '@/components/user/UserInformationForm.vue';
import GeneralPhoneForm from '@/components/general/GeneralPhoneForm';
import GeneralAddressForm from '@/components/general/GeneralAddressForm';

export default {
  components: {
    UserInformationForm,
    GeneralPhoneForm,
    GeneralAddressForm,
  },
  props:
    {
      user: {
        type: Object,
        default: () => {},
      },
      isPasswordRequired: {
        type: Boolean,
        default: false,
      },
      isPhoneRequired: {
        type: Boolean,
        default: false,
      },
      showPasswordReset: {
        type: Boolean,
        default: true,
      },
      isAddressRequired: {
        type: Boolean,
        default: false,
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
      hidePassword: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
        default: 'Login Information',
      },
      hideTitle: {
        type: Boolean,
        default: false,
      },
    },

  data: () => ({
  }),

  methods: {
    async submit() {
      const result = {
        success: false,
        completed: false,
      };
      result.completed = await this.validate();

      if (!result.completed) return result;
      this.$bus.$emit('loading', true);

      const userResponse = await this.$refs.userInformationForm.submit();
      if (!userResponse) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading', false);
        return result;
      }

      const addressResponse = await this.$refs.generalAddressForm.submit(null, userResponse.userInformation.id);
      const phoneResponse = await this.$refs.generalPhoneForm.submit(null, userResponse.userInformation.id);
      const phone2Response = await this.$refs.eveningPhoneForm.submit(null, userResponse.userInformation.id);

      if (!addressResponse && !phoneResponse && !phone2Response) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading', false);
        return false;
      }
      if (this.user && this.user.id) {
        this.$emit('user-success', 'User Updated');
      } else {
        this.$emit('user-success', 'User Created');
      }
      this.$bus.$emit('loading', false);
      result.success = true;
      return true;
    },
    async validate() {
      const validateComponentsResult = await Promise.all([
        this.$refs.userInformationForm.validate(),
        this.$refs.generalAddressForm.validate(),
        this.$refs.generalPhoneForm.validate()])
        .then((values) => !values.includes(false));

      return validateComponentsResult;
    },
    getPhone(type) {
      if (!this.user || !this.user.userInformation || !this.user.userInformation.phones || this.user.userInformation.phones.length === 0) return null;

      return this.user.userInformation.phones.find((x) => x.phoneType.name.toLowerCase() === type.toLowerCase());
    },
  },
};
</script>
