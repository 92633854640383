<template>
  <v-row
    style="min-width: 1279px; min-height: 650px;"
    class="pa-0"
  >
    <v-col
      class="pb-0"
      cols="6"
    >
      <div style="height: 100%; display: grid; margin-bottom: 0px !important; padding-bottom: 0px !important;">
        <v-row>
          <v-col class="text-left mt-8 ml-3">
            <a
              href="https://altruvest.org/"
            >
              <img
                :src="require('@/assets/Altruvest Full Logo - Blue Text.svg')"
                width="290px"
              >
            </a>
          </v-col>
        </v-row>
        <v-form
          style="margin-top: -0px; height: 200px;"
          @submit.prevent="login"
        >
          <v-card
            class="elevation-0"
            style="margin-top: -80px;"
          >
            <v-card-text>
              <v-row>
                <!-- <v-col
                  class="pb-0"
                  cols="3"
                /> -->
                <v-col cols="9">
                  <span
                    class="black--text text-h5"
                    style="font-weight: bold;"
                  >Sign In</span>
                </v-col>
              </v-row>
              <v-row style="margin-top: 3%;">
                <!-- <v-col
                  class="pb-0"
                  cols="3"
                /> -->
                <v-col
                  class="pb-0"
                >
                  <span class="text-h6">Username (Email Address)</span>
                  <v-text-field
                    v-model="userName"
                    name="login"
                    filled
                    class="custom-filled"
                    type="text"
                  />
                  <v-row>
                    <v-col class="pt-0">
                      <span class="text-h6">Password</span>
                    </v-col>
                  </v-row>
                  <v-text-field
                    id="password"
                    v-model="password"
                    name="password"
                    type="password"
                    class="custom-filled"
                    filled
                  />
                </v-col>
                <v-col
                  class="pb-0"
                  cols="1"
                />
              </v-row>
              <v-row>
                <!-- <v-col
                  class="pa-0"
                  cols="3"
                /> -->
                <v-col
                  cols="4"
                  class="pt-0"
                >
                  <a
                    class="text-h6 justify-end font-weight-light"
                    @click="openForgotPasswordDialog"
                  >Forgot Password?</a>
                </v-col>
                <v-col
                  class="pa-0"
                  cols="2"
                />
                <v-col
                  class="pt-0"
                  cols="6"
                >
                  <v-btn
                    type="submit"
                    color="success"
                    x-large
                    class="custom-button"
                    elevation="0"
                    width="82%"
                    style="text-transform: unset !important; "
                  >
                    Sign In
                  </v-btn>
                </v-col>
                <!-- <v-col
                  class="pa-0"
                  cols="1"
                /> -->
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>
        <!-- <v-row style="max-height: 10px !important;">
          <v-col cols="12">
            <v-divider
              inset
              style="margin-left: 17%;"
            />
          </v-col>
        </v-row> -->
        <div>
          <v-row>
            <v-col
              cols="12"
              class="black--text text-h5 ml-3 mb-2"
              style="font-weight: bold;"
            >
              Not A Member?
            </v-col>
            <v-col
              class="pa-0"
              cols="1"
            />
            <v-col
              cols="5"
              class="d-flex justify-center pa-0"
            >
              <v-btn
                type="submit"
                color="primary"
                large
                class="custom-button"
                elevation="0"
                outlined
                style="text-transform: unset !important; "
                @click="registerCandidate()"
              >
                Join as Candidate
              </v-btn>
            </v-col>
            <v-col
              cols="5"
              class="d-flex justify-left pa-0"
            >
              <v-btn
                type="submit"
                color="primary"
                large
                class="custom-button"
                elevation="0"
                outlined
                style="text-transform: unset !important; "
                @click="registerCharity()"
              >
                Join as Charity
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-col>
    <v-col
      cols="6"
      class="pa-0"
    >
      <v-carousel
        cycle
        :interval="999000"
        height="100vh"
        class="pa-0 ma-0 custom-carousel"
        hide-delimiter-background
        :show-arrows="false"
        style="height: 100vh; max-height: 100%; z-index: 2; min-height: 650px;"
      >
        <v-carousel-item
          v-for="item in carouselItems"
          :key="item.id"
          class="pa-0 ma-0"
          style="height: 100%; max-height: 100%;"
        >
          <v-img
            :src="require(`@/assets/${item.backgroundImage}`)"
            class=" pa-0 ma-0 fill-height carousel-background"
            contain
            style="height: 90%; max-height: 90%; z-index: 5; min-height: 500px;"
          />
          <v-row
            no-gutters
            style="margin-top: -200px;"
          >
            <v-col cols="2" />
            <v-col
              class="d-flex align-end pb-10"
              cols="10"
            >
              <v-row class="text-center pl-8 pr-8">
                <v-col>
                  <div class="carousel-content-div">
                    <span class="text-h4 white--text">{{ item.title }}</span>
                    <br>
                    <br>
                    <span class="white--text">{{ item.description }}</span>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-carousel-item>
      </v-carousel>
      <div
        style="position: absolute; left: 59%; top: 0px; width: 100vw; height: 100%; margin: 0px; padding: 0px; z-index: 0;min-height: 650px; "
        class="primary"
      />
      <div style="position: absolute; bottom: 20px; left: 20px;">
        <v-row
          class="d-flex align-bottom ma-0"
        >
          <v-col class="d-flex align-end pa-0 caption">
            <div>
              <a
                class="blue--text text--lighten-2 mb-1 ml-1 mr-1"
                @click="$refs.programOverviewForm.toggleDialog()"
              >
                Program Overview
              </a> -
              <a
                class="blue--text text--lighten-2 mb-1 ml-1 mr-1"
                @click="$refs.legalForm.toggleDialog()"
              >
                Legal
              </a> -
              <a
                class="blue--text text--lighten-2 mb-1 ml-1 mr-1"
                @click="$refs.contactUsForm.toggleDialog()"
              >
                Contact Us
              </a> -
              <a
                class="blue--text text--lighten-2 mb-1 ml-1 mr-1"
                @click="$refs.faqForm.toggleDialog()"
              >
                Boardmatch FAQs
              </a>-
              <span class="mb-1 ml-1 mr-1 grey--text">&copy; {{ year }}, </span><a
                class="blue--text text--lighten-2 mb-1 ml-1 mr-1"
                target="_blank"
                href="https://altruvest.org/"
                style="text-decoration: none;"
              >Altruvest</a><br>

              <span class="mb-1 ml-1 mr-1 grey--text">Altruvest Charitable Registration #: 892814161RR0001</span>
              -
              <span class="mb-1 ml-1 mr-1 grey--text">Powered By</span> <a
                class="blue--text text--lighten-2"
                target="_blank"
                href="https://www.balanced.plus/"
                style="text-decoration: none;"
              >BALANCED+</a>
            </div>
          </v-col>
        </v-row>
      </div>
      <!-- <div
        style="position: absolute; right: 10px; top: 20px; width: 50%; display: grid; justify-content: end; z-index: 10"
      >
        <v-row
          style="width: 500px;"
        >
          <v-col
            class="d-flex justify-end"
          >
            <div>
              <span class="text-h6 white--text font-weight-regular">Not A Member?</span>
            </div>
          </v-col>
          <v-col>
            <a
              class="text-h6 orange--text text--lighten-1 font-weight-regular"
              @click="registerCandidate()"
            >
              Join as Candidate
            </a><br>
            <a
              class="text-h6 orange--text text--lighten-1 font-weight-regular"
              @click="registerCharity()"
            >
              Join as Charity
            </a>
          </v-col>
        </v-row>
      </div> -->
      <candidate-registration-dialog
        ref="candidateRegistrationDialog"
      />
      <charity-registration-dialog ref="charityRegistrationDialog" />
      <general-dialog
        ref="charityInvitationDialog"
        :title="`Charity Representative Registration`"
        submit-button-label="Register"
        dialog-width="800px"
        @submit="inviteRepresentative"
      >
        <charity-rep-invitation-registration-form
          v-if="invitation"
          ref="charityInvitationForm"
          :charity-name="invitation.charityName"
          :chariable-number="invitation.charitableNumber"
          :user-name="invitation.userName"
          :first-name="invitation.firstName"
          :last-name="invitation.lastName"
          @closeForm="closeInvitaionDialog"
          @verify="verifyUser"
        />
      </general-dialog>
      <Snackbar
        ref="snackbar"
      />
      <general-dialog
        ref="userVerificationDialog"
        :title="`Your account is not verified`"
        dialog-width="650px"
        :suppress-footer="true"
      >
        <user-verification-form
          ref="userVerificationForm"
          :user-name="userName"
          @closeForm="closeVerificationDialog"
        />
      </general-dialog>
      <user-forgot-password-dialog
        ref="userForgotPasswordDialog"
        :login-user-name="userName"
        @forgot-password-success="forgotPasswordSuccess"
      />
      <program-overview-form ref="programOverviewForm" />
      <contact-us-form ref="contactUsForm" />
      <legal-page-form ref="legalForm" />
      <GeneralFAQDialog ref="faqForm" />
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapState } from 'vuex';
import Snackbar from '@/components/app/TheSnackbar';
import UserForgotPasswordDialog from '@/components/user/UserForgotPasswordDialog';
import ProgramOverviewForm from '@/components/infoForms/ProgramOverviewForm';
import ContactUsForm from '@/components/infoForms/ContactUsForm';
import LegalPageForm from '@/components/infoForms/LegalPageForm';
import CandidateRegistrationDialog from '@/components/candidate/CandidateRegistrationDialog';
import CharityRegistrationDialog from '@/components/charity/CharityRegistrationDialog';
import CharityRepInvitationRegistrationForm from '@/components/charity/charityRep/CharityRepInvitationRegistrationForm';
import GeneralFAQDialog from '@/components/infoForms/GeneralFAQDialog';
import GeneralDialog from '@/components/general/GeneralDialog';
import UserVerificationForm from '@/components/user/UserVerificationForm';

export default {
  name: 'Login',
  components: {
    Snackbar,
    UserForgotPasswordDialog,
    ProgramOverviewForm,
    ContactUsForm,
    LegalPageForm,
    CandidateRegistrationDialog,
    CharityRegistrationDialog,
    GeneralFAQDialog,
    UserVerificationForm,
    GeneralDialog,
    CharityRepInvitationRegistrationForm,
  },
  data: () => ({
    userName: '',
    password: '',
    token: null,
    invitation: null,
  }),
  computed: {
    year() {
      const year = moment().format('YYYY');
      return year;
    },
    ...mapState({
      isLoggedIn: (state) => state.auth.isLoggedIn,
      userState: (state) => state.user.userState,
    }),
    ...mapGetters({
      getBaseUrl: 'user/getBaseUrl',
    }),
    carouselItems() {
      return [
        {
          id: 1,
          backgroundImage: 'login_background-1.svg',
          title: 'Why We Need Charities',
          description: 'Giving to charity is a selfless act. There’s no financial return. Generosity for its own sake creates a more compassionate, community-focused world. Modeling the value of generosity and selflessness to children makes it more likely they will continue to give as adults. This creates lasting change and a legacy of kindness.',
        },
        {
          id: 2,
          backgroundImage: 'login_background-2.svg',
          title: 'Charities Benefit Our Communities',
          description: 'When people come together, they can make a big impact. Charities depend on individuals who commit to giving their time and support. While donations from big groups are great, a community that cares about a charity and who they serve is essential. It’s those small donations that add up. The power of community proves that when people care and come together, change is more than possible. This inspires others around the world and encourages more participation in charities.',
        },
        {
          id: 3,
          backgroundImage: 'login_background-3.svg',
          title: 'How Philanthropy Helps You',
          description: 'Charity is about giving without expecting anything in return, but donors get intangible benefits. Helping others creates feelings of peace, pride, and purpose. These feelings translate into a more fulfilled life. When people experience this positivity, they’re more likely to continue giving and participating in other ways, as well. The world is a better place when people have a purpose.',
        },
        {
          id: 4,
          backgroundImage: 'login_background-4.svg',
          title: 'Sign Up As A Charity, Candidate Or Corporation.',
          description: 'When people come together, they can make a big impact. Charities depend on individuals who commit to giving small amounts. While donations from big groups are great, a community that cares about a charity and who they serve is essential. It’s those small donations that add up. The power of community proves that when people care and come together, change is more than possible. This inspires others around the world and encourages more participation in charities.',
        },
      ];
    },
  },
  mounted() {
    if (this.$route.query && this.$route.query.token && !this.$route.query.regusr) this.verifyUser();
    else if (this.$route.query && this.$route.query.regusr
    && this.$route.query.char
    && this.$route.query.token
    && this.$route.query.charnum
    && this.$route.query.lname && this.$route.query.fname) this.registerInvitation();
    else if (this.$route.query && this.$route.query.openCandidateReg) {
      this.registerCandidate();
    }
    const regMsg = this.$store.state.auth.regMessage;
    if (regMsg === '') return;
    this.$bus.$emit('showSuccess', regMsg);
    this.$store.commit('auth/deleteRegMessage');
  },
  created() {
    if (this.isLoggedIn) {
      let baseUrl = this.userState ? this.userState.lastState : null;
      if (!baseUrl) baseUrl = this.getBaseUrl;
      if (this.$route.path !== baseUrl) this.$router.push(`${baseUrl}`);
    }
  },
  methods: {
    login() {
      const { userName } = this;
      const { password } = this;
      this.$store
        .dispatch('auth/login', { userName, password })
        .then()
        .catch((err) => {
          if (err.message.includes('verification')) this.$refs.userVerificationDialog.openForm();
          else this.$refs.snackbar.showError(err.message);
        });
    },
    openForgotPasswordDialog() {
      this.$refs.userForgotPasswordDialog.openDialog();
    },
    closeVerificationDialog() {
      this.$refs.userVerificationDialog.closeForm();
    },
    closeInvitaionDialog() {
      this.$refs.charityInvitationDialog.closeForm();
    },
    forgotPasswordSuccess() {
      const message = 'Password reset link has been sent to your email';
      this.$refs.snackbar.showSuccess(message);
    },
    registerCandidate() {
      this.$refs.candidateRegistrationDialog.openForm();
    },
    registerCharity() {
      this.$refs.charityRegistrationDialog.openForm();
    },
    registerInvitation() {
      this.invitation = {
        userName: this.$route.query.regusr,
        charityName: this.$route.query.char,
        charitableNumber: this.$route.query.charnum,
        firstName: this.$route.query.fname,
        lastName: this.$route.query.lname,
        token: this.$route.query.token,
      };
      this.$refs.charityInvitationDialog.openForm();
    },
    inviteRepresentative() {
      this.$refs.charityInvitationForm.submit();
    },
    async verifyUser() {
      // open dialog
      // this.$refs.verificationDialog.openForm();
      this.token = this.$route.query.token;
      // const query = { ...this.$route.query };
      // delete query.token;
      // this.$router.replace({ query });
      const res = await this.$api.auth.verification(this.token);
      if (!res) this.$refs.snackbar.showError('Unable to verify your account.');
      else this.$refs.snackbar.showSuccess('Account has been verified. Please sign in using your username and password.');
    },
  },
};
</script>
<style>
  .custom-text-field {
    border-radius: 8px;
  }
  .custom-button {
    border-radius: 8px;
    font-size: 20px !important;
    font-weight: 400 !important;
  }
  .custom-text-field.v-text-field div.v-input__control div.v-input__slot::before {
    width: 0px !important;
  }
  .custom-text-field.v-text-field div.v-input__control div.v-input__slot::after {
    width: 0px !important;
  }
  .blue-background {
    background-color: rgb(10,102,194)
  }
   .carousel-background.theme--light div.v-image__image {
    z-index: 2;
    height: 65%;
    margin-top: 30px;
   }
  .v-image.v-responsive.pa-0.ma-0.fill-height.carousel-background div.v-responsive__sizer {
    /* for some reason if we don't do this, the carousel expands and adds a scroll bar. */
    padding-bottom: 0% !important;
  }
  .carousel-content-div {
    height: 260px;
    z-index: 6;
    position:absolute;
    margin-right: 35px;
  }
  .custom-carousel.v-carousel div.v-item-group {
    margin-left: 15%
  }
  div.custom-filled.v-text-field--filled div.v-input__control div.v-input__slot {
    background: rgb(246, 248, 255) !important;
  }
</style>
