/* eslint-disable consistent-return */
/* eslint-disable no-async-promise-executor */
/* eslint-disable no-param-reassign */
import ThinkificCoursesApi from '@/api/thinkific/ThinkificCoursesApi';

export default {
  namespaced: true,

  state: () => ({
    types: [],
  }),
  mutations: {

    storeTypes(state, list) {
      state.types = list;
    },

    clearData(state) {
      state.types = [];
    },

  },
  actions: {
    getTypes({ commit }) {
      return new Promise(async (resolve) => {
        const list = await ThinkificCoursesApi.getTypes();

        commit('storeTypes', list);
        resolve();
      });
    },
  },
  getters: {

  },
};
