<template>
  <v-container
    fluid
    class="px-0 mx-0 pa-12 pb-2"
  >
    <v-row>
      <v-col>
        <v-tabs
          v-model="isDisabledTab"
          center-active
          class="hahaha"
        >
          <v-tab>Active</v-tab>
          <v-tab>Inactive</v-tab>
        </v-tabs>
      </v-col>
      <v-col class="text-end">
        <v-btn
          color="primary"
          outlined
          @click="openNewPositionDialog()"
        >
          <v-icon
            small
            class="mr-1"
          >
            mdi-plus
          </v-icon>
          New Position
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="dataHeaders"
          :items="positions"
          :footer-props="dataPerPageProps"
          :items-per-page="dataPerPage"
          :loading="loading"
          dense
          :option="options"
          @update:options="updateOptions"
          @pagination="updatePagination"
        >
          <template #[`item.name`]="{ item }">
            {{ item.name }}
          </template>
          <template #[`item.isNotBoard`]="{ item }">
            <v-chip
              small
              :color="chipColor(item.isNotBoard)"
              text-color="white"
            >
              {{
                item.isNotBoard
                  ? "NOT BOARD MEMBER"
                  : "BOARD MEMBER" }}
            </v-chip>
          </template>
          <template #[`item.actions`]="{ item }">
            <a
              v-if="!item.isDefault"
              class="mr-2"
              @click="openUpdateForm(item)"
            > EDIT </a>
            <a
              v-if="isDisabledTab"
              class="mr-2"
              @click="deactivate(item)"
            > ACTIVATE </a>
            <a
              v-else
              class="mr-2"
              @click="deactivate(item)"
            > DEACTIVATE </a>
            <a
              class="mr-2"
              @click="remove(item)"
            > DELETE </a>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-slide-y-transition>
      <v-container v-show="showForm">
        <zigzag />
        <v-row>
          <v-col>
            <span class="text-h6">
              {{ formTitle }}
            </span>
          </v-col>
        </v-row>
        <validation-observer ref="positionValObserver">
          <v-row>
            <v-col cols="4">
              <validation-provider
                v-slot="{ errors }"
                name="Position Name"
                rules="required"
              >
                <v-text-field
                  v-model="position.name"
                  required
                  :error-messages="errors"
                >
                  <template #label>
                    Position Name<red-asterisk />
                  </template>
                </v-text-field>
              </validation-provider>
            </v-col>
            <v-col
              cols="4"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Is Board"
                rules="required"
              >
                <v-checkbox
                  v-model="position.isNotBoard"
                  :label="`This is not a Board Position`"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              class="text-end"
            >
              <v-btn
                class="mr-4"
                text
                @click="closeForm"
              >
                cancel
              </v-btn>
              <v-btn
                class="mr-4"
                type="submit"
                color="success"
                @click="submit"
              >
                submit
              </v-btn>
            </v-col>
          </v-row>
        </validation-observer>
      </v-container>
    </v-slide-y-transition>
    <general-dialog
      ref="newPositionDialog"
      title="New Position"
      dialog-width="500px"
      @submit="submitNewPosition"
    >
      <charity-new-position-form
        ref="newPositionForm"
        :charity="charity"
        @closeNewPositionDialog="closeNewPositionDialog"
      />
    </general-dialog>
  </v-container>
</template>

<script>
/* eslint-disable eqeqeq */
import {
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from 'vee-validate';
import { mapState } from 'vuex';
import { createPaginationObject } from '@/helpers/PaginationHelper.js';

import CharityNewPositionForm from '@/components/charity/charityPosition/CharityNewPositionForm.vue';
import GeneralDialog from '@/components/general/GeneralDialog';

setInteractionMode('eager');

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    CharityNewPositionForm,
    GeneralDialog,

  },
  data: () => ({
    positions: [],
    dataPerPageProps: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    props: {
      search: {
        default: '',
        type: String,
      },
      title: {
        default: '',
        type: String,
      },
      filters: {
        default: null,
        type: Array,
      },
    },
    dataPerPage: 20,
    loading: true,
    formTitle: 'Create Position',
    position: {
      id: 0,
      name: '',
      isDefault: 0,
      isDeleted: 0,
    },
    showForm: false,
    isDisabledTab: false,
    options: null,
    total: 0,

  }),
  computed: {
    dataHeaders() {
      const headers = [];

      headers.push({ text: 'Name', value: 'name' });
      headers.push({ text: 'Position Type', value: 'isNotBoard' });
      headers.push({ text: 'Actions', value: 'actions', align: 'right' });
      return headers;
    },
    ...mapState({
      charity: (state) => state.charity.charity,
    }),
    filters() {
      const returnFilters = [];

      if (this.charity && this.charity.id) {
        returnFilters.push({ name: 'charityId', values: [this.charity.id.toString()] });
      }

      const isDeactivated = this.isDisabledTab;
      returnFilters.push({ name: 'isDisabled', values: [isDeactivated] });

      return returnFilters;
    },
  },
  watch: {
    search(value) {
      if (value) {
        this.getPositions();
      }
    },
    options(value) {
      if (value) {
        this.getPositions();
      }
    },
    filters(value) {
      if (value) {
        this.getPositions();
      }
    },
    isDisabledTab(value) {
      this.getPositions(value);
    },
  },
  created() {
    if (!this.charity) return;
    this.init();
  },
  methods: {
    init() {
      // this.getPositions()
    },
    chipColor(isNotBoard, status = null) {
      if (status !== null) {
        return status ? 'green' : '';
      }
      return isNotBoard ? 'orange' : 'purple';
    },
    async getPositions() {
      this.loading = true;
      const res = await this.$api.charityPositions.read(createPaginationObject(this.options, this.search, this.filters));
      this.positions = res.positions;
      this.total = res.total;
      // let parameters = [];
      // parameters.push({name: 'isDisabled', value: isDeactivated});
      // this.positions = await this.$api.charityPositions.get(`${this.charity.id}`, parameters)
      this.loading = false;
    },
    openNewPositionDialog() {
      this.$refs.newPositionDialog.openForm();
    },
    openUpdateForm(position) {
      this.formTitle = `Update Position ' ${position.name} '`;
      this.position = JSON.parse(JSON.stringify(position));
      this.showForm = true;
    },

    async submit() {
      if (!await this.$refs.positionValObserver.validate()) return;
      this.position.charityId = this.charity.id;
      try {
        this.$bus.$emit('loading', true);
        const res = this.position.id !== 0
          ? await this.$api.charityPositions.update(this.position)
          : await this.$api.charityPositions.create(this.position);

        this.$bus.$emit('loading', false);
        if (!res) {
          this.$bus.$emit('showError');
          return;
        }

        if (this.position && this.position.id) {
          this.$bus.$emit('showSuccess', 'Position Updated');
        } else {
          this.$bus.$emit('showSuccess', 'Position Created');
        }
        this.getPositions();
        this.closeForm();
      } catch (err) {
        console.log(err);
      }
    },
    submitNewPosition() {
      this.$refs.newPositionForm.submitNewPosition();
      this.getPositions();
    },
    closeForm() {
      this.showForm = false;
    },
    closeNewPositionDialog() {
      this.$refs.newPositionDialog.closeForm();
    },
    async deactivate(position) {
      if (position == null || this.charity == null || this.charity.id == null) {
        this.$bus.$emit('showError');
        return;
      }
      if (!confirm(`Are you sure you want to ${this.isDisabledTab == true ? 'activate' : 'deactivate'} this position?`)) return;
      const res = await this.$api.charityPositions.get(`disable/${position.id}/${this.charity.id}`);
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }

      this.$bus.$emit('showSuccess', `Position ${this.isDisabledTab == true ? 'Activated' : 'Deactivated'}`);
      this.getPositions(this.isDisabledTab ? 1 : 0);
    },
    async remove(position) {
      if (position == null || this.charity == null || this.charity.id == null) {
        this.$bus.$emit('showError');
        return;
      }

      if (!confirm('Are you sure you want to delete this position?')) return;
      const res = await this.$api.charityPositions.softDelete(`${position.id}/${this.charity.id}`);
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }

      this.$bus.$emit('showSuccess', 'Position Deleted');
      this.getPositions(this.isDisabledTab ? 1 : 0);
    },
    updatePagination(pagination) {
      this.pagination = pagination;
    },
    updateOptions(options) {
      this.options = options;
    },

  },
};
</script>
