/* eslint-disable consistent-return */
/* eslint-disable no-async-promise-executor */
/* eslint-disable no-param-reassign */
import CountryApi from '@/api/generals/CountryApi';
import RegionApi from '@/api/generals/RegionApi';
import PhoneTypeApi from '@/api/generals/PhoneTypeApi';
import SocialMediaApi from '@/api/generals/SocialMediaApi';
import FileApi from '@/api/generals/FileApi';
import UserApi from '@/api/users/UserApi';
import BoardmatchRepRolesApi from '@/api/boardmatchReps/BoardmatchRepRolesApi';

export default {
  namespaced: true,

  state: () => ({
    countries: [],
    regions: [],
    pronouns: [],
    phoneTypes: [],
    socialMediaTypes: [],
    filePrivacyTypes: [],
    boardMatchRepRoles: [],
  }),
  mutations: {

    storeCountries(state, list) {
      state.countries = list;
    },

    storeRegions(state, list) {
      state.regions = list;
    },
    storePronoun(state, list) {
      state.pronouns = list;
    },
    storePhoneTypes(state, list) {
      state.phoneTypes = list;
    },
    storeSocialMediaTypes(state, data) {
      state.socialMediaTypes = data;
    },
    storeFilePrivacyTypes(state, data) {
      state.filePrivacyTypes = data;
    },
    storeHideWelcome(state, hideWelcome) {
      state.hideWelcome = hideWelcome;
    },
    storeBoardMatchRepRoles(state, list) {
      state.boardMatchRepRoles = list;
    },
    clearData(state) {
      state.countries = [];
      state.regions = [];
      state.pronouns = [];
      state.phoneTypes = [];
      state.socialMediaTypes = [];
      state.filePrivacyTypes = [];
      state.boardMatchRepRoles = [];
    },

  },
  actions: {
    getCountries({ commit }) {
      return new Promise(async (resolve) => {
        const list = await CountryApi.get();

        commit('storeCountries', list);
        resolve();
      });
    },
    getRegion({ commit }) {
      return new Promise(async (resolve) => {
        const list = await RegionApi.get();

        commit('storeRegions', list);
        resolve();
      });
    },
    getBoardMatchRoles({ commit }) {
      return new Promise(async (resolve) => {
        const res = await BoardmatchRepRolesApi.get();
        if (res) commit('storeBoardMatchRepRoles', res);

        resolve();
      });
    },
    getPronoun({ commit }) {
      return new Promise(async (resolve) => {
        const list = await UserApi.getPronoun();

        commit('storePronoun', list);
        resolve();
      });
    },
    getPhoneTypes({ commit }) {
      return new Promise(async (resolve) => {
        const list = await PhoneTypeApi.get();

        commit('storePhoneTypes', list);
        resolve();
      });
    },
    getSocialMediaTypes({ commit }) {
      return new Promise(async (resolve) => {
        const list = await SocialMediaApi.getTypes();
        commit('storeSocialMediaTypes', list);
        resolve();
      });
    },
    getFilePrivacyTypes({ commit }) {
      return new Promise(async (resolve) => {
        const list = await FileApi.getPrivacyTypes();
        commit('storeFilePrivacyTypes', list);
        resolve();
      });
    },
  },
  getters: {
  },
};
