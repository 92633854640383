<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      fullscreen
      hide-overlay
    >
      <v-card
        fill-height
      >
        <v-container fluid>
          <v-row>
            <v-col class="text-right">
              <v-btn
                icon
                @click="closeForm"
              >
                <v-icon large>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-tabs
            v-model="FAQTab"
            center-active
          >
            <v-tab>Candidate FAQ</v-tab>
            <v-tab>Charity FAQ</v-tab>
          </v-tabs>
          <CandidateFAQForm v-if="FAQTab == 0" />
          <CharityFAQForm v-else-if="FAQTab == 1" />
          <v-row>
            <v-col
              class="text-end"
            >
              <v-btn
                class="mr-4"
                text
                @click="closeForm"
              >
                cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import CandidateFAQForm from '@/components/infoForms/CandidateFAQForm';
import CharityFAQForm from '@/components/infoForms/CharityFAQForm';

export default {
  components: {
    CandidateFAQForm,
    CharityFAQForm,
  },

  data: () => ({
    showDialog: false,
    FAQTab: 0,
  }),
  methods: {
    toggleDialog() {
      this.showDialog = !this.showDialog;
    },
    closeForm() {
      this.showDialog = false;
    },
  },
};
</script>
