import axios from 'axios';
import Api from '../Api';

class FileApi extends Api {
  constructor() {
    super('files', 'files');
  }

  async upload(entityType, entityId, fileType, file, onUploadProgress) {
    // eslint-disable-next-line prefer-const
    let formData = new FormData();
    formData.append('FileContent', file);

    let errorText = 'Could not upload file';
    let res;
    try {
      res = await axios.post(`${this.endpoint}/upload/${entityType}/${entityId}/${fileType}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress,
      });
      if (res.status === 200) {
        res = res.data;
      } else if (res.status === 204) {
        errorText = 'Could not upload file';
        console.error(errorText);
      } else {
        console.error(errorText);
        res = false;
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = false;
    }
    return res;
  }

  async getPrivacyTypes() {
    const res = await this.get('privacy-types');
    return res;
  }

  async getFile(fileId, fileName) {
    let res;
    let errorText = 'Could not fetch office list';
    try {
      res = await axios
        .get(`${this.endpoint}/download/${fileId}`, {
          responseType: 'blob',
        })
        .then((response) => {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(new Blob([response.data]));
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          return response;
        });
      if (res.status === 200) {
        res = res.data;
      } else if (res.status === 204) {
        errorText = 'unable to export';
        console.error(errorText);
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
    }
    return res;
  }
}

const fileApi = new FileApi();
export default fileApi;
