<template>
  <v-container
    fluid
    style="background-color: white;border-radius: 8px;"
  >
    <v-row class="pa-3">
      <v-col>
        <h1 class="text-left mb-3">
          FAQs for Charities
        </h1>
        <p>
          <span class="font-weight-bold">PLEASE NOTE:</span> <a
            target="_blank"
            href="https://altruvest.org/BoardMatch/Content/Downloads/Agency%20Help%20Manual.pdf"
          >Click here</a> to view the Help Manual for Voluntary Sector Charities in its entirety. The Help Manual may also be downloaded from the Downloads link at the top of this page.
        </p>
        <h4 class="mb-3">
          How to use the BoardMatch® Fundamentals Canada System:
        </h4>
        <div class="ml-4">
          <p class="mb-0">
            1. <a @click="goto('howToQ1')">How can we register with BoardMatch Fundamentals Canada?</a>
          </p>
          <p class="mb-0">
            2. <a @click="goto('howToQ2')">How does an Charity list more than one Board position?</a>
          </p>
          <p class="mb-0">
            3. <a @click="goto('howToQ3')">Where does one find the online orientation on the website?</a>
          </p>
          <p class="mb-0">
            4. <a @click="goto('howToQ4')">Is the on line registration system fully compatible with Macintosh computers?</a>
          </p>
          <p class="mb-0">
            5. <a @click="goto('howToQ5')">If I have forgotten my password how do I track it down? </a>
          </p>
          <p class="mb-0">
            6. <a @click="goto('howToQ6')">Why won't the site accept my email address?</a>
          </p>
          <p class="mb-0">
            7. <a @click="goto('howToQ7')">How I can retrieve a resume that you are sending to our Charity?</a>
          </p>
          <p class="mb-0">
            8. <a @click="goto('howToQ8')">How can I search candidate profiles/resumes and invite them for an interview?</a>
          </p>
          <p class="mb-0">
            9. <a @click="goto('howToQ9')">If we are not interested in receiving automatic ‘board candidate notifications’ from BoardMatch Fundamentals, on behalf of candidates whose skills and interests may meet our needs, how do we disable this feature?</a>
          </p>
          <p class="mb-0">
            10. <a @click="goto('howToQ10')">When updating my Charity's entry how can I add skills that are not part of the checklist?</a>
          </p>
          <p class="mb-0">
            11. <a @click="goto('howToQ11')">Is there a print option available for the online learning?</a>
          </p>
          <p class="mb-0">
            12. <a @click="goto('howToQ12')">How do I edit, add, or delete board positions?</a>
          </p>
          <p class="mb-0">
            13. <a @click="goto('howToQ13')">How will a candidate know that his/her application has been forwarded by the system to the charitable Charity?</a>
          </p>
          <p class="mb-0">
            14. <a @click="goto('howToQ14')">What do we do if a candidate does not respond to our emails and phone calls?</a>
          </p>
          <p class="mb-0">
            15. <a @click="goto('howToQ15')">What do we do if we do not wish to receive any more applications while we review the current applications?</a>
          </p>
          <p class="mb-0">
            16. <a @click="goto('howToQ16')">How do I go about updating the information about our charity on the site?</a>
          </p>
          <p class="mb-0">
            17. <a @click="goto('howToQ17')">How do we indicate that a candidate has voluntarily withdrawn his/her application?</a>
          </p>
          <p class="mb-0">
            18. <a @click="goto('howToQ18')">How can we indicate that we have offered a candidate a committee position instead of a board position?</a>
          </p>
          <p class="mb-0">
            19. <a @click="goto('howToQ19')">What should we do if we get a Server error?</a>
          </p>
          <p>
            20. <a @click="goto('howToQ20')">Who can I turn to for support if I have questions about the BoardMatch Fundamentals Canada Service?</a>
          </p>
        </div>

        <h4 class="mb-3">
          How to use the BoardMatch® Fundamentals Canada System:
        </h4>
        <div class="ml-4">
          <p class="mb-0">
            1. <a @click="goto('whatIsQ1')">What is BoardMatch Fundamentals Canada?</a>
          </p>
          <p class="mb-0">
            2. <a @click="goto('whatIsQ2')">Who qualifies for BoardMatch Fundamentals?</a>
          </p>
          <p class="mb-0">
            3. <a @click="goto('whatIsQ3')">What orientation does BoardMatch Fundamentals provide to community agencies?</a>
          </p>
          <p class="mb-0">
            4. <a @click="goto('whatIsQ4')">What orientation does BoardMatch Fundamentals provide to Board Member candidates?</a>
          </p>
          <p class="mb-0">
            5. <a @click="goto('whatIsQ5')">What expectations do candidates have?</a>
          </p>
          <p class="mb-0">
            6. <a @click="goto('whatIsQ6')">What qualifications do board member candidates possess?</a>
          </p>
          <p class="mb-0">
            7. <a @click="goto('whatIsQ7')">Why should we recruit board members through BoardMatch Fundamentals?</a>
          </p>
          <p>
            8. <a @click="goto('whatIsQ8')">Which other agencies participate in BoardMatch Fundamentals?</a>
          </p>
        </div>

        <h3 class="text-center mb-3">
          How to use the BoardMatch Fundamentals System
        </h3>
        <h4 id="#howToQ1">
          Q1: How can we register with BoardMatch Fundamentals Canada?
        </h4>
        <h4>
          A:
        </h4>
        <p class="ml-4">
          <ul>
            <li>
              The entire registration process including the required BoardMatch Fundamentals Canada Voluntary Sector Charity Orientation online, takes 2 hours and can be split into several sessions if you prefer.
            </li>
            <li>
              Go to the BoardMatch Fundamentals Canada Website at <a href="https://altruvest.org/">www.altruvest.org</a>  or <a href="http://boardmatch2.altruvest.org/">www.boardmatch.org</a>.
            </li>
            <li>
              Click on "Login/Sign-Up" button on the menu bar at the top right side of the screen, then click on the "Sign up" button for charity/not-for-profit, at the top right section of the next page.
            </li>
            <li>
              Complete the brief Registration Form by providing the required information.
            </li>
            <li>
              Complete the Charity Details and Position Matching Information pages
            </li>
            <li>
              All users from a participating Charity must use the same e-mail and password (Charity logins) to access the BoardMatch Fundamentals Canada Website. The system cannot presently handle multiple user e-mails for the same Charity.
            </li>
            <p><span class="font-weight-bold">Recommendation:</span> We recommend against using a shared/general delivery email address since e-mails usually contain information about board candidates applying to your agency and are time sensitive. In case your agency only has shared/general delivery e-mail addresses, then please inform the person(s) opening these e-mails to ensure that emails are directed to the designated officer(s) immediately.</p>
            <li>
              Complete the BoardMatch Fundamentals Canada Voluntary Sector Charity Orientation session online or at a live session in select locations. If you have taken the live session, you do not have to take it again online, unless you want to review it. Please send an e-mail to the local BoardMatch Fundamentals office (see <a @click="$refs.contactUsForm.toggleDialog()">Contact Us</a>) indicating the date and location of the session, and we will update your profile).
            </li>
            <li>
              Complete the Registration Form page.
            </li>
            <li>
              Post board member requirements, after completing your registration, by clicking on 'Create New Posting' button on your charity portal/home page. You can also close any board positions that you fill by clicking on the 'View' button for the associated posting to bring you to teh posting details page. From here you can click "Close" and your posting will be closed. If your entire board is full, you can close all positions by closing each one individually. By closing positions, you will ensure that you do not receive applications for positions you have already filled.
            </li>
            <li>
              Once you have completed all the steps listed above, you will start receiving applications from candidates. Some agencies start receiving candidates in 1 week whereas others may take 2 months. Since board candidates decide which agencies to apply to, response times can vary greatly. You may also search candidate profiles/resumes and invite them to interview with you, if so inclined. You will also receive optional ‘board candidate notifications’ making you aware of new board candidates with skills, interests and experience that may align with your board posting and whom you may wish to invite to interview with your agency.
            </li>
            <li>
              If you do not receive any applications from BoardMatch Fundamentals within 2 months, do review your agency's information (as posted on the BoardMatch Fundamentals Canada Website) and see if it can be modified to make it more appealing to professionals from the business community and individuals from the broader community.
            </li>
          </ul>
        </p>
        <p>The entire registration process takes about 2 hours and can be broken up into <a href="">these stages:</a> </p>
        <h4 id="#howToQ2">
          Q2: How does an Charity list more than one Board position?
        </h4>
        <h4>
          A:
        </h4>
        <p class="ml-4">
          <ul>
            <li>
              For each posting, go to your Charity Portal (access Charity Portal/Home Page by clicking on Login, and enter e-mail and password).
            </li>
            <li>
              Click on "Create New Posting" at the bottom of your portal.
            </li>
            <li>
              Select the position title and check off the skills required against the position.
            </li>
            <li>
              We recommend that you post a different posting for each board position (there is no charge on a per posting basis). Please post a separate board position for each position/skill set you require. A single posting with too many skills tends to scare away board candidates since they may think that they are expected to have all the skills you have listed rather than only some of them.
            </li>
            <li>
              Click save to confirm your selection
            </li>
          </ul>
        </p>
        <h4 id="#howToQ3">
          Q3: Where does one find the online orientation on the website?
        </h4>
        <h4>
          A:
        </h4>
        <p class="ml-4">
          <ol>
            <li>
              Go to the website: <a href="http://boardmatch2.altruvest.org/">www.boardmatch.org</a>
            </li>
            <li>
              Login to your Charity portal.
            </li>
            <li>
              Click on the "Orientation" icon
            </li>
          </ol>
        </p>
        <h4 id="#howToQ4">
          Q4: Is the on line registration system fully compatible with Macintosh computers?
        </h4>
        <p>
          <span class="font-weight-bold">A:</span> There should be no problem with the Macintosh or any other system as the program is web-based.  If problems occur, please report them by email to: <a href="mailto:technicalsupport@altruvest.org">technicalsupport@altruvest.org</a> The minimum system requirement is Windows 98 or higher with Internet Explorer 4.0 or higher web browser.
        </p>
        <h4 id="#howToQ5">
          Q5: If I have forgotten my password how do I track it down?
        </h4>
        <p>
          <span class="font-weight-bold">A:</span> Click on the "Forgot your password?" link on the login page. Enter your email address and click the "Reset Password" button. An email will be sent to you with your new password.
        </p>
        <h4 id="#howToQ6">
          Q6: Why won't the site accept my email address?
        </h4>
        <p>
          <span class="font-weight-bold">A:</span> In some cases the system does not accept complex email addresses (i.e. long ones with punctuation marks in between words). Call the local BoardMatch Fundamentals Canada office (see <a @click="$refs.contactUsForm.toggleDialog()">Contact Us</a>) and the Administrator will input this for you.
        </p>
        <h4 id="#howToQ7">
          Q7: How I can retrieve a resume that you are sending to our Charity?
        </h4>
        <p>
          <span class="font-weight-bold">A:</span> Go to the Website: <a href="https://altruvest.org/">www.altruvest.org</a>  or <a href="http://boardmatch2.altruvest.org/">www.boardmatch.org</a>.
        </p>
        <p class="ml-4">
          <ol>
            <li>
              Login to your Charity Portal
            </li>
            <li>
              Click the "View" link next to the position in question.
            </li>
            <li>
              Click the "View Details" link next to the applicant whose resume you whish to view.
            </li>
          </ol>
        </p>
        <h4 id="#howToQ8">
          Q8: How can I search candidate profiles/resumes and invite them for an interview?
        </h4>
        <p>
          <span class="font-weight-bold">A:</span> This functionality is only available after you post at least one board position
        </p>
        <p class="ml-4">
          <ul>
            <li>
              Login to your Charity Portal, where you hcave posted one or more board positions.
            </li>
            <li>
              Click the "View" link next to the position you wish to search for.
            </li>
            <li>
              Under “Candidate Search” insert desired parameters, then click ‘search’ and review results that appear by clicking on ‘View’
            </li>
            <li>
              If interested in interviewing this candidate, click ‘Invite for Interview’ button. Candidate’s name will appear under “Applicants/Invitations”
            </li>
            <li>
              As matters progress with the candidate, click the "Change Status" link next to the desired applicant.
            </li>
            <li>
              Click the "Select" link next to the interview step.
            </li>
            <li>
              Scroll to the bottom of the page and click the "Save" button to submit your changes.
            </li>
          </ul>
        </p>
        <h4 id="#howToQ9">
          Q9: If we are not interested in receiving automatic ‘board candidate notifications’ from BoardMatch Fundamentals, on behalf of candidates whose skills and interests may meet our needs, how do we disable this feature?
        </h4>
        <p>
          <span class="font-weight-bold">A:</span> You can withdraw from the automated 'board candidate notifications' at any time by doing the following:
        </p>
        <p class="ml-4">
          <ul>
            <li>
              Login to your Charity Portal/Home Page.
            </li>
            <li>
              Click the 'position matching information' registration step.
            </li>
            <li>
              Scroll down and check the box 'I no longer wish to participate in the automated matching service'.
            </li>
            <li>
              Scroll to the bottom of the page and click the "Submit" button to submit your changes.
            </li>
            <li>
              You will no longer receive automatic notifications.
            </li>
          </ul>
        </p>
        <h4 id="#howToQ10">
          Q10: When updating my Charity's entry how can I add skills that are not part of the checklist?
        </h4>
        <p>
          <span class="font-weight-bold">A:</span> You may add required skills in the box "List a Requirement".
        </p>
        <p class="ml-4">
          <ul>
            <li>
              We recommend that you not list any special requirements unless they are essential. When candidates see special requirements listed that they do not meet, they will not apply for that position.
            </li>
          </ul>
        </p>
        <h4 id="#howToQ11">
          Q11: Is there a print option available for the online learning?
        </h4>
        <p>
          <span class="font-weight-bold">A:</span> Yes, the entire module can be printed or you may choose to print one page at a time. To print the entire module click "Printable Version (Entire Module)" at the bottom of the online training page. The entire module will open in a new window, Click on "Ctrl" and "P" at the same time, or click on "File," then "Print" on your browser (Internet Explorer, Netscape, AOL, etc.). To print a single page at a time click on "Print Page" at the bottom of the page, a print dialog will automatically pop up, click yes to print.
        </p>
        <h4 id="#howToQ12">
          Q12: How do I add, edit, or delete board positions?
        </h4>
        <p>
          <span class="font-weight-bold">A:</span> To Add a position:
        </p>
        <p class="ml-4">
          <ol>
            <li>
              On the Charity portal under the section "Postings", click on the "Create New Posting".
            </li>
            <li>
              Go to the drop down list of Board Positions and choose accordingly.
            </li>
            <li>
              Tick off the skills required for that position.
            </li>
            <li>
              Click Save
            </li>
          </ol>
        </p>
        <p>
          We recommend that you post a different posting for each board position. Please post a separate board position for each position/skill set you require. A single posting with too many skills tends to scare away board candidates since they may think that they are expected to have all the skills you have listed rather than only some of them.
        </p>
        <p>
          To Edit a position:
        </p>
        <p class="ml-4">
          <ol>
            <li>
              On the Charity portal under the section "Postings", click on the "View" button next to the Board position,
            </li>
            <li>
              Go to the drop down list of Board Positions and choose accordingly.
            </li>
            <li>
              Tick off the skills required for that position.
            </li>
            <li>
              Click Save
            </li>
          </ol>
        </p>
        <p>
          To Delete/Close a board position:
        </p>
        <p class="ml-4">
          <ul>
            <li>
              If you fill a position with a candidate that was sent to you by BoardMatch Fundamentals, we ask that instead of deleting the position, you inform BoardMatch Fundamentals by changing the candidate’s status to “Offer Extended”
            </li>
            <li>
              In case you have been independently informed by the candidate that he/she has accepted your offer, i.e. verbally or by email, please inform BoardMatch Fundamentals. BoardMatch Fundamentals will update the status accordingly. Note that you cannot change the candidate’s status to ”accept.” This option is only available to the candidate and BoardMatch Fundamentals.
            </li>
            <li>
              If special circumstances warrant that the candidate not be offered the position until formal nomination at your agency's AGM at a later date, click on ”offer extended” and add a note indicating this in the “Custom Message” Section. Inform BoardMatch Fundamentals by separate email as well since the system will not automatically notify us.
            </li>
            <li>
              If you fill a position with a candidate that was not referred to you by BoardMatch Fundamentals Canada, you should close the position by taking the actions below.
            </li>
            <li>
              Login to your Charity Portal.
            </li>
            <li>
              Go to the Edit a Posting page, and click on the "Close" button.
            </li>
          </ul>
        </p>
        <h4 id="#howToQ13">
          Q13: How will a candidate know that his/her application has been forwarded by the system to the charitable Charity?
        </h4>
        <p>
          <span class="font-weight-bold">A:</span> The system will inform the candidate that his/her application has been successfully submitted to the agency. On the candidate's portal, the status will indicate "Applied" against the name of your agency."
        </p>
        <h4 id="#howToQ14">
          Q14: What do we do if a candidate does not respond to our emails and phone calls?
        </h4>
        <p>
          <span class="font-weight-bold">A:</span> If a candidate does not respond to your e-mails, please call him/her at least twice. If you still do not receive a response from him/her, he/she is probably no longer interested in being on the board of your Charity. This may happen if you have taken longer than 2 weeks to respond to their application, or occasionally, a candidate will change their mind after applying, and may become unresponsive. In both cases, they should ideally have withdrawn their application to your Charity. In case they have not withdrawn their application, you have every right to decline the candidate. From the posting details page click on "Change Status" for the candidate you wish to decline. In the pop up select a "Decline" option, enter any additional information to the outgoing message and click "Save".
        </p>
        <h4 id="#howToQ15">
          Q15: What do we do if we do not wish to receive any more applications while we review the current applications?
        </h4>
        <p>
          <span class="font-weight-bold">A:</span> Suspend a board posting temporarily when you have received a number of candidate applications. This allows you the opportunity to focus on the current applications before considering more. You will still be able to view and track the current applications.
        </p>
        <p class="ml-4">
          <ol>
            <li>
              Close the posting, see Close a Posting
            </li>
          </ol>
        </p>
        <h4 id="#howToQ16">
          Q16: How do I go about updating the information about our charitable Charity on the site?
        </h4>
        <p class="ml-4">
          <ol>
            <li>
              Login to your Charity Portal
            </li>
            <li>
              Click on the "Charity Details" link.
            </li>
            <li>
              Click on each item you want to change, and when finished click "Submit"
            </li>
          </ol>
        </p>
        <h4 id="#howToQ17">
          Q17: How do we indicate that a candidate has voluntarily withdrawn his/her application?
        </h4>
        <p>
          <span class="font-weight-bold">A:</span> You can go to your organization’s portal and change the candidate’s status to “Decline,” by selecting the “Decline-After Interview” message. Please be sure to inform the local BoardMatch Fundamentals Canada office by e-mail or phone (see <a @click="$refs.contactUsForm.toggleDialog()">Contact Us</a> ) of the candidate’s decision so that we can follow up with him/her. The system does not automatically copy us on the message you choose to send the candidate.
        </p>
        <h4 id="#howToQ18">
          Q18: How can we indicate that we have offered a candidate a committee position instead of a board position?
        </h4>
        <p>
          <span class="font-weight-bold">A:</span> BoardMatch Fundamentals candidates are oriented to the roles, responsibilities, duties and liabilities of a board member, and through the matching process, come to expect that any offer will be at a board level. As an Charity we are primarily mandated to place candidates on boards of directors versus board committees or as program volunteers.  When creating a new board posting, select ‘sub-committee member’ from the drop-down list and proceed with balance of posting information.
        </p>
        <h4 id="#howToQ19">
          Q19: What should we do if we get a Server error?
        </h4>
        <p>
          <span class="font-weight-bold">A:</span> Try again in an hour, or the next day. If the problem recurs then, please report the error to BoardMatch Fundamentals Canada by going to the <a @click="$refs.contactUsForm.toggleDialog()">contact us</a> page.
        </p>
        <h4 id="#howToQ20">
          Q20: What should we do if we get a Server error?
        </h4>
        <p>
          <span class="font-weight-bold">A:</span>
        </p>
        <p class="ml-4">
          <ul>
            <li>
              If you have any questions, we recommend you look through this list of Frequently Asked Questions (FAQs).
            </li>
            <li>
              If you cannot find answers to your questions in the list of FAQs, then please access our BoardMatch Fundamentals Canada Help Manual for Voluntary Sector Charities via the 'Downloads' link at the top or the web page. Failing that, please feel free to contact the local BoardMatch Fundamentals Canada office (see <a @click="$refs.contactUsForm.toggleDialog()">Contact Us</a>).
            </li>
          </ul>
        </p>
        <h4 class="text-center">
          What is BoardMatch Fundamentals Canada?
        </h4>
        <h4 id="#whatIsQ1">
          Q1: What is BoardMatch Fundamentals Canada?
        </h4>
        <p>
          <span class="font-weight-bold">A:</span>
        </p>
        <p class="ml-4">
          <ul>
            <li>
              BoardMatch Fundamentals is a service available to registered charities in the Greater Toronto Area. The service is available to both charities and not-for-profit Charities in Calgary, Fredericton, Greater Vancouver, and Thunder Bay. It links charitable Charities with highly motivated and informed volunteers from diverse backgrounds who are willing to serve in a governance role on their boards of directors.
            </li>
            <li>
              Recruiting activities target community-minded individuals with backgrounds in finance and accounting, law, human resources, marketing, operational management, sales, etc.
            </li>
            <li>
              We have placed more than 2400 volunteers on the boards of more than 650 charitable Charities in the Greater Toronto Area, Greater Vancouver, Calgaru and Thunder Bay over the last 8 years
            </li>
            <li>
              BoardMatch Fundamentals is a program of Altruvest Charitable Services, a registered charity based in Mississauga, Ontario.
            </li>
            <li>
              The program is funded by foundations (e.g. The J.W. McConnell Family), private corporations (e.g. Manulife Financial, Maritz), and supported by the United Way of Canada and local Volunteer Centres. For a complete list of our Altruvest supporters please click on the <a href="">”Donors & Sponsors"</a>  link. Altruvest is pleased to be collaborating with Volunteer Calgary and Volunteer Thunder Bay to bring BoardMatch Fundamentals to their respective cities.
            </li>
            <li>
              Further information can be found on our Website at <a href="">www.altruvest.org</a> or via <a href="">“Program Overview”</a>  link.
            </li>
          </ul>
        </p>
        <h4 id="#whatIsQ1">
          Q2: Who qualifies for BoardMatch Fundamentals Canada?
        </h4>
        <p>
          <span class="font-weight-bold">A:</span>
        </p>
        <p class="ml-4">
          <ul>
            <li>
              Registered charities in the Greater Toronto Area. Both charities and not-for-profit Charities in Calgary, Fredericton, Greater Vancouver, and Thunder Bay...
            </li>
            <li>
              Currently located in Calgary, Fredericton, Greater Toronto Area, Greater Vancouver, and Thunder Bay...
            </li>
            <li>
              Expansion plans for other national partners are underway.
            </li>
          </ul>
        </p>
        <h4 id="#whatIsQ3">
          Q3: What orientation does BoardMatch Fundamentals Canada provide to community agencies?
        </h4>
        <p>
          <span class="font-weight-bold">A:</span>
        </p>
        <p class="ml-4">
          <ul>
            <li>
              BoardMatch Fundamentals helps agencies to improve their process of recruiting, motivating and retaining quality board members.
            </li>
            <li>
              Specific information provided includes the following:
              <ul class="ml-4">
                <li>
                  Duties and responsibilities of board members
                </li>
                <li>
                  Liabilities of board members
                </li>
                <li>
                  Checklist for board recruitment
                </li>
                <li>
                  Ethical fundraising
                </li>
                <li>
                  How to motivate your board
                </li>
                <li>
                  Developing a board manual
                </li>
                <li>
                  Sample job descriptions for board members
                </li>
                <li>
                  Sample board member contracts
                </li>
                <li>
                  Board report card
                </li>
                <li>
                  Board attendance policy
                </li>
                <li>
                  Board member reference checklist
                </li>
              </ul>
            </li>
            <li>
              Community agencies can take the free 90 minute BoardMatch Fundamentals Canada Voluntary Sector Charity Orientation over the Internet at - <a href="https://altruvest.org/">www.altruvest.org</a> or <a href="http://boardmatch2.altruvest.org/">www.boardmatch.org</a> click on "Login / Sign up" on the toolbar at the top of the screen, enter your Username/e-mail and Password or click "Sign up" and complete the brief registration form for new registrants. Complete the 'Charity Details' and 'Position Matching' registration steps and the 'Orientation' registration step will become available. Limited Live Orientation Sessions will be available.
            </li>
          </ul>
        </p>
        <h4 id="#whatIsQ4">
          Q4: What orientation does BoardMatch Fundamentals Canada provide to Board Member candidates?
        </h4>
        <p>
          <span class="font-weight-bold">A:</span>
        </p>
        <p class="ml-4">
          <ul>
            <li>
              Board member candidates take a free 90-minute orientation over the Internet where they learn about the roles, responsibilities, duties and liabilities of board members. Limited Live Orientation Sessions are available.
            </li>
            <li>
              Specific information provided includes the following:
              <ul class="ml-4">
                <li>
                  Size, scope and funding of the voluntary sector.
                </li>
                <li>
                  Roles and responsibilities of board members including strategic planning, stewardship, fundraising, human resources, community relations and accountability.
                </li>
                <li>
                  Duties and liabilities of board members.
                </li>
                <li>
                  Guidelines for being an effective board member - demonstrating commitment to the Charity and due diligence in carrying out duties.
                </li>
              </ul>
            </li>
          </ul>
        </p>
        <h4 id="#whatIsQ5">
          Q5: What expectations do candidates have?
        </h4>
        <p>
          <span class="font-weight-bold">A:</span>
        </p>
        <p class="ml-4">
          <ul>
            <li>
              Time commitment depends on the Charity, typically 5 to 15 hours a month. Agencies must be absolutely clear about this expectation of their board members.
            </li>
            <li>
              Term of service varies from one to four years, again dependent on the Charity.
            </li>
            <li>
              Dedication to the work of the agency and its vision for the future is necessary.
            </li>
            <li>
              Willingness to contribute their expertise and to work in different areas of agency governance, including cultivating contacts and fundraising.
            </li>
            <li>
              Tolerance of differing views-a sense of humour helps!
            </li>
          </ul>
        </p>
        <h4 id="#whatIsQ6">
          Q6: What qualifications do board member candidates possess?
        </h4>
        <p>
          <span class="font-weight-bold">A:</span> Board candidates should possess the following:
        </p>
        <p class="ml-4">
          <ul>
            <li>
              Post-secondary degree or diploma with at least 2-3 years of experience preferred(or equivalent post-graduate education) in the following fields: accounting, engineering, finance, human resources, information technology, law, marketing, sales, operations management, public relations, etc.
            </li>
            <li>
              Leadership and critical thinking skills.
            </li>
            <li>
              Ability to work effectively as part of a team.
            </li>
            <li>
              Previous volunteer experience is a plus but not required.
            </li>
          </ul>
        </p>
        <h4 id="#whatIsQ7">
          Q7: Why should we recruit board members through BoardMatch Fundamentals Canada?
        </h4>
        <p>
          <span class="font-weight-bold">A:</span>
        </p>
        <p class="ml-4">
          <ul>
            <li>
              Improve governance, accountability and capacity by bringing in motivated volunteers with business and/or community experience.
            </li>
            <li>
              Create diversity of skills on your board by including members with expertise in accounting, engineering, finance, human resources, information technology, law, marketing, sales, operations management, public relations, etc.
            </li>
            <li>
              Expand resource development networks through another source of connections.
            </li>
            <li>
              Reflect the broader community that you serve by creating diversity of backgrounds on your board.
            </li>
          </ul>
        </p>
        <h4 id="#whatIsQ8">
          Q8: Which other agencies participate in BoardMatch Fundamentals Canada?
        </h4>
        <p>
          <span class="font-weight-bold">A:</span>
        </p>
        <p class="ml-4">
          <ul>
            <li>
              Charitable Charities of all sizes and missions participate in BoardMatch Fundamentals.
            </li>
            <li>
              Services, member agencies provide include Addiction/Drug Education, Arts/Culture, Children, Community Development, Community Law and Advocacy, Disabled, Employment Training, Environment, Health, HIV/AIDS, Homelessness, Humane/Animal Welfare, Hunger, Immigrants/Refugees, International Aid, Literacy, Mental Health, Poverty, Seniors, Women, and Youth.
            </li>
            <li>
              Participating Charities include Big Brothers and Sisters, Boys & Girls Clubs, Canadian Cancer Society, Distress Centres, United Ways, and local Volunteer Centres. A complete list is available on our Website: www.boardmatch.org, and click on "Participating Charities" on the toolbar at the left side of the screen.
            </li>
          </ul>
        </p>
        <p
          class="text-center font-weight-bold"
          style="color: red;"
        >
          NOTE: BoardMatch Fundamentals Canada does not pre-screen nor verify the background information provided by prospective board candidates and participating Charities. Parties are encouraged and expected to engage in their own due diligence exercise as the matching process progresses.
        </p>
      </v-col>
    </v-row>
    <contact-us-form ref="contactUsForm" />
  </v-container>
</template>

<script>
import ContactUsForm from '@/components/infoForms/ContactUsForm';

export default {
  components: {
    ContactUsForm,
  },

  data: () => ({
  }),

  methods: {
    goto(elementId) {
      const el = document.getElementById(`#${elementId}`);

      if (el != null) el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    },
  },
};
</script>
