<template>
  <v-container
    fluid
    class="pa-2 pb-2 ma-2 mt-12"
    style="background-color: white; border-radius: 8px;"
  >
    <v-row class="text-left">
      <v-col
        cols="12"
        class="pl-6 pt-5"
      >
        <h1>Posted Positions</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pl-6 pt-5">
        <span class="font-italic">View your charity's posted positions and candidate applications.</span>
      </v-col>
    </v-row>
    <charity-posting-template
      :charity="charity"
      :position-filter="positionFilter"
    />
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import CharityPostingTemplate from '@/components/charity/charityPosting/CharityPostingTemplate';

export default {
  components: {
    CharityPostingTemplate,
  },
  data: () => ({
    positionFilter: '',
  }),
  computed: {
    ...mapState({
      charity: (state) => state.charity.charity,
    }),
  },
  created() {
    this.positionFilter = this.$route.params.positions;
  },

};
</script>
