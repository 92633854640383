<template>
  <div v-if="charityPosting">
    <v-row>
      <v-col
        cols="7"
        class="pr-1 pt-5"
      >
        <span class="text-h6 font-weight-bold">Position&nbsp;&nbsp;</span>
        <span class="text-h6 font-weight-bold"># {{ charityPosting.id }}&nbsp;&nbsp;</span>
        <v-chip
          small
          :color="chipColor(charityPosting.charityPosition.isNotBoard)"
          text-color="white"
          class="mb-2"
        >
          {{
            charityPosting.charityPosition.isNotBoard
              ? "NOT BOARD MEMBER"
              : "BOARD MEMBER" }}
        </v-chip>
      </v-col>
      <v-col
        cols="5"
        class="pl-1 d-flex justify-end"
      >
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="applyError != '' ? on : null"
            >
              <v-btn
                v-if="!postingExpired && isCandidateView"
                class="success apply-button text-capitalize"
                elevation="0"
                height="45"
                :disabled="applyError != '' || applied"
                @click="openApplicationForm()"
              >
                <span
                  class="text-h5"
                >{{ applied ? 'Applied' : 'Apply' }}</span>
              </v-btn>
              <v-btn v-else-if="!postingExpired && isCharityRepView && involvement.charityId == charityPosting.charityId"
                class="apply-button text-capitalize"
                color="success"
                outlined
                elevation="0"
                height="45"
                @click="showShareCharityPosting()"
              >
                <span
                  class="text-h5"
                >{{ 'Share' }}</span>
              </v-btn>
              <span
                v-else
                class="text-h4 font-weight-bold"
                style="color: rgb(192, 0, 0);"
              >{{ postingExpired ? 'EXPIRED' : '' }}</span>
              <span
                v-if="isCandidateView && postingExpired"
                class="text-h8"
                style="color: rgb(192, 0, 0);"
              ><br>&nbsp;&nbsp;&nbsp;&nbsp;{{ formattedExpiryDate }}</span>
            </div>
          </template>
          <span>{{ applyError }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-divider />
      </v-col>
      <v-col class="pt-1">
        <span
          v-if="isCandidateView"
          class="text-h6 font-weight-bold"
        >Charity:&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <span
          v-if="isCandidateView"
          class="text-h5 font-weight-bold"
        >{{ charityPosting.charity.name }}<br><br></span>
        <span
          v-if="isCandidateView"
          class="text-h6 font-weight-bold"
        >Position:&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <span class="text-h4 font-weight-bold">{{ charityPosting.charityPosition.name }}</span><br><br>
        <span class="text-h6 font-weight-bold">Mandate Period&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <span class="text-h7">{{ formattedStartDate }} - {{ formattedEndDate }} &nbsp;&nbsp; ({{ mandatePeriod }})</span><br><br>
        <span class="text-h6 font-weight-bold">Description</span><br>
        <div
          class="ml-4 mr-4 mt-2"
          style="line-height: 1.2;"
          v-html="charityPosting.description"
        /><br>
        <span class="text-h6 font-weight-bold">Skills</span>
        <div class="ml-4 mr-4 mt-1">
          <v-chip
            v-for="(skill, index) in charityPosting.candidateSkill"
            :key="'skill-chip-' + index"
            color="primary"
            class="mr-2 mb-1 mt-1"
            small
          >
            {{ '#' + skill.name }}
            <v-icon
              v-if="!skillChipDisabled(skill)"
              right
              class="mr-0"
              color="white"
            >
              mdi-check
            </v-icon>
          </v-chip>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-1">
        <span class="text-h6 font-weight-bold">Other Requirements</span>
        <div
          class="ml-4 mr-4 mt-2"
          style="white-space: pre; white-space: pre-line;"
        >
          {{ charityPosting.specialRequirements }}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-1">
        <span class="text-h6 font-weight-bold">Posting Expiry &nbsp;&nbsp;&nbsp;&nbsp;</span>
        <span class="text-h7">{{ formattedExpiryDate }} <span><br><br>
        </span></span>
      </v-col>
    </v-row>
    <general-dialog
      ref="postingShareDialog"
      dialog-width="1280px"
      :title="`You are sharing Posting #${charityPosting ? charityPosting.id : ''}`"
      @submit="sendPostingShareEmail()"
    >
      <posting-share-widget
        ref="postingShareWidget"
        :charity-posting="charityPosting"
        :applied="true"
        class="pa-4"
        @sent="closeShareDialog()"
      />
    </general-dialog>
    <candidate-application-dialog
      ref="candidateApplicationDialog"
      :show-candidate-details="false"
      :title="`You are applying for Position #${charityPosting.id}`"
      :posting="charityPosting"
      @position-success="applicationSuccessful()"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import CandidateApplicationDialog from '../../candidate/application/CandidateApplicationDialog.vue';
import PostingShareWidget from './PostingShareWidget.vue';
import GeneralDialog from '@/components/general/GeneralDialog';

export default {
  components: {
    CandidateApplicationDialog,
    PostingShareWidget,
    GeneralDialog,
  },
  props: {
    charityPosting: {
      type: Object,
      default: () => {},
    },
    applied: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
  }),
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.auth.isLoggedIn,
      involvement: (state) => state.user.selectedInv,
      candidate: (state) => state.candidate.candidate,
    }),
    ...mapGetters({
      onboardingCompleted: 'candidate/onboardingCompleted',
    }),
    isCandidateView() {
      return this.involvement.involvement === 'candidate';
    },
    isCharityRepView() {
      return this.involvement.involvement === 'charityRepresentative';
    },
    applyError() {
      if (this.isCharityRepView) return '';
      if (this.postingExpired) return 'Position Exipred';
      if (!this.isLoggedIn || !this.involvement || this.involvement.involvement !== 'candidate') return 'Must be signed in as a Candidate to apply.';
      if (!this.candidate.onboardingComplete) return 'You must complete your Onboarding before you can apply.';
      return '';
    },
    formattedStartDate() {
      const startDate = new Date(this.charityPosting.startDate);
      return startDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    },
    formattedEndDate() {
      const endDate = new Date(this.charityPosting.endDate);
      return endDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    },
    formattedExpiryDate() {
      const endDate = new Date(this.charityPosting.expiryDate);
      return endDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    },
    mandatePeriod() {
      const startDate = new Date(this.charityPosting.startDate);
      const endDate = new Date(this.charityPosting.endDate);

      const timeDiff = endDate - startDate; // Time difference in milliseconds
      const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

      if (daysDiff >= 7) {
        const weeksDiff = Math.floor(daysDiff / 7);
        return weeksDiff === 1 ? `${weeksDiff} week` : `${weeksDiff} weeks`;
      } if (daysDiff >= 365) {
        const yearsDiff = Math.floor(daysDiff / 365);
        return yearsDiff === 1 ? `${yearsDiff} year` : `${yearsDiff} years`;
      }
      return daysDiff === 1 ? `${daysDiff} day` : `${daysDiff} days`;
    },
    postingExpired() {
      return new Date(this.charityPosting.expiryDate) < Date.now();
    },
  },
  watch: {
  },

  created() {
  },

  methods: {
    chipColor(isNotBoard) {
      return isNotBoard ? 'orange' : 'purple';
    },
    openApplicationForm() {
      this.$refs.candidateApplicationDialog.openApplicationDialog(true);
      // if (!confirm('Are you sure you would like to apply for this position')) {
      //   this.$refs.candidateApplicationDialog.submitApplication();
      // }
    },
    skillChipDisabled(skill) {
      if (!this.isLoggedIn) return true;
      if (!this.candidate || !this.candidate.skills || !this.involvement || this.involvement.involvement !== 'candidate') return true;
      return this.candidate.skills.findIndex((x) => x.id === skill.id) === -1;
    },
    applicationSuccessful() {
      this.$bus.$emit('showSuccess', `Successfully applied for ${this.charityPosting.charityPosition.name}`);
      this.$emit('loadExistingApplications');
    },
    showShareCharityPosting() {
      this.$refs.postingShareDialog.openForm();
    },
    sendPostingShareEmail() {
      this.$refs.postingShareWidget.sendEmail();
    },
    closeShareDialog() {
      this.$refs.postingShareDialog.closeForm();
    },
  },
};
</script>
<style>

.website-button {
    position: absolute;
    right: 59%;
    top: 13px;
    z-index: 2
  }
.widget{
  border-radius: 8px;
  padding: 12px;
}
.background-gray{
  background-color: rgb(239, 239, 239);
}
.apply-button {
  width: 100%;
  border-radius: 8px;

}
</style>
