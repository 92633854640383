<template>
  <v-dialog
    v-model="positionDialog"
    width="1280px"
    :hide-overlay="false"
  >
    <v-card
      fill-height
    >
      <v-container fluid>
        <v-row>
          <v-col
            cols="11"
            class="text-h4 d-felx justify-left"
            style="padding-left: 23px;"
          >
            {{ title }}
          </v-col>
          <v-col
            cols="1"
            class="text-right"
          >
            <v-btn
              icon
              @click="closeApplicationDialog"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <candidate-application-form
          v-if="isEdit == true"
          ref="positionInfoForm"
          :posting="posting"
          @position-success="notifySuccess"
        />
        <candidate-information-summary
          v-if="showCandidateDetails == true"
          :candidate="candidate"
          :show-header="showCandidateDetails == true"
        />
        <candidate-application-summary
          v-if="isEdit == false"
          ref="applicationSummary"
          :application="application"
          :show-header="showCandidateDetails == true"
        />
        <v-row>
          <v-col
            class="text-end"
          >
            <slot :item="application" />
            <v-btn
              class="mr-4"
              text
              @click="closeApplicationDialog"
            >
              cancel
            </v-btn>
            <v-btn
              v-if="isEdit == true"
              class="mr-4"
              type="submit"
              color="success"
              @click="submitApplication"
            >
              apply
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import CandidateApplicationForm from '@/components/candidate/application/CandidateApplicationForm';
import CandidateApplicationSummary from '@/components/candidate/application/CandidateApplicationSummary.vue';
import CandidateInformationSummary from '@/components/candidate/CandidateInformationSummary';
import { createPaginationObject } from '@/helpers/PaginationHelper.js';

export default {
  components: {
    CandidateApplicationForm,
    CandidateApplicationSummary,
    CandidateInformationSummary,
  },
  props:
    {
      posting: {
        type: Object,
        default: () => {},
      },
      application: {
        type: Object,
        default: () => {},
      },
      title: {
        type: String,
        default: '',
      },
      charity: {
        type: Object,
        default: () => {},
      },
      showCandidateDetails: {
        type: Boolean,
        default: false,
      },
    },

  data: () => ({
    positionDialog: false,
    isEdit: false,
    candidate: null,
  }),
  computed: {
  },

  methods: {
    async openApplicationDialog(isEdit = false) {
      // await next tick because application prop can't keep up
      this.$nextTick(async () => {
        this.isEdit = isEdit;
        this.positionDialog = true;
        if (this.showCandidateDetails === true) {
          const filters = [];
          filters.push({ name: 'Id', values: [this.application.candidate.id] });
          // const response = await this.$api.candidate.read(createPaginationObject(null, null, filters));
          const res = await this.$api.candidate.read(
            createPaginationObject(null, null, filters),
          );

          // const res = await this.$api.candidate.getCandidateByUserId(this.application.candidate.id);

          [this.candidate] = res.candidates;
        }
      });
    },
    closeApplicationDialog() {
      this.positionDialog = false;
    },
    async submitApplication() {
      const validateResult = await Promise.all([
        this.$refs.positionInfoForm.validate(),
      ])
        .then((values) => !values.includes(false));

      if (!validateResult) return;
      this.$bus.$emit('loading', true);

      const res = await this.$refs.positionInfoForm.submit();
      this.$bus.$emit('loading', false);
      this.$emit('posting-success');

      if (!res) {
        this.$bus.$emit('showError');
        return;
      }

      this.notifySuccess('SUCCESS');
    },
    notifySuccess(msg) {
      // this.$refs.positionInfoForm.reset()
      this.closeApplicationDialog();
      this.$emit('position-success', msg);
    },

  },
};
</script>
