<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <span class="text-h6">
          "{{ role ? role.name : '' }}" Role Permissions
        </span>
      </v-col>
    </v-row>
    <v-data-table
      class="mt-5"
      :headers="dataHeaders"
      :items="role.boardmatchRepresentativeRolePermission"
      :footer-props="dataPerPageProps"
      :items-per-page="dataPerPage"
      dense
    >
      <template #[`item.claimName`]="{ item }">
        {{ item.boardmatchRepresentativeClaim.name }}
      </template>
      <template #[`item.access`]="{ item }">
        <input
          class="mt-2 ml-2"
          readonly
          style="width: 25px; height: 25px; pointer-events: none"
          type="radio"
          :checked="item.reads === 1 ? true : false"
        >
      </template>
      <template #[`item.description`]="{ item }">
        {{ item.boardmatchRepresentativeClaim.description }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  props:
  {
    role: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    dataPerPageProps: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    dataPerPage: 20,
    loading: true,
    roles: [],
    permissionDialog: false,

  }),
  computed: {
    dataHeaders() {
      const headers = [];

      headers.push({ text: 'Permission Name', value: 'claimName' });
      headers.push({ text: 'Access Granted', value: 'access' });
      headers.push({ text: 'Description', value: 'description' });

      return headers;
    },
  },
};
</script>
