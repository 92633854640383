<template>
  <div>
    <charity-information-form
      ref="charityInformationForm"
      :charity="charity"
      :is-disabled="isCharityPage && isEditing == false"
    />
    <v-row
      class="mt-3"
      style="margin-left: 5px; margin-right: 5px;"
    >
      <v-col cols="6">
        <general-phone-form
          ref="generalPhoneForm"
          :phone="getPhone('Main')"
          :is-disabled="isCharityPage && isEditing == false"
          :is-required="isPhoneRequired"
        />
      </v-col>
      <v-col
        cols="6"
      >
        <general-phone-form
          ref="eveningPhoneForm"
          :phone="getPhone('Evening')"
          :is-disabled="isCharityPage && isEditing == false"
          :is-required="false"
          phone-type="evening"
        />
      </v-col>
      <general-address-form
        ref="generalAddressForm"
        :address="charity ? charity.address : null"
        :is-disabled="isCharityPage && isEditing == false"
        :is-required="isAddressRequired"
      />
    </v-row>
  </div>
</template>

<script>
import GeneralPhoneForm from '@/components/general/GeneralPhoneForm';
import GeneralAddressForm from '@/components/general/GeneralAddressForm';
import CharityInformationForm from '@/components/charity/CharityInformationForm';

export default {
  components: {
    GeneralPhoneForm,
    GeneralAddressForm,
    CharityInformationForm,
  },
  props:
    {
      charity: {
        type: Object,
        default: () => {},
      },
      title: {
        type: String,
        default: '',
      },
      isCharityPage: {
        type: Boolean,
        default: false,
      },
      isEditing: {
        type: Boolean,
        default: false,
      },
      isPhoneRequired: {
        type: Boolean,
        default: false,
      },
      isAddressRequired: {
        type: Boolean,
        default: false,
      },
      showSectionTitle: {
        type: Boolean,
        default: true,
      },

    },
  methods: {
    async submitCharity() {
      const result = {
        success: false,
        completed: false,
      };
      const validateComponentsResult = await this.validate();
      result.completed = validateComponentsResult;

      if (!validateComponentsResult) {
        return result;
      }
      this.$bus.$emit('loading', true);

      const charityResponse = await this.$refs.charityInformationForm.submit();
      if (!charityResponse) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading', false);
        return result;
      }
      const addressResponse = await this.$refs.generalAddressForm.submit(charityResponse.id);
      const phoneResponse = await this.$refs.generalPhoneForm.submit(charityResponse.id);
      const phone2Response = await this.$refs.eveningPhoneForm.submit(charityResponse.id);

      if (!addressResponse && !phoneResponse && !phone2Response) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading', false);
        return result;
      }
      if (this.charity && this.charity.id) {
        this.$emit('charity-success', 'Charity Updated');
      } else {
        this.$emit('charity-success', 'Charity Created');
      }
      this.$bus.$emit('loading', false);
      result.success = true;
      return result;
    },
    async validate(showError = true) {
      const validated = await Promise.all([this.$refs.charityInformationForm.validate(showError), this.$refs.generalAddressForm.validate(), this.$refs.generalPhoneForm.validate()])
        .then((values) => !values.includes(false));
      if (showError) {
        if (validated) this.$bus.$emit('showSuccess', 'Successfully updated details.');
        else this.$bus.$emit('showError', 'Please fill out all required fields.');
      }
      return validated;
    },
    getPhone(type) {
      if (!this.charity || !this.charity.phones || this.charity.phones.length === 0) return null;

      return this.charity.phones.find((x) => x.phoneType.name.toLowerCase() === type.toLowerCase());
    },
  },
};
</script>
