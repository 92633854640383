<template>
  <div>
    <v-row>
      <v-col>{{ message }}</v-col>
    </v-row>
    <v-row>
      <v-col class="mb-8">
        <v-btn
          v-if="!isAuthorized"
          color="success"
          @click="openThinkificCourse(courseName)"
        >
          Training Course
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { kebabCaseFormatter } from '@/helpers/FormatHelper.js';

export default {
  data: () => ({
    courseName: 'API testing course',
  }),
  computed: {
    ...mapState({
      charity: (state) => state.charity.charity,
    }),
    ...mapGetters({
      findClaim: 'auth/findClaim',
    }),
    isAuthorized() {
      const candidatesThinkificEnrollmentCompletedClaim = this.findClaim(
        'candidate-thinkific-enrollment-complete',
      );
      let authorized = (candidatesThinkificEnrollmentCompletedClaim && candidatesThinkificEnrollmentCompletedClaim === '1');
      authorized = authorized || false;
      return authorized;
    },
    message() {
      const candidatesThinkificEnrollmentCompletedClaim = this.findClaim(
        'candidate-thinkific-enrollment-complete',
      );
      let authorized = (candidatesThinkificEnrollmentCompletedClaim && candidatesThinkificEnrollmentCompletedClaim === '1');
      authorized = authorized || false;
      if (authorized !== true) return 'To complete this onboarding step, please click the Training Course link below';
      return 'Thank you for completing your training, please proceed to the next step, or revisit your orientation in the Training section';
    },
  },
  methods: {
    async openThinkificCourse(courseName = null) {
      // TODO: move to environment file
      const thinkificSubdomain = 'evan-s-school-c3ed';
      const errorText = 'Could not open course';
      let returnUrl = '';
      if (courseName) {
        const courseNameUrl = kebabCaseFormatter(courseName);
        if (courseNameUrl) returnUrl = `&return_to=https://${thinkificSubdomain}.thinkific.com/courses/${courseNameUrl}`;
      }
      try {
        const res = await this.$api.thinkificAuthentication.get();
        if (res) {
          const thinkificJWT = res;
          window.open(
            `https://${thinkificSubdomain}.thinkific.com/api/sso/v2/sso/jwt?jwt=${thinkificJWT}${returnUrl}`,
            '_blank',
          );
        } else {
          this.$bus.$emit('showError', errorText);
          console.error(errorText);
        }
      } catch (e) {
        console.error(errorText);
        console.error(e);
      }
    },
    async validate() {
      return this.isAuthorized;
    },
  },
};
</script>
