<template>
  <div class="ma-0 pa-0">
    <v-row
      class="pt-0 mt-0"
    >
      <v-col cols="12">
        <charity-position-matching-form
          ref="charityPositionMatchingForm"
          :read-only="readOnly"
          :charity="charity"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CharityPositionMatchingForm from '@/components/charity/positionMatching/CharityPositionMatchingForm.vue';

export default {
  components: {
    CharityPositionMatchingForm,
  },
  props:
    {
      readOnly: {
        type: Boolean,
        default: false,
      },
      charity: {
        type: Object,
        default: null,
      },
    },
  data: () => ({
    charityId: null,
  }),
  methods: {
    async submit() {
      const result = {
        success: false,
        completed: false,
      };
      result.completed = await this.validate();
      if (!result.completed) return result;

      this.$bus.$emit('loading', true);

      const res = await this.$refs.charityPositionMatchingForm.submit();

      if (!res) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading', false);
        return result;
      }
      this.$bus.$emit('loading', false);
      this.$bus.$emit('showSuccess', 'Successfully updated your Matchmaking preferences.');
      this.$emit('registration-success');
      result.success = true;
      return result;
    },
    async validate(showError = true) {
      const validateComponentsResult = await Promise.all([
        this.$refs.charityPositionMatchingForm.validate(),
      ]).then((values) => !values.includes(false));
      if (showError) {
        if (!validateComponentsResult) this.$bus.$emit('showError', 'Please fill out all required fields');
      }
      return validateComponentsResult;
    },
    resetForm() {
      this.$refs.userInformationForm.reset();
      this.$refs.generalPhoneForm.reset();
    },
  },
};
</script>
