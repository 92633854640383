<template>
  <v-dialog
    v-model="positionDialog"
    width="1300"
  >
    <v-card
      fill-height
    >
      <v-container fluid>
        <v-row>
          <v-col class="text-h4">
            {{ title }}
          </v-col>
          <v-col class="text-right">
            <v-btn
              icon
              @click="closePositionDialog"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <charity-position-template
          :charity="charity"
        />
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import CharityPositionTemplate from '@/components/charity/charityPosition/CharityPositionTemplate';

export default {
  components: {
    CharityPositionTemplate,
  },
  props:
    {
      title: {
        type: String,
        default: '',
      },
      charity: {
        type: Object,
        default: () => {},
      },
    },

  data: () => ({
    positionDialog: false,
  }),

  methods: {
    openPositionDialog() {
      this.positionDialog = true;
    },
    closePositionDialog() {
      this.positionDialog = false;
    },
  },
};
</script>
