<template>
  <v-row>
    <v-col
      v-if="title"
      class="text-h6 pa-6"
      cols="12"
    >
      {{ title }}
    </v-col>
    <v-col>
      <v-data-table
        :headers="headers"
        :items="charities"
        :footer-props="footerProps"
        :items-per-page="itemsPerPage"
        :loading="loading"
        :server-items-length="total"
        dense
        :option="options"
        style="width:100%"
        @pagination="updatePagination"
        @update:options="updateOptions"
      >
        <template #[`item.name`]="{ item }">
          <text-highlight
            :queries="search"
            style="font-weight: bold;"
          >
            {{ item.name }}
          </text-highlight>
        </template>
        <template #[`item.email`]="{ item }">
          <text-highlight :queries="search">
            {{ item.email != null ? item.email.name : "" }}
          </text-highlight>
        </template>
        <template #[`item.chariableNumber`]="{ item }">
          <text-highlight :queries="search">
            {{ item.chariableNumber }}
          </text-highlight>
        </template>
        <!-- <template #[`item.mandate`]="{ item }">
          {{ item.mandate }}
        </template> -->
        <template #[`item.yearOfIncorporation`]="{ item }">
          <text-highlight :queries="search">
            {{ item.yearOfIncorporation === 0 ? '-' : item.yearOfIncorporation }}
          </text-highlight>
        </template>
        <template #[`item.website`]="{ item }">
          <text-highlight :queries="search" />
          {{ item.website }}
        </template>
        <template #[`item.scope`]="{ item }">
          <v-chip
            small
            :color="chipColor(item.charityScope)"
            text-color="white"
          >
            {{ item.charityScope ? item.charityScope.name : '-' }}
          </v-chip>
        </template>
        <template #[`item.type`]="{ item }">
          <text-highlight :queries="search">
            {{ item.charityType ? item.charityType.name : '-' }}
          </text-highlight>
        </template>
        <template #[`item.address`]="{ item }">
          <v-tooltip top>
            <template #activator="{on, attrs}">
              <span
                :queries="search"
                v-bind="attrs"
                v-on="on"
              >
                {{
                  item.shortAddress.length >= 20 ? `${item.shortAddress.substring(0,20)} ...` : item.shortAddress
                }}
              </span>
            </template>
            <span>{{ item.shortAddress }}</span>
          </v-tooltip>
        </template>
        <template #[`item.phone`]="{ item }">
          <text-highlight :queries="search">
            {{
              item.phones[0] != null
                ? `${item.phones[0].number} ${
                  item.phones[0].extension ? "Ext. " + item.phones[0].extension : ""
                }`
                : ""
            }}
          </text-highlight>
        </template>
        <template #[`item.actions`]="{ item }">
          <slot :item="item" />
          <!-- <a
            class="mr-2"
            @click="openCharityUpdateForm(item)"
          > EDIT </a>
          <a
            class="mr-2"
            @click="openCharityRepForm(item)"
          > USERS </a>
          <a
            class="mr-2"
            @click="softDelete(item.id)"
          > DELETE </a> -->
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import TextHighlight from 'vue-text-highlight';
import { createPaginationObject } from '@/helpers/PaginationHelper.js';

export default {
  components: {
    TextHighlight,
  },
  props: {
    search: {
      default: '',
      type: String,
    },
    title: {
      default: '',
      type: String,
    },
    filters: {
      default: null,
      type: Array,
    },
  },
  data: () => ({
    charities: [],
    footerProps: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    itemsPerPage: 20,
    loading: false,
    // candidateDialog: false,
    user: null,
    candidateFormTitle: 'Create Candidate',
    pagination: null,
    total: 0,
    options: null,
  }),
  computed: {
    headers() {
      const headers = [];

      headers.push({ text: 'Name', value: 'name' });
      headers.push({ text: 'Email', value: 'email' });
      headers.push({ text: 'Chariable Number', value: 'chariableNumber' });
      // headers.push({ text: 'Mandate', value: 'mandate' })
      headers.push({
        text: 'Year of Incorp.',
        value: 'yearOfIncorporation',
      });
      // headers.push({ text: 'Website', value: 'website' });
      headers.push({ text: 'Scope', value: 'scope' });
      // headers.push({ text: 'Type', value: 'type' });
      headers.push({ text: 'Address', value: 'address', sortable: false });
      headers.push({ text: 'Phone', value: 'phone', sortable: false });
      headers.push({
        text: 'Actions',
        value: 'actions',
        align: 'right',
        sortable: false,
        width: 100,
      });

      return headers;
    },
  },
  watch: {
    search(value) {
      if (value) {
        this.getCharities();
      }
    },
    options(value) {
      if (value) {
        this.getCharities();
      }
    },
    filters(value) {
      if (value) {
        this.getCharities();
      }
    },
  },

  methods: {
    async getCharities() {
      this.loading = true;
      const result = await this.$api.charity.read(
        createPaginationObject(this.options, this.search, this.filters),
      );

      if (!result) {
        this.charities = [];
        this.total = 0;
        this.loading = false;
        return;
      }
      // this.pagination = pagination;
      this.charities = result.charities.map((charity) => ({
        ...charity,
        shortAddress:
          charity.address
            ? `${charity.address.address1} ${charity.address.city} ${charity.address.region ? charity.address.region.abbreviation : ''} ${charity.address.code} ${charity.address.country ? charity.address.country.name : ''}`
            : '-',
      }));
      this.total = result.total;
      this.loading = false;
    },
    updatePagination(pagination) {
      this.pagination = pagination;
    },
    updateOptions(options) {
      this.options = options;
    },
    refresh() {
      this.getCharities();
    },
    chipColor(scope) {
      if (!scope) {
        return '';
      }
      let color;
      switch (scope.id) {
        case 1:
          color = 'success';
          break;
        case 2:
          color = 'orange';
          break;
        case 3:
          color = 'primary';
          break;

        default:
          color = 'primary';
          break;
      }
      return color;
    },
  },
};
</script>

<style></style>
