var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-2 pb-2 ma-2 mt-12",staticStyle:{"background-color":"white","border-radius":"8px"},attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pa-6",attrs:{"cols":"12"}},[_c('h1',[_vm._v("Candidates")])])],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',[_c('v-tabs',{attrs:{"center-active":""},model:{value:(_vm.isDisabledTab),callback:function ($$v) {_vm.isDisabledTab=$$v},expression:"isDisabledTab"}},[_c('v-tab',[_vm._v("Active")]),_c('v-tab',[_vm._v("Inactive")])],1)],1),_c('v-col',{staticClass:"text-end pa-3 pr-6"},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":_vm.openCandidateCreateForm}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" mdi-plus ")]),_vm._v(" New Candidate ")],1)],1)],1),_c('v-row',[_c('v-col',[_c('candidate-table',{ref:"candidateTable",attrs:{"filters":_vm.filters},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('font-awesome-icon',_vm._g(_vm._b({staticClass:"icon-hover mr-2",attrs:{"icon":['fabs', 'user-pen']},on:{"click":function($event){return _vm.authAs(item)}}},'font-awesome-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("Login As (To Edit Candidate Details)")])]),(_vm.isDisabledTab == 0)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('font-awesome-icon',_vm._g(_vm._b({staticClass:"icon-hover mr-3",attrs:{"icon":['fabs', 'xmark']},on:{"click":function($event){return _vm.disableCandidate(item.id)}}},'font-awesome-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("Deactivate candidate")])]):_vm._e()],[(_vm.isDisabledTab == 1)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('font-awesome-icon',_vm._g(_vm._b({staticClass:"icon-hover mr-3",attrs:{"icon":['fabs', 'circle-plus']},on:{"click":function($event){return _vm.disableCandidate(item.id)}}},'font-awesome-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("Activate candidate")])]):_vm._e()],_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('font-awesome-icon',_vm._g(_vm._b({staticClass:"icon-hover",staticStyle:{"color":"red"},attrs:{"icon":['fabs', 'trash-can']},on:{"click":function($event){return _vm.softDelete(item.id)}}},'font-awesome-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("Delete candidate")])])]}}])})],1)],1),_c('candidate-template-dialog',{ref:"candidateTemplateDialog",attrs:{"title":_vm.candidateDialogTitle,"user":_vm.user},on:{"candidate-success":_vm.notifySuccess},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [(_vm.currentUser != null && item != null && _vm.currentUser.id != item.id)?_c('v-btn',{staticClass:"mr-4",attrs:{"color":"primary"},on:{"click":function($event){return _vm.authAs(item)}}},[_vm._v(" Log In As User ")]):_vm._e()]}}])}),_c('user-authenticate-as-dialog',{ref:"authenticateAsDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }