var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"text-center"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',[_vm._v("Corporations")])]),_c('v-btn',{staticClass:"d-flex align-self-end",attrs:{"color":"primary","outlined":""},on:{"click":_vm.openCorporationCreateForm}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" fas fa-plus ")]),_vm._v(" New Record ")],1)],1),_c('v-row',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.corporationHeaders,"items":_vm.corporations,"footer-props":_vm.corporationsPerPageProps,"items-per-page":_vm.corporationsPerPage,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.email != null ? item.email.name : '')+" ")]}},{key:"item.website",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.website)+" ")]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.address != null ? ((item.address.address1) + " " + (item.address.city) + " " + (item.address.region.abbreviation) + " " + (item.address.code) + " " + (item.address.country.name)) : '')+" ")]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.phone != null ? ("(" + (item.phone.extension) + ") " + (item.phone.number)) : '')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"mr-2",on:{"click":function($event){return _vm.openCorporationUpdateForm(item)}}},[_vm._v(" EDIT ")])]}}],null,true)})],1),_c('v-row',{attrs:{"justify":"center"}},[_c('corporation-form',{ref:"corporationForm",attrs:{"corporation-prop":_vm.corporation},on:{"corporation-success":_vm.notifyCorporationSuccess,"corporation-fail":_vm.notifyCorporationFail}})],1),_c('Snackbar',{ref:"snackbar"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }