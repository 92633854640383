<template>
  <v-row>
    <v-col>
      <general-image-selector-widget
        v-if="thumbnails.defaultThumbnails && thumbnails.defaultThumbnails.length > 0"
        ref="defaultImages"
        height="125"
        :images="thumbnails.defaultThumbnails"
        @imageSelected="(img) => imageSelected(img, 'default')"
      />
      <general-image-selector-widget
        v-if="thumbnails.customThumbnails && thumbnails.customThumbnails.length > 0"
        ref="customImages"
        height="125"
        :images="thumbnails.customThumbnails"
        class="mt-5"
        @imageSelected="(img) => imageSelected(img, 'custom')"
      />
      <general-upload-widget
        :multiple="false"
        :is-image="true"
        :image-preview-max-height="250"
        entity-type="charity"
        :entity-id="charity.id"
        file-type="background-image"
        class="mt-5"
        @success="loadBackgroundImageThumbnails()"
      />
    </v-col>
  </v-row>
</template>

<script>
import GeneralImageSelectorWidget from '@/components/general/GeneralImageSelectorWidget';
import GeneralUploadWidget from '@/components/general/GeneralUploadWidget';
import { createPaginationObject } from '@/helpers/PaginationHelper.js';

export default {
  components: {
    GeneralImageSelectorWidget,
    GeneralUploadWidget,
  },

  props:
  {
    charity: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    thumbnails: {
      defaultThumbnails: [],
      customThumbnails: [],
    },
    selectedThumbnail: null,
  }),
  computed: {
    defaultImages() {
      if (this.thumbnails.length === 0) return [];
      return [];
    },
  },
  watch: {
  },
  created() {
    this.loadBackgroundImageThumbnails();
  },
  methods: {
    async submit() {
      const response = await this.$api.image.setActive('charity', this.charity.id, this.selectedThumbnail.id);
      if (response === true) this.$bus.$emit('showSuccess', 'Successfully updated your Background Image.');
      else this.$bus.$emit('showError', 'Unable to update your Background Image.');
      this.$emit('backgroundChanged');
      this.$emit('close');
    },
    async loadBackgroundImageThumbnails() {
      const filters = [
        {
          name: 'entityId',
          values: [this.charity.id.toString()],
        },
        {
          name: 'type',
          values: ['background-image-thumbnail'],
        },
      ];
      const response = await this.$api.image.readImages('charity', createPaginationObject(null, null, filters));

      const thumbnails = {
        defaultThumbnails: [],
        customThumbnails: [],
      };
      response.images.forEach((readImage) => {
        if (readImage.isDefault) thumbnails.defaultThumbnails.push(readImage);
        else thumbnails.customThumbnails.push(readImage);
      });

      this.thumbnails = thumbnails;
    },
    imageSelected(image, source) {
      this.selectedThumbnail = image;
      switch (source) {
        case 'custom':
          if (this.$refs.defaultImages) this.$refs.defaultImages.clearSelection();
          break;
        case 'default':
          if (this.$refs.customImages) this.$refs.customImages.clearSelection();
          break;
        default:
          if (this.$refs.defaultImages) this.$refs.defaultImages.clearSelection();
          if (this.$refs.customImages) this.$refs.customImages.clearSelection();
      }
    },
  },
};
</script>
<style>
</style>
