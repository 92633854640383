<template>
  <v-container fluid>
    <!-- <v-row class="text-left">
      <v-col cols="12">
        <h1>Social Media</h1>
      </v-col>
    </v-row> -->
    <v-row>
      <v-col>
        <v-tabs
          v-model="tab"
          center-active
        >
          <v-tab>Types</v-tab>
          <!--<v-tab>Users</v-tab>
          <v-tab>Charities</v-tab>-->
        </v-tabs>
      </v-col>
      <v-col
        v-if="tab == 0"
        class="text-end"
      >
        <v-btn
          color="primary"
          outlined
          @click="openTypeForm()"
        >
          <v-icon
            small
            class="mr-1"
          >
            mdi-plus
          </v-icon>
          Add Type
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <social-media-types-template
          v-if="tab == 0"
          ref="socialMediaTypesTemplate"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import SocialMediaTypesTemplate from '@/components/boardmatchRep/socialMedia/SocialMediaTypesTemplate';

export default {
  components: {
    SocialMediaTypesTemplate,
  },
  data: () => ({
    tab: 0,
  }),
  computed: {
    ...mapState({
      currentUser: (state) => state.user,
    }),
  },
  mounted() {
    this.init();
  },

  methods: {
    init() {
    },
    openTypeForm() {
      this.$refs.socialMediaTypesTemplate.openTypeForm();
    },
  },
};
</script>
