import axios from 'axios';
import Api from '@/api/Api';

class CharityRepApi extends Api {
  constructor() {
    super('charities/representatives', 'charityrepresentatives');
  }

  async getCharityRepsByCharityId(charityId) {
    const res = await this.get(`charity/${charityId}`);
    return res;
  }

  async sendInvite(userInfo) {
    let res;
    const errorText = 'Failed to send invite';
    await axios.post(`/${this.endpoint}/invite`, userInfo)
      .then((response) => {
        res = response.data;
      })
      .catch((error) => {
        if (error.response) {
        // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
        // The request was made but no response was received
          console.log(error.request);
        } else {
        // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(errorText);
        res = false;
      });
    return res;
  }

  async suppressWelcome(id) {
    const res = await this.get('suppress-welcome', [{ name: 'id', value: id }]);
    return res;
  }

  async getWelcomeStatus(id) {
    const res = await this.get('get-welcome', [{ name: 'id', value: id }]);
    return res;
  }
}

const charityRepApi = new CharityRepApi();
export default charityRepApi;
