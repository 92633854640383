<template>
  <v-row
    :style="`z-index: 1;${pageSpecificStyle}`"
    class="pa-0 pb-2 ma-0"
  >
    <v-col
      cols="12"
      class="d-flex justify-center caption pa-0 ma-0"
    >
      <div>
        <a
          class="blue--text text--lighten-2 mb-1 ml-1 mr-1"
          @click="$refs.programOverviewForm.toggleDialog()"
        >
          Program Overview
        </a> -
        <a
          class="blue--text text--lighten-2 mb-1 ml-1 mr-1"
          @click="$refs.legalForm.toggleDialog()"
        >
          Legal
        </a> -
        <a
          class="blue--text text--lighten-2 mb-1 ml-1 mr-1"
          @click="$refs.contactUsForm.toggleDialog()"
        >
          Contact Us
        </a> -
        <a
          class="blue--text text--lighten-2 mb-1 ml-1 mr-1"
          @click="openFaqForm()"
        >
          Boardmatch FAQs
        </a>-
        <!-- <v-icon
          x-small
          class="mb-1 ml-1 mr-1 grey--text"
        >
          mdi-circle
        </v-icon> -->
        <span class="mb-1 ml-1 mr-1 grey--text">&copy; {{ year }}, </span><a
          class="blue--text text--lighten-2 mb-1 ml-1 mr-1"
          target="_blank"
          href="https://altruvest.org/"
          style="text-decoration: none;"
        >Altruvest</a>
        -
        <span class="mb-1 ml-1 mr-1 grey--text">Altruvest Charitable Registration #: 892814161RR0001</span>
        -
        <span class="mb-1 ml-1 mr-1 grey--text">Powered By</span> <a
          class="blue--text text--lighten-2"
          target="_blank"
          href="https://www.balanced.plus/"
          style="text-decoration: none;"
        >BALANCED+</a>
      </div>
    </v-col>
    <program-overview-form ref="programOverviewForm" />
    <legal-form ref="legalForm" />
    <contact-us-form ref="contactUsForm" />
    <charity-f-a-q-dialog ref="charityFaq" />
    <candidate-f-a-q-dialog ref="candidateFaq" />
  </v-row>
</template>

<script>

import { mapGetters } from 'vuex';
import Router from '@/router/index.js';
import ProgramOverviewForm from '@/components/infoForms/ProgramOverviewForm';
import LegalForm from '@/components/infoForms/LegalPageForm';
import ContactUsForm from '@/components/infoForms/ContactUsForm';
import CharityFAQDialog from '@/components/infoForms/CharityFAQDialog';
import CandidateFAQDialog from '@/components/infoForms/CandidateFAQDialog';

export default {
  components: {
    ProgramOverviewForm,
    LegalForm,
    ContactUsForm,
    CharityFAQDialog,
    CandidateFAQDialog,
  },

  props:
  {
  },
  data: () => ({
  }),
  computed: {
    ...mapGetters({
      selectedInv: 'user/getSelectedInv',
    }),
    year() {
      return new Date().getFullYear();
    },
    pageSpecificStyle() {
      if (Router.app.$route.path === '/search') return ''; // ' margin-top: 200px !important;';
      return '';
    },
  },
  watch: {
  },
  methods: {
    openFaqForm() {
      if (this.selectedInv && this.selectedInv.involvement === 'candidate') {
        this.$refs.candidateFaq.toggleDialog();
      } else {
        this.$refs.charityFaq.toggleDialog();
      }
    },
  },
};
</script>
<style>
</style>
