<template>
  <v-row>
    <v-col
      cols="12"
      class="pt-0 pb-0"
    >
      <span
        class="text-h4"
      ><b>Charity Postings </b></span>
      <!-- <template v-if="total == 0 && loadingCharityPostings == true">
        <v-progress-circular
          indeterminate
          color="grey"
          size="20"
          class="mb-2 mr-2"
        />
        <span class="text-h6 grey--text text--darken-2">Loading...</span>
      </template> -->
      <template>
        <!--v-else-->
        <span class="text-h6 grey--text text--darken-2">{{ total.toLocaleString() }} Result{{ total != 1 ? 's' : '' }}</span>
      </template>
    </v-col>
    <v-col
      v-for="(charityPosting) in charityPostings"
      :key="charityPosting.id"
      cols="4"
    >
      <div
        class="white widget ma-2"
      >
        <search-charity-posting-result-tile-widget
          :charity-posting="charityPosting"
          :applied="applied(charityPosting.id)"
          @showCharityPosting="(charityPostingId) => showCharityPosting(charityPostingId)"
        />
      </div>
    </v-col>
    <v-col
      v-if="total > 0 && charityPostings.length > 0 && charityPostings.length < total"
      cols="12"
      class="d-flex justify-center"
    >
      <v-btn
        id="#loadMoreCharityPostingsBtn"
        :disabled="loadingCharityPostings"
        elevation="0"
        outlined
        @click="searchCharityPostings(false)"
      >
        <v-progress-circular
          v-if="loadingCharityPostings"
          indeterminate
          color="grey"
          class="mr-2"
        />{{ loadingCharityPostings ? 'Loading...' : 'Load More' }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';
import { createPaginationObject } from '@/helpers/PaginationHelper.js';
import SearchCharityPostingResultTileWidget from '@/components/search/SearchCharityPostingResultTileWidget';

export default {
  components: {
    SearchCharityPostingResultTileWidget,
  },

  props:
  {
    filters: {
      type: Array,
      default: () => [],
    },
    search: {
      type: String,
      default: '',
    },
    existingApplications: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    charityPostings: [],
    loadingCharityPostings: false,
    total: 0,
    options: {
      page: 1,
      itemsPerPage: 6,
      sortBy: [],
      include: [],
    },
  }),
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.auth.isLoggedIn,
      involvement: (state) => state.user.selectedInv,
      candidate: (state) => state.candidate.candidate,
    }),
    isCandidate() {
      return this.isLoggedIn && this.involvement && this.involvement.involvement === 'candidate' && this.candidate;
    },
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    init() {
    },
    async searchCharityPostings(reset = true) {
      if (reset && this.loadingCharityPostings) return; // prevent double-invocation of initial search
      this.loadingCharityPostings = true;
      if (reset) {
        this.charityPostings = [];
        this.options.page = 1;
        this.total = 0;
      }
      const filter = this.filters;
      filter.push({ name: 'expire' });
      const result = this.isLoggedIn
        ? await this.$api.charityPostings.read(
          createPaginationObject(this.options, this.search, filter),
        )
        : await this.$api.charityPostings.readPublic(
          createPaginationObject(this.options, this.search, this.filters),
        );
      if (result) {
        this.options.page += 1;
        this.total = result.total;
      }
      result.postings.forEach((charityPosting) => {
        const copiedPosting = { ...charityPosting, matchingSkill: [], candidateSkill: [] };
        if (this.isCandidate) {
          charityPosting.candidateSkill.forEach((skill) => {
            if (this.candidate.skills.findIndex((x) => x.id === skill.id) > -1) {
              copiedPosting.matchingSkill.push(skill);
            } else {
              copiedPosting.candidateSkill.push(skill);
            }
          });
        } else {
          copiedPosting.candidateSkill = charityPosting.candidateSkill;
        }

        this.charityPostings.push(copiedPosting);
      });
      this.$emit('loaded', this.total);
      this.goto('loadMoreCharityPostingsBtn');
      this.loadingCharityPostings = false;
    },
    goto(elementId) {
      const el = document.getElementById(`#${elementId}`);
      if (el != null) el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    },
    showCharityPosting(id) {
      this.$emit('showCharityPosting', id);
    },
    applied(postingId) {
      if (!postingId) return false;
      return this.existingApplications.findIndex((x) => x.postingId === postingId) > -1;
    },

  },
};
</script>
<style scoped>
  .widget{
    border-radius: 8px;
    padding: 12px;
  }
</style>
