import Vue from 'vue';
import authenticationApi from './AuthenticationApi';
import userApi from './users/UserApi';
import userRolesApi from './users/UserRolesApi';
import userInformationApi from './users/UserInformationApi';
import userStateApi from './users/UserStateApi';
import candidateApi from './candidates/CandidateApi';
import candidateSkillsApi from './candidates/CandidateSkillsApi';
import candidateFocusAreasApi from './candidates/CandidateFocusAreasApi';
import candidateOnboardingApi from './candidates/CandidateOnboardingApi';
import candidateApplicationsApi from './candidates/CandidateApplicationsApi';
import candidateMatchingPreferencesApi from './candidates/CandidateMatchingPreferencesApi';
import candidateQualificationsApi from './candidates/CandidateQualificationsApi';
import charityApi from './charities/CharityApi';
import charityOnboardingApi from './charities/CharityOnboardingApi';
import charityScopesApi from './charities/CharityScopesApi';
import charityTypesApi from './charities/CharityTypesApi';
import charityRepApi from './charities/charityRep/CharityRepApi';
import charityRepresentativeOnboardingApi from './charities/charityRep/CharityRepOnboardingApi';
import charityRepRolesApi from './charities/charityRep/CharityRepRolesApi';
import addressApi from './generals/AddressApi';
import phoneApi from './generals/PhoneApi';
import imageApi from './generals/ImageApi';
import fileApi from './generals/FileApi';
import phoneTypeApi from './generals/PhoneTypeApi';
import contactApi from './generals/ContactApi';
import countryApi from './generals/CountryApi';
import regionApi from './generals/RegionApi';
import socialMediaApi from './generals/SocialMediaApi';
import charityPositionsApi from './charities/charityPositions/CharityPositionsApi';
import charityBoardRolesApi from './charities/charityPositions/CharityBoardRolesApi';
import charityPostingsApi from './charities/charityPostings/CharityPostingsApi';
import boardmatchRepsApi from './boardmatchReps/BoardmatchRepsApi';
import boardmatchRepRolesApi from './boardmatchReps/BoardmatchRepRolesApi';
import charityMandateApi from './charities/charityPositions/CharityMandateApi';
import thinkificAuthenticationApi from './thinkific/ThinkificAuthenticationApi';
import thinkificEnrollmentsApi from './thinkific/ThinkificEnrollmentsApi';
import thinkificCoursesApi from './thinkific/ThinkificCoursesApi';

const factories = {

  auth: authenticationApi,
  // user
  user: userApi,
  userRoles: userRolesApi,
  userInfo: userInformationApi,
  userState: userStateApi,

  // boardmatch rep
  boardmatchRep: boardmatchRepsApi,
  boardmatchRepRoles: boardmatchRepRolesApi,

  // candidate
  candidate: candidateApi,
  candidateSkills: candidateSkillsApi,
  candidateFocusAreas: candidateFocusAreasApi,
  candidateApplications: candidateApplicationsApi,
  candidateOnboarding: candidateOnboardingApi,
  candidateMatchingPreferences: candidateMatchingPreferencesApi,
  candidateQualifications: candidateQualificationsApi,

  // charity
  charity: charityApi,
  charityScopes: charityScopesApi,
  charityTypes: charityTypesApi,
  charityOnboarding: charityOnboardingApi,

  // charity rep
  charityRep: charityRepApi,
  charityRepRoles: charityRepRolesApi,
  charityRepOnboarding: charityRepresentativeOnboardingApi,

  // charity position
  charityPositions: charityPositionsApi,
  charityBoardRoles: charityBoardRolesApi,
  charityMandate: charityMandateApi,

  // charity postings
  charityPostings: charityPostingsApi,

  // generals
  address: addressApi,
  phone: phoneApi,
  phoneType: phoneTypeApi,
  contact: contactApi,
  country: countryApi,
  region: regionApi,
  image: imageApi,
  file: fileApi,
  socialMedia: socialMediaApi,

  // thinkific
  thinkificAuthentication: thinkificAuthenticationApi,
  thinkificEnrollments: thinkificEnrollmentsApi,
  thinkificCourses: thinkificCoursesApi,

};

Vue.prototype.$api = factories;
