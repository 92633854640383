<template>
  <v-container
    fluid
  >
    <validation-observer
      ref="addressValidateObserver"
    >
      <v-row>
        <v-col
          v-if="!isSimplifiedView"
          class="pt-0 pb-0 pl-0"
          :cols="rows == 2 ? '4' : false"
        >
          <!-- <validation-provider
            v-slot="{ errors }"
            name="Street Address"
            :rules="isRequired ? 'required' : ''"
          >
            <v-text-field
              v-model="addressData.address1"
              :error-messages="errors"
              label="Street Address"
              :readonly="isDisabled"
              :style="isDisabled ? 'pointer-events: none' : ''"
            >
              <template #label>
                Street Address<span
                  v-if="isRequired"
                  class="red--text"
                ><strong>* </strong></span>
              </template>
            </v-text-field>
          </validation-provider> -->
          <v-text-field
            v-model="addressData.address1"
            label="Street Address"
            :readonly="isDisabled"
            :style="isDisabled ? 'pointer-events: none' : ''"
          />
        </v-col>
        <v-col
          class="pt-0 pb-0 pl-0"
          :cols="rows == 2 ? '4' : false"
        >
          <validation-provider
            v-slot="{ errors }"
            name="Country"
            :rules="isRequired ? 'required' : ''"
          >
            <v-select
              v-model="addressData.countryId"
              :items="countries"
              :error-messages="errors"
              label="Country"
              data-vv-name="select"
              item-text="name"
              item-value="id"
              :readonly="isDisabled"
              :style="isDisabled ? 'pointer-events: none' : ''"
            >
              <template #label>
                Country
                <span
                  v-if="isRequired"
                  class="red--text"
                ><strong>* </strong></span>
              </template>
            </v-select>
          </validation-provider>
        </v-col>
        <v-col
          class="pt-0 pb-0 pl-0"
          :cols="rows == 2 ? '6' : false"
        >
          <validation-provider
            v-slot="{ errors }"
            name="Province/State"
            :rules="isRequired ? 'required' : ''"
          >
            <v-select
              v-model="addressData.regionId"
              :items="provincesByCountry"
              :error-messages="errors"
              label="Province/State"
              data-vv-name="select"
              item-text="name"
              item-value="id"
              :disabled="addressData.countryId == null"
              :readonly="isDisabled"
              :style="isDisabled ? 'pointer-events: none' : ''"
            >
              <template #label>
                Province/State
                <span
                  v-if="isRequired"
                  class="red--text"
                ><strong>* </strong></span>
              </template>
            </v-select>
          </validation-provider>
        </v-col>
        <v-col
          class="pt-0 pb-0 pl-0"
          :cols="rows == 2 ? '4' : false"
        >
          <validation-provider
            v-slot="{ errors }"
            name="City"
            :rules="isRequired ? 'required' : ''"
          >
            <v-text-field
              v-model="addressData.city"
              :error-messages="errors"
              label="City"
              :readonly="isDisabled"
              :style="isDisabled ? 'pointer-events: none' : ''"
            >
              <template #label>
                City
                <span
                  v-if="isRequired"
                  class="red--text"
                ><strong>* </strong></span>
              </template>
            </v-text-field>
          </validation-provider>
        </v-col>
        <v-col
          v-if="!isSimplifiedView"
          class="pt-0 pb-0 pl-0 pr-4"
          :cols="rows == 2 ? '6' : false"
        >
          <!-- <validation-provider
            v-slot="{ errors }"
            name="Postal Code"
            :rules="isRequired ? 'required' : ''"
          >
            <v-text-field
              v-model="addressData.code"
              :error-messages="errors"
              label="Postal Code"
              :readonly="isDisabled"
              :style="isDisabled ? 'pointer-events: none' : ''"
            >
              <template #label>
                Postal Code
                <span
                  v-if="isRequired"
                  class="red--text"
                ><strong>* </strong></span>
              </template>
            </v-text-field>
          </validation-provider> -->
          <v-text-field
            v-model="addressData.code"
            label="Postal Code"
            :readonly="isDisabled"
            :style="isDisabled ? 'pointer-events: none' : ''"
          />
        </v-col>
      </v-row>
    </validation-observer>
  </v-container>
</template>

<script>

import { required } from 'vee-validate/dist/rules';
import {
  extend, ValidationObserver, ValidationProvider, setInteractionMode,
} from 'vee-validate';
import { mapState } from 'vuex';

setInteractionMode('eager');

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props:
    {
      address: {
        type: Object,
        default: () => {},
      },
      isRequired: {
        type: Boolean,
        default: true,
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
      rows: {
        type: Number,
        default: 1,
      },
      isSimplifiedView: {
        type: Boolean,
        default: false,
      },
    },
  data: () => ({
    addressData: null,
    provincesByCountry: [],

  }),
  computed: {
    ...mapState({
      countries: (state) => state.general.countries,
      provinces: (state) => state.general.regions,
    }),
  },
  watch: {
    address: {
      immediate: true,
      handler(value) {
        this.addressData = value
          || {
            id: 0,
            address1: '',
            city: '',
            regionId: null,
            code: '',
            countryId: null,
          };
      },
    },
    'addressData.countryId': {
      handler(value) {
        if (!value) return;
        this.provincesByCountry = this.provinces.filter((p) => p.countryId === value);
      },

    },
  },
  created() {
    if (!this.countries.length) this.$store.dispatch('general/getCountries');

    if (!this.provinces.length) {
      this.$store.dispatch('general/getRegion').then(() => {
        this.provincesByCountry = this.provinces;
      });
    } else this.provincesByCountry = this.provinces;
  },
  methods: {
    async submit(charityId = null, userInformationId = null, corporationId = null) {
      try {
        this.addressData.charityId = charityId;
        this.addressData.userInformationId = userInformationId;
        this.addressData.corporationId = corporationId;

        return this.addressData.id !== 0
          ? await this.$api.address.update(this.addressData)
          : await this.$api.address.create(this.addressData);
      } catch (err) {
        console.log(err);
      }

      return null;
    },
    async validate(showError = true) {
      const res = await this.$refs.addressValidateObserver.validate();
      if (!res) {
        if (showError) this.$bus.$emit('showError', 'Please enter address required fields marked red');
        return false;
      }
      return res;
    },
    reset() {
      this.addressData = {
        id: 0,
        address1: '',
        city: '',
        regionId: null,
        code: '',
        countryId: null,
      };
    },
  },

};
</script>
