import Api from '../Api';

class UserInvolvementsApi extends Api {
  constructor() {
    super('users/involvements', 'userinvs');
  }
}

const userInvolvementsApi = new UserInvolvementsApi();
export default userInvolvementsApi;
