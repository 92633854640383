<template>
  <div class="ma-0 pt-5 pl-3 pr-3">
    <v-row>
      <v-col class="text-h5 font-weight-bold">
        Charity Information
      </v-col>
    </v-row>
    <charity-information-form
      ref="charityInformationForm"
      :is-signup="true"
    />
    <v-row>
      <v-col>
        <span class="text-h5 font-weight-bold">
          Charity Administrator
        </span>
        <a
          v-if="!charityAdminUserId"
          @click="openLogInForm()"
        >Already have a Boardmatch user account?</a>
      </v-col>
      <v-col
        cols="12"
        class="mt-0 vt-0"
      >
        <user-information-form
          v-if="
            !charityAdminUserId || newUserAdmin"
          ref="charityAdminUserInformationForm"
          :rows="2"
          :show-password-reset="false"
          :hide-password="false"
          :show-pronoun="true"
        />
        <user-used-title
          v-else
          :username="charityAdminUserName"
        />
      </v-col>
    </v-row>
    <user-login-dialog
      ref="userLoginDialog"
      :title="'Login'"
      @log-in-success="logInSuccess"
    />
    <charity-rep-information-form
      v-show="false"
      ref="charityAdminRepForm"
    />
  </div>
</template>

<script>
import UserInformationForm from '@/components/user/UserInformationForm.vue';
import CharityInformationForm from '@/components/charity/CharityInformationForm';
import UserLoginDialog from '@/components/user/UserLoginDialog';
import UserUsedTitle from '@/components/user/UserUsedTitle.vue';
import CharityRepInformationForm from '@/components/charity/charityRep/CharityRepInformationForm';

export default {
  components: {
    UserInformationForm,
    UserLoginDialog,
    UserUsedTitle,
    CharityInformationForm,
    CharityRepInformationForm,
  },
  props:
  {
    isSignup: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    address: null,
    charityAdminUserId: null,
    charityAdminUserName: '',
    isReleaseFormAgreed: false,
    newUserAdmin: true,
  }),
  methods: {
    async submit() {
      // ensure we have a userId for charity admin
      if (!this.charityAdminUserId) {
        // user doesn't exist, so we create one.
        const validateComponentsResult = await Promise.all([
          this.$refs.charityAdminUserInformationForm.validate(),
        ]).then((values) => !values.includes(false));

        if (!validateComponentsResult) return;

        this.$bus.$emit('loading', true);
        this.$emit('loading', true);

        const charityAdminUserResponse = await this.$refs.charityAdminUserInformationForm.submit();

        if (!charityAdminUserResponse) {
          this.$bus.$emit('showError', 'Unable to create User');
          this.$bus.$emit('loading', false);
          this.$emit('loading', false);
          return;
        }
        this.charityAdminUserId = charityAdminUserResponse.id;
        this.charityAdminUserName = `${charityAdminUserResponse.userInformation.firstName} ${charityAdminUserResponse.userInformation.lastName}`;
      }

      // create charity
      const charityResponse = await this.$refs.charityInformationForm.submit();
      if (!charityResponse) {
        this.$bus.$emit('showError', 'Unable to register Charity');
        this.$bus.$emit('loading', false);
        this.$emit('loading', false);
        return;
      }

      const charityAdminRepResponse = await this.$refs.charityAdminRepForm.submit(
        charityResponse.id,
        this.charityAdminUserId,
        [{ id: 1, name: 'Admin' }],
      );

      if (
        !charityAdminRepResponse
      ) {
        this.$bus.$emit('showError', 'Unable to register Charity Profile');
        this.$bus.$emit('loading', false);
        this.$emit('loading', false);
        return;
      }

      this.$bus.$emit('loading', false);
      this.$bus.$emit('showSuccess', 'Registered Successfully! Verification Email has been sent.');
      this.$emit('registration-success');
      this.$emit('loading', false);
    },
    async resetForm() {
      this.charityAdminUserId = null;
      this.charityAdminUserName = '';
      await this.$nextTick();
      this.$refs.charityAdminUserInformationForm.reset();
      if (this.$refs.generalPhoneForm) this.$refs.generalPhoneForm.reset();
    },
    // For Logged In user
    openLogInForm() {
      this.$refs.userLoginDialog.openForm();
    },
    logInSuccess(userData) {
      if (!userData) return;
      this.$bus.$emit('showSuccess', 'Success!');
      this.charityAdminUserId = userData.id;
      this.charityAdminUserName = `${userData.userInformation.firstName} ${userData.userInformation.lastName}`;
      this.newUserAdmin = false;
    },
  },
};
</script>
