<template>
  <v-container>
    <v-row justify="center">
      <v-dialog
        v-model="forgotPasswordDialog"
        max-width="500px"
      >
        <v-card class="elevation-1">
          <v-card-title>
            Forgot Password
          </v-card-title>
          <v-card-text>
            <p>
              Enter the email address associated with your Boardmatch account.<br>
              You will receive an email with a link to reset your password.
            </p>
            <validation-observer
              ref="validationObserver"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Email"
                rules="required"
              >
                <v-text-field
                  v-model="userName"
                  prepend-icon="mdi-account"
                  name="Email"
                  label="Email"
                  type="text"
                  :error-messages="errors"
                />
              </validation-provider>
              <v-col class="text-right">
                <v-btn
                  class="mr-4"
                  text
                  @click="forgotPasswordDialog = false"
                >
                  cancel
                </v-btn>
                <v-btn
                  class="mr-4"
                  color="success"
                  @click="submit"
                >
                  submit
                </v-btn>
              </v-col>
            </validation-observer>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { required } from 'vee-validate/dist/rules';
import {
  extend, ValidationObserver, ValidationProvider, setInteractionMode,
} from 'vee-validate';

setInteractionMode('eager');

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
});
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    loginUserName: {
      default: '',
      type: String,
    },
  },

  data: () => ({
    forgotPasswordDialog: false,
    userName: '',
    password: '',
    submitted: false,
  }),

  methods: {
    openDialog() {
      this.userName = this.loginUserName;
      this.forgotPasswordDialog = true;
    },
    async submit() {
      if (!await this.$refs.validationObserver.validate()) return;

      const result = await this.$api.auth.forgotPassword(this.userName);

      this.$bus.$emit('loading', true);

      if (!result) {
        this.$bus.$emit('showError', 'It seems you are not a BoardMatch user.');
        return;
      }

      this.$bus.$emit('loading', false);
      this.$emit('forgot-password-success');
      this.userName = '';
      this.forgotPasswordDialog = false;
    },

  },
};
</script>
