import Vue from 'vue';
import VueRouter from 'vue-router';
import BoardmatchAdminIndex from '@/views/boardmatch/admin/BoardmatchAdminIndex';
import CharityAdminIndex from '@/views/charity/charityAdmin/CharityAdminIndex';
import BoardmatchCandidates from '@/views/boardmatch/BoardmatchCandidates.vue';
import BoardmatchCharities from '@/views/boardmatch/BoardmatchCharities.vue';
import BoardmatchCorporations from '@/views/boardmatch/BoardmatchCorporations';
import BoardmatchTraining from '@/views/boardmatch/BoardmatchTraining';
import BoardmatchSocialMedia from '@/views/boardmatch/BoardmatchSocialMedia';
import Candidate from '@/views/candidate/Candidate';
import Charity from '@/views/charity/Charity';
import CharityProfile from '@/views/charity/CharityProfile';
import CharityOnboarding from '@/views/charity/CharityOnboarding';
import CharityFAQ from '@/views/charity/CharityFAQ';
import CharityPositions from '@/views/charity/CharityPositions';
import CharityPostings from '@/views/charity/CharityPostings';
import CandidateProfile from '@/views/candidate/CandidateProfile';
import CandidatePositions from '@/views/candidate/CandidatePositions';
import CandidateSearch from '@/views/candidate/CandidateSearch';
import CandidateTraining from '@/views/candidate/CandidateTraining';
import CandidateOnboarding from '@/views/candidate/CandidateOnboarding';
import CandidateFAQ from '@/views/candidate/CandidateFAQ';
import BoardmatchSearch from '@/views/boardmatch/BoardmatchSearch';
import UserRegistration from '@/views/registration/UserRegistration';
import CharityRegistration from '@/views/registration/CharityRegistration';
import BoardmatchDonations from '@/views/boardmatch/BoardmatchDonations';
import UserInformation from '@/views/user/UserInformation';
import UserResetPassword from '@/views/user/UserResetPassword';
import DemoPage from '@/views/debug/DemoPage';
import Search from '@/views/Search';
import Home from '../views/Home.vue';
import store from '../store';
import Login from '../views/Login.vue';

Vue.use(VueRouter);
let redirectUrl = store.state.user && store.state.user.userState && store.state.user.userState.lastState ? store.state.user.userState.lastState : store.getters['user/getBaseUrl'];
redirectUrl = store.getters['auth/isLoggedIn'] ? redirectUrl : '/login';
const routes = [
  {
    path: '/',
    redirect: () => redirectUrl,
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/registration',
    name: 'Registration',
    component: UserRegistration,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/charity-registration',
    name: 'Charity Registration',
    component: CharityRegistration,
    meta: {
      requiresAuth: false,
    },
  },

  // Staff section
  {
    path: '/boardmatch-search',
    name: 'Boardmatch Search',
    component: BoardmatchSearch,
    meta: { authorize: [] },
  },
  {
    path: '/boardmatch-candidates',
    name: 'Boardmatch Candidates',
    component: BoardmatchCandidates,
    meta: {
      authorize: [],
    },
  },
  {
    path: '/boardmatch-charities',
    name: 'Boardmatch Charities',
    component: BoardmatchCharities,
    meta: {
      authorize: [],
    },
  },
  {
    path: '/corporations',
    name: 'Corporations',
    component: BoardmatchCorporations,
  },
  {
    path: '/boardmatch-donations',
    name: 'Boardmatch Donations',
    component: BoardmatchDonations,
    meta: {
      authorize: [],
    },
  },
  {
    path: '/boardmatch-training',
    name: 'Boardmatch Training',
    component: BoardmatchTraining,
    meta: {
      authorize: [],
    },
  },
  {
    path: '/boardmatch-social-media',
    name: 'Boardmatch Training',
    component: BoardmatchSocialMedia,
    meta: {
      authorize: [],
    },
  },

  // User Section
  // Candidate section
  {
    path: '/candidate',
    name: 'candidate',
    component: Candidate,
    meta: {
      authorize: [{ claim: 'candidate.read', value: 1 }],
    },
  },
  {
    path: '/candidate-faq',
    name: 'candidate-faq',
    component: CandidateFAQ,
    meta: {
      authorize: [{ claim: 'candidate.read', value: 1 }],
    },
  },
  {
    path: '/candidate-search',
    name: 'candidate-search',
    component: CandidateSearch,
    meta: {
      authorize: [{ claim: 'candidate-search.read', value: 1 }],
    },
  },
  {
    path: '/candidate-onboarding',
    name: 'candidate-onboarding',
    component: CandidateOnboarding,
    meta: {
      authorize: [],
    },
  },
  {
    path: '/candidate-postings',
    name: 'posting',
    component: CandidatePositions,
    meta: {
      authorize: [
        { claim: 'posting.read', value: 1 },
        // { claim: 'candidate-thinkific-enrollment-complete', value: 1 }
      ],
    },
  },
  {
    path: '/candidate-training',
    name: 'candidate-training',
    component: CandidateTraining,
    meta: {
      authorize: [],
    },
  },
  {
    path: '/candidate-profile',
    name: 'candidate-profile',
    component: CandidateProfile,
    meta: {
      authorize: [],
    },
  },

  // Charity section
  //  {
  //    path: '/charity',
  //    name: 'charity',
  //    component: CharityCandidates,
  //    meta: { authorize: {involvement: 'charity', reads: 1, claim: 'charity'}},
  //  },
  {
    path: '/charity',
    name: 'charity',
    component: Charity,
    meta: { requiresAuth: false },
  },
  {
    path: '/charity-faq',
    name: 'charity-faq',
    component: CharityFAQ,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/charity-onboarding',
    name: 'charity-onboarding',
    component: CharityOnboarding,
    meta: { requiresAuth: false },
  },
  {
    path: '/charity-board',
    name: 'charity-board',
    component: CharityPositions,
    meta: {
      authorize: [{ claim: 'charity-board.read', value: 1 }],
    },
  },
  {
    path: '/charity-postings',
    name: 'charity-posting',
    component: CharityPostings,
    meta: {
      authorize: [{ claim: 'charity-posting.read', value: 1 }],
    },
  },
  {
    path: '/charity-profile',
    name: 'charity-profile',
    component: CharityProfile,
    meta: {
      authorize: [],
    },
  },
  // UserRegistration
  // {
  //   path: '/verification',
  //   name: 'verification',
  //   component: UserVerification,
  //   meta: {
  //     requiresAuth: false,
  //   },
  // },

  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      requiresAuth: false,
    },
  },

  {
    path: '/reset-password/:token',
    name: 'reset-password',
    component: UserResetPassword,
    meta: {
      requiresAuth: false,
    },
  },

  {
    path: '/user-information',
    name: 'user-information',
    component: UserInformation,
    meta: {
      authorize: [{ claim: 'user-information.read', value: 1 }],
    },
  },

  // Admin section
  {
    path: '/boardmatch-admin',
    name: 'boardmatch-admin',
    component: BoardmatchAdminIndex,
    meta: {
      authorize: [{ claim: 'boardmatch-admin.read', value: 1 }],
    },
  },
  {
    path: '/charity-admin',
    name: 'charity-admin',
    component: CharityAdminIndex,
    meta: {
      authorize: [{ claim: 'charity-admin.read', value: 1 }],
    },
  },
  // debug
  {
    path: '/debug',
    name: 'debug',
    component: DemoPage,
    meta: {
      authorize: [{ claim: 'candidate.read', value: 1 }],
    },
  },
  // General (available to all)
  {
    path: '/search',
    name: 'search',
    component: Search,
    meta: {
      authorize: [],
      forceSaveState: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
});
const candidatePaths = [
  '/candidate-search',
  '/candidate',
  '/candidate-onboarding',
  '/candidate-postings',
  '/candidate-training',
  '/candidate-profile',
  '/candidate-faq',
  '/search',
  '/debug',
  'candidate',
];
const charityPaths = [
  '/charity',
  '/charity-faq',
  '/charity-onboarding',
  '/charity-board',
  '/charity-postings',
  '/charity-profile',
];
// eslint-disable-next-line consistent-return
router.beforeEach((to, from, next) => {
  if (store.state.auth.isLoggedIn) {
    let redirectPath = '';
    if (candidatePaths.findIndex((x) => x === to.path) > -1) {
      // we're directing to a candidate module
      const onboardingFinished = store.getters['candidate/onboardingCompleted']();
      if (to.path === '/candidate-onboarding' && onboardingFinished && to.query.forceReroute) {
        // if last visit ended on onboarding and onboarding is finished, redirect to profile
        redirectPath = '/candidate-profile';
      }
      if ((from.path === '/login' || to.query.forceReroute) && to.path !== '/candidate-onboarding' && !onboardingFinished) {
        // if, upon login, last visit was not on onboarding, and onboarding is not finished, force the user back to it
        redirectPath = '/candidate-onboarding';
      }
      // in all other cases direct the user to their last visited candidate page
    } else if (charityPaths.findIndex((x) => x === to.path) > -1) {
      // we're directing to a charity module
      const onboardingFinished = store.getters['charity/onboardingCompleted']() && store.getters['charityRep/onboardingCompleted']();
      if (to.path === '/charity-onboarding' && onboardingFinished && to.query.forceReroute) {
        // if last visit ended on onboarding and onboarding is finished, redirect to profile
        redirectPath = '/charity-profile';
      }
      if ((from.path === '/login' || to.query.forceReroute) && to.path !== '/charity-onboarding' && !onboardingFinished) {
        // if, upon login, last visit was not on onboarding, and onboarding is not finished, force the user back to it
        redirectPath = '/charity-onboarding';
      }
    }
    // in all other cases direct the user to their last visited candidate page
    if (redirectPath !== '') {
      // update user state
      const updateState = {
        userId: store.state.user.nameId,
        lastState: redirectPath,
      };
      store.dispatch('user/updateUserState', updateState).then(() => {
        next(redirectPath);
        return true;
      });
    } else {
      const updateState = {
        userId: store.state.user.nameId,
        lastState: to.path,
      };
      store.dispatch('user/updateUserState', updateState);
    }
  }

  const { authorize } = to.meta;

  // no authorization required
  if (to.matched.some((record) => record.meta.requiresAuth === false)) {
    if (to.matched.some((record) => record.meta.forceSaveState === true && store.state.user.nameId)) {
      const updateState = {
        userId: store.state.user.nameId,
        lastState: to.path,
      };
      store.dispatch('user/updateUserState', updateState).then(() => {
        next();
      });
    } else next();
  } else if (authorize && store.state.auth.isLoggedIn) {
    // check if authorize properties, user is logged in
    if (authorize.length > 0) {
      // check if any authorizations fail
      const failedAuthorization = authorize.filter((element) => {
        if (!element.claim || !element.value) return true;
        const claimValue = store.getters['auth/findClaim'](element.claim);
        // eslint-disable-next-line eqeqeq
        if (claimValue != element.value) return true;
        return null;
      });

      if (failedAuthorization.length > 0) {
        next(from);
        return false;
      }
    }

    // update user state
    const updateState = {
      userId: store.state.user.nameId,
      lastState: to.path,
    };
    store.dispatch('user/updateUserState', updateState).then(() => {
      next();
    }).catch((e) => {
      console.log(e);
      next();
    });

    // navigate successfully
    // eslint-disable-next-line consistent-return
  } else {
    store.dispatch('auth/logout');
    next('/login');
    // eslint-disable-next-line consistent-return
  }
});

export default router;
