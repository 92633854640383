<template>
  <v-container
    pa-0
    style="position: relative; min-width: 100vw;  padding-right: 50px;"
  >
    <v-row
      v-if="!isLoginPage"
      style="width: 1280px; position: absolute; left: 50%; margin: 0 0 0 -640px;"
      dense
      class="header"
    >
      <div
        color="white"
        :height="isLoggedIn ? '110px' : '70px'"
        style="width: 100%"
      >
        <v-row
          class="white-background"
          style="max-height: 100%;"
        >
          <v-col>
            <v-row
              style="height: 70px"
              no-gutters
            >
              <v-col
                cols="3"
                style="display: flex; align-items: center;"
              >
                <v-img
                  :src="require(`@/assets/${'Altruvest Full Logo - Blue Text.svg'}`)"
                  alt=""
                  max-height="38px"
                  contain
                  class="ml-3"
                />
              </v-col>
              <v-col
                cols="5"
                style="display: flex; align-items: center;"
              >
                <the-search-bar @triggerSearch="triggerSearch()" />
                <!--<v-text-field
                    v-model="searchString"
                    placeholder="Search BoardMatch..."
                    hide-details
                    filled
                    rounded
                    prepend-inner-icon="mdi-magnify"
                    class="search-text-field"
                    @keydown="search"
                    @click:prepend-inner="search"
                  />-->
              </v-col>
              <!-- <v-col
                  v-show="isSearchPage"
                  class="ml-5"
                >
                  <v-btn
                    x-large
                    outlined
                    type="submit"
                    color="default"
                    class="pa-0 filter-button ml-1"
                    @click="toggleFilters()"
                  >
                    <v-icon>{{ filtersExpanded ? 'mdi-close' : 'mdi-tune' }}</v-icon>
                  </v-btn>
                </v-col> -->
              <v-col
                cols="4"
                align-self="center"
                style="padding-right:10px ;"
                class="d-flex justify-end pt-1"
              >
                <v-menu
                  v-if="userInfo != null"
                  v-model="menuOpen"
                  content-class="elevation-0"
                  offset-y
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      color="#E9EFFD"
                      :min-width="'306px'"
                      min-height="56px"
                      depressed
                      v-bind="attrs"
                      append-icon="mdi-chevron-right"
                      :class="(menuOpen ? 'action-menu-button-active' : 'action-menu-button') "
                      v-on="on"
                    >
                      <v-row>
                        <v-col
                          cols="3"
                          class="ma-0 pa-0"
                          style="display: flex; align-items: center;"
                        >
                          <initials-portrait
                            style="margin-left: -1px;"
                            :first-name="userInfo.userInformation.firstName"
                            :last-name="userInfo.userInformation.lastName"
                            :image="userImage ? userImage : null"
                            max-height="50px"
                            max-width="50px"
                            :colour="userImage ? '' : `#${userInfo.profileColour}`"
                          />
                        </v-col>
                        <v-col
                          cols="8"
                          class="pt-5"
                        >
                          <v-row>
                            <v-col
                              cols="12"
                              class="d-flex justify-start ma-0 pa-0 subtitle-2 font-weight-bold"
                            >
                              {{ menuTitle }}
                            </v-col>
                          </v-row>
                          <v-row class="mt-1 pa-0">
                            <v-col
                              cols="12"
                              class="d-flex justify-start ma-0 mt-1 pa-0 caption"
                            >
                              {{ menuSubtitle }}
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col
                          cols="1"
                          style="display: flex; align-items: center;"
                          class="pa-0"
                        >
                          <v-icon class="ma-0 pa-0">
                            {{ menuOpen ? 'mdi-chevron-down' : 'mdi-chevron-right' }}
                          </v-icon>
                        </v-col>
                      </v-row>
                    </v-btn>
                  </template>
                  <v-card
                    elevation="0"
                    class="action-menu-dropdown"
                    style="background-color: #E9EFFD"
                  >
                    <v-list
                      style="background-color: #E9EFFD"
                    >
                      <v-list-item
                        v-for="(item, index) in userInvolvements"
                        :key="index"
                      >
                        <v-list-item-title>
                          <v-btn
                            depressed
                            color="white"
                            width="100%"
                            @click="selectedInv = item"
                          >
                            <v-icon
                              v-show="selectedInv === item"
                              class="mr-1"
                              color="success"
                            >
                              mdi-check
                            </v-icon>
                            {{ involvementRole(item) }}
                          </v-btn>
                        </v-list-item-title>
                      </v-list-item>
                      <!-- <v-divider /> -->
                      <!-- <v-list-item
                          class="mt-0 pt-0 mb-0 pb-0"
                          dense
                        >
                          <v-row>
                            <v-col
                              cols="12"
                              class="subtitle-1 font-weight-bold mt-0 pt-0 mb-0 pb-0"
                            >
                              Personal Account
                            </v-col>
                          </v-row>
                        </v-list-item> -->
                      <!-- <v-list-item class="d-flex justify-center">
                        <div>
                          <v-row>
                            <v-col
                              cols="12"
                              class="text-left mt-0 pt-0 mb-0 pb-0"
                            >
                              <router-link
                                to="/candidate"
                                style="text-decoration: none;"
                              >
                                Profile & Settings
                              </router-link>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col
                              cols="12"
                              class="text-left mt-0 pt-0"
                            >
                              <a @click="menuActionClick('faq')">FAQ</a>
                            </v-col>
                          </v-row>
                        </div>
                      </v-list-item> -->
                      <!-- <v-divider /> -->
                      <v-list-item>
                        <v-btn
                          depressed
                          color="white"
                          width="100%"
                          @click="menuActionClick('logout')"
                        >
                          <v-icon color="error">
                            mdi-logout
                          </v-icon>
                          LOGOUT
                        </v-btn>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-menu>
                <v-btn
                  v-else
                  href="/login"
                  elevation="0"
                  outlined
                  color="primary"
                >
                  Sign-in / Join now
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="pa-0 ma-0">
              <v-divider
                v-if="isLoggedIn"
                class="pa-0 ma-0"
              />
            </v-row>
            <v-row
              v-if="isLoggedIn"
              style="background-color: white;"
              class="mt-0 pt-0 pb-0"
              justify="center"
            >
              <v-col
                cols="10"
                class="pt-1 pl-0 pb-0 d-flex justify-center"
              >
                <div>
                  <v-tabs
                    ref="tabs"
                    dark
                    background-color="white"
                    height="39"
                    style="color:black;"
                  >
                    <v-tab
                      v-for="(item, index) in navigationItems"
                      :key="'nav-'+index"
                      :to="item.link"
                      :class="`${currentlySelected(item) ? 'navigation-button-selected' : 'navigation-button'} flex mt-0 mb-0 pt-0 pb-0`"
                      style="color: #000000 !important; background-color: #ffffff !important;"
                      :disabled="item.disabled"
                    >
                      {{ item.title }}
                    </v-tab>
                  </v-tabs>
                </div>
              </v-col>
              <v-col
                v-if="!isSelf()"
                class="mt-2 pt-0 pl-0 pb-0 d-flex justify-center"
                cols="2"
              >
                <v-btn
                  depressed
                  color="white"
                  class="red--text"
                  @click="openLogInForm()"
                >
                  Back To Admin
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-row>
    <charity-edit-template-dialog
      ref="charityEditTemplateDialog"
      title="Update Charity"
      :charity="charity"
    />
    <charity-f-a-q-dialog ref="charityFaq" />
    <candidate-f-a-q-dialog ref="candidateFaq" />
    <user-login-dialog
      ref="userLoginDialog"
      :title="'Authenticate'"
      :login-as="true"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import CharityEditTemplateDialog from '@/components/charity/CharityEditTemplateDialog';
import CharityFAQDialog from '@/components/infoForms/CharityFAQDialog';
import CandidateFAQDialog from '@/components/infoForms/CandidateFAQDialog';
import TheSearchBar from '@/components/app/TheSearchBar.vue';
import InitialsPortrait from '@/components/general/InitialsPortrait';
import UserLoginDialog from '@/components/user/UserLoginDialog';
import Router from '@/router/index.js';

export default {
  components: {
    CharityEditTemplateDialog,
    CharityFAQDialog,
    CandidateFAQDialog,
    TheSearchBar,
    InitialsPortrait,
    UserLoginDialog,
  },
  data() {
    return {
      menuOpen: false,
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.auth.isLoggedIn,
      // isSelf: (state) => state.auth.isSelf,
      isCharity: (state) => state.auth.isCharity,
      charity: (state) => state.charity.charity,
      userInvolvements: (state) => state.user.sortedUserInvs,
      userState: (state) => state.user.userState,
      userInfo: (state) => state.user.userInfo,
      candidateOnboarding: (state) => state.candidate.onboarding,
      charityOnboarding: (state) => state.charity.onboarding,
      charityRepOnboarding: (state) => state.charityRep.onboarding,
      userImage: (state) => state.user.profileImage,
    }),
    isLoginPage() {
      return Router.app.$route.path === '/login';
    },
    isSearchPage() {
      return Router.app.$route.path === '/search';
    },
    ...mapGetters({
      getBaseUrl: 'user/getBaseUrl',
      getSelectedInv: 'user/getSelectedInv',
      findClaim: 'auth/findClaim',
      onboardingCompleted: 'candidate/onboardingCompleted',
      isExpanded: 'navigation/expanded',
    }),
    filtersExpanded: {
      get() {
        return this.isExpanded;
      },
      set(value) {
        if (value != null) {
          this.$store.dispatch('navigation/expandFilters');
        }
      },
    },
    menuButtonStyle() {
      return this.menuOpen ? 'border-top-left-radius: 26px; border-top-right-radius: 26px; border-bottom-left-radius: 0px; border-bottom-right-radius: 0px;' : 'border-radius: 26px;';
    },
    menuActionsStyle() {
      return '';
    },
    navigationItems() {
      const items = [];
      if (!this.selectedInv) return items;
      switch (this.selectedInv.involvement) {
        case 'boardmatch':
          if (this.findClaim('boardmatch-search.read') === '1') {
            items.push({
              icon: 'mdi-magnify',
              title: 'ADMIN SEARCH',
              disabled: false, // TODO: will be used to drive disabled (but visible) navigation menu items.
              link: '/boardmatch-search',
            });
          }

          if (this.findClaim('candidates.read') === '1') {
            items.push({
              icon: 'mdi-account-tie',
              title: 'CANDIDATES',
              disabled: false,
              link: '/boardmatch-candidates',
            });
          }

          if (this.findClaim('charities.read') === '1') {
            items.push({
              icon: 'mdi-hand-heart-outline',
              title: 'CHARITIES',
              disabled: false,
              link: '/boardmatch-charities',
            });
          }

          /* items.push({
            icon: 'mdi-currency-usd',
            title: 'TRAINING',
            disabled: false,
            link: '/boardmatch-training',
          }); */

          // items.push({
          //   icon: 'mdi-hand-heart-outline',
          //   title: 'SOCIAL MEDIA',
          //   disabled: false,
          //   link: '/boardmatch-social-media',
          // });

          // items.push({
          //   icon: 'mdi-currency-usd',
          //   title: 'DONATIONS',
          //   disabled: false,
          //   link: '/boardmatch-donations',
          // });

          if (this.findClaim('boardmatch-admin.read') === '1') {
            items.push({
              icon: 'mdi-account-circle',
              title: 'ADMIN',
              disabled: false,
              link: '/boardmatch-admin',
            });
          }
          break;

        case 'candidate':
          items.push({
            icon: 'mdi-account',
            title: 'MY DASHBOARD',
            disabled: this.onboardingNeeded,
            link: '/candidate-profile',
            // action: "openThinkificCourse"
          });
          items.push({
            icon: 'mdi-exclamation-thick',
            title: `${this.onboardingNeeded ? 'ONBOARDING' : 'PROFILE'}`,
            color: 'red',
            disabled: false,
            link: '/candidate-onboarding',
          });

          if (this.findClaim('posting.read') === '1' && this.onboardingCompleted) {
            items.push({
              icon: 'mdi-briefcase-account',
              title: 'MY APPLICATIONS',
              disabled: this.onboardingNeeded,
              link: '/candidate-postings',
            });
          }
          /* items.push({
            icon: 'mdi-school',
            title: 'TRAINING',
            disabled: false,
            link: '/candidate-training',
            // action: "openThinkificCourse"
          }); */
          items.push({
            icon: 'mdi-account',
            title: 'FAQ',
            disabled: false,
            link: '/candidate-faq',
            // action: "openThinkificCourse"
          });
          break;

        case 'charityRepresentative':
          items.push({
            icon: 'mdi-account',
            title: 'CHARITY DASHBOARD',
            disabled: this.onboardingNeeded,
            link: '/charity-profile',
            // action: "openThinkificCourse"
          });
          items.push({
            icon: 'mdi-hand-heart-outline',
            title: `${this.onboardingNeeded ? 'ONBOARDING' : 'PROFILE'}`,
            disabled: false,
            link: '/charity-onboarding',
          });
          if (this.findClaim('charity-board.read') === '1') {
            items.push({
              icon: 'mdi-briefcase-account',
              title: 'BOARD',
              disabled: this.onboardingNeeded,
              link: '/charity-board',
            });
          }
          if (this.findClaim('charity-posting.read') === '1') {
            items.push({
              icon: 'mdi-account-box-outline',
              title: 'POSTED POSITIONS',
              disabled: this.onboardingNeeded,
              link: '/charity-postings',
            });
          }
          items.push({
            icon: 'mdi-account',
            title: 'FAQ',
            disabled: false,
            link: '/charity-faq',
            // action: "openThinkificCourse"
          });
          if (this.findClaim('charity-admin.read') === '1') {
            items.push({
              icon: 'mdi-account-circle',
              title: 'ADMIN',
              disabled: this.onboardingNeeded,
              link: '/charity-admin',
            });
          }

          break;
        default:
          break;
      }
      return items;
    },

    selectedInv: {
      get() {
        return this.getSelectedInv;
      },
      set(value) {
        const baseUrl = `/${this.getBaseUrl}`;

        const newState = {
          id: 0,
          userId: this.$store.state.user.nameId,
          candidateRole: 0,
          charityRole: 0,
          boardmatchRole: 0,
          charityId: null,
          lastState: baseUrl,
        };

        switch (value.involvement) {
          case 'boardmatch':
            newState.boardmatchRole = 1;
            break;
          case 'candidate':
            newState.candidateRole = 1;
            break;
          case 'charityRepresentative':
            newState.charityRole = 1;
            newState.charityId = value.charityId;
            break;
          default:
            break;
        }

        this.$store.commit('user/storeUserState', newState);
        this.storeSelectedInv(value);
        this.$store.dispatch('user/submitUserState').then(() => {
          this.$store.dispatch('auth/refreshToken').then(() => {
            const newBaseUrl = `${this.getBaseUrl}?forceReroute=true`;
            if (this.$route.name !== newBaseUrl) this.$router.push(`/${newBaseUrl}`);
            else document.location.reload();
          });
        });
      },
    },
    involvementName() {
      let involvementName = '';
      if (!this.selectedInv) return involvementName;
      switch (this.selectedInv.involvement) {
        case 'boardmatch':
          involvementName = 'ALTRUVEST';
          break;
        case 'candidate':
          involvementName = 'ALTRUVEST PLEDGER';
          break;
        case 'charityRepresentative':
          involvementName = `${this.charity && this.charity.name != null ? this.charity.name.toUpperCase() : ''}`;
          break;
        default:
          break;
      }
      return involvementName;
    },
    menuTitle() {
      let fullName = `${this.userInfo.userInformation.firstName} ${this.userInfo.userInformation.lastName}`;
      if (fullName.length > 19) {
        // enforce a 9 character limit per name, with 1 extra character for the space in between.
        let { firstName, lastName } = this.userInfo.userInformation;
        if (firstName.length < 9) {
          // first name is shorter than the 9 character limit, so take the remainder and allow more characters for last name
          lastName = `${lastName.substring(0, (9 + (9 - firstName.length)))}...`;
        } else if (lastName.length < 9) {
          // last name is shorter than the 9 character limit, so take the remainder and allow more characters for first name
          firstName = `${firstName.substring(0, (9 + (9 - lastName.length)))}...`;
        } else {
          // both names are longer than 9 characters, cut both down
          firstName = `${firstName.substring(0, 9)}...`;
          lastName = `${lastName.subString(0, 9)}...`;
        }
        fullName = `${firstName} ${lastName}`;
      }
      return fullName;
    },
    menuSubtitle() {
      let involvementName = '';
      if (!this.selectedInv) return involvementName;
      if (!this.isSelf && !this.isCharity) return 'Candidate View';
      if (!this.isSelf && this.isCharity) return this.involvementName.length > 14 ? `'${this.involvementName.substring(0, 14)}...' View` : `'${this.involvementName}' View`;
      switch (this.selectedInv.involvement) {
        case 'boardmatch':
          involvementName = 'BoardMatch Admin View';
          break;
        case 'candidate':
          involvementName = 'Candidate View';
          break;
        case 'charityRepresentative':
          involvementName = this.involvementName.length > 14 ? `'${this.involvementName.substring(0, 14)}...' View` : `'${this.involvementName}' View`;
          break;
        default:
          break;
      }
      return involvementName;
    },
    involvementColor() {
      let involvementColor = '';
      if (!this.selectedInv) return involvementColor;
      switch (this.selectedInv.involvement) {
        case 'boardmatch':
          involvementColor = 'red';
          break;
        case 'candidate':
          involvementColor = 'green';
          break;
        case 'charityRepresentative':
          involvementColor = 'blue';
          break;
        default:
          break;
      }
      return involvementColor;
    },
    onboardingNeeded() {
      if (this.selectedInv.involvement === 'candidate') {
        const onboarding = this.candidateOnboarding.filter((o) => o.onboardingId !== 4);
        const index = onboarding.findIndex((x) => x.completedPercent < 100);
        if (index > -1) return true;
      } else if (this.selectedInv.involvement === 'charityRepresentative') {
        let index = this.charityOnboarding.findIndex((x) => x.completedPercent < 100);
        if (index > -1) return true;
        const onboarding = this.charityRepOnboarding.filter((o) => o.onboardingId !== 8);
        index = onboarding.findIndex((x) => x.completedPercent < 100);
        if (index > -1) return true;
      }
      return false;
    },
    collapseLogo() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true;
        case 'sm': return true;
        case 'md': return false;
        case 'lg': return false;
        case 'xl': return false;
        default: return false;
      }
    },
  },
  methods: {
    ...mapMutations('user', [
      'storeSelectedInv',
    ]),
    isSelf() {
      return this.findClaim('isSelf') === 'True';
    },
    openLogInForm() {
      this.$refs.userLoginDialog.openForm();
    },
    toggleFilters() {
      this.filtersExpanded = !this.filtersExpanded;
      /* if (!this.filtersExpanded) {
        this.selectedCity = null;
        this.selectedPositions = [];
        this.selectedPostingDateRange = [];
      } */
    },
    currentlySelected(button) {
      return button.link === this.$route.path;
    },
    hideDrawer() {
      this.$emit('hideDrawer');
    },
    logout() {
      this.$store.dispatch('auth/logout').then(() => {
        this.$router.push('/login');
      });
    },
    openCharityUpdateForm() {
      // this.$refs.charityForm.closeCharityForm()
      this.charityFormTitle = 'Update Charity';
      this.charity = JSON.parse(JSON.stringify(this.selectedInv.charityId));
      this.$refs.charityEditTemplateDialog.openCharityForm();
    },
    involvementRole(item) {
      if (item.involvement === 'candidate') return 'Candidate';
      if (item.involvement === 'charityRepresentative') {
        let name = item.name.replace('Charity ', '');
        if (name.length > 12) name = `${name.substring(0, 12)}...`;
        return `'${name}' Rep`;
      }
      if (item.involvement === 'boardmatch') {
        return `${item.name.replace('Altruvest Boardmatch', 'BoardMatch')} Admin`;
      }
      return '';
    },

    openFaqForm() {
      if (!this.selectedInv || !this.selectedInv.involvement) return;

      if (this.selectedInv.involvement === 'charityRepresentative') {
        this.$refs.charityFaq.toggleDialog();
      } else if (this.selectedInv.involvement === 'candidate') {
        this.$refs.candidateFaq.toggleDialog();
      }
    },
    menuActionClick(action) {
      if (action === 'logout') {
        this.logout();
      } else if (action === 'openCharityUpdateForm') {
        this.openCharityUpdateForm();
      } else if (action === 'openThinkificCourse') {
        this.openThinkificCourse();
      } else if (action === 'faq') {
        switch (this.selectedInv.involvement) {
          case 'candidate':
            this.$refs.candidateFaq.toggleDialog();
            break;
          case 'charityRepresentative':
            this.$refs.charityFaq.toggleDialog();
            break;
          default:
            break;
        }
      }
    },
    triggerSearch() {
      this.$emit('triggerSearch');
    },
  },
};
</script>
<style scoped>
  .action-menu-dropdown {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 26px;
    border-bottom-right-radius: 26px;
    width: 306px;
  }
  .action-menu-button {
    border-radius: 27px;
  }
  .action-menu-button-active {
    border-top-left-radius: 27px;
    border-top-right-radius: 27px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  button.v-btn.action-menu-button:focus
  {
    opacity: 1 !important;
  }
  .navigation-button {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .navigation-button.v-btn:hover {
    border-bottom-width: 3px !important;
    border-bottom-color: rgba(200, 200, 200) !important;
    border-bottom-style: solid !important;
  }
  .navigation-button-selected {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-width: 3px !important;
    background-color: white !important;
    border-bottom-color: rgba(117.3, 117.3, 117.3) !important;
    border-bottom-style: solid !important;
  }
  .header-sides{
    background-color: white;
  }
  .white-background {
    background-color: white;
  }
  a.v-btn--active.v-btn.v-btn--has-bg.v-btn--router.theme--light.v-size--default.white.navigation-button-selected.flex.mt-0.mb-0.pt-0.pb-0::before{
    background-color: white;
  }
  .custom-app-bar {
    overflow-x: auto; /* Enable horizontal scrollbar when necessary */
    overflow-y: hidden; /* Hide vertical scrollbar */
  }
</style>
<style>
  div.search-text-field.v-text-field div.v-input__control div.v-input__slot {
    min-height: auto !important;
    height: 40px;
    display: flex !important;
    align-items: center !important;
    padding-left: 5px;
    padding-right: 5px;
  }
  div.search-text-field.v-text-field div.v-input__slot div.v-input__prepend-inner{
    margin-top:8px;
    margin-right: 5px;
    margin-left: 5px;
    border-right: solid 1px rgba(200, 200, 200);
  }
  div.search-text-field.v-text-field div.v-input__control div.v-input__slot div.v-text-field__slot{
    margin-left: 5px;

  }
  div.v-tabs div div i.v-icon {
    color:black;
  }
  div.v-tabs div div.v-slide-group__prev--disabled
 {
  display: none;
 }
  div.v-tabs div div.v-slide-group__next--disabled
 {
  display: none;
 }
</style>
