import axios from 'axios';
import Api from '@/api/Api';

class CharityPostingsApi extends Api {
  constructor() {
    super('charities/postings', 'Charities Postings');
  }

  async sharePosting(sharePostingDTO) {
    let res;
    const errorText = 'Failed to share posting';
    await axios.post(`/${this.endpoint}/share`, sharePostingDTO)
      .then((response) => {
        res = response.data;
      })
      .catch((error) => {
        if (error.response) {
        // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
        // The request was made but no response was received
          console.log(error.request);
        } else {
        // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(errorText);
        res = false;
      });
    return res;
  }
}
const charityPostingsApi = new CharityPostingsApi();
export default charityPostingsApi;
