/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable consistent-return */
/* eslint-disable no-async-promise-executor */
/* eslint-disable no-param-reassign */
import { createPaginationObject } from '@/helpers/PaginationHelper.js';
import CharityRepApi from '@/api/charities/charityRep/CharityRepApi';
import CharityRepRolesApi from '@/api/charities/charityRep/CharityRepRolesApi';
import CharityRepOnboardingApi from '@/api/charities/charityRep/CharityRepOnboardingApi';

export default {
  namespaced: true,

  state: () => ({
    charityRep: null,
    charityRepRoles: [],
    permissions: [],
    onboarding: [],
    onboarded: false,
  }),
  mutations: {

    clearData(state) {
      state.charityRep = null;
      state.charityRepRoles = [];
      state.permissions = [];
      state.onboarding = [];
      state.onboarded = false;
    },

    storeCharityRep(state, rep) {
      state.charityRep = rep;
    },

    storeCharityRepRoles(state, list) {
      state.charityRepRoles = list;
    },
    storeCharityRepOnboarding(state, data) {
      state.onboarding = data;
    },
    storeOnboardingState(state, data) {
      state.onboarded = data;
    },
  },
  actions: {
    getCharityRep({ commit }, id) {
      return new Promise(async (resolve) => {
        const filters = [{ name: 'CharityRepId', values: [id] }, { name: 'IsDisabled', values: [0] }];
        const search = createPaginationObject(null, null, filters);
        const res = await CharityRepApi.read(search);
        const [first] = res.charityReps;
        commit('storeCharityRep', first);
        resolve();
      });
    },
    getCharityRepOnboarding({ commit }, id) {
      return new Promise(async (resolve) => {
        const filters = [{ name: 'CharityRepId', values: [id] }, { name: 'IsDisabled', values: [0] }];
        const search = createPaginationObject(null, null, filters);
        const onboarding = await CharityRepOnboardingApi.read(search);
        commit('storeCharityRepOnboarding', onboarding);
        resolve();
      });
    },

    getRoles({ commit }) {
      return new Promise(async (resolve) => {
        const res = await CharityRepRolesApi.get();
        if (res) commit('storeCharityRepRoles', res);

        resolve();
      });
    },
    saveOnboardingState({ commit }, status) {
      return new Promise(async (resolve) => {
        commit('storeOnboardingState', status);
        resolve();
      });
    },
  },
  getters: {
    // legacy claims
    /*        findClaim: (state) => (claimName) => {

            const claim = state.permissions.find(r => r.charityRepresentativeClaim.name === claimName)
            return claim
        } */
    onboardingCompleted: (state) => () => {
      const completed = state.onboarded;
      return completed;
    },
  },
};
