<template>
  <v-container
    fluid
    class="pa-2 pb-2 ma-2 mt-12"
    style="background-color: white; border-radius: 8px;"
  >
    <v-row>
      <v-col
        cols="12"
        class="pa-6"
      >
        <h1>Candidates</h1>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col>
        <v-tabs
          v-model="isDisabledTab"
          center-active
        >
          <v-tab>Active</v-tab>
          <v-tab>Inactive</v-tab>
        </v-tabs>
      </v-col>
      <v-col class="text-end pa-3 pr-6">
        <v-btn
          color="primary"
          outlined
          @click="openCandidateCreateForm"
        >
          <v-icon
            small
            class="mr-1"
          >
            mdi-plus
          </v-icon>
          New Candidate
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <candidate-table
          ref="candidateTable"
          :filters="filters"
        >
          <template slot-scope="{item}">
            <template>
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <font-awesome-icon
                    class="icon-hover mr-2"
                    :icon="['fabs', 'user-pen']"
                    v-bind="attrs"
                    v-on="on"
                    @click="authAs(item)"
                  />
                </template>
                <span>Login As (To Edit Candidate Details)</span>
              </v-tooltip>
              <v-tooltip
                v-if="isDisabledTab == 0"
                top
              >
                <template #activator="{ on, attrs }">
                  <font-awesome-icon
                    class="icon-hover mr-3"
                    v-bind="attrs"
                    :icon="['fabs', 'xmark']"
                    v-on="on"
                    @click="disableCandidate(item.id)"
                  />
                </template>
                <span>Deactivate candidate</span>
              </v-tooltip>
            </template>
            <template>
              <v-tooltip
                v-if="isDisabledTab == 1"
                top
              >
                <template #activator="{on, attrs}">
                  <font-awesome-icon
                    class="icon-hover mr-3"
                    :icon="['fabs', 'circle-plus']"
                    v-bind="attrs"
                    v-on="on"
                    @click="disableCandidate(item.id)"
                  />
                </template>
                <span>Activate candidate</span>
              </v-tooltip>
            </template>
            <v-tooltip top>
              <template #activator="{on, attrs}">
                <font-awesome-icon
                  class="icon-hover"
                  style="color:red"
                  :icon="['fabs', 'trash-can']"
                  v-bind="attrs"
                  v-on="on"
                  @click="softDelete(item.id)"
                />
              </template>
              <span>Delete candidate</span>
            </v-tooltip>
          </template>
        </candidate-table>
      </v-col>
    </v-row>
    <candidate-template-dialog
      ref="candidateTemplateDialog"
      :title="candidateDialogTitle"
      :user="user"
      @candidate-success="notifySuccess"
    >
      <template slot-scope="{item}">
        <v-btn
          v-if="currentUser != null && item != null && currentUser.id != item.id"
          class="mr-4"
          color="primary"
          @click="authAs(item)"
        >
          Log In As User
        </v-btn>
      </template>
    </candidate-template-dialog>
    <user-authenticate-as-dialog
      ref="authenticateAsDialog"
    />
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import CandidateTemplateDialog from '@/components/candidate/CandidateTemplateDialog';
import CandidateTable from '@/components/candidate/CandidateTable.vue';
import UserAuthenticateAsDialog from '@/components/user/UserAuthenticateAsDialog';

export default {
  components: {
    CandidateTemplateDialog,
    CandidateTable,
    UserAuthenticateAsDialog,
  },
  data: () => ({
    candidates: [],
    candidatesPerPageProps: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    candidatesPerPage: 20,
    loading: false,
    user: null,
    isDisabledTab: 0,
    candidateDialogTitle: 'Create Candidate',
    pagination: null,
    total: 0,
  }),
  computed: {
    filters() {
      return [
        { name: 'IsDisabled', values: [`${this.isDisabledTab}`] },
        { name: 'AdminOverride', values: [1] },
      ];
    },
    ...mapState({
      currentUser: (state) => state.user,
    }),
  },

  methods: {
    openCandidateCreateForm() {
      this.candidateDialogTitle = 'Create Candidate';
      this.user = null;
      this.$refs.candidateTemplateDialog.openCandidateForm();
    },
    openCandidateUpdateForm(candidate) {
      this.candidateDialogTitle = 'Update Candidate';
      this.user = JSON.parse(JSON.stringify(candidate.user));
      this.user.candidate = JSON.parse(JSON.stringify(candidate));
      this.$refs.candidateTemplateDialog.openCandidateForm();
    },

    notifySuccess(message) {
      this.$bus.$emit('showSuccess', message);
      this.$refs.candidateTable.refresh();
    },

    async disableCandidate(candidateId) {
      if (!confirm('Are you sure you would like to deactivate this candidate?')) return;
      const response = await this.$api.candidate.disableCandidate(candidateId);
      if (response === false) return;
      this.notifySuccess('Candidate disabled');
    },
    async softDelete(candidateId) {
      if (!confirm('Are you sure you would like to delete this candidate?')) return;
      const response = await this.$api.candidate.softDelete(candidateId);
      if (response === true) {
        this.notifySuccess('Candidate deleted');
      } else {
        this.$bus.$emit('showError');
      }
    },
    authAs(user) {
      this.$refs.authenticateAsDialog.openDialog(user);
    },
  },
};
</script>

<style>
.icon-hover:hover {
  cursor: pointer;
}
</style>
