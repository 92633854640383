<template>
  <v-container fluid>
    <validation-observer ref="postingValObserver">
      <h2
        v-if="showHeader == true"
        class="mb-5"
      >
        Application Details
      </h2>
      <v-row>
        <v-col cols="12">
          <v-divider />
        </v-col>
        <v-col class="pt-1">
          <span class="text-h6 font-weight-bold">Charity:&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <span class="text-h5 font-weight-bold">{{ posting.charity.name }}</span><br><br>
          <span class="text-h6 font-weight-bold">Position:&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <span class="text-h5 font-weight-bold">{{ posting.charityPosition.name }}</span><br><br>
          <span class="text-h6 font-weight-bold">Mandate Period&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <span class="text-h7">{{ formattedStartDate }} - {{ formattedEndDate }} &nbsp;&nbsp; ({{ mandatePeriod }})</span><br><br>
          <span class="text-h6 font-weight-bold">Description</span><br>
          <div
            :style="!expandedDescription ? 'height: 6em; overflow: hidden; text-overflow: ellipsis; width: 100%; overflow-wrap: break-word;' : ''"
            class="grey--text text--darken-2 mb-2"
          >
            <span v-html="posting.description" />
          </div><a
            v-if="!expandedDescription"
            style="white-space: no-wrap;"
            @click="expandedDescription = true"
          >Show More</a>
          <a
            v-else
            style="white-space: no-wrap;"
            @click="expandedDescription = false"
          >Show Less</a><br>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <span class="text-h8">Please explain why are you a good candidate?</span><red-asterisk />
        </v-col>
        <v-col>
          <validation-provider
            v-slot="{ errors }"
            name="Notes"
            rules="required"
          >
            <v-textarea
              v-model="applicationData.description"
              solo
              :error-messages="errors"
              name="input-7-4"
              rows="5"
            />
          </validation-provider>
        </v-col>
      </v-row>
    </validation-observer>
  </v-container>
</template>

<script>
import { required } from 'vee-validate/dist/rules';
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from 'vee-validate';

setInteractionMode('eager');
extend('required', {
  ...required,
  message: '{_field_} can not be empty',
});
extend('select-required', {
  async validate(value) {
    return !!value.id;
  },
  message: '{_field_} can not be empty',
});
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    posting: {
      type: Object,
      default: () => {},
    },
    positionId: {
      type: Number,
      default: 0,
    },
    showHeader: {
      type: Boolean,
      default: false,
    },
    editing: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    applicationData: {},
    charityPositionTypes: [],
    expandedDescription: false,
  }),
  computed: {
    formattedStartDate() {
      const startDate = new Date(this.posting.startDate);
      return startDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    },
    formattedEndDate() {
      const endDate = new Date(this.posting.endDate);
      return endDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    },
    formattedExpiryDate() {
      const endDate = new Date(this.posting.expiryDate);
      return endDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    },
    mandatePeriod() {
      const startDate = new Date(this.posting.startDate);
      const endDate = new Date(this.posting.endDate);

      const timeDiff = endDate - startDate; // Time difference in milliseconds
      const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

      if (daysDiff >= 7) {
        const weeksDiff = Math.floor(daysDiff / 7);
        return weeksDiff === 1 ? `${weeksDiff} week` : `${weeksDiff} weeks`;
      } if (daysDiff >= 365) {
        const yearsDiff = Math.floor(daysDiff / 365);
        return yearsDiff === 1 ? `${yearsDiff} year` : `${yearsDiff} years`;
      }
      return daysDiff === 1 ? `${daysDiff} day` : `${daysDiff} days`;
    },
  },
  watch: {
    posting: {
      immediate: true,
      handler(value) {
        if (!value) return;
        this.applicationData = {
          id: 0,
          postingId: value.id,
          charityId: value.charityId,
          description: '',
        };
      },
    },
    positionId: {
      immediate: true,
      handler(value) {
        if (value === 0) return;
        this.applicationData.charityPositionId = value;
      },
    },
  },
  methods: {
    async validate() {
      // eslint-disable-next-line no-return-await
      return await this.$refs.postingValObserver.validate();
    },

    async submit() {
      // eslint-disable-next-line no-return-await
      const res = await this.$api.candidateApplications.create(this.applicationData);
      return res;
    },
    // async closePosition(){
    //   if(!this.position.id) return false
    //   return await PositionApi.close(this.position.id)
    // },
    reset() {
      this.$refs.postingValObserver.reset();
    },
    formatDate(date) {
      if (!date) return '';
      return this.$moment(date).format('YYYY-MM-DD');
    },
  },
};
</script>
