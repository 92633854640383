var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[(_vm.title)?_c('v-col',{staticClass:"text-h6 pa-6",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_c('v-col',[_c('v-data-table',{staticStyle:{"width":"100%"},attrs:{"headers":_vm.headers,"items":_vm.candidates,"footer-props":_vm.footerProps,"items-per-page":_vm.itemsPerPage,"loading":_vm.loading,"server-items-length":_vm.total,"dense":"","option":_vm.options},on:{"pagination":_vm.updatePagination,"update:options":_vm.updateOptions},scopedSlots:_vm._u([{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{staticStyle:{"font-weight":"bold"},attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.user && item.user.userName ? item.user.userName : "-")+" ")])]}},{key:"item.firstName",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.user ? item.user.userInformation.firstName : "-")+" ")])]}},{key:"item.lastName",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.user ? item.user.userInformation.lastName : "-")+" ")])]}},{key:"item.skills",fn:function(ref){
var item = ref.item;
return [_vm._l((item.skills),function(skill,index){return [_c('text-highlight',{key:index,attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(skill.name)+_vm._s(item.skills.length == index + 1 ? "" : ",")+" ")])]})]}},{key:"item.causes",fn:function(ref){
var item = ref.item;
return [_vm._l((item.focusAreas),function(focusArea,index){return [_c('text-highlight',{key:index,attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(focusArea.name)+_vm._s(item.focusAreas.length == index + 1 ? "" : ",")+" ")])]})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_vm._t("default",null,{"item":item})]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }