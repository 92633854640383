var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('validation-observer',{ref:"repValObserver"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticStyle:{"font-size":"x-large","font-weight":"450"}},[_vm._v("Charity Representative Information")])]),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Board Position","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Select Role","error-messages":errors,"items":_vm.roles,"item-text":"name","required":"","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"black--text"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"label",fn:function(){return [_vm._v(" Select Role"),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"black--text"},[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true),model:{value:(_vm.submitData.charityRepresentativeRole),callback:function ($$v) {_vm.$set(_vm.submitData, "charityRepresentativeRole", $$v)},expression:"submitData.charityRepresentativeRole"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Email","rules":'required|email'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"required":"","disabled":_vm.charityRep && _vm.charityRep.id != null},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Email"),(_vm.charityRep && _vm.charityRep.id == null)?_c('red-asterisk'):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.submitData.email),callback:function ($$v) {_vm.$set(_vm.submitData, "email", $$v)},expression:"submitData.email"}})]}}])})],1),_c('v-row',{staticClass:"pa-3"},[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"required":"","error-messages":errors,"disabled":_vm.charityRep && _vm.charityRep.id != null},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" First Name"),(_vm.charityRep && _vm.charityRep.id == null)?_c('red-asterisk'):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.submitData.firstName),callback:function ($$v) {_vm.$set(_vm.submitData, "firstName", $$v)},expression:"submitData.firstName"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"required":"","disabled":_vm.charityRep && _vm.charityRep.id != null},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Last Name"),(_vm.charityRep && _vm.charityRep.id == null)?_c('red-asterisk'):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.submitData.lastName),callback:function ($$v) {_vm.$set(_vm.submitData, "lastName", $$v)},expression:"submitData.lastName"}})]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }