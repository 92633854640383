<template>
  <v-carousel
    v-if="images"
    v-model="carouselStep"
    :height="multipage ? `${(heightNumeric * pages.length) + 50}px` : `${heightNumeric}px`"
    :hide-delimiters="true"
    :show-arrows="false"
    style="margin-left: 0px !important;"
  >
    <v-carousel-item
      v-for="(page, index) in pages"
      :key="index"
    >
      <v-row>
        <v-col
          v-for="(img, imgIndex) in page.images"
          :key="img.name"
          cols="3"
        >
          <v-img
            :class="selectedImage.page === index && selectedImage.number === imgIndex ? 'selected-image' : ''"

            height="117px"
            width="400px"
            :src="(img && img.image ? 'data:' + img.image.mimeType + ';base64,' + img.image.file : '')"
            @click="selectImage(img, imgIndex)"
          />
        </v-col>
      </v-row>
    </v-carousel-item>
  </v-carousel>
</template>

<script>

export default {
  components: {
  },

  props:
  {
    images: {
      type: Array,
      default: () => [],
    },
    height: {
      type: String,
      default: '100',
    },
    perPage: {
      type: Number,
      default: 4,
    },
  },
  data: () => ({
    carouselStep: 0,
    selectedImage: {
      page: null,
      number: null,
    },
  }),
  computed: {
    pages() {
      const totalPages = Math.ceil(this.images.length / this.perPage);
      const pages = [];
      for (let i = 0; i < totalPages; i += 1) {
        const imagesOnPage = this.images.filter((image, index) => index >= (i * this.perPage) && (i * this.perPage + this.perPage));
        const page = {
          page: i,
          images: imagesOnPage,
        };
        pages.push(page);
      }
      return pages;
    },
    multipage() {
      return this.pages.length > 1;
    },
    heightNumeric() {
      const height = Number(this.height);
      return height;
    },
  },
  watch: {
  },
  methods: {
    selectImage(img, index) {
      this.selectedImage = {
        page: this.carouselStep,
        number: index,
      };
      this.$emit('imageSelected', img);
    },
    clearSelection() {
      this.selectedImage = {
        page: null,
        number: null,
      };
    },
  },
};
</script>
<style>
  .selected-image{
    border: solid 3px red;
  }
</style>
