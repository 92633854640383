<template>
  <v-dialog
    v-model="postingDialog"
    :hide-overlay="false"
    width="90%"
    content-class="mt-0 mb-0 "
  >
    <v-card
      fill-height
    >
      <v-container fluid>
        <v-row>
          <v-col
            v-if="title != null && title != ''"
            class="text-h4"
          >
            <font-awesome-icon
              size="xs"
              :icon="titleIcon"
            />
            {{ title }}
          </v-col>
          <v-col class="text-right">
            <v-btn
              icon
              @click="closePostingDialog"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <charity-summary
          v-if="showCharity && posting != null && posting.charity != null"
          ref="charitySummary"
          :show-header="showHeader"
          :charity-id="posting.charity.id"
          class="mb-10"
        />
        <charity-posting-information-form
          ref="postingInfoForm"
          :posting="posting"
          :charity="charity"
          :position-id="positionId"
          :readonly="!isEdit"
          :show-header="true"
          @posting-success="notifySuccess"
        />
        <candidate-application-form
          v-if="isApplication"
          ref="candidateApplicationForm"
          :show-header="true"
          :posting="posting"
          :charity="charity"
          @position-success="notifySuccess"
        />
        <v-row
          class="mt-0"
        >
          <v-col
            class="
            text-end"
          >
            <v-btn
              v-if="isApplication"
              class="mr-4"
              type="submit"
              color="success"
              @click="submitApplication"
            >
              apply
            </v-btn>
            <v-btn
              class="mr-4"
              text
              @click="closePostingDialog"
            >
              cancel
            </v-btn>
            <v-btn
              v-if="!isApplication"
              class="mr-4"
              type="submit"
              color="success"
              @click="submitPosting"
            >
              submit
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import CharityPostingInformationForm from '@/components/charity/charityPosting/CharityPostingInformationForm';
import CharitySummary from '@/components/charity/CharitySummary';
import CandidateApplicationForm from '@/components/candidate/application/CandidateApplicationForm';

export default {
  components: {
    CharityPostingInformationForm,
    CharitySummary,
    CandidateApplicationForm,
  },
  props:
    {
      posting: {
        type: Object,
        default: () => {},
      },
      title: {
        type: String,
        default: '',
      },
      charity: {
        type: Object,
        default: () => {},
      },
      showHeader: {
        type: Boolean,
        default: false,
      },
      showCharity: {
        type: Boolean,
        default: false,
      },
      isApplication: {
        type: Boolean,
        default: false,
      },
      titleIcon: {
        type: Array,
        default: () => [],
      },
    },

  data: () => ({
    postingDialog: false,
    positionId: 0,
    isEdit: true,
  }),

  methods: {
    openPostingDialog(positionId = 0, isEdit = null) {
      if (isEdit != null) this.isEdit = isEdit;
      this.positionId = positionId;
      this.postingDialog = true;
      this.$nextTick(async () => {
        this.$refs.charitySummary.loadCharity();
        this.$refs.postingInfoForm.getCharityPositions();
      });
    },
    closePostingDialog() {
      if (this.$refs.postingInfoForm) {
        this.$refs.postingInfoForm.reset();
      }
      this.postingDialog = false;
    },
    async submitPosting() {
      const validateResult = await Promise.all([
        this.$refs.postingInfoForm.validate(),
      ])
        .then((values) => !values.includes(false));

      if (!validateResult) return;
      this.$bus.$emit('loading', true);

      const res = await this.$refs.postingInfoForm.submit();
      this.$bus.$emit('loading', false);

      if (!res) {
        this.$bus.$emit('showError');
        return;
      }

      this.notifySuccess('Successfully Created New Posting.');
    },
    notifySuccess(msg) {
      this.$refs.postingInfoForm.reset();
      this.closePostingDialog();
      this.$emit('posting-success', msg);
    },
    async submitApplication() {
      const validateResult = await Promise.all([
        this.$refs.candidateApplicationForm.validate(),
      ])
        .then((values) => !values.includes(false));

      if (!validateResult) return;
      this.$bus.$emit('loading', true);

      const res = await this.$refs.candidateApplicationForm.submit();
      this.$bus.$emit('loading', false);
      this.$emit('posting-success');

      if (!res) {
        this.$bus.$emit('showError');
        return;
      }

      this.notifySuccess('Successfully Applied.');
    },

  },
};
</script>
<style>
  .full-height-dialog {
    height: 100% !important;
  }
</style>
