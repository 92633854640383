<template>
  <v-row>
    <v-col
      cols="12"
      class="pt-0 pb-0"
    >
      <span
        class="text-h4"
      ><b>Charities </b></span>
      <!-- <template v-if="total == 0 && loadingCharities == true">
        <v-progress-circular
          indeterminate
          color="grey"
          size="20"
          class="mb-2 mr-2"
        />
        <span class="text-h6 grey--text text--darken-2">Loading...</span>
      </template> -->
      <template>
        <!--v-else-->
        <span class="text-h6 grey--text text--darken-2">{{ total.toLocaleString() }} Result{{ total != 1 ? 's' : '' }}</span>
      </template>
    </v-col>
    <v-col
      v-for="(charity) in charities"
      :key="`char-${charity.id}`"
      cols="4"
    >
      <div
        class="white widget ma-2"
      >
        <search-charity-result-tile-widget
          :charity="charity"
          @showCharity="(charityId) => showCharity(charityId)"
          @showCharityPostingByCharity="(charityId) => showCharityPostingByCharity(charityId)"
        />
      </div>
    </v-col>
    <v-col
      v-if="total > 0 && charities.length > 0 && charities.length < total"
      cols="12"
      class="d-flex justify-center"
    >
      <v-btn
        id="#loadMoreCharitiesBtn"
        :disabled="loadingCharities"
        elevation="0"
        outlined
        @click="searchCharities(false)"
      >
        <v-progress-circular
          v-if="loadingCharities"
          indeterminate
          color="grey"
          class="mr-2"
        />{{ loadingCharities ? 'Loading...' : 'Load More' }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';
import { createPaginationObject } from '@/helpers/PaginationHelper.js';
import SearchCharityResultTileWidget from '@/components/search/SearchCharityResultTileWidget';

export default {
  components: {
    SearchCharityResultTileWidget,
  },

  props:
  {
    filters: {
      type: Array,
      default: () => [],
    },
    search: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    charities: [],
    loadingCharities: false,
    total: 0,
    options: {
      page: 1,
      itemsPerPage: 6,
      sortBy: [],
      include: ['ThumbnailImage', 'ProfileImage'],
    },
    charityThumbnailImages: [],
    charityProfileImages: [],
  }),
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.auth.isLoggedIn,
    }),
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    init() {
    },
    async searchCharities(reset = true) {
      if (reset && this.loadingCharities) return; // prevent double-invocation of initial search
      this.loadingCharities = true;
      if (reset) {
        this.charities = [];
        this.options.page = 1;
        this.total = 0;
      }
      const result = this.isLoggedIn
        ? await this.$api.charity.read(
          createPaginationObject(this.options, this.search, this.filters),
        )
        : await this.$api.charity.readPublic(
          createPaginationObject(this.options, this.search, this.filters),
        );
      if (result) {
        this.options.page += 1;
        this.total = result.total;
      }
      result.charities.forEach((charity) => {
        this.charities.push({ ...charity });
      });
      this.$emit('loaded', this.total);
      this.goto('loadMoreCharitiesBtn');
      this.loadingCharities = false;
    },
    goto(elementId) {
      const el = document.getElementById(`#${elementId}`);
      if (el != null) el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    },
    showCharity(id) {
      this.$emit('showCharity', id);
    },
    showCharityPostingByCharity(id) {
      this.$emit('showCharityPostingByCharity', id);
    },
  },
};
</script>
<style scoped>
  .widget{
    border-radius: 8px;
    padding: 12px;
  }
</style>
