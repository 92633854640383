<template>
  <v-container fluid>
    <v-row>
      <v-col
        v-if="title != ''"
        class="text-h5 font-weight-bold"
        cols="12"
      >
        {{ title }}
      </v-col>
    </v-row>
    <validation-observer ref="charityValidateObserver">
      <v-row>
        <v-col
          :cols="isSignup ? '12' : '10'"
        >
          <v-row>
            <v-col
              :cols="isSignup ? '6' : '4'"
              :class="isSignup ? 'pt-2 pb-0 pl-0' : 'pt-2 pb-0'"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Organization Name"
                rules="required"
              >
                <v-text-field
                  v-model="charityData.name"
                  required
                  :error-messages="errors"
                  label="Organization Name"
                  :readonly="isDisabled"
                  :style="isDisabled ? 'pointer-events: none' : ''"
                >
                  <template #label>
                    {{ `Organization Name` }}<red-asterisk v-if="!isDisabled" />
                  </template>
                </v-text-field>
              </validation-provider>
            </v-col>
            <v-col
              v-if="isSignup !== true"
              cols="4"
              class="pt-2 pb-0"
            >
              <v-text-field
                v-model="charityData.email.name"
                label="Email"
                :readonly="isDisabled"
                :style="isDisabled ? 'pointer-events: none' : ''"
              />
            </v-col>

            <v-col
              v-if="isSignup !== true"
              cols="2"
              class="pt-2 pb-0"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Scope"
                rules="required"
              >
                <v-select
                  v-model="charityData.charityScopeId"
                  :items="charityScopes"
                  :error-messages="errors"
                  label="Scope"
                  data-vv-name="select"
                  item-text="name"
                  item-value="id"
                  required
                  :readonly="isDisabled"
                  :style="isDisabled ? 'pointer-events: none' : ''"
                >
                  <template #label>
                    Scope<red-asterisk v-if="!isDisabled" />
                  </template>
                </v-select>
              </validation-provider>
            </v-col>
            <v-col
              v-if="isSignup !== true"
              cols="2"
              class="pt-2 pb-0"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Year of Incorporation"
                rules="required"
              >
                <v-select
                  v-model="charityData.yearOfIncorporation"
                  :items="years"
                  :error-messages="errors"
                  label="Year of Incorporation"
                  data-vv-name="select"
                  required
                  :readonly="isDisabled"
                  :style="isDisabled ? 'pointer-events: none' : ''"
                >
                  <template #label>
                    Year of Incorporation<red-asterisk v-if="!isDisabled" />
                  </template>
                </v-select>
              </validation-provider>
            </v-col>

            <v-col
              :cols="isSignup ? 6 : 4"
              class="pt-2 pb-0"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Registered Charitable Number"
                rules="required"
              >
                <v-text-field
                  v-model="charityData.chariableNumber"
                  :error-messages="errors"
                  label="Registered Charitable #/Not for Profit #:"
                  required
                  :readonly="isDisabled"
                  :style="isDisabled ? 'pointer-events: none;' : ''"
                >
                  <template #label>
                    Registered Charitable #/Not for Profit #:<red-asterisk v-if="!isDisabled" />
                  </template>
                </v-text-field>
              </validation-provider>
            </v-col>

            <v-col
              v-if="isSignup !== true"
              cols="4"
              class="pt-2 pb-0"
            >
              <validation-provider name="Website">
                <v-text-field
                  v-model="charityData.website"
                  label="Website"
                  :readonly="isDisabled"
                  :style="isDisabled ? 'pointer-events: none' : ''"
                />
              </validation-provider>
            </v-col>
            <v-col
              v-if="isSignup !== true"
              cols="4"
              class="pt-0 pb-0"
            >
              <p>
                Type of Organization<red-asterisk v-if="!isDisabled" />
              </p>
              <v-radio-group
                v-model="charityData.charityTypeId"
                row
              >
                <v-radio
                  v-for="(type) in charityTypes"
                  :key="type.id"
                  :label="type.name"
                  :value="type.id"
                  :readonly="isDisabled"
                  :style="isDisabled ? 'pointer-events: none' : ''"
                />
              </v-radio-group>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-if="isSignup !== true"
          style="position: relative;"
          cols="3"
        >
          Logo
          <v-img
            :src="charityImage ? ('data:' + charityImage.mimeType + ';base64,' + charityImage.file) : require('@/assets/place_holder.png')"
            height="200px"
            width="180px"
            class="mb-5"
            contain
          >
            <v-hover
              v-if="!isDisabled"
              v-slot="{ hover }"
              style="background-color: rgb(0,0,0,0.2);"
            >
              <v-btn
                height="100%"
                width="100%"
                :class="hover ? 'profile-picture-button-hover' : 'profile-picture-button'"
                @click="changePhoto()"
              >
                <v-icon>
                  mdi-pencil
                </v-icon>
              </v-btn>
            </v-hover>
          </v-img>
          <v-icon
            v-if="charityImage"
            style="position: absolute; top: 0; right: 0;"
            @click="deleteCharityImage()"
          >
            mdi-close
          </v-icon>
        </v-col>
        <v-col
          v-if="isSignup !== true"
          cols="9"
        >
          <span>Background Image</span>
          <v-img
            :src="backgroundImage ? ('data:' + backgroundImage.mimeType + ';base64,' + backgroundImage.file) : ''"
            max-height="200px"
            style="border: width 200px !important;"
            class="mb-5"
          >
            <v-hover
              v-if="!isDisabled"
              v-slot="{ hover }"
              style="background-color: rgb(0,0,0,0.2);"
            >
              <v-btn
                height="100%"
                width="100%"
                :class="hover ? 'profile-picture-button-hover' : 'profile-picture-button'"
                @click="editBackground()"
              >
                <v-icon>
                  mdi-pencil
                </v-icon>
              </v-btn>
            </v-hover>
          </v-img>
        </v-col>
      </v-row>
      <!--<v-row v-if="isSignup !== true">
        <v-col
          cols="4"
          class="pb-0"
        >
          <validation-provider
            v-slot="{ errors }"
            name="Organization Mandate"
            :rules="charityData.vision == null || charityData.vision == '' ? 'required' : ''"
          >
            <span>Organization Mandate<red-asterisk v-if="!isDisabled && (charityData.vision == null || charityData.vision == '')" /></span>
            <v-textarea
              v-model="charityData.mandate"
              solo
              :error-messages="errors"
              name="input-7-4"
              rows="5"
              :readonly="isDisabled"
              :style="isDisabled ? 'pointer-events: none' : ''"
            />
          </validation-provider>
        </v-col>
        <v-col
          cols="4"
          class="pb-0"
        >
          <validation-provider
            v-slot="{ errors }"
            name="Organization Vision"
            :rules="charityData.mandate == null || charityData.mandate == '' ? 'required' : ''"
          >
            <span>Organization Vision<red-asterisk v-if="!isDisabled && (charityData.mandate == null || charityData.mandate == '')" /></span>
            <v-textarea
              v-model="charityData.vision"
              solo
              :error-messages="errors"
              name="input-7-4"
              rows="5"
              :readonly="isDisabled"
              :style="isDisabled ? 'pointer-events: none' : ''"
            />
          </validation-provider>
        </v-col>
        <v-col
          cols="4"
          class="pb-0"
        >
          <span>Organization Quote</span>
          <v-textarea
            v-model="charityData.quote"
            solo
            name="input-7-4"
            rows="5"
            :readonly="isDisabled"
            :style="isDisabled ? 'pointer-events: none' : ''"
          />
        </v-col>
      </v-row>-->
      <v-row v-if="isSignup !== true">
        <v-col
          cols="6"
          class="pb-8"
        >
          <span>Organization Mandate<red-asterisk v-if="!isDisabled && (charityData.vision == null || charityData.vision == '')" />:</span>
          <span
            v-if="!charity.mandate && validationTriggered"
            class="red--text ml-2"
          >Must be filled</span>
          <validation-provider
            v-show="false"
            v-slot="{ errors }"
            :name="'Mission'"
            :rules="charityData.vision == null || charityData.vision == '' ? 'required' : ''"
          >
            <v-textarea
              v-model="charityData.mandate"
              :error-messages="errors"
            />
          </validation-provider>
          <vue-editor
            v-model="charityData.mandate"
            :editor-toolbar="customToolbar"
          />
        </v-col>
        <v-col
          cols="6"
          class="pb-8"
        >
          Preview:
          <general-text-widget
            title="Mission"
            class="mt-0"
            :html-text="charityData.mandate"
            preview
          />
        </v-col>
      </v-row>
      <v-row v-if="isSignup !== true">
        <v-col
          cols="6"
          class="pb-8"
        >
          <span>Organization Vision<red-asterisk v-if="!isDisabled && (charityData.mandate == null || charityData.mandate == '')" />:</span>
          <span
            v-if="!charityData.vision && validationTriggered"
            class="red--text ml-2"
          >Must be filled</span>
          <validation-provider
            v-show="false"
            v-slot="{ errors }"
            :name="'Vision'"
            :rules="charityData.mandate == null || charityData.mandate == '' ? 'required' : ''"
          >
            <v-textarea
              v-model="charityData.vision"
              :error-messages="errors"
            />
          </validation-provider>
          <vue-editor
            v-model="charityData.vision"
            :editor-toolbar="customToolbar"
          />
        </v-col>
        <v-col
          cols="6"
          class="pb-8"
        >
          Preview:
          <general-text-widget
            title="Vision"
            class="mt-0"
            :html-text="charityData.vision"
            preview
          />
        </v-col>
      </v-row>
      <v-row v-if="isSignup !== true">
        <v-col
          cols="6"
          class="pb-8"
        >
          <span>Quote:</span>
          <vue-editor
            v-model="charityData.quote"
            :editor-toolbar="customToolbar"
          />
        </v-col>
        <v-col
          cols="6"
          class="pb-8"
        >
          Preview:
          <general-quote-widget
            title="Quote"
            class="mt-0"
            :html-text="charityData.quote"
            preview
          />
        </v-col>
      </v-row>

      <v-row
        v-if="isSignup != true"
        class="mt-0 mb-6"
      >
        <v-col cols="12">
          <span>Please share your social media links here:</span>
          <br>

          <v-row
            v-for="socialMedia in socialMediaSubmit"
            :key="socialMedia.socialMediaTypeId"
            cols="12"
            class="mt-3 pb-0"
          >
            <v-col
              cols="2"
              class="pt-0 pb-0"
              style="display: flex; align-items: end;"
            >
              {{ socialMedia.name }}
            </v-col>
            <v-col
              cols="8"
              class="pa-0"
            >
              <v-text-field
                v-model="socialMedia.url"
                dense
                class="mb-0 pb-1"
                :hide-details="true"
                :disabled="isDisabled"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </validation-observer>
    <general-dialog
      v-if="charity"
      ref="charityImageSelectorDialog"
      title="Upload a new profile image"
      submit-button-label="Upload"
      dialog-width="600px"
      :submit-button-enabled="false"
      :suppress-footer="true"
      @submit="submitCharityImageChange()"
    >
      <charity-image-selector-template
        ref="charityImageSelector"
        :charity-id="charity.id"
        @success="loadCharityImage()"
      />
    </general-dialog>
    <general-dialog
      v-if="charity"
      ref="backgroundImageSelectorDialog"
      title="Select or Upload Background Image"
      submit-button-label="Apply Selected Background"
      dialog-width="1750px"
      :submit-button-enabled="false"
      @submit="submitBackgroundImageChange()"
    >
      <charity-background-image-selector-template
        ref="backgroundImageSelector"
        :charity="charity"
        @close="closeDialogs()"
        @backgroundChanged="loadBackgroundImage()"
      />
    </general-dialog>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import { required } from 'vee-validate/dist/rules';
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from 'vee-validate';
import { VueEditor } from 'vue2-editor';
import GeneralDialog from '@/components/general/GeneralDialog';
import CharityImageSelectorTemplate from '@/components/charity/profile/CharityImageSelectorTemplate';
import { createPaginationObject } from '@/helpers/PaginationHelper.js';
import GeneralTextWidget from '@/components/general/GeneralTextWidget.vue';
import GeneralQuoteWidget from '@/components/general/GeneralQuoteWidget.vue';
import CharityBackgroundImageSelectorTemplate from '@/components/charity/profile/CharityBackgroundImageSelectorTemplate';

setInteractionMode('eager');

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    GeneralDialog,
    CharityImageSelectorTemplate,
    CharityBackgroundImageSelectorTemplate,
    GeneralTextWidget,
    VueEditor,
    GeneralQuoteWidget,
  },
  props: {
    charity: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isSignup: {
      type: Boolean,
      default: false,
    },
    orgName: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    charityData: {
      id: 0,
      name: '',
      website: '',
      yearOfIncorporation: null,
      quote: '',
      mandate: '',
      vision: '',
      charitableNumber: '',
      charityScopeId: null,
      charityTypeId: null,
      charityPrograms: [],
      email: { name: '' },
      focusAreas: [],
      charityPositionMatching: null,
    },
    validationTriggered: false,
    charityTypes: [],
    charityScopes: [],
    charityImage: null,
    charityImageId: null,
    backgroundImage: null,
    loadingImage: null,
    socialMediaSubmit: [],
    customToolbar: [
      ['bold', 'italic', 'underline'],
    ],
  }),
  computed: {
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        { length: year - 1900 },
        (value, index) => 1901 + index,
      ).reverse();
    },
    ...mapState({
      socialMediaTypes: (state) => state.general.socialMediaTypes,
      storeCharity: (state) => state.charity.charity,
    }),
  },
  watch: {
    charity: {
      immediate: true,
      handler(value) {
        if (value) { this.charityData = { ...value, email: value.email ? value.email : { name: '' } }; }
        if (this.socialMediaTypes != null && this.socialMediaTypes.length > 0 && value != null && value.socialMedia != null && value.socialMedia.length > 0) {
          const newSocialMedia = value.socialMedia;
          this.setSocialMedia(newSocialMedia, this.socialMediaTypes);
        }
      },
    },
    socialMediaTypes: {
      immediate: true,
      handler(value) {
        if (value && this.charity) {
          const existingLinks = this.charity && this.charity.socialMedia ? this.charity.socialMedia : [];
          this.setSocialMedia(existingLinks, value);
        }
      },
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getCharityTypes();
      this.getCharityScopes();
      this.loadCharityImage();
      this.loadBackgroundImage();
    },
    async getCharityTypes() {
      this.charityTypes = await this.$api.charityTypes.get();
    },
    async getCharityScopes() {
      this.charityScopes = await this.$api.charityScopes.get();
    },

    async validate() {
      // eslint-disable-next-line no-return-await
      let valid = false;
      valid = await this.$refs.charityValidateObserver.validate();
      if (!valid) {
        return false;
      }
      this.validationTriggered = true;
      // if (!this.charityImage && !this.isSignup) {
      //   if (this.loadingImage) await this.loadingImage;
      //   if (!this.charityImage) return false;
      // }
      return true;
    },
    async submit() {
      if (this.socialMediaSubmit && this.socialMediaSubmit.length) await this.$api.socialMedia.update(this.socialMediaSubmit);
      const submitCharity = { ...this.charityData, charityPositionMatching: null, focusAreas: this.charityData.focusAreas ? this.charityData.focusAreas.map((x) => x.id) : [] };
      try {
        return submitCharity.id !== 0
          ? await this.$api.charity.update(submitCharity)
          : await this.$api.charity.create(submitCharity);
      } catch (err) {
        console.log(err);
      }
      return null;
    },
    async loadCharityImage() {
      if (!this.charity) return;
      const filters = [
        {
          name: 'entityId',
          values: [this.charity.id.toString()],
        },
        {
          name: 'type',
          values: ['profile-image'],
        },
      ];
      this.loadingImage = this.$api.image.readImages('charity', createPaginationObject(null, null, filters));
      const response = await this.loadingImage;
      if (response.images == null || response.images.length === 0) return;
      const [firstImage] = response.images;
      this.charityImageId = firstImage.id;
      this.charityImage = firstImage.image;
      this.closeDialogs();
      if (this.charity.id.toString() === this.storeCharity.id.toString()) {
        this.$store.dispatch('charity/getProfileImage');
      }
      this.$emit('imageLoaded', this.charityImage);
    },
    async loadBackgroundImage() {
      if (!this.charity) return;
      const filters = [
        {
          name: 'entityId',
          values: [this.charity.id.toString()],
        },
        {
          name: 'type',
          values: ['background-image'],
        },
      ];
      const response = await this.$api.image.readImages('charity', createPaginationObject(null, null, filters));
      const [firstImage] = response.images;
      this.backgroundImage = firstImage.image;
    },
    editBackground() {
      this.$refs.backgroundImageSelectorDialog.openForm();
    },
    submitBackgroundImageChange() {
      this.$refs.backgroundImageSelector.submit();
    },
    changePhoto() {
      this.$refs.charityImageSelectorDialog.openForm();
    },
    deleteCharityImage() {
      if (!confirm('Are you sure you want to delete your picture?')) return;
      this.$api.image.softDelete(this.charityImageId, `/charity/${this.charity.id}`);
      this.charityImage = null;
    },
    closeDialogs() {
      this.$refs.charityImageSelectorDialog.closeForm();
      this.$refs.backgroundImageSelectorDialog.closeForm();
      if (this.$refs.backgroundImageSelectorDialog) this.$refs.backgroundImageSelectorDialog.closeForm();
      if (this.$refs.charityImageSelectorDialog) this.$refs.charityImageSelectorDialog.closeForm();
    },
    reset() {
      this.charityData = {
        id: 0,
        name: '',
        charityTypeId: 0,
        charityScopeId: null,
        chariableNumber: '',
        mandate: '',
        website: '',
        yearOfIncorporation: '',
        email: {
          name: '',
        },
      };
    },
    setSocialMedia(existingData, types) {
      this.socialMediaSubmit = types.map((x) => (
        {
          charityId: this.charity.id,
          socialMediaTypeId: x.id,
          url: existingData.findIndex((exl) => exl.socialMediaTypeId === x.id) > -1 ? existingData.find((exl) => exl.socialMediaTypeId === x.id).url : '',
          id: existingData.findIndex((exl) => exl.socialMediaTypeId === x.id) > -1 ? existingData.find((exl) => exl.socialMediaTypeId === x.id).id : '',
          name: x.name,
        }
      ));
    },
  },
};
</script>
