import Api from '../Api';

class CharityApi extends Api {
  constructor() {
    super('charities', 'charities');
  }

  async getCharityByUserId(id) {
    const res = await this.getByUserId(id);
    return res;
  }

  async getCities() {
    const res = await this.get('cities');
    return res;
  }

  async getProvince() {
    const res = await this.get('province');
    return res;
  }

  async getBoardTypes() {
    const res = await this.get('board-types');
    return res;
  }

  async getDevelopmentStages() {
    const res = await this.get('development-stages');
    return res;
  }

  async getFundingStatuses() {
    const res = await this.get('funding-statuses');
    return res;
  }

  async getBoardWorkHours() {
    const res = await this.get('board-work-hours');
    return res;
  }
}

const charityApi = new CharityApi();
export default charityApi;
