import Vue from 'vue';
import Vuex from 'vuex';
import SecureLS from 'secure-ls';
import CreatePersistedState from 'vuex-persistedstate';

import auth from './auth.module';
import user from './user.module';
import charity from './charity/charity.module';
import charityRep from './charity/charityRep.module';
import general from './general.module';
import charityPosition from './charity/charityPosition.module';
import charityPosting from './charity/charityPosting.module';
import candidate from './candidate.module';
import navigation from './navigation.module';
import thinkific from './thinkific.module';

const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

const vuexPersisted = new CreatePersistedState({
  key: 'boardMatch',
  storage: {
    getItem: (key) => ls.get(key),
    setItem: (key, value) => ls.set(key, value),
    removeItem: (key) => ls.remove(key),
  },
});

export default new Vuex.Store({
  plugins: [vuexPersisted],
  modules: {
    auth,
    user,
    charity,
    charityRep,
    general,
    charityPosition,
    charityPosting,
    candidate,
    navigation,
    thinkific,
  },
});
