<template>
  <div style="width: 100%;">
    <v-row
      class="align-center"
    >
      <v-col cols="4">
        <v-text-field
          v-model.lazy="search"
          :placeholder="'Search for ' + tabName + '..'"
          hide-details=""
          x-large
          outlined
        />
      </v-col>
      <v-col cols="1">
        <v-radio-group v-model="searchRadioButton">
          <v-radio
            label="Charities"
            value="charities"
            @click="clearSearch(true)"
          />
          <v-radio
            label="Positions"
            value="positions"
            @click="clearSearch(true)"
          />
        </v-radio-group>
      </v-col>
      <v-col cols="1">
        <v-btn
          x-large
          class="mr-4"
          type="submit"
          color="success"
          @click="searchEntries(filters)"
        >
          search
        </v-btn>
      </v-col>
      <v-col cols="3">
        <a
          v-if="searchRadioButton == 'positions'"
          class="text-body-1 ml-1"
          @click="openAdvancedFilter()"
        >
          Advanced
        </a>
      </v-col>
    </v-row>
    <v-row
      v-if="filterChips && filterChips.length > 0"
      class="mt-0"
    >
      <v-col>
        <span class="font-weight-bold">Advanced Search Options:</span>
        <v-chip
          v-for="chip in filterChips"
          :key="chip.filterName+'-'+chip.value"
          close
          class="ml-1"
          :color="chip.chipColor"
          small
          @click:close="removeFilterChip(chip)"
        >
          {{ chip.name }}
        </v-chip>
        <a
          class="ml-3 text-body-1"
          @click="clearFilters()"
        >Clear All</a>
      </v-col>
    </v-row>
    <candidate-search-dialog
      ref="candidateSearchDialog"
      :filters="filterDefinitions"
      class="mt-0 ml-0 mb-10 pa-0"
      @apply-filter="(filters) => searchEntries(filters)"
      @apply-filter-string="(filters) => updatefilterChip(filters)"
    />
    <charity-table
      v-show="searchRadioButton == 'charities'"
      :search="searchSubmit"
      :filters="filters"
      title="Charities"
    >
      <template slot-scope="{item}">
        <a
          class="mr-2"
          @click="openSubtable('positions', item.id)"
        > POSITIONS </a>
        <a
          @click="openCharityDialog(item)"
        > VIEW </a>
      </template>
    </charity-table>
    <charity-posting-table
      v-show="searchRadioButton == 'positions'"
      :search="searchSubmit"
      :filters="filters"
      title="Positions"
    >
      <template slot-scope="{item}">
        <a
          class="mr-2"
          @click="openSubtable('charity', item.charityId)"
        > CHARITY </a>
        <a
          @click="openPostingDialog(item)"
        > {{ onboardingCompleted == true ? 'VIEW/APPLY' : 'VIEW' }}  </a>
      </template>
    </charity-posting-table>
    <template v-if="subTableOpen">
      <v-row>
        <v-col>
          <zigzag />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-h6">
          {{ subTableTitle }}
        </v-col>
        <v-col class="text-right">
          <v-btn
            icon
            @click="closeSubtable()"
          >
            <v-icon large>
              mdi-close
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <charity-table
        v-show="subTable == 'charity'"
        :filters="subTableFilters"
      >
        <template slot-scope="{item}">
          <a
            @click="openCharityDialog(item)"
          > VIEW </a>
        </template>
      </charity-table>
      <charity-posting-table
        v-show="subTable == 'positions'"
        :filters="subTableFilters"
      >
        <template slot-scope="{item}">
          <a
            @click="openPostingDialog(item)"
          > {{ onboardingCompleted == true ? 'VIEW/APPLY' : 'VIEW' }}  </a>
        </template>
      </charity-posting-table>
    </template>
    <charity-template-dialog
      ref="charityDialog"
      :charity="selectedCharity"
    />
    <charity-posting-dialog
      ref="postingDialog"
      :show-header="true"
      :show-charity="true"
      :posting="selectedPosting"
      :is-application="onboardingCompleted"
      @posting-success="notifySuccess"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CharityTable from '@/components/charity/CharityTable';
import CharityPostingTable from '@/components/charity/charityPosting/CharityPostingTable';
import CharityTemplateDialog from '@/components/charity/CharityTemplateDialog';
import CharityPostingDialog from '@/components/charity/charityPosting/CharityPostingDialog';
import CandidateSearchDialog from '@/components/candidate/search/CandidateSearchDialog';
import { createPaginationObject } from '@/helpers/PaginationHelper.js';

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CharityTable, CharityPostingTable, CharityTemplateDialog, CharityPostingDialog, CandidateSearchDialog,
  },
  props: {
    searchProp: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    search: '',
    searchSubmit: '',
    searchRadioButton: 'charities',
    filters: [],
    showAdvancedFilter: false,
    skills: [],
    focusAreas: [],
    positions: [],
    selectedCharity: null,
    selectedPosting: null,
    filterChips: [],
    subTableOpen: false,
    subTable: 'positions',
    subTableFilters: [],
    charityCities: [],
  }),
  computed: {
    tabName() {
      return this.searchRadioButton.charAt(0).toUpperCase() + this.searchRadioButton.slice(1);
    },
    ...mapState({
      onboardingSteps: (state) => state.candidate.onboarding,
      navigationSearch: (state) => state.navigation.search,
    }),
    onboardingCompleted() {
      return this.onboardingSteps != null && this.onboardingSteps.length > 0 && this.onboardingSteps.findIndex((x) => x.completedPercent !== 100) === -1;
    },
    filterDefinitions() {
      return [
        {
          name: 'city',
          label: 'City',
          type: 'autocomplete',
          options: this.charityCities,
          value: '',
          cols: 3,
          chipColor: 'success',
        },
        {
          name: 'position',
          label: 'Position',
          type: 'autocomplete',
          options: this.positions,
          multiselect: true,
          value: [],
          cols: 3,
          chipColor: 'default',
        },
        {
          name: 'dateRange',
          label: 'Date Range',
          type: 'dropdown',
          options: [
            {
              value: -1,
              name: 'All Open Postings',
            },
            {
              value: 3,
              name: '3 months',
            },
            {
              value: 1,
              name: '1 month',
            },
            {
              value: 0,
              name: 'Today',
            },
          ],
          value: -1,
          cols: 3,
          chipColor: 'secondary',
        },
        {
          name: 'linebreak1',
          type: 'linebreak',
          cols: 3,
        },
        {
          name: 'skillSet',
          label: 'Skill Sets',
          type: 'checkboxes',
          options: this.skills,
          valueSource: 'id',
          value: [],
          collapsed: false,
          cols: 12,
          perRow: 6,
          chipColor: 'primary',
        },
        // the following is to be added when we flush out focus areas for charities
        /* {
          name: 'focusAreas',
          label: 'Focus Areas',
          type: 'checkboxes',
          options: this.focusAreas,
          collapsed: true,
          cols: 12,
        }, */
      ];
    },
    subTableTitle() {
      if (this.subTable === '') return this.subTable;
      return this.subTable.charAt(0).toUpperCase() + this.subTable.slice(1);
    },
  },
  watch: {
    navigationSearch(value) {
      this.search = value;
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.search = this.navigationSearch;
      await Promise.all(
        [
          await this.getSkills(),
          await this.getFocusAreas(),
          await this.getPositions(),
          await this.getCharityCities(),
        ],
      );
      this.searchEntries();
    },
    async getSkills() {
      const skills = await this.$api.candidateSkills.get();
      this.skills = [];
      skills.forEach((skill) => {
        const modifiedSkill = skill;
        modifiedSkill.selected = false;
        this.skills.push(modifiedSkill);
      });
    },
    async getFocusAreas() {
      const focusAreas = await this.$api.candidateFocusAreas.get();
      this.focusAreas = [];
      focusAreas.forEach((focusArea) => {
        const modifiedFocusArea = focusArea;
        modifiedFocusArea.selected = false;
        this.focusAreas.push(modifiedFocusArea);
      });
    },
    async getPositions() {
      const positions = await this.$api.charityPositions.read(createPaginationObject(null, null, [{ name: 'isDisabled', values: [0] }]));
      this.positions = [];
      // this.positions.push({ id: -1, name: '' });
      if (positions != null && positions.positions != null) {
        positions.positions.forEach((position) => {
          const modifiedPosition = { value: position.name, name: position.name };
          if (this.positions.findIndex((x) => x.name === modifiedPosition.name) === -1) { this.positions.push(modifiedPosition); }
        });
      }
    },
    async getCharityCities() {
      const cities = await this.$api.charity.getCities();
      this.charityCities = [];
      cities.forEach((x) => {
        const splitName = x.split(' ');
        for (let i = 0; i < splitName.length; i += 1) {
          splitName[i] = splitName[i][0].toUpperCase() + splitName[i].substr(1);
        }
        this.charityCities.push({ name: splitName.join(' '), value: x });
      });
      // this.positions.push({ id: -1, name: '' });
      /* if (positions != null && positions.positions != null) {
        positions.positions.forEach((position) => {
          const modifiedPosition = { value: position.name, name: position.name };
          if (this.positions.findIndex((x) => x.name === modifiedPosition.name) === -1) { this.positions.push(modifiedPosition); }
        });
      } */
    },
    searchEntries(filters = []) {
      this.filters = filters;
      if (this.$refs.candidateSearchDialog != null) {
        const advancedFilter = this.$refs.candidateSearchDialog.selectedFlters;
        if (advancedFilter && advancedFilter.length > 0) { this.filters = this.filters.concat(advancedFilter); }
      }
      // this has been done at Art's request to make users click the search button
      this.searchSubmit = this.search;
    },
    updatefilterChip(filters = []) {
      this.filterChips = filters;
    },
    openAdvancedFilter() {
      this.$refs.candidateSearchDialog.openForm();
    },
    clearSearch(onlyFilters = false) {
      this.filters = [];
      this.filterChips = [];
      this.subTableOpen = false;
      if (!onlyFilters) {
        this.search = '';
        this.searchSubmit = '';
      }
    },
    async openSubtable(type, charityId) {
      this.subTable = type;
      await this.$nextTick();
      const charityIdFilter = { name: 'charityId', values: [charityId.toString()] };
      this.subTableFilters = [];
      this.subTableFilters.push(charityIdFilter);
      this.subTableOpen = true;
      await this.$nextTick();
      this.goto('closeSubtableButton');
    },
    closeSubtable() {
      this.subTableOpen = false;
    },
    goto(elementId) {
      const el = document.getElementById(`#${elementId}`);
      if (el != null) el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    },
    openCharityDialog(charity) {
      this.selectedCharity = charity;
      this.$refs.charityDialog.openCharityForm(false);
    },
    openPostingDialog(posting) {
      this.selectedPosting = posting;
      this.$refs.postingDialog.openPostingDialog(0, false);
    },
    notifySuccess(message) {
      this.$bus.$emit('showSuccess', message);
      this.$refs.postingTable.refresh();
    },
    clearFilters() {
      this.filterChips = [];
      this.filters = [];
      this.searchEntries();
    },
    removeFilterChip(chip) {
      const filterDefinition = this.filterDefinitions.find((x) => x.name === chip.filterName);
      const filterIndex = this.filters.findIndex((x) => x.name === chip.filterName);
      const chipIndex = this.filterChips.findIndex((x) => x === chip);
      if (filterDefinition == null || filterIndex === -1) return;

      if (filterDefinition.type === 'checkboxes' || filterDefinition.multiselect === true) {
        const filters = [...this.filters];
        const valueIndex = filters[filterIndex].values.findIndex((x) => x === chip.value);
        filters[filterIndex].values.splice(valueIndex, 1);
        this.filterChips.splice(chipIndex, 1);
        if (this.filters[filterIndex].values.length === 0) filters.splice(filterIndex, 1);
        this.filters = filters; // this has to be done so that the table's watcher catches changes to the table. without this, any splicing done to a filter's values does not trigger the watcher
      } else {
        this.filterChips.splice(chipIndex, 1);
        this.filters.splice(filterIndex, 1);
      }
      this.searchEntries(this.filters);
    },
  },
};
</script>

<style></style>
