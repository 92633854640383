<template>
  <v-row
    :class="preview ? 'widget' : ''"
  >
    <v-col
      cols="2"
      class="left-quote"
    >
      <v-img
        :src="require('@/assets/quotes-left.png')"
        :height="imageExist ? '65' : '45'"
        contain
      />
    </v-col>
    <v-col
      class="quote-text-col d-flex justify-center"
      cols="8"
    >
      <div class="quote-text">
        <i
          v-if="htmlText"
          v-html="htmlText"
        />
        <span
          v-else
          class="mb-2 mt-0;"
        >{{ text }}</span>
        <!--<span
          class="quote-text"
        >{{ text }}</span>-->
      </div>
    </v-col>
    <v-col
      cols="2"
      class="right-quote"
    >
      <v-img
        :src="require('@/assets/quotes-right.png')"
        :height="imageExist ? '65' : '45'"
        contain
      />
    </v-col>
  </v-row>
</template>

<script>

export default {
  components: {
  },

  props:
  {
    text: {
      type: String,
      default: '',
    },
    htmlText: {
      type: String,
      default: '',
    },
    preview: {
      type: Boolean,
      default: false,
    },
    imageExist: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
  }),
  computed: {
  },
  watch: {
    htmlText: {
      immediate: true,
      handler(value) {
        if (this.htmlText != null && this.$refs && this.$refs[`general-text-content-${this.title}`]) {
          this.$refs[`general-text-content-${this.title}`].innerHTML = value;
        }
      },
    },
  },
  methods: {
  },
};
</script>
<style scoped>
.quote-text {
  z-index: 50;
  font-size: x-large;
}
.quote-text-col {
  padding-top: 35px;
  padding-bottom: 35px;
  margin-left: -20px !important;
  padding-right: -100px !important;
  z-index: 2;
}
.right-quote {
  margin-left: -40px !important;
  padding-top: 0px !important;
  align-content: end;
  display: grid;
  z-index: 1;
}
.left-quote {
  margin-left: 20px !important;
  margin-right: 10px !important;
}
.widget {
  border-radius: 8px;
  padding: 12px;
  outline: 1px solid #e0e0e0;
  margin: 0px;
}
</style>
