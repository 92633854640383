var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[(_vm.title)?_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_c('v-col',[_c('v-data-table',{staticStyle:{"width":"100%"},attrs:{"headers":_vm.headers,"items":_vm.courses,"footer-props":_vm.footerProps,"items-per-page":_vm.itemsPerPage,"loading":_vm.loading,"server-items-length":_vm.total,"dense":"","option":_vm.options},on:{"pagination":_vm.updatePagination,"update:options":_vm.updateOptions},scopedSlots:_vm._u([{key:"item.courseName",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.courseName)+" ")])]}},{key:"item.courseId",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.courseId)+" ")])]}},{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(_vm.kebabCaseFormatter(item.courseName))+" ")])]}},{key:"item.thinkificCourseType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.thinkificCourseType.name)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_vm._t("default",null,{"item":item})]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }