var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[(_vm.title)?_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_c('v-col',[_c('v-data-table',{staticClass:"custom-row-height",staticStyle:{"width":"100%"},attrs:{"headers":_vm.headers,"items":_vm.positions,"footer-props":_vm.footerProps,"items-per-page":_vm.itemsPerPage,"loading":_vm.loading,"server-items-length":_vm.total,"dense":"","option":_vm.options},on:{"pagination":_vm.updatePagination,"update:options":_vm.updateOptions},scopedSlots:_vm._u([{key:"item.position",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{staticClass:"font-weight-bold",attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item && item.charityPosition ? item.charityPosition.name : '')+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":_vm.chipColor(item),"text-color":"white"}},[_vm._v(" "+_vm._s(_vm.status(item))+" ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.charityRepresentative)?_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(((item.charityRepresentative.user.userInformation.firstName) + " " + (item.charityRepresentative.user.userInformation.lastName)))+" ")]):_c('span',{staticStyle:{"color":"red"}},[_vm._v(" Vacant ")])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.charityRepresentative.user.userName)+" ")])]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(_vm.formatDate(item.startDate))+" ")])]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(_vm.formatDate(item.endDate ))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_vm._t("default",null,{"item":item})]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }