/* eslint-disable valid-typeof */
/* eslint-disable consistent-return */
/* eslint-disable no-async-promise-executor */
/* eslint-disable no-param-reassign */

export default {
  namespaced: true,

  state: () => ({
    search: '',
    filters: [],
    expanded: false,
    selectedCategories: [1, 2, 3],
  }),
  mutations: {
    storeSearch(state, data) {
      state.search = data.search.search;
      state.filters = data.search.filters;
    },
    deleteSearch(state) {
      state.search = '';
      state.filters = [];
      state.expanded = false;
      state.selectedCategories = [-1, 1, 2, 3];
    },
    expand(state) {
      state.expanded = !state.expanded;
    },
    assignCategories(state, data) {
      state.selectedCategories = data;
    },
  },
  actions: {
    saveSearch({ commit }, search) {
      commit('storeSearch', { search });
    },
    clearSearch({ commit }) {
      commit('deleteSearch');
    },
    expandFilters({ commit }) {
      commit('expand');
    },
    selectCategories({ commit }, categories) {
      commit('assignCategories', categories);
    },
  },
  getters: {
    search: (state) => state.search,
    expanded: (state) => state.expanded,
    selectedCategories: (state) => state.selectedCategories,
  },
};
