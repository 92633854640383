<template>
  <v-container>
    <v-row class="text-center">
      <v-col
        cols="12"
        class="ma-0 pa-0"
      >
        <v-img
          :src="require('@/assets/Modified Dashboard v2.png')"
          width="100%"
          class="ma-0"
          contain
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'Home',

  components: {
  },
};
</script>
