/* eslint-disable consistent-return */
/* eslint-disable no-async-promise-executor */
/* eslint-disable no-param-reassign */
import CharityBoardRolesApi from '@/api/charities/charityPositions/CharityBoardRolesApi';
import charityPositionsApi from '@/api/charities/charityPositions/CharityPositionsApi';

export default {
  namespaced: true,

  state: () => ({
    charityBoardRoles: [],
    charityPositions: [],
  }),
  mutations: {

    storeCharityBoardRoles(state, list) {
      state.charityBoardRoles = list;
    },

    storeCharityPositions(state, list) {
      state.charityPositions = list;
    },
  },
  actions: {
    getCharityBoardRoles({ commit }) {
      return new Promise(async (resolve) => {
        const list = await CharityBoardRolesApi.get();

        commit('storeCharityBoardRoles', list);
        resolve();
      });
    },

    getCharityPositions({ commit }, data) {
      return new Promise(async (resolve) => {
        const list = await charityPositionsApi.read(data);
        commit('storeCharityPositions', list.positions);
        resolve();
      });
    },
  },
  getters: {

  },
};
