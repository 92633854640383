// converts string to kebab case i.e. foo-bar-baz
// eslint-disable-next-line import/prefer-default-export
export function kebabCaseFormatter(str) {
  return str
    ? str
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g,
      )
      .join('-')
      .toLowerCase()
    : '';
}
export function phoneFormatter(str) {
  const cleanedNumber = str.replace(/\D/g, '');

  // Apply formatting to the cleaned number
  const formattedNumber = cleanedNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');

  return formattedNumber;
}
