<template>
  <v-container
    fluid
    class="pa-2 pb-2 ma-2 mt-12"
    style="background-color: white; border-radius: 8px;"
  >
    <v-overlay
      :value="overlay"
      class="align-center justify-center"
    >
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-row
      id="#title"
      class="text-left pl-6 pt-5"
    >
      <v-col cols="8">
        <h1>{{ title }}</h1>
      </v-col>
    </v-row>
    <v-row class="text-left pl-6 pt-0">
      <v-col
        cols="8"
        style="padding-bottom: 5px;"
      >
        <!-- <span class="font-italic">Please complete {{ requiredSteps }} of this onboarding process to unlock all of the Boardmatch features.</span><br> -->
        <span class="font-italic">Please complete all steps of this onboarding process to unlock all of the Boardmatch features.</span><br>
        <!-- <span
          v-if="!canEditCharity"
          class="font-italic"
        >Note: If you need to edit details in steps 1-2, please contact your Administrator.</span> -->
      </v-col>
    </v-row>
    <v-row v-if="onboarding != null && onboarding.length > 0">
      <v-col class="text-end">
        <v-stepper
          v-model="currentStep"
          class="stepper"
          flat
          elevation="0"
          @change="goto('title')"
        >
          <v-stepper-header class="sticky">
            <template
              v-for="(step, index) in onboarding"
            >
              <v-stepper-step
                v-if="true"
                :key="`step-${step.onboardingId}`"
                editable
                :step="index + 1"
                :color="iconColor(step, index)"
                class="custom-step"
              >
                <span>
                  {{ step.name }}
                  <v-icon
                    v-if="step.completedPercent >= 100"
                    class="mb-1"
                    style="font-size: 30px;"
                    color="success"
                  >
                    mdi-check
                  </v-icon>
                </span>
              </v-stepper-step>

              <v-divider
                v-if="index !== onboarding.length - 1"
                :key="`div-${step.onboardingId}`"
              />
            </template>
          </v-stepper-header>
          <v-stepper-items class="text-left">
            <v-stepper-content
              v-for="(step, index) in onboarding"
              :key="`${step.onboardingId}-content`"
              :step="index + 1"
            >
              <v-row
                v-if="step.onboardingId == charityDetailsStepId && !loadingCharityInfo"
              >
                <v-col>
                  <charity-edit-template
                    ref="charityEditTemplate"
                    :charity="charity"
                    :is-phone-required="true"
                    :is-address-required="true"
                    :is-editing="canEditCharity"
                    :is-charity-page="true"
                  />
                </v-col>
              </v-row>
              <v-row
                v-else-if="step.onboardingId == positionMatchingStepId && !loadingPositionMatching"
              >
                <v-col>
                  <charity-position-matching-template
                    ref="charityPositionMatchingTemplate"
                    :charity="charity"
                    :read-only="!canEditCharity"
                  />
                </v-col>
              </v-row>
              <v-row
                v-else-if="step.onboardingId == orientationStepId && !loadingTraining"
              >
                <v-col>
                  <span class="text-h5 font-weight-bold">Training Courses</span>
                  <training-course-enrollment-table
                    ref="trainingTable"
                    class="mt-4"
                    :filters="trainingFilters"
                  >
                    <template slot-scope="{item}">
                      <v-btn
                        color="success"
                        elevation="0"
                        :outlined="item.completed"
                        @click="openThinkificCourse(item.course_name)"
                      >
                        {{ item.completed ? 'Retake This Course' : 'Take This Course' }}
                      </v-btn>
                    </template>
                  </training-course-enrollment-table>
                </v-col>
              </v-row>
              <v-row
                v-else-if="step.onboardingId == releaseFormStepId"
              >
                <v-col>
                  <charity-release-template
                    v-if="user != null"
                    ref="charityReleaseTemplate"
                    :is-onboarding="true"
                    :charity-rep="charityRep"
                  />
                </v-col>
              </v-row>
              <v-row
                v-else
              >
                <v-col cols="5" />
                <v-col cols="2">
                  <v-card
                    color="primary"
                    dark
                  >
                    <v-card-text>
                      Loading, Please stand by
                      <v-progress-linear
                        indeterminate
                        color="white"
                        class="mb-0"
                      />
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  class="text-start"
                  cols="4"
                >
                  <v-btn
                    v-if="index > 0"
                    color="primary"
                    @click="previousStep(index + 1)"
                  >
                    Previous
                  </v-btn>
                </v-col>
                <v-col
                  class="text-center"
                  cols="4"
                >
                  <v-btn
                    color="success"
                    :disabled="step.charity && !canEditCharity"
                    outlined
                    @click="submit(step.onboardingId)"
                  >
                    {{ step.onboardingId === orientationStepId ? 'refresh course status': 'Save' }}
                  </v-btn>
                </v-col>
                <v-col
                  cols="4"
                  class="text-right"
                >
                  <v-btn
                    v-if="index + 1 < onboarding.length"
                    color="primary"
                    @click="nextStep(index + 1)"
                  >
                    Next
                  </v-btn>
                  <v-btn
                    v-else-if="index + 1 == onboarding.length"
                    :disabled="!canFinish"
                    color="success"
                    @click="finish()"
                  >
                    Finish
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
    <general-dialog
      ref="welcomeDialog"
      :title="`Welcome to Altruvest`"
      submit-button-label="Save"
      dialog-width="650px"
      :submit-button-enabled="false"
      :suppress-footer="true"
      @closed="setWelcomeStatus"
    >
      <v-divider class="mt-2 mb-0" />
      <v-row class="pl-4">
        <v-col
          cols="12"
          clas="pa-0"
        />
        <v-col
          class="d-flex justify-start mb-4"
          cols="9"
        >
          <div>
            <span style="font-size: x-large;">Please complete charity onboarding</span><br>
            <span style="font-size: x-large;">to unlock all Boardmatch features.</span>
            <v-checkbox
              v-model="hideWelcome"
              label="Do not show this message again"
              hide-details
            />
          </div>
        </v-col>
        <v-col
          cols="3"
          class="d-flex justify-end"
        >
          <v-img
            :src="require('@/assets/Altruvest Official Logo.svg')"
            width="100px"
            height="100px"
            contain
          />
        </v-col>
      </v-row>
    </general-dialog>
  </v-container>
</template>
<script>
/* eslint-disable no-await-in-loop */

import { mapState, mapGetters } from 'vuex';
// import UserTemplate from '@/components/user/UserTemplate';
import { createPaginationObject } from '@/helpers/PaginationHelper.js';
import CharityReleaseTemplate from '@/components/charity/CharityReleaseTemplate';
import TrainingCourseEnrollmentTable from '@/components/training/TrainingCourseEnrollmentTable.vue';
import { openThinkificCourse } from '@/helpers/ThinkificHelper.js';
import CharityEditTemplate from '@/components/charity/CharityEditTemplate';
import CharityPositionMatchingTemplate from '@/components/charity/positionMatching/CharityPositionMatchingTemplate';
import GeneralDialog from '@/components/general/GeneralDialog';

export default {
  components: {
    // UserTemplate,
    CharityReleaseTemplate,
    TrainingCourseEnrollmentTable,
    CharityEditTemplate,
    CharityPositionMatchingTemplate,
    GeneralDialog,
  },

  data: () => ({
    user: null,
    currentStep: 1,
    title: 'Charity Onboarding',
    onboarding: [],
    charityDetailsStepId: 6, // as per onboarding database table
    positionMatchingStepId: 7, // as per onboarding database table
    orientationStepId: 8, // as per onboarding database table
    releaseFormStepId: 9, // as per onboarding database table
    courseTypeId: 2, // as per ThinkificCourseTypes table, 2 = Charity Rep
    hideWelcome: false,
    submitSuccess: false,
    overlay: false,
  }),
  computed: {
    ...mapState({
      // general
      userId: (state) => state.user.nameId,
      involvement: (state) => state.user.selectedInv,
      invPromise: (state) => state.user.invPromise,
      charity: (state) => state.charity.charity,
      charityRep: (state) => state.charityRep.charityRep,
      countries: (state) => state.general.countries,
      provinces: (state) => state.general.regions,
      isSelf: (state) => state.auth.isSelf,
      // position matching
      candidateFocusAreas: (state) => state.candidate.candidateFocusAreas,
      boardTypes: (state) => state.charity.boardTypes,
      developmentStages: (state) => state.charity.developmentStages,
      fundingStatuses: (state) => state.charity.fundingStatuses,
      boardWorkHours: (state) => state.charity.boardWorkHours,
      // optional charity
      socialMediaTypes: (state) => state.general.socialMediaTypes,
      charityOnboarding: (state) => state.charity.onboarding,
      charityRepOnboarding: (state) => state.charityRep.onboarding,
    }),
    ...mapGetters({
      findClaim: 'auth/findClaim',
      getSelectedInv: 'user/getSelectedInv',
    }),
    trainingFilters() {
      const filters = [];
      filters.push({ name: 'UserId', values: [this.userId] });
      filters.push({ name: 'Type', values: [this.courseTypeId] });
      return filters;
    },
    canFinish() {
      let canFinish = false;
      if (this.onboarding != null && this.onboarding.length > 0) {
        canFinish = this.onboarding.findIndex((x) => x.completedPercent !== 100) === -1;
      }
      return canFinish;
    },
    onboardingNeeded() {
      if (this.involvement.involvement === 'charityRepresentative') {
        let index = this.charityOnboarding.findIndex((x) => x.completedPercent < 100);
        if (index > -1) return true;
        const onboarding = this.charityRepOnboarding.filter((o) => o.onboardingId !== this.orientationStepId);
        index = onboarding.findIndex((x) => x.completedPercent < 100);
        if (index > -1) return true;
      }
      return false;
    },
    loadingCharityInfo() {
      return this.user === null
        || this.charity === null
        || this.charityRep === null
        || this.socialMediaTypes == null || this.socialMediaTypes.length === 0
        || this.countries == null || this.countries.length === 0
        || this.provinces == null || this.provinces.length === 0;
    },
    loadingPositionMatching() {
      return this.charity == null
        || this.charity.charityPositionMatching == null
        || this.candidateFocusAreas == null || this.candidateFocusAreas.length === 0
        || this.boardTypes == null || this.boardTypes.length === 0
        || this.developmentStages == null || this.developmentStages.length === 0
        || this.fundingStatuses == null || this.fundingStatuses.length === 0
        || this.boardWorkHours == null || this.boardWorkHours.length === 0;
    },
    loadingTraining() {
      return this.user === null
        || this.charity === null
        || this.charityRep === null
        || !this.findClaim;
    },
    canEditCharity() {
      const claim = this.findClaim('charity-onboarding.update') === '1';
      return claim;
    },
    requiredSteps() {
      const required = this.canEditCharity ? 'all steps' : 'steps 3 and 4';
      return required;
    },
  },
  mounted() {
    this.clearReroute();
    this.init();
  },

  methods: {
    async init() {
      this.overlay = true;
      await this.refresh();
      this.overlay = false;
      // this.modalWelcome();
    },
    async refresh() {
      if (this.invPromise) await this.invPromise;
      if (!this.userId) await new Promise((r) => setTimeout(r, 2000));
      await Promise.all([
        this.getUser(),
        this.$store.dispatch('charity/getCharity', this.involvement.charityId),
        this.$store.dispatch('charityRep/getCharityRep', this.involvement.charityRepId),
      ]);
      await Promise.all([
        this.loadOnboarding(),
        this.loadTypes(),
      ]);
      await Promise.all([
        this.modalWelcome(),
      ]);
      this.autoComplete();
    },
    async modalWelcome() {
      const suppressWelcome = await this.getWelcomeStatus();
      if (!this.canFinish && !suppressWelcome && !this.onboardingNeeded) {
        this.$refs.welcomeDialog.openForm();
      }
    },
    async getWelcomeStatus() {
      if (!this.charityRep) {
        return false;
      }
      const res = await this.$api.charityRep.getWelcomeStatus(this.charityRep.id);
      return res;
    },
    async setWelcomeStatus(suppress = this.hideWelcome) {
      let res = false;
      if (suppress && this.charityRep) {
        res = await this.$api.charityRep.suppressWelcome(this.charityRep.id);
      }
      return res;
    },
    async getUser() {
      await this.$nextTick();
      if (!this.userId) return;
      const response = await this.$api.user.getById(this.userId);
      if (!response) return;
      this.user = response;
    },
    async loadOnboarding() {
      let filters = [{ name: 'CharityId', values: [this.charity.id] }, { name: 'IsDisabled', values: [0] }];
      let search = createPaginationObject(null, null, filters);
      const charityOnboarding = await this.$api.charityOnboarding.read(search);
      filters = [{ name: 'CharityRepId', values: [this.charityRep.id] }, { name: 'IsDisabled', values: [0] }];
      search = createPaginationObject(null, null, filters);
      const charityRepOnboarding = await this.$api.charityRepOnboarding.read(search);
      this.onboarding = [];
      if (charityOnboarding && this.canEditCharity) charityOnboarding.forEach((onboarding) => { this.onboarding.push(onboarding); });
      if (charityRepOnboarding) charityRepOnboarding.forEach((onboarding) => { this.onboarding.push(onboarding); });
      this.onboarding = this.onboarding.filter((o) => o.onboardingId !== this.orientationStepId);
    },
    async loadTypes() {
      // user details
      if (!this.countries.length) this.$store.dispatch('general/getCountries');
      if (!this.provinces.length) this.$store.dispatch('general/getRegion');
      // position matching
      if (!this.candidateFocusAreas.length) await this.$store.dispatch('candidate/getCandidateFocusAreas');
      if (!this.boardTypes.length) this.$store.dispatch('charity/getBoardTypes');
      if (!this.developmentStages.length) this.$store.dispatch('charity/getDevelopmentStages');
      if (!this.fundingStatuses.length) this.$store.dispatch('charity/getFundingStatuses');
      if (!this.boardWorkHours.length) this.$store.dispatch('charity/getBoardWorkHours');
      // optional
      if (!this.socialMediaTypes.length) this.$store.dispatch('general/getSocialMediaTypes');
    },
    async autoComplete() {
      let updated = false;
      for (let index = 0; index < this.onboarding.length; index += 1) {
        if (this.onboarding[index].charityRep || this.canEditCharity) {
          let validatedStep = this.onboarding[index].completedPercent === 100;
          if (this.onboarding[index].onboardingId === this.charityDetailsStepId) {
            // validatedStep = await this.$refs.charityEditTemplate[0].validate(false);
          } else if (this.onboarding[index].onboardingId === this.positionMatchingStepId) {
            // validatedStep = await this.$refs.charityPositionMatchingTemplate[0].validate(false);
          } else if (this.onboarding[index].onboardingId === this.orientationStepId) {
            validatedStep = await this.$refs.trainingTable[0].validate(false);
          }
          // not doing this for release form
          if (validatedStep !== (this.onboarding[index].completedPercent === 100) && this.onboarding[index].onboardingId !== this.releaseFormStepId) {
            if (this.onboarding[index].charityRep != null) {
              await this.$api.charityRepOnboarding.complete(this.charityRep.id, this.onboarding[index].onboardingId, validatedStep);
              updated = true;
            } else if (this.onboarding[index].charity != null) {
              await this.$api.charityOnboarding.complete(this.charity.id, this.onboarding[index].onboardingId, validatedStep);
              updated = true;
            }
          }
        }
      }
      if (updated === true) {
        this.loadOnboarding();
      }
    },
    async nextStep(n) {
      if (n < this.onboarding.length) {
        const stepId = { ...this.onboarding[n - 1] }.onboardingId;
        await this.submit(stepId);
        if (this.submitSuccess) this.currentStep = n + 1;
        this.goToTop();
      }
    },
    async previousStep(n) {
      if (n > 0) {
        const stepId = { ...this.onboarding[n - 1] }.onboardingId;
        await this.submit(stepId);
        this.currentStep = n - 1;
        this.goToTop();
      }
    },
    icon(step, index) {
      if (index + 1 === this.currentStep) return 'mdi-pencil';
      if (step.completedPercent >= 100) return 'mdi-check';
      return '';
    },
    iconColor(step, index) {
      if (!this.canEditCharity && step.charity != null) return 'primary';
      if (index + 1 === this.currentStep) return 'primary';
      if (step.completedPercent >= 100) return 'success';
      return '';
    },
    async submit(stepId) {
      const step = this.onboarding.find((x) => x.onboardingId === stepId);
      if (step.charity && !this.canEditCharity) return;
      let res = {
        success: false,
        completed: false,
      };
      if (stepId === this.charityDetailsStepId) {
        // reference is an array, because it's inside a for loop, but there will only ever be 1 item in the array
        res = await this.$refs.charityEditTemplate[0].submitCharity();
      } else if (stepId === this.positionMatchingStepId) {
        res = await this.$refs.charityPositionMatchingTemplate[0].submit();
      } else if (stepId === this.orientationStepId) {
        const trainingRes = await this.$refs.trainingTable[0].validate();
        res.success = true;
        res.completed = trainingRes;
      } else if (stepId === this.releaseFormStepId) {
        res = await this.$refs.charityReleaseTemplate[0].submit();
      }
      if (res.success === true) {
        this.submitSuccess = true;
        if (stepId === this.charityDetailsStepId || stepId === this.positionMatchingStepId) await this.$api.charityOnboarding.complete(this.charity.id, stepId, res.completed);
        else await this.$api.charityRepOnboarding.complete(this.charityRep.id, stepId, res.completed);
        await this.refresh();
        if (this.onboarding.length > this.currentStep && stepId !== this.orientationStepId) this.currentStep += 1;
        this.goToTop();
      }
    },
    notifySuccess(message) {
      this.$bus.$emit('showSuccess', message);
      this.$store.dispatch('charity/getCharityOnboarding', this.charity.id);
      this.$store.dispatch('charityRep/getCharityRepOnboarding', this.charityRep.id);
      this.getUser();
    },
    async openThinkificCourse(courseName = null) {
      openThinkificCourse(courseName);
    },
    async finish() {
      if (this.canFinish === true) {
        this.$store.dispatch('charity/getCharityOnboarding', this.charity.id);
        this.$store.dispatch('charityRep/getCharityRepOnboarding', this.charityRep.id);
        this.$router.push('/charity-profile');
        this.$bus.$emit('showSuccess', 'Thank you for completing your onboarding!');
      }
    },
    goto(elementId) {
      const el = document.getElementById(`#${elementId}`);
      if (el != null) el.scrollIntoView({ behavior: 'smooth', block: 'start' });
    },
    async goToTop() {
      setTimeout(() => {
        this.goto('title');
      }, 200);
    },
    clearReroute() {
      if (this.$route.query.forceReroute) {
        const query = { ...this.$route.query };
        delete query.forceReroute;
        this.$router.replace({ query });
      }
    },
  },
};
</script>
<style>
  div.v-stepper__step.custom-step span.v-stepper__step__step {
    font-size: 22px !important;
    height: 30px !important;
    width: 30px !important;
  }
  div.v-stepper__step.custom-step.v-stepper__step--editable{
    height: 70px !important;
  }
  .stepper {
    overflow: visible !important;
  }
  .sticky {
    position: sticky;
    top: 122px;
    z-index: 8;
  }
  .v-stepper__header {
    box-shadow: rgba(0, 0, 0, 0) 0px 3px 1px -2px, rgba(0, 0, 0, 0) 0px 2px 2px 0px, rgba(0, 0, 0, 0) 0px 1px 5px 0px !important;
    border-bottom: rgba(0,0,0,0.2) !important;
    border-bottom-width: 2px !important;
    border-bottom-style: solid !important;
    border-top: rgba(0,0,0,0.2) !important;
    border-top-width: 2px !important;
    border-top-style: solid !important;
    background-color: white;
  }
</style>
