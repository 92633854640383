<template>
  <v-row class="no-gutters">
    <v-col>
      <v-hover
        v-slot="{ hover }"
      >
        <v-row
          :class="`${hover ? 'custom-color' : ''} widget-hat pa-0 pb-3`"
          @click="openCharity()"
        >
          <v-col
            cols="4"
            class="widget pt-2"
          >
            <div
              v-if="location"
              :class="`widget ${hover ? '' : 'white'} pa-0 pl-1 text-button`"
              style="color: rgb(255, 166, 0);"
            >
              <!-- <v-btn x-small color="secondary"></v-btn> -->
              charity
            </div>
          </v-col>

          <v-col
            cols="8"
            class="widget d-flex justify-end"
          >
            <div
              v-if="location"
              :class="`widget ${hover ? '' : 'white'} pa-1 pt-0 pb-0`"
            >
              <span class="ma-1">
                <v-icon
                  small
                >
                  mdi-crosshairs-gps
                </v-icon>
                {{ location }}
              </span>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="pa-2 pl-4 pr-4"
          >
            <span class="text-h5">{{ charityName }}</span><br>
          </v-col>
          <v-col
            v-if="charity && charityImage"
            cols="4"
            class="pa-2 pb-0 d-flex justify-center"
          >
            <v-img
              :src="(charity && charityImage ? 'data:' + charityImage.mimeType + ';base64,' + charityImage.file : '')"
              max-height="100px"
              max-width="100px"
              contain
            />
          </v-col>
          <v-col
            :cols="`${charity && charityImage ? 7 : 12}`"
            :class="(charity && charityImage ? 'pl-0 pb-0 pt-2 pr-2' : 'pa-0 pl-4 pr-4' )"
          >
            <div
              style="height: 4.5em; overflow: hidden; text-overflow: ellipsis;white-space:nowrap; width: 230px; overflow-wrap: break-word; "
              class="grey--text text--darken-2"
            >
              <span
                style="line-height: 1.5em; "
                class="suppress-p-margin"
                v-html="charityDescription"
              />
            </div>
          </v-col>
          <!--<v-col
            cols="6"
            class="pt-2 pb-1"
          >
            <v-btn
              width="100%"
              color="primary"
              outlined
              class="widget"
              elevation="0"
              small
            >
              Available Positions
            </v-btn>
          </v-col>
          <v-col
            cols="6"
            class="pt-1"
          >
            <v-btn
              width="100%"
              color="primary"
              class="widget"
              elevation="0"
              outlined
              small
              @click="openCharity()"
            >
              Learn More
            </v-btn>
          </v-col>-->
        </v-row>
      </v-hover>
      <v-hover
        v-if="charity.activePostingCount"
        v-slot="{ hover }"
      >
        <v-row
          :class="`${hover ? 'hovering' : ''} widget-shoe pa-0`"
          @click="openCharityPosting()"
        >
          <v-col
            cols="12"
            class="pt-1 pb-1 d-flex justify-center "
          >
            <span class="primary--text text-button v-btn__content">Available Positions: {{ charity.activePostingCount }}</span>
            <!--<v-btn
              width="100%"
              color="primary"
              outlined
              class="widget"
              elevation="0"
              small
            >
              Available Positions
            </v-btn>-->
          </v-col>
        </v-row>
      </v-hover>
      <v-row
        v-else
        :class="`widget-shoe pa-0 disabled-clickable-area`"
      >
        <v-col
          cols="12"
          class="pt-1 pb-1 d-flex justify-center "
        >
          <span class="grey--text text-button v-btn__content">No Available Positions</span>
          <!--<v-btn
              width="100%"
              color="primary"
              outlined
              class="widget"
              elevation="0"
              small
            >
              Available Positions
            </v-btn>-->
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { createPaginationObject } from '@/helpers/PaginationHelper.js';

export default {
  components: {
  },

  props:
  {
    charity: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    charityImage: null,
  }),
  computed: {
    location() {
      return this.charity && this.charity.address && this.charity.address.region && this.charity.address.country ? `${this.charity.address.region.name}, ${this.charity.address.country.name}` : '';
    },
    charityName() {
      const { name } = this.charity;
      // if (name.length > 16) name = `${name.substring(0, 16)}...`;
      return name;
    },
    charityDescription() {
      let name = '';
      if (this.charity.quote != null && this.charity.quote !== '') name = this.charity.quote;
      else if (this.charity.mandate != null && this.charity.mandate !== '') name = this.charity.mandate;
      else if (this.charity.vision != null && this.charity.vision !== '') name = this.charity.vision;
      // if (name.length > 60) name = `${name.substring(0, 60)}...`;
      if (!name.startsWith('<p>')) name = `<p>${name}</p>`;
      return name;
    },
  },
  watch: {
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.loadCharityImage();
    },
    async loadCharityImage() {
      const filters = [
        {
          name: 'entityId',
          values: [this.charity.id.toString()],
        },
        {
          name: 'type',
          values: ['profile-image'],
        },
      ];
      const response = await this.$api.image.readImages('charity', createPaginationObject(null, null, filters));
      if (response.images == null || response.images.length === 0) {
        this.charityImage = null;
        this.$emit('imageLoaded', this.charityImage);
        return;
      }
      const [firstImage] = response.images;
      this.charityImage = firstImage.image;
      // this.closeDialogs();
      if (this.storeCharity && this.storeCharity.id && this.charity.id.toString() === this.storeCharity.id.toString()) {
        this.$store.dispatch('charity/getProfileImage');
      }
      this.$emit('imageLoaded', this.charityImage);
    },
    openCharity() {
      this.$emit('showCharity', this.charity.id);
      // this.$router.push(`/charity-profile?cid=${this.charity.id}`);
    },
    openCharityPosting() {
      this.$emit('showCharityPostingByCharity', this.charity.id);
      // this.$router.push(`/charity-profile?cid=${this.charity.id}`);
    },
  },
};
</script>
<style scoped>
  .widget{
    border-radius: 10px;
    padding: 12px;
  }
  .widget-hat {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 12px;
  }
  .widget-shoe {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 12px;
    border-top: solid 1px rgb(0,0,0,0.2);
  }
  .thumbnail-image {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .hovering {
    background-color: transparent !important;
    background-color: #E0E0E0 !important;
    cursor: pointer;
  }
  .disabled-clickable-area {
    cursor: default !important;
  }
 .custom-color {
   background-color: #d8f2e0;
   cursor: pointer;
  }
</style>
<style>
span.suppress-p-margin p {
  margin-bottom: 0px;
}
</style>
