<template>
  <v-row>
    <v-col
      v-if="title"
      cols="12"
    >
      {{ title }}
    </v-col>
    <v-col>
      <v-data-table
        :headers="headers"
        :items="positions"
        :footer-props="footerProps"
        :items-per-page="itemsPerPage"
        :loading="loading"
        :server-items-length="total"
        dense
        :option="options"
        style="width:100%"
        class="custom-row-height"
        @pagination="updatePagination"
        @update:options="updateOptions"
      >
        <template #[`item.position`]="{ item }">
          <text-highlight
            :queries="search"
            class="font-weight-bold"
          >
            {{ item && item.charityPosition ? item.charityPosition.name : '' }}
          </text-highlight>
        </template>
        <template #[`item.status`]="{ item }">
          <v-chip
            small
            :color="chipColor(item)"
            text-color="white"
          >
            {{ status(item) }}
          </v-chip>
        </template>
        <template #[`item.name`]="{ item }">
          <text-highlight
            v-if="item.charityRepresentative"
            :queries="search"
          >
            {{ `${item.charityRepresentative.user.userInformation.firstName} ${item.charityRepresentative.user.userInformation.lastName}` }}
          </text-highlight>
          <span
            v-else
            style="color: red;"
          >
            Vacant
          </span>
        </template>
        <template #[`item.email`]="{ item }">
          <text-highlight
            :queries="search"
          >
            {{ item.charityRepresentative.user.userName }}
          </text-highlight>
        </template>
        <template #[`item.startDate`]="{ item }">
          <text-highlight :queries="search">
            {{ formatDate(item.startDate) }}
          </text-highlight>
        </template>
        <template #[`item.endDate`]="{ item }">
          <text-highlight :queries="search">
            {{ formatDate(item.endDate ) }}
          </text-highlight>
        </template>

        <template #[`item.actions`]="{ item }">
          <slot :item="item" />
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import TextHighlight from 'vue-text-highlight';
import { createPaginationObject } from '@/helpers/PaginationHelper.js';

export default {
  components: {
    TextHighlight,
  },
  props: {
    search: {
      default: '',
      type: String,
    },
    title: {
      default: '',
      type: String,
    },
    filters: {
      default: null,
      type: Array,
    },
    charity: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    positions: [],
    footerProps: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    itemsPerPage: 20,
    loading: false,
    pagination: null,
    total: 0,
    options: null,
  }),
  computed: {
    headers() {
      const headers = [];

      headers.push({ text: 'Position', value: 'position' });
      headers.push({
        text: 'Status', value: 'status', align: 'center', width: 80,
      });
      headers.push({ text: 'Occupied By', value: 'name' });
      headers.push({ text: 'Email', value: 'email' });
      headers.push({ text: 'Start Date', value: 'startDate', width: 110 });
      headers.push({ text: 'End Date', value: 'endDate', width: 110 });
      headers.push({
        text: 'Actions',
        value: 'actions',
        align: 'right',
        sortable: false,
        width: 130,
      });

      return headers;
    },

  },
  watch: {
    search(value) {
      if (value) {
        this.getPositions();
      }
    },
    options(value) {
      if (value) {
        this.getPositions();
      }
    },
    filters(value) {
      if (value) {
        this.getPositions();
      }
    },
  },

  methods: {
    async getPositions() {
      this.loading = true;
      const result = await this.$api.charityMandate.read(
        createPaginationObject(this.options, this.search, this.filters),
      );
      this.loading = false;
      if (!result) {
        this.positions = [];
        this.total = 0;
        return;
      }
      this.positions = result.charityMandates;
      this.total = result.total;
    },
    updatePagination(pagination) {
      this.pagination = pagination;
    },
    updateOptions(options) {
      this.options = options;
    },
    refresh() {
      this.getPositions();
    },
    formatDate(date) {
      if (!date) return '';
      return this.$moment(date).format('YYYY-MM-DD');
    },
    status(item) {
      const now = this.$moment();
      if (now > this.$moment(item.endDate)) return 'Past';
      if (now < this.$moment(item.startDate)) return 'Future';
      return 'Current';
    },
    chipColor(item) {
      const now = this.$moment();
      if (now > this.$moment(item.endDate)) return 'red';
      if (now < this.$moment(item.startDate)) return 'blue';
      return 'green';
    },
  },
};
</script>

<style>
.custom-row-height table tbody tr{
  height: 40px; /* Adjust the desired row height */
}
</style>
