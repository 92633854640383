<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <h1>Corporations</h1>
      </v-col>
      <v-btn
        color="primary"
        outlined
        class="d-flex align-self-end"
        @click="openCorporationCreateForm"
      >
        <v-icon
          small
          class="mr-1"
        >
          fas fa-plus
        </v-icon>
        New Record
      </v-btn>
    </v-row>
    <v-row>
      <v-data-table
        class="elevation-1"
        :headers="corporationHeaders"
        :items="corporations"
        :footer-props="corporationsPerPageProps"
        :items-per-page="corporationsPerPage"
        :loading="loading"
      >
        <template #[`item.name`]="{ item }">
          {{ item.name }}
        </template>
        <template #[`item.email`]="{ item }">
          {{ item.email != null ? item.email.name : '' }}
        </template>
        <template #[`item.website`]="{ item }">
          {{ item.website }}
        </template>
        <template #[`item.address`]="{ item }">
          {{ item.address != null ? `${item.address.address1} ${item.address.city} ${item.address.region.abbreviation} ${item.address.code} ${item.address.country.name}` : '' }}
        </template>
        <template #[`item.phone`]="{ item }">
          {{ item.phone != null ? `(${item.phone.extension}) ${item.phone.number}` : '' }}
        </template>
        <template #[`item.actions`]="{ item }">
          <a
            class="mr-2"
            @click="openCorporationUpdateForm(item)"
          > EDIT </a>
        </template>
      </v-data-table>
    </v-row>
    <v-row justify="center">
      <corporation-form
        ref="corporationForm"
        :corporation-prop="corporation"
        @corporation-success="notifyCorporationSuccess"
        @corporation-fail="notifyCorporationFail"
      />
    </v-row>
    <Snackbar ref="snackbar" />
  </v-container>
</template>

<script>

import CorporationForm from '@/components/corporation/CorporationForm';
import Snackbar from '@/components/app/TheSnackbar';
import CorporationApi from '@/api/corporations/CorporationApi';

export default {
  components: {
    CorporationForm,
    Snackbar,
  },
  data: () => ({
    corporations: [],
    corporationsPerPageProps: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    corporationsPerPage: 20,
    loading: false,
    corporation: null,

  }),
  computed: {
    corporationHeaders() {
      const headers = [];

      headers.push({ text: 'Name', value: 'name' });
      headers.push({ text: 'Email', value: 'email' });
      headers.push({ text: 'Website', value: 'website' });
      headers.push({ text: 'Address', value: 'address' });
      headers.push({ text: 'Phone', value: 'phone' });
      headers.push({ text: 'Actions', value: 'actions' });

      return headers;
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getCorporations();
    },
    openCorporationCreateForm() {
      this.corporation = null;
      this.$refs.corporationForm.openCorporationForm();
    },
    openCorporationUpdateForm(corporation) {
      this.corporation = JSON.parse(JSON.stringify(corporation));
      this.$refs.corporationForm.openCorporationForm();
    },
    async getCorporations() {
      this.loading = true;
      this.corporations = await CorporationApi.get();
      this.loading = false;
    },
    notifyCorporationSuccess(message) {
      this.$bus.$emit('showSuccess', message);
      this.getCorporations();
    },
    notifyCorporationFail(message) {
      this.$bus.$emit('showError', message);
    },

  },
};

</script>
