<template>
  <v-dialog
    v-model="faqDialog"
    fullscreen
    hide-overlay
  >
    <v-card
      fill-height
    >
      <v-container fluid>
        <v-row>
          <v-col class="text-h4">
            Boardmatch FAQs
          </v-col>
          <v-col class="text-right">
            <v-btn
              icon
              @click="toggleDialog"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <candidate-f-a-q-form />
        <contact-us-form ref="contactUsForm" />
        <program-overview-form ref="overviewForm" />
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import ContactUsForm from '@/components/infoForms/ContactUsForm';
import ProgramOverviewForm from '@/components/infoForms/ProgramOverviewForm';
import CandidateFAQForm from '@/components/infoForms/CandidateFAQForm';

export default {
  components: {
    ContactUsForm,
    ProgramOverviewForm,
    CandidateFAQForm,
  },

  data: () => ({
    showPassword: false,
    userData: null,
    faqDialog: false,
  }),

  methods: {
    goto(elementId) {
      const el = document.getElementById(`#${elementId}`);

      if (el != null) el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    },
    toggleDialog() {
      this.faqDialog = !this.faqDialog;
    },
  },
};
</script>
