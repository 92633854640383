<template>
  <v-container fluid>
    <v-row class="text-left">
      <v-col cols="12">
        <h1>Donations</h1>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
  }),
};
</script>
