import Api from '../Api';

class SocialMediaApi extends Api {
  constructor() {
    super('socialMedia', 'social media');
  }

  async getTypes() {
    const res = await this.get('types');
    return res;
  }
}
const userApi = new SocialMediaApi();
export default userApi;
