<template>
  <v-row :class="preview ? 'widget' : ''">
    <v-col>
      <v-row>
        <v-col
          class="ma-2 mb-0 pb-0"
          style="display: grid; align-content: center;"
        >
          <div
            style="display: grid; align-content: center;"
          >
            <span
              class="font-weight-bold text-h5"
              style="margin-bottom: 5px;"
            >{{ title }}</span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="ma-4 mt-0">
          <div
            :ref="`general-text-content-${title}`"
            style="display: grid; align-content: center;"
          >
            <span
              v-if="htmlText"
              v-html="htmlText"
            />
            <span
              v-else
              class="mb-2 mt-0;"
            >{{ text }}</span>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>

export default {
  components: {
  },

  props:
  {
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    htmlText: {
      type: String,
      default: '',
    },
    preview: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
  }),
  computed: {
  },
  watch: {
  },
  methods: {
  },
};
</script>
<style scoped>
.widget {
  border-radius: 8px;
  padding: 12px;
  outline: 1px solid #e0e0e0;
  margin: 0px;
}
</style>
