<template>
  <div v-if="charityPosting">
    <validation-observer ref="sharePostingValidationObserver">
      <v-row>
        <v-col cols="12">
          <v-divider />
        </v-col>
        <v-col cols="5" class="d-flex align-end">
          <span class="mr-3" style="font-size: 18px;">To:</span>
          <validation-provider
            v-slot="{ errors }"
            name="Recipient Email"
            rules="required"
            style="width: 100% !important;"
          >
            <v-text-field
              v-model="message.toEmail"
              placeholder="Recipient Email"
              :error-messages="errors"
              class="mb-1"
              dense
              required
              hide-details></v-text-field>
          </validation-provider>
        </v-col>
        <v-col cols="7" class="d-flex align-end">
            <validation-provider
              v-slot="{ errors }"
              name="Recipient Email"
              rules="required"
              style="width: 100% !important;"
            >
              <v-text-field v-model="message.toName" required placeholder="Recipient Name" class="mb-1" dense hide-details :error-messages="errors"></v-text-field>
            </validation-provider>
        </v-col>
        <v-col cols="12" class="d-flex align-end">
          <span class="mr-2" style="font-size: 18px;">From: </span>
          <span class="" style="font-size: 18px; font-weight: bold;">Altruvest BoardMatch (notifications@altruvest.org)</span>
          <span class="mr-3" style="font-size: 18px;">, on behalf of:</span>
          <validation-provider
            v-if="!fullNameState"
            v-slot="{ errors }"
            name="Recipient Email"
            rules="required"
            style="width: 100% !important;"
          >
            <v-text-field v-model="message.fromName" placeholder="Your Name" class="mr-3 mb-1" dense hide-details :error-messages="errors"></v-text-field>
          </validation-provider>
          <span style="font-size: 18px; font-weight: bold;">{{(fullNameState ? `${fullNameState} ` : '') }}({{ username }})</span>
        </v-col>
        <v-col cols="12" class="d-flex align-end">
          <span class="mr-3" style="font-size: 18px;">Subject:</span>
          <validation-provider
            v-slot="{ errors }"
            name="Recipient Email"
            rules="required"
            style="width: 100% !important;"
          >
            <v-text-field v-model="message.subject" placeholder="Subject Line" class="mr-3 mb-1" dense hide-details :error-messages="errors"></v-text-field>
          </validation-provider>
        </v-col>
        <v-col cols="12">
          <span class="mr-3" style="font-size: 18px;">Body:</span>
          <div style="border: 1px rgba(0, 0, 0, 0.87) solid; border-radius: 4px;" class="pa-2 background-gray">
            {{ greeting }}
            <br/>
            <br/>
            <v-text-field v-model="message.customBody" placeholder="Custom Message" class="mr-3 mb-1 white pl-1" dense hide-details></v-text-field>
            <template v-if="charityPosting && charityPosting.charityPosition">
              <br/>
              <span class="font-weight-bold">Title: </span><span>{{ charityPosting.charityPosition.name }}</span>
              <br/>
            </template>
            <template v-if="charityPosting && charityPosting.charity">
              <br/>
              <span class="font-weight-bold">Posted By:</span><span> {{ charityPosting.charity.name }}</span>
              <br/>
            </template>
            <br/>
            <span  class="font-weight-bold">Mandate: </span><span>{{ mandate }}</span>
            <br/>
            <template v-if="description">
              <br/>
              <span  class="font-weight-bold">Description: </span><span>{{ description }}</span>
              <br/>
            </template>
            <template v-if="specialRequirements">
              <br/>
              <span  class="font-weight-bold">Special Requirements: </span><span>{{ specialRequirements }}</span>
              <br/>
            </template>
            <template v-if="skills">
              <br/>
              <span  class="font-weight-bold">Skills: </span><span>{{ skills }}</span>
              <br v-if="!focusAreas"/>
            </template>
            <template v-if="focusAreas">
              <br/>
              <span  class="font-weight-bold">Focus Areas: </span><span>{{ focusAreas }}</span>
              <br/>
            </template>
            <template v-if="formattedExpiryDate">
              <br/>
              <span  class="font-weight-bold">Expires On: </span><span>{{ formattedExpiryDate }}</span>
              <br/>
            </template>
            <br/>
            <div v-html="registerLink" />
            <br/>
            <div v-html="postingLink" />
            <br/>
            <div v-html="signature" />
            <br/>

          </div>
        </v-col>
      </v-row>
    </validation-observer>
  </div>
</template>

<script>
import { mapState } from 'vuex'; // , mapGetters
import { required } from 'vee-validate/dist/rules';
import {
  extend,
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate';

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    charityPosting: {
      type: Object,
      default: () => {},
    },
    applied: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    message: {
      charityPostingId: null,
      toName: '',
      toEmail: '',
      fromName: '',
      subject: '',
      customBody: 'You are invited to apply for the following position on BoardMatch:',
    },
  }),
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.auth.isLoggedIn,
      username: (state) => state.user.userInfo.userName,
      userInfo: (state) => state.user.userInfo,
    }),
    greeting() {
      return `Hello${this.message.toName ? ` ${this.message.toName}` : ''},`;
    },
    description() {
      let sanitizedDescription = this.charityPosting.description;
      sanitizedDescription = sanitizedDescription.replaceAll('<p>', '');
      sanitizedDescription = sanitizedDescription.replaceAll('</p>', '');
      if (sanitizedDescription.replaceAll(' ', '') === '') return '';

      return sanitizedDescription ? `${sanitizedDescription}` : '';
    },
    specialRequirements() {
      return this.charityPosting.specialRequirements ? `${this.charityPosting.specialRequirements}` : '';
    },
    mandate() {
      return `${this.formattedStartDate} - ${this.formattedEndDate}   (${this.mandatePeriod})`;
    },
    skills() {
      let skills = '';
      let first = true;
      this.charityPosting.candidateSkill.forEach((skill) => {
        skills += !first ? ', ' : '';
        skills += skill.name;
        first = false;
      });
      return skills;
    },
    focusAreas() {
      let focusAreas = '';
      let first = true;
      this.charityPosting.charity.focusAreas.forEach((focusArea) => {
        focusAreas += !first ? ', ' : '';
        focusAreas += focusArea.name;
        first = false;
      });
      return focusAreas;
    },
    registerLink() {
      return 'If interested, please register with Altruvest BoardMatch <a href="https://boardmatch2.altruvest.org/login" target="_blank">HERE</a>';
    },
    postingLink() {
      return 'Once registered, you can apply for this board position <a href="https://boardmatch2.altruvest.org/login" target="_blank">HERE</a>';
    },
    signature() {
      return `<span>Best Regards,</span><br /><span>${this.message.fromName ? this.message.fromName : 'BoardMatch Team'}</span>`;
    },
    formattedStartDate() {
      const startDate = new Date(this.charityPosting.startDate);
      return startDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    },
    formattedEndDate() {
      const endDate = new Date(this.charityPosting.endDate);
      return endDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    },
    formattedExpiryDate() {
      const expDate = new Date(this.charityPosting.expiryDate);
      return expDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    },
    mandatePeriod() {
      const startDate = new Date(this.charityPosting.startDate);
      const endDate = new Date(this.charityPosting.endDate);

      const timeDiff = endDate - startDate; // Time difference in milliseconds
      const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

      if (daysDiff >= 7) {
        const weeksDiff = Math.floor(daysDiff / 7);
        return weeksDiff === 1 ? `${weeksDiff} week` : `${weeksDiff} weeks`;
      } if (daysDiff >= 365) {
        const yearsDiff = Math.floor(daysDiff / 365);
        return yearsDiff === 1 ? `${yearsDiff} year` : `${yearsDiff} years`;
      }
      return daysDiff === 1 ? `${daysDiff} day` : `${daysDiff} days`;
    },
    postingExpired() {
      return new Date(this.charityPosting.expiryDate) < Date.now();
    },
    fullNameState() {
      return this.userInfo && this.userInfo.userInformation && this.userInfo.userInformation.firstName && this.userInfo.userInformation.lastName ? `${this.userInfo.userInformation.firstName} ${this.userInfo.userInformation.lastName}` : '';
    },
  },
  watch: {
    charityPosting: {
      immediate: true,
      handler(value) {
        if (value) {
          this.populateDefaults();
        }
      },
    },
  },

  created() {
  },

  methods: {
    populateDefaults() {
      const subject = `Recommended Position - ${(this.charityPosting && this.charityPosting.charityPosition ? `${this.charityPosting.charityPosition.name} ` : '')}with ${(this.charityPosting && this.charityPosting.charity ? `${this.charityPosting.charity.name} ` : '')}on Altruvest BoardMatch`;
      this.message = {
        charityPostingId: this.charityPosting.id,
        toName: '',
        toEmail: '',
        fromName: this.fullNameState,
        subject,
        customBody: 'You are invited to apply for the following position on BoardMatch:',
      };
    },
    async sendEmail() {
      const valid = await this.validate();
      console.log(valid);
      if (!valid) {
        this.$bus.$emit('showError', 'Please fill in all required fields.');
        return;
      }
      const rep = await this.$api.charityPostings.sharePosting(this.message);
      if (rep === true) {
        this.$bus.$emit('showSuccess', 'This position posting has been sent.');
        this.populateDefaults();
        this.$emit('sent');
      } else this.$bus.$emit('showError', 'Failed to send position posting.');
    },
    async validate() {
      const res = await this.$refs.sharePostingValidationObserver.validate();
      return res;
    },
  },
};
</script>
<style>

.website-button {
    position: absolute;
    right: 59%;
    top: 13px;
    z-index: 2
  }
.widget {
  border-radius: 8px;
  padding: 12px;
}
.background-gray {
  background-color: rgb(239, 239, 239);
}
.apply-button {
  width: 100%;
  border-radius: 8px;

}
</style>
