/* eslint-disable valid-typeof */
/* eslint-disable consistent-return */
/* eslint-disable no-async-promise-executor */
/* eslint-disable no-param-reassign */
import axios from 'axios';
import AuthenticationApi from '@/api/AuthenticationApi';

export default {
  namespaced: true,

  state: () => ({
    token: '',
    refreshToken: '',
    regMessage: '',
    isLoggedIn: false,
    isSelf: true,
    isCharity: false,
    claims: {},
    charityOnboardingPromise: null,
    charityRepOnboardingPromise: null,
    candidateOnboardingPromise: null,
  }),
  mutations: {
    async authSuccess(state, data) {
      // if(data.user.boardmatchRepresentative){
      //   this.state.boardmatchRep.boardmatchRep = data.user.boardmatchRepresentative.boardmatchRepresentative
      //   this.state.boardmatchRep.permissions = data.user.boardmatchRepresentative.boardmatchRepresentativePermissions
      // }
      // else if(data.user.candidate){
      //   this.state.candidate.candidate = data.user.candidate.candidate
      //   this.state.candidate.permissions = data.user.candidate.candidatePermissions
      // }
      // else if(data.user.charityRepresentative){
      //   this.state.charityRep.charityRep = data.user.charityRepresentative.charityRepresentative
      //   this.state.charityRep.permissions = data.user.charityRepresentative.charityRepresentativePermissions
      // }
      // else{
      //   return
      // }
      if (data.clearData === true) {
        state.isLoggedIn = false;
        this.commit('user/clearData');
        this.commit('candidate/clearData');
        this.commit('charityRep/clearData');
        await new Promise((r) => setTimeout(r, 100));
      }

      const parseJwt = (token) => {
        try {
          return JSON.parse(Buffer.from(token.split('.')[1], 'base64'));
        } catch (e) {
          return null;
        }
      };
      const parsedToken = parseJwt(data.token);
      if (!parsedToken) {
        this.state.boardmatchRep.boardmatchRep = null;
        this.state.boardmatchRep.permissions = [];
        this.state.candidate.candidate = null;
        this.state.candidate.permissions = [];
        this.state.charityRep.charityRep = null;
        this.state.charityRep.permissions = [];
        this.state.auth.charityOnboardingPromise = null;
        this.state.auth.charityRepOnboardingPromise = null;
        this.state.auth.candidateOnboardingPromise = null;
        return;
      }
      state.claims = parsedToken;
      this.state.user.nameId = parsedToken.nameId;
      state.token = data.token;
      state.refreshToken = data.refreshToken;
      state.isLoggedIn = true;
      axios.defaults.headers.common.Authorization = `Bearer ${data.token}`;
      if (data.user != null) {
        if (data.user.candidate != null) {
          this.dispatch('candidate/getCandidate', data.user.candidate.candidate.userId);
          state.candidateOnboardingPromise = this.dispatch('candidate/getCandidateOnboarding', data.user.candidate.candidate.id);
        }
        if (data.user.charityRepresentative != null) {
          state.charityOnboardingPromise = this.dispatch('charity/getCharityOnboarding', data.user.charityRepresentative.charityRepresentative.charityId);
          state.charityRepOnboardingPromise = this.dispatch('charityRep/getCharityRepOnboarding', data.user.charityRepresentative.charityRepresentative.id);
        }
      }
    },
    logout(state) {
      state.token = '';
      state.refreshToken = '';
      state.isLoggedIn = false;
      state.isSelf = true;
      state.isCharity = false;
      state.claims = {};
      this.commit('user/clearData');
      this.commit('candidate/clearData');
      this.commit('charityRep/clearData');
      this.commit('charity/clearData');
      this.commit('thinkific/clearData');
      this.commit('general/clearData');
      delete axios.defaults.headers.common.Authorization;
    },
    storeRegSuccess(state, regMessage) {
      state.regMessage = regMessage;
    },
    deleteRegMessage(state) {
      state.regMessage = '';
    },
    storeIsSelf(state, data) {
      state.isSelf = data;
    },
    storeIsCharity(state, data) {
      state.isCharity = data;
    },
  },
  actions: {
    async login({ commit }, userData) {
      return new Promise(async (resolve, reject) => {
        try {
          const resp = await AuthenticationApi.authenticate(
            userData.userName,
            userData.password,
          );

          if (resp.error) {
            reject(resp);
          }

          const user = resp.response;
          const { token, refreshToken } = resp.response;
          commit('authSuccess', { user, token, refreshToken });
          // this.saveOnboarding(user);
          // we grab and store the overall state of onboarding for this user for this specific involvement
          if (user.charityRepresentative && user.charityRepresentative.charityRepresentative) {
            await this.dispatch('charityRep/saveOnboardingState', user.charityRepresentative.charityRepresentative.onboardingComplete);
            if (user.charityRepresentative.charityRepresentative.charity) await this.dispatch('charity/saveOnboardingState', user.charityRepresentative.charityRepresentative.charity.onboardingComplete);
          } else if (user.candidate && user.candidate.candidate) {
            await this.dispatch('candidate/saveOnboardingState', user.candidate.candidate.onboardingComplete);
          }
          resolve(resp.response);
        } catch (err) {
          console.log(err);
        }
      });
    },
    async loginAs({ commit }, userData) {
      return new Promise(async (resolve, reject) => {
        try {
          const resp = await AuthenticationApi.authenticateAs(
            userData.targetUserId,
            userData.adminUsername,
            userData.adminPassword,
          );
          if (!resp) {
            reject(resp);
            return false;
          }

          const user = resp;
          const { token, refreshToken } = resp;
          const clearData = true;

          await commit('authSuccess', {
            user, token, refreshToken, clearData,
          });
          commit('storeIsSelf', false);
          // this.saveOnboarding(user);
          // we grab and store the overall state of onboarding for this user for this specific involvement
          if (user.charityRepresentative && user.charityRepresentative.charityRepresentative) {
            await this.dispatch('charityRep/saveOnboardingState', user.charityRepresentative.charityRepresentative.onboardingComplete);
            if (user.charityRepresentative.charityRepresentative.charity) await this.dispatch('charity/saveOnboardingState', user.charityRepresentative.charityRepresentative.charity.onboardingComplete);
          } else if (user.candidate && user.candidate.candidate) {
            await this.dispatch('candidate/saveOnboardingState', user.candidate.candidate.onboardingComplete);
          }
          resolve(resp);
        } catch (err) {
          console.log(err);
        }
      });
    },
    storeIsCharity({ commit }, data) {
      return new Promise((resolve) => {
        commit('storeIsCharity', data);
        resolve();
      });
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        commit('logout');
        resolve();
      });
    },

    refreshToken({ commit }) {
      return new Promise(async (resolve, reject) => {
        const resp = await AuthenticationApi.refreshToken(this.state.auth.token, this.state.auth.refreshToken);
        if (!resp) {
          commit('logout');
          reject();
          return;
        }
        const user = resp;
        const { token, refreshToken } = resp;

        // we grab and store the overall state of onboarding for this user for this specific involvement
        if (user.charityRepresentative && user.charityRepresentative.charityRepresentative) {
          await this.dispatch('charityRep/saveOnboardingState', user.charityRepresentative.charityRepresentative.onboardingComplete);
          if (user.charityRepresentative.charityRepresentative.charity) await this.dispatch('charity/saveOnboardingState', user.charityRepresentative.charityRepresentative.charity.onboardingComplete);
        } else if (user.candidate && user.candidate.candidate) {
          await this.dispatch('candidate/saveOnboardingState', user.candidate.candidate.onboardingComplete);
        }
        commit('authSuccess', {
          user, token, refreshToken,
        });
        resolve(resp);
      });
    },

  },

  getters: {
    userToken: (state) => state.token,
    userRefreshToken: (state) => state.refreshToken,
    findClaim: (state) => (claimName) => {
      if (claimName in state.claims) {
        const claim = state.claims[claimName];
        if (typeof claim === undefined || claim === null || claim === '') {
          return 1;
        }
        return claim;
      }
      return null;
    },
    isLoggedIn: (state) => state.isLoggedIn,
  },
};
