<template>
  <v-container fluid>
    <validation-observer ref="formObserver">
      <v-row>
        <v-col>
          <validation-provider
            v-slot="{ errors }"
            name="Name"
            rules="required"
          >
            <v-text-field
              v-model="courseData.courseName"
              dense
              :error-messages="errors"
              hide-details
            >
              <template #label>
                Course Name<span class="red--text"><strong>* </strong></span>
              </template>
            </v-text-field>
          </validation-provider>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <validation-provider
            v-slot="{ errors }"
            name="Type"
            rules="required"
          >
            <v-select
              v-model="courseData.thinkificCourseTypeId"
              :error-messages="errors"
              :items="courseTypes"
              label="Select Role"
              data-vv-name="select"
              item-text="name"
              item-value="id"
              dense
              hide-details
              @input="log"
            >
              <template #label>
                Select Type<span class="red--text"><strong>* </strong></span>
              </template>
            </v-select>
          </validation-provider>
        </v-col>
        <v-col>
          <validation-provider
            v-slot="{ errors }"
            name="CourseId"
            rules="required"
          >
            <v-text-field
              v-model="courseData.courseId"
              dense
              :error-messages="errors"
              hide-details
              @blur="enforceInt()"
            >
              <template #label>
                Course ID<span class="red--text"><strong>* </strong></span>
              </template>
            </v-text-field>
          </validation-provider>
        </v-col>
      </v-row>
    </validation-observer>
  </v-container>
</template>

<script>
import {
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from 'vee-validate';
import { mapState } from 'vuex';

setInteractionMode('eager');

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props:
  {
    course: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    courseData: null,
  }),
  computed: {
    ...mapState({
      courseTypes: (state) => state.thinkific.types,
    }),
  },
  watch: {
    course: {
      immediate: true,
      handler(value) {
        this.courseData = { ...value };
      },
    },
  },
  methods: {
    log() {
      console.log(this.courseData);
    },
    enforceInt() {
      if (typeof (this.courseData.courseId) === 'string') this.courseData.courseId = this.courseData.courseId.replace(/\D/g, '');
      this.courseData.courseId = parseInt(this.courseData.courseId, 10);
      if (isNaN(this.courseData.courseId)) this.courseData.courseId = null;
    },
    async validate() {
      const res = await this.$refs.roleValObserver.validate();
      return res;
    },
    async submit() {
      const res = {
        valid: false,
        successful: false,
      };
      this.enforceInt();
      res.valid = await this.$refs.formObserver.validate();
      if (!res.valid) return res;
      try {
        const data = {
          courseId: this.courseData.courseId, courseName: this.courseData.courseName, id: this.courseData.id, thinkificCourseTypeId: this.courseData.thinkificCourseTypeId,
        };
        const result = this.courseData.id !== 0 && this.courseData.id != null ? await this.$api.thinkificCourses.update(data) : await this.$api.thinkificCourses.create(data);
        if (!result || !result.id) return res;

        res.successful = true;
        return res;
      } catch (err) {
        console.log(err);
        return res;
      }
    },
  },
};
</script>
