<template>
  <v-row justify="center">
    <v-dialog
      v-model="profileDialog"
      width="800px"
      overlay-opacity="0.7"
    >
      <v-card
        fill-height
      >
        <v-container fluid>
          <v-row>
            <v-col class="text-h4">
              Contact Us
            </v-col>
            <v-col class="text-right">
              <v-btn
                icon
                @click="toggleDialog"
              >
                <v-icon large>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h3 class="text-center font-weight-bold mb-10">
                If you have a program question or technical issue, please contact your local BoardMatch Fundamentals office below for assistance.
              </h3>
              <p class="text-center font-weight-bold">
                BoardMatch® Fundamentals
              </p>
              <p class="text-center">
                2 Bloor Street W, Suite 700
              </p>
              <p class="text-center">
                Toronto, ON M4W 3R1
              </p>
              <p class="text-center">
                <a href="mailto:boardmatchfundamentals@altruvest.org">boardmatchfundamentals@altruvest.org</a>
              </p>
              <p class="text-center">
                Ph. 416 972 5076
              </p>
              <p class="text-center">
                Fax 416 972 5071
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {

  data: () => ({
    profileDialog: false,
  }),

  methods: {
    toggleDialog() {
      this.profileDialog = !this.profileDialog;
    },

  },
};
</script>
