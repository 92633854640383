<template>
  <v-container
    fluid
  >
    <h2
      v-if="showHeader == true"
      class="mb-5"
    >
      Application Details
    </h2>
    <v-row>
      <v-col
        cols="7"
        class="pr-1 pt-5"
      >
        <span class="text-h6 font-weight-bold">Position&nbsp;&nbsp;</span>
        <span class="text-h6 font-weight-bold"># {{ application.posting.id }}</span>
      </v-col>
      <v-col cols="5">
        <span class="text-h6 font-weight-bold">Status:&nbsp;&nbsp;</span>
        <v-chip
          :color="chipColor(application.applicationStatus)"
          text-color="white"
        >
          {{
            application.applicationStatus.name
              ? application.applicationStatus.name
              : "-" }}
        </v-chip>
      </v-col>
      <v-col
        cols="7"
        class="pr-1"
      >
        <span class="text-h6 font-weight-bold">Applicant:&nbsp;&nbsp;</span>
        <span class="text-h6 font-weight-bold">{{ application.candidate.user.userInformation.firstName }} {{ application.candidate.user.userInformation.lastName }}</span>
      </v-col>
      <v-col cols="5">
        <span class="text-h6 font-weight-bold">Applied on:&nbsp;&nbsp;</span>
        <span class="text-h7 font-weight-bold">{{ $moment(application.applicationDate).format('YYYY-MMM-DD, h:mm A') }}</span>
      </v-col>
      <v-col cols="12">
        <v-divider />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-1">
        <span class="text-h4 font-weight-bold">{{ application.posting.charityPosition.name }}&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <v-chip
          small
          :color="colorIsBoard(application.posting.charityPosition.isNotBoard)"
          text-color="white"
          class="mb-2"
        >
          {{
            application.posting.charityPosition.isNotBoard
              ? "Not Board"
              : "Board" }}
        </v-chip><br><br>
        <span class="text-h6 font-weight-bold">Mandate Period&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <span class="text-h7">{{ formattedStartDate }} - {{ formattedEndDate }} &nbsp;&nbsp; ({{ mandatePeriod }})</span><br><br>
        <span class="text-h6 font-weight-bold">Description</span><br>
        <div
          v-if="application.posting.description"
          class="ml-4 mr-4 mt-2"
          style="line-height: 1.5;"
          v-html="application.posting.description"
        />
        <div v-else>
          Not Specified
        </div>
        <br>
        <span class="text-h6 font-weight-bold">Skills</span>
        <div
          v-if="application.posting.candidateSkill"
          class="ml-4 mr-4 mt-1"
        >
          <v-chip
            v-for="(skill, index) in application.posting.candidateSkill"
            :key="'skill-chip-' + index"
            color="primary"
            class="mr-2 mb-1 mt-1"
            small
          >
            {{ '#' + skill.name }}
            <!-- <v-icon
              v-if="!skillChipDisabled(skill)"
              right
              class="mr-0"
              color="white"
            >
              mdi-check
            </v-icon> -->
          </v-chip>
        </div>
        <div v-else>
          Not Specified
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-1">
        <span class="text-h6 font-weight-bold">Other Requirements</span>
        <div
          class="ml-4 mr-4 mt-2"
          style="white-space: pre; white-space: pre-line;"
        >
          {{ application.posting.specialRequirements ? application.posting.specialRequirements : 'Not Specified' }}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-1">
        <span class="text-h6 font-weight-bold">Posting Date &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <span class="text-h7">{{ formattedDatePosted }}</span><br>
        <span class="text-h6 font-weight-bold">Posting Expiry &nbsp;&nbsp;&nbsp;&nbsp;</span>
        <span class="text-h7">{{ formattedExpiryDate }} </span><br>
      </v-col>
      <v-col
        cols="12"
        class="pt-0"
      >
        <v-divider />
      </v-col>
      <v-col>
        <span class="text-h6 font-weight-bold">Why I am a good candidate:</span><br>
        <div
          class="ml-4 mr-4 mt-2"
          style="line-height: 1.5;"
          v-html="application.description"
        /><br>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  components: {
  },
  props: {
    application: {
      type: Object,
      default: () => {},
    },
    showHeader: {
      type: Boolean,
      default: false,
    },
    viewer: {
      default: 'candidate',
      type: String,
    },
  },

  data: () => ({
    acceptStatusId: 1,
    appliedStatusId: 2,
    candidateDeclineStatusId: 6,
    declineStatusId: 7,
    inactiveStatusId: 7,
    offerExtendedStatusId: 14,
    interviewStatusId: 12,
    underReviewStatusId: 15,
  }),
  computed: {
    formattedStartDate() {
      const startDate = new Date(this.application.posting.startDate);
      return startDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    },
    formattedEndDate() {
      const endDate = new Date(this.application.posting.endDate);
      return endDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    },
    formattedExpiryDate() {
      const endDate = new Date(this.application.posting.expiryDate);
      return endDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    },
    formattedDatePosted() {
      const endDate = new Date(this.application.posting.datePosted);
      return endDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    },
    mandatePeriod() {
      const startDate = new Date(this.application.posting.startDate);
      const endDate = new Date(this.application.posting.endDate);

      const timeDiff = endDate - startDate; // Time difference in milliseconds
      const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

      if (daysDiff >= 7) {
        const weeksDiff = Math.floor(daysDiff / 7);
        return weeksDiff === 1 ? `${weeksDiff} week` : `${weeksDiff} weeks`;
      } if (daysDiff >= 365) {
        const yearsDiff = Math.floor(daysDiff / 365);
        return yearsDiff === 1 ? `${yearsDiff} year` : `${yearsDiff} years`;
      }
      return daysDiff === 1 ? `${daysDiff} day` : `${daysDiff} days`;
    },
    postingExpired() {
      return new Date(this.application.posting.expiryDate) < Date.now();
    },
  },
  watch: {
  },
  methods: {
    formatDate(date) {
      if (!date) return '';
      return this.$moment(date).format('YYYY-MM-DD');
    },
    colorIsBoard(isNotBoard) {
      return isNotBoard ? 'orange' : 'purple';
    },
    chipColor(applicationStatus) {
      if (applicationStatus.id == null
        || applicationStatus.id === this.candidateDeclineStatusId
        || applicationStatus.id === this.declineStatusId) { return 'red'; }
      if (this.viewer === 'candidate') {
        if (applicationStatus.id === this.offerExtendedStatusId
          || applicationStatus.id === this.interviewStatusId) return 'green';
        if (applicationStatus.id === this.acceptStatusId) return 'purple';
        if (applicationStatus.id === this.appliedStatusId) return 'blue';

        return 'grey';
      } if (this.viewer === 'charity') {
        if (applicationStatus.id === this.appliedStatusId) return 'blue';
        if (applicationStatus.id === this.acceptStatusId) return 'purple';
        if (applicationStatus.id === this.underReviewStatusId
          || applicationStatus.id === this.inactiveStatusId) return 'green';
        return 'grey';
      }
      return 'white';
    },
  },
};
</script>
