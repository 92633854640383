<template>
  <div style="display: inline-flex;">
    <a
      v-for="(socialMedia, index) in usableLinks"
      :key="socialMedia.id"
      :class="`${index > 0 ? 'ml-3' : 'mr-0'} mt-0 mb-2`"
      @click="openWindow(socialMedia.url, socialMedia.socialMediaTypeId)"
    >
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <!-- <v-img
            v-if="socialMedia.socialMediaTypeId > 1"
            :key="socialMedia.id"
            width="35px"
            height="35px"
            v-bind="attrs"
            :class="index > 0 ? 'ml-3' : 'mr-0'"
            contain
            :src="(socialMedia.socialMediaType && socialMedia.socialMediaType.image && socialMedia.socialMediaType.image.image ? 'data:' + socialMedia.socialMediaType.image.image.mimeType + ';base64,' + socialMedia.socialMediaType.image.image.file : '')"
            v-on="on"
          /> -->
          <font-awesome-icon
            v-if="socialMedia.socialMediaTypeId > 1"
            :key="socialMedia.id"
            :icon="getIconClass(socialMedia)"
            color="black"
            size="2xl"
            v-bind="attrs"
            v-on="on"
          />
          <v-img
            v-if="socialMedia.socialMediaTypeId == socialMediaEmailId"
            :key="socialMedia.id"
            width="35px"
            height="35px"
            v-bind="attrs"
            :class="index > 0 ? 'ml-3' : 'mr-0'"
            contain
            :src="require('@/assets/mail-logo.png')"
            v-on="on"
          />
          <div
            v-if="socialMedia.socialMediaTypeId == charityWebsiteId"
            class="d-flex"
            v-bind="attrs"
            v-on="on"
          >
            <v-img
              :key="socialMedia.id"
              width="35px"
              height="35px"
              :class="index > 0 ? 'ml-3' : 'mr-0'"
              contain
              :src="'data:' + socialMedia.image.mimeType + ';base64,' + socialMedia.image.file"
            />
          </div>
          <v-hover
            v-if="socialMedia.socialMediaTypeId == charityPhoneId"
            v-slot="{ hover }"
          >
            <div
              v-show="hover"
              class="d-flex"
              v-bind="attrs"
              v-on="on"
            >
              <v-img
                :key="socialMedia.id"
                width="35px"
                height="35px"
                :class="index > 0 ? 'ml-3' : 'mr-0'"
                contain
                :src="require('@/assets/phone-logo.png')"
              />
              <v-slide-x-transition>
                <span
                  v-show="hover"
                  class="website-span"
                >
                  {{ socialMedia.url.number }} {{ socialMedia.url.extension ? ` ext. ${socialMedia.url.extension}` : '' }}
                </span>
              </v-slide-x-transition>

            </div>
          </v-hover>
        </template>
        <span>
          {{ socialMedia && socialMedia.socialMediaType ? socialMedia.socialMediaType.name : '' }}
        </span>
      </v-tooltip>
    </a>
  </div>
</template>

<script>

export default {
  components: {
  },

  props:
  {
    links: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    charityWebsiteId: -1,
    charityPhoneId: -2,
    socialMediaEmailId: 1,
    hoveringPhone: false,
  }),
  computed: {
    usableLinks() {
      const usableLinks = this.links.filter((x) => (x != null && x.url != null && x.url !== ''));
      return usableLinks;
    },
  },
  watch: {
  },
  methods: {
    openWindow(link, socialMediaTypeId = null) {
      if (socialMediaTypeId === this.socialMediaEmailId) window.open(`mailto:${link}?subject=I found your profile on BoardMatch`);
      if (socialMediaTypeId === this.charityPhoneId) window.open(`tel:${link}`);
      else window.open(link);
    },
    getIconClass(socialMedia) {
    // Determine the appropriate icon class based on the socialMedia object
      let res = ['fab', 'address-card'];
      if (socialMedia.socialMediaType.name === 'Facebook') {
        res = ['fab', 'square-facebook'];
      } else if (socialMedia.socialMediaType.name === 'LinkedIn') {
        res = ['fab', 'linkedin'];
      } else if (socialMedia.socialMediaType.name === 'Instagram') {
        res = ['fab', 'instagram'];
      }
      return res;
    },
  },
};
</script>
<style scoped>
.website-span{
  align-content: center;
  display: grid;
  margin-left: 6px;
}
.hyperlink{
  align-content: center;
  display: grid;
  margin-left: 6px;

}
</style>
