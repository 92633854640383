<template>
  <v-container>
    <v-row justify="center">
      <v-dialog
        v-model="loginDialog"
        max-width="500px"
      >
        <!-- <v-container style="background: white;"> -->

        <v-card class="elevation-1">
          <v-card-title>
            {{ title }}
          </v-card-title>
          <v-card-text>
            <validation-observer
              ref="validationObserver"
            >
              <v-form @submit.prevent="login">
                <validation-provider
                  v-slot="{ errors }"
                  name="Email"
                  rules="required"
                >
                  <v-text-field
                    v-model="userName"
                    prepend-icon="mdi-account"
                    name="Email"
                    label="Email"
                    type="text"
                    :error-messages="errors"
                  />
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <v-text-field
                    id="password"
                    v-model="password"
                    prepend-icon="mdi-lock"
                    name="password"
                    label="Password"
                    type="password"
                    :error-messages="errors"
                  />
                </validation-provider>
                <v-col class="text-right">
                  <v-btn
                    class="mr-4"
                    text
                    @click="loginDialog = false"
                  >
                    cancel
                  </v-btn>
                  <v-btn
                    class="mr-4"
                    type="submit"
                    color="success"
                    @click="loginAs ? submit() : ''"
                  >
                    Log In
                  </v-btn>
                </v-col>
              </v-form>
            </validation-observer>
          </v-card-text>
        </v-card>
        <!-- </v-container> -->
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { required } from 'vee-validate/dist/rules';
import {
  extend, ValidationObserver, ValidationProvider, setInteractionMode,
} from 'vee-validate';

setInteractionMode('eager');

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
});
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props:
    {
      title: {
        type: String,
        default: '',
      },
      loginAs: {
        type: Boolean,
        default: false,
      },

    },

  data: () => ({
    loginDialog: false,
    userName: '',
    password: '',
    submitted: false,
  }),
  computed: {
    ...mapState({
      currentUser: (state) => state.user,
      userState: (state) => state.user.userState,
      userInvolvements: (state) => state.user.sortedUserInvs,
    }),
    ...mapGetters({
      findClaim: 'auth/findClaim',
    }),
  },

  methods: {
    async login() {
      if (!await this.$refs.validationObserver.validate()) return;

      const result = await this.$api.auth.authData(this.userName, this.password);
      if (!result) {
        this.$bus.$emit('showError', 'Email or password is incorrect');
        return;
      }
      this.$emit('log-in-success', result);
      this.loginDialog = false;
    },
    async submit() {
      this.$store.dispatch('auth/logout')
        .then(() => {
          this.$store
            .dispatch('auth/login', { userName: this.userName, password: this.password })
            .then(() => {
              this.$store.dispatch('user/getUserState').then(async () => {
                let baseUrl = this.userState.lastState;
                if (!baseUrl) baseUrl = this.getBaseUrl;
                if (this.$route.path !== baseUrl) this.$router.push(`${baseUrl}`).catch();
                // this.$router.push('/candidate-onboarding');
                // await new Promise((r) => setTimeout(r, 2000));
                // location.reload();
              });
            })
            .catch((err) => {
              this.$bus.$emit('showError', 'Email or password is incorrect');
              // eslint-disable-next-line no-console
              console.log(err);
            });
        });
    },
    openForm() {
      this.loginDialog = true;
    },
  },
};
</script>
