var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('validation-observer',{ref:"positionValObserver"},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"name":"Board Position","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.charityPositionTypes,"error-messages":errors,"label":"Position","data-vv-name":"select","item-text":function (item) { return ("" + (item.name)); },"item-value":"id"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Board Position"),_c('red-asterisk')]},proxy:true}],null,true),model:{value:(_vm.positionData.charityPositionId),callback:function ($$v) {_vm.$set(_vm.positionData, "charityPositionId", $$v)},expression:"positionData.charityPositionId"}})]}}])})],1),_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.charityReps,"label":"Charity Representative","data-vv-name":"select","disabled":_vm.addNewRep,"item-text":function (item) { return item.user && item.user.userInformation ? item.user.userInformation.firstName +' '+ item.user.userInformation.lastName : '-'; },"return-object":""},model:{value:(_vm.positionData.charityRepresentative),callback:function ($$v) {_vm.$set(_vm.positionData, "charityRepresentative", $$v)},expression:"positionData.charityRepresentative"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-checkbox',{attrs:{"label":"Add New Representative"},model:{value:(_vm.addNewRep),callback:function ($$v) {_vm.addNewRep=$$v},expression:"addNewRep"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticStyle:{"font-size":"x-large","font-weight":"450"}},[_vm._v("Board Position Mandate")])]),_c('v-col',{attrs:{"cols":"2","sm":"2","xs":"6"}},[_c('v-menu',{attrs:{"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Start Date","persistent-hint":"","prepend-icon":"mdi-calendar"},model:{value:(_vm.positionData.startDate),callback:function ($$v) {_vm.$set(_vm.positionData, "startDate", $$v)},expression:"positionData.startDate"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"no-title":""},model:{value:(_vm.positionData.startDate),callback:function ($$v) {_vm.$set(_vm.positionData, "startDate", $$v)},expression:"positionData.startDate"}})],1)],1),_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{attrs:{"cols":"2","sm":"2","xs":"6"}},[_c('v-menu',{attrs:{"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"End Date","persistent-hint":"","prepend-icon":"mdi-calendar"},model:{value:(_vm.positionData.endDate),callback:function ($$v) {_vm.$set(_vm.positionData, "endDate", $$v)},expression:"positionData.endDate"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"no-title":""},model:{value:(_vm.positionData.endDate),callback:function ($$v) {_vm.$set(_vm.positionData, "endDate", $$v)},expression:"positionData.endDate"}})],1)],1)],1),(_vm.addNewRep)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('zigzag',{staticClass:"mb-4"})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticStyle:{"font-size":"x-large","font-weight":"450"}},[_vm._v("New Charity Representative")])]),_c('v-col',{attrs:{"cols":"3","sm":"3","xs":"6"}},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"required":"","error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" First Name"),_c('red-asterisk')]},proxy:true}],null,true),model:{value:(_vm.inviteeData.firstName),callback:function ($$v) {_vm.$set(_vm.inviteeData, "firstName", $$v)},expression:"inviteeData.firstName"}})]}}],null,false,2034107850)})],1),_c('v-col',{attrs:{"cols":"3","sm":"3","xs":"6"}},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"required":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Last Name"),_c('red-asterisk')]},proxy:true}],null,true),model:{value:(_vm.inviteeData.lastName),callback:function ($$v) {_vm.$set(_vm.inviteeData, "lastName", $$v)},expression:"inviteeData.lastName"}})]}}],null,false,1947395498)})],1),_c('v-col',{attrs:{"cols":"3","sm":"3","xs":"6"}},[_c('validation-provider',{attrs:{"name":"Email","rules":'required|email'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"required":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Email"),_c('red-asterisk')]},proxy:true}],null,true),model:{value:(_vm.inviteeData.email),callback:function ($$v) {_vm.$set(_vm.inviteeData, "email", $$v)},expression:"inviteeData.email"}})]}}],null,false,783789130)})],1)],1):_vm._e(),(_vm.positionData.charityRepresentative)?_c('v-row'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }