<template>
  <v-container fluid>
    <v-row class="text-left">
      <v-col cols="12">
        <h1>Training</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-tabs
          v-model="trainingTab"
          center-active
        >
          <v-tab>Courses</v-tab>
          <v-tab>Training Profiles</v-tab>
          <v-tab>Enrollments</v-tab>
        </v-tabs>
      </v-col>
      <v-col
        v-if="trainingTab == 0"
        class="text-end"
      >
        <v-btn
          color="primary"
          outlined
          @click="openCourseForm()"
        >
          <v-icon
            small
            class="mr-1"
          >
            mdi-plus
          </v-icon>
          Add Course
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <thinkific-courses-template
          v-if="trainingTab == 0"
          ref="thinkificCoursesTemplate"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import ThinkificCoursesTemplate from '@/components/thinkific/ThinkificCoursesTemplate';

export default {
  components: {
    ThinkificCoursesTemplate,
  },
  data: () => ({
    trainingTab: 0,
  }),
  computed: {
    ...mapState({
      currentUser: (state) => state.user,
      courseTypes: (state) => state.thinkific.types,
    }),
    loading() {
      return this.currentUser === null
        || this.courseTypes === null || this.courseTypes.length === 0;
    },
  },
  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.refresh();
    },
    async refresh() {
      await Promise.all([
        this.loadTypes(),
      ]);
    },
    async loadTypes() {
      if (!this.courseTypes.length) this.$store.dispatch('thinkific/getTypes');
    },
    openCourseForm() {
      this.$refs.thinkificCoursesTemplate.openCourseForm();
    },
  },
};
</script>
