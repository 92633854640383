<template>
  <div>
    <v-row>
      <v-col
        v-if="title"
        cols="12"
      >
        {{ title }}
      </v-col>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="applications"
          :footer-props="footerProps"
          :items-per-page="itemsPerPage"
          :loading="loading"
          :server-items-length="total"
          dense
          :option="options"
          style="width:100%"
          @pagination="updatePagination"
          @update:options="updateOptions"
        >
          <template #[`item.fullName`]="{ item }">
            <a
              :queries="search"
              @click="showCandidate(item.candidate.id, item.postingId)"
            >
              {{
                item.candidate
                  && item.candidate.user
                  && item.candidate.user.userInformation
                  ? item.candidate.user.userInformation.firstName + " " + item.candidate.user.userInformation.lastName
                  : '-'
              }}
            </a>
          </template>
          <template #[`item.charityName`]="{ item }">
            <a
              :queries="search"
              @click="showCharity(item.posting.charity.id)"
            >
              {{
                item.posting
                  && item.posting.charity
                  ? item.posting.charity.name
                  : '-'
              }}
            </a>
          </template>
          <template #[`item.positionName`]="{ item }">
            <a
              class="font-weight-bold"
              :queries="search"
              @click="showCharityPosting(item.postingId)"
            >
              {{
                item.posting
                  && item.posting.charityPosition
                  ? item.posting.charityPosition.name
                  : "-" }}
            </a>
          </template>
          <template #[`item.statusName`]="{ item }">
            <v-chip
              small
              :color="chipColor(item.applicationStatus)"
              text-color="white"
            >
              {{
                item.applicationStatus
                  ? statusMask(item.applicationStatus.name)
                  : "-" }}
            </v-chip>
          </template>
          <template #[`item.applicationDate`]="{ item }">
            <text-highlight :queries="search">
              {{ formatDate(item.applicationDate) }}
            </text-highlight>
          </template>
          <template #[`item.actions`]="{ item }">
            <slot :item="item" />
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <general-dialog
      ref="postingSummaryDialog"
      dialog-width="1280px"
      hide-submit
      :show-title="false"
    >
      <posting-summary-widget
        :charity-posting="selectedPosting"
        :applied="true"
        :is-candidate-view="false"
        class="pa-4"
      />
    </general-dialog>
  </div>
</template>

<script>
import TextHighlight from 'vue-text-highlight';
import { createPaginationObject } from '@/helpers/PaginationHelper.js';
import GeneralDialog from '@/components/general/GeneralDialog';
import PostingSummaryWidget from '../../charity/listings/PostingSummaryWidget.vue';

export default {
  components: {
    TextHighlight,
    GeneralDialog,
    PostingSummaryWidget,
  },
  props: {
    search: {
      default: '',
      type: String,
    },
    title: {
      default: '',
      type: String,
    },
    filters: {
      default: null,
      type: Array,
    },
    viewer: {
      default: 'candidate',
      type: String,
    },
  },
  data: () => ({
    applications: [],
    footerProps: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    itemsPerPage: 20,
    loading: false,
    user: null,
    selectedTab: 0,
    pagination: null,
    total: 0,
    options: null,
    postings: [],
    selectedPosting: null,

    acceptStatusId: 1,
    appliedStatusId: 2,
    candidateDeclineStatusId: 6,
    declineStatusId: 7,
    inactiveStatusId: 7,
    offerExtendedStatusId: 14,
    interviewStatusId: 12,
    underReviewStatusId: 15,
  }),
  computed: {
    headers() {
      const headers = [];
      // if (this.viewer === 'charity') {
      // }
      headers.push({ text: 'Charity', value: 'charityName' });
      headers.push({ text: 'Position', value: 'positionName' });
      headers.push({ text: 'Candidate', value: 'fullName' });
      headers.push({ text: 'Status', value: 'statusName', align: 'center' });
      headers.push({ text: 'Applied on', value: 'applicationDate' });
      headers.push({
        text: 'Actions', value: 'actions', align: 'right', sortable: false, width: '15%',
      });

      return headers;
    },
  },
  watch: {
    search(value) {
      if (value) {
        this.getApplications();
      }
    },
    options(value) {
      if (value) {
        this.getApplications();
      }
    },
    filters(value) {
      if (value) {
        this.getApplications();
      }
    },
  },

  methods: {
    async getApplications() {
      this.loading = true;
      // const filters = [];
      // filters.push({ name: 'position', values: ['Vice Chair'] });
      const result = await this.$api.candidateApplications.read(createPaginationObject(this.options, this.search, this.filters));

      if (!result) {
        this.applications = [];
        this.total = 0;
        this.loading = false;
        return;
      }
      // this.pagination = pagination;
      this.applications = result.applications;
      this.total = result.total;
      this.loading = false;
    },
    updatePagination(pagination) {
      this.pagination = pagination;
    },
    updateOptions(options) {
      this.options = options;
    },
    refresh() {
      this.getApplications();
    },
    formatDate(date) {
      if (!date) return '';
      return this.$moment(date).format('YYYY-MMM-DD, h:mm A');
    },
    chipColor(applicationStatus) {
      if (applicationStatus == null
        || applicationStatus.id === this.candidateDeclineStatusId
        || applicationStatus.id === this.declineStatusId) { return 'red'; }
      if (this.viewer === 'candidate') {
        if (applicationStatus.id === this.offerExtendedStatusId
          || applicationStatus.id === this.interviewStatusId) return 'green';
        if (applicationStatus.id === this.acceptStatusId) return 'purple';
        if (applicationStatus.id === this.appliedStatusId) return 'blue';

        return 'grey';
      } if (this.viewer === 'charity') {
        if (applicationStatus.id === this.offerExtendedStatusId
          || applicationStatus.id === this.interviewStatusId) return 'green';
        if (applicationStatus.id === this.acceptStatusId) return 'purple';
        if (applicationStatus.id === this.appliedStatusId) return 'blue';

        return 'grey';
      }
      return 'white';
    },
    showCharity(charityId) {
      this.$router.push(`/search?chid=${charityId}`);
    },
    showCandidate(candidateId, postingId) {
      // if (this.viewer === 'charity') {
      this.$router.push(`/search?cid=${candidateId}&pid=${postingId}`);
      // }
    },
    async showCharityPosting(id) {
      // this.$router.push(`/search?cpid=${charityPostingId}`);
      if (this.viewer === 'charity') {
        // const filters = [];
        // filters.push({ name: 'charityId', values: [charityId] });
        const response = await this.$api.charityPostings.read(createPaginationObject(null, null, this.filters));
        if (response && response.postings) {
          this.postings = response.postings;
          this.selectedPosting = this.postings.find((x) => x.id === id);
          if (this.selectedPosting) {
            this.$refs.postingSummaryDialog.openForm();
          } else {
            this.$bus.$emit('showError', 'The position is no longer available.');
          }
        }
      }
    },
    statusMask(status) {
      if (status === 'Accept') return 'Offer Accepted';
      if (status === 'Applied') return 'Application in Progress';

      return status;
    },
  },
};
</script>

<style></style>
