<template>
  <div class="ma-0 pa-0">
    <v-row
      class="pt-0 mt-0"
    >
      <v-col cols="12">
        <candidate-release-form
          ref="releaseForm"
          :is-onboarding="isOnboarding"
          :candidate="candidate"
          :read-only="readOnly"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CandidateReleaseForm from '@/components/candidate/CandidateReleaseForm';

export default {
  components: {
    CandidateReleaseForm,
  },

  props:
    {
      isOnboarding: {
        type: Boolean,
        default: false,
      },
      candidate: {
        type: Object,
        default: () => {},
      },
      readOnly: {
        type: Boolean,
        default: false,
      },
    },
  data: () => ({
    candidateId: null,
  }),
  methods: {
    async submit() {
      const result = {
        success: false,
        completed: false,
      };
      result.completed = await Promise.all([
        this.$refs.releaseForm.validate(),
      ]).then((values) => !values.includes(false));

      this.$bus.$emit('loading', true);

      const res = await this.$refs.releaseForm.submit();

      if (!res) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading', false);
        return result;
      }
      if (!result.completed) {
        // we let them untick the checkbox, but warn them that this prevents them from completing onboarding.
        this.$bus.$emit('showError', 'Successfully updated your agreement, but you must agree to terms and conditions to complete this step.');
      } else {
        this.$bus.$emit('showSuccess', 'Successfully updated your agreement.');
      }
      this.$bus.$emit('loading', false);
      result.success = true;
      return result;
    },
    resetForm() {
      this.$refs.userInformationForm.reset();
      this.$refs.generalPhoneForm.reset();
    },
    validate() {
      return this.$refs.releaseForm.validate();
    },
  },
};
</script>
