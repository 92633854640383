<template>
  <v-container
    fluid
    class="pa-2 pb-12 ma-2 mt-12"
    style="background-color: white; border-radius: 8px;"
  >
    <v-row class="text-left pl-6 pt-5">
      <v-col cols="12">
        <h1>Admin</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-expansion-panels class="ml-0">
          <v-expansion-panel>
            <v-expansion-panel-header><h3>Charity Representatives</h3></v-expansion-panel-header>
            <v-expansion-panel-content>
              <charity-admin-users />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="mt-3">
            <v-expansion-panel-header><h3>Charity Roles</h3></v-expansion-panel-header>
            <v-expansion-panel-content>
              <admin-roles
                :roles="roles"
                :is-charity-rep="true"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="mt-3">
            <v-expansion-panel-header><h3>Positions</h3></v-expansion-panel-header>
            <v-expansion-panel-content>
              <charity-admin-positions />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import CharityAdminUsers from '@/views/charity/charityAdmin/CharityAdminUsers';
import AdminRoles from '@/views/user/AdminRoles';
import CharityAdminPositions from '@/views/charity/charityAdmin/CharityAdminPositions';

export default {
  components: {
    CharityAdminUsers,
    AdminRoles,
    CharityAdminPositions,
  },
  data: () => ({
    roles: [],

  }),
  computed: {
    ...mapState({
      charity: (state) => state.charity.charity,
    }),
  },
  created() {
    this.getRoles();
  },

  methods: {
    async getRoles() {
      if (!this.charity) return;
      this.roles = await this.$api.charityRepRoles.get(`permissions/${this.charity.id}`);
    },
  },

};
</script>
