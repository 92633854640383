<template>
  <v-row justify="center">
    <v-dialog
      v-model="releaseDialog"
      fullscreen
      hide-overlay
    >
      <v-card
        fill-height
      >
        <v-container fluid>
          <v-row>
            <v-col class="text-h4">
              Release Form
            </v-col>
            <v-col class="text-right">
              <v-btn
                icon
                @click="toggleDialog"
              >
                <v-icon large>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <p class="font-weight-bold">
                AGENCY’S AGREEMENT, RELEASE AND INDEMNITY
              </p>
              <p>TO:    Altruvest Charitable Services ("<span class="font-weight-bold">Altruvest</span>") and Volunteer Centre of Calgary ("<span class="font-weight-bold">Volunteer Calgary</span>")</p>
              <p>AND TO:   The Directors and Officers of Altruvest and Volunteer Calgary</p>
              <p>
                In consideration of our participation in BoardMatch® Fundamentals (the "<span class="font-weight-bold">Program</span>") and for other good and valuable consideration, the receipt and sufficiency whereof is hereby acknowledged, we hereby:
              </p>
              <p class="ml-4">
                <ol>
                  <li class="mb-5">
                    <p> Agree to participate in the Program and abide by all of the terms and conditions of the Program, including without limitation, the obligations to: </p>

                    <p>
                      (a) subject to subsection (b), keep confidential and not disclose to any third party, the resumé, candidate’s
                      profile or personal information of any individual provided to us under the Program;
                    </p>
                    <p>
                      (b) only use and disclose to our employees and contractors, the resumé, candidate's profile or
                      personal information of any individual provided to us under the Program for the purposes
                      of the Program;
                    </p>
                    <p>
                      (c) only contact individuals provided to us  under the Program for the purposes of the Program; and
                    </p>
                    <p>
                      (d) maintain directors and officers liability insurance with a reputable insurance company;
                    </p>
                  </li>
                  <li class="mb-5">
                    Agree that since Altruvest and Volunteer Calgary neither pre-screen nor make any representation or   warranty respecting the individuals participating in the Program, we are solely responsible for making our own investigation and review of the individuals referred to us under the Program, particularly if such individuals become our officers, directors, employees or volunteers;
                  </li>
                  <li class="mb-5">
                    Agree to abide by Altruvest’s web site Terms of Service, as amended (the “Terms of Service”), a copy of which is posted on the Altruvest web site located at  <a href="https://altruvest.org/">www.altruvest.org</a> (including <a href="http://www.boardmatch.org/">www.boardmatch.org</a> ) (the “<span class="font-weight-bold">Site</span>”);
                  </li>
                  <li class="mb-5">
                    release and discharge each of you and your respective successors and assigns from and against any and all claims or loss which we may have or could have against you and your respective successors and assigns arising out of or related to the Program, including without limitation, arising out of or related to the collection, use and/or disclosure of personal information provided by us to you, or use of the Site for the purposes outlined in the Terms of Service, and the acts or omissions of any individuals referred to us under the Program; and
                  </li>
                  <li>
                    Indemnify each of you and your respective successors and assigns from and against any and all claims or loss which you and your successors and assigns may suffer or incur arising out of or related to our participation in the Program, including, without limitation, arising out of or related to the collection, use and/or disclosure of personal information provided by us to you, or use of the Site for the purposes outlined in the Terms of Service, and the acts or omissions of any individuals referred to us under the Program.
                  </li>
                </ol>
              </p>
            </v-col>
          </v-row>
          <p>
            <v-checkbox
              v-model="isAgreed"
              label="I agree to the above terms and conditions."
            />
          </p>
          <v-row>
            <v-col
              style="background-color: #ffffe6;"
              class="ml-3 mr-3"
            >
              <span>By clicking “I Agree”, I acknowledge that: I have read the terms stated above; I intend to form a legally binding agreement between yourself and Altruvest, and any related Altruvest program co-operator listed above (i.e. Volunteer Calgary or Volunteer Thunder Bay), if and as applicable. I will abide by the terms stated above; and that the use of our website and the Program are subject to Altruvest’s <a
                href="https://altruvest.org/BoardMatch/Content/Downloads/Altruvest%20Terms%20of%20Service.pdf"
                target="_blank"
              >Terms of Service</a> and <a
                href="https://altruvest.org/BoardMatch/Content/Downloads/Altruvest%20Privacy%20Policy.pdf"
                target="_blank"
              >Privacy Policy</a>.</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-end">
              <v-btn
                class="mr-4"
                text

                @click="toggleDialog"
              >
                cancel
              </v-btn>
              <v-btn
                class="mr-4"
                type="submit"
                color="success"
                :disabled="isAgreed ? false : true"
                @click="submit"
              >
                submit
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

export default {

  data: () => ({
    releaseDialog: false,
    isAgreed: false,
  }),

  methods: {
    toggleDialog() {
      this.releaseDialog = !this.releaseDialog;
    },
    submit() {
      if (!this.isAgreed) return;
      this.$emit('agreed-success');
      this.toggleDialog();
    },

  },
};
</script>
