<template>
  <v-container fluid>
    <v-row class="text-left">
      <v-col cols="8">
        <h1>{{ charityTitle }}</h1>
      </v-col>
      <v-col class="text-end">
        <v-btn
          v-if="updates && isCharityPage"
          color="primary"
          outlined
          class="mt-3"
          @click="openCharityUpdate"
        >
          <v-icon
            small
            class="mr-1"
          >
            mdi-square-edit-outline
          </v-icon>
          EDIT
        </v-btn>
      </v-col>
    </v-row>

    <charity-edit-template
      ref="charityEditTemplate"
      :charity="charity"
      :show-section-title="false"
      :is-charity-page="isCharityPage"
      :is-phone-required="false"
      :is-address-required="false"
      @charity-success="notifySuccess"
    />
    <v-row v-if="!isCharityPage">
      <v-col
        class="text-end"
      >
        <v-btn
          class="mr-4"
          text
          @click="cancel"
        >
          cancel
        </v-btn>
        <v-btn
          class="mr-4"
          color="success"
          @click="submit"
        >
          submit
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import CharityEditTemplate from '@/components/charity/CharityEditTemplate';

export default {
  components: {
    CharityEditTemplate,
  },
  data: () => ({
    charityTitle: 'Charity',
    isCharityPage: true,
    updates: false,
  }),
  computed: {
    ...mapState({
      charityRep: (state) => state.charityRep.charityRep,
      charity: (state) => state.charity.charity,
    }),
    ...mapGetters({
      findClaim: 'auth/findClaim',
    }),
  },

  created() {
    const charityClaim = this.findClaim('charity.update');
    if (!charityClaim) return;
    if (charityClaim === '1') {
      this.updates = true;
    }
  },

  methods: {
    async getCharity() {
      if (!this.charityRep && !this.charity) return;
      if (this.charity != null && this.charity.id > 0) this.$store.dispatch('charity/getCharity', this.charity.id);
      else if (this.charityRep != null) this.$store.dispatch('charity/getCharity', this.charityRep.charityId);
    },

    openCharityUpdate() {
      this.charityTitle = 'Update Charity';
      this.isCharityPage = false;
    },

    notifySuccess(message) {
      this.$bus.$emit('showSuccess', message);
      this.getCharity();
      this.isCharityPage = true;
      this.charityTitle = 'Charity';
    },

    submit() {
      this.$refs.charityEditTemplate.submitCharity();
    },

    cancel() {
      this.charityTitle = 'Charity';
      this.getCharity();
      this.isCharityPage = true;
    },

  },
};
</script>
