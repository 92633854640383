<template>
  <v-container
    fluid
    fill-height
  >
    <v-row
      v-if="isActive"
      align-center
      justify-center
      fill-width
    >
      <v-spacer />
      <v-col
        xs12
        sm10
        md6
        lg4
      >
        <v-row class="mb-5">
          <v-col class="text-center">
            <img
              :src="require('@/assets/Altruvest Full Logo - Blue Text.svg')"
              width="65%"
              class="mb-5"
            >
          </v-col>
        </v-row>
        <v-card class="elevation-1">
          <v-toolbar
            dark
            color="primary"
          >
            <v-toolbar-title>Reset Password</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <validation-observer
              ref="passwordObserver"
            >
              <validation-provider
                v-slot="{ errors }"
                name="password"
                rules="required|password:@confirm|password-rule"
              >
                <v-text-field
                  v-model="password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :error-messages="errors"
                  label="Password"
                  required
                  :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword"
                >
                  <template #label>
                    Password<red-asterisk />
                  </template>
                </v-text-field>
              </validation-provider>

              <validation-provider
                v-slot="{ errors }"
                name="confirm"
                rules="required|password-rule"
              >
                <v-text-field
                  v-model="confirmation"
                  :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :error-messages="errors"
                  label="Confirm Password"
                  required
                  :type="showConfirmPassword ? 'text' : 'password'"
                  @click:append="showConfirmPassword = !showConfirmPassword"
                >
                  <template #label>
                    Confirm Password<red-asterisk />
                  </template>
                </v-text-field>
              </validation-provider>
            </validation-observer>
          </v-card-text>
          <v-card-actions>
            <v-row class="px-3">
              <v-spacer />
              <v-col class="text-right">
                <v-btn
                  link
                  :to="'/login'"
                  text
                >
                  Back To Login
                </v-btn>
              </v-col>
              <v-col
                class="text-right"
              >
                <v-btn
                  color="success"
                  :disabled="isSbmBtnDisabled"
                  @click="submit"
                >
                  Update Password
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-spacer />
    </v-row>
    <Snackbar ref="snackbar" />
  </v-container>
</template>

<script>
import { required } from 'vee-validate/dist/rules';
import {
  extend, ValidationObserver, ValidationProvider, setInteractionMode,
} from 'vee-validate';
import Snackbar from '@/components/app/TheSnackbar';

setInteractionMode('eager');

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
});

extend('password-rule', {
  validate(value) {
    const regex = new RegExp(/(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*?]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/);
    return regex.test(value);
  },
  message: 'Use 8 or more characters with a mix of letters, numbers & symbols',
});

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Password confirmation does not match',
});

export default {
  components: {
    Snackbar,
    ValidationProvider,
    ValidationObserver,
  },

  beforeRouteEnter(to, from, next) {
    if (!to.params && !to.params.token) return;
    const { token } = to.params;

    next((method) => {
      method.init(token);
    });
  },
  data: () => ({
    confirmation: '',
    password: '',
    token: '',
    showPassword: false,
    showConfirmPassword: false,
    isActive: false,
    isSbmBtnDisabled: false,
  }),
  created() {

  },
  mounted() {
    this.checkResetPassword();
  },
  methods: {
    init(token) {
      this.token = token;
    },
    async submit() {
      const validate = await this.$refs.passwordObserver.validate();
      if (!validate) return;
      if (!this.token || !this.password) return;
      const res = await this.$api.auth.resetPassword(this.token, this.password);
      if (!res) {
        this.$refs.snackbar.showError();
        return;
      }

      this.$refs.snackbar.showSuccess('Password has been reset successfully!');
      this.isSbmBtnDisabled = true;
      setTimeout(() => {
        this.$router.push('/login');
      }, 3000);
    },

    async checkResetPassword() {
      if (!this.token) return;
      const res = await this.$api.auth.checkResetPassword(this.token);
      if (!res) {
        this.$bus.$emit('showError', 'Password Reset has expired');
        setTimeout(() => {
          this.$router.push('/login');
        }, 3000);
        return;
      }
      this.isActive = true;
    },
  },
};
</script>
