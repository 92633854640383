/* eslint-disable import/prefer-default-export */
import { kebabCaseFormatter } from '@/helpers/FormatHelper.js';
import ThinkificAuthenticationApi from '@/api/thinkific/ThinkificAuthenticationApi';

export async function openThinkificCourse(courseName = null) {
  // TODO: move to environment file
  const thinkificSubdomain = process.env.VUE_APP_THINKIFIC_SUBDOMAIN;
  const errorText = 'Could not open course';
  let returnUrl = '';
  if (courseName) {
    const courseNameUrl = kebabCaseFormatter(courseName);
    if (courseNameUrl) returnUrl = `&return_to=https://${thinkificSubdomain}.thinkific.com/courses/${courseNameUrl}`;
  }
  try {
    const res = await ThinkificAuthenticationApi.get();
    if (res) {
      const thinkificJWT = res;
      window.open(
        `https://${thinkificSubdomain}.thinkific.com/api/sso/v2/sso/jwt?jwt=${thinkificJWT}${returnUrl}`,
        '_blank',
      );
    } else {
      this.$bus.$emit('showError', errorText);
      console.error(errorText);
    }
  } catch (e) {
    console.error(errorText);
    console.error(e);
  }
}
// options: {page: number,
// itemsPerPage: number,
// sortBy: string[],
// sortDesc: boolean[],
// groupBy: string[],
// groupDesc: boolean[],
// multiSort: boolean,
// mustSort: boolean}
