<template>
  <v-row>
    <v-col class="text-h6 font-weight-regular green--text">
      Existing Boardmatch user "{{ username }}" will be used.
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    username: {
      type: String,
      default: '',
    },
  },
};
</script>

<style></style>
