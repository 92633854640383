import axios from 'axios';
import Api from '../Api';

class CandidateOnboardingApi extends Api {
  constructor() {
    super('candidates/onboarding', 'candidateonboarding');
  }

  async complete(candidateId, onboardingId, completed) {
    let res;
    const errorText = `Could not refresh onboarding for candidate id ${candidateId}`;
    try {
      const url = `/${this.endpoint}/complete?candidateId=${candidateId}&onboardingId=${onboardingId}&completed=${completed}`;
      res = await axios.get(url);
      if (res.status === 200) {
        res = res.data;
      } else {
        console.error(errorText);
        res = false;
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = false;
    }
    return res;
  }
}

const candidateOnboardingApi = new CandidateOnboardingApi();
export default candidateOnboardingApi;
