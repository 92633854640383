var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('span',{staticClass:"text-h6"},[_vm._v(" \""+_vm._s(_vm.role ? _vm.role.name : '')+"\" Role Permissions ")])])],1),_c('v-data-table',{staticClass:"mt-5",attrs:{"headers":_vm.dataHeaders,"items":_vm.role.charityRepresentativeRolePermission,"footer-props":_vm.dataPerPageProps,"items-per-page":_vm.dataPerPage,"dense":""},scopedSlots:_vm._u([{key:"item.claimName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.charityRepresentativeClaim.name)+" ")]}},{key:"item.read",fn:function(ref){
var item = ref.item;
return [_c('input',{staticClass:"mt-2 ml-2",staticStyle:{"width":"25px","height":"25px","pointer-events":"none"},attrs:{"readonly":"","type":"radio"},domProps:{"checked":item.reads === 1 ? true : false}})]}},{key:"item.create",fn:function(ref){
var item = ref.item;
return [_c('input',{staticClass:"mt-2 ml-2",staticStyle:{"width":"25px","height":"25px","pointer-events":"none"},attrs:{"readonly":"","type":"radio"},domProps:{"checked":item.creates === 1 ? true : false}})]}},{key:"item.update",fn:function(ref){
var item = ref.item;
return [_c('input',{staticClass:"mt-2 ml-2",staticStyle:{"width":"25px","height":"25px","pointer-events":"none"},attrs:{"readonly":"","type":"radio"},domProps:{"checked":item.updates === 1 ? true : false}})]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('input',{staticClass:"mt-2 ml-2",staticStyle:{"width":"25px","height":"25px","pointer-events":"none"},attrs:{"readonly":"","type":"radio"},domProps:{"checked":item.deletes === 1 ? true : false}})]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.charityRepresentativeClaim.description)+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }