<template>
  <v-row>
    <v-col
      cols="12"
      class="pt-0 pb-0"
    >
      <span
        class="text-h4"
      ><b>Candidates </b></span>
      <!-- <template v-if="total == 0 && loadingCandidates == true">
        <v-progress-circular
          indeterminate
          color="grey"
          size="20"
          class="mb-2 mr-2"
        />
        <span class="text-h6 grey--text text--darken-2">Loading...</span>
      </template> -->
      <template>
        <!--v-else-->
        <span class="text-h6 grey--text text--darken-2">{{ total.toLocaleString() }} Result{{ total != 1 ? 's' : '' }}</span>
      </template>
    </v-col>
    <v-col
      v-for="(candidate) in candidates"
      :key="`cand-${candidate.id}`"
      cols="4"
    >
      <div
        class="white widget ma-2"
      >
        <search-candidate-result-tile-widget
          :candidate="candidate"
          @showCandidate="(candidateId) => showCandidate(candidateId)"
        />
      </div>
    </v-col>
    <v-col
      v-if="total > 0 && candidates.length > 0 && candidates.length < total"
      cols="12"
      class="d-flex justify-center"
    >
      <v-btn
        id="#loadMoreCandidatesBtn"
        :disabled="loadingCandidates"
        elevation="0"
        outlined
        @click="searchCandidates(false)"
      >
        <v-progress-circular
          v-if="loadingCandidates"
          indeterminate
          color="grey"
        />{{ loadingCandidates ? 'Loading...' : 'Load More' }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';
import { createPaginationObject } from '@/helpers/PaginationHelper.js';
import SearchCandidateResultTileWidget from '@/components/search/SearchCandidateResultTileWidget';

export default {
  components: {
    SearchCandidateResultTileWidget,
  },

  props:
  {
    filters: {
      type: Array,
      default: () => [],
    },
    search: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    candidates: [],
    loadingCandidates: false,
    total: 0,
    options: {
      page: 1,
      itemsPerPage: 6,
      sortBy: [],
      include: ['ProfileImage'],
    },
    candidateThumbnailImages: [],
    candidateProfileImages: [],
  }),
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.auth.isLoggedIn,
    }),
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    init() {
    },
    async searchCandidates(reset = true) {
      if (reset && this.loadingCandidates) return; // prevent double-invocation of initial search
      this.loadingCandidates = true;
      if (reset) {
        this.candidates = [];
        this.options.page = 1;
        this.total = 0;
      }
      const result = this.isLoggedIn
        ? await this.$api.candidate.read(
          createPaginationObject(this.options, this.search, this.filters),
        )
        : await this.$api.candidate.readPublic(
          createPaginationObject(this.options, this.search, this.filters),
        );
      if (result) {
        this.options.page += 1;
        this.total = result.total;
      }
      result.candidates.forEach((candidate) => {
        this.candidates.push({ ...candidate });
      });
      this.$emit('loaded', this.total);
      this.goto('loadMoreCandidatesBtn');
      this.loadingCandidates = false;
    },
    goto(elementId) {
      const el = document.getElementById(`#${elementId}`);
      if (el != null) el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    },
    showCandidate(candidateId) {
      this.$emit('showCandidate', candidateId);
    },
  },
};
</script>
<style scoped>
  .widget{
    border-radius: 8px;
    padding: 12px;
  }
</style>
