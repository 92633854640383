<template>
  <v-hover
    v-slot="{ hover }"
  >
    <v-row
      :class="`${hover ? 'custom-color' : ''} widget pa-0`"
      @click="openCandidate()"
    >
      <v-col
        cols="4"
        class="widget d-flex align-center"
      >
        <initials-portrait
          :last-name="candidate.user.userInformation.lastName"
          :first-name="candidate.user.userInformation.firstName"
          :image="candidate && candidate.profileImage && candidate && candidate.profileImage.image ? candidate.profileImage.image : null"
          :colour="`#${candidate.user.profileColour}`"
          max-height="110px"
          max-width="110px"
          font-size="40px"
        />
      </v-col>
      <v-col
        cols="8"
        class="pa-2 pb-2 pt-2 d-flex align-center"
      >
        <div
          :class="`${hover ? '' : 'white'} widget pa-1`"
          style="width: 100%;"
        >
          <span class="text-h6 grey--text text--darken-3">{{ fullName }}</span><br>
          <v-divider class="mt-2 mb-2" />
          <span
            class="grey--text text--darken-2"
          >{{ description }}</span><br>
          <span class="text-subtitle-2">
            {{ organizationName }}
          </span>
          <br>
          <span
            v-if="location"
            class="ma-1 ml-0 grey--text text--darken-2"
          >
            <v-icon
              small
            >
              mdi-crosshairs-gps
            </v-icon>
            {{ location }}
          </span>
        </div>
      </v-col>
    </v-row>
  </v-hover>
</template>

<script>
import InitialsPortrait from '@/components/general/InitialsPortrait';

export default {
  components: {
    InitialsPortrait,
  },

  props:
  {
    candidate: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
  }),
  computed: {
    location() {
      if (this.candidate && this.candidate.user && this.candidate.user.userInformation && this.candidate.user.userInformation.address && this.candidate.user.userInformation.address.region && this.candidate.user.userInformation.address.country) {
        return `${this.candidate.user.userInformation.address.region.name}, ${this.candidate.user.userInformation.address.country.name}`;
      }
      return '';
    },
    fullName() {
      const fullName = `${this.candidate.user.userInformation.firstName} ${this.candidate.user.userInformation.lastName}`;
      /* if (fullName.length > 15) {
        // enforce a 9 character limit per name, with 1 extra character for the space in between.
        let { firstName, lastName } = this.candidate.user.userInformation;
        if (firstName == null) firstName = '';
        if (lastName == null) lastName = '';
        if (firstName.length < 7) {
          // first name is shorter than the 9 character limit, so take the remainder and allow more characters for last name
          lastName = `${lastName.substring(0, (7 + (9 - firstName.length)))}...`;
        } else if (lastName.length < 7) {
          // last name is shorter than the 9 character limit, so take the remainder and allow more characters for first name
          firstName = `${firstName.substring(0, (7 + (9 - lastName.length)))}...`;
        } else {
          // both names are longer than 9 characters, cut both down
          firstName = `${firstName.substring(0, 7)}...`;
          lastName = `${lastName.substring(0, 7)}...`;
        }
        fullName = `${firstName} ${lastName}`;
      } */
      return fullName;
    },
    description() {
      let name = '';
      if (this.candidate && this.candidate.candidateQualifications && this.candidate.candidateQualifications.candidateExperience && this.candidate.candidateQualifications.candidateExperience.length > 0) {
        const experience = this.candidate.candidateQualifications.candidateExperience.find((x) => x.end == null);
        if (experience && experience.title) {
          name = `${experience.title}`;
          // if (experience.organizationName) name += ` at ${experience.organizationName}`;
        }
      }
      return name;
    },
    organizationName() {
      let name = '';
      if (this.candidate && this.candidate.candidateQualifications && this.candidate.candidateQualifications.candidateExperience && this.candidate.candidateQualifications.candidateExperience.some((x) => x.end == null && x.organizationName != null)) {
        const experience = this.candidate.candidateQualifications.candidateExperience.find((x) => x.end == null);
        name = `${experience.organizationName}`;
      }
      return name;
    },
  },
  watch: {
  },
  methods: {
    openCandidate() {
      this.$emit('showCandidate', this.candidate.id);
      // this.$router.push(`/candidate-profile?cid=${this.candidate.id}`);
    },
  },
};
</script>
<style>
  .widget{
    border-radius: 10px;
    padding: 12px;
  }
  .profile-image {
    border-radius: 50%;
  }
  .hovering {
    background-color: transparent !important;
    background-color: #E0E0E0 !important;
    cursor: pointer;
  }
  .custom-color {
   background-color: #d0eed9;
   cursor: pointer;
  }
</style>
