<template>
  <v-dialog
    v-model="postingDialog"
    width="1300"
  >
    <v-card
      fill-height
    >
      <v-container fluid>
        <v-row>
          <v-col class="text-h4">
            {{ title }}
          </v-col>
          <v-col class="text-right">
            <v-btn
              icon
              @click="closePostingDialog"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <charity-posting-template
          :charity="charity"
        />
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import CharityPostingTemplate from '@/components/charity/charityPosting/CharityPostingTemplate';

export default {
  components: {
    CharityPostingTemplate,
  },
  props:
    {
      title: {
        type: String,
        default: '',
      },
      charity: {
        type: Object,
        default: () => {},
      },
    },

  data: () => ({
    postingDialog: false,
  }),

  methods: {
    openPostingDialog() {
      this.postingDialog = true;
    },
    closePostingDialog() {
      this.postingDialog = false;
    },
  },
};
</script>
