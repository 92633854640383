<template>
  <v-row justify="center">
    <v-dialog
      v-model="charityDialog"
      fullscreen
      hide-overlay
    >
      <v-card
        fill-height
      >
        <v-container
          fluid
        >
          <template v-if="isEdit == true">
            <v-row>
              <v-col class="text-h4">
                {{ title }}
              </v-col>
              <v-col class="text-right">
                <v-btn
                  icon
                  @click="closeForm"
                >
                  <v-icon large>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="mt-0 pa-3">
              <v-col class="text-h5 font-weight-bold">
                Charity Information
              </v-col>
              <!-- <v-col cols="12">
                <charity-information-form
                  ref="charityInformationForm"
                />
              </v-col> -->
            </v-row>
            <charity-information-form
              ref="charityInformationForm"
            />
            <v-row class="mt-0 pa-3">
              <v-col
                cols="6"
              >
                <general-phone-form
                  ref="generalPhoneForm"
                />
              </v-col>
              <v-col
                cols="6"
              >
                <general-phone-form
                  ref="eveningPhoneForm"
                  :is-required="false"
                  phone-type="evening"
                />
              </v-col>
              <v-col
                class="pa-0"
                cols="12"
              >
                <general-address-form
                  ref="generalAddressForm"
                  :address="address"
                />
              </v-col>
            </v-row>

            <v-row class="pa-3 mt-0">
              <v-col>
                <span class="text-h5 font-weight-bold">
                  Senior Staff
                </span>
              </v-col>
            </v-row>
            <v-row class="pa-3 mt-0">
              <v-col
                v-if="!seniorStaff"
                cols="12"
              >
                <user-information-form
                  ref="userSeniorInformationForm"
                  :title="''"
                  :hide-password="false"
                />
              </v-col>
              <v-col
                v-if="!seniorStaff"
                class="pa-0"
                cols="12"
              >
                <general-contact-form

                  ref="generalContactForm"
                  :show-first-name="false"
                  :show-last-name="false"
                  :show-email="false"
                />
              </v-col>
              <user-used-title
                v-else
                :username="seniorStaff ? `${seniorStaff.userInformation.firstName} ${seniorStaff.userInformation.lastName}` : ''"
              />
            </v-row>
            <charity-rep-information-form
              ref="charityRepSeniorInformationForm"
            />
            <v-row class="pa-3">
              <v-col
                cols="12"
                class="pt-0 pb-0"
              >
                <span class="text-h5 font-weight-bold">
                  Charity Administrator
                </span>
              </v-col>
              <v-cols
                class="pa-3"
                cols="12"
              >
                <user-information-form
                  v-if="!user"
                  ref="userInformationForm"
                  :hide-password="false"
                />
                <user-used-title
                  v-else
                  :username="user ? `${user.userInformation.firstName} ${user.userInformation.lastName}` : '' "
                />
              </v-cols>
            </v-row>

            <!-- <charity-rep-information-form
              ref="charityRepInformationForm"
            /> -->
            <v-row class="pa-3">
              <v-col
                class="text-end"
              >
                <v-btn
                  class="mr-4"
                  text
                  @click="closeForm"
                >
                  cancel
                </v-btn>
                <v-btn
                  class="mr-4"
                  type="submit"
                  color="success"
                  @click="submitCharity"
                >
                  submit
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <charity-summary
              :charity-id="charity.id"
            />
            <v-row class="pa-3">
              <v-col
                class="text-end"
              >
                <v-btn
                  class="mr-4"
                  text
                  @click="closeForm"
                >
                  close
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import UserInformationForm from '@/components/user/UserInformationForm';
import GeneralPhoneForm from '@/components/general/GeneralPhoneForm';
import GeneralAddressForm from '@/components/general/GeneralAddressForm';
import GeneralContactForm from '@/components/general/GeneralContactForm';
import CharityInformationForm from '@/components/charity/CharityInformationForm';
import CharityRepInformationForm from '@/components/charity/charityRep/CharityRepInformationForm';
import UserUsedTitle from '@/components/user/UserUsedTitle.vue';
import CharitySummary from '@/components/charity/CharitySummary';

export default {
  components: {
    UserInformationForm,
    GeneralPhoneForm,
    GeneralAddressForm,
    GeneralContactForm,
    CharityInformationForm,
    CharityRepInformationForm,
    UserUsedTitle,
    CharitySummary,
  },
  props:
  {
    charity: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    charityDialog: false,
    seniorStaff: null,
    user: null,
    currentUser: 0,
    address: null,
    isEdit: true,
  }),

  methods: {
    openCharityForm(isEdit = null) {
      if (isEdit != null) this.isEdit = isEdit;
      this.charityDialog = true;
    },
    closeCharityForm() {
      this.charityDialog = false;
    },
    closeForm() {
      this.resetForm();
      this.charityDialog = false;
    },
    async submitCharity() {
      const validateComponentsResult = await Promise.all([
        this.$refs.charityInformationForm.validate(),
        this.user ? true : this.$refs.userInformationForm.validate(),
        this.$refs.generalAddressForm.validate(),
        this.$refs.generalPhoneForm.validate(),
        this.seniorStaff ? true : this.$refs.generalContactForm.validate(),
        this.seniorStaff ? true : this.$refs.userSeniorInformationForm.validate(),
      ])
        .then((values) => !values.includes(false));

      if (!validateComponentsResult) return;
      this.$bus.$emit('loading', true);

      const charityResponse = await this.$refs.charityInformationForm.submit();
      const userResponse = this.user ? true : await this.$refs.userInformationForm.submit(2);
      const userSeniorResponse = this.seniorStaff ? true : await this.$refs.userSeniorInformationForm.submit(2);
      if (!charityResponse && !userResponse && !userSeniorResponse) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading', false);
        return;
      }

      const charityRepResponse = this.user
        ? await this.$refs.charityRepInformationForm.submit(charityResponse.id, this.user.id, [{ id: 1, name: 'Admin' }])
        : await this.$refs.charityRepInformationForm.submit(charityResponse.id, userResponse.id, [{ id: 1, name: 'Admin' }]);

      const charitySeniorRepResponse = this.seniorStaff
        ? await this.$refs.charityRepSeniorInformationForm.submit(charityResponse.id, this.seniorStaff.id, [{ id: 1, name: 'Admin' }])
        : await this.$refs.charityRepSeniorInformationForm.submit(charityResponse.id, userSeniorResponse.id, [{ id: 1, name: 'Admin' }]);

      const addressResponse = await this.$refs.generalAddressForm.submit(charityResponse.id);
      const phoneResponse = await this.$refs.generalPhoneForm.submit(charityResponse.id);
      const phone2Response = await this.$refs.eveningPhoneForm.submit(charityResponse.id);
      const contactResponse = this.seniorStaff ? true : await this.$refs.generalContactForm.submit(charityResponse.id);

      if (!charityRepResponse && !addressResponse && !phoneResponse && !contactResponse && !charitySeniorRepResponse && !phone2Response) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading', false);
        return;
      }
      if (this.charity && this.charity.id) {
        this.$emit('charity-success', 'Charity Updated');
      } else {
        this.$emit('charity-success', 'Charity Created');
      }
      this.resetForm();
      this.$bus.$emit('loading', false);
      this.charityDialog = false;
    },
    userFormSelected(data) {
      if (!data) return;
      if (this.currentUser === 1) {
        this.user = data;
      } else if (this.currentUser === 2) {
        this.seniorStaff = data;
      }
    },
    resetForm() {
      // if we're in read mode, this.$refs.charityInformationForm is null
      if (this.$refs.charityInformationForm) {
        this.$refs.charityInformationForm.reset();
        if (!this.user) this.$refs.userInformationForm.reset();
        if (!this.seniorStaff) this.$refs.userSeniorInformationForm.reset();
        this.$refs.generalAddressForm.reset();
        this.$refs.generalPhoneForm.reset();
        if (!this.seniorStaff) this.$refs.generalContactForm.reset();
        this.user = null;
        this.seniorStaff = null;
      }
    },

  },
};
</script>
