import axios from 'axios';
import Api from '../Api';

class CorporationApi extends Api {
  constructor() {
    super('corporations', 'corporations');
  }

  async create(data) {
    let res = {};
    const errorText = `Could not create ${this.model}`;
    try {
      res = await axios.post(`/${this.endpoint}`, data);
      if (res.status === 200) {
        res = res.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = false;
    }
    return res;
  }

  async update(data) {
    let res = {};
    const errorText = `Could not create ${this.model}`;
    try {
      res = await axios.put(`/${this.endpoint}`, data);
      if (res.status === 200) {
        res = res.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = false;
    }
    return res;
  }

  async getById(id) {
    let item = {};
    const errorText = `Could not fetch ${this.model}`;
    try {
      const url = `/${this.endpoint}/${id}`;
      item = await axios.get(url);
      if (item.status === 200) {
        item = item.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      item = false;
    }
    return item;
  }
}

const corporationApi = new CorporationApi();
export default corporationApi;
