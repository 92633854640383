<template>
  <v-container
    fluid
    style="padding-inline: 50px; padding-bottom: 0px;"
  >
    <!-- <h2
      v-if="showHeader == true"
      class="mb-5"
    >
      Posting Information
    </h2> -->
    <validation-observer ref="postingValObserver">
      <v-row>
        <v-col cols="5">
          <span style="font-size: large; font-weight: bolder;">Position</span><red-asterisk />
          <validation-provider
            v-slot="{ errors }"
            name="Board Position"
            rules="required"
          >
            <v-select
              v-model="postingData.charityPositionId"
              :items="charityPositionTypes"
              :error-messages="errors"
              label="Board Position"
              data-vv-name="select"
              :item-text="(item) => `${item.name} ${item.isNotBoard ? '(Not Board member)': ''}`"
              item-value="id"
              :readonly="readonly"
              :style="readonly ? 'pointer-events: none;' : ''"
              class="custom-bolded-selected-value"
              dense
            >
              <template #label>
                Position<red-asterisk />
              </template>
            </v-select>
          </validation-provider>
        </v-col>
        <v-col cols="1" />
        <v-col
          cols="3"
        >
          <span style="font-size: large; font-weight: bolder;">Mandate Period</span>
          <v-menu
            v-model="dateMenus.start"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
            :close-on-content-click="false"
          >
            <template #activator="{ on, attrs }">
              <validation-provider
                v-slot="{ errors }"
                name="Expiry Date"
                rules="required"
              >
                <v-text-field
                  v-model="postingData.startDate"
                  persistent-hint
                  :readonly="readonly"
                  :style="readonly ? 'pointer-events: none;' : ''"
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  :error-messages="errors"
                  class="custom-text-field-bold-value"
                  dense
                  v-on="on"
                >
                  <template #label>
                    Mandate Start Date<red-asterisk />
                  </template>
                </v-text-field>
              </validation-provider>
            </template>
            <v-date-picker
              v-model="postingData.startDate"
              :max="postingData.endDate"
              :min="currentDate"
              no-title
              :readonly="readonly"
              :style="readonly ? 'pointer-events: none;' : ''"
            >
              <v-spacer />
              <v-btn
                text
                color="primary"
                @click="dateMenus.start = false"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="3">
          <br>
          <v-menu
            v-model="dateMenus.end"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
            :close-on-content-click="false"
          >
            <template #activator="{ on, attrs }">
              <validation-provider
                v-slot="{ errors }"
                name="Expiry Date"
                rules="required"
              >
                <v-text-field
                  v-model="postingData.endDate"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  :readonly="readonly"
                  :style="readonly ? 'pointer-events: none;' : ''"
                  :error-messages="errors"
                  class="custom-text-field-bold-value"
                  dense
                  v-on="on"
                >
                  <template #label>
                    Mandate End Date<red-asterisk />
                  </template>
                </v-text-field>
              </validation-provider>
            </template>
            <v-date-picker
              v-model="postingData.endDate"
              :min="postingData.startDate ? postingData.startDate : currentDate"
              no-title
              :readonly="readonly"
              :style="readonly ? 'pointer-events: none;' : ''"
            >
              <v-spacer />
              <v-btn
                text
                color="primary"
                @click="setEndDate()"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col
          cols="12"
          style="padding-top: 0px;"
        >
          <span style="font-size: large; font-weight: bold;">Position Description<red-asterisk /></span>
          <span
            v-if="!postingData.description && validationTriggered"
            class="red--text ml-2"
          >Must be filled</span>
        </v-col>
        <v-col
          cols="12"
          style="padding-top: 0px;"
        >
          <validation-provider
            v-slot="{ errors }"
            name="Description"
            rules="required"
          >
            <!-- <v-textarea
              v-model="postingData.description"
              outlined
              :error-messages="errors"
              name="input-7-4"
              rows="3"
              :readonly="readonly"
              :style="readonly ? 'pointer-events: none;' : ''"
            /> -->
            <vue-editor
              id="editor1"
              v-model="postingData.description"
              :error-messages="errors"
              :editor-toolbar="customToolbar"
              :readonly="readonly"
              :style="readonly ? 'pointer-events: none;' : ''"
            />
          </validation-provider>
        </v-col>
      </v-row>

      <v-row class="pt-1 mt-0">
        <v-col cols="12">
          <span style="font-size: large; font-weight: bold;">Skills<red-asterisk /></span>
          <validation-provider
            v-slot="{ errors }"
            name="Skills"
            rules="required"
          >
            <v-text-field
              v-show="false"
              v-model="skillsSelected"
            />
            <span class="red--text font-weight-regular subtitle-1">{{ errors.length > 0 ? 'Must select at least one skill' : '' }}</span>
          </validation-provider>
        </v-col>
        <v-col
          v-for="(skill, index) in candidateSkills"
          :key="index"
          sm="2"
          class="pt-1 pb-1"
        >
          <v-checkbox
            :key="'s' + index"
            v-model="postingData.candidateSkill"
            :label="skill.name"
            :value="skill.id"
            hide-details
            :readonly="readonly"
            :style="readonly ? 'pointer-events: none;' : ''"
            class="pa-0 ma-0"
          />
        </v-col>
      </v-row>
      <!--<v-row>
        <v-col
          cols="3"
          class="text-right"
        >
          <v-text-field
            label="optional skill"
          />
        </v-col>
      </v-row>-->
      <!-- <v-row>
        <v-col>
          <span style="font-size: large; font-weight: bold;">Position Description<red-asterisk /></span>
        </v-col>
        <v-col cols="9">
          <span>Do you have any special requirements for this position i.e., accounting designation, work or residence in a specific geographical area, etc.?<red-asterisk /></span>
        </v-col>
      </v-row> -->
      <v-row>
        <!-- <v-col cols="12" /> -->
        <!-- <v-col>
          <validation-provider
            v-slot="{ errors }"
            name="Description"
            rules="required"
          >
            <v-textarea
              v-model="postingData.description"
              outlined
              :error-messages="errors"
              name="input-7-4"
              rows="5"
              :readonly="readonly"
              :style="readonly ? 'pointer-events: none;' : ''"
            />
          </validation-provider>
        </v-col> -->
        <v-col
          cols="7"
        >
          <span>Do you have any special requirements for this position i.e., accounting designation, work or residence in a specific geographical area, etc.?<red-asterisk /></span>
          <validation-provider
            v-slot="{ errors }"
            name="Special Requirements"
            rules="required"
          >
            <v-textarea
              v-model="postingData.specialRequirements"
              outlined
              :error-messages="errors"
              name="input-7-4"
              rows="2"
              :readonly="readonly"
              :style="readonly ? 'pointer-events: none; width: 920px; margin-top: 10px;' : 'width: 920px; margin-top: 10px;'"
            />
          </validation-provider>
        </v-col>
        <v-col cols="1" />
        <v-col cols="4">
          <span style="font-size: large; font-weight: bold;">Posting Expiry</span>
          <v-menu
            v-model="dateMenus.expiry"
            transition="scale-transition"
            offset-y
            max-width="290px"
            :close-on-content-click="false"
            min-width="auto"
            :readonly="readonly"
            :style="readonly ? 'pointer-events: none;' : ''"
          >
            <template #activator="{ on, attrs }">
              <validation-provider
                v-slot="{ errors }"
                name="Expiry Date"
                rules="required"
              >
                <v-text-field
                  v-model="postingData.expiryDate"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  :error-messages="errors"
                  :readonly="readonly"
                  :style="readonly ? 'pointer-events: none;' : ''"
                  v-on="on"
                >
                  <template #label>
                    Posting Expiry Date<red-asterisk />
                  </template>
                </v-text-field>
              </validation-provider>
            </template>
            <v-date-picker
              v-model="postingData.expiryDate"
              no-title
              :readonly="readonly"
              :style="readonly ? 'pointer-events: none;' : ''"
              :min="currentDate"
              :max="postingData.endDate ? postingData.endDate : maxExpirationDate"
            >
              <v-spacer />
              <v-btn
                text
                color="primary"
                @click="dateMenus.expiry = false"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col>
          <v-row>
            <v-col
              cols="5"
              sm="6"
              xs="6"
            >
              <v-menu
                v-model="dateMenus.expiry"
                transition="scale-transition"
                offset-y
                max-width="290px"
                :close-on-content-click="false"
                min-width="auto"
                :readonly="readonly"
                :style="readonly ? 'pointer-events: none;' : ''"
              >
                <template #activator="{ on, attrs }">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Expiry Date"
                    rules="required"
                  >
                    <v-text-field
                      v-model="postingData.expiryDate"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      :error-messages="errors"
                      :readonly="readonly"
                      :style="readonly ? 'pointer-events: none;' : ''"
                      v-on="on"
                    >
                      <template #label>
                        Posting Expiry Date<red-asterisk />
                      </template>
                    </v-text-field>
                  </validation-provider>
                </template>
                <v-date-picker
                  v-model="postingData.expiryDate"
                  no-title
                  :readonly="readonly"
                  :style="readonly ? 'pointer-events: none;' : ''"
                  :min="currentDate"
                  :max="postingData.endDate ? postingData.endDate : maxExpirationDate"
                >
                  <v-spacer />
                  <v-btn
                    text
                    color="primary"
                    @click="dateMenus.expiry = false"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-row>
            <v-col
              cols="5"
              sm="6"
              xs="6"
            >
              <v-menu
                v-model="dateMenus.start"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
                :close-on-content-click="false"
              >
                <template #activator="{ on, attrs }">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Expiry Date"
                    rules="required"
                  >
                    <v-text-field
                      v-model="postingData.startDate"
                      persistent-hint
                      :readonly="readonly"
                      :style="readonly ? 'pointer-events: none;' : ''"
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      :error-messages="errors"
                      v-on="on"
                    >
                      <template #label>
                        Mandate Start Date<red-asterisk />
                      </template>
                    </v-text-field>
                  </validation-provider>
                </template>
                <v-date-picker
                  v-model="postingData.startDate"
                  :max="postingData.endDate"
                  :min="currentDate"
                  no-title
                  :readonly="readonly"
                  :style="readonly ? 'pointer-events: none;' : ''"
                >
                  <v-spacer />
                  <v-btn
                    text
                    color="primary"
                    @click="dateMenus.start = false"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-row>
            <v-col
              cols="5"
              sm="6"
              xs="6"
            >
              <v-menu
                v-model="dateMenus.end"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
                :close-on-content-click="false"
              >
                <template #activator="{ on, attrs }">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Expiry Date"
                    rules="required"
                  >
                    <v-text-field
                      v-model="postingData.endDate"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      :readonly="readonly"
                      :style="readonly ? 'pointer-events: none;' : ''"
                      :error-messages="errors"
                      v-on="on"
                    >
                      <template #label>
                        Mandate End Date<red-asterisk />
                      </template>
                    </v-text-field>
                  </validation-provider>
                </template>
                <v-date-picker
                  v-model="postingData.endDate"
                  :min="postingData.startDate ? postingData.startDate : currentDate"
                  no-title
                  :readonly="readonly"
                  :style="readonly ? 'pointer-events: none;' : ''"
                >
                  <v-spacer />
                  <v-btn
                    text
                    color="primary"
                    @click="setEndDate()"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
      </v-row> -->
    </validation-observer>
  </v-container>
</template>

<script>
import { required } from 'vee-validate/dist/rules';
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from 'vee-validate';
import { mapState } from 'vuex';
import moment from 'moment';
import { VueEditor } from 'vue2-editor';
import { createPaginationObject } from '@/helpers/PaginationHelper.js';

setInteractionMode('eager');
extend('required', {
  ...required,
  message: '{_field_} can not be empty',
});
extend('select-required', {
  async validate(value) {
    return !!value.id;
  },
  message: '{_field_} can not be empty',
});
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VueEditor,
  },
  props: {
    posting: {
      type: Object,
      default: () => {},
    },
    charity: {
      type: Object,
      default: () => {},
    },
    positionId: {
      type: Number,
      default: 0,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    showHeader: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    postingData: null,
    charityPositionTypes: [],
    options: null,
    dateMenus: {
      expiry: false,
      start: false,
      end: false,
    },
    customToolbar: [
      ['bold', 'italic', 'underline'],
    ],
    validationTriggered: false,
  }),
  computed: {
    ...mapState({
      candidateSkills: (state) => state.candidate.candidateSkills,
    }),
    filters() {
      const returnFilters = [];
      if (this.charity && this.charity.id) {
        returnFilters.push({ name: 'charityId', values: [this.charity.id.toString()] });
      }

      const isDeactivated = 0;
      returnFilters.push({ name: 'isDisabled', values: [isDeactivated] });
      return returnFilters;
    },
    currentDate() {
      const current = moment().format('YYYY-MM-DD');
      return current;
    },
    maxExpirationDate() {
      const current = moment();
      return current.add(1, 'y').format('YYYY-MM-DD');
    },
    skillsSelected() {
      const skills = this.postingData.candidateSkill;
      return skills;
    },
  },
  watch: {
    search(value) {
      if (value) {
        this.getCharityPositions();
      }
    },
    options(value) {
      if (value) {
        this.getCharityPositions();
      }
    },
    filters(value) {
      if (value) {
        this.getCharityPositions();
      }
    },
    posting: {
      immediate: true,
      handler(value) {
        this.postingData = JSON.parse(JSON.stringify(value)) || {
          id: 0,
          charityPositionId: null,
          charityRepresentativeId: null,
          candidateSkill: [],
          charityId: 0,
          description: '',
          specialRequirements: '',
          charityPostingStatusId: 1,
          datePosted: '',
          expiryDate: '',
          startDate: '',
          endDate: '',
        };
        const skillIds = this.postingData.candidateSkill ? this.postingData.candidateSkill.map((x) => x.id) : [];
        this.postingData.candidateSkill = skillIds;
        this.postingData.expiryDate = this.formatDate(this.postingData.expiryDate);
        this.postingData.startDate = this.formatDate(this.postingData.startDate);
        this.postingData.endDate = this.formatDate(this.postingData.endDate);
      },
    },
    positionId: {
      immediate: true,
      handler(value) {
        if (value === 0) return;
        this.postingData.charityPositionId = value;
      },
    },
    charity: {
      handler() {
        this.getCharityPositions();
      },
    },
  },
  created() {
    if (!this.candidateSkills.length) this.$store.dispatch('candidate/getCandidateSkills');
    this.getCharityPositions();
  },
  methods: {
    async validate() {
      const res = await this.$refs.postingValObserver.validate();
      this.validationTriggered = true;
      return res;
    },

    async getCharityPositions() {
      const res = await this.$api.charityPositions.read(createPaginationObject(null, null, this.filters));
      this.charityPositionTypes = res.positions;

      // this.charityPositionTypes = await this.$api.charityPositions.read(createPaginationObject(this.options, this.search, this.filters));

      // let parameters = [];
      // parameters.push({name: 'isDisabled', value: isDeactivated});
      // this.charityPositionTypes = await this.$api.charityPositions.get(`${this.charity.id}`, parameters)
    },

    async submit() {
      const valid = await this.validate();
      if (!valid) return null;
      if (!this.charity) return null;
      if (this.postingData.charityId === 0) this.postingData.charityId = this.charity.id;
      let res;
      if (this.postingData.id !== 0) {
        res = await this.$api.charityPostings.update(this.postingData);
      } else {
        res = await this.$api.charityPostings.create(this.postingData);
      }
      return res;
    },
    // async closePosition(){
    //   if(!this.position.id) return false
    //   return await PositionApi.close(this.position.id)
    // },
    reset() {
      this.$refs.postingValObserver.reset();
      this.user = {
        id: 0,
        charityPositionId: 0,
        charityRepresentativeId: null,
        candidateSkill: [],
        charityId: null,
        description: '',
        specialRequirements: '',
        charityPostingStatusId: 1,
        datePosted: '',
        expiryDate: '',
        startDate: '',
        endDate: '',

      };
    },
    // async getBoardPositions(){
    //     if(!this.charity) return
    //     //const readObj = createPaginationObject({page: 0, itemsPerPage: -1, sortBy: []}, null, [{name: "charityId", values: [this.charity.id.toString()]}])
    //     let res = await this.$api.charityPositions.read(readObj)
    //     if(res) this.charityPositions = res.positions
    // },
    formatDate(date) {
      if (!date) return '';
      return this.$moment(date).format('YYYY-MM-DD');
    },
    updateOptions(options) {
      this.options = options;
    },
    setEndDate() {
      this.dateMenus.end = false;
      if (moment(this.postingData.expiryDate).isAfter(this.postingData.endDate)) {
        this.postingData.expiryDate = this.postingData.endDate;
        this.$bus.$emit('showWarning', 'Expiry Date is after Mandate End Date. Expiry Date has been adjusted.');
      }
    },
  },
};
</script>
<style>
.custom-bolded-selected-value div div div div.v-select__selections {
  font-size: larger;
  font-weight: bold;
}
.custom-text-field-bold-value div div div input {
  font-weight: bold;
}
#editor1 {
  height: 100px;
}
</style>
