<template>
  <v-row justify="center">
    <v-dialog
      v-model="candidateDialog"
      fullscreen
      hide-overlay
    >
      <v-card
        fill-height
      >
        <v-container fluid>
          <v-row>
            <v-col class="text-h4">
              {{ title }}
            </v-col>
            <v-col class="text-right">
              <v-btn
                icon
                @click="closeCandidateForm"
              >
                <v-icon large>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <candidate-template
            ref="candidateTemplate"
            :user="user"
            @candidate-success="notifySuccess"
          />
          <v-row>
            <v-col
              class="text-end"
            >
              <slot :item="user" />
              <v-btn
                class="mr-4"
                text
                @click="closeCandidateForm"
              >
                cancel
              </v-btn>
              <v-btn
                class="mr-4"
                type="submit"
                color="success"
                @click="submitCandidate"
              >
                submit
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import CandidateTemplate from '@/components/candidate/CandidateTemplate';

export default {
  components: {
    CandidateTemplate,
  },
  props:
    {
      user: {
        type: Object,
        default: () => {},
      },
      title: {
        type: String,
        default: '',
      },
    },

  data: () => ({
    candidateDialog: false,
  }),

  methods: {
    openCandidateForm() {
      this.candidateDialog = true;
    },
    closeCandidateForm() {
      this.$refs.candidateTemplate.resetForm();
      this.candidateDialog = false;
    },
    submitCandidate() {
      this.$refs.candidateTemplate.submit();
    },
    notifySuccess(msg) {
      this.closeCandidateForm();
      this.$emit('candidate-success', msg);
    },

  },
};
</script>
