<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      width="1400px"
      overlay-opacity="0.7"
    >
      <v-card
        fill-height
      >
        <v-container
          fluid
        >
          <v-row>
            <v-col
              class="text-h4 ml-3 mt-2"
              cols="10"
            >
              {{ title }}
            </v-col>
            <v-col class="text-right">
              <v-btn
                icon
                @click="closeForm"
              >
                <v-icon large>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row
            class="ml-1"
          >
            <v-col>
              <advanced-search-filter
                ref="advancedSearchFilter"
                :filters="filters"
                class="mt-0 ml-0 mb-10 pa-0"
              />
            </v-col>
          </v-row>
          <v-row
            class="mt-10 mb-0"
          >
            <v-col
              class="
            text-end"
            >
              <a
                class="mr-4"
                @click="clearForm()"
              >Clear All </a>
              <v-btn
                class="mr-4"
                type="submit"
                color="success"
                @click="search()"
              >
                search
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import AdvancedSearchFilter from '@/components/general/AdvancedSearchFilter';

export default {
  components: {
    AdvancedSearchFilter,
  },
  props:
    {
      title: {
        type: String,
        default: 'Advanced Search Options',
      },
      filters: {
        type: Array,
        default: () => [],
      },
    },

  data: () => ({
    showDialog: false,
  }),
  computed: {
    filterChips() {
      if (this.$refs.advancedSearchFilter) return this.$refs.advancedSearchFilter.selectedFlters;
      return '';
    },
  },

  methods: {
    openForm() {
      this.showDialog = true;
    },
    closeForm() {
      this.showDialog = false;
    },
    clearForm() {
      this.$refs.advancedSearchFilter.clearForm();
    },
    search() {
      const filter = this.$refs.advancedSearchFilter.selectedFlters;
      const { filterChips } = this.$refs.advancedSearchFilter;
      this.$emit('apply-filter', filter);
      this.$emit('apply-filter-string', filterChips);
      this.closeForm();
    },

  },
};
</script>
