var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0 mx-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"text-end"},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.openCharityRepForm()}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" mdi-plus ")]),_vm._v(" New Charity Representative ")],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.charityRepHeaders,"items":_vm.charityReps,"footer-props":_vm.charityRepPerPageProps,"items-per-page":_vm.charityRepPerPage,"loading":_vm.loading,"dense":""},scopedSlots:_vm._u([{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user ? item.user.userInformation.email.name : '-')+" ")]}},{key:"item.firstName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user ? item.user.userInformation.firstName : '-')+" ")]}},{key:"item.lastName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user ? item.user.userInformation.lastName : '-')+" ")]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.charityRepresentativeRole.map(function (r) { return " "+ r.name; }).toString())+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.isBoardMatchAdmin)?_c('a',{staticClass:"mr-2",on:{"click":function($event){return _vm.authAs(item)}}},[_vm._v(" LOGIN ")]):_vm._e(),(!_vm.isBoardMatchAdmin)?_c('a',{staticClass:"mr-2",on:{"click":function($event){return _vm.openCharityRepForm(item)}}},[_vm._v(" EDIT ")]):_vm._e(),_c('a',{staticClass:"mr-2",on:{"click":function($event){return _vm.softDelete(item.id)}}},[_vm._v(" DELETE ")])]}}],null,true)}),_c('charity-rep-template-dialog',{ref:"charityRepTemplateDialog",attrs:{"charity-rep":_vm.charityRep,"title":_vm.charityRepFormTitle,"title-icon":_vm.charityRepFormTitleIcon,"charity-id":_vm.charityId},on:{"notify-success":_vm.notifySuccess}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }