<template>
  <charity-rep-table-template
    ref="charityRepTableTemplate"
    :charity-id="charityId"
  />
</template>

<script>
import CharityRepTableTemplate from '@/components/charity/charityRep/CharityRepTableTemplate';

export default {
  components: {
    CharityRepTableTemplate,
  },
  data: () => ({
    charityId: 0,
  }),
  created() {
    this.init();
  },
  methods: {
    init() {
      this.charityId = this.$store.state.charity.charity.id;
    },
  },
};
</script>
