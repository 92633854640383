<template>
  <v-row justify="center">
    <v-dialog
      v-model="profileDialog"
      fullscreen
      hide-overlay
    >
      <v-card
        fill-height
      >
        <v-container fluid>
          <v-row>
            <v-col class="text-h4">
              Program Overview
            </v-col>
            <v-col class="text-right">
              <v-btn
                icon
                @click="toggleDialog"
              >
                <v-icon large>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <p>
                <span class="font-weight-bold">BoardMatch Fundamentals</span>  introduces registered charities and not for profit organizations to talented, interested and informed individuals from diverse backgrounds, willing to share their enthusiasm and expertise by serving as volunteer members of the boards of charitable organizations. BoardMatch Fundamentals is an innovative program of <a
                  target="_blank"
                  href="https://altruvest.org/"
                >Altruvest Charitable Services</a> , a registered charity.
              </p>
              <p>BoardMatch Fundamentals began in the spring of 2000 and to current date has facilitated in excess of 2,400 board matches on over 650 charitable/non-for-profit organizations.</p>
              <p>BoardMatch Fundamentals engages with companies, professional associations and community groups to recruit individuals interested in volunteering their expertise.</p>
              <p>Our participating organizations range in size and scope in terms of budget, mission and geographic outreach. They include organizations such as; Big Brothers and Sisters, Boys & Girls Clubs Canadian Cancer Society, Canadian Red Cross, Ernestine’s Women’s Shelter, Girl Guides of Canada, Habitat for Humanity, literacy councils, arts councils, environmental groups , local United Ways, and Volunteer Centres.</p>
              <p>What makes the BoardMatch Fundamentals program unique is the mandatory governance orientation that board candidates and charitable organizations go through, either online or at a live session as well as the online research that each party can do of the other, prior to an interview. User evaluations have shown greater retention rates for BoardMatch candidates.</p>
              <p>This service is presently available in Calgary, Fredericton, Greater Toronto Area, Greater Vancouver, and Thunder Bay. Altruvest is pleased to be collaborating with Volunteer Calgary and Volunteer Thunder Bay to bring BoardMatch Fundamentals to their respective cities. Over the next 3 to 5 years, Altruvest intends to further expand the program across Canada.  </p>
              <p>In the Greater Toronto Area, participating organizations include registered charities. In Calgary, Fredericton, Greater Vancouver, and Thunder Bay both registered charities and not-for-profit organizations with charitable purposes are included.  </p>
              <p>
                We are pleased to recognize our national funding partners whose support has been instrumental in the creation and expansion of this program:
                <ul>
                  <li>
                    The J.W. McConnell Family Foundation
                  </li>
                  <li>
                    Maritz
                  </li>
                </ul>
              </p>
              <p>For a complete list of Altruvest supporters please click on ” <a href="">Donors & Sponsors</a> " on the menu bar. Donations to Altruvest Charitable Services can be made by contacting <a href="mailto:information@altruvest.org">information@altruvest.org</a> or clicking on the following link:</p>
              <h4>THE CHALLENGE TO CHARITIES AND NOT FOR PROFITS</h4>
              <p>In the current climate of cutbacks in government funding, many organizations are looking for ways to operate more efficiently, increase their donor base and forge links with the community. It is vital that organizations not only recruit board members who have the dedication and skills to help them meet these challenges but that they recruit board members who reflect the diversity of the community they serve. Boards must employ innovative approaches to recruit candidates with specific skills in such areas as finance, marketing, information technology, engineering, public relations, fundraising, law, sales, operations and human resources and those candidates often under-represented from the community such as youth and various ethnic groups.</p>
              <h4>THE CHALLENGE TO POTENTIAL VOLUNTEERS</h4>
              <p>Many people are willing to volunteer their time and expertise at a board level but no one has ever asked them or informed them about the opportunities available! Even when people do want to volunteer, they do not know how to find agencies that are looking, they are not sure what reception they'll get, and the commitment they will be asked to make. Often, potential volunteers are unaware of the size of the voluntary sector and the variety of programs and services provided by agencies. Volunteers need a source of knowledge about the charitable community and the opportunities for volunteering.</p>
              <h4>THE CHALLENGE TO CORPORATE PHILANTHROPY</h4>
              <p>Corporations are receiving more requests for donations and support from the voluntary sector than ever before. With limited donation budgets, many are looking for strategic ways beyond financial donations to demonstrate their commitment to strengthening communities. One avenue gaining popularity is the creation of employer supported volunteerism programs. For example, corporations can give employees time off to volunteer, make donations to organizations where employees have demonstrated a volunteer commitment, and/or help employees learn about volunteer opportunities. Employer supported volunteer programs benefit employees by enabling them to become involved in their community in a meaningful way with the support of their employers. The corporations sponsoring these programs benefit by enhancing their reputation as good corporate citizens, by aligning themselves with their employees' values, and by gaining more committed, skilled and satisfied employees.</p>
              <p style="color: red;">
                For more information about attending an orientation or to arrange a Live Orientation Session, <a href="">contact</a> the local BoardMatch Fundamentals office.
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

export default {

  data: () => ({
    profileDialog: false,
  }),

  methods: {
    toggleDialog() {
      this.profileDialog = !this.profileDialog;
    },

  },
};
</script>
