var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-2 pb-2 ma-2 mt-12",staticStyle:{"background-color":"white","border-radius":"8px"},attrs:{"fluid":""}},[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"pa-6 pb-0",attrs:{"cols":"12"}},[_c('h1',[_vm._v("Charities")])]),_c('v-col',{staticClass:"text-end pa-6 pt-0 pb-0",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":_vm.openCharityCreateForm}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" mdi-plus ")]),_vm._v(" New Charity ")],1)],1)],1),_c('v-row',[_c('v-col',[_c('charity-table',{ref:"charityTable",attrs:{"filters":_vm.filters},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('font-awesome-icon',_vm._g(_vm._b({staticClass:"icon-hover mr-3",attrs:{"icon":['fabs','user-pen']},on:{"click":function($event){return _vm.loginAsCharityRep(item)}}},'font-awesome-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("Login As (To Edit Charity Details)")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('font-awesome-icon',_vm._g(_vm._b({staticClass:"icon-hover mr-3",staticStyle:{"color":"red"},attrs:{"icon":['fabs', 'trash-can']},on:{"click":function($event){return _vm.softDelete(item.id)}}},'font-awesome-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("Delete Charity")])])]}}])})],1)],1),_c('charity-template-dialog',{ref:"charityTemplateDialog",attrs:{"title":_vm.charityFormTitle},on:{"charity-success":_vm.notifySuccess}}),_c('charity-edit-template-dialog',{ref:"charityEditTemplateDialog",attrs:{"title":_vm.charityFormTitle,"charity":_vm.charity},on:{"charity-success":_vm.notifySuccess}}),_c('charity-rep-table-template-dialog',{ref:"charityRepTableTemplateDialog",attrs:{"title":_vm.charityRepsTableFormTitle,"is-board-match-admin":true}}),_c('charity-position-template-dialog',{ref:"charityPositionTemplateDialog",attrs:{"title":_vm.charityPositionDialogTitle,"charity":_vm.charity}}),_c('charity-posting-template-dialog',{ref:"charityPostingTemplateDialog",attrs:{"title":_vm.charityPostingDialogTitle,"charity":_vm.charity}}),_c('user-authenticate-as-dialog',{ref:"authenticateAsDialog",attrs:{"is-charity":true,"charity-id":_vm.charityId}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }