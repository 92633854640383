<template>
  <v-row
    justify="center"
  >
    <v-dialog
      v-model="showDialog"
      width="800px"
      overlay-opacity="0.7"
    >
      <v-card
        fill-height
        class="pt-2"
      >
        <v-container fluid>
          <v-row class="pl-2 pr-2">
            <v-col class="text-h4 font-weight-bold">
              {{ title }}
            </v-col>
            <v-col class="text-right">
              <v-btn
                icon
                @click="closeForm"
              >
                <v-icon large>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <candidate-registration-template
            ref="candidateRegistrationTemplate"
            @registration-success="closeForm"
            @loading="(loading) => setLoading(loading)"
          />
          <v-row class="pl-2 pr-2">
            <v-col cols="7">
              <v-checkbox
                v-model="isAgreed"
                class="large"
              >
                <template #label>
                  <span>I agree to the <a
                    @click="openReleaseForm()"
                  >release form</a> terms and conditions.<red-asterisk /></span>
                </template>
              </v-checkbox>
            </v-col>
            <v-col
              cols="5"
              class="text-end"
            >
              <slot :item="user" />
              <v-btn
                class="mt-4 mr-4"
                text
                @click="closeForm"
              >
                cancel
              </v-btn>
              <v-btn
                class="mt-4 mr-4"
                type="submit"
                color="success"
                :disabled="loading"
                @click="submit"
              >
                <v-progress-circular
                  v-if="loading"
                  indeterminate
                  size="20"
                  color="grey"
                  class="mr-2"
                />register{{ loading ? 'ing' : '' }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <general-dialog
      ref="releaseFormDialog"
      :title="'PARTICIPANT’S AGREEMENT, CONSENT AND RELEASE'"
      submit-button-label="Ok"
      dialog-width="650px"
      :hide-submit="true"
      :cancel-button-label="'Close'"
    >
      <v-row class="pl-2 pr-2">
        <v-col>
          <p>TO:    Altruvest Charitable Services ("<span class="font-weight-bold">Altruvest</span>")</p>
          <p>AND TO:   The Directors and Officers of Altruvest</p>
          <p>
            In consideration of my participation in BoardMatch® Fundamentals and/or BoardMatch Leaders (collectively, the "<span class="font-weight-bold">Program</span>") and for other good and valuable consideration, the receipt and sufficiency whereof is hereby acknowledged, I hereby:
          </p>
          <p class="ml-4">
            <ol>
              <li class="mb-5">
                Agree to participate in the Program and abide by all of the terms and conditions of the Program;
              </li>
              <li class="mb-5">
                Agree that since Altruvest neither pre-screens nor makes any representation or warranty respecting the charitable organizations participating in the Program, I am solely responsible for making my own investigation and review of the charitable organizations that I wish to volunteer with including, without limitation, the adequacy of any directors and officers liability insurance maintained by the charitable organizations;
              </li>
              <li class="mb-5">
                Consent to the use of personally identifying information (i.e. personal information by which the undersigned can be identified) which I provide to Altruvest through the web site located at <a href="https://altruvest.org/">www.altruvest.org</a> (including <a href="http://www.boardmatch.org/">www.boardmatch.org</a> ) (the "<span class="font-weight-bold">Site</span>") or otherwise, including all personal information which I provide to Altruvest in connection with the Program, agree to the terms of Altruvest’s privacy policy, as amended (the "<span class="font-weight-bold">Privacy Policy</span>") and Altruvest’s web site Terms of Service, as amended (the “<span class="font-weight-bold">Terms of Service</span>”), copies of which are posted on the Site, and consent to the collection, use and/or disclosure of my personal information for the purposes outlined in the Privacy Policy; and
              </li>
              <li>
                Release and discharge each of you and your respective successors and assigns from and against any and all claims or loss which I may have or could have against you and your respective successors and assigns arising out of or related to my participation in the Program, including without limitation, arising out of or related to the collection, use and/or disclosure of my personal information for the purposes outlined in the Privacy Policy, or use of the Site for the purposes outlined in the Terms of Service and my becoming an officer, director, employee or volunteer of any charitable organization which participates in the Program.
              </li>
            </ol>
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          style="background-color: #ffffe6;"
          class="ml-3 mr-3"
        >
          <span>By clicking “I Agree”, I acknowledge that: I have read the terms stated above; I intend to form a legally binding agreement between yourself and Altruvest, and any related Altruvest program co-operator listed above (i.e. Volunteer Calgary or Volunteer Thunder Bay), if and as applicable. I will abide by the terms stated above; and that the use of our website and the Program are subject to Altruvest’s <a
            href="https://altruvest.org/BoardMatch/Content/Downloads/Altruvest%20Terms%20of%20Service.pdf"
            target="_blank"
          >Terms of Service</a> and <a
            href="https://altruvest.org/BoardMatch/Content/Downloads/Altruvest%20Privacy%20Policy.pdf"
            target="_blank"
          >Privacy Policy</a>.</span>
        </v-col>
      </v-row>
    </general-dialog>
  </v-row>
</template>

<script>
import CandidateRegistrationTemplate from '@/components/candidate/CandidateRegistrationTemplate';
import GeneralDialog from '@/components/general/GeneralDialog';

export default {
  components: {
    CandidateRegistrationTemplate,
    GeneralDialog,
  },
  props:
    {
      user: {
        type: Object,
        default: () => {},
      },
      title: {
        type: String,
        default: 'Candidate Registration',
      },
    },

  data: () => ({
    showDialog: false,
    loading: false,
    isAgreed: false,
  }),

  methods: {
    openForm() {
      this.showDialog = true;
    },
    closeForm() {
      this.$refs.candidateRegistrationTemplate.resetForm();
      this.showDialog = false;
    },
    async submit() {
      this.$refs.candidateRegistrationTemplate.submit(this.isAgreed);
    },
    notifySuccess(msg) {
      this.closeForm();
      this.$emit('candidate-success', msg);
    },
    setLoading(loading) {
      this.loading = loading;
    },
    openReleaseForm() {
      this.$refs.releaseFormDialog.openForm();
    },
  },
};
</script>
