<template>
  <v-row>
    <v-col
      v-if="title"
      cols="12"
    >
      {{ title }}
    </v-col>
    <v-col>
      <v-data-table
        :headers="headers"
        :items="courses"
        :footer-props="footerProps"
        :items-per-page="itemsPerPage"
        :loading="loading"
        :server-items-length="total"
        dense
        :option="options"
        style="width: 100%"
        @pagination="updatePagination"
        @update:options="updateOptions"
      >
        <template #[`item.courseName`]="{ item }">
          <text-highlight :queries="search">
            {{ item.courseName }}
          </text-highlight>
        </template>
        <template #[`item.courseId`]="{ item }">
          <text-highlight :queries="search">
            {{ item.courseId }}
          </text-highlight>
        </template>
        <template #[`item.url`]="{ item }">
          <text-highlight :queries="search">
            {{ kebabCaseFormatter(item.courseName) }}
          </text-highlight>
        </template>
        <template #[`item.thinkificCourseType`]="{ item }">
          {{ item.thinkificCourseType.name }}
        </template>
        <template #[`item.actions`]="{ item }">
          <slot :item="item" />
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import TextHighlight from 'vue-text-highlight';
import moment from 'moment';
import { createPaginationObject } from '@/helpers/PaginationHelper.js';
import { kebabCaseFormatter } from '@/helpers/FormatHelper.js';

export default {
  components: {
    TextHighlight,
  },
  props: {
    search: {
      default: '',
      type: String,
    },
    title: {
      default: '',
      type: String,
    },
    filters: {
      default: () => [],
      type: Array,
    },
  },
  data: () => ({
    courses: [],
    footerProps: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    itemsPerPage: 20,
    loading: false,
    pagination: null,
    total: 0,
    options: null,
    requiredCourseIds: [1679471], // to-do: make this dynamic.
  }),
  computed: {
    headers() {
      const headers = [];
      headers.push({ text: 'Course Name', value: 'courseName' });
      headers.push({ text: 'Course ID', value: 'courseId' });
      headers.push({ text: 'URL', value: 'url' });
      headers.push({ text: 'Course Type', value: 'thinkificCourseType' });
      headers.push({
        text: 'Actions',
        value: 'actions',
        align: 'right',
        sortable: false,
        width: '15%',
      });

      return headers;
    },
  },
  watch: {
    search(value) {
      if (value) {
        this.getCourses();
      }
    },
    options(value) {
      if (value) {
        this.getCourses();
      }
    },
    filters(value) {
      if (value) {
        this.getCourses();
      }
    },
  },

  methods: {
    async getCourses() {
      const result = await this.$api.thinkificCourses.read(
        createPaginationObject(null, null, this.filters),
      );

      if (!result) {
        this.courses = [];
        this.total = 0;
        this.loading = false;
        return;
      }
      this.courses = result.courses;
      // this.total = result.total;
      this.total = result.total;
      this.loading = false;
    },
    updatePagination(pagination) {
      this.pagination = pagination;
    },
    updateOptions(options) {
      this.options = options;
    },
    refresh() {
      this.getCourses();
    },
    moment() {
      return moment();
    },
    setStatus(item) {
      if (item) {
        let status = {};
        if (item.percentage_completed === 1) {
          (status = { name: 'Completed', color: 'success' });
        } else if (item.percentage_completed !== 0
            && item.percentage_completed !== 1
            && item.started_at != null) {
          (status = { name: 'In Progress', color: 'primary' });
        } else {
          (status = { name: 'Not Started', color: 'error' });
        }
        return status;
      }
      return null;
    },
    kebabCaseFormatter(input) {
      return kebabCaseFormatter(input);
    },
    async validate(showError = true) {
      await this.getCourses();
      const incompleteEnrollmentIndex = this.courses.findIndex((x) => this.requiredCourseIds.findIndex((y) => y === x.course_id) > -1 && x.percentage_completed < 1);
      if (incompleteEnrollmentIndex > -1) return false;
      if (showError) this.$bus.$emit('showSuccess', 'Successfully completed all required training.');
      return true;
    },
  },
};
</script>

<style></style>
