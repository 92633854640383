<template>
  <v-row>
    <v-col
      v-if="title"
      class="text-h6 pa-6"
      cols="12"
    >
      {{ title }}
    </v-col>
    <v-col>
      <v-data-table
        :headers="headers"
        :items="candidates"
        :footer-props="footerProps"
        :items-per-page="itemsPerPage"
        :loading="loading"
        :server-items-length="total"
        dense
        :option="options"
        style="width:100%"
        @pagination="updatePagination"
        @update:options="updateOptions"
      >
        <template #[`item.email`]="{ item }">
          <text-highlight
            :queries="search"
            style="font-weight: bold;"
          >
            {{
              item.user &&
                item.user.userName
                ? item.user.userName
                : "-"
            }}
          </text-highlight>
        </template>
        <template #[`item.firstName`]="{ item }">
          <text-highlight :queries="search">
            {{ item.user ? item.user.userInformation.firstName : "-" }}
          </text-highlight>
        </template>
        <template #[`item.lastName`]="{ item }">
          <text-highlight :queries="search">
            {{ item.user ? item.user.userInformation.lastName : "-" }}
          </text-highlight>
        </template>
        <template #[`item.skills`]="{ item }">
          <template v-for="(skill, index) in item.skills">
            <text-highlight
              :key="index"
              :queries="search"
            >
              {{ skill.name
              }}{{
                item.skills.length == index + 1 ? "" : ","
              }}
            </text-highlight>
          </template>
        </template>
        <template #[`item.causes`]="{ item }">
          <template v-for="(focusArea, index) in item.focusAreas">
            <text-highlight
              :key="index"
              :queries="search"
            >
              {{ focusArea.name
              }}{{
                item.focusAreas.length == index + 1 ? "" : ","
              }}
            </text-highlight>
          </template>
        </template>
        <template #[`item.actions`]="{ item }">
          <slot :item="item" />
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import TextHighlight from 'vue-text-highlight';
import { createPaginationObject } from '@/helpers/PaginationHelper.js';

export default {
  components: {
    TextHighlight,
  },
  props: {
    search: {
      default: '',
      type: String,
    },
    title: {
      default: '',
      type: String,
    },
    filters: {
      default: null,
      type: Array,
    },
  },
  data: () => ({
    candidates: [],
    footerProps: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    itemsPerPage: 20,
    loading: false,
    // candidateDialog: false,
    user: null,
    selectedTab: 0,
    candidateFormTitle: 'Create Candidate',
    pagination: null,
    total: 0,
    options: null,
  }),
  computed: {
    headers() {
      const headers = [];
      headers.push({ text: 'Email/Login', value: 'email' });
      headers.push({ text: 'First Name', value: 'firstName' });
      headers.push({ text: 'Last Name', value: 'lastName', width: '10%' });
      headers.push({
        text: 'Skills', value: 'skills', sortable: false, width: '30%',
      });
      headers.push({ text: 'Causes', value: 'causes', sortable: false });
      headers.push({
        text: 'Actions', value: 'actions', align: 'right', sortable: false, width: '10%',
      });

      return headers;
    },
  },
  watch: {
    search(value) {
      if (value) {
        this.getCandidates();
      }
    },
    options(value) {
      if (value) {
        this.getCandidates();
      }
    },
    filters(value) {
      if (value) {
        this.getCandidates();
      }
    },
  },

  methods: {
    async getCandidates() {
      this.loading = true;
      const result = await this.$api.candidate.read(createPaginationObject(this.options, this.search, this.filters));

      if (!result) {
        this.candidates = [];
        this.total = 0;
        this.loading = false;
        return;
      }
      // this.pagination = pagination;
      this.candidates = result.candidates;
      this.total = result.total;
      this.loading = false;
    },
    updatePagination(pagination) {
      this.pagination = pagination;
    },
    updateOptions(options) {
      this.options = options;
    },
    refresh() {
      this.getCandidates();
    },
  },
};
</script>

<style></style>
