<template>
  <v-container
    fluid
    class="px-0 mx-0"
  >
    <v-row>
      <v-col class="text-end">
        <v-btn
          color="primary"
          outlined
          @click="openCharityRepForm()"
        >
          <v-icon
            small
            class="mr-1"
          >
            mdi-plus
          </v-icon>
          New Charity Representative
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :headers="charityRepHeaders"
      :items="charityReps"
      :footer-props="charityRepPerPageProps"
      :items-per-page="charityRepPerPage"
      :loading="loading"
      dense
    >
      <template #[`item.email`]="{ item }">
        {{ item.user ? item.user.userInformation.email.name : '-' }}
      </template>
      <template #[`item.firstName`]="{ item }">
        {{ item.user ? item.user.userInformation.firstName : '-' }}
      </template>
      <template #[`item.lastName`]="{ item }">
        {{ item.user ? item.user.userInformation.lastName : '-' }}
      </template>
      <template #[`item.role`]="{ item }">
        {{ item.charityRepresentativeRole.map(r =>" "+ r.name).toString() }}
      </template>
      <template #[`item.actions`]="{ item }">
        <a
          v-if="isBoardMatchAdmin"
          class="mr-2"
          @click="authAs(item)"
        > LOGIN </a>
        <a
          v-if="!isBoardMatchAdmin"
          class="mr-2"
          @click="openCharityRepForm(item)"
        > EDIT </a>
        <a
          class="mr-2"
          @click="softDelete(item.id)"
        > DELETE </a>
      </template>
    </v-data-table>
    <charity-rep-template-dialog
      ref="charityRepTemplateDialog"
      :charity-rep="charityRep"
      :title="charityRepFormTitle"
      :title-icon="charityRepFormTitleIcon"
      :charity-id="charityId"
      @notify-success="notifySuccess"
    />
  </v-container>
</template>

<script>
import CharityRepTemplateDialog from '@/components/charity/charityRep/CharityRepTemplateDialog';
// import UserAuthenticateAsDialog from '@/components/user/UserAuthenticateAsDialog';

export default {
  components: {
    CharityRepTemplateDialog,
    // UserAuthenticateAsDialog,
  },
  props:
  {
    charityId: {
      type: Number,
      default: 0,
    },
    isBoardMatchAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    charityRepPerPageProps: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    charityRepPerPage: 20,
    loading: true,
    charityRepFormTitle: '',
    charityRepFormTitleIcon: [],
    charityReps: [],
    charityRepsTableDialog: false,
    charityRep: null,

  }),
  computed: {
    charityRepHeaders() {
      const headers = [];

      headers.push({ text: 'Email/Login', value: 'email' });
      headers.push({ text: 'First Name', value: 'firstName' });
      headers.push({ text: 'Last Name', value: 'lastName' });
      headers.push({ text: 'Roles', value: 'role' });
      headers.push({ text: 'Actions', value: 'actions', align: 'right' });

      return headers;
    },
  },
  watch: {
    charityId: {
      immediate: true,
      handler(value) {
        if (!value) return;
        this.getCharityReps();
      },
    },
  },
  methods: {
    async getCharityReps() {
      this.loading = true;
      this.charityReps = await this.$api.charityRep.getCharityRepsByCharityId(this.charityId);
      this.loading = false;
    },
    openCharityRepForm(representative = null) {
      this.charityRep = representative;
      if (!representative) {
        this.charityRepFormTitle = 'New Charity Representative';
        this.charityRepFormTitleIcon = ['fabs', 'circle-plus'];
      } else {
        this.charityRepFormTitle = 'Update Charity Representative';
        this.charityRepFormTitleIcon = ['fabs', 'pencil'];
      }
      this.$refs.charityRepTemplateDialog.openCharityRepForm();
    },
    notifySuccess(message) {
      this.$bus.$emit('showSuccess', message);
      this.$store.dispatch('user/setUserInfo');
      this.getCharityReps(this.charityId);
    },
    async softDelete(id) {
      if (!confirm('Are you sure you would like to delete this representative?')) return;
      const res = await this.$api.charityRep.softDelete(id);
      if (res) this.notifySuccess('Representative deleted');
      else this.$bus.$emit('showError');
    },

  },
};
</script>
