var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0 mx-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"text-end"},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":_vm.openrCreateForm}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" mdi-plus ")]),_vm._v(" New User ")],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.usersHeaders,"items":_vm.users,"footer-props":_vm.usersPerPageProps,"items-per-page":_vm.usersPerPage,"loading":_vm.loading,"dense":""},scopedSlots:_vm._u([{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.userInformation != null ? item.userInformation.email != null ? item.userInformation.email.name : '-' : '-')+" ")]}},{key:"item.firstName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.userInformation != null ? item.userInformation.firstName : '-')+" ")]}},{key:"item.lastName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.userInformation != null ? item.userInformation.lastName : '-')+" ")]}},{key:"item.roles",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.boardmatchRepresentative ? item.boardmatchRepresentative.boardmatchRepresentativeRole.map(function (r) { return " "+ r.name; }).toString() : "")+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"mr-2",on:{"click":function($event){return _vm.openUpdateForm(item)}}},[_vm._v(" EDIT ")]),_c('a',{staticClass:"mr-2",on:{"click":function($event){return _vm.softDelete(item.id)}}},[_vm._v(" DELETE ")])]}}],null,true)})],1)],1),_c('user-admin-template-dialog',{ref:"userAdminTemplateDialog",attrs:{"user":_vm.user,"title":_vm.userFormTitle,"title-icon":_vm.BMRepFormTitleIcon},on:{"notify-success":_vm.notifySuccess}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }