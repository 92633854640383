import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
// eslint-disable-next-line import/no-extraneous-dependencies
import '@mdi/font/css/materialdesignicons.css';
// Ensure you are using css-loader
Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
  theme: {
    themes: {
      light: {
        primary: '#0073FF',
        secondary: '#122145',
        tertiary: '#2129BD',
        accent: '#8c9eff',
        // error: '#b71c1c',
        success: '#0AC285',
        neutral: '#FDF0E5',
        coldNeutral: 'D4E5FB',
        information: '#FAB226',
      },
    },
  },
});
