var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[(_vm.title)?_c('v-col',{staticClass:"text-h6",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_c('v-col',[_c('v-data-table',{staticStyle:{"width":"100%"},attrs:{"headers":_vm.headers,"items":_vm.postings,"footer-props":_vm.footerProps,"items-per-page":_vm.itemsPerPage,"loading":_vm.loading,"server-items-length":_vm.total,"dense":"","option":_vm.options},on:{"pagination":_vm.updatePagination,"update:options":_vm.updateOptions},scopedSlots:_vm._u([{key:"item.charity",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.charityPosting.id)+" ")])]}},{key:"item.boardRole",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{staticClass:"font-weight-bold",attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.charityPosition.name)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.chipColor(item),"small":""}},[_vm._v(" "+_vm._s(_vm.postingStatus(item))+" ")])]}},{key:"item.datePosted",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(_vm.formatDate(item.datePosted ))+" ")])]}},{key:"item.expiryDate",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(_vm.formatDate(item.expiryDate ))+" ")])]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(_vm.formatDate(item.startDate ))+" ")])]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(_vm.formatDate(item.endDate ))+" ")])]}},{key:"item.totalApplicants",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"queries":_vm.search},on:{"click":function($event){return _vm.navigateToApplication(item.id)}}},[_vm._v(" "+_vm._s(item.applicationCount)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_vm._t("default",null,{"item":item})]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }