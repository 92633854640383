<template>
  <v-container
    fluid
    class="px-0 mx-0"
  >
    <v-row>
      <v-col class="text-end">
        <v-btn
          color="primary"
          outlined
          @click="openrCreateForm"
        >
          <v-icon
            small
            class="mr-1"
          >
            mdi-plus
          </v-icon>
          New User
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="usersHeaders"
          :items="users"
          :footer-props="usersPerPageProps"
          :items-per-page="usersPerPage"
          :loading="loading"
          dense
        >
          <template #[`item.email`]="{ item }">
            {{ item.userInformation != null ? item.userInformation.email != null ? item.userInformation.email.name : '-' : '-' }}
          </template>
          <template #[`item.firstName`]="{ item }">
            {{ item.userInformation != null ? item.userInformation.firstName : '-' }}
          </template>
          <template #[`item.lastName`]="{ item }">
            {{ item.userInformation != null ? item.userInformation.lastName : '-' }}
          </template>
          <template

            #[`item.roles`]="{ item }"
          >
            {{ item.boardmatchRepresentative ? item.boardmatchRepresentative.boardmatchRepresentativeRole.map(r =>" "+ r.name).toString() : "" }}
          </template>
          <template #[`item.actions`]="{ item }">
            <a
              class="mr-2"
              @click="openUpdateForm(item)"
            > EDIT </a>
            <a
              class="mr-2"
              @click="softDelete(item.id)"
            > DELETE </a>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <user-admin-template-dialog
      ref="userAdminTemplateDialog"
      :user="user"
      :title="userFormTitle"
      :title-icon="BMRepFormTitleIcon"
      @notify-success="notifySuccess"
    />
  </v-container>
</template>

<script>
import UserAdminTemplateDialog from '@/components/user/UserAdminTemplateDialog';

export default {
  components: {
    UserAdminTemplateDialog,
  },
  data: () => ({
    users: [],
    usersPerPageProps: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    usersPerPage: 20,
    loading: true,
    userFormTitle: 'Create User',
    user: null,
    BMRepFormTitleIcon: [],

  }),
  computed: {
    usersHeaders() {
      const headers = [];

      headers.push({ text: 'Email', value: 'email' });
      headers.push({ text: 'First Name', value: 'firstName' });
      headers.push({ text: 'Last Name', value: 'lastName' });
      headers.push({ text: 'Roles', value: 'roles' });
      headers.push({ text: 'Actions', value: 'actions', align: 'right' });
      return headers;
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getUsers();
    },
    async getUsers() {
      this.loading = true;
      this.users = await this.$api.user.get('boardmatch');
      this.loading = false;
    },
    openrCreateForm() {
      this.userFormTitle = 'New BoardMatch Admin';
      this.BMRepFormTitleIcon = ['fabs', 'circle-plus'];
      this.user = null;
      this.$refs.userAdminTemplateDialog.openUserForm();
    },
    openUpdateForm(user) {
      this.userFormTitle = 'Update User';
      this.user = JSON.parse(JSON.stringify(user));
      this.$refs.userAdminTemplateDialog.openUserForm();
    },
    notifySuccess(message) {
      this.$bus.$emit('showSuccess', message);
      this.getUsers();
    },
    async softDelete(id) {
      if (!confirm('Are you sure you would like to delete this user?')) return;
      console.log(id);
      const response = await this.$api.boardmatchRep.softDelete(id);
      if (response === true) {
        this.notifySuccess('BoardMatch Admin Deleted');
      } else {
        this.$bus.$emit('showError');
      }
    },
  },
};
</script>
