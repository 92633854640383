var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"padding-inline":"50px","padding-bottom":"0px"},attrs:{"fluid":""}},[_c('validation-observer',{ref:"postingValObserver"},[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('span',{staticStyle:{"font-size":"large","font-weight":"bolder"}},[_vm._v("Position")]),_c('red-asterisk'),_c('validation-provider',{attrs:{"name":"Board Position","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"custom-bolded-selected-value",style:(_vm.readonly ? 'pointer-events: none;' : ''),attrs:{"items":_vm.charityPositionTypes,"error-messages":errors,"label":"Board Position","data-vv-name":"select","item-text":function (item) { return ((item.name) + " " + (item.isNotBoard ? '(Not Board member)': '')); },"item-value":"id","readonly":_vm.readonly,"dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Position"),_c('red-asterisk')]},proxy:true}],null,true),model:{value:(_vm.postingData.charityPositionId),callback:function ($$v) {_vm.$set(_vm.postingData, "charityPositionId", $$v)},expression:"postingData.charityPositionId"}})]}}])})],1),_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{attrs:{"cols":"3"}},[_c('span',{staticStyle:{"font-size":"large","font-weight":"bolder"}},[_vm._v("Mandate Period")]),_c('v-menu',{attrs:{"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"Expiry Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"custom-text-field-bold-value",style:(_vm.readonly ? 'pointer-events: none;' : ''),attrs:{"persistent-hint":"","readonly":_vm.readonly,"prepend-icon":"mdi-calendar","error-messages":errors,"dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Mandate Start Date"),_c('red-asterisk')]},proxy:true}],null,true),model:{value:(_vm.postingData.startDate),callback:function ($$v) {_vm.$set(_vm.postingData, "startDate", $$v)},expression:"postingData.startDate"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}]),model:{value:(_vm.dateMenus.start),callback:function ($$v) {_vm.$set(_vm.dateMenus, "start", $$v)},expression:"dateMenus.start"}},[_c('v-date-picker',{style:(_vm.readonly ? 'pointer-events: none;' : ''),attrs:{"max":_vm.postingData.endDate,"min":_vm.currentDate,"no-title":"","readonly":_vm.readonly},model:{value:(_vm.postingData.startDate),callback:function ($$v) {_vm.$set(_vm.postingData, "startDate", $$v)},expression:"postingData.startDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dateMenus.start = false}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('br'),_c('v-menu',{attrs:{"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"Expiry Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"custom-text-field-bold-value",style:(_vm.readonly ? 'pointer-events: none;' : ''),attrs:{"persistent-hint":"","prepend-icon":"mdi-calendar","readonly":_vm.readonly,"error-messages":errors,"dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Mandate End Date"),_c('red-asterisk')]},proxy:true}],null,true),model:{value:(_vm.postingData.endDate),callback:function ($$v) {_vm.$set(_vm.postingData, "endDate", $$v)},expression:"postingData.endDate"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}]),model:{value:(_vm.dateMenus.end),callback:function ($$v) {_vm.$set(_vm.dateMenus, "end", $$v)},expression:"dateMenus.end"}},[_c('v-date-picker',{style:(_vm.readonly ? 'pointer-events: none;' : ''),attrs:{"min":_vm.postingData.startDate ? _vm.postingData.startDate : _vm.currentDate,"no-title":"","readonly":_vm.readonly},model:{value:(_vm.postingData.endDate),callback:function ($$v) {_vm.$set(_vm.postingData, "endDate", $$v)},expression:"postingData.endDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.setEndDate()}}},[_vm._v(" OK ")])],1)],1)],1)],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticStyle:{"padding-top":"0px"},attrs:{"cols":"12"}},[_c('span',{staticStyle:{"font-size":"large","font-weight":"bold"}},[_vm._v("Position Description"),_c('red-asterisk')],1),(!_vm.postingData.description && _vm.validationTriggered)?_c('span',{staticClass:"red--text ml-2"},[_vm._v("Must be filled")]):_vm._e()]),_c('v-col',{staticStyle:{"padding-top":"0px"},attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-editor',{style:(_vm.readonly ? 'pointer-events: none;' : ''),attrs:{"id":"editor1","error-messages":errors,"editor-toolbar":_vm.customToolbar,"readonly":_vm.readonly},model:{value:(_vm.postingData.description),callback:function ($$v) {_vm.$set(_vm.postingData, "description", $$v)},expression:"postingData.description"}})]}}])})],1)],1),_c('v-row',{staticClass:"pt-1 mt-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticStyle:{"font-size":"large","font-weight":"bold"}},[_vm._v("Skills"),_c('red-asterisk')],1),_c('validation-provider',{attrs:{"name":"Skills","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],model:{value:(_vm.skillsSelected),callback:function ($$v) {_vm.skillsSelected=$$v},expression:"skillsSelected"}}),_c('span',{staticClass:"red--text font-weight-regular subtitle-1"},[_vm._v(_vm._s(errors.length > 0 ? 'Must select at least one skill' : ''))])]}}])})],1),_vm._l((_vm.candidateSkills),function(skill,index){return _c('v-col',{key:index,staticClass:"pt-1 pb-1",attrs:{"sm":"2"}},[_c('v-checkbox',{key:'s' + index,staticClass:"pa-0 ma-0",style:(_vm.readonly ? 'pointer-events: none;' : ''),attrs:{"label":skill.name,"value":skill.id,"hide-details":"","readonly":_vm.readonly},model:{value:(_vm.postingData.candidateSkill),callback:function ($$v) {_vm.$set(_vm.postingData, "candidateSkill", $$v)},expression:"postingData.candidateSkill"}})],1)})],2),_c('v-row',[_c('v-col',{attrs:{"cols":"7"}},[_c('span',[_vm._v("Do you have any special requirements for this position i.e., accounting designation, work or residence in a specific geographical area, etc.?"),_c('red-asterisk')],1),_c('validation-provider',{attrs:{"name":"Special Requirements","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{style:(_vm.readonly ? 'pointer-events: none; width: 920px; margin-top: 10px;' : 'width: 920px; margin-top: 10px;'),attrs:{"outlined":"","error-messages":errors,"name":"input-7-4","rows":"2","readonly":_vm.readonly},model:{value:(_vm.postingData.specialRequirements),callback:function ($$v) {_vm.$set(_vm.postingData, "specialRequirements", $$v)},expression:"postingData.specialRequirements"}})]}}])})],1),_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{attrs:{"cols":"4"}},[_c('span',{staticStyle:{"font-size":"large","font-weight":"bold"}},[_vm._v("Posting Expiry")]),_c('v-menu',{style:(_vm.readonly ? 'pointer-events: none;' : ''),attrs:{"transition":"scale-transition","offset-y":"","max-width":"290px","close-on-content-click":false,"min-width":"auto","readonly":_vm.readonly},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"Expiry Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({style:(_vm.readonly ? 'pointer-events: none;' : ''),attrs:{"persistent-hint":"","prepend-icon":"mdi-calendar","error-messages":errors,"readonly":_vm.readonly},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Posting Expiry Date"),_c('red-asterisk')]},proxy:true}],null,true),model:{value:(_vm.postingData.expiryDate),callback:function ($$v) {_vm.$set(_vm.postingData, "expiryDate", $$v)},expression:"postingData.expiryDate"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}]),model:{value:(_vm.dateMenus.expiry),callback:function ($$v) {_vm.$set(_vm.dateMenus, "expiry", $$v)},expression:"dateMenus.expiry"}},[_c('v-date-picker',{style:(_vm.readonly ? 'pointer-events: none;' : ''),attrs:{"no-title":"","readonly":_vm.readonly,"min":_vm.currentDate,"max":_vm.postingData.endDate ? _vm.postingData.endDate : _vm.maxExpirationDate},model:{value:(_vm.postingData.expiryDate),callback:function ($$v) {_vm.$set(_vm.postingData, "expiryDate", $$v)},expression:"postingData.expiryDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dateMenus.expiry = false}}},[_vm._v(" OK ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }