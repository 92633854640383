<template>
  <div
    class="pl-2 pr-2"
  >
    <!--<v-row>
      <v-col cols="3">
        <v-select
          v-model="selectedFilterPosition"
          :items="charityPositionTypes"
          label="Position"
          item-text="name"
          item-value="id"
          clearable
        />
      </v-col>
      <v-col class="text-end">
        <v-btn
          color="primary"
          outlined
          @click="openPostingCreateDialog"
        >
          <v-icon
            small
            class="mr-1"
          >
            mdi-plus
          </v-icon>
          New Posting
        </v-btn>
      </v-col>
    </v-row>-->
    <candidate-application-dialog
      ref="applicationDialog"
      :title="applicationTitle"
      :posting="posting"
      :application="application"
      :charity="charity"
      :show-candidate-details="false"
      @posting-success="notifySuccess"
    >
      <template
        v-if="application != null && canDecline(application)"
        slot-scope="{item}"
      >
        <v-btn
          class="mr-4"
          color="error"
          @click="updateStatus(item.id, candidateDeclineStatusId)"
        >
          {{ `${application.applicationStatusId == 14 ? 'Deny' : 'Withdraw'}` }}
        </v-btn>
        <v-btn
          v-if="item.applicationStatus != null && item.applicationStatus.id == offerExtendedStatusId"
          class="mr-4"
          color="success"
          @click="updateStatus(item.id, acceptStatusId)"
        >
          Accept Offer
        </v-btn>
      </template>
    </candidate-application-dialog>
    <candidate-applications-table
      ref="applicationsTable"
      viewer="candidate"
      :charity="charity"
      :filters="filters"
    >
      <template slot-scope="{item}">
        <a
          class="mr-2"
          @click="viewApplication(item)"
        >VIEW</a>
        <!--<a class='mr-2' v-if="item.applicationStatus.id == offerExtendedStatusId" @click="updateStatus(item.id, acceptStatusId)">ACCEPT OFFER</a>-->
        <!--<a v-if="canDecline(item)" @click="updateStatus(item.id, candidateDeclineStatusId)">WITHDRAW</a>-->
      </template>
    </candidate-applications-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CandidateApplicationDialog from '@/components/candidate/application/CandidateApplicationDialog';
import CandidateApplicationsTable from '@/components/candidate/application/CandidateApplicationsTable';

export default {
  components: {
    CandidateApplicationDialog,
    CandidateApplicationsTable,
  },
  props: {
    charity: {
      type: Object,
      default: () => {},
    },
    positionFilter: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    acceptStatusId: 1,
    candidateDeclineStatusId: 6,
    declineStatusId: 7,
    offerExtendedStatusId: 14,

    editingApplication: true,
    applicationTitle: '',
    posting: null,
    application: null,
    selectedFilterPosition: '',
  }),

  computed: {
    filters() {
      const returnFilters = [];
      const appliedFilter = false;
      returnFilters.push({ name: 'applied', values: [appliedFilter] });
      if (this.candidate != null) {
        returnFilters.push({ name: 'candidateId', values: [this.candidate.id] });
      }

      /* if(this.charity && this.charity.id){
        returnFilters.push({name: "charityId", values: [this.charity.id.toString()]})
      }

      if(this.selectedFilterPosition){
        returnFilters.push({name: "position", values: [this.selectedFilterPosition]})
      } */

      return returnFilters;
    },
    ...mapGetters({
      candidate: 'candidate/pullCandidate',
    }),
  },

  created() {
    if (this.positionFilter) this.selectedFilterPosition = this.positionFilter;
  },
  methods: {
    openApplicationDialog(posting) {
      this.applicationTitle = `Apply to posting for ${posting.charityPosition.name} at ${posting.charity.name}`;
      this.posting = posting;
      this.$refs.applicationDialog.openApplicationDialog(true);
    },
    notifySuccess(message) {
      this.$bus.$emit('showSuccess', message);
      this.$refs.postingTable.refresh();
    },
    viewApplication(application) {
      // this.applicationTitle = `Application for ${
      //   application.posting.charityPosition.name
      // } at ${application.posting.charity.name
      // } (${application.applicationStatus.name})`;
      this.applicationTitle = 'Application';
      this.application = application;
      this.$refs.applicationDialog.openApplicationDialog(false);
    },
    async updateStatus(applicationId, status) {
      let actionConfirmation = 'Are you sure?';
      switch (status) {
        case this.candidateDeclineStatusId:
          actionConfirmation = 'Are you sure you would like to decline this application?';
          break;
        case this.acceptStatusId:
          actionConfirmation = 'Please confirm that you are accepting this offer.';
          break;
        default:
          break;
      }
      if (!confirm(actionConfirmation)) return;
      const patchObjects = [
        { op: 'replace', path: '/applicationStatusId', value: status },
      ];
      const res = await this.$api.candidateApplications.patch(applicationId, patchObjects);
      if (res === false) this.$bus.$emit('showError', "There was an issue with updating the application's status");
      else {
        this.$bus.$emit('showSuccess', (status === 'Candidate Decline' ? 'Application was withdrawn' : "Application's status updated"));
        this.$refs.applicationDialog.closeApplicationDialog();
        this.$refs.applicationsTable.refresh();
      }
    },
    canDecline(application) {
      if (application == null || application.applicationStatusId == null) return null;
      return application.applicationStatus.id !== this.candidateDeclineStatusId
        && application.applicationStatus.id !== this.declineStatusId
        && application.applicationStatus.id !== this.acceptStatusId;
    },
  },
};
</script>
