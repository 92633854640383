<template>
  <v-hover
    v-slot="{ hover }"
  >
    <v-row
      @click="openCharityPosting()"
    >
      <v-col
        cols="12"
        :class="`widget ${hover || selected ? 'custom-color' : 'white'} pa-4 pt-3`"
      >
        <v-row>
          <v-col
            class="pb-1 pt-2 flex-grow-1 flex-shrink-0"
            cols="6"
          >
            <span
              class=" text-button"
              style="color: rgb(0, 145, 255);"
            >{{ status }}</span>
            <!-- <v-btn x-small color="success"></v-btn> -->
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-icon
                  v-if="applied"
                  color="success"
                  class="mb-2 mr-2 ml-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-check-circle
                </v-icon>
              </template>
              <span>Applied</span>
            </v-tooltip>
          </v-col>
          <v-col
            class="pb-1 pt-3 flex-grow-1 flex-shrink-0 d-flex justify-end"
            cols="6"
          >
            <!--<v-chip
              small
              :color="elapsedTimeColor"
              class="mb-2"
            >
              <v-icon class="mr-1">
                mdi-clock-outline
              </v-icon>{{ elapsedTime }}
            </v-chip>-->
            {{ expireStatus }}
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col
            cols="12"
            class="pb-0"
          >
            <span class="text-h6 font-weight-bold mr-2">{{ positionName }} - #{{ charityPosting.id }}</span>
          </v-col>
          <v-col cols="12">
            <v-divider />
          </v-col>
        </v-row>
        <v-row v-if="showCharity">
          <v-col
            class="pt-0 text-subtitle-1 font-weight-bold"
            cols="12"
          >
            {{ charityName }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0">
            <template v-if="charityPosting.matchingSkill && charityPosting.matchingSkill.length">
              <span
                class="text-subtitle-2 font-weight-regular success--text"
              >MATCHING YOUR SKILLS</span> <br>
              <v-chip
                v-for="(skill, index) in charityPosting.matchingSkill"
                :key="'skill-chip-' + index"
                color="primary"
                class="mr-1 mb-0 mt-1"
              >
                {{ '#' + skill.name }}
                <v-icon
                  right
                  class="mr-0"
                  color="success"
                >
                  mdi-check
                </v-icon>
              </v-chip>

              <br>
            </template>
            <div
              class="text-subtitle-2 font-weight-regular mt-2"
            >
              {{ charityPosting.matchingSkill && charityPosting.matchingSkill.length ? 'ADDITIONAL ' : '' }}REQUIRED SKILLS
            </div>
            <v-chip
              v-for="(skill, index) in charityPosting.candidateSkill"
              :key="'posting-' + charityPosting.id + 'skill-chip-' + index"
              color="primary"
              class="mr-1 mb-0 mt-1"
            >
              {{ '#' + skill.name }}
            </v-chip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-hover>
</template>

<script>
import { mapState } from 'vuex';

export default {
  components: {
  },

  props:
  {
    charityPosting: {
      type: Object,
      default: () => {},
    },
    showCharity: {
      type: Boolean,
      default: true,
    },
    applied: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
  }),
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.auth.isLoggedIn,
      involvement: (state) => state.user.selectedInv,
      candidate: (state) => state.candidate.candidate,
    }),
    positionName() {
      const { name } = this.charityPosting.charityPosition;
      // if (name.length > 16) name = `${name.substring(0, 16)}...`;
      return name;
    },
    charityName() {
      const { name } = this.charityPosting.charity;
      // if (name.length > 16) name = `${name.substring(0, 16)}...`;
      return name;
    },
    /* elapsedTime() {
      const elapsedDays = this.$moment().diff(this.$moment(this.charityPosting.datePosted), 'days');
      if (elapsedDays < 31) return `${elapsedDays} Day${elapsedDays > 1 ? 's' : ''} Ago`;
      const elapsedMonths = this.$moment().diff(this.$moment(this.charityPosting.datePosted), 'months');
      if (elapsedMonths < 13) return `${elapsedMonths} Month${elapsedMonths > 1 ? 's' : ''} Ago`;
      const elapsedYears = this.$moment().diff(this.$moment(this.charityPosting.datePosted), 'years');
      return `${elapsedYears} Year${elapsedYears > 1 ? 's' : ''} Ago`;
    },
    elapsedTimeColor() {
      const elapsedDays = this.$moment().diff(this.$moment(this.charityPosting.datePosted), 'days');
      if (elapsedDays < 8) return 'success';
      if (elapsedDays < 31) return 'warning';
      return 'error';
    }, */
    status() {
      // if (this.charityPosting.expiryDate < this.$moment().format('YYYY-MM-DD')) return 'EXPIRED';
      return 'OPEN POSITION';
    },
    expireStatus() {
      let expires = '';
      const elapsedDays = Math.abs(this.$moment().diff(this.$moment(this.charityPosting.expiryDate), 'days'));
      const elapsedMonths = Math.abs(this.$moment().diff(this.$moment(this.charityPosting.expiryDate), 'months'));
      const elapsedYears = Math.abs(this.$moment().diff(this.$moment(this.charityPosting.expiryDate), 'years'));
      if (elapsedDays < 31) expires = `${elapsedDays} Day${elapsedDays > 1 ? 's' : ''}`;
      else if (elapsedMonths < 13) expires = `${elapsedMonths} Month${elapsedMonths > 1 ? 's' : ''}`;
      else expires = `${elapsedYears} Year${elapsedYears > 1 ? 's' : ''}`;
      if (this.charityPosting.expiryDate < this.$moment().format('YYYY-MM-DD')) {
        expires = `Expired ${expires} ago`;
      } else {
        expires = `${expires} left`;
      }
      return expires;
    },
  },
  watch: {
  },
  methods: {
    openCharityPosting() {
      this.$emit('showCharityPosting', this.charityPosting.id);
      // this.$router.push(`/charity-profile?cid=${this.charity.id}`);
    },
    /* skillChipDisabled(skill) {
      if (!this.isLoggedIn) return true;
      if (!this.candidate || !this.candidate.skills || !this.involvement || this.involvement.involvement !== 'candidate') return true;
      return this.candidate.skills.findIndex((x) => x.id === skill.id) === -1;
    }, */
  },
};
</script>
<style>
  .widget{
    border-radius: 10px;
    padding: 12px;
  }
  .thumbnail-image {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .hovering {
    background-color: transparent !important;
    background-color: #E0E0E0 !important;
    cursor: pointer;
  }
  .custom-color {
   background-color: #d8f2e0;
   cursor: pointer;
  }
</style>
