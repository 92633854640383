<template>
  <div class="ma-0 pa-2 text-start">
    <validation-observer ref="postingValObserver">
      <span
        class="text-h5 font-weight-bold"
      >Programs<red-asterisk /></span>
      <v-row
        v-if="submitCharity.charityPrograms == null || submitCharity.charityPrograms.length == 0"
      >
        <v-col>
          <span
            class="red--text"
          >Please add at least 1 program</span>
        </v-col>
      </v-row>

      <v-row
        v-for="(program, index) in submitCharity.charityPrograms"
        :key="'program-' + index"
        class="mt-4 mb-4 pt-2 charity-program"
      >
        <v-col
          cols="1"
          class="row-number-col d-flex justify-start align-start text-h6"
        >
          {{ index + 1 }}.
        </v-col>
        <v-col
          cols="10"
          class="ma-0 mt-3  pa-0 pl-5"
        >
          <v-row>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                :name="'numberOfPaidStaff'"
                rules="required"
              >
                <v-text-field
                  v-model="program.name"
                  label="Program Name"
                  dense
                  :error-messages="errors"
                  hide-details=""
                  :readonly="readOnly"
                  :style="readOnly ? 'pointer-events: none' : ''"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              class="pt-4 d-flex justify-end align-start program-description"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="'numberOfPaidStaff'"
                rules="required"
              >
                <!-- <v-textarea
                  v-model="program.details"
                  label="Program Description"
                  dense
                  :error-messages="errors"
                  rows="2"
                  hide-details=""
                  :readonly="readOnly"
                  :style="readOnly ? 'pointer-events: none' : ''"
                /> -->
                <vue-editor
                  v-model="program.details"
                  :error-messages="errors"
                  :editor-toolbar="customToolbar"
                  :readonly="readOnly"
                  :style="readOnly ? 'pointer-events: none;' : ''"
                />
              </validation-provider>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="1"
          class="ma-0 pa-0 pl-5 text-h6 d-flex justify-end align-start pt-1"
        >
          <v-icon
            color="red"
            class="mr-3 mb-1"
            :readonly="readOnly"
            :style="readOnly ? 'pointer-events: none' : ''"
          >
            mdi-close-thick
          </v-icon>
        </v-col>
      </v-row>
      <v-row class="mb-8">
        <v-col
          cols="12"
          class="text-center"
        >
          <v-btn
            color="success"
            elevation="0"
            :disabled="readOnly"
            outlined
            @click="addProgram()"
          >
            Add Another Program
          </v-btn>
        </v-col>
      </v-row>
      <span class="text-h5 font-weight-bold">Focus Areas<red-asterisk /></span>
      <span
        v-if="!submitCharity.focusAreas || submitCharity.focusAreas.length == 0 && validationTriggered"
        class="red--text ml-2"
      >Must select at least 1 Focus Area</span>
      <br>
      <template
        v-if="submitCharity.focusAreas && submitCharity.focusAreas.length > 0"
      >
        <v-chip
          v-for="(focusArea, focusAreaIndex) in submitCharity.focusAreas"
          :key="'position-matching-focus-area-' + focusAreaIndex"
          medium
          class="ml-2 mt-2"
          close
          color="success"
          @click:close="removeFilterChip(focusAreaIndex, 'focus-area')"
        >
          {{ chipName('focus-area', focusArea) }}
        </v-chip>
      </template>
      <v-chip
        medium
        color="success"
        class="ml-2 mt-2"
        @click="openChipSelector('focus-area')"
      >
        +
      </v-chip>
      <!--<v-row class="mt-3 mb-8">
        <v-col cols="12">
          <ValidationProvider
            v-slot="{ errors }"
            immediate
            name="charityFocusAreas"
            rules="required"
          >
            <span
              v-if="errors.length > 0"
              class="red--text"
            >Please select at least 1 Focus Area</span>
            <v-text-field
              v-show="false"
              v-model="submitCharity.focusAreas"
              :error="errors.length > 0"
            />
          </ValidationProvider>
        </v-col>
        <v-col
          v-for="(chbx, index) in candidateFocusAreas"
          :key="index"
          cols="3"
          sm="2"
          class="ma-0 pa-0 pl-5"
        >
          <v-checkbox
            :key="'sel-' + index"
            v-model="submitCharity.focusAreas"
            multiple
            hide-details
            :value="chbx.id"
            :label="chbx.name"
            class="mt-0 compact"
            :readonly="readOnly"
            :style="readOnly ? 'pointer-events: none' : ''"
          />
        </v-col>
      </v-row>-->
      <br><br><br>
      <span class="text-h5 font-weight-bold">Other Matching Preferences:</span>
      <v-row class="mt-3">
        <v-col cols="9">
          <span>
            What month do you conduct your Annual General Meeting (AGM)?</span>
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="submitCharity.charityPositionMatching.annualGeneralMeetingMonth"
            item-value="number"
            :items="months"
            class="mt-0 d-grid"
            item-text="name"
            hide-details
            dense
            :readonly="readOnly"
            :style="readOnly ? 'pointer-events: none' : ''"
          >
            <template #label>
              Select Month
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row class="mt-3">
        <v-col cols="9">
          <span>
            What month do you normally begin to recruit for board members?</span>
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="submitCharity.charityPositionMatching.recruitForBoardMonth"
            item-value="number"
            :items="months"
            class="mt-0 d-grid"
            item-text="name"
            hide-details
            dense
            :readonly="readOnly"
            :style="readOnly ? 'pointer-events: none' : ''"
          >
            <template #label>
              Select Month
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row class="mt-3">
        <v-col cols="9">
          <span>
            What is your board type?<red-asterisk /></span>
        </v-col>
        <v-col cols="3">
          <validation-provider
            v-slot="{ errors }"
            :name="'boardType'"
            rules="required"
          >
            <v-select
              v-model="submitCharity.charityPositionMatching.charityBoardTypeId"
              item-value="id"
              :items="boardTypes"
              :error-messages="errors"
              class="mt-0 d-grid"
              item-text="name"
              hide-details
              dense
              :readonly="readOnly"
              :style="readOnly ? 'pointer-events: none' : ''"
            >
              <template #label>
                Board Type
              </template>
            </v-select>
          </validation-provider>
        </v-col>
      </v-row>
      <v-row class="mt-3">
        <v-col cols="9">
          <span>
            How would you describe the development stage of your organization at this point?<red-asterisk /></span>
        </v-col>
        <v-col cols="3">
          <validation-provider
            v-slot="{ errors }"
            :name="'developmentStage'"
            rules="required"
          >
            <v-select
              v-model="submitCharity.charityPositionMatching.charityDevelopmentStageId"
              item-value="id"
              :items="developmentStages"
              :error-messages="errors"
              class="mt-0 d-grid"
              item-text="name"
              hide-details
              dense
              :readonly="readOnly"
              :style="readOnly ? 'pointer-events: none' : ''"
            >
              <template #label>
                Development Stage
              </template>
            </v-select>
          </validation-provider>
        </v-col>
      </v-row>
      <v-row class="mt-3">
        <v-col cols="9">
          <span>
            What is your operating budget for the current year?<red-asterisk /></span>
        </v-col>
        <v-col cols="3">
          <validation-provider
            v-slot="{ errors }"
            :name="'operatingBudgetCurrentYear'"
            rules="required"
          >
            <v-text-field
              v-model="submitCharity.charityPositionMatching.operatingBudgetCurrentYear"
              :error-messages="errors"
              label="Operating Budget"
              dense
              hide-details=""
              :readonly="readOnly"
              :style="readOnly ? 'pointer-events: none' : ''"
            />
          </validation-provider>
        </v-col>
      </v-row>
      <v-row class="mt-3">
        <v-col cols="9">
          <span>
            How is your funding for the next fiscal year?<red-asterisk /></span>
        </v-col>
        <v-col
          cols="3"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="'charityFundingStatusId'"
            rules="required"
          >
            <v-select
              v-model="submitCharity.charityPositionMatching.charityFundingStatusId"
              item-value="id"
              :items="fundingStatuses"
              :error-messages="errors"
              class="mt-0 d-grid"
              item-text="name"
              hide-details
              dense
              :readonly="readOnly"
              :style="readOnly ? 'pointer-events: none' : ''"
            >
              <template #label>
                Funding Status
              </template>
            </v-select>
          </validation-provider>
        </v-col>
      </v-row>
      <v-row class="mt-3">
        <v-col cols="2">
          <span>
            Number of paid staff:<red-asterisk /></span>
        </v-col>
        <v-col cols="2">
          <validation-provider
            v-slot="{ errors }"
            :name="'numberOfPaidStaff'"
            rules="required"
          >
            <v-text-field
              v-model="submitCharity.charityPositionMatching.numberOfPaidStaff"
              hide-details
              single-line
              :error-messages="errors"
              type="number"
              dense
              :readonly="readOnly"
              :style="readOnly ? 'pointer-events: none' : ''"
            >
              <template #label>
                # of Staff
              </template>
            </v-text-field>
          </validation-provider>
        </v-col>
        <v-col cols="2">
          <span>
            Number of volunteers:<red-asterisk /></span>
        </v-col>
        <v-col cols="2">
          <validation-provider
            v-slot="{ errors }"
            :name="'numberOfVolunteers'"
            rules="required"
          >
            <v-text-field
              v-model="submitCharity.charityPositionMatching.numberOfVolunteers"
              hide-details
              single-line
              :error-messages="errors"
              type="number"
              dense
              :readonly="readOnly"
              :style="readOnly ? 'pointer-events: none' : ''"
            >
              <template #label>
                # of Volunteers
              </template>
            </v-text-field>
          </validation-provider>
        </v-col>
        <v-col cols="2">
          <span>
            Maximum board size:<red-asterisk /></span>
        </v-col>
        <v-col cols="2">
          <validation-provider
            v-slot="{ errors }"
            :name="'maximumBoardSize'"
            rules="required"
          >
            <v-text-field
              v-model="submitCharity.charityPositionMatching.maximumBoardSize"
              hide-details
              single-line
              :error-messages="errors"
              type="number"
              dense
              :readonly="readOnly"
              :style="readOnly ? 'pointer-events: none' : ''"
            >
              <template #label>
                # Board Members
              </template>
            </v-text-field>
          </validation-provider>
        </v-col>
      </v-row>
      <v-row class="mt-3">
        <v-col cols="2">
          <span>
            Length of board term:<red-asterisk /></span>
        </v-col>
        <v-col cols="2">
          <validation-provider
            v-slot="{ errors }"
            :name="'lengthOfBoardTerm'"
            rules="required"
          >
            <v-select
              v-model="submitCharity.charityPositionMatching.lengthOfBoardTerm"
              item-value="number"
              :items="boardTermLength"
              :error-messages="errors"
              class="mt-0 d-grid"
              item-text="name"
              hide-details
              dense
              :readonly="readOnly"
              :style="readOnly ? 'pointer-events: none' : ''"
            >
              <template #label>
                Length
              </template>
            </v-select>
          </validation-provider>
        </v-col>
        <v-col cols="2">
          <span>Length of renewable term:</span>
        </v-col>
        <v-col cols="2">
          <v-select
            v-model="submitCharity.charityPositionMatching.lengthOfRenewableTerm"
            item-value="number"
            :items="renewableTerm"
            class="mt-0 d-grid"
            item-text="name"
            hide-details
            dense
            :readonly="readOnly"
            :style="readOnly ? 'pointer-events: none' : ''"
          >
            <template #label>
              Times
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row class="mt-3">
        <v-col cols="8">
          <span>
            Approximately how much time do you ask each board member to devote to their monthly duties including all committee work, etc.?<red-asterisk /></span>
        </v-col>
        <v-col :cols="submitCharity.charityPositionMatching.charityBoardWorkHoursId == charityBoardWorkHoursOtherId ? '2' : '4'">
          <validation-provider
            v-slot="{ errors }"
            :name="'charityBoardWorkHoursId'"
            rules="required"
          >
            <v-select
              v-model="submitCharity.charityPositionMatching.charityBoardWorkHoursId"
              item-value="id"
              :items="boardWorkHours"
              :error-messages="errors"
              item-text="name"
              hide-details
              dense
              :readonly="readOnly"
              :style="readOnly ? 'pointer-events: none' : ''"
            >
              <template #label>
                Hours per Month
              </template>
            </v-select>
          </validation-provider>
        </v-col>
        <v-col
          v-if="submitCharity.charityPositionMatching.charityBoardWorkHoursId == charityBoardWorkHoursOtherId"
          cols="2"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="'charityBoardWorkHoursOther'"
            rules="required"
          >
            <v-text-field
              v-model="submitCharity.charityPositionMatching.charityBoardWorkHoursOther"
              :error-messages="errors"
              label="Please Specify"
              dense
              hide-details
              :readonly="readOnly"
              :style="readOnly ? 'pointer-events: none' : ''"
            />
          </validation-provider>
        </v-col>
      </v-row>
      <v-row class="mt-3">
        <v-col cols="8">
          <span>
            How many times per year does your board meet?<red-asterisk /></span>
        </v-col>
        <v-col cols="4">
          <validation-provider
            v-slot="{ errors }"
            :name="'boardMeetingTimesPerYear'"
            rules="required"
          >
            <v-text-field
              v-model="submitCharity.charityPositionMatching.boardMeetingTimesPerYear"
              hide-details
              single-line
              :error-messages="errors"
              type="number"
              dense
              :readonly="readOnly"
              :style="readOnly ? 'pointer-events: none' : ''"
            >
              <template #label>
                Times Per Year
              </template>
            </v-text-field>
          </validation-provider>
        </v-col>
      </v-row>
      <v-row class="mt-3">
        <v-col cols="8">
          <span>
            What time and day does your board meet?<red-asterisk /></span>
        </v-col>
        <v-col cols="4">
          <validation-provider
            v-slot="{ errors }"
            :name="'boardMeetingTimeAndDay'"
            rules="required"
          >
            <v-text-field
              v-model="submitCharity.charityPositionMatching.boardMeetingTimeAndDay"
              hide-details
              :error-messages="errors"
              dense
              :readonly="readOnly"
              :style="readOnly ? 'pointer-events: none' : ''"
            >
              <template #label>
                Time/Day
              </template>
            </v-text-field>
          </validation-provider>
        </v-col>
      </v-row>
      <v-row class="mt-3">
        <v-col cols="8">
          <span>
            Where are your board meetings held?<red-asterisk /></span>
        </v-col>
        <v-col cols="4">
          <validation-provider
            v-slot="{ errors }"
            :name="'boardMeetingLocation'"
            rules="required"
          >
            <v-text-field
              v-model="submitCharity.charityPositionMatching.boardMeetingLocation"
              hide-details
              :error-messages="errors"
              dense
              :readonly="readOnly"
              :style="readOnly ? 'pointer-events: none' : ''"
            >
              <template #label>
                Meeting Location
              </template>
            </v-text-field>
          </validation-provider>
        </v-col>
      </v-row>
      <v-row class="mt-3">
        <v-col cols="8">
          <span>
            In addition to attendance at board meetings, what other activities are required of all board members?<red-asterisk /></span>
        </v-col>
        <v-col
          cols="12"
          class="ma-0 pa-0 pl-5"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="'boardExtraActivities'"
            rules="required"
          >
            <v-textarea
              v-model="submitCharity.charityPositionMatching.boardExtraActivities"
              label="Other Activities"
              outlined
              class="mr-5"
              dense
              :error-messages="errors"
              hide-details
              :readonly="readOnly"
              :style="readOnly ? 'pointer-events: none' : ''"
            />
          </validation-provider>
        </v-col>
      </v-row>
      <v-row class="mt-5">
        <v-col
          cols="10"
          :class="`pt-4${ submitCharity.charityPositionMatching.filedLatestT3010 == false && validationTriggered ? ' red--text' : ''}`"
        >
          <span>
            Have you filed the latest T3010 form with the Canada Revenue Agency?<red-asterisk /></span>
          <span
            v-if="submitCharity.charityPositionMatching.filedLatestT3010 == false && validationTriggered"
            class="red--text ml-2"
          >The answer can not be "No"</span>
        </v-col>
        <v-col
          cols="2"
          class="ma-0 pa-0 pl-5"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="'filedLatestT3010'"
            rules="required"
          >
            <v-radio-group
              v-model="submitCharity.charityPositionMatching.filedLatestT3010"
              :error-messages="errors"
              dense
              hide-details
              row
              :readonly="readOnly"
              :style="readOnly ? 'pointer-events: none' : ''"
            >
              <v-radio
                label="Yes"
                :value="true"
              />
              <v-radio
                label="no"
                :value="false"
              />
            </v-radio-group>
          </validation-provider>
        </v-col>
      </v-row>
      <v-row class="mt-5">
        <v-col
          cols="10"
          :class="`pt-4${ submitCharity.charityPositionMatching.statuaryRemittancesUpToDate == false && validationTriggered ? ' red--text' : ''}`"
        >
          <span>
            Are your organization's statutory remittances up to date?<red-asterisk /></span>
          <span
            v-if="submitCharity.charityPositionMatching.statuaryRemittancesUpToDate == false && validationTriggered"
            class="red--text ml-2"
          >The answer can not be "No"</span>
        </v-col>
        <v-col
          cols="2"
          class="ma-0 pa-0 pl-5"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="'statuaryRemittancesUpToDate'"
            rules="required"
          >
            <v-radio-group
              v-model="submitCharity.charityPositionMatching.statuaryRemittancesUpToDate"
              :error-messages="errors"
              dense
              hide-details
              row
              :readonly="readOnly"
              :style="readOnly ? 'pointer-events: none' : ''"
            >
              <v-radio
                label="Yes"
                :value="true"
              />
              <v-radio
                label="no"
                :value="false"
              />
            </v-radio-group>
          </validation-provider>
        </v-col>
      </v-row>
    </validation-observer>
    <v-dialog
      v-model="editSkillsDialog"
      width="500px"
    >
      <v-card
        fill-height
      >
        <v-container
          fluid
        >
          <v-row>
            <v-col>
              <span class="text-h4">Select Focus Areas</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              class="ml-2"
            >
              <span>Please select 1 or more focus areas from the list below that you would like to be matched with:</span><br><br>
              <v-chip
                v-for="focusArea in candidateFocusAreas"
                :key="focusArea.id"
                :class="chipSelected(focusArea) ? 'success' : ''"
                small
                class="mt-1"
                @click="selectChip(focusArea)"
              >
                {{ focusArea.name }}
              </v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              class="text-end"
            >
              <v-btn
                class="mr-4 default"
                elevation="0"
                @click="closeForm"
              >
                Ok
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { required } from 'vee-validate/dist/rules';
import { mapState } from 'vuex';
import { VueEditor } from 'vue2-editor';
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from 'vee-validate';

setInteractionMode('immediate');
extend('required', {
  ...required,
  message: 'Must select at least one {_field_}',
});
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VueEditor,
  },
  props:
    {
      readOnly: {
        type: Boolean,
        default: false,
      },
      charity: {
        type: Object,
        default: () => null,
      },
    },
  data: () => ({
    submitCharity: {},
    submitCharityPositionMatching: {
      id: 0,
      charityId: 0,
      anualGeneralMeetingMonth: null,
      recruitForBoardMonth: null,
      charityBoardTypeId: null,
      charityBoardType: null,
      charityDevelopmentStageId: null,
      charityDevelopmentStage: null,
      operatingBudgetCurrentYear: null,
      charityFundingStatusId: null,
      charityFundingStatus: null,
      numberOfPaidStaff: null,
      numberOfVolunteers: null,
      maximumBoardSize: null,
      lengthOfRenewableTerm: null,
      lengthOfBoardTerm: null,
      charityBoardWorkHoursId: null,
      charityBoardWorkHours: null,
      charityBoardWorkHoursOther: null,
      boardMeetingTimeAndDay: null,
      boardMeetingLocation: null,
      boardExtraActivities: null,
      filedLatestT3010: false,
      statuaryRemittancesUpToDate: false,
    },
    charityBoardWorkHoursOtherId: 4, // as per CharityBoardWorkHours table
    editSkillsDialog: false,
    validationTriggered: false,
    customToolbar: [
      ['bold', 'italic', 'underline'],
    ],
  }),
  computed: {
    ...mapState({
      candidateFocusAreas: (state) => state.candidate.candidateFocusAreas,
      boardTypes: (state) => state.charity.boardTypes,
      developmentStages: (state) => state.charity.developmentStages,
      fundingStatuses: (state) => state.charity.fundingStatuses,
      boardWorkHours: (state) => state.charity.boardWorkHours,
    }),
    months() {
      return [{
        number: 1,
        name: 'January',
      },
      {
        number: 2,
        name: 'February',
      },
      {
        number: 3,
        name: 'March',
      },
      {
        number: 4,
        name: 'April',
      },
      {
        number: 5,
        name: 'May',
      },
      {
        number: 6,
        name: 'June',
      },
      {
        number: 7,
        name: 'July',
      },
      {
        number: 8,
        name: 'August',
      },
      {
        number: 9,
        name: 'September',
      },
      {
        number: 10,
        name: 'October',
      },
      {
        number: 11,
        name: 'November',
      },
      {
        number: 12,
        name: 'December',
      }];
    },
    renewableTerm() {
      return [
        {
          number: 1,
          name: '1 Time',
        },
        {
          number: 2,
          name: '2 Times',
        },
        {
          number: 3,
          name: '3 Times',
        },
        {
          number: 4,
          name: '4 Times',
        },
        {
          number: 5,
          name: '5 Times',
        },
        {
          number: -1,
          name: 'No Limit',
        },
        {
          number: 0,
          name: 'Not Renewable',
        },
      ];
    },
    boardTermLength() {
      return [
        {
          number: 1,
          name: '1 Year',
        },
        {
          number: 2,
          name: '2 Years',
        },
        {
          number: 3,
          name: '3 Years',
        },
        {
          number: 4,
          name: '4 Years',
        },
        {
          number: 5,
          name: '5 Years',
        },
      ];
    },
  },
  watch: {
    charity: {
      immediate: true,
      handler(value) {
        if (value != null) {
          this.submitCharity = {
            ...value,
            focusAreas: value.focusAreas && value.focusAreas.length ? value.focusAreas.map((fa) => fa.id) : [],
            charityPrograms: value.charityPrograms && value.charityPrograms.length > 0 ? value.charityPrograms : [{
              id: null, charityId: this.charity.id, name: '', details: '',
            }],
          };
        }
      },
    },
  },
  mounted() {
  },
  methods: {
    async submit() {
      const res = await this.$api.charity.update(this.submitCharity);
      return res;
    },
    resetForm() {
      this.charitySkills = [];
      this.charityFocusAreas = [];
    },
    async validate() {
      if (this.submitCharity.charityPrograms == null || this.submitCharity.charityPrograms.length === 0) return false;
      const res = await this.$refs.postingValObserver.validate();
      this.validationTriggered = true;
      if (res !== true) {
        return false;
      }
      if (this.submitCharity.charityPositionMatching.filedLatestT3010 !== true) return false;
      if (this.submitCharity.charityPositionMatching.statuaryRemittancesUpToDate !== true) return false;
      return true;
    },
    addProgram() {
      this.submitCharity.charityPrograms.push({
        id: null, charityId: this.charity.id, name: '', details: '',
      });
    },
    removeProgram(index) {
      this.submitCharity.charityPrograms.splice(index, 1);
    },
    openChipSelector() {
      this.editSkillsDialog = true;
    },
    closeForm() {
      this.editSkillsDialog = false;
    },
    chipSelected(item) {
      if (this.submitCharity.focusAreas.findIndex((x) => x === item.id) > -1) return true;
      return false;
    },
    selectChip(item) {
      const index = this.submitCharity.focusAreas.findIndex((x) => x === item.id);
      if (index === -1) this.submitCharity.focusAreas.push(item.id);
      else this.removeFilterChip(index, this.dialogChipType);
    },
    removeFilterChip(skillIndex) {
      this.submitCharity.focusAreas.splice(skillIndex, 1);
    },
    chipName(type, id) {
      let index = -1;
      if (type === 'skill') index = this.candidateSkills.findIndex((x) => x.id === id);
      if (type === 'focus-area') index = this.candidateFocusAreas.findIndex((x) => x.id === id);
      if (index > -1) {
        if (type === 'skill') return this.candidateSkills[index].name;
        if (type === 'focus-area') return this.candidateFocusAreas[index].name;
      }
      return 'unknown';
    },
  },
};
</script>
<style scoped>
.charity-program {
  border-style: solid;
  border-color: lightgrey;
  border-width: 1px;
  margin-left: 1px;
  margin-right: 1px;
  padding-bottom: 20px;
}
.program-description.col span {
  width: 100%;
}
  .row-number-col {
    display: grid !important;
  }
</style>
