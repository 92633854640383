import axios from 'axios';

class Api {
  constructor(endpoint, model) {
    this.model = model;
    this.endpoint = endpoint;
  }

  async get(path = null, parameters = []) {
    let list = [];
    const errorText = `Could not fetch ${this.model} list`;
    let url = `/${this.endpoint}${path ? `/${path}` : ''}`;
    if (parameters.length > 0) {
      url += '?';
      let first = true;
      parameters.forEach((x) => {
        if (first === false) url += '&';
        url += (`${x.name}=${x.value}`);
        first = false;
      });
    }
    await axios.get(url)
      .then((response) => {
        list = response.data;
      })
      .catch((error) => {
        if (error.response) {
        // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
        // The request was made but no response was received
          console.log(error.request);
        } else {
        // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(errorText);
        list = [];
      });
    return list;
  }

  async create(data, path = '', isForm = false) {
    let res;
    const errorText = `Could not create ${this.model}`;
    const formData = new FormData();
    if (isForm) {
      Object.entries(data).forEach((property) => {
        const [key, value] = property;
        if (value != null) formData.append(key, value);
      });
    }

    await axios.post(`/${this.endpoint}${path}`, (isForm ? formData : data), (isForm ? {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    } : null))
      .then((response) => {
        res = response.data;
      })
      .catch((error) => {
        if (error.response) {
        // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
        // The request was made but no response was received
          console.log(error.request);
        } else {
        // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(errorText);
        res = false;
      });
    return res;
  }

  async update(data, path = '', isForm = false) {
    let res;
    const errorText = `Could not update ${this.model}`;
    const formData = new FormData();
    if (isForm) {
      Object.entries(data).forEach((property) => {
        const [key, value] = property;
        if (value != null) formData.append(key, value);
      });
    }

    await axios.put(`/${this.endpoint}${path}`, (isForm ? formData : data), (isForm ? {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    } : null))
      .then((response) => {
        res = response.data;
      })
      .catch((error) => {
        if (error.response) {
        // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
        // The request was made but no response was received
          console.log(error.request);
        } else {
        // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(errorText);
        res = false;
      });
    return res;
  }

  async patch(id, data) {
    let res;
    const errorText = `Could not patch ${this.model}`;

    await axios.patch(`/${this.endpoint}/${id}`, data)
      .then((response) => {
        res = response.data;
      })
      .catch((error) => {
        if (error.response) {
        // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
        // The request was made but no response was received
          console.log(error.request);
        } else {
        // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(errorText);
        res = false;
      });
    return res;
  }

  async read(options) {
    let list = [];
    const errorText = `Could not fetch ${this.model} list`;

    await axios.post(`/${this.endpoint}/read`, options)
      .then((response) => {
        list = response.data;
      })
      .catch((error) => {
        if (error.response) {
        // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
        // The request was made but no response was received
          console.log(error.request);
        } else {
        // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(errorText);
        list = null;
      });
    return list;
  }

  async readPublic(options) {
    let list = [];
    const errorText = `Could not fetch ${this.model} list`;

    await axios.post(`/${this.endpoint}/read-public`, options)
      .then((response) => {
        list = response.data;
      })
      .catch((error) => {
        if (error.response) {
        // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
        // The request was made but no response was received
          console.log(error.request);
        } else {
        // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(errorText);
        list = null;
      });
    return list;
  }

  async getById(id) {
    let res;
    const errorText = `Could not fetch ${this.model} item`;

    await axios.get(`/${this.endpoint}/${id}`)
      .then((response) => {
        res = response.data;
      })
      .catch((error) => {
        if (error.response) {
        // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
        // The request was made but no response was received
          console.log(error.request);
        } else {
        // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(errorText);
        res = false;
      });
    return res;
  }

  async softDelete(id, path = '') {
    let res;
    const errorText = `Could not delete ${this.model} id ${id}`;

    await axios.delete(`/${this.endpoint}${path}/${id}`)
      .then((response) => {
        res = response.data;
      })
      .catch((error) => {
        if (error.response) {
        // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
        // The request was made but no response was received
          console.log(error.request);
        } else {
        // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(errorText);
        res = false;
      });
    return res;
  }

  async unlink(id, entityType, entityId) {
    let res;
    const errorText = `Could not unlink ${entityType} id ${entityId} from ${this.model} id ${id}`;

    await axios.delete(`/${this.endpoint}/${id}/${entityType}/${entityId}`)
      .then((response) => {
        res = response.data;
      })
      .catch((error) => {
        if (error.response) {
        // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
        // The request was made but no response was received
          console.log(error.request);
        } else {
        // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(errorText);
        res = false;
      });
    return res;
  }

  async getFiltered(filter) {
    let list = [];
    const errorText = `Could not fetch ${this.model} list`;
    try {
      const url = `/${this.endpoint}/filtered`;
      list = await axios.post(url, filter);
      if (list.status === 200) {
        list = list.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      list = [];
    }
    return list;
  }

  async getByPage(page) {
    let list = [];
    const errorText = `Could not fetch ${this.model} list`;
    try {
      const url = `/${this.endpoint}/page`;
      list = await axios.post(url, page);
      if (list.status === 200) {
        list = list.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      list = [];
    }
    return list;
  }

  async createReturnData(data) {
    let res = {};
    const errorText = `Could not create ${this.model}`;
    try {
      res = await axios.post(`/${this.endpoint}`, data);
      if (res.status === 200) {
        res = res.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = false;
    }
    return res;
  }

  async updateReturnData(data) {
    let res = {};
    const errorText = `Could not create ${this.model}`;
    try {
      res = await axios.put(`/${this.endpoint}`, data);
      if (res.status === 200) {
        res = res.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = false;
    }
    return res;
  }

  async toggleEnabled(itemId) {
    let res;
    const errorText = `Could not toggle ${this.model} enabled status`;
    try {
      res = await axios.get(`/${this.endpoint}/toggle-enabled/${itemId}`);
      if (res.status === 200) {
        res = true;
      } else {
        console.error(errorText);
        res = false;
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = false;
    }
    return res;
  }

  async getByUserId(id) {
    let res;
    const errorText = `Could not fetch ${this.model} item`;
    try {
      // let url = `/${this.endpoint}`;
      res = await axios.get(`/${this.endpoint}/user/${id}`);
      if (res.status === 200) {
        res = res.data;
      } else {
        console.error(errorText);
        res = false;
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = false;
    }
    return res;
  }
}

export default Api;
