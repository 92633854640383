/* eslint-disable import/prefer-default-export */
export function createPaginationObject(options = null, search = null, filters = null) {
  let Pagination = {
    Page: 1,
    ItemsPerPage: -1,
  };
  let Sort = [];
  const Include = [];
  if (options != null) {
    if (options.page && options.itemsPerPage) {
      Pagination = {
        Page: options.page,
        ItemsPerPage: options.itemsPerPage,
      };
    }
    if (options.sortBy && options.sortBy.length && options.sortDesc && options.sortDesc.length) { // this is happening when we're pulling Vuetify's native table sort objects
      Sort = [{
        field: options.sortBy[0],
        isAscending: options.sortDesc[0],
      }];
    } else if (options.sortBy && options.sortBy.length) { // otherwise we have our own custom overriding array of sort objects
      Sort = options.sortBy;
    }
    if (options.include != null) {
      for (let index = 0; index < options.include.length; index += 1) {
        Include.push(options.include[index]);
      }
    }
  }

  return {
    Filters: filters,
    Pagination,
    Sort,
    Search: search,
    Include,
  };
}
export function createSortObject(name, direction) {
  return {
    field: name,
    isAscending: direction === 'asc' || direction === 'ascending',
  };
}

// options: {page: number,
// itemsPerPage: number,
// sortBy: string[],
// sortDesc: boolean[],
// groupBy: string[],
// groupDesc: boolean[],
// multiSort: boolean,
// mustSort: boolean}
