<template>
  <v-container class="mb-0">
    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <v-btn
          class="mr-2"
          outlined
          color="primary"
          v-bind="attrs"
          v-on="on"
          @click="resetPassword()"
        >
          RESET
        </v-btn>
      </template>
      <span>Send an email with a link to Reset Password</span>
    </v-tooltip>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {

  props:
  {
    user: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    isAdmin: false,
  }),

  computed: {
    ...mapGetters({
      findClaim: 'auth/findClaim',
    }),
  },

  created() {
    this.getClaim();
  },

  methods: {
    getClaim() {
      if (this.findClaim('charity-admin.read') === '1' || this.findClaim('boardmatch-admin.read') === '1') {
        this.isAdmin = true;
      }
    },
    async resetPassword() {
      if (!this.user && !this.user.userName) return;
      if (!confirm('Are you sure you would like to send an email with password reset for this user?')) return;
      const result = await this.$api.auth.forgotPassword(this.user.userName);

      if (!result) {
        this.$bus.$emit('showError');
        return;
      }

      const message = `Password Reset Email has been sent to ${this.user.userName}`;
      this.$bus.$emit('showSuccess', message);
    },

  },
};
</script>
