// import axios from 'axios';
import Api from '../Api';

class UserStateApi extends Api {
  constructor() {
    super('users/states', 'userstates');
  }
}

const userStateApi = new UserStateApi();
export default userStateApi;
