/* eslint-disable consistent-return */
/* eslint-disable no-async-promise-executor */
/* eslint-disable no-param-reassign */
import { createPaginationObject } from '@/helpers/PaginationHelper.js';
import charityApi from '@/api/charities/CharityApi';
import ImageApi from '@/api/generals/ImageApi';
import CharityOnboardingApi from '@/api/charities/CharityOnboardingApi';

export default {
  namespaced: true,

  state: () => ({
    charity: {},
    onboarding: [],
    onboarded: false,
    boardTypes: [],
    developmentStages: [],
    fundingStatuses: [],
    boardWorkHours: [],
    profileImage: null,
  }),
  mutations: {

    clearData(state) {
      state.charity = {};
      state.onboarding = [];
      state.onboarded = false;
      state.boardTypes = [];
      state.developmentStages = [];
      state.fundingStatuses = [];
      state.boardWorkHours = [];
      state.profileImage = null;
    },
    storeCharity(state, charity) {
      state.charity = charity;
    },
    storeCharityOnboarding(state, data) {
      state.onboarding = data;
    },
    storeOnboardingState(state, data) {
      state.onboarded = data;
    },
    storeBoardTypes(state, data) {
      state.boardTypes = data;
    },
    storeDevelopmentStages(state, data) {
      state.developmentStages = data;
    },
    storeFundingStatuses(state, data) {
      state.fundingStatuses = data;
    },
    storeBoardWorkHours(state, data) {
      state.boardWorkHours = data;
    },
    storeProfileImage(state, data) {
      state.profileImage = data;
    },
  },
  actions: {

    getCharity({ commit }, id) {
      return new Promise(async (resolve) => {
        const filters = [{ name: 'charityId', values: [id] }, { name: 'IsDisabled', values: [0] }];
        const search = createPaginationObject(null, null, filters);
        const res = await charityApi.read(search);
        let charity = {};
        if (res != null && res.charities != null) [charity] = res.charities;
        commit('storeCharity', charity);
        resolve();
      });
    },
    getCharityOnboarding({ commit }, id) {
      return new Promise(async (resolve) => {
        const filters = [{ name: 'CharityId', values: [id] }, { name: 'IsDisabled', values: [0] }];
        const search = createPaginationObject(null, null, filters);
        const onboarding = await CharityOnboardingApi.read(search);
        commit('storeCharityOnboarding', onboarding);
        resolve();
      });
    },
    getBoardTypes({ commit }) {
      return new Promise(async (resolve) => {
        const list = await charityApi.getBoardTypes();
        commit('storeBoardTypes', list);
        resolve();
      });
    },
    getDevelopmentStages({ commit }) {
      return new Promise(async (resolve) => {
        const list = await charityApi.getDevelopmentStages();
        commit('storeDevelopmentStages', list);
        resolve();
      });
    },
    getFundingStatuses({ commit }) {
      return new Promise(async (resolve) => {
        const list = await charityApi.getFundingStatuses();
        commit('storeFundingStatuses', list);
        resolve();
      });
    },
    getBoardWorkHours({ commit }) {
      return new Promise(async (resolve) => {
        const list = await charityApi.getBoardWorkHours();
        commit('storeBoardWorkHours', list);
        resolve();
      });
    },
    saveOnboardingState({ commit }, status) {
      return new Promise(async (resolve) => {
        commit('storeOnboardingState', status);
        resolve();
      });
    },
    async getProfileImage({ commit, state }) {
      return new Promise(async (resolve) => {
        const filters = [
          {
            name: 'entityId',
            values: [state.charity.id.toString()],
          },
          {
            name: 'type',
            values: ['profile-image'],
          },
        ];
        const response = await ImageApi.readImages('charity', createPaginationObject(null, null, filters));
        if (!response || !response.images || response.images.length === 0) return;
        const [firstImage] = response.images;

        commit('storeProfileImage', firstImage.image);
        resolve();
      });
    },

  },
  getters: {
    charity: (state) => state.charity,
    onboardingCompleted: (state) => () => {
      const completed = state.onboarded;
      return completed;
    },
  },
};
