<template>
  <v-container fluid>
    <v-row>
      <v-col
        :cols="'6'"
        :class="'pt-2 pb-0'"
      >
        <v-text-field
          :value="charityName"
          label="Organization Name"
          class="custom-disabled-gray"
          disabled
        />
      </v-col>
      <v-col
        :cols="'6'"
        :class="'pt-2 pb-0'"
      >
        <v-text-field
          :value="chariableNumber"
          class="custom-disabled-gray"
          label="Registered Charitable #/Not for Profit #:"
          disabled
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <user-information-form
          ref="userInformationForm"
          :existing-username="userName"
          :existing-firstname="firstName"
          :existing-lastname="lastName"
          :rows="2"
          :show-password-reset="false"
          :hide-password="false"
          :disable-email="true"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import UserInformationForm from '@/components/user/UserInformationForm.vue';

export default {
  components: {
    UserInformationForm,
  },
  props:
  {
    charityName: {
      type: String,
      default: '',
    },
    chariableNumber: {
      type: String,
      default: '',
    },
    userName: {
      type: String,
      default: '',
    },
    firstName: {
      type: String,
      default: '',
    },
    lastName: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    address: null,
    charityAdminUserName: '',
    isReleaseFormAgreed: false,
    repToken: null,
  }),
  methods: {
    async submit() {
      // todo
      this.$refs.userInformationForm.submit();
      this.$emit('verify');
      this.$emit('closeForm');
      this.$bus.$emit('showSuccess', 'Account created successfully.');
    },
    async resetForm() {
      await this.$nextTick();
      this.$refs.userInformationForm.reset();
      if (this.$refs.generalPhoneForm) this.$refs.generalPhoneForm.reset();
    },
    // For Logged In user
    openLogInForm() {
      this.$refs.userLoginDialog.openForm();
    },
    logInSuccess(userData) {
      if (!userData) return;
      this.$bus.$emit('showSuccess', 'Success!');
    },
  },
};
</script>
<style>
.custom-disabled-gray.v-input--is-disabled div div div label {
  color: rgba(0,0,0,0.6);
}
.custom-disabled-gray.v-input--is-disabled div div div input{
  color: rgba(0,0,0,0.6);
}
</style>
