<template>
  <v-container fluid>
    <validation-observer ref="postingValObserver">
      <template v-if="isSignup == false">
        <v-row v-if="showSectionTitles">
          <v-col class="text-h5 font-weight-bold mt-3 pb-0">
            Required Information
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="6"
            class="pb-8"
          >
            <span>Please tell us about yourself:</span>
            <!-- <span
              v-if="!candidateData.about && validationTriggered"
              class="red--text ml-2"
            >Must be filled</span>
            <validation-provider
              v-show="false"
              v-slot="{ errors }"
              :name="'About Candidate'"
              rules="required"
            >
              <v-textarea
                v-model="candidateData.about"
                :error="errors.length > 0"
              />
            </validation-provider> -->
            <vue-editor
              v-model="candidateData.about"
              :editor-toolbar="customToolbar"
            />
          </v-col>
          <v-col
            cols="6"
            class="pb-8"
          >
            Preview:
            <general-text-widget
              title="About Me"
              class="mt-0"
              :html-text="candidateData.about"
              preview
            />
          </v-col>
        </v-row>
        <span>How did you hear about BoardMatch Fundamentals:</span>
        <v-row>
          <v-col
            cols="3"
            class="pb-0 mt-3"
          >
            <!-- <ValidationProvider
              v-slot="{ errors }"
              name="introductionSourceId"
              rules="required"
            >
              <v-select
                v-model="candidateData.candidateSourceOfIntroductionId"
                :error="errors.length > 0"
                class="mt-0 pt-0"
                :items="candidateIntroductionTypes"
                :item-text="(item) => item.name"
                :hide-details="true"
                dense
                item-value="id"
              />
            </ValidationProvider> -->
            <v-select
              v-model="candidateData.candidateSourceOfIntroductionId"
              class="mt-0 pt-0"
              :items="candidateIntroductionTypes"
              :item-text="(item) => item.name"
              :hide-details="true"
              dense
              item-value="id"
            />
          </v-col>
          <v-col
            cols="9"
            class="pb-0 mt-3"
          >
            <!-- <ValidationProvider
              v-slot="{ errors }"
              name="introductionSource"
              rules="required"
            >
              <v-text-field
                v-model="candidateData.sourceOfIntroductionDetails"
                class="mt-0 pt-0"
                :error="errors.length > 0"
                hide-details
                dense
                label="Please specify"
              />
            </ValidationProvider> -->
            <v-text-field
              v-model="candidateData.sourceOfIntroductionDetails"
              class="mt-0 pt-0"
              hide-details
              dense
              label="Please specify"
            />
          </v-col>
        </v-row>
        <v-row v-if="showSectionTitles">
          <v-col class="text-h5 font-weight-bold mt-3 pb-0">
            Optional Information
          </v-col>
        </v-row>
        <v-row class="mt-3">
          <v-col cols="2">
            <span>Profile Image</span>
            <v-img
              :src="userImage ? ('data:' + userImage.mimeType + ';base64,' + userImage.file) : require('@/assets/place_holder.png')"
              max-height="200px"
              max-width="200px"
              style="border-radius: 125px !important;"
              class="mt-5 ml-5"
            >
              <v-hover
                v-slot="{ hover }"
                style="background-color: rgb(0,0,0,0.2);"
              >
                <v-btn
                  height="100%"
                  width="100%"
                  :class="hover ? 'profile-picture-button-hover' : 'profile-picture-button'"
                  @click="changeUserPhoto()"
                >
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </v-hover>
            </v-img>
          </v-col>
          <v-col cols="2">
            <v-icon
              v-if="userImage"
              @click="deleteUserPhoto()"
            >
              mdi-close
            </v-icon>
          </v-col>
          <v-col cols="6">
            <span>Background Image</span>
            <v-img
              :src="backgroundImage ? ('data:' + backgroundImage.mimeType + ';base64,' + backgroundImage.file) : ''"
              max-height="200px"
              style="border: width 200px !important;"
              class="mt-5 ml-5"
            >
              <v-hover
                v-slot="{ hover }"
                style="background-color: rgb(0,0,0,0.2);"
              >
                <v-btn
                  height="100%"
                  width="100%"
                  :class="hover ? 'profile-picture-button-hover' : 'profile-picture-button'"
                  @click="editBackground()"
                >
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </v-hover>
            </v-img>
          </v-col>
        </v-row>
        <v-row class="mt-3">
          <v-col cols="2">
            <v-select
              v-model="candidateData.ageGroupId"
              :items="ageGroups"
              data-vv-name="select"
              :item-text="(age) => ageName(age)"
              item-value="id"
              :readonly="isDisabled"
              :style="isDisabled ? 'pointer-events: none' : ''"
            >
              <template #label>
                Age Group
              </template>
            </v-select>
          </v-col>
        </v-row>
        <span>Please indicate any minority groups which you belong to:</span>
        <v-row class="mt-3 mb-8">
          <v-col
            v-for="(chbx, index) in minorityGroups"
            :key="index"
            cols="3"
            sm="2"
            class="ma-0 pa-0 pl-5"
          >
            <v-checkbox
              :key="'min-' + index"
              v-model="candidateData.candidateToCandidateMinorityGroupIds"
              multiple
              hide-details
              :value="chbx.id"
              :label="chbx.name"
              class="mt-0 compact"
              :readonly="isDisabled"
              :style="isDisabled ? 'pointer-events: none' : ''"
            />
          </v-col>
        </v-row>
        <v-row v-if="showOtherMinority">
          <v-col
            cols="3"
            class="ma-0 pa-0 pl-5 pt-0"
          >
            <v-text-field
              v-model="candidateData.otherMinorityGroup"
              label="Please Specify Other Minority"
              class="mt-0 compact"
              :readonly="isDisabled"
              :style="isDisabled ? 'pointer-events: none' : ''"
            />
          </v-col>
        </v-row>
        <v-row v-if="showSectionTitles">
          <v-col cols="12">
            <span>Please share your social media links here:</span>
            <br>

            <v-row
              v-for="socialMedia in socialMediaSubmit"
              :key="socialMedia.socialMediaTypeId"
              cols="12"
              class="mt-3 pb-0"
            >
              <v-col
                cols="2"
                class="pt-0 pb-0"
                style="display: flex; align-items: end;"
              >
                {{ socialMedia.name }}
              </v-col>
              <v-col
                cols="8"
                class="pa-0"
              >
                <v-text-field
                  v-model="socialMedia.url"
                  dense
                  class="mb-0 pb-1"
                  :hide-details="true"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mt-6">
          <v-col
            cols="11"
            class="ma-0 pa-0 pl-2"
          >
            <v-checkbox
              v-model="candidateData.displayEmail"
              label="Display my email on my profile page"
              :readonly="isDisabled"
              dense
              :style="isDisabled ? 'pointer-events: none' : ''"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="11"
            class="ma-0 pa-0 pl-2"
          >
            <v-checkbox
              v-model="candidateData.acceptCommunications"
              label="Yes, I would like to receive periodic communications from Altruvest on governance topics and events."
              :readonly="isDisabled"
              dense
              :style="isDisabled ? 'pointer-events: none' : ''"
            />
          </v-col>
        </v-row>
      </template>
    </validation-observer>
    <general-dialog
      v-if="candidate"
      ref="userImageSelectorDialog"
      title="Upload a new profile image"
      submit-button-label="Upload"
      dialog-width="600px"
      :suppress-footer="true"
      :submit-button-enabled="false"
      @submit="submitUserImageChange()"
    >
      <user-image-selector-template
        ref="userImageSelector"
        :user-id="candidate.userId"
        @success="loadUserImage()"
      />
    </general-dialog>
    <general-dialog
      v-if="candidate"
      ref="backgroundImageSelectorDialog"
      title="Select or Upload Background Image"
      submit-button-label="Apply Selected Background"
      dialog-width="1750px"
      :submit-button-enabled="false"
      @submit="submitBackgroundImageChange()"
    >
      <candidate-background-image-selector-template
        ref="backgroundImageSelector"
        :candidate="candidate"
        @close="closeDialogs()"
        @backgroundChanged="loadBackgroundImage()"
      />
    </general-dialog>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import {
  extend,
  ValidationObserver,
  // ValidationProvider,
  setInteractionMode,
} from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { VueEditor } from 'vue2-editor';
import GeneralDialog from '@/components/general/GeneralDialog';
import UserImageSelectorTemplate from '@/components/user/UserImageSelectorTemplate';
import { createPaginationObject } from '@/helpers/PaginationHelper.js';
import GeneralTextWidget from '@/components/general/GeneralTextWidget.vue';
import CandidateBackgroundImageSelectorTemplate from '@/components/candidate/profile/CandidateBackgroundImageSelectorTemplate';

setInteractionMode('immediate');
extend('required', {
  ...required,
  message: 'Must select at least one {_field_}',
});

export default {
  components: {
    ValidationObserver,
    // ValidationProvider,
    GeneralDialog,
    UserImageSelectorTemplate,
    CandidateBackgroundImageSelectorTemplate,
    VueEditor,
    GeneralTextWidget,
  },
  props:
  {
    candidate: {
      type: Object,
      default: () => {},
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isSignup: {
      type: Boolean,
      default: false,
    },
    showSectionTitles: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    candidateData: {
      id: 0,
      skillList: [],
      focusAreasList: [],
      userId: 0,
      joinDate: '',
      candidateToCandidateMinorityGroupIds: [],
      candidateRole: [{ id: 1, name: 'Base' }],
      displayEmail: false,
    },
    validationTriggered: false,
    socialMediaSubmit: [],
    showDateDialog: false,
    otherMinorityId: 14, // as per [CandidateMinorityGroup] table
    userImage: null,
    userImageId: null,
    backgroundImage: null,
    customToolbar: [
      ['bold', 'italic', 'underline'],
    ],
  }),
  computed: {
    ...mapState({
      userId: (state) => state.user.nameId,
      ageGroups: (state) => state.candidate.ageGroups,
      minorityGroups: (state) => state.candidate.minorityGroups,
      socialMediaTypes: (state) => state.general.socialMediaTypes,
      candidateIntroductionTypes: (state) => state.candidate.candidateIntroductionTypes,
      storeUser: (state) => state.user,
      isLoggedIn: (state) => state.auth.isLoggedIn,
    }),
    showOtherMinority() {
      const index = this.candidateData.candidateToCandidateMinorityGroupIds.findIndex((x) => x === this.otherMinorityId);
      if (index > -1) return true;
      return false;
    },
  },
  watch: {
    candidate: {
      immediate: true,
      handler(value) {
        if (value && this.candidateData.id === 0) {
          const copy = { ...value };
          const minorityGroupIds = value.candidateMinorityGroup ? copy.candidateMinorityGroup.map((x) => x.id) : [];
          const joinDateFormatted = copy.joinDate == null ? '' : this.$moment(copy.joinDate).format('YYYY-MM-DD'); // original joinDate format is '2022-04-21 00:00:00.000' and we want '2022-04-21'
          this.candidateData = {
            ...value,
            candidateToCandidateMinorityGroupIds: minorityGroupIds,
            joinDate: joinDateFormatted,
          };
          if (this.socialMediaTypes != null && this.socialMediaTypes.length > 0 && value.user != null && value.user.socialMedia != null && value.user.socialMedia.length > 0) {
            const newSocialMedia = value.user.socialMedia;
            this.setSocialMedia(newSocialMedia, this.socialMediaTypes);
          }
        }
      },
    },
    socialMediaTypes: {
      immediate: true,
      handler(value) {
        if (value && this.candidate) {
          const existingLinks = this.candidate.user && this.candidate.user.socialMedia ? this.candidate.user.socialMedia : [];
          this.setSocialMedia(existingLinks, value);
        }
      },
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.isLoggedIn) { this.loadUserImage(); this.loadBackgroundImage(); }
    },
    async submit(userId = null, agreedToTerms = null, skills = [], focusAreas = [], experienceTitle = null, experienceOrganizationName = null, isLookingForPosition = false) {
      try {
        if (agreedToTerms) this.candidateData.acceptTermsAndConditions = agreedToTerms;
        this.candidateData.selectedCandidateSkills = skills;
        this.candidateData.selectedCandidateFocusAreas = focusAreas;
        this.candidateData.experienceTitle = experienceTitle;
        this.candidateData.experienceOrganizationName = experienceOrganizationName;
        this.candidateData.isLookingForPosition = isLookingForPosition;
        if (this.socialMediaSubmit && this.socialMediaSubmit.length) await this.$api.socialMedia.update(this.socialMediaSubmit);
        this.candidateData.userId = userId;
        return this.candidateData.id !== 0 ? await this.$api.candidate.update(this.candidateData) : await this.$api.candidate.create(this.candidateData);
      } catch (err) {
        console.log(err);
      }
      return null;
    },
    reset() {
      this.candidateData = {
        id: 0,
        skillList: [],
        focusAreasList: [],
        userId: 0,
        joinDate: '',
        candidateToCandidateMinorityGroupIds: [],
        candidateRole: [{ id: 1, name: 'Base' }],
        displayEmail: false,
      };
      this.showDateDialog = false;
    },
    ageName(age) {
      if (age.from == null) return `< ${age.to}`;
      if (age.to == null) return `${age.from}+`;
      return `${age.from}-${age.to}`;
    },
    setSocialMedia(existingData, types) {
      this.socialMediaSubmit = types.map((x) => (
        {
          userId: this.userId,
          socialMediaTypeId: x.id,
          url: existingData.findIndex((exl) => exl.socialMediaTypeId === x.id) > -1 ? existingData.find((exl) => exl.socialMediaTypeId === x.id).url : '',
          id: existingData.findIndex((exl) => exl.socialMediaTypeId === x.id) > -1 ? existingData.find((exl) => exl.socialMediaTypeId === x.id).id : '',
          name: x.name,
        }
      ));
    },
    async validate(showError = true) {
      const res = await this.$refs.postingValObserver.validate();
      if (!res) {
        this.validationTriggered = true;
        if (showError) this.$bus.$emit('showError', 'Please enter all required fields marked red');
        return false;
      }
      return true;
    },
    async loadUserImage() {
      const filters = [
        {
          name: 'entityId',
          values: [this.candidate.userId.toString()],
        },
        {
          name: 'type',
          values: ['profile-image'],
        },
      ];
      const response = await this.$api.image.readImages('user', createPaginationObject(null, null, filters));
      if (response.images == null || response.images.length === 0) return;
      const [firstImage] = response.images;
      this.userImageId = firstImage.id;
      this.userImage = firstImage.image;
      this.closeDialogs();
      if (this.candidate.userId.toString() === this.storeUser.nameId.toString()) {
        this.$store.dispatch('user/getProfileImage');
      }
    },
    async loadBackgroundImage() {
      // if (!this.externalCandidateId && !this.candidateId) return;
      const filters = [
        {
          name: 'entityId',
          values: [this.candidate.id.toString()],
        },
        {
          name: 'type',
          values: ['background-image'],
        },
      ];
      const response = await this.$api.image.readImages('candidate', createPaginationObject(null, null, filters));
      if (response.images == null || response.images.length === 0) return;
      const [firstImage] = response.images;
      // the background image is rendered at the app level, so we emit it up the chain
      this.backgroundImage = firstImage.image;
      this.closeDialogs();
      if (this.candidate.userId.toString() === this.storeUser.nameId.toString()) {
        this.$store.dispatch('user/getBackgroundImage');
      }
      // this.$emit('updateBackgroundImage', 'candidate', (this.externalCandidateId ? this.externalCandidateId.toString() : this.candidateId.toString()));
    },
    submitBackgroundImageChange() {
      this.$refs.backgroundImageSelector.submit();
    },
    editBackground() {
      this.$refs.backgroundImageSelectorDialog.openForm();
    },
    changeUserPhoto() {
      this.$refs.userImageSelectorDialog.openForm();
    },
    deleteUserPhoto() {
      if (!confirm('Are you sure you want to delete your picture?')) return;
      this.$api.image.softDelete(this.userImageId, `/user/${this.candidate.userId}`);
      this.userImage = null;
      this.$store.dispatch('user/deleteProfileImage');
    },
    closeDialogs() {
      if (this.$refs.backgroundImageSelectorDialog) this.$refs.backgroundImageSelectorDialog.closeForm();
      if (this.$refs.userImageSelectorDialog) this.$refs.userImageSelectorDialog.closeForm();
    },

  },
};
</script>
