<template>
  <v-container
    fluid
    class="pb-2"
  >
    <!-- <v-slide-x-reverse-transition>
      <div
        v-if="activeComponent != 'searchTemplate'"
        :class="activeComponent == 'charityListingTemplate' ? 'return-button-posting' : 'return-button'"
        style="z-index: 4;"
      >
        <v-btn @click="showSearch()">
          Return to Search
        </v-btn>
      </div>
    </v-slide-x-reverse-transition> -->
    <v-slide-x-transition>
      <search-template
        v-show="activeComponent == 'searchTemplate'"
        ref="searchTemplate"
        :existing-applications="existingApplications"
        @triggerSearch="triggerSearch()"
        @showCharity="(charityId) => showCharity(charityId)"
        @showCandidate="(candidateId) => showCandidate(candidateId)"
        @showCharityPosting="(charityPostingId) => showCharityPosting(charityPostingId)"
        @showCharityPostingByCharity="(charityId) => showCharityPostingByCharity(charityId)"
      />
    </v-slide-x-transition>
    <v-slide-x-transition>
      <charity-listings-template
        v-show="activeComponent == 'charityListingTemplate'"
        ref="charityListingTemplate"
        :charity-posting-id="selectedCharityPostingId"
        :charity-id="selectedCharityId"
        :existing-applications="existingApplications"
        @loadExistingApplications="loadExistingApplications()"
        @updateBackgroundImage="(entity, id) => updateBackgroundImage(entity, id)"
      />
    </v-slide-x-transition>
    <v-slide-x-transition>
      <charity-profile-template
        v-show="activeComponent == 'charityProfileTemplate'"
        ref="charityProfileTemplate"
        :charity-id="selectedCharityId"
        @updateBackgroundImage="(entity, id) => updateBackgroundImage(entity, id)"
      />
    </v-slide-x-transition>
    <v-slide-x-transition>
      <candidate-profile-template
        v-show="activeComponent == 'candidateProfileTemplate'"
        ref="candidateProfileTemplate"
        :candidate-id="selectedCandidateId"
        @updateBackgroundImage="(entity, id) => updateBackgroundImage(entity, id)"
      />
    </v-slide-x-transition>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import { createPaginationObject } from '@/helpers/PaginationHelper.js';
import SearchTemplate from '@/components/search/SearchTemplate';
import CharityProfileTemplate from '@/components/charity/profile/CharityProfileTemplate.vue';
import CandidateProfileTemplate from '@/components/candidate/profile/CandidateProfileTemplate.vue';
import CharityListingsTemplate from '../components/charity/listings/CharityListingsTemplate.vue';

export default {
  components: {
    SearchTemplate,
    CharityProfileTemplate,
    CandidateProfileTemplate,
    CharityListingsTemplate,
  },
  data: () => ({
    activeComponent: 'searchTemplate',
    selectedCharityId: null,
    selectedCharityPostingId: null,
    selectedCandidateId: null,
    existingApplications: [],
  }),
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.auth.isLoggedIn,
      involvement: (state) => state.user.selectedInv,
      candidate: (state) => state.candidate.candidate,
    }),
  },

  created() {
    this.init();
  },
  methods: {
    init() {
      this.loadExistingApplications();
    },
    async loadExistingApplications() {
      if (this.candidate) {
        this.loadingExistingApplications = true;
        const filters = [];
        filters.push({ name: 'candidateId', values: [this.candidate.id] });
        const result = await this.$api.candidateApplications.read(createPaginationObject(null, null, filters));
        this.existingApplications = result.applications;
        this.loadingExistingApplications = false;
      }
    },
    showCharity(charityId) {
      this.selectedCharityId = charityId;
      this.activeComponent = 'charityProfileTemplate';
      this.selectedCandidateId = null;
      this.selectedCharityPostingId = null;
    },
    showCandidate(candidateId) {
      this.selectedCandidateId = candidateId;
      this.activeComponent = 'candidateProfileTemplate';
      this.selectedCharityId = null;
      this.selectedCharityPostingId = null;
    },
    showCharityPosting(charityPostingId) {
      this.selectedCharityPostingId = charityPostingId;
      this.activeComponent = 'charityListingTemplate';
      this.selectedCharityId = null;
      this.selectedCandidateId = null;
    },
    showCharityPostingByCharity(charityId) {
      this.selectedCharityPostingId = null;
      this.activeComponent = 'charityListingTemplate';
      this.selectedCharityId = charityId;
      this.selectedCandidateId = null;
    },
    showSearch() {
      this.activeComponent = 'searchTemplate';
      this.selectedCandidateId = null;
      this.selectedCharityId = null;
      this.selectedCharityPostingId = null;
      this.$refs.searchTemplate.loadTypes();
      this.$emit('updateBackgroundImage', null, null);
    },
    updateBackgroundImage(entity, id) {
      if (this.activeComponent === 'charityProfileTemplate' || this.activeComponent === 'candidateProfileTemplate' || this.activeComponent === 'charityListingTemplate') { this.$emit('updateBackgroundImage', entity, id); }
    },
    triggerSearch() {
      if (this.$refs && this.$refs.searchTemplate) this.$refs.searchTemplate.searchEntries(null, 'init');
      this.showSearch();
    },
  },
};
</script>

<style>
  .return-button {
    position: absolute;
    left: 30px;
    top: 30px;
    z-index: 4
  }
  .return-button-posting {
    position: absolute;
    left: 10px;
    top: 25px;
    z-index: 4
  }
</style>
