/* eslint-disable no-console */
import axios from 'axios';
import Api from '@/api/Api';

class AuthenticationApi extends Api {
  constructor() {
    super('authentication', 'authentication');
  }

  async authenticate(userName, password) {
    const res = {
      error: null,
      message: '',
      response: null,
    };

    const errorText = 'Username or Password is incorrect';

    const formData = new FormData();
    formData.append('UserName', userName);
    formData.append('Password', password);

    await axios.post(`/${this.endpoint}`, formData)
      .then((response) => {
        res.response = response.data;
      })
      .catch((error) => {
        res.error = true;
        console.log(error);
        if (error.response) {
        // Request made and server responded
          res.message = error.response.data.message;
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
        // The request was made but no response was received
          console.log(error.request);
          res.message = 'Unable to connect to server.';
        } else {
        // Something happened in setting up the request that triggered an Error
          res.message = 'Connection Failed.';
          console.log('Error', error.message);
        }
        console.log(errorText);
      });
    return res;
  }

  async refreshToken(token, refreshToken) {
    let res;

    const errorText = 'token or refresh token is incorrect';

    const formData = new FormData();
    formData.append('token', token);
    formData.append('refreshToken', refreshToken);

    await axios.post(`/${this.endpoint}/refresh-token`, formData)
      .then((response) => {
        res = response.data;
      })
      .catch((error) => {
        if (error.response) {
        // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
        // The request was made but no response was received
          console.log(error.request);
        } else {
        // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(errorText);
        res = false;
      });
    return res;
  }

  async authData(userName, password) {
    let res;

    const errorText = 'Username or Password is incorrect';

    const formData = new FormData();
    formData.append('UserName', userName);
    formData.append('Password', password);

    await axios.post(`/${this.endpoint}/user-data`, formData)
      .then((response) => {
        res = response.data;
      })
      .catch((error) => {
        if (error.response) {
        // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
        // The request was made but no response was received
          console.log(error.request);
        } else {
        // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(errorText);
        res = false;
      });
    return res;
  }

  async forgotPassword(userName) {
    let res;

    const errorText = 'Forgot Password Failed';

    const formData = new FormData();
    formData.append('UserName', userName);

    await axios.post(`/${this.endpoint}/forgot-password`, formData)
      .then((response) => {
        res = response.data;
      })
      .catch((error) => {
        if (error.response) {
        // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
        // The request was made but no response was received
          console.log(error.request);
        } else {
        // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(errorText);
        res = false;
      });
    return res;
  }

  async resetPassword(token, password) {
    let res;
    const errorText = 'Reset Password Failed';

    const formData = new FormData();
    formData.append('Token', token);
    formData.append('Password', password);

    await axios.post(`/${this.endpoint}/reset-password`, formData)
      .then((response) => {
        res = response.data;
      })
      .catch((error) => {
        if (error.response) {
        // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
        // The request was made but no response was received
          console.log(error.request);
        } else {
        // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(errorText);
        res = false;
      });
    return res;
  }

  async verification(token) {
    let res;
    const errorText = 'Verification Failed';
    await axios.post(`/${this.endpoint}/verification?token=${token}`)
      .then((response) => {
        res = response.data;
      })
      .catch((error) => {
        if (error.response) {
        // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
        // The request was made but no response was received
          console.log(error.request);
        } else {
        // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(errorText);
        res = false;
      });
    return res;
  }

  async resendVerification(userName) {
    let res;
    const errorText = 'Resend Verification Failed';
    const formData = new FormData();
    formData.append('UserName', userName);
    await axios.post(`/${this.endpoint}/resend-verification`, formData)
      .then((response) => {
        res = response.data;
      })
      .catch((error) => {
        if (error.response) {
        // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
        // The request was made but no response was received
          console.log(error.request);
        } else {
        // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(errorText);
        res = false;
      });
    return res;
  }

  async checkResetPassword(token) {
    let res;
    const errorText = 'Reset Password Failed';

    const formData = new FormData();
    formData.append('Token', token);

    await axios.post(`/${this.endpoint}/check-reset-password`, formData)
      .then((response) => {
        res = response.data;
      })
      .catch((error) => {
        if (error.response) {
        // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
        // The request was made but no response was received
          console.log(error.request);
        } else {
        // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(errorText);
        res = false;
      });
    return res;
  }

  async authenticateAs(targetUserId, adminUsername, adminPassword) {
    let res;

    const formData = new FormData();
    formData.append('targetUserId', targetUserId);
    formData.append('adminUsername', adminUsername);
    formData.append('adminPassword', adminPassword);

    await axios.post(`/${this.endpoint}/authenticate-as-user`, formData)
      .then((response) => {
        res = response.data;
      })
      .catch((error) => {
        if (error.response) {
        // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
        // The request was made but no response was received
          console.log(error.request);
        } else {
        // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        res = false;
      });
    return res;
  }
}

const authenticationApi = new AuthenticationApi();
export default authenticationApi;
