// noinspection ES6UnusedImports
import Vue from 'vue';
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import store from '../store';
import router from '../router/index';

const refreshAuthLogic = (failedRequest) => store.dispatch('auth/refreshToken').then((tokenRefreshResponse) => {
  if (tokenRefreshResponse && tokenRefreshResponse.token && tokenRefreshResponse.token !== '') {
    localStorage.setItem('token', tokenRefreshResponse.token);
    // eslint-disable-next-line no-param-reassign
    failedRequest.response.config.headers.Authorization = `Bearer ${tokenRefreshResponse.token}`;
  }
  return Promise.resolve();
}, () => {
  // this happens on token refresh failure
  store.dispatch('auth/logout').then(() => {
    // only reroute back to login if the current page requires authorization
    const route = router.options.routes.find((r) => r.path === window.location.pathname);
    if (route == null || route.meta == null || route.meta.requiresAuth !== false) router.push('/login');
  });
});
const interceptOptions = {
  retryInstance: axios,
};
createAuthRefreshInterceptor(
  axios,
  refreshAuthLogic,
  interceptOptions,
);

axios.defaults.baseURL = process.env.VUE_APP_API;
axios.defaults.headers.common.Accept = 'application/json';

axios.interceptors.request.use((request) => {
  const token = store.getters['auth/userToken'];
  request.headers.Authorization = `Bearer ${token}`;
  return request;
});

Vue.$http = axios;
Object.defineProperty(Vue.prototype, '$http', {
  get() {
    return axios;
  },
});
