<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col
          v-if="charityImage"
          cols="2"
          class="pa-5 pr-0 d-flex justify-center"
        >
          <v-img
            :src="('data:' + charityImage.mimeType + ';base64,' + charityImage.file)"
            max-height="200px"
            max-width="180px"
            contain
          >
            <v-hover
              v-slot="{ hover }"
              style="background-color: rgb(0,0,0,0.2);"
            >
              <v-btn
                v-if="editing"
                height="100%"
                width="100%"
                :class="hover ? 'profile-picture-button-hover' : 'profile-picture-button'"
                @click="changePhoto()"
              >
                <v-icon>
                  mdi-pencil
                </v-icon>
              </v-btn>
            </v-hover>
          </v-img>
        </v-col>
        <v-col :cols="charityImage ? 10 : 12"
          style="padding: 20px; padding-bottom: 10px;"
          >
          <v-row>
            <v-col cols="12" class="d-flex align-center">
              <span
                class="font-weight-bold text-h4"
                style="margin-bottom: 5px;"
              >{{ fullName }}</span>

            </v-col>
            <v-col
              cols="4"
              style="padding: 20px; padding-top: 10px;"
            >
              <div
                style="display: grid; align-content: center;"
              >
                <template v-if="charity.charityType">
                  <!-- <br> -->
                  <span>
                    {{ charity.charityType.name }}
                  </span>
                </template>
                <!-- <br> -->

                <span
                  style="margin-top: 5px;"
                >
                  <v-icon
                    small
                    style="margin-bottom: 2px;"
                  >
                    mdi-crosshairs-gps
                  </v-icon>
                  {{ location }}
                </span>
                <span
                  v-if="charity.phones"
                  style="margin-top: 5px;"
                >
                  <v-icon
                    small
                    style="margin-bottom: 2px;"
                  >
                    mdi-phone
                  </v-icon>
                  {{ phoneFormatter(charity.phones[0].number) }}
                </span>
                <span v-if="charity.email && charity.email.name">
                  <v-icon
                    small
                    style="margin-bottom: 2px;"
                  >
                    mdi-email-outline
                  </v-icon>
                  <a
                    :href="'mailto:' + charity.email.name"
                    class="text-decoration-none"
                  >
                    {{ charity.email.name }}</a>
                </span>
                <span
                  v-if="charity.website"
                  style="margin-top: 5px;"
                >
                  <v-icon
                    small
                    style="margin-bottom: 2px;"
                  >
                    mdi-web
                  </v-icon>
                  <a
                    :href="'https://' + charity.website"
                    target="_blank"
                    class="text-decoration-none"
                  >
                    {{ charity.website }}
                  </a>
                </span>
              </div>
            </v-col>
            <v-col
              v-if="charity.quote && charity.quote != ''"
              cols="8"
              :lg="charityImage ? '' : '7'"
              class="pa-5 flex-grow-1 flex-shrink-0 d-flex"
              style="display: grid; align-content: center;"
            >
              <div
                style="display: grid; align-content: center;"
              >
                <general-quote-widget
                  :html-text="charity.quote"
                  :image-exist="charityImage ? true : false"
                />
              </div>
            </v-col>
          </v-row>

        </v-col>
      </v-row>
      <v-row>
        <v-col class="ml-3">
          <span
            v-if="charity.focusAreas && charity.focusAreas.length"
            class="subtitle-3 font-weight-bold"
            style="color: rgb(100, 100, 100);"
          >Focus Areas:</span>
          <v-chip
            v-for="(focusArea, index) in charity.focusAreas"
            :key="'focus-area-chip-' + index"
            color="success"
            class="mt-1 ml-1 mr-0 mb-1"
          >
            {{ '#' + focusArea.name }}
          </v-chip>
        </v-col>
      </v-row>
      <general-dialog
        v-if="charity"
        ref="charityImageSelectorDialog"
        title="Upload a new profile image"
        submit-button-label="Upload"
        dialog-width="600px"
        :submit-button-enabled="false"
        @submit="submitCharityImageChange()"
      >
        <charity-image-selector-template
          ref="charityImageSelector"
          :charity-id="charity.id"
          @success="loadCharityImage()"
        />
      </general-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';
import GeneralDialog from '@/components/general/GeneralDialog';
import GeneralQuoteWidget from '@/components/general/GeneralQuoteWidget';
import CharityImageSelectorTemplate from '@/components/charity/profile/CharityImageSelectorTemplate';
import { createPaginationObject } from '@/helpers/PaginationHelper.js';
import { phoneFormatter } from '@/helpers/FormatHelper.js';

export default {
  components: {
    GeneralDialog,
    CharityImageSelectorTemplate,
    GeneralQuoteWidget,
  },

  props:
  {
    charity: {
      type: Object,
      default: () => {},
    },
    editing: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    charitySubmit: null,
    charityImage: null,
  }),
  computed: {
    ...mapState({
      storeCharity: (state) => state.charity.charity,
    }),
    fullName() {
      if (!this.charity || !this.charity.name) return '';
      return `${this.charity.name}`;
    },
    location() {
      if (!this.charity || !this.charity.address) return '';
      return `${this.charity.address.address1 ? `${this.charity.address.address1}, ` : ''} ${this.charity.address.address2 ? `${this.charity.address.address2}, ` : ''} ${this.charity.address.city}, ${this.charity.address.region ? this.charity.address.region.abbreviation : ''} ${this.charity.address.code ? this.charity.address.code : ''} ${this.charity.address.country ? this.charity.address.country.name : ''}`;
    },
  },
  watch: {
    charity: {
      immediate: true,
      handler(value) {
        this.loadCharityImage();
        if (!value && !this.editable) return;
        this.charitySubmit = value;
      },
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.loadCharityImage();
    },
    async removeFocusArea(focusArea, index) {
      if (this.charitySubmit.focusAreas.length < 2) {
        this.$bus.$emit('showError', 'Must have at least one Area of Focus in your Charity Profile');
        return;
      }
      if (!confirm(`Are you sure you want to unlink ${focusArea.name} from your profile?`)) return;
      const res = await this.$api.charity.unlink(this.charity.id, 'focusArea', focusArea.id);
      if (res === true) {
        this.charitySubmit.focusAreas = this.charitySubmit.focusAreas.splice(index, 1);
      }
    },
    phoneFormatter(str) {
      return phoneFormatter(str);
    },
    changePhoto() {
      this.$refs.charityImageSelectorDialog.openForm();
    },
    submitCharityImageChange() {
      this.$refs.charityImageSelector.submit();
    },
    async loadCharityImage() {
      const filters = [
        {
          name: 'entityId',
          values: [this.charity.id.toString()],
        },
        {
          name: 'type',
          values: ['profile-image'],
        },
      ];
      const response = await this.$api.image.readImages('charity', createPaginationObject(null, null, filters));
      if (response.images == null || response.images.length === 0) {
        this.charityImage = null;
        this.$emit('imageLoaded', this.charityImage);
        return;
      }
      const [firstImage] = response.images;
      this.charityImage = firstImage.image;
      this.closeDialogs();
      if (this.storeCharity && this.storeCharity.id && this.charity.id.toString() === this.storeCharity.id.toString()) {
        this.$store.dispatch('charity/getProfileImage');
      }
      this.$emit('imageLoaded', this.charityImage);
    },
    closeDialogs() {
      this.$refs.charityImageSelectorDialog.closeForm();
    },
    async loadFile(id, fileName) {
      await this.$api.file.getFile(id, fileName);
    },
    workDetails(charity) {
      if (!charity
        || !charity.charityQualifications
        || charity.charityQualifications.charityExperience == null
        || !charity.charityQualifications.charityExperience.length) return '';
      const presentExperience = charity.charityQualifications.charityExperience.find((x) => x.end == null);
      if (presentExperience == null) return '';
      return `${presentExperience.title} at ${presentExperience.organizationName}`;
    },
    formatDate(datetime) {
      return this.$moment(datetime).format('YYYY-MM-DD');
    },
  },
};
</script>
<style>
  .profile-picture-button{
    background-color: rgb(0,0,0,0.2) !important;
  }
  button.profile-picture-button-hover span i.v-icon.notranslate.mdi {
    width: 100px !important;
    height: 101px !important;
    font-size: 70px;
    background-color: transparent;
    color: rgb(0,0,0,0.5);
  }
  button.profile-picture-button span i.v-icon.notranslate.mdi {
    background-color: transparent;
    color: transparent;
  }
</style>
