<template>
  <v-container>
    <v-row justify="center">
      <v-dialog
        v-model="showDialog"
        max-width="500px"
      >
        <v-card class="elevation-1">
          <v-card-title>
            Authenticate
          </v-card-title>
          <v-card-text>
            <validation-observer
              ref="validationObserver"
            >
              <validation-provider
                v-if="isCharity"
                v-slot="{ errors }"
                name="Rep"
                rules="required"
              >
                <v-select

                  v-model="charityRep"
                  name="Rep"
                  :items="charityReps"
                  :class="`icon-style`"
                  :error-messages="errors"
                  :item-text="(item) => `${item.user.userName} (${item.charityRepresentativeRole.length > 0 ? item.charityRepresentativeRole[0].name : ''})`"
                  prepend-icon="mdi-account-circle"
                  return-object
                >
                  <template #label>
                    Charity Representative
                  </template>
                </v-select>
              </validation-provider>
              <p>Enter your Boardmatch Administrator Credentials</p>
              <validation-provider
                v-slot="{ errors }"
                name="Email"
                rules="required"
              >
                <v-text-field
                  v-model="adminUsername"
                  prepend-icon="mdi-account"
                  name="Email"
                  label="Admin Email"
                  type="text"
                  :error-messages="errors"
                />
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="password"
                rules="required"
              >
                <v-text-field
                  v-model="adminPassword"
                  prepend-icon="mdi-lock"
                  name="password"
                  label="Password"
                  type="password"
                  :error-messages="errors"
                />
              </validation-provider>
              <v-col class="text-right">
                <v-btn
                  class="mr-4"
                  text
                  @click="showDialog = false"
                >
                  cancel
                </v-btn>
                <v-btn
                  ref="submitButton"
                  class="mr-4"
                  color="success"
                  @click="submit"
                >
                  submit
                </v-btn>
              </v-col>
            </validation-observer>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { required } from 'vee-validate/dist/rules';
import {
  extend, ValidationObserver, setInteractionMode, ValidationProvider,
} from 'vee-validate';

setInteractionMode('eager');

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
});
export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    isCharity: {
      type: Boolean,
      default: false,
    },
    charityId: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    showDialog: false,
    targetUser: null,
    adminUsername: '',
    adminPassword: '',
    submitted: false,
    charityReps: [],
    charityRep: null,
  }),
  computed: {
    ...mapState({
      currentUser: (state) => state.user,
      userState: (state) => state.user.userState,
      userInvolvements: (state) => state.user.sortedUserInvs,
    }),
    ...mapGetters({
      findClaim: 'auth/findClaim',
    }),
  },
  watch: {
    charityId: {
      immediate: true,
      handler(value) {
        if (!value) return;
        this.getCharityReps();
      },
    },
    charityRep: {
      immediate: true,
      handler(value) {
        if (!value) return;
        this.targetUser = this.charityRep;
      },
    },
  },
  methods: {
    openDialog(targetUser) {
      this.targetUser = targetUser;
      this.adminUsername = this.currentUser && this.currentUser.userInfo ? this.currentUser.userInfo.userName : '';
      this.showDialog = true;
    },
    async getCharityReps() {
      this.charityReps = await this.$api.charityRep.getCharityRepsByCharityId(this.charityId);
    },
    openLoginAsCharityRep() {
      // this.targetUser = this.charityRep;
      this.adminUsername = this.currentUser && this.currentUser.userInfo ? this.currentUser.userInfo.userName : '';
      this.showDialog = true;
    },
    async submit() {
      if (!await this.$refs.validationObserver.validate()) return;
      const targetUserId = this.targetUser.userId;
      const userId = targetUserId;
      this.$store.dispatch('auth/storeIsCharity', this.isCharity);
      this.$store
        .dispatch('auth/loginAs', { targetUserId, adminUsername: this.adminUsername, adminPassword: this.adminPassword })
        .then(() => {
          this.$store
            .dispatch('user/getUserState', userId)
            .then(() => {
              let baseUrl = this.userState.lastState;
              if (!baseUrl) baseUrl = this.getBaseUrl;
              if (this.$route.path !== baseUrl) this.$router.push(`${baseUrl}`).catch(() => {});
            // if (this.isCharity) this.$router.push('/charity-onboarding');
            // else this.$router.push('/candidate-onboarding');
            // location.reload();
            });
        })
        .catch((err) => {
          this.$bus.$emit('showError', 'Email or password is incorrect');
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
  },
};
</script>
<style>
.icon-style .v-input__prepend-outer {
  margin-top: 10px;
  margin-bottom: 0px;
}
</style>
