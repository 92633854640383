<template>
  <div>
    <v-row>
      <v-col class="pl-7 pb-6 pt-8">
        <v-tabs
          v-model="postingsTab"
          center-active
        >
          <v-tab>Posted Positions</v-tab>
          <v-tab>Applications</v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col
        cols="3"
        class="pl-7 pb-6 pt-6"
      >
        <v-select
          v-model="selectedFilterPosition"
          :items="charityPositionTypes"
          label="Position"
          item-text="name"
          item-value="id"
          clearable
          hide-details
        />
      </v-col>
      <v-col
        cols="3"
        class="pb-6 pt-6"
      >
        <v-select
          v-if="postingsTab == 1"
          v-model="selectedFilterPosting"
          :items="postingFilters"
          :disabled="!postingFilters.length"
          label="Posting"
          :item-text="(item) => `${item.charityPosition.name} (${item.id})`"
          item-value="id"
          clearable
          hide-details
        />
      </v-col>
      <v-col class="text-end pr-7 pb-6 pt-8">
        <v-btn
          color="primary"
          outlined
          class="mr-2"
          @click="openNewPositionDialog"
        >
          <v-icon
            small
            class="mr-1"
          >
            mdi-plus
          </v-icon>
          New Position
        </v-btn>
        <v-btn
          color="primary"
          outlined
          @click="openPostingCreateDialog"
        >
          <v-icon
            small
            class="mr-1"
          >
            mdi-plus
          </v-icon>
          New Position Posting
        </v-btn>
      </v-col>
    </v-row>
    <charity-posting-dialog
      ref="postingDialog"
      :title="postingTitle"
      :title-icon="postingTitleIcon"
      :posting="posting"
      :charity="charity"
      @posting-success="notifySuccess"
    />
    <candidate-application-dialog
      v-if="postingsTab == 1"
      ref="applicationDialog"
      :show-candidate-details="false"
      :title="applicationTitle"
      :posting="posting"
      :application="application"
    >
      <template
        v-if="application != null && canDecline(application)"
        slot-scope="{item}"
      >
        <v-btn
          class="ma-1 mr-4"
          color="error"
          @click="updateStatus(item.id, declineStatusId)"
        >
          Decline
        </v-btn>
        <v-btn
          v-if="item.applicationStatus != null && item.applicationStatus.id != underReviewStatusId && item.applicationStatus"
          class="ma-1 mr-4"
          color="secondary"
          @click="updateStatus(item.id, underReviewStatusId)"
        >
          Under Review
        </v-btn>
        <v-btn
          v-if="item.applicationStatus != null && item.applicationStatus.id != interviewStatusId"
          class="ma-1 mr-4"
          color="primary"
          @click="updateStatus(item.id, interviewStatusId)"
        >
          Request Interview
        </v-btn>
        <v-btn
          v-if="item.applicationStatus != null && item.applicationStatus.id != offerExtendedStatusId"
          class="ma-1 mr-4"
          color="success"
          @click="updateStatus(item.id, offerExtendedStatusId)"
        >
          Extend Offer
        </v-btn>
      </template>
    </candidate-application-dialog>
    <charity-posting-table
      v-if="postingsTab == 0"
      ref="postingTable"
      :include-charity-column="true"
      :charity="charity"
      :filters="filters"
      @navigateToApplication="navigateToApplication"
    >
      <template slot-scope="{item}">

        <a
          v-if="!positionExpired(item.expiryDate)"
          class="font-weight-bold"
          @click="showShareCharityPosting(item.id)"
        >SHARE</a>
        <a
          class="ml-1"
          @click="showCharityPosting(item.id)"
        >VIEW</a>
        <a
        class="ml-1"
          @click="openPostingUpdateDialog(item)"
        >EDIT</a>
        <a @click="softDelete(item.id)" class="ml-1">DELETE</a>
      </template>
    </charity-posting-table>
    <candidate-applications-table
      v-if="postingsTab == 1"
      ref="applicationsTable"
      viewer="charity"
      :charity="charity"
      :filters="filters"
    >
      <template slot-scope="{item}">
        <a @click="viewApplication(item)">VIEW</a>
        <template v-if="canDecline(item)">
          <!--<a class='mr-2' v-if="item.applicationStatus.id != interviewStatusId" @click="updateStatus(item.id, interviewStatusId)">INTERVIEW</a>
          <a class='mr-2' v-if="item.applicationStatus.id != underReviewStatusId" @click="updateStatus(item.id, underReviewStatusId)">REVIEW</a>
          <a class='mr-2' v-if="item.applicationStatus.id != offerExtendedStatusId" @click="updateStatus(item.id, offerExtendedStatusId)">OFFER</a>
          <a @click="updateStatus(item.id, declineStatusId)">DECLINE</a>-->
        </template>
      </template>
    </candidate-applications-table>
    <general-dialog
      ref="newPositionDialog"
      title="New Position"
      dialog-width="500px"
      @submit="submitNewPosition"
    >
      <charity-new-position-form
        ref="newPositionForm"
        :charity="charity"
        @closeNewPositionDialog="closeNewPositionDialog"
        @newPositionAdded="getCharityPositions()"
      />
    </general-dialog>
    <general-dialog
      ref="postingShareDialog"
      dialog-width="1280px"
      @submit="sendPostingShareEmail()"
      :title="`You are sharing Posting #${selectedPosting ? selectedPosting.id : ''}`"
    >
      <posting-share-widget
        ref="postingShareWidget"
        :charity-posting="selectedPosting"
        :applied="true"
        class="pa-4"
        @sent="closeShareDialog()"
      />
    </general-dialog>

    <general-dialog
      ref="postingSummaryDialog"
      dialog-width="1280px"
      hide-submit
      :show-title="false"
    >
      <posting-summary-widget
        :charity-posting="selectedPosting"
        :applied="true"
        class="pa-4"
      />
    </general-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';// import { mapGetters, mapState, mapMutations } from 'vuex';
import CharityPostingDialog from '@/components/charity/charityPosting/CharityPostingDialog';
import CharityNewPositionForm from '@/components/charity/charityPosition/CharityNewPositionForm.vue';
import CandidateApplicationDialog from '@/components/candidate/application/CandidateApplicationDialog';
import CharityPostingTable from '@/components/charity/charityPosting/CharityPostingTable';
import CandidateApplicationsTable from '@/components/candidate/application/CandidateApplicationsTable';
import GeneralDialog from '@/components/general/GeneralDialog';
import { createPaginationObject } from '@/helpers/PaginationHelper.js';
import PostingShareWidget from '../listings/PostingShareWidget.vue';
import PostingSummaryWidget from '../listings/PostingSummaryWidget.vue';

export default {
  components: {
    CharityPostingDialog,
    CandidateApplicationDialog,
    CharityPostingTable,
    CandidateApplicationsTable,
    CharityNewPositionForm,
    GeneralDialog,
    PostingShareWidget,
    PostingSummaryWidget,
  },
  props: {
    charity: {
      type: Object,
      default: () => {},
    },
    positionFilter: {
      type: String,
      default: '',
    },
    postingFilter: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    acceptStatusId: 1,
    candidateDeclineStatusId: 6,
    declineStatusId: 7,
    interviewStatusId: 12,
    offerExtendedStatusId: 14,
    underReviewStatusId: 15,

    postingTitle: '',
    postingTitleIcon: [],
    applicationTitle: '',
    selectedPosting: null,
    application: {},
    posting: null,
    charityPositionTypes: [],
    // selectedFilterPosition: '',
    // selectedFilterPosting: '',
    options: null,
    // postingsTab: 0,
    postings: [],
  }),

  computed: {
    ...mapState({
      storeFilterPosition: (state) => state.charityPosting.selectedPositionId,
      storeFilterPosting: (state) => state.charityPosting.selectedPostingId,
      storePostingsTab: (state) => state.charityPosting.selectedTab,
    }),
    selectedFilterPosition: {
      get() {
        return this.storeFilterPosition;
      },
      set(value) {
        this.$store.dispatch('charityPosting/setSelectedPositionId', value);
      },
    },
    selectedFilterPosting: {
      get() {
        return this.storeFilterPosting;
      },
      set(value) {
        this.$store.dispatch('charityPosting/setSelectedPostingId', value);
      },
    },
    postingsTab: {
      get() {
        return this.storePostingsTab;
      },
      set(value) {
        this.$store.dispatch('charityPosting/setSelectedTab', value);
      },
    },
    filters() {
      const returnFilters = [];

      if (this.charity && this.charity.id) {
        returnFilters.push({ name: 'charityId', values: [this.charity.id.toString()] });
      }

      if (this.selectedFilterPosition) {
        returnFilters.push({ name: 'positionId', values: [this.selectedFilterPosition] });
      }

      if (this.selectedFilterPosting) {
        returnFilters.push({ name: 'postingId', values: [this.selectedFilterPosting] });
      }
      // const isDeactivated = 0;
      // returnFilters.push({ name: 'isDisabled', values: [isDeactivated] });

      return returnFilters;
    },
    postingFilters() {
      if (this.selectedFilterPosition == null || this.selectedFilterPosition === '') return [];
      return this.postings.filter((posting) => posting.charityPositionId === this.selectedFilterPosition);
    },
  },
  watch: {
    selectedFilterPosition(value) {
      if (value == null || value === '') {
        this.selectedFilterPosting = '';
      }
    },
  },

  created() {
    if (this.positionFilter) this.selectedFilterPosition = this.positionFilter;
    if (this.postingFilter) this.selectedFilterPosting = this.postingFilter;
    this.getCharityPositions();
    this.getCharityPostings();
  },
  methods: {
    showCharityPosting(id) {
      if (this.postings) {
        this.selectedPosting = this.postings.find((x) => x.id === id);
        if (this.selectedPosting) {
          this.$refs.postingSummaryDialog.openForm();
        }
      }
    },
    showShareCharityPosting(id) {
      if (this.postings) {
        this.selectedPosting = this.postings.find((x) => x.id === id);
        if (this.selectedPosting) {
          this.$refs.postingShareDialog.openForm();
        }
      }
    },
    navigateToApplication(postingId) {
      this.postingsTab = 1;
      this.selectedFilterPosting = postingId;
    },
    openPostingCreateDialog() {
      this.postingTitle = ' New Position Posting';
      this.postingTitleIcon = ['fabs', 'circle-plus'];
      this.posting = null;
      this.$refs.postingDialog.openPostingDialog();
    },
    openNewPositionDialog() {
      this.$refs.newPositionDialog.openForm();
    },
    openPostingUpdateDialog(posting) {
      this.postingTitle = 'Update Position Posting';
      this.posting = posting;
      this.$refs.postingDialog.openPostingDialog();
    },
    submitNewPosition() {
      this.$refs.newPositionForm.submitNewPosition();
    },
    closeNewPositionDialog() {
      this.$refs.newPositionDialog.closeForm();
    },
    closeShareDialog() {
      this.$refs.postingShareDialog.closeForm();
    },
    viewApplication(application) {
      this.applicationTitle = 'Application';
      this.application = application;
      this.$refs.applicationDialog.openApplicationDialog(false);
    },
    async softDelete(id) {
      if (!confirm('Are you sure you want to delete the record?')) return;
      const res = await this.$api.charityPostings.softDelete(id);
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }

      this.$bus.$emit('showSuccess', 'Deleted!');
      this.$refs.postingTable.refresh();
    },
    async getCharityPostings() {
      const response = await this.$api.charityPostings.read(createPaginationObject(null, null, this.filters));
      if (response && response.postings) {
        this.postings = response.postings;
      }
    },
    async getCharityPositions() {
      if (!this.charity) return;
      const res = await this.$api.charityPositions.read(createPaginationObject(null, null, this.filters));
      this.charityPositionTypes = res.positions;

      // this.charityPositionTypes.sort((a, b) => {
      //   if (a.name < b.name) {
      //     return -1;
      //   }
      //   if (a.name > b.name) {
      //     return 1;
      //   }
      //   return 0;
      // }); // sort alphabetically
      // let parameters = [];
      // parameters.push({name: 'isDisabled', value: isDeactivated});
      // this.charityPositionTypes = await this.$api.charityPositions.get(`${this.charity.id}`, parameters)
    },
    notifySuccess(message) {
      this.$bus.$emit('showSuccess', message);
      this.$refs.postingTable.refresh();
      this.getCharityPostings();
    },
    updateOptions(options) {
      this.options = options;
    },
    async updateStatus(applicationId, status) {
      let actionConfirmation = 'Are you sure?';
      switch (status) {
        case this.declineStatusId:
          actionConfirmation = 'Are you sure you want to reject this application?';
          break;
        case this.interviewStatusId:
          actionConfirmation = 'By selecting "Interview", you are informing the candidate of your desire to interview them. Please contact the candidate directly to set up a mutually convenient meeting time';
          break;
        case this.offerExtendedStatusId:
          actionConfirmation = 'By Extending an Offer you are indicating that your agency has already met with the candidate and that you now want to extend an offer to the candidate';
          break;
        case this.underReviewStatusId:
          actionConfirmation = 'By selecting "Review", you can take 1-2 weeks to review and discuss a board candidate\'s resume. After 1-2 weeks, you should return to BoardMatch Fundamentals and change the candidate\'s status to "Interview" or "Decline"';
          break;
        default:
          break;
      }
      if (!confirm(actionConfirmation)) return;

      const patchObjects = [{ op: 'replace', path: '/applicationStatusId', value: status }];
      const res = await this.$api.candidateApplications.patch(applicationId, patchObjects);
      if (res === false) {
        this.$bus.$emit('showError', "There was an issue with updating the application's status");
      } else {
        this.$bus.$emit('showSuccess', (status === 'Decline' ? 'Application was declined' : "Application's status updated"));
        this.$refs.applicationDialog.closeApplicationDialog();
        this.$refs.applicationsTable.refresh();
      }
    },
    canDecline(application) {
      if (application == null || application.applicationStatus == null) return false;
      return application.applicationStatus.id !== this.candidateDeclineStatusId
        && application.applicationStatus.id !== this.declineStatusId
        && application.applicationStatus.id !== this.acceptStatusId;
    },
    positionExpired(date) {
      return new Date(date) < Date.now();
    },
    sendPostingShareEmail() {
      this.$refs.postingShareWidget.sendEmail();
    },
  },
};
</script>
