var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('validation-observer',{ref:"userValidationObserver"},[_c('v-row',[(_vm.title != '')?_c('v-col',{staticClass:"text-h5 font-weight-bold",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e()],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0 pl-0",attrs:{"cols":_vm.rows == 2 ? 6 : ''}},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{style:(_vm.isDisabled ? 'pointer-events: none' : ''),attrs:{"required":"","error-messages":errors,"readonly":_vm.isDisabled},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" First Name"),(!_vm.isDisabled)?_c('red-asterisk'):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.userData.userInformation.firstName),callback:function ($$v) {_vm.$set(_vm.userData.userInformation, "firstName", $$v)},expression:"userData.userInformation.firstName"}})]}}])})],1),_c('v-col',{staticClass:"pt-0 pb-0 pl-0",attrs:{"cols":_vm.rows == 2 ? 6 : ''}},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{style:(_vm.isDisabled ? 'pointer-events: none' : ''),attrs:{"error-messages":errors,"required":"","readonly":_vm.isDisabled},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Last Name"),(!_vm.isDisabled)?_c('red-asterisk'):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.userData.userInformation.lastName),callback:function ($$v) {_vm.$set(_vm.userData.userInformation, "lastName", $$v)},expression:"userData.userInformation.lastName"}})]}}])})],1),(_vm.showPronoun)?_c('v-col',{staticClass:"pt-0 pb-0 pl-0"},[_c('v-select',{attrs:{"items":_vm.pronouns,"item-text":"name","item-value":"id"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Pronoun ")]},proxy:true}],null,false,891934476),model:{value:(_vm.userData.userInformation.pronounId),callback:function ($$v) {_vm.$set(_vm.userData.userInformation, "pronounId", $$v)},expression:"userData.userInformation.pronounId"}})],1):_vm._e(),(_vm.userData.userInformation.pronounId === 4)?_c('v-col',{staticClass:"pt-0 pb-0 pl-0"},[_c('validation-provider',{attrs:{"name":"Pronoun Other","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{style:(_vm.isDisabled ? 'pointer-events: none' : ''),attrs:{"required":"","error-messages":errors,"readonly":_vm.isDisabled},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Other"),(!_vm.isDisabled)?_c('red-asterisk'):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.userData.userInformation.pronounOther),callback:function ($$v) {_vm.$set(_vm.userData.userInformation, "pronounOther", $$v)},expression:"userData.userInformation.pronounOther"}})]}}],null,false,1822569995)})],1):_vm._e(),_c('v-col',{staticClass:"pt-0 pb-0 pl-0",attrs:{"cols":_vm.rows == 2 ? 12 : 5}},[_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":"Email","rules":_vm.userData.id != 0 || _vm.isEmail
                  ? 'required|email'
                  : 'required|username|email'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{staticClass:"custom-disabled-gray",style:(_vm.isDisabled ? 'pointer-events: none' : ''),attrs:{"error-messages":errors,"required":"","readonly":_vm.isDisabled,"disabled":(_vm.userData && _vm.userData.id > 0) || _vm.disableEmail},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Email"),(!_vm.isDisabled)?_c('red-asterisk'):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.userData.userInformation.email.name),callback:function ($$v) {_vm.$set(_vm.userData.userInformation.email, "name", $$v)},expression:"userData.userInformation.email.name"}})]}}])})],1),_c('v-col',{staticClass:"pl-0"},[_c('v-row',{staticClass:"ml-0 mr-0"},[_c('v-col',{staticClass:"pr-0 pl-0",attrs:{"cols":_vm.showPasswordReset == true ? 7 : 12}},[_c('v-row',{staticClass:"no-gutters"},[_c('v-col',{attrs:{"cols":_vm.showPassword ? 10 : 12}},[_c('validation-provider',{attrs:{"name":"password","rules":_vm.isPasswordRequired && _vm.userData.id === 0 ? 'required|password-rule' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{style:(_vm.isDisabled || _vm.hidePassword ? 'pointer-events: none' : ''),attrs:{"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"error-messages":errors,"required":"","type":_vm.showPassword ? 'text' : 'password',"readonly":_vm.isDisabled || _vm.hidePassword,"autocomplete":"new-password"},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Password "),(_vm.isPasswordRequired && _vm.userData.id === 0)?_c('red-asterisk'):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.userData.password),callback:function ($$v) {_vm.$set(_vm.userData, "password", $$v)},expression:"userData.password"}})]}}])})],1),(_vm.showPassword)?_c('v-col',{staticClass:"d-flex justify-center align-start mt-5"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle ")])]}}],null,false,130105724)},[_c('span',[_vm._v("Password must have one of each of the following:")]),_c('br'),_c('ul',[_c('li',[_vm._v("At least 8 characters")]),_c('li',[_vm._v("At least one upper-case character (A-Z)")]),_c('li',[_vm._v("At least one lower-case character (a-z)")]),_c('li',[_vm._v("At least one digit (0-9)")]),_c('li',[_vm._v("At least one special character (!@#$%^&*)")])])])],1):_vm._e()],1)],1),(_vm.showPasswordReset == true)?_c('v-col',{staticClass:"pl-0",attrs:{"cols":"4"}},[_c('user-reset-password-btn',{attrs:{"user":_vm.user}})],1):_vm._e()],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }