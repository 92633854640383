<template>
  <div>
    <v-row>
      <v-col
        cols="3"
        class="pl-7 pb-6 pt-8"
      >
        <v-select
          v-model="selectedFilterStatus"
          :items="statusFilterSlots"
          label="Status"
          item-text="name"
          item-value="id"
          clearable
          dense
          hide-details
        />
      </v-col>
      <v-col class="text-end pr-7 pb-6 pt-8">
        <v-btn
          color="primary"
          outlined
          class="mr-2"
          @click="openNewPositionDialog"
        >
          <v-icon
            small
            class="mr-1"
          >
            mdi-plus
          </v-icon>
          New Position
        </v-btn>
        <v-btn
          color="primary"
          outlined
          @click="openPositionCreateDialog"
        >
          <v-icon
            small
            class="mr-1"
          >
            mdi-plus
          </v-icon>
          New Board Member
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <charity-position-table
          ref="positionTable"
          :charity="charity"
          :filters="filters"
        >
          <template slot-scope="{item}">
            <a
              class="mr-2"
              @click="openPositionUpdateDialog(item)"
            > EDIT </a>
            <!-- <a
              class="mr-2"
              @click="redirectPosting(item)"
            > POSTINGS </a> -->

            <a @click="softDelete(item.id)">DELETE</a>
          </template>
        </charity-position-table>
      </v-col>
    </v-row>
    <general-dialog
      ref="newPositionDialog"
      title="New Position"
      dialog-width="500px"
      @submit="submitNewPosition"
    >
      <charity-new-position-form
        ref="newPositionForm"
        :charity="charity"
        @closeNewPositionDialog="closeNewPositionDialog"
      />
    </general-dialog>
    <charity-position-dialog
      ref="positionDialog"
      :title="positionTitle"
      :title-icon="positionTitleIcon"
      :position="position"
      :charity="charity"
      @position-success="notifySuccess"
    />
    <charity-posting-dialog
      ref="postingDialog"
      :title="postingTitle"
      :posting="posting"
      :charity="charity"
      @posting-success="notifySuccess"
    />
  </div>
</template>

<script>
import CharityPositionDialog from '@/components/charity/charityPosition/CharityPositionDialog';
import CharityPositionTable from '@/components/charity/charityPosition/CharityPositionTable';
import CharityPostingDialog from '@/components/charity/charityPosting/CharityPostingDialog';
import CharityNewPositionForm from '@/components/charity/charityPosition/CharityNewPositionForm.vue';
import GeneralDialog from '@/components/general/GeneralDialog';

export default {
  components: {
    CharityPositionDialog,
    CharityPositionTable,
    CharityPostingDialog,
    CharityNewPositionForm,
    GeneralDialog,
  },
  props: {
    charity: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    positionTitle: '',
    positionTitleIcon: [],
    postingTitle: '',
    position: null,
    posting: null,
    statusFilterSlots: [{ id: 1, name: 'All' }, { id: 2, name: 'Current' }, { id: 3, name: 'Past' }],
    selectedFilterStatus: 1,
    isNotBoardPosition: false,
  }),

  computed: {
    filters() {
      const returnFilters = [];

      if (this.charity && this.charity.id) {
        returnFilters.push({ name: 'charityId', values: [this.charity.id.toString()] });
      }

      if (this.selectedFilterStatus) {
        returnFilters.push({ name: 'status', values: [this.selectedFilterStatus] });
      }
      if (this.isNotBoardPosition) {
        returnFilters.push({ name: 'boardPosition', values: [this.isNotBoardPosition] });
      }

      return returnFilters;
    },
  },

  mounted() {

  },
  methods: {
    openPositionCreateDialog() {
      this.positionTitle = ' New Board Member';
      this.positionTitleIcon = ['fabs', 'circle-plus'];
      this.position = null;
      this.$refs.positionDialog.openPositionDialog();
    },
    openPositionUpdateDialog(position) {
      this.positionTitle = 'Update Position';
      this.position = position;
      this.$refs.positionDialog.openPositionDialog();
    },
    redirectPosting(item) {
      if (!item && !item.charityPosition) return;
      this.$router.push(`/charity-postings?positions=${item.charityPosition.id}`);
    },
    async softDelete(id) {
      if (!confirm('Are you sure you want to delete the record?')) return;
      const res = await this.$api.charityMandate.softDelete(id);
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }

      this.$bus.$emit('showSuccess', 'Deleted!');
      this.$refs.positionTable.refresh();
    },
    notifySuccess(message) {
      this.$bus.$emit('showSuccess', message);
      this.$refs.positionTable.refresh();
    },
    openNewPositionDialog() {
      this.$refs.newPositionDialog.openForm();
    },
    submitNewPosition() {
      this.$refs.newPositionForm.submitNewPosition();
    },
    closeNewPositionDialog() {
      this.$refs.newPositionDialog.closeForm();
    },
  },
};
</script>
