<template>
  <v-container class="pa-0 ">
    <v-row class="pb-1">
      <v-col
        cols="3"
        class="pr-0 mr-0"
      >
        <v-menu
          v-model="filtersExpanded"
          content-class="menu-card"
          offset-y
        >
          <template #activator="{ on, attrs }">
            <v-btn
              elevation="0"
              color="primary"
              dark
              max-width="100%"
              v-bind="attrs"
              :class="`${(filtersExpanded ? 'left-button-expanded' : 'left-button')} mt-1`"
              style="text-transform: unset !important;"
              v-on="on"
              @click="filtersExpanded = !filtersExpanded"
            >
              <v-row>
                <v-col
                  cols="9"
                  class="pr-0 subtitle-2"
                  style="display: flex; align-items: center;"
                >
                  <!--{{ !collapseLogo ? filterName(selectedFilter).shortName : filterName(selectedFilter).shorterName }}-->
                  {{ filterName(selectedFilter).shortName }}
                </v-col>
                <v-col
                  cols="1"
                  class="pl-0 subtitle-2"
                  style="display: flex; align-items: center;"
                >
                  <v-icon>
                    {{ filtersExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                  </v-icon>
                </v-col>
              </v-row>
            </v-btn>
          </template>
          <v-card
            class="menu-card primary"
            elevation="0"
            dark
          >
            <v-list
              class="primary"
            >
              <v-list-item
                v-for="(filter) in filters"
                :key="filter.id"
                @click="selectedFilter = filter.id"
              >
                {{ filter.name }}
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-col>
      <v-col
        cols="7"

        class="pr-0 mr-0 ml-0 pl-0"
      >
        <v-text-field
          v-model="searchString"
          :placeholder="!collapseLogo ? 'Search BoardMatch...' : 'Search'"
          hide-details
          class="search-text-field"
          :append-outer-icon="searchString ? 'mdi-close' : null"
          dense
          @click:append-outer="clearSearch"
          @keydown="(input) => {
            search(input, 'textfield'); // Existing search logic
          }"
        />
      </v-col>
      <v-col
        cols="2"
        class="ml-0 pl-0"
      >
        <v-btn
          elevation="0"
          color="primary"
          class="right-button mt-1"
          dark
          @click="(input) => search(input, 'button')"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';

export default {
  components: {
  },
  data: () => ({
    // selectedFilter: -1,
    filtersExpanded: false,
  }),
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.auth.isLoggedIn,
    }),
    ...mapGetters({
      getBaseUrl: 'user/getBaseUrl',
      storeSearch: 'navigation/search',
      selectedInv: 'user/getSelectedInv',
      chosenCategories: 'navigation/selectedCategories',
    }),
    selectedFilter: {
      get() {
        return this.chosenCategories[0];
      },
      set(value) {
        let newValue = [value];
        if (value === -1) newValue = [-1, 1, 2, 3];
        if (newValue != null) this.$store.dispatch('navigation/selectCategories', newValue);
      },
    },
    filters() {
      const fltrs = [
        {
          id: -1,
          name: 'All Results',
          shortName: 'All Results',
          shorterName: 'All',
        },
        {
          id: 2,
          name: 'Search Charities',
          shortName: 'Charities',
          shorterName: 'Char',
        },
        {
          id: 3,
          name: 'Search Positions',
          shortName: 'Positions',
          shorterName: 'Pos',
        },
      ];
      if (!this.isCandidate) {
        fltrs.push({
          id: 1,
          name: 'Search Candidates',
          shortName: 'Candidates',
          shorterName: 'Cand',
        });
      }
      return fltrs;
    },
    searchString: {
      get() {
        return this.storeSearch;
      },
      set(value) {
        if (value != null) {
          this.$store.dispatch('navigation/saveSearch', { search: value });
        }
      },
    },
    isCandidate() {
      return this.selectedInv && this.selectedInv.involvement === 'candidate';
    },
    collapseLogo() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true;
        case 'sm': return true;
        case 'md': return false;
        case 'lg': return false;
        case 'xl': return false;
        default: return false;
      }
    },

  },
  methods: {
    ...mapMutations('user', [
      'storeSelectedInv',
    ]),

    search(input, source) {
      if (source === 'button' || (source === 'textfield' && input.key === 'Enter')) {
        /* this.$store.dispatch('navigation/saveSearch', { search: this.searchString, filters: [{ name: 'source', values: [this.selectedFilter] }] })
          .then(() => {
            if (this.$route.path !== '/search') this.$router.push('/search');
            this.searchString = '';
          }); */
        if (this.$route.path !== '/search') this.$router.push('/search');
        else this.$emit('triggerSearch');
      }
    },
    filterName(id) {
      if (this.filters.findIndex((x) => x.id === id) === -1) return this.filters[0];
      return this.filters.find((x) => x.id === id);
    },

    clearSearch() {
      this.searchString = ''; // Clear the search text
    },

  },
};
</script>
<style>
  .right-button {
    height: 40px !important;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
  }
  .left-button {
    height: 40px !important;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    width: 100%;
    color: rgb(112,112,112);
  }
  .left-button:focus::before {
    opacity: 0 !important;
  }
  .left-button-expanded {
    height: 40px !important;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 00px;
    border-top-left-radius: 20px;
    width: 100%;
    color: rgb(112,112,112);
  }
  .left-button-expanded:focus::before {
    opacity: 0 !important;
  }
  .search-text-field {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;

  }
  .menu-card {
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
    border-bottom-right-radius: 20px !important;
    border-bottom-left-radius: 20px !important;

  }
  div.v-input.search-text-field.v-text-field div.v-input__control div.v-input__slot::before {
    width: 0px !important;
  }
  .btn {
    text-transform: unset !important;
  }
   div.v-input.search-text-field.v-text-field {
    background-color: #E9EFFD;
   }
  .clear-button {
    height: 40px;
    width: 40px;
    min-width: unset;
    border-radius: 20px;
    margin-left: 8px;
  }
  .search-text-field .v-input__icon--append-outer {
    margin-top: 4px;
    margin-right: 5px;
  }
</style>
