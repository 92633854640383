<template>
  <v-container>
    <v-dialog
      v-model="corporationDialog"
      max-width="900px"
    >
      <v-container style="background: white;">
        <validation-observer
          ref="corporationValidateObserver"
        >
          <form @submit.prevent="submit">
            <validation-provider
              v-slot="{ errors }"
              name="Primary Email"
              rules="required|email"
            >
              <v-text-field
                v-model="corporation.email.name"
                :error-messages="errors"
                label="Primary Email"
                required
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="Corporation Name"
              rules="required"
            >
              <v-text-field
                v-model="corporation.name"
                required
                :error-messages="errors"
                label="Corporation Name"
              />
            </validation-provider>
            <validation-provider
              name="Website"
            >
              <v-text-field
                v-model="corporation.website"
                label="Website"
              />
            </validation-provider>
          </form>
        </validation-observer>

        <v-btn
          class="mr-4"
          type="submit"
          @click="submitCorporation"
        >
          {{ btnLabel }}
        </v-btn>
        <v-btn
          class="mr-4"
          type="submit"
          @click="corporationDialog = false"
        >
          cancel
        </v-btn>
      </v-container>
    </v-dialog>
  </v-container>
</template>

<script>

import { required } from 'vee-validate/dist/rules';
import {
  extend, ValidationObserver, ValidationProvider, setInteractionMode,
} from 'vee-validate';
// import AddressForm from '@/components/forms/AddressForm'
// import PhoneForm from '@/components/forms/PhoneForm'
// import ContactForm from '@/components/forms/ContactForm'
import CorporationApi from '@/api/corporations/CorporationApi.js';

setInteractionMode('eager');

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    // 'address-form': AddressForm,
    // 'phone-form': PhoneForm,
    // 'contact-form': ContactForm
  },
  props:
    {
      corporationProp: {
        type: Object,
        default: () => {},
      },
    },
  data: () => ({
    corporationDialog: false,
    corporation: null,
    btnLabel: 'submit',

  }),
  watch: {
    corporationProp: {
      immediate: true,
      handler(value) {
        this.corporation = value
          || {
            id: 0,
            name: '',
            website: '',
            email: {
              name: '',
            },
          };
      },
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
    },
    openCorporationForm() {
      this.corporationDialog = true;
      if (this.corporation.id !== 0) this.btnLabel = 'update';
    },

    async validateForms() {
      const res = await Promise.all([await this.$refs.corporationValidateObserver.validate(), this.$refs.addressForm.validate(), this.$refs.phoneForm.validate(), this.$refs.contactForm.validate()])
        .then((values) => {
          if (values.includes(false)) return false;
          return true;
        });
      return res;
    },

    async submitCorporation() {
      const promiseAllRes = await this.validateForms();

      if (promiseAllRes === false) return;

      const corporationResponse = this.corporation.id !== 0 ? await CorporationApi.update(this.corporation) : await CorporationApi.create(this.corporation);

      if (corporationResponse === false) {
        this.$emit('corporation-fail', 'Something went wrong');
        return;
      }

      const addressSubmitPromise = new Promise((resolve, reject) => {
        this.$refs.addressForm.submitAddress(resolve, reject, null, null, corporationResponse.id);
      });

      const phoneSubmitPromise = new Promise((resolve, reject) => {
        this.$refs.phoneForm.submitPhone(resolve, reject, null, null, corporationResponse.id);
      });

      const contactSubmitPromise = new Promise((resolve, reject) => {
        this.$refs.contactForm.submitContact(resolve, reject, null, null, corporationResponse.id);
      });

      Promise.all([addressSubmitPromise, phoneSubmitPromise, contactSubmitPromise]).then(() => {
        this.corporationDialog = false;
        if (this.corporation.id !== 0) {
          this.$emit('corporation-success', 'Corporation Updated');
        } else {
          this.$emit('corporation-success', 'Corporation Created');
        }
      })
        .catch((err) => {
          console.log(err);
          if (this.corporation.id !== 0) {
            this.$emit('corporation-fail', 'Corporation Update failed');
          } else {
            this.$emit('corporation-fail', 'Corporation Creation failed');
          }
        });
    },
  },
};
</script>
