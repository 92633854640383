<template>
  <v-container
    fluid
    class="pa-2 pb-2 ma-2 mt-12"
    style="background-color: white; border-radius: 8px;"
  >
    <v-row class="text-left pl-6 pt-5">
      <v-col cols="12">
        <h1>My Applications</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <span class="font-italic pl-6 pt-5">See all currently Board positions that you applied to.</span>
      </v-col>
    </v-row>

    <candidate-positions-template
      v-if="onboardingCompleted"
      :charity="charity"
    />
    <candidate-positions-unauthorized-template v-else />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import CandidatePositionsTemplate from '@/components/candidate/charityPosition/CandidatePositionsTemplate';
import CandidatePositionsUnauthorizedTemplate from '@/components/candidate/charityPosition/CandidatePositionsUnauthorizedTemplate';

export default {
  components: {
    CandidatePositionsTemplate,
    CandidatePositionsUnauthorizedTemplate,
  },
  data: () => ({}),
  computed: {
    ...mapState({
      charity: (state) => state.charity.charity,
    }),
    ...mapGetters({
      findClaim: 'auth/findClaim',
      onboardingCompleted: 'candidate/onboardingCompleted',
    }),
    // TODO: Make claim name dynamic
    /* (isAuthorized() {
      const candidatesThinkificEnrollmentCompletedClaim = this.findClaim(
        'candidate-thinkific-enrollment-complete',
      );
      let authorized = (candidatesThinkificEnrollmentCompletedClaim && candidatesThinkificEnrollmentCompletedClaim === '1');
      authorized = authorized || false;
      return authorized;
    }, */
  },

};
</script>
