<template>
  <v-container
    fluid
    fill-height
  >
    <v-row>
      <v-col class="text-h4">
        Charity Registration
      </v-col>
      <v-col class="text-right">
        <v-btn
          icon
          @click="redirectLogin"
        >
          <v-icon large>
            mdi-close
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-h5 font-weight-bold">
        Charity Information
      </v-col>
    </v-row>
    <charity-information-form ref="charityInformationForm" />
    <v-row>
      <v-col cols="6">
        <general-phone-form
          ref="generalPhoneForm"
          phone-type="main"
        />
      </v-col>
      <v-col cols="6">
        <general-phone-form
          ref="eveningPhoneForm"
          phone-type="evening"
          :is-required="false"
        />
      </v-col>
    </v-row>
    <general-address-form ref="generalAddressForm" />
    <v-row>
      <v-col>
        <span class="text-h5 font-weight-bold">
          Senior Staff
        </span>
        <a
          v-if="!seniorStaffId"
          @click="openLogInForm(2)"
        >Already have an account at Boardmatch?</a>
      </v-col>
    </v-row>
    <user-information-form
      v-if="!seniorStaffId"
      ref="userInformationSeniorForm"
      :show-password-reset="false"
      :hide-password="false"
      :title="''"
    />
    <general-contact-form
      v-if="!seniorStaffId"
      ref="generalContactForm"
      :show-first-name="false"
      :show-last-name="false"
      :show-email="false"
    />
    <user-used-title
      v-else
      :username="seniorName"
    />
    <charity-rep-information-form ref="charityRepInformationSeniorForm" />
    <v-row>
      <v-col>
        <span class="text-h5 font-weight-bold">
          Charity Administrator
        </span>
        <a
          v-if="!userId"
          @click="openLogInForm(1)"
        >Already have an account at Boardmatch?</a>
      </v-col>
    </v-row>

    <user-information-form
      v-if="!userId"
      ref="userInformationForm"
      :show-password-reset="false"
      :hide-password="false"
      :title="''"
    />
    <user-used-title
      v-else
      :username="userName"
    />
    <charity-rep-information-form ref="charityRepInformationForm" />

    <!-- </v-row> -->
    <v-row>
      <v-col /><v-col
        class="text-end"
      >
        <v-btn
          class="mr-4"
          text
          @click="redirectLogin"
        >
          cancel
        </v-btn>
        <v-btn
          class="mr-4"
          type="submit"
          color="success"
          @click="submitCharity"
        >
          submit
        </v-btn>
      </v-col>
    </v-row>

    <user-login-dialog
      ref="userLoginDialog"
      :title="'Login'"
      @log-in-success="logInSuccess"
    />
    <release-charity-form
      ref="releaseForm"
      @agreed-success="releaseFormAgreed"
    />
  </v-container>
</template>

<script>
import UserInformationForm from '@/components/user/UserInformationForm';
import GeneralPhoneForm from '@/components/general/GeneralPhoneForm';
import GeneralAddressForm from '@/components/general/GeneralAddressForm';
import GeneralContactForm from '@/components/general/GeneralContactForm';
import CharityInformationForm from '@/components/charity/CharityInformationForm';
import CharityRepInformationForm from '@/components/charity/charityRep/CharityRepInformationForm';
import UserLoginDialog from '@/components/user/UserLoginDialog';
import UserUsedTitle from '@/components/user/UserUsedTitle.vue';
import ReleaseCharityForm from '@/components/infoForms/ReleaseCharityForm';

export default {
  components: {
    UserInformationForm,
    GeneralPhoneForm,
    GeneralAddressForm,
    GeneralContactForm,
    CharityInformationForm,
    CharityRepInformationForm,
    UserLoginDialog,
    UserUsedTitle,
    ReleaseCharityForm,
  },

  data: () => ({
    userId: null,
    seniorStaffId: null,
    currentUser: 0,
    seniorName: '',
    userName: '',
    isReleaseFormAgreed: false,
  }),

  methods: {
    async submitCharity() {
      const validateComponentsResult = await Promise.all([
        this.$refs.charityInformationForm.validate(),
        this.$refs.generalAddressForm.validate(),
        this.$refs.generalPhoneForm.validate(),
        this.userId ? true : this.$refs.userInformationForm.validate(),
        this.seniorStaffId ? true : this.$refs.generalContactForm.validate(),
        this.seniorStaffId ? true : this.$refs.userInformationSeniorForm.validate(),
      ]).then((values) => !values.includes(false));

      if (!validateComponentsResult) return;

      if (!this.isReleaseFormAgreed) {
        this.$refs.releaseForm.toggleDialog();
        return;
      }

      this.$bus.$emit('loading', true);

      const charityResponse = await this.$refs.charityInformationForm.submit();
      const userResponse = this.userId
        ? true
        : await this.$refs.userInformationForm.submit();
      const userSeniorResponse = this.seniorStaffId
        ? true
        : await this.$refs.userInformationSeniorForm.submit();
      if (!charityResponse && !userResponse && !userSeniorResponse) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading', false);
        return;
      }

      const charityRepResponse = this.userId
        ? await this.$refs.charityRepInformationForm.submit(
          charityResponse.id,
          this.userId,
          [{ id: 1, name: 'Admin' }],
        )
        : await this.$refs.charityRepInformationForm.submit(
          charityResponse.id,
          userResponse.id,
          [{ id: 1, name: 'Admin' }],
        );
      const charitySeniorRepResponse = this.userId
        ? await this.$refs.charityRepInformationSeniorForm.submit(
          charityResponse.id,
          this.seniorStaffId,
          [{ id: 5, name: 'Basic Access' }],
        )
        : await this.$refs.charityRepInformationSeniorForm.submit(
          charityResponse.id,
          userSeniorResponse.id,
          [{ id: 5, name: 'Basic Access' }],
        );
      const addressResponse = await this.$refs.generalAddressForm.submit(
        charityResponse.id,
      );
      const phoneResponse = await this.$refs.generalPhoneForm.submit(
        charityResponse.id,
      );
      const phone2Response = await this.$refs.eveningPhoneForm.submit(
        charityResponse.id,
      );
      const contactResponse = this.seniorStaffId
        ? true
        : await this.$refs.generalContactForm.submit(charityResponse.id);

      if (
        !charityRepResponse
        && !addressResponse
        && !phoneResponse
        && !phone2Response
        && !contactResponse
        && !charitySeniorRepResponse
      ) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading', false);
        return;
      }
      this.$bus.$emit('showSuccess', 'Charity Created');
      this.$bus.$emit('loading', false);
      // Work around to show Snackbar on login page
      this.$store.commit(
        'auth/storeRegSuccess',
        'New Charity is Registered Successfully! Welcome to Boardmatch!',
      );
      this.redirectLogin();
    },
    redirectLogin() {
      this.$router.push('/login');
    },

    // For Logged In user
    openLogInForm(currentUser) {
      this.currentUser = currentUser;
      this.$refs.userLoginDialog.openForm();
    },

    logInSuccess(userData) {
      if (!userData) return;
      this.$bus.$emit('showSuccess', 'Success!');
      if (this.currentUser === 1) {
        this.userId = userData.id;
        this.userName = `${userData.userInformation.firstName} ${userData.userInformation.lastName}`;
      } else {
        this.seniorStaffId = userData.id;
        this.seniorName = `${userData.userInformation.firstName} ${userData.userInformation.lastName}`;
      }
    },

    releaseFormAgreed() {
      this.isReleaseFormAgreed = true;
      this.submitCharity();
    },
  },
};
</script>

<style>

  @media (min-width: 1264px){
    .container {
      max-width: 1785cm;
  }
  @media (min-width: 1264px){
    .container {
        max-width: 1185cm;
    }
  }

  @media (min-width: 960px){
    .container {
        max-width: 900cm;
    }
  }

}
</style>
