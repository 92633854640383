<template>
  <v-snackbar
    v-model="snackbar"
    :bottom="y === 'bottom'"
    :color="color"
    :left="x === 'left'"
    :multi-line="mode === 'multi-line'"
    :right="x === 'right'"
    :timeout="timeout"
    :top="y === 'top'"
    :vertical="mode === 'vertical'"
  >
    {{ text }}
    <!-- <template v-if="!action">
      {{ text }}
    </template>
    <template v-else>
      <span>{{ text.substring(0, text.indexOf(action.replaceText)) }}</span>
      <a
        style="color: white; text-decoration: underline;"
        @click="callback()"
      >{{ text.substring(text.indexOf(action.replaceText), action.replaceText.length + text.indexOf(action.replaceText)) }}</a>
      <span>{{ text.substring(text.indexOf(action.replaceText) + action.replaceText.length) }}</span>
    </template> -->
    <template #action="{ attrs }">
      <v-btn
        dark
        text
        v-bind="attrs"
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
export default {
  data: () => ({
    action: null,
    color: '',
    mode: '',
    snackbar: false,
    text: '',
    timeout: 10000,
    x: null,
    y: 'top',
  }),
  created() {
    this.$bus.$on('showSuccess', this.showSuccess);
    this.$bus.$on('showError', this.showError);
    this.$bus.$on('showWarning', this.showWarning);
  },
  methods: {
    showSuccess(text = null, action = null) {
      if (text === null) {
        this.text = 'Success';
      } else {
        this.text = text;
      }
      this.action = action;
      this.color = 'green';
      this.snackbar = true;
    },
    showError(text = null, action = null) {
      if (text === null) {
        this.text = 'Whoops, something went wrong... Unexpected Error.';
      } else {
        this.text = text;
      }

      this.action = action;
      this.color = 'red';
      this.snackbar = true;
    },
    showWarning(text = null, action = null) {
      if (text === null) {
        return;
      }
      this.action = action;
      this.text = text;
      this.color = 'orange';
      this.snackbar = true;
    },
    callback() {
      if (this.action && this.action.callback) {
        this.$emit(this.action.callback);
      }
    },
  },
};
</script>
<style>
</style>
