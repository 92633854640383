<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      width="800px"
      overlay-opacity="0.7"
    >
      <v-card
        fill-height
      >
        <v-container fluid>
          <v-row>
            <v-col class="text-h4 font-weight-bold pl-6">
              {{ title }}
            </v-col>
            <v-col class="text-right pr-6">
              <v-btn
                icon
                @click="closeForm"
              >
                <v-icon large>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <charity-registration-template
            ref="charityRegistrationTemplate"
            @registration-success="closeForm"
            @loading="(loading) => setLoading(loading)"
          />
          <v-row>
            <v-col
              class="text-end pr-4 pb-6"
            >
              <slot :item="user" />
              <v-btn
                class="mr-4"
                text
                @click="closeForm"
              >
                cancel
              </v-btn>
              <v-btn
                class="mr-4"
                type="submit"
                color="success"
                :disabled="loading"
                @click="submit"
              >
                <v-progress-circular
                  v-if="loading"
                  indeterminate
                  size="20"
                  color="grey"
                  class="mr-2"
                />register{{ loading ? 'ing' : '' }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import CharityRegistrationTemplate from '@/components/charity/CharityRegistrationTemplate';

export default {
  components: {
    CharityRegistrationTemplate,
  },
  props:
    {
      user: {
        type: Object,
        default: () => {},
      },
      title: {
        type: String,
        default: 'Charity Registration',
      },
    },

  data: () => ({
    showDialog: false,
    loading: false,
  }),

  methods: {
    openForm() {
      this.showDialog = true;
    },
    closeForm() {
      this.$refs.charityRegistrationTemplate.resetForm();
      this.showDialog = false;
    },
    submit() {
      this.$refs.charityRegistrationTemplate.submit();
    },
    notifySuccess(msg) {
      this.closeForm();
      this.$emit('candidate-success', msg);
    },
    setLoading(loading) {
      this.loading = loading;
    },

  },
};
</script>
