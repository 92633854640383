import Api from '../Api';

class CandidateMatchingPreferencesApi extends Api {
  constructor() {
    super('candidates/preference-matching', 'candidates preference matching');
  }

  async getIntroductionTypes() {
    const res = await this.get('introduction-types');
    return res;
  }

  async getVolunteerProgramTypes() {
    const res = await this.get('volunteer-program-types');
    return res;
  }

  async getVolunteerExperienceTypes() {
    const res = await this.get('volunteer-experience-types');
    return res;
  }

  async getBoardExperienceTypes() {
    const res = await this.get('board-experience-types');
    return res;
  }
}

const candidateMatchingPreferencesApi = new CandidateMatchingPreferencesApi();
export default candidateMatchingPreferencesApi;
