<template>
  <div class="ma-0 pa-0 pl-2 pr-2">
    <v-row>
      <v-col cols="12">
        <a
          v-if="!userId"
          class="mb-0 pb-0"
          @click="openLogInForm()"
        >Already have a Boardmatch user account?</a>
      </v-col>
      <v-col
        cols="8"
        class="mt-0 pt-0 pr-0"
      >
        <user-information-form
          v-if="
            !userId || newUser"
          ref="userInformationForm"
          :rows="2"
          :show-password-reset="false"
          :hide-password="false"
          :show-pronoun="false"
        />
        <user-used-title
          v-else
          :username="userName"
        />
      </v-col>
      <v-col
        cols="4"
        class="pt-0 pl-0 mt-0"
      >
        <general-phone-form
          ref="generalPhoneForm"
          phone-type="main"
          :cols="1"
          :show-extension="false"
        />
      </v-col>
    </v-row>
    <validation-observer ref="candidateRegistrationObserver">
      <v-row>
        <v-col class="text-h5 font-weight-bold pt-0">
          Employer
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="pt-0 mt-0"
          cols="6"
        >
          <validation-provider
            v-slot="{ errors }"
            name="Title/Position"
            rules="required"
          >
            <v-text-field
              v-model="experienceTitle"
              :error-messages="errors"
            >
              <template #label>
                Title/Position<red-asterisk />
              </template>
            </v-text-field>
          </validation-provider>
        </v-col>

        <v-col
          class="pl-0 pt-0 mt-0"
          cols="6"
        >
          <validation-provider
            v-slot="{ errors }"
            name="Organization Name"
            rules="required"
          >
            <v-text-field
              v-model="experienceOrganizationName"
              class="pr-3"
              :error-messages="errors"
            >
              <template #label>
                Organization Name<red-asterisk />
              </template>
            </v-text-field>
          </validation-provider>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-h5 font-weight-bold pt-0">
          Residence
        </v-col>
        <v-col
          cols="12"
          class="pt-0 mt-0"
        >
          <general-address-form
            ref="generalAddressForm"
            :is-required="true"
            :is-disabled="false"
            :is-simplified-view="true"
          />
        </v-col>
      </v-row>
      <v-row class="mt-3 mb-1">
        <v-col
          cols="12"
          class="pa-0"
        >
          <ValidationProvider
            v-slot="{ errors }"
            immediate
            name="candidateSkills"
            rules="required"
          >
            <v-text-field
              v-show="false"
              v-model="submitCandidatePreferences.selectedCandidateSkills"
              :error="errors.length > 0"
            />
            <v-text-field
              v-show="false"
              v-model="submitCandidatePreferences.selectedCandidateFocusAreas"
              :error="errors.length > 0"
            />
          </ValidationProvider>
        </v-col>
        <v-col
          cols="6"
          class="pt-0"
        >
          <span
            class="text-h5 font-weight-bold"
          >Skills<red-asterisk /></span>
          <span
            v-if="!submitCandidatePreferences.selectedCandidateSkills || submitCandidatePreferences.selectedCandidateSkills.length == 0"
            class="red--text ml-2"
          >Must select at least one</span>
          <br>
          <template
            v-if="submitCandidatePreferences.selectedCandidateSkills && submitCandidatePreferences.selectedCandidateSkills.length > 0"
          >
            <v-chip
              v-for="(skill, skillIndex) in submitCandidatePreferences.selectedCandidateSkills"
              :key="'position-matching-skill-' + skillIndex"
              medium
              class="ml-2 mt-2"
              close
              color="primary"
              @click:close="removeFilterChip(skillIndex, 'skill')"
            >
              {{ chipName('skill', skill) }}
            </v-chip>
          </template>
          <v-chip
            medium
            class="ml-2 mt-2"
            color="primary"
            @click="openChipSelector('skill')"
          >
            +
          </v-chip>
        </v-col>
        <v-divider vertical />
        <v-col
          cols="6"
          class="pt-0 pl-7"
        >
          <span class="text-h5 font-weight-bold">Focus Areas<red-asterisk /></span>
          <span
            v-if="!submitCandidatePreferences.selectedCandidateFocusAreas || submitCandidatePreferences.selectedCandidateFocusAreas.length == 0"
            class="red--text ml-2"
          >Must select at least one</span>
          <br>
          <template
            v-if="submitCandidatePreferences.selectedCandidateFocusAreas && submitCandidatePreferences.selectedCandidateFocusAreas.length > 0"
          >
            <v-chip
              v-for="(focusArea, focusAreaIndex) in submitCandidatePreferences.selectedCandidateFocusAreas"
              :key="'position-matching-focus-area-' + focusAreaIndex"
              medium
              class="ml-2 mt-2"
              close
              color="success"
              @click:close="removeFilterChip(focusAreaIndex, 'focus-area')"
            >
              {{ chipName('focus-area', focusArea) }}
            </v-chip>
          </template>
          <v-chip
            medium
            color="success"
            class="ml-2 mt-2"
            @click="openChipSelector('focus-area')"
          >
            +
          </v-chip>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="pt-0 pb-0"
        >
          <ValidationProvider
            v-slot="{ errors }"
            name="Open For Position"
            rules="required"
          >
            <v-radio-group
              v-model="isLookingForPosition"
              :error-messages="errors"
              row
            >
              <template #label>
                <span style="font-size: 16px;">Are you currently looking for a board position?<red-asterisk /></span>
              </template>
              <v-radio
                label="Yes"
                value="true"
              />
              <v-radio
                label="No"
                value="false"
              />
            </v-radio-group>
          </ValidationProvider>
        </v-col>
      </v-row>
    </validation-observer>
    <user-login-dialog
      ref="userLoginDialog"
      style="display: none;"
      :title="'Login'"
      @log-in-success="logInSuccess"
    />
    <candidate-information-form
      ref="candidateInformationForm"
      style="display: none;"
      :is-signup="true"
    />
    <v-dialog
      v-model="editSkillsDialog"
      width="500px"
    >
      <v-card
        fill-height
      >
        <v-container
          fluid
        >
          <v-row>
            <v-col>
              <span class="text-h4">Select {{ dialogChipType == 'skill' ? 'Skills' : '' }}{{ dialogChipType == 'focus-area' ? 'Focus Areas' : '' }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              v-if="dialogChipType == 'skill'"
              class="ml-2"
            >
              <span>Please select 1 or more skills from the list below that you would like to be matched with:</span><br><br>
              <v-chip
                v-for="skill in candidateSkills"
                :key="skill.id"
                :class="chipSelected(skill) ? 'primary' : ''"
                small
                class="mt-1"
                @click="selectChip(skill)"
              >
                {{ skill.name }}
              </v-chip>
            </v-col>
            <v-col
              v-if="dialogChipType == 'focus-area'"
              class="ml-2"
            >
              <span>Please select 1 or more focus areas from the list below that you would like to be matched with:</span><br><br>
              <v-chip
                v-for="focusArea in candidateFocusAreas"
                :key="focusArea.id"
                :class="chipSelected(focusArea) ? 'success' : ''"
                small
                class="mt-1"
                @click="selectChip(focusArea)"
              >
                {{ focusArea.name }}
              </v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              class="text-end"
            >
              <v-btn
                class="mr-4 default"
                elevation="0"
                @click="closeForm"
              >
                Ok
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate';
import UserInformationForm from '@/components/user/UserInformationForm.vue';
import GeneralPhoneForm from '@/components/general/GeneralPhoneForm';
import GeneralAddressForm from '@/components/general/GeneralAddressForm';
import CandidateInformationForm from '@/components/candidate/CandidateInformationForm';
import UserLoginDialog from '@/components/user/UserLoginDialog';
import UserUsedTitle from '@/components/user/UserUsedTitle.vue';

export default {
  components: {
    UserInformationForm,
    GeneralPhoneForm,
    GeneralAddressForm,
    CandidateInformationForm,
    UserLoginDialog,
    UserUsedTitle,
    ValidationProvider,
    ValidationObserver,
  },
  props:
  {
    isSignup: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    address: null,
    userId: null,
    userName: '',
    isReleaseFormAgreed: false,
    newUser: true,
    isLookingForPosition: null,
    validationTriggered: false,
    submitCandidatePreferences: {
      id: 0,
      candidateId: 0,
      selectedCandidateSkills: [],
      selectedCandidateFocusAreas: [],
      candidateBoardExperienceTypeId: null,
      candidateEmployerVolunteerProgramTypeId: null,
      volunteerExperienceTypeIds: [],
      geographicalAreaPreference: '',
      otherVolunteerExperience: '',
      candidateSourceOfIntroductionId: null,
      sourceOfIntroductionDetails: '',
      reasonsAndGoals: '',
      permissionToViewInformation: false,
      withdrawParticipation: false,
    },
    dialogChipType: '',
    editSkillsDialog: false,
    candidateSkills: [],
    candidateFocusAreas: [],
    experienceTitle: null,
    experienceOrganizationName: null,
  }),
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.candidateSkills = await this.$api.candidateSkills.get();
      this.candidateFocusAreas = await this.$api.candidateFocusAreas.get();
    },
    async submit(isAgreed = false) {
      if (!this.userId) {
        // user doesn't exist, so we create one.
        const validateComponentsResult = await Promise.all([
          this.$refs.userInformationForm.validate(),
          this.$refs.generalPhoneForm.validate(),
          this.$refs.generalAddressForm.validate(),
          this.validate(),
        ]).then((values) => !values.includes(false));

        if (!isAgreed) {
          this.$bus.$emit('showError', 'Please agree with terms and conditions.');
        }
        if (!validateComponentsResult || !isAgreed) return;

        this.$bus.$emit('loading', true);
        this.$emit('loading', true);

        const userResponse = await this.$refs.userInformationForm.submit();

        if (!userResponse) {
          this.$bus.$emit('showError');
          this.$bus.$emit('loading', false);
          this.$emit('loading', false);
          return;
        }
        this.userId = userResponse.id;
        const phoneResponse = await this.$refs.generalPhoneForm.submit(
          null,
          userResponse.userInformation.id,
        );
        if (!phoneResponse) {
          this.$bus.$emit('showError');
          this.$bus.$emit('loading', false);
          this.$emit('loading', false);
          return;
        }

        const addressResponse = await this.$refs.generalAddressForm.submit(
          null,
          userResponse.userInformation.id,
        );
        if (!addressResponse) {
          this.$bus.$emit('showError');
          this.$bus.$emit('loading', false);
          this.$emit('loading', false);
          return;
        }
      }

      const candidateResponse = await this.$refs.candidateInformationForm.submit(
        this.userId,
        isAgreed,
        this.submitCandidatePreferences.selectedCandidateSkills,
        this.submitCandidatePreferences.selectedCandidateFocusAreas,
        this.experienceTitle,
        this.experienceOrganizationName,
        this.isLookingForPosition,
      );
      if (!candidateResponse) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading', false);
        this.$emit('loading', false);
        return;
      }

      this.$bus.$emit('loading', false);
      this.$bus.$emit('showSuccess', 'Registered Successfully! Verification Email has been sent.');
      this.$emit('registration-success');
      this.$emit('loading', false);
    },
    resetForm() {
      if (!this.userId) {
        this.$refs.userInformationForm.reset();
        this.$refs.generalPhoneForm.reset();
        this.$refs.generalAddressForm.reset();
      } else {
        this.userId = null;
        this.userName = '';
      }
    },
    // For Logged In user
    openLogInForm() {
      this.$refs.userLoginDialog.openForm();
    },
    logInSuccess(userData) {
      if (!userData) return;
      if (userData.candidate != null) {
        this.$bus.$emit('showError', 'The specified user is already a candidate');
        return;
      }
      this.$bus.$emit('showSuccess', 'Success!');
      this.userId = userData.id;
      this.userName = `${userData.userInformation.firstName} ${userData.userInformation.lastName}`;
      this.newUser = false;
    },
    async validate() {
      return this.$refs.candidateRegistrationObserver.validate();
    },
    openChipSelector(type) {
      this.dialogChipType = type;
      this.editSkillsDialog = true;
    },
    removeFilterChip(skillIndex, type) {
      if (type === 'skill') this.submitCandidatePreferences.selectedCandidateSkills.splice(skillIndex, 1);
      if (type === 'focus-area') this.submitCandidatePreferences.selectedCandidateFocusAreas.splice(skillIndex, 1);
    },
    chipName(type, id) {
      let index = -1;
      if (type === 'skill') index = this.candidateSkills.findIndex((x) => x.id === id);
      if (type === 'focus-area') index = this.candidateFocusAreas.findIndex((x) => x.id === id);
      if (index > -1) {
        if (type === 'skill') return this.candidateSkills[index].name;
        if (type === 'focus-area') return this.candidateFocusAreas[index].name;
      }
      return 'unknown';
    },
    chipSelected(item) {
      if (this.dialogChipType === 'skill' && this.submitCandidatePreferences.selectedCandidateSkills.findIndex((x) => x === item.id) > -1) return true;
      if (this.dialogChipType === 'focus-area' && this.submitCandidatePreferences.selectedCandidateFocusAreas.findIndex((x) => x === item.id) > -1) return true;
      return false;
    },
    selectChip(item) {
      if (this.dialogChipType === 'skill') {
        const index = this.submitCandidatePreferences.selectedCandidateSkills.findIndex((x) => x === item.id);
        if (index === -1) this.submitCandidatePreferences.selectedCandidateSkills.push(item.id);
        else this.removeFilterChip(index, this.dialogChipType);
      }
      if (this.dialogChipType === 'focus-area') {
        const index = this.submitCandidatePreferences.selectedCandidateFocusAreas.findIndex((x) => x === item.id);
        if (index === -1) this.submitCandidatePreferences.selectedCandidateFocusAreas.push(item.id);
        else this.removeFilterChip(index, this.dialogChipType);
      }
    },
    closeForm() {
      this.editSkillsDialog = false;
    },
  },
};
</script>
