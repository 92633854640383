<template>
  <div style="background-color: #efefef;  width: 100%;">
    <div
      class="content-div"
    >
      <v-row>
        <v-col
          class="ma-0 pb-0 pt-0 ml-0"
        >
          <template v-if="socialMediaLinks && socialMediaLinks.length > 0">
            <div class="social-media-widget white">
              <general-social-media-widget
                :links="socialMediaLinks"
              />
            </div>
          </template>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col
          cols="12"
        >
          <div class="white title-widget">
            <charity-title-widget
              v-if="charity"
              ref="charityTitleWidget"
              :charity="charity"
              :editing="false"
              :editable="false"
              @imageLoaded="(image) => {profileImage = image}"
            />
          </div>
        </v-col>
      </v-row>
      <v-row
        class="mt-0"
        style="z-index: 3;"
      >
        <v-col
          cols="5"
        >
          <div class="left-section mt-3 pa-4">
            <span
              style="font-size: x-large; font-weight: bold;"
            >Open Positions</span><br>
            <template v-if="loadingCharityPostings">
              Loading Charity Postings...
            </template>
            <template
              v-else
            >
              <div
                v-for="(posting) in charityPostings"
                :key="`cp-${posting.id}`"
                class="mt-2 mb-2"
                style="background-color: #efefef; padding-top: 1px; padding-bottom: 1px; border-radius: 8px;"
              >
                <search-charity-posting-result-tile-widget
                  :charity-posting="posting"
                  :class="`custom-width ${posting === selectedPosting ? 'selected' : ''}`"
                  style="margin: 1px;"
                  :selected="posting === selectedPosting"
                  :show-charity="false"
                  :applied="applied(posting.id)"
                  @showCharityPosting="(id) => setSelectedCharityPosting(id)"
                />
              </div>
            </template>
          </div>
        </v-col>
        <v-col
          cols="7"
        >
          <v-row class="mt-0">
            <v-col>
              <template v-if="loadingCharityPostings">
                Loading Posting...
              </template>
              <template v-else>
                <posting-summary-widget
                  :charity-posting="selectedPosting"
                  class="widget white"
                  :applied="applied()"
                  @loadExistingApplications="loadExistingApplications()"
                />
              </template>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
// import GeneralTextWidget from '@/components/general/GeneralTextWidget';
import { createPaginationObject } from '@/helpers/PaginationHelper.js';
import SearchCharityPostingResultTileWidget from '@/components/search/SearchCharityPostingResultTileWidget.vue';
// import CharitySummaryWidget from '@/components/charity/listings/CharitySummaryWidget.vue';
import PostingSummaryWidget from '@/components/charity/listings/PostingSummaryWidget.vue';
import GeneralSocialMediaWidget from '@/components/general/GeneralSocialMediaWidget';
import CharityTitleWidget from '@/components/charity/profile/CharityTitleWidget';

export default {
  components: {
    SearchCharityPostingResultTileWidget,
    // CharitySummaryWidget,
    PostingSummaryWidget,
    GeneralSocialMediaWidget,
    CharityTitleWidget,
  },
  props: {
    charityPostingId: {
      type: Number,
      default: 0,
    },
    charityId: {
      type: Number,
      default: 0,
    },
    existingApplications: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    charity: null,
    charityPostings: [],
    selectedPosting: null,
    profileImage: null,
    loadingCharity: false,
    loadingCharityPostings: false,
    loadingExistingApplications: false,
  }),
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.auth.isLoggedIn,
      involvement: (state) => state.user.selectedInv,
      candidate: (state) => state.candidate.candidate,
    }),
    socialMediaLinks() {
      const links = [];
      if (this.charity != null) {
        // if (this.charity.website != null && this.charity.website !== '' && this.profileImage) {
        //   links.push({
        //     id: -1, socialMediaTypeId: -1, url: this.charity.website, image: this.profileImage, socialMediaType: { name: 'Website' },
        //   });
        // }
        if (this.charity && this.charity.socialMedia && this.charity.socialMedia.length > 0) Array.prototype.push.apply(links, this.charity.socialMedia.filter(((x) => x.url != null && x.url !== '')));
      }
      return links;
    },
  },
  watch: {
    charityPostingId: {
      immediate: true,
      handler(value) {
        if (value) {
          this.refresh();
        }
      },
    },
    charityId: {
      immediate: true,
      handler(value) {
        if (value && this.charityPostingId == null) {
          this.refresh();
        }
      },
    },
  },

  created() {
    this.init();
  },

  methods: {
    async init() {
      this.refresh();
    },
    async loadExistingApplications() {
      this.$emit('loadExistingApplications');
    },
    async refresh() {
      this.charity = null;
      this.charityPostings = [];
      if (this.charityPostingId || this.charityId) {
        this.loadingCharity = true;
        this.loadingCharityPostings = false;
        await this.loadCharity();
        this.loadingCharity = false;
        await this.loadCharityPostings();
        this.loadingCharityPostings = false;
        await this.loadBackgroundImage();
      } else {
        this.clear();
      }
    },
    clear() {
      this.charity = null;
      this.charityPostings = [];
      this.selectedPosting = null;
      this.profileImage = null;
    },
    async loadCharity() {
      const filters = [];
      if (this.charityPostingId) filters.push({ name: 'charityPostingId', values: [this.charityPostingId] });
      else if (this.charityId) filters.push({ name: 'charityId', values: [this.charityId] });
      // const response = await this.$api.charity.read(createPaginationObject(null, null, filters));
      const options = {
        page: 1,
        itemsPerPage: 1,
        sortBy: [],
        include: ['ProfileImage'],
      };
      const response = this.isLoggedIn
        ? await this.$api.charity.read(
          createPaginationObject(options, null, filters),
        )
        : await this.$api.charity.readPublic(
          createPaginationObject(options, null, filters),
        );

      [this.charity] = response.charities;
    },
    async loadCharityPostings() {
      if (!this.charity) return;
      const filters = [];
      filters.push({ name: 'charityId', values: [this.charity.id] });
      const response = this.isLoggedIn
        ? await this.$api.charityPostings.read(
          createPaginationObject(null, null, filters),
        )
        : await this.$api.charityPostings.readPublic(
          createPaginationObject(null, null, filters),
        );

      const today = new Date().getTime();
      this.charityPostings = response.postings.filter((x) => new Date(x.expiryDate).getTime() >= today);
      this.setSelectedCharityPosting();
    },
    async loadBackgroundImage() {
      if (!this.charity.id) return;
      /* const filters = [
        {
          name: 'entityId',
          values: [this.externalCharityId ? this.externalCharityId.toString() : this.charityId.toString()],
        },
        {
          name: 'type',
          values: ['background-image'],
        },
      ];
      const response = await this.$api.image.readImages('charity', createPaginationObject(null, null, filters));
      const [firstImage] = response.images;
      this.backgroundImage = firstImage.image; */
      this.$emit('updateBackgroundImage', 'charity', this.charity.id.toString());
    },
    setSelectedCharityPosting(id = null) {
      if (!id) this.selectedPosting = this.charityPostings.find((x) => x.id === this.charityPostingId);
      else this.selectedPosting = this.charityPostings.find((x) => x.id === id);
      if (!this.selectedPosting && this.charityPostings.length > 0) [this.selectedPosting] = this.charityPostings;
    },
    applied(postingId) {
      if (!postingId) {
        if (!this.selectedPosting) return false;
        return this.existingApplications.findIndex((x) => x.postingId === this.selectedPosting.id) > -1;
      }
      return this.existingApplications.findIndex((x) => x.postingId === postingId) > -1;
    },
  },
};
</script>
<style>
.status-tile {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
  border-radius: 8px;
  padding: 10px;
  margin:12px;
  width: fit-content;
  font-family: "Roboto", sans-serif;
}
.background-image{
  z-index: 1 !important;

}
.title-widget{
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  padding: 12px;
}
.widget{
  border-radius: 8px;
  padding: 12px;
}
.charity-fab{
  margin: 12px;
}
.edit-background-button{
  border-radius: 16px;
  border: 3px solid lightgray;
  background-color: transparent !important;
  width: 800px;
  height: 175px !important;
}
.left-section {
  margin-top: 35px;
  background-color: white;
  border-radius: 8px;
}
.selected {
  /* border: 1px solid #0073FF;*/
  border-radius: 8px;
  background-color: #d8f2e0;
}
.custom-width{
  width: 100% - 1px;
}
</style>
