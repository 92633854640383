<template>
  <v-container fluid>
    <h2
      v-if="showHeader == true"
      class="mb-5"
    >
      Charity Details
    </h2>
    <v-row>
      <v-col cols="2">
        <span>
          <strong>Name:</strong> {{ charity.name }}
        </span>
      </v-col>
      <v-col cols="2">
        <span>
          <strong>Mandate:</strong> {{ charity.mandate }}
        </span>
      </v-col>
      <v-col cols="2">
        <span>
          <strong>Year Incorporated:</strong> {{ charity.yearOfIncorporation }}
        </span>
      </v-col>
      <v-col
        v-if="charity.charityType != null"
        cols="2"
      >
        <span>
          <strong>Type:</strong> {{ charity.charityType.name }}
        </span>
      </v-col>
      <v-col
        v-if="charity.charityScope != null"
        cols="2"
      >
        <span>
          <strong>Scope:</strong> {{ charity.charityScope.name }}
        </span>
      </v-col>
    </v-row>
    <v-row v-if="charity.email != null || charity.phone != null">
      <v-col
        v-if="charity.email != null"
        cols="2"
      >
        <span>
          <strong>Email:</strong> {{ charity.email.name }}
        </span>
      </v-col>
      <template v-if="charity != null && charity.phones != null && charity.phones.length > 0">
        <v-col
          v-for="phone in charity.phones"
          :key="phone.id"
          cols="2"
        >
          <span>
            <strong>{{ phone.phoneType != null ? phone.phoneType.name + ' ' : '' }}Phone:</strong> {{ phone.number }} {{ (phone.extension != '' ? 'ext: ' + phone.extension : phone.extension) }}
          </span>
        </v-col>
      </template>
      <v-col
        v-if="charity.address != null"
        cols="4"
      >
        <span>
          <strong>Address:</strong> {{ address }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createPaginationObject } from '@/helpers/PaginationHelper.js';

export default {
  components: {
  },
  props: {
    charityId: {
      type: Number,
      default: 0,
    },
    showHeader: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    charity: {},
  }),
  computed: {
    address() {
      if (this.charity
        && this.charity.address) {
        const { address } = this.charity;
        let res = address.address1;
        if (address.address2 != null && address.address2 !== '') res += `, ${address.address2}`;
        if (address.city != null && address.city !== '') res += `, ${address.city}`;
        if (address.region != null && address.code != null && address.code !== '') res += `, ${address.region.abbreviation} ${address.code}`;
        if (address.country != null) res += `, ${address.country.name}`;
        return res;
      }
      return '';
    },
  },
  watch: {
  },
  methods: {
    async loadCharity() {
      // the prop charity doesn't have all of the charity details, so we're loading it directly
      if (this.charity) {
        const filters = [
          {
            name: 'charityId',
            values: [this.charityId.toString()],
          },
        ];
        const result = await this.$api.charity.read(
          createPaginationObject(null, null, filters),
        );
        if (result != null && result.charities != null && result.charities.length > 0) {
          const charity = result.charities[0];
          this.charity = charity;
        }
      }
    },
  },
};
</script>
