<template>
  <v-row>
    <v-col
      class="mb-3"
    >
      <v-row>
        <v-col
          class="ma-2 mb-0 pb-0"
          style="display: grid; align-content: center;"
        >
          <div
            style="display: grid; align-content: center;"
          >
            <span
              class="font-weight-bold text-h5"
              style="margin-bottom: 5px;"
            >Experience</span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-for="(experience, experienceIndex) in candidate.candidateQualifications.candidateExperience"
          :key="experience.id"
          cols="12"
        >
          <v-divider class="mb-3" />
          <v-row>
            <v-col
              cols="2"
              style="display: grid; align-content: center;"
            >
              <v-img
                v-if="experience.logo"
                :src="experience.logo"
                style="border-radius: 50% !important;"
                max-height="60px"
                max-width="60px"
                class="ml-2"
              />
              <!-- <v-img
                v-else
                :src="require('@/assets/experience-logo.png')"
                style="border-radius: 50% !important;"
                max-height="60px"
                max-width="60px"
                class="ml-2"
              /> -->
              <font-awesome-icon v-else class="ml-5" size="2xl" :icon="['fabs', 'briefcase']" />
            </v-col>
            <v-col cols="10" class="pl-0">
              <span
                class="subtitle-1 font-weight-bold"
                style="color: rgb(100,100,100)"
              >
                {{ experience.title }}
              </span>
              <br>
              <template v-if="experience.organizationName">
                <span
                  class="subtitle-2 font-weight-regular"
                  style="color: rgb(100,100,100); margin-top: 0px;"
                >
                  {{ experience.organizationName }} -
                </span>
              </template>
              <template v-if="experience.candidateExperienceContractType && experience.candidateExperienceContractType.name">
                <span
                  class="subtitle-2"
                  style="color: rgb(100,100,100); margin-top: 0px;"
                >
                  {{ experience.candidateExperienceContractType.name }}
                </span>
              </template>
              <br>
              <template v-if="experience.start && experience.start != '0001-01-01T00:00:00'">
                <span
                  class="subtitle-2 font-weight-regular"
                  style="color: rgb(100,100,100); margin-top: 0px;"
                >
                  {{ timeframe(experience) }} -
                </span>
                <span
                  class="subtitle-2"
                  style="color: rgb(100,100,100); margin-top: 0px;"
                >
                  {{ duration(experience) }}
                </span>
                <br>
              </template>
              <template v-if="addressString(experience)">
                <span
                  style="margin-top: 5px;"
                >
                  <v-icon
                    small
                    style="margin-bottom: 2px;"
                  >
                    mdi-crosshairs-gps
                  </v-icon>
                  {{ addressString(experience) }}
                </span>
                <br>
              </template>
              <template v-if="experience.description">
                <span
                  class="caption"
                  style="color: rgb(100,100,100); margin-top: 0px;"
                >{{ experience.description }}</span>
                <br>
              </template>
              <div class="mt-3">
                <span
                  v-if="hasSkills(experience)"
                  class="
                  subtitle-2
                  font-weight-bold"
                  style="color: rgb(100,100,100); margin-top: 0px;"
                >Skills:</span>
                <v-chip
                  v-for="skill in experience.skills"
                  :key="'exp-' + experienceIndex + '-skill-' + skill.id"
                  small
                  color="primary"
                  class="ml-2 mb-1"
                >
                  {{ skill.name }}
                </v-chip>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>

export default {
  components: {
  },

  props:
  {
    candidate: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
  }),
  computed: {
  },
  watch: {
  },
  methods: {
    duration(experience) {
      // calculate duration of experience in years and months
      let end = null;
      if (experience.end === null) end = this.$moment();
      else end = this.$moment(experience.end);
      const start = this.$moment(experience.start);
      const years = this.$moment(end).diff(start, 'years');
      if (years === 0) {
        const months = this.$moment(end).diff(start, 'months');
        return `${months} ${months === 1 ? 'month' : 'months'}`;
      }
      return `${years} ${years === 1 ? 'year' : 'years'}`;
    },
    timeframe(experience) {
      // show timeframe of experience in presentable format
      const isCurrent = experience.end === null;
      return `${this.$moment(experience.start).format('MMM YYYY')} - ${isCurrent ? 'Present' : this.$moment(experience.end).format('MMM YYYY')}`;
    },
    addressString(experience) {
      // show location of experience in presentable format
      if (experience === null || experience.candidateExperienceLocationType === null) return null;
      if (experience.candidateExperienceLocationType.name !== 'Physical') return experience.candidateExperienceLocationType.name;
      if (experience.address == null || experience.address.region == null || experience.address.country == null) return null;
      return `${experience.address.city}, ${experience.address.region.name}, ${experience.address.country.name}`;
    },
    hasSkills(experience) {
      return experience.skills.length > 0;
    },
  },
};
</script>
<style>
</style>
