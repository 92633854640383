<template>
  <v-container fluid>
    <validation-observer ref="formObserver">
      <v-row>
        <v-col>
          <validation-provider
            v-slot="{ errors }"
            name="Name"
            rules="required"
          >
            <v-text-field
              v-model="typeData.name"
              dense
              :error-messages="errors"
              hide-details
            >
              <template #label>
                Social Media Name<span class="red--text"><strong>* </strong></span>
              </template>
            </v-text-field>
          </validation-provider>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <general-upload-widget
            ref="generalUploadWidget"
            :return-file="true"
            :is-image="true"
            :multiple="false"
            :existing-image="typeData.image"
            :image-preview-max-height="150"
            :hide-upload="true"
            @success="success()"
          />
        </v-col>
      </v-row>
    </validation-observer>
  </v-container>
</template>

<script>
import {
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from 'vee-validate';
import GeneralUploadWidget from '@/components/general/GeneralUploadWidget.vue';

setInteractionMode('eager');

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    GeneralUploadWidget,
  },
  props:
  {
    type: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    typeData: {
      id: null,
      name: '',
      image: null,
      imageId: null,
    },
  }),
  watch: {
    type: {
      immediate: true,
      handler(value) {
        this.typeData = { ...value };
      },
    },
  },
  methods: {
    async validate() {
      const res = await this.$refs.roleValObserver.validate();
      return res;
    },
    async submit() {
      const res = {
        valid: false,
        successful: false,
      };
      res.valid = await this.$refs.formObserver.validate();
      if (!res.valid) return res;
      try {
        const payload = { ...this.typeData, image: this.$refs.generalUploadWidget.returnFiles()[0] };
        const result = this.typeData.id !== 0 && this.typeData.id != null ? await this.$api.socialMedia.update(payload, '/type', true) : await this.$api.socialMedia.create(payload, '/type', true);
        if (!result || !result.id) return res;

        res.successful = true;
        return res;
      } catch (err) {
        return res;
      }
    },
    clear() {
      this.$refs.generalUploadWidget.removeFile();
    },
    success() {
      this.$emit('success');
      this.clear();
    },
  },
};
</script>
