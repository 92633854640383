<template>
  <v-container
    fluid
    class="pa-2 pb-2 ma-2 mt-12"
    style="background-color: white; border-radius: 8px;"
  >
    <v-overlay
      :value="overlay"
      class="align-center justify-center"
    >
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-row
      id="#title"
      class="text-left pl-6 pt-5"
    >
      <v-col cols="8">
        <h1>{{ title }}</h1>
      </v-col>
    </v-row>
    <v-row
      v-if="onboardingNeeded"
      class="text-left pl-6 pt-0"
    >
      <v-col cols="8">
        <span class="font-italic">Please complete all steps of this onboarding process to unlock all of the Boardmatch features.</span>
      </v-col>
    </v-row>
    <v-row v-if="onboarding != null && onboarding.length > 0">
      <v-col class="text-end">
        <v-stepper
          ref="stepper"
          v-model="currentStep"
          class="stepper"
          flat
          @change="goto('title')"
        >
          <v-stepper-header
            class="sticky"
          >
            <template v-for="(step, index) in onboarding">
              <v-stepper-step
                v-if="true"
                :key="`step-${step.onboardingId}`"
                editable
                :step="index + 1"
                :color="iconColor(step)"
                class="custom-step"
              >
                <span>
                  {{ step.name }}
                  <v-icon
                    v-if="step.completedPercent >= 100"
                    class="mb-1"
                    style="font-size: 30px;"
                    color="success"
                  >
                    mdi-check
                  </v-icon>
                </span>
              </v-stepper-step>

              <v-divider
                v-if="index !== onboarding.length - 1"
                :key="`div-${step.onboardingId}`"
              />
            </template>
          </v-stepper-header>
          <v-stepper-items
            id="#stepper-items"
            class="text-left"
          >
            <v-stepper-content
              v-for="(step, index) in onboarding"
              :key="`${step.onboardingId}-content`"
              :step="index + 1"
            >
              <v-row
                v-if="step.onboardingId == informationStepId && !loadingUserInfo"
              >
                <v-col>
                  <!--<user-template
                    ref="userTemplate"
                    :is-disabled="false"
                    :is-password-required="true"
                    :is-phone-required="true"
                    :is-address-required="true"
                    :show-password-reset="true"
                    :hide-password="true"
                    title="Candidate Information"
                    :user="user"
                    @user-success="notifySuccess"
                  />-->

                  <candidate-template
                    ref="candidateTemplate"
                    :user="user"
                    :candidate="candidate"
                    :show-section-titles="true"
                    :is-phone-required="true"
                    :is-address-required="true"
                    @candidate-success="notifySuccess"
                  />
                </v-col>
              </v-row>
              <v-row
                v-else-if="step.onboardingId == qualificationsStepId && !loadingQualifications"
              >
                <v-col>
                  <candidate-qualifications-template
                    v-if="user != null"
                    ref="candidateQualificationsTemplate"
                  />
                </v-col>
              </v-row>
              <v-row
                v-else-if="step.onboardingId == positionMatchingStepId && !loadingPositionMatching"
              >
                <v-col>
                  <candidate-position-matching-template
                    ref="candidatePositionMatchingTemplate"
                  />
                </v-col>
              </v-row>
              <!-- <v-row
                v-else-if="step.onboardingId == orientationStepId && !loadingTraining"
              >
                <v-col>
                  <span class="text-h5 font-weight-bold">Training Courses</span><br><br>
                  <span>Candidates must complete all required training courses in order to complete onboarding and qualify to apply for Board positions.</span>
                  <training-course-enrollment-table
                    ref="trainingTable"
                    :filters="trainingFilters"
                    class="mt-4"
                  >
                    <template slot-scope="{item}">
                      <v-btn
                        color="success"
                        elevation="0"
                        :outlined="item.completed"
                        @click="openThinkificCourse(item.course_name)"
                      >
                        {{ courseButtonText(item) }}
                      </v-btn>
                    </template>
                  </training-course-enrollment-table>
                </v-col>
              </v-row> -->
              <v-row
                v-else-if="step.onboardingId == releaseFormStepId && !loadingReleaseForm"
              >
                <v-col>
                  <candidate-release-template
                    v-if="user != null"
                    ref="candidateReleaseTemplate"
                    :is-onboarding="true"
                    :candidate="user.candidate"
                  />
                </v-col>
              </v-row>
              <v-row
                v-else
              >
                <v-col cols="5" />
                <v-col cols="2">
                  <v-card
                    color="primary"
                    dark
                  >
                    <v-card-text>
                      Loading, Please stand by
                      <v-progress-linear
                        indeterminate
                        color="white"
                        class="mb-0"
                      />
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  class="text-start"
                  cols="4"
                >
                  <v-btn
                    v-if="index > 0"
                    color="primary"
                    @click="previousStep(index + 1)"
                  >
                    Previous
                  </v-btn>
                </v-col>
                <v-col
                  class="text-center"
                  cols="4"
                >
                  <v-btn
                    color="success"
                    outlined
                    @click="submit(step.onboardingId)"
                  >
                    {{ step.onboardingId === orientationStepId ? 'refresh course status': 'Save' }}
                  </v-btn>
                </v-col>
                <v-col
                  cols="4"
                  class="text-right"
                >
                  <v-btn
                    v-if="index + 1 < onboarding.length"
                    color="primary"
                    @click="nextStep(index + 1)"
                  >
                    Next
                  </v-btn>
                  <v-btn
                    v-else-if="index + 1 == onboarding.length"
                    :disabled="!canFinish"
                    color="success"
                    @click="finish()"
                  >
                    Finish
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
    <general-dialog
      ref="welcomeDialog"
      :title="`Welcome to Altruvest`"
      submit-button-label="Save"
      dialog-width="650px"
      :submit-button-enabled="false"
      :suppress-footer="true"
      @closed="setWelcomeStatus"
    >
      <v-divider class="mt-2 mb-0" />
      <v-row class="pl-4">
        <v-col
          cols="12"
          clas="pa-0"
        />
        <v-col
          class="d-flex justify-start mb-4"
          cols="9"
        >
          <div>
            <span style="font-size: x-large;">Please complete charity onboarding</span><br>
            <span style="font-size: x-large;">to unlock all Boardmatch features.</span>
            <v-checkbox
              v-model="hideWelcome"
              label="Do not show this message again"
              hide-details
            />
          </div>
        </v-col>
        <v-col
          cols="3"
          class="d-flex justify-end"
        >
          <v-img
            :src="require('@/assets/Altruvest Official Logo.svg')"
            width="100px"
            height="100px"
            contain
          />
        </v-col>
      </v-row>
    </general-dialog>
  </v-container>
</template>
<script>
/* eslint-disable no-await-in-loop */

import { mapState, mapGetters } from 'vuex';
// import UserTemplate from '@/components/user/UserTemplate';
import CandidateQualificationsTemplate from '@/components/candidate/qualifications/CandidateQualificationsTemplate.vue';
import CandidatePositionMatchingTemplate from '@/components/candidate/positionMatching/CandidatePositionMatchingTemplate.vue';
import { createPaginationObject } from '@/helpers/PaginationHelper.js';
// import CandidatePositionsUnauthorizedTemplate from '@/components/candidate/charityPosition/CandidatePositionsUnauthorizedTemplate';
import CandidateReleaseTemplate from '@/components/candidate/CandidateReleaseTemplate';
// import TrainingCourseEnrollmentTable from '@/components/training/TrainingCourseEnrollmentTable.vue';
import { openThinkificCourse } from '@/helpers/ThinkificHelper.js';
import CandidateTemplate from '@/components/candidate/CandidateTemplate';
import GeneralDialog from '@/components/general/GeneralDialog';

export default {
  components: {
    // UserTemplate,
    CandidateQualificationsTemplate,
    CandidatePositionMatchingTemplate,
    // CandidatePositionsUnauthorizedTemplate,
    CandidateReleaseTemplate,
    // TrainingCourseEnrollmentTable,
    CandidateTemplate,
    GeneralDialog,
  },

  data: () => ({
    user: null,
    currentStep: 1,
    onboarding: [],
    informationStepId: 1, // as per onboarding database table
    qualificationsStepId: 2, // as per onboarding database table
    positionMatchingStepId: 3, // as per onboarding database table
    orientationStepId: 4, // as per onboarding database table
    releaseFormStepId: 5, // as per onboarding database table
    courseTypeId: 1, // as per ThinkificCourseTypes table, 1 = Candidate
    hideWelcome: false,
    overlay: false,
  }),
  computed: {
    ...mapState({
      // general
      userId: (state) => state.user.nameId,
      candidate: (state) => state.candidate.candidate,
      countries: (state) => state.general.countries,
      provinces: (state) => state.general.regions,
      // qualifications
      educationTypes: (state) => state.candidate.educationTypes,
      experienceContractTypes: (state) => state.candidate.experienceContractTypes,
      experienceLocationTypes: (state) => state.candidate.experienceLocationTypes,
      candidateQualifications: (state) => state.candidate.candidateQualifications,
      filePrivacyTypes: (state) => state.general.filePrivacyTypes,
      // position matching
      candidateSkills: (state) => state.candidate.candidateSkills,
      candidateFocusAreas: (state) => state.candidate.candidateFocusAreas,
      candidateIntroductionTypes: (state) => state.candidate.candidateIntroductionTypes,
      volunteerProgramTypes: (state) => state.candidate.volunteerProgramTypes,
      volunteerExperienceTypes: (state) => state.candidate.volunteerExperienceTypes,
      boardExperienceTypes: (state) => state.candidate.boardExperienceTypes,
      candidateMatchingPreferences: (state) => state.candidate.candidateMatchingPreferences,
      // optional candidate
      ageGroups: (state) => state.candidate.ageGroups,
      minorityGroups: (state) => state.candidate.minorityGroups,
      socialMediaTypes: (state) => state.general.socialMediaTypes,

      // other
      candidateOnboarding: (state) => state.candidate.onboarding,
    }),
    ...mapGetters({
      getSelectedInv: 'user/getSelectedInv',
    }),
    ...mapGetters({
      findClaim: 'auth/findClaim',
    }),
    title() {
      return this.onboardingNeeded ? 'Candidate Onboarding' : 'Candidate Profile';
    },
    onboardingNeeded() {
      const onboarding = this.candidateOnboarding.filter((o) => o.onboardingId !== this.orientationStepId);
      const index = onboarding.findIndex((x) => x.completedPercent < 100);
      if (index > -1) return true;
      return false;
    },
    trainingFilters() {
      const filters = [];
      filters.push({ name: 'UserId', values: [this.userId] });
      filters.push({ name: 'Type', values: [this.courseTypeId] });
      return filters;
    },
    canFinish() {
      let canFinish = false;
      if (this.onboarding != null && this.onboarding.length > 0) {
        canFinish = this.onboarding.findIndex((x) => x.completedPercent !== 100) === -1;
      }
      return canFinish;
    },
    loadingUserInfo() {
      return this.user === null
        || this.candidate === null
        || this.ageGroups == null || this.ageGroups.length === 0
        || this.minorityGroups == null || this.minorityGroups.length === 0
        || this.socialMediaTypes == null || this.socialMediaTypes.length === 0
        || this.countries == null || this.countries.length === 0
        || this.provinces == null || this.provinces.length === 0;
    },
    loadingQualifications() {
      return this.user === null
        || this.candidate === null
        || this.educationTypes == null || this.educationTypes.length === 0
        || this.experienceContractTypes == null || this.experienceContractTypes.length === 0
        || this.experienceLocationTypes == null || this.experienceLocationTypes.length === 0
        || this.candidateQualifications == null || this.candidateQualifications === null
        || this.countries == null || this.countries.length === 0
        || this.provinces == null || this.provinces.length === 0
        || this.filePrivacyTypes == null || this.filePrivacyTypes.length === 0;
    },
    loadingPositionMatching() {
      return this.user === null
        || this.candidate === null
        || this.candidateSkills == null || this.candidateSkills.length === 0
        || this.candidateFocusAreas == null || this.candidateFocusAreas.length === 0
        || this.candidateIntroductionTypes == null || this.candidateIntroductionTypes.length === 0
        || this.volunteerProgramTypes == null || this.volunteerProgramTypes.length === 0
        || this.volunteerExperienceTypes == null || this.volunteerExperienceTypes.length === 0
        || this.boardExperienceTypes == null || this.boardExperienceTypes.length === 0
        || this.candidateMatchingPreferences == null || this.candidateMatchingPreferences.length === 0;
    },
    loadingTraining() {
      return this.user === null
        || this.candidate === null
        || !this.findClaim;
    },
    loadingReleaseForm() {
      return this.user === null
        || this.candidate === null;
    },
  },
  mounted() {
    this.clearReroute();
    this.init();
  },
  methods: {
    async init() {
      this.overlay = true;
      await this.refresh();
      this.modalWelcome();
      this.overlay = !this.overlay;
    },
    async refresh() {
      await Promise.all([
        this.getUser(),
        this.$store.dispatch('candidate/getCandidate', this.externalUserId ? this.externalUserId : this.userId),
      ]);
      await Promise.all([
        this.loadOnboarding(),
        this.loadTypes(),
      ]);
      this.autoComplete();
    },
    async modalWelcome() {
      const suppressWelcome = await this.getWelcomeStatus();
      if (!this.canFinish && !suppressWelcome && this.onboardingNeeded) {
        this.$refs.welcomeDialog.openForm();
      }
    },
    async getWelcomeStatus() {
      const res = await this.$api.candidate.getWelcomeStatus(this.candidate.id);
      return res;
    },
    async setWelcomeStatus() {
      let res = false;
      if (this.hideWelcome) {
        res = await this.$api.candidate.suppressWelcome(this.candidate.id);
      }
      return res;
    },
    async getUser() {
      if (!this.userId) return;
      const response = await this.$api.user.getById(this.userId);
      if (!response) return;
      this.user = response;
    },
    async loadOnboarding() {
      const filters = [{ name: 'CandidateId', values: [this.candidate.id] }, { name: 'IsDisabled', values: [0] }];
      const search = createPaginationObject(null, null, filters);
      const onboarding = await this.$api.candidateOnboarding.read(search);
      this.onboarding = onboarding.filter((o) => o.onboardingId !== this.orientationStepId);
    },
    async loadTypes() {
      const promises = [];
      // user details
      if (!this.candidate) promises.push(this.$store.dispatch('candidate/getCandidate', this.userId));
      if (!this.countries.length) promises.push(this.$store.dispatch('general/getCountries'));
      if (!this.provinces.length) promises.push(this.$store.dispatch('general/getRegion'));
      // qualifications
      if (!this.educationTypes.length) promises.push(this.$store.dispatch('candidate/getEducationTypes'));
      if (!this.experienceContractTypes.length) promises.push(this.$store.dispatch('candidate/getExperienceContractTypes'));
      if (!this.experienceLocationTypes.length) promises.push(this.$store.dispatch('candidate/getExperienceLocationTypes'));
      // position matching
      if (!this.candidateSkills.length) promises.push(this.$store.dispatch('candidate/getCandidateSkills'));
      if (!this.candidateFocusAreas.length) promises.push(this.$store.dispatch('candidate/getCandidateFocusAreas'));
      if (!this.candidateIntroductionTypes.length) promises.push(this.$store.dispatch('candidate/getCandidateIntroductionTypes'));
      if (!this.volunteerExperienceTypes.length) promises.push(this.$store.dispatch('candidate/getVolunteerExperienceTypes'));
      if (!this.boardExperienceTypes.length) promises.push(this.$store.dispatch('candidate/getBoardExperienceTypes'));
      if (!this.volunteerProgramTypes.length) promises.push(this.$store.dispatch('candidate/getVolunteerProgramTypes'));
      if (!this.ageGroups.length) promises.push(this.$store.dispatch('candidate/getAgeGroups'));
      if (!this.minorityGroups.length) promises.push(this.$store.dispatch('candidate/getMinorityGroups'));
      if (!this.socialMediaTypes.length) promises.push(this.$store.dispatch('general/getSocialMediaTypes'));
      if (!this.filePrivacyTypes.length) promises.push(this.$store.dispatch('general/getFilePrivacyTypes'));
      await Promise.all(promises);
    },
    async autoComplete() {
      let updated = false;
      for (let index = 0; index < this.onboarding.length; index += 1) {
        const validatedStep = this.onboarding[index].completedPercent === 100;
        if (this.onboarding[index].onboardingId === this.informationStepId) {
          // validatedStep = await this.$refs.candidateTemplate[0].validate();
        } else if (this.onboarding[index].onboardingId === this.positionMatchingStepId) {
          // validatedStep = await this.$refs.candidatePositionMatchingTemplate[0].validate();
        } else if (this.onboarding[index].onboardingId === this.qualificationsStepId) {
          // validatedStep = await this.$refs.candidateQualificationsTemplate[0].validate(false);
        } else if (this.onboarding[index].onboardingId === this.orientationStepId) {
          // validatedStep = await this.$refs.trainingTable[0].validate(false);
        }
        // not doing this for release form
        if (validatedStep !== (this.onboarding[index].completedPercent === 100) && this.onboarding[index].onboardingId !== this.releaseFormStepId) {
          await this.$api.candidateOnboarding.complete(this.candidate.id, this.onboarding[index].onboardingId, validatedStep);
          updated = true;
        }
      }
      if (updated === true) {
        this.loadOnboarding();
      }
    },
    async nextStep(n) {
      if (n < this.onboarding.length) {
        const stepId = { ...this.onboarding[n - 1] }.onboardingId;
        await this.submit(stepId);
        this.currentStep = n + 1;
        this.goToTop();
      }
    },
    async previousStep(n) {
      if (n > 0) {
        const stepId = { ...this.onboarding[n - 1] }.onboardingId;
        await this.submit(stepId);
        this.currentStep = n - 1;
        this.goToTop();
      }
    },
    icon(step, index) {
      if (index + 1 === this.currentStep) return 'mdi-pencil';
      if (step.completedPercent >= 100) return 'mdi-check';
      return '';
    },
    iconColor() {
      // if (step && step.completedPercent >= 100) return 'success';
      return 'primary';
    },
    async submit(stepId) {
      let res = {
        success: false,
        completed: false,
      };
      if (stepId === this.informationStepId) {
        // reference is an array, because it's inside a for loop, but there will only ever be 1 item in the array
        if (await this.$refs.candidateTemplate[0].validate()) res = await this.$refs.candidateTemplate[0].submit();
      } else if (stepId === this.qualificationsStepId) {
        if (await this.$refs.candidateQualificationsTemplate[0].validate()) res = await this.$refs.candidateQualificationsTemplate[0].submit();
      } else if (stepId === this.positionMatchingStepId) {
        if (await this.$refs.candidatePositionMatchingTemplate[0].validate()) res = await this.$refs.candidatePositionMatchingTemplate[0].submit();
      } else if (stepId === this.orientationStepId) {
        const trainingRes = await this.$refs.trainingTable[0].validate();
        res.success = true;
        res.completed = trainingRes;
      } else if (stepId === this.releaseFormStepId) {
        res = await this.$refs.candidateReleaseTemplate[0].submit();
      }
      if (res.success === true) {
        await this.$api.candidateOnboarding.complete(this.candidate.id, stepId, res.completed);
        await this.refresh();
        if (this.onboarding.length > this.currentStep && this.currentStep !== this.orientationStepId) this.currentStep += 1;
        // this.$refs.stepper.stepClick();
        if (this.onboarding.length > this.currentStep) this.goToTop();
      }
    },
    async goToTop() {
      setTimeout(() => {
        this.goto('title');
      }, 100);
    },
    notifySuccess(message) {
      this.$bus.$emit('showSuccess', message);
      this.$store.dispatch('candidate/getCandidateOnboarding', this.candidate.id);
      this.getUser();
    },
    async openThinkificCourse(courseName = null) {
      openThinkificCourse(courseName);
    },
    async finish() {
      if (this.canFinish === true) {
        this.$store.dispatch('candidate/getCandidateOnboarding', this.candidate.id);
        this.$router.push('/candidate-profile');
        this.$bus.$emit('showSuccess', 'Thank you for completing your onboarding!');
      }
    },
    goto(elementId) {
      const el = document.getElementById(`#${elementId}`);
      if (el != null) el.scrollIntoView({ behavior: 'smooth', block: 'start' });
    },
    clearReroute() {
      if (this.$route.query.forceReroute) {
        const query = { ...this.$route.query };
        delete query.forceReroute;
        this.$router.replace({ query });
      }
    },
    courseButtonText(course) {
      if (course.completed) return 'Retake This Course';
      if (course.percentage_completed > 0.01 && course.percentage_completed < 1) return 'Resume This Course';
      return 'Start This Course';
    },
  },
};
</script>
<style>
  div.v-stepper__step.custom-step span.v-stepper__step__step {
    font-size: 22px !important;
    height: 30px !important;
    width: 30px !important;
  }
  div.v-stepper__step.custom-step.v-stepper__step--editable{
    height: 70px !important;
  }
  .stepper {
    overflow: visible !important;
  }
  .sticky {
    position: sticky;
    top: 122px;
    z-index: 8;
  }
  .v-stepper__header {
    box-shadow: rgba(0, 0, 0, 0) 0px 3px 1px -2px, rgba(0, 0, 0, 0) 0px 2px 2px 0px, rgba(0, 0, 0, 0) 0px 1px 5px 0px !important;
    border-bottom: rgba(0,0,0,0.2) !important;
    border-bottom-width: 2px !important;
    border-bottom-style: solid !important;
    border-top: rgba(0,0,0,0.2) !important;
    border-top-width: 2px !important;
    border-top-style: solid !important;
    background-color: white;
  }
</style>
