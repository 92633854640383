import Api from '../Api';

class UserInformationApi extends Api {
  constructor() {
    super('users/informations', 'userinformations');
  }

  async getUserInfoByUserId(id) {
    const res = await this.getByUserId(id);
    return res;
  }
}

const userInformationApi = new UserInformationApi();
export default userInformationApi;
