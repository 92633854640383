<template>
  <div class="ma-0 pa-0">
    <v-row
      class="pt-0 mt-0"
    >
      <v-col cols="12">
        <social-media-types-table
          ref="socialMediaTypesTable"
        >
          <template slot-scope="{item}">
            <span><a
              class="mr-2"
              @click="openTypeForm(item)"
            >Edit</a>
            </span>
          </template>
        </social-media-types-table>
      </v-col>
    </v-row>
    <general-dialog
      ref="typeFormDialog"
      :title="editingType.id == null ? 'Add New Type' : 'Edit Type'"
      submit-button-label="Save"
      dialog-width="650px"
      :submit-button-enabled="false"
      @submit="submit()"
      @closed="clearEditing()"
    >
      <social-media-type-form
        ref="socialMediaTypeForm"
        :type="editingType"
        :existing-image="editingType.image"
        @close="closeDialogs()"
        @backgroundChanged="loadBackgroundImage()"
        @success="success()"
      />
    </general-dialog>
  </div>
</template>

<script>
import SocialMediaTypesTable from '@/components/boardmatchRep/socialMedia/SocialMediaTypesTable';
import GeneralDialog from '@/components/general/GeneralDialog';
import SocialMediaTypeForm from '@/components/boardmatchRep/socialMedia/SocialMediaTypeForm';

export default {
  components: {
    SocialMediaTypesTable,
    GeneralDialog,
    SocialMediaTypeForm,
  },

  props:
    {
    },
  data: () => ({
    editingType: {
      id: null,
      name: '',
      imageId: null,
      image: null,
    },
  }),
  methods: {
    openTypeForm(type = null) {
      if (type) {
        this.editingType = { ...type, image: type.image.image, imageId: type.image.id };
      } else {
        this.editingType = {
          id: null,
          name: '',
          imageId: null,
          image: null,
        };
      }
      this.$refs.typeFormDialog.openForm();
    },
    closeDialogs() {
      this.$refs.typeFormDialog.closeForm();
      this.editingType = {
        id: null,
        name: '',
        imageId: null,
        image: null,
      };
      this.clearEditing();
    },
    clearEditing() {
      this.editingType = {
        id: null,
        name: '',
        imageId: null,
        image: null,
      };
      this.$refs.socialMediaTypeForm.clear();
    },
    async submit() {
      const res = await this.$refs.socialMediaTypeForm.submit();
      if (!res || !res.valid) this.$bus.$emit('showError', 'Please enter all required details marked in red.');
      else if (!res.successful) this.$bus.$emit('showError', 'Failed to save changes.');
      else {
        this.$bus.$emit('showSuccess', 'Successfully saved changes.');
        this.$refs.socialMediaTypesTable.refresh();
        this.closeDialogs();
      }
    },
    success() {
      this.clearEditing();
      this.closeDialogs();
    },
  },
};
</script>
