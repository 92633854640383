<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      :width="dialogWidth"
      overlay-opacity="0.7"
      :hide-overlay="false"
    >
      <v-card
        fill-height
        :height="dialogHeight"
      >
        <v-container
          fluid
        >
          <v-row
            v-if="showTitle"
          >
            <v-col
              class="text-h4 pl-5 pt-5"
              cols="10"
            >
              <font-awesome-icon
                size="xs"
                :icon="titleIcon"
              />
              {{ title }}
            </v-col>
            <v-col class="text-right">
              <v-btn
                icon
                @click="closeForm"
              >
                <v-icon large>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <slot>
            Missing component
          </slot>
        </v-container>
        <v-card-actions
          v-if="!suppressFooter"
          class="justify-end pb-4"
        >
          <v-btn
            class="mr-4"
            text
            @click="closeForm"
          >
            {{ cancelButtonLabel }}
          </v-btn>
          <v-btn
            v-if="!hideSubmit"
            class="mr-4"
            type="submit"
            color="success"
            @click="submit"
          >
            {{ submitButtonLabel }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

export default {
  components: {
  },
  props:
    {
      user: {
        type: Object,
        default: () => {},
      },
      title: {
        type: String,
        default: '',
      },
      submitButtonLabel: {
        type: String,
        default: 'Submit',
      },
      submitButtonEnabled: {
        type: Boolean,
        default: true,
      },
      cancelButtonLabel: {
        type: String,
        default: 'Cancel',
      },
      dialogWidth: {
        type: String,
        default: '800px',
      },
      dialogHeight: {
        type: String,
        default: '',
      },
      suppressFooter: {
        type: Boolean,
        default: false,
      },
      hideSubmit: {
        type: Boolean,
        default: false,
      },
      titleIcon: {
        type: Array,
        default: () => [],
      },
      showTitle: {
        type: Boolean,
        default: true,
      },
    },

  data: () => ({
    showDialog: false,
  }),

  methods: {
    openForm() {
      this.showDialog = true;
    },
    closeForm() {
      this.showDialog = false;
      this.$emit('closed');
    },
    submit() {
      this.$emit('submit');
    },
    notifySuccess(msg) {
      this.closeForm();
      this.$emit('success', msg);
    },

  },
};
</script>
