var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[(_vm.title)?_c('v-col',{staticClass:"text-h6 pa-6",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_c('v-col',[_c('v-data-table',{staticStyle:{"width":"100%"},attrs:{"headers":_vm.headers,"items":_vm.charities,"footer-props":_vm.footerProps,"items-per-page":_vm.itemsPerPage,"loading":_vm.loading,"server-items-length":_vm.total,"dense":"","option":_vm.options},on:{"pagination":_vm.updatePagination,"update:options":_vm.updateOptions},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{staticStyle:{"font-weight":"bold"},attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.email != null ? item.email.name : "")+" ")])]}},{key:"item.chariableNumber",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.chariableNumber)+" ")])]}},{key:"item.yearOfIncorporation",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.yearOfIncorporation === 0 ? '-' : item.yearOfIncorporation)+" ")])]}},{key:"item.website",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}}),_vm._v(" "+_vm._s(item.website)+" ")]}},{key:"item.scope",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":_vm.chipColor(item.charityScope),"text-color":"white"}},[_vm._v(" "+_vm._s(item.charityScope ? item.charityScope.name : '-')+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.charityType ? item.charityType.name : '-')+" ")])]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({attrs:{"queries":_vm.search}},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.shortAddress.length >= 20 ? ((item.shortAddress.substring(0,20)) + " ...") : item.shortAddress)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.shortAddress))])])]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.phones[0] != null ? ((item.phones[0].number) + " " + (item.phones[0].extension ? "Ext. " + item.phones[0].extension : "")) : "")+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_vm._t("default",null,{"item":item})]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }