<template>
  <v-row>
    <v-col
      v-if="title"
      class="text-h6"
      cols="12"
    >
      {{ title }}
    </v-col>
    <v-col>
      <v-data-table
        :headers="headers"
        :items="postings"
        :footer-props="footerProps"
        :items-per-page="itemsPerPage"
        :loading="loading"
        :server-items-length="total"
        dense
        :option="options"
        style="width:100%"
        @pagination="updatePagination"
        @update:options="updateOptions"
      >
        <template #[`item.charity`]="{ item }">
          <text-highlight :queries="search">
            {{ item.charityPosting.id }}
          </text-highlight>
        </template>
        <template #[`item.boardRole`]="{ item }">
          <text-highlight
            class="font-weight-bold"
            :queries="search"
          >
            {{ item.charityPosition.name }}
          </text-highlight>
        </template>
        <template #[`item.status`]="{ item }">
          <v-chip
            :color="chipColor(item)"
            small
          >
            {{ postingStatus(item) }}
          </v-chip>
        </template>
        <template #[`item.datePosted`]="{ item }">
          <text-highlight :queries="search">
            {{ formatDate(item.datePosted ) }}
          </text-highlight>
        </template>
        <template #[`item.expiryDate`]="{ item }">
          <text-highlight :queries="search">
            {{ formatDate(item.expiryDate ) }}
          </text-highlight>
        </template>
        <template #[`item.startDate`]="{ item }">
          <text-highlight :queries="search">
            {{ formatDate(item.startDate ) }}
          </text-highlight>
        </template>
        <template #[`item.endDate`]="{ item }">
          <text-highlight :queries="search">
            {{ formatDate(item.endDate ) }}
          </text-highlight>
        </template>
        <template #[`item.totalApplicants`]="{ item }">
          <a
            :queries="search"
            @click="navigateToApplication(item.id)"
          >
            {{ item.applicationCount }}
          </a>
        </template>
        <template #[`item.actions`]="{ item }">
          <slot :item="item" />
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import TextHighlight from 'vue-text-highlight';
import { createPaginationObject } from '@/helpers/PaginationHelper.js';

export default {
  components: {
    TextHighlight,
  },
  props: {
    search: {
      default: '',
      type: String,
    },
    title: {
      default: '',
      type: String,
    },
    filters: {
      default: null,
      type: Array,
    },
    charity: {
      type: Object,
      default: () => {},
    },
    includeCharityColumn: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    postings: [],
    footerProps: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    itemsPerPage: 20,
    loading: false,
    pagination: null,
    total: 0,
    options: null,
  }),
  computed: {
    headers() {
      const headers = [];

      headers.push({ text: 'Id', value: 'id', width: 80 });
      headers.push({ text: 'Position', value: 'boardRole', width: 200 });
      // if (this.includeCharityColumn === true) headers.push({ text: 'Charity Name', value: 'charityName' });
      headers.push({
        text: 'Status', value: 'status', width: 100, align: 'center',
      });
      headers.push({ text: 'Posted Date', value: 'datePosted', width: 120 });
      headers.push({ text: 'Expiry Date', value: 'expiryDate', width: 120 });
      headers.push({ text: 'Start Date', value: 'startDate', width: 110 });
      headers.push({ text: 'End Date', value: 'endDate', width: 110 });
      headers.push({
        text: 'Total Applicants', value: 'totalApplicants', width: 120, align: 'center',
      });
      headers.push({
        text: 'Actions',
        value: 'actions',
        align: 'right',
        sortable: false,
        width: 200,
      });

      return headers;
    },

  },
  watch: {
    search(value) {
      if (value) {
        this.getPostings();
      }
    },
    options(value) {
      if (value) {
        this.getPostings();
      }
    },
    filters(value) {
      if (value) {
        this.getPostings();
      }
    },
  },

  methods: {
    async getPostings() {
      this.loading = true;
      const result = await this.$api.charityPostings.read(
        createPaginationObject(this.options, this.search, this.filters),
      );
      this.loading = false;
      if (!result) {
        this.postings = [];
        this.total = 0;
        return;
      }
      this.postings = result.postings;
      this.total = result.total;
    },

    updatePagination(pagination) {
      this.pagination = pagination;
    },
    updateOptions(options) {
      this.options = options;
    },
    refresh() {
      this.getPostings();
    },
    formatDate(date) {
      if (!date) return '';
      return this.$moment(date).format('YYYY-MMM-DD');
    },
    positionExpired(date) {
      return new Date(date) < Date.now();
    },
    postingStatus(posting) {
      let status;
      if (posting.charityPostingStatusId === 2) {
        status = 'FULFILLED';
      } else {
        status = this.positionExpired(posting.expiryDate) ? 'EXPIRED' : 'OPEN';
      }
      return status;
    },
    chipColor(posting) {
      let color;
      if (posting.charityPostingStatusId === 2) {
        color = 'primary';
      } else {
        color = this.positionExpired(posting.expiryDate) ? 'error' : 'success';
      }
      return color;
    },
    navigateToApplication(positionId) {
      this.$emit('navigateToApplication', positionId);
    },
  },
};
</script>

<style></style>
