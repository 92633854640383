<template>
  <v-container fluid>
    <v-row>
      <template
        v-for="filter in availableFilters"
      >
        <v-col
          :key="filter.name"
          :cols="filter.cols"
        >
          <template v-if="filter.type == 'text'">
            <v-text-field
              v-model="filter.value"
              :label="filter.label"
            />
          </template>
          <template v-if="filter.type == 'dropdown'">
            <v-select
              v-model="filter.value"
              :items="filter.options"
              :label="filter.label"
              item-text="name"
              :item-value="filter.valueSource ? filter.valueSource: 'value'"
              :multiple="filter.multiselect === true"
            />
          </template>
          <template v-else-if="filter.type == 'autocomplete'">
            <v-autocomplete
              v-model="filter.value"
              :items="filter.options"
              item-text="name"
              :item-value="filter.valueSource ? filter.valueSource: 'value'"
              :chips="filter.multiselect === true"
              :small-chips="filter.multiselect === true"
              :label="filter.label"
              :multiple="filter.multiselect === true"
            />
          </template>
          <template v-else-if="filter.type == 'radiobuttons'">
            <span>{{ filter.label }}: </span>
            <v-radio-group
              v-model="filter.value"
              row
              class="pa-0 mt-2 mb-0 pb-0"
            >
              <v-radio
                v-for="option in filter.options"
                :key="option.value"
                :label="option.name"
                :value="option.value"
              />
            </v-radio-group>
          </template>
          <template v-else-if="filter.type == 'checkboxes'">
            <div style="margin-bottom: 5px;">
              <span>{{ filter.label }}: {{ stringifyArray(filter) }} - </span>
              <a
                v-if="filter.collapsed == true && filter.collapsable != false"
                @click="filter.collapsed = !filter.collapsed"
              >Show All</a>
              <a
                v-else-if="filter.collapsable != false"
                @click="filter.collapsed = !filter.collapsed"
              >Hide All</a>
              <a
                v-if="filter.value != null && filter.value.length > 0"
                class="ml-2"
                @click="clearValues(filter)"
              > Clear All
              </a>
              <a
                v-if="filter.options != null && filter.value.length < filter.options.length"
                class="ml-2"
                @click="selectAllValues(filter)"
              > Select All
              </a>
            </div>
            <template
              v-if="filter.collapsed == false || filter.collapsable == false"
            >
              <v-row class="ma-0 pa-0">
                <v-col
                  v-for="(chbx, index) in filter.options"
                  :key="index"
                  :cols="perRow(filter)"
                  class="ma-0 pa-0"
                >
                  <v-checkbox
                    :key="filter.name + '-' + index"
                    v-model="filter.value"
                    multiple
                    hide-details
                    :value="filter.valueSource == 'id' ? chbx.id : chbx.value"
                    :label="chbx.name"
                    class="mt-0 compact"
                  />
                </v-col>
              </v-row>
            </template>
          </template>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>

import {
  setInteractionMode,
} from 'vee-validate';

setInteractionMode('eager');

export default {
  components: {
  },
  props:
  {
    filters: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    availableFilters: [],
    collapsedFilters: [],
  }),
  computed: {
    selectedFlters() {
      const filters = [];
      this.availableFilters.forEach((filter) => {
        if (filter.type === 'checkboxes' && filter.value && filter.value.length > 0) {
          filters.push({ name: filter.name, values: filter.value });
        } else if (filter.type === 'text' && filter.value && filter.value !== '') {
          filters.push({ name: filter.name, values: [filter.value] });
        } else if (filter.type === 'dropdown' || filter.type === 'autocomplete') {
          if (filter.multiselect === true && filter.value && filter.value.length > 0) {
            filters.push({ name: filter.name, values: filter.value });
          } else if (filter.multiselect !== true && filter.value && filter.value !== -1) {
            filters.push({ name: filter.name, values: [filter.value] });
          }
        } else if (filter.type === 'dropdown' && filter.value && filter.value !== -1) {
          filters.push({ name: filter.name, values: [filter.value] });
        } else if (filter.type === 'radiobuttons' && filter.value && filter.value !== -1) {
          filters.push({ name: filter.name, values: [filter.value] });
        }
      });
      return filters;
    },
    filterChips() {
      const chips = [];
      this.availableFilters.forEach((filter) => {
        if ((filter.type === 'checkboxes' || filter.multiselect === true) && filter.value && filter.value.length > 0) {
          filter.value.forEach((value) => {
            const index = filter.options.findIndex(((x) => {
              if (filter.valueSource === 'id') {
                return x.id === value;
              }
              return x.value === value;
            }));
            if (index > -1) {
              const newChip = { ...filter.options[index], filterName: filter.name, chipColor: filter.chipColor };
              if (filter.valueSource === 'id') newChip.value = filter.options[index].id;
              chips.push(newChip);
            }
          });
        } else if (filter.type === 'autocomplete' && filter.multiselect !== true && filter.value && filter.value !== '') {
          const index = filter.options.findIndex((x) => x.value === filter.value);
          const chip = {
            filterName: filter.name, id: filter.value, name: filter.options[index].name, chipColor: filter.chipColor,
          };
          chips.push(chip);
        } else if (filter.type === 'text' && filter.value && filter.value !== '') {
          const chip = {
            filterName: filter.name, id: filter.value, name: filter.value, chipColor: filter.chipColor,
          };
          chips.push(chip);
        } else if ((filter.type === 'dropdown' || filter.type === 'radiobuttons') && filter.multiselect !== true && filter.value && filter.value !== -1) {
          const index = filter.options.findIndex(((x) => x.value === filter.value));
          if (index > -1) {
            chips.push({ ...filter.options[index], filterName: filter.name, chipColor: filter.chipColor });
          }
        }
      });
      return chips;
    },

  },
  watch: {
    filters: {
      immediate: true,
      handler(value) {
        this.availableFilters = value;
      },
    },
  },
  created() {
  },
  methods: {
    stringifyArray(filter) {
      let res = '';
      if (filter == null || filter.value == null || filter.value.length === 0) return 'none';
      let first = true;
      filter.value.forEach((element) => {
        const item = filter.options.find((option) => {
          if (filter.valueSource === 'id') {
            return option.id === element;
          } return option.value === element;
        });
        if (item != null) {
          if (first === false) res += ', ';
          res += item.name;
          first = false;
        }
      });
      return res;
    },
    clearValues(filter) {
      const index = this.availableFilters.findIndex((x) => x === filter);
      if (index > -1) {
        this.availableFilters[index].value = [];
      }
    },
    clearForm() {
      for (let i = 0; i < this.availableFilters.length; i += 1) {
        if (this.availableFilters[i].type === 'checkboxes') this.availableFilters[i].value = [];
        else this.availableFilters[i].value = null;
      }
    },
    selectAllValues(filter) {
      const index = this.availableFilters.findIndex((x) => x === filter);
      if (index > -1) {
        const { valueSource } = this.availableFilters[index];
        const all = this.availableFilters[index].options.map((opt) => {
          if (valueSource === 'id') return opt.id;
          return opt.value;
        });
        this.availableFilters[index].value = all;
      }
    },
    perRow(filter) {
      const number = filter.perRow;
      if (number <= 6 && number !== 5) return (12 / number).toString();
      return '3';
    },
  },

};
</script>
<style>
div.v-input.v-text-field.v-autocomplete div.v-input__control div.v-input__slot div.v-select__slot div.v-select__selections input{
  padding-bottom: 8px !important;
  padding-top: 8px !important;
  max-height: 32px !important;
}
</style>
