<template>
  <general-dialog
    ref="charityRepTemplateDialog"
    :title="title"
    :title-icon="titleIcon"
    dialog-width="800"
    :suppress-footer="false"
    @submit="submitRep"
  >
    <charity-rep-information-form
      ref="RepInfoForm"
      :charity="charity"
      :charity-rep="charityRep"
    />
  </general-dialog>
</template>

<script>
import { mapState } from 'vuex';
import {
  setInteractionMode,
} from 'vee-validate';
import GeneralDialog from '@/components/general/GeneralDialog';
import CharityRepInformationForm from '@/components/charity/charityRep/CharityRepInformationForm';

setInteractionMode('eager');
export default {
  components: {
    GeneralDialog,
    CharityRepInformationForm,
  },

  props:
  {
    charityRep: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: '',
    },
    charityId: {
      type: Number,
      default: 0,
    },
    titleIcon: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    charityRepDialog: false,
    isAddressRequired: false,
    isPhoneRequired: false,
    selectedRepRoles: [],
    user: null,
    roles: [],
  }),
  computed: {
    ...mapState({
      charityRepRoles: (state) => state.charityRep.charityRepRoles,
      charity: (state) => state.charity.charity,
    }),
    canAuthAs() {
      const involvement = this.userInvolvements.find((x) => x.involvement === 'boardmatch');
      return involvement != null && this.currentUser != null && this.charityRep != null && this.currentUser.id !== this.charityRep.userId;
    },
    isEditing() {
      return this.charityRep != null;
    },
  },
  watch: {
    charityRep: {
      immediate: true,
      handler(value) {
        if (!value) return;
        this.selectedRepRoles = value.charityRepresentativeRole;
      },
    },
  },

  methods: {
    openCharityRepForm() {
      this.$refs.charityRepTemplateDialog.openForm();
    },
    async submitRep() {
      const rep = await this.$refs.RepInfoForm.submit();
      this.$bus.$emit('loading', false);
      if (!rep) {
        this.$bus.$emit('showError');
        return;
      }
      this.closeForm();
    },
    closeForm() {
      this.$refs.charityRepTemplateDialog.closeForm();
      this.$emit('notify-success', 'Charity Representative invited successfully');
    },
  },
};
</script>
