<template>
  <v-row>
    <v-col
      v-if="title"
      cols="12"
    >
      {{ title }}
    </v-col>
    <v-col>
      <v-data-table
        :headers="headers"
        :items="courseEnrollments"
        :footer-props="footerProps"
        :items-per-page="itemsPerPage"
        :loading="loading"
        :server-items-length="total"
        dense
        :option="options"
        style="width: 100%"
        class="education-table"
        @pagination="updatePagination"
        @update:options="updateOptions"
      >
        <template #[`item.courseName`]="{ item }">
          <text-highlight
            :queries="search"
            class="font-weight-bold"
          >
            {{ item.course_name }}
          </text-highlight>
          <template v-if="requiredCourseIds.findIndex(x => x == item.course_id) > -1">
            <v-chip
              color="error"
              small
              class="ml-1"
              style="margin-top: -2px;"
            >
              Required
            </v-chip>
          </template>
        </template>
        <template #[`item.status`]="{ item }">
          <v-chip
            v-if="item != null"
            small
            :color="setStatus(item).color"
            text-color="white"
          >
            {{ setStatus(item).name }}
          </v-chip>
        </template>
        <template #[`item.percentageCompleted`]="{ item }">
          <v-progress-linear
            :value="item.percentage_completed * 100"
            height="25"
            color="success"
          >
            <strong>{{ Math.round(item.percentage_completed * 100) }}%</strong>
          </v-progress-linear>
        </template>
        <template #[`item.startedDate`]="{ item }">
          <text-highlight :queries="search">
            {{
              item.started_at
                ? moment(item.started_at).format("YYYY-MMM-DD")
                : "-"
            }}
          </text-highlight>
        </template>
        <template #[`item.completedDate`]="{ item }">
          <text-highlight :queries="search">
            {{
              item.completed_at
                ? moment(item.completed_at).format("YYYY-MMM-DD")
                : "-"
            }}
          </text-highlight>
        </template>
        <template #[`item.actions`]="{ item }">
          <slot :item="item" />
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import TextHighlight from 'vue-text-highlight';
import moment from 'moment';
import { createPaginationObject } from '@/helpers/PaginationHelper.js';

export default {
  components: {
    TextHighlight,
  },
  props: {
    search: {
      default: '',
      type: String,
    },
    title: {
      default: '',
      type: String,
    },
    filters: {
      default: null,
      type: Array,
    },
  },
  data: () => ({
    courseEnrollments: [],
    footerProps: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    itemsPerPage: 20,
    loading: false,
    pagination: null,
    total: 0,
    options: null,
    requiredCourseIds: [1679471, 1948700], // , 2009533], // to-do: make this dynamic.
  }),
  computed: {
    headers() {
      const headers = [];
      headers.push({ text: 'Course Name', value: 'courseName' });
      // headers.push({ text: 'Req', value: 'required' });
      headers.push({ text: 'Status', value: 'status' });
      headers.push({ text: 'Completed (%)', value: 'percentageCompleted' });
      headers.push({ text: 'Started Date', value: 'startedDate' });
      headers.push({ text: 'Completed Date', value: 'completedDate' });
      headers.push({
        text: 'Actions',
        value: 'actions',
        align: 'right',
        sortable: false,
        width: '15%',
      });

      return headers;
    },
  },
  watch: {
    search(value) {
      if (value) {
        this.getCourseEnrollments();
      }
    },
    options(value) {
      if (value) {
        this.getCourseEnrollments();
      }
    },
    filters(value) {
      if (value) {
        this.getCourseEnrollments();
      }
    },
  },

  methods: {
    async getCourseEnrollments() {
      const result = await this.$api.thinkificEnrollments.read(
        createPaginationObject(null, null, this.filters),
      );

      if (!result) {
        this.courseEnrollments = [];
        this.total = 0;
        this.loading = false;
        return;
      }
      this.courseEnrollments = result;
      // this.total = result.total;
      this.total = result.length;
      this.loading = false;
    },
    updatePagination(pagination) {
      this.pagination = pagination;
    },
    updateOptions(options) {
      this.options = options;
    },
    refresh() {
      this.getCourseEnrollments();
      const incompleteEnrollmentIndex = this.courseEnrollments.findIndex((x) => this.requiredCourseIds.findIndex((y) => y === x.course_id) > -1 && x.percentage_completed < 1);
      if (incompleteEnrollmentIndex > -1) this.$bus.$emit('showError', 'Please enter all required fields marked red');
    },
    moment() {
      return moment();
    },
    setStatus(item) {
      if (item) {
        let status = {};
        if (item.percentage_completed === 1) {
          (status = { name: 'Completed', color: 'success' });
        } else if (item.percentage_completed !== 0
            && item.percentage_completed !== 1
            && item.started_at != null) {
          (status = { name: 'In Progress', color: 'primary' });
        } else {
          (status = { name: 'Not Started', color: 'orange' });
        }
        return status;
      }
      return null;
    },
    async validate(showError = true) {
      await this.getCourseEnrollments();
      if (this.courseEnrollments == null || this.courseEnrollments.length === 0) return false;
      const incompleteEnrollmentIndex = this.courseEnrollments.findIndex((x) => this.requiredCourseIds.findIndex((y) => y === x.course_id) > -1 && x.percentage_completed < 1);
      if (incompleteEnrollmentIndex > -1) {
        if (showError) this.$bus.$emit('showError', 'One or more mandatory courses not yet complete');
        return false;
      }
      if (showError) this.$bus.$emit('showSuccess', 'Successfully completed all required training.');
      return true;
    },
  },
};
</script>

<style>

  div.v-data-table.education-table div.v-data-table__wrapper table tbody tr {
    height: 50px !important;
  }
</style>
