<template>
  <div class="ma-0 pa-0 text-start">
    <validation-observer ref="postingValObserver">
      <v-row>
        <v-col>
          <span
            class="text-h5 font-weight-bold"
          >Education Details</span>
        </v-col>
      </v-row>
      <!-- <v-row v-if="submitCandidateQualifications.candidateEducation != null && submitCandidateQualifications.candidateEducation.length == 0">
        <v-col class="red--text">
          Must add at least one education
        </v-col>
      </v-row> -->
      <v-row
        v-for="(education, index) in submitCandidateQualifications.candidateEducation"
        :key="'edu-' + index"
        class="mt-3 mb-1 framed-content"
      >
        <v-col
          cols="1"
          class="row-number-col d-flex justify-start align-start text-h6"
        >
          <span class="row-number">
            {{ index + 1 }}.
          </span>
        </v-col>
        <v-col cols="10">
          <v-row>
            <v-col class="ma-0 mr-2 pa-0">
              <validation-provider
                v-slot="{ errors }"
                :name="'Education Type ' + index"
                rules="required"
              >
                <v-select
                  v-model="education.candidateEducationTypeId"
                  item-value="id"
                  :error-messages="errors"
                  :items="educationTypes"
                  class="mt-0 d-grid"
                  :item-text="(item) => getEducationName(item.id)"
                  hide-details
                >
                  <template #label>
                    Education Level<red-asterisk />
                  </template>
                </v-select>
              </validation-provider>
            </v-col>
            <v-col
              class="ma-0 pa-0 pr-2"
            >
              <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="education.start"
                    readonly
                    v-bind="attrs"
                    hide-details
                    class="mt-0 compact"
                    v-on="on"
                    @click="activeStartDatePicker = index"
                  >
                    <template #label>
                      Start Date
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-if="activeStartDatePicker === index"
                  v-model="education.start"
                  :max="education.end"
                  @input="activeStartDatePicker = -1"
                />
              </v-menu>
            </v-col>
            <v-col
              class="ma-0 pa-0 pr-2"
            >
              <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="education.end"
                    label="End Date"
                    readonly
                    hide-details
                    class="mt-0 compact"
                    v-bind="attrs"
                    v-on="on"
                    @click="activeEndDatePicker = index"
                  />
                </template>
                <v-date-picker
                  v-if="activeEndDatePicker === index"
                  v-model="education.end"
                  label="Select End Date"
                  :min="education.start"
                  @input="activeEndDatePicker = -1"
                />
              </v-menu>
            </v-col>
          </v-row>
          <v-row class="mt-6">
            <v-col
              class="ma-0 mr-2 pa-0"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="'Education Type ' + index"
                rules="required"
              >
                <v-text-field
                  :key="'edu-inst-' + index"
                  v-model="education.institution"
                  hide-details
                  class="mt-0 compact"

                  :error-messages="errors"
                  :readonly="readOnly"
                  :style="readOnly ? 'pointer-events: none' : ''"
                >
                  <template #label>
                    Name of institution<red-asterisk />
                  </template>
                </v-text-field>
              </validation-provider>
            </v-col>

            <v-col
              class="ma-0 mr-2 pa-0"
            >
              <v-text-field
                :key="'edu-desig-' + index"
                v-model="education.designation"
                hide-details
                class="mt-0 compact"
                :readonly="readOnly"
                :style="readOnly ? 'pointer-events: none' : ''"
              >
                <template #label>
                  Diploma/Degree
                </template>
              </v-text-field>
            </v-col>

            <v-col
              class="ma-0 pa-0"
            >
              <v-text-field
                :key="'edu-area-' + index"
                v-model="education.areaOfStudy"
                hide-details
                class="mt-0 compact"
                :readonly="readOnly"
                :style="readOnly ? 'pointer-events: none' : ''"
              >
                <template #label>
                  Area of Study
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="ml-0 pl-0">
              <div class="mt-3">
                <span
                  class="subtitle-2 mb-4 ml-0"
                  style="color: rgb(100,100,100); margin-top: 0px;"
                >Skills:</span>
                <template
                  v-if="education.skills && education.skills.length > 0"
                >
                  <v-chip
                    v-for="(skill, skillIndex) in education.skills"
                    :key="'edu-' + index + '-skill-' + skillIndex"
                    small
                    color="primary"
                    class="ml-2"
                    close
                    @click:close="removeFilterChip(index, 'education', skillIndex)"
                  >
                    {{ skillName(skill) }}
                  </v-chip>
                </template>
                <v-chip
                  small
                  class="ml-2"
                  color="primary"
                  outlined
                  @click="openSkillSelector(index, 'education')"
                >
                  +
                </v-chip>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="1"
          class="d-flex justify-end"
        >
          <span class="row-number">
            <v-icon
              color="red"
              class="mr-1 mb-1"
              @click="removeEducation(index)"
            >
              mdi-close-thick
            </v-icon>
          </span>
        </v-col>
      </v-row>
      <v-row class="mb-8">
        <v-col class="add-row-button">
          <v-btn
            elevation="0"
            outlined
            color="success"
            @click="addEducation()"
          >
            ADD Another Education
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span
            class="text-h5 font-weight-bold"
          >Experience</span>
        </v-col>
      </v-row>
      <v-row v-if="submitCandidateQualifications.candidateExperience != null && submitCandidateQualifications.candidateExperience.length == 0">
        <v-col class="red--text">
          Must add at least one piece of experience
        </v-col>
      </v-row>
      <v-row
        v-for="(experience, index) in submitCandidateQualifications.candidateExperience"
        :key="'exp-' + index"
        class="mb-1 framed-content"
      >
        <v-col
          cols="1"
          class="row-number-col d-flex justify-start align-start"
        >
          <span class="row-number">
            {{ index + 1 }}.
          </span>
        </v-col>
        <v-col cols="10">
          <v-row>
            <v-col class="ma-0 mr-2 pa-0">
              <!-- <validation-provider
                v-slot="{ errors }"
                :name="'Experience Contract Type ' + index"
                rules="required"
              >
                <v-select
                  v-model="experience.candidateExperienceContractTypeId"
                  item-value="id"
                  :items="experienceContractTypes"
                  :error-messages="errors"
                  class="mt-0 d-grid"
                  :item-text="(item) => getExperienceContractName(item.id)"
                  hide-details
                >
                  <template #label>
                    Contract Type<red-asterisk />
                  </template>
                </v-select>
              </validation-provider> -->
              <v-select
                v-model="experience.candidateExperienceContractTypeId"
                item-value="id"
                :items="experienceContractTypes"
                class="mt-0 d-grid"
                :item-text="(item) => getExperienceContractName(item.id)"
                hide-details
              >
                <template #label>
                  Contract Type
                </template>
              </v-select>
            </v-col>
            <v-col
              class="ma-0 pa-0 pr-2"
            >
              <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="experience.start"
                    readonly
                    v-bind="attrs"
                    hide-details
                    class="mt-0 compact"
                    v-on="on"
                    @click="activeStartDatePicker = index"
                  >
                    <template #label>
                      Start Date
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-if="activeStartDatePicker === index"
                  v-model="experience.start"
                  :max="experience.end"
                  @input="activeStartDatePicker = -1"
                />
              </v-menu>
            </v-col>
            <v-col
              class="ma-0 pa-0 pr-2"
            >
              <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="experience.end"
                    label="End Date"
                    readonly
                    hide-details
                    class="mt-0 compact"
                    v-bind="attrs"
                    v-on="on"
                    @click="activeEndDatePicker = index"
                  />
                </template>
                <v-date-picker
                  v-if="activeEndDatePicker === index"
                  v-model="experience.end"
                  label="Select End Date"
                  :min="experience.start"
                  @input="activeEndDatePicker = -1"
                />
              </v-menu>
            </v-col>
          </v-row>
          <v-row class="mt-6">
            <v-col
              class="ma-0 mr-2 pa-0"
              cols="3"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="'Experience Title ' + index"
                rules="required"
              >
                <v-text-field
                  :key="'exp-title-' + index"
                  v-model="experience.title"
                  hide-details
                  :error-messages="errors"
                  class="mt-0 compact"
                  :readonly="readOnly"
                  :style="readOnly ? 'pointer-events: none' : ''"
                >
                  <template #label>
                    Title/Position<red-asterisk />
                  </template>
                </v-text-field>
              </validation-provider>
            </v-col>

            <v-col
              class="ma-0 mr-2 pa-0"
              cols="3"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="'Experience Organization Name ' + index"
                rules="required"
              >
                <v-text-field
                  :key="'exp-org-' + index"
                  v-model="experience.organizationName"
                  hide-details
                  :error-messages="errors"
                  class="mt-0 compact"
                  :readonly="readOnly"
                  :style="readOnly ? 'pointer-events: none' : ''"
                >
                  <template #label>
                    Organization Name<red-asterisk />
                  </template>
                </v-text-field>
              </validation-provider>
            </v-col>

            <v-col
              class="ma-0 pa-0"
            >
              <v-text-field
                :key="'exp-desc-' + index"
                v-model="experience.description"
                hide-details
                class="mt-0 compact"
                :readonly="readOnly"
                :style="readOnly ? 'pointer-events: none' : ''"
              >
                <template #label>
                  Job Summary
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-6">
            <v-col
              class="ma-0 mr-2 pa-0"
              cols="3"
            >
              <v-select
                v-model="experience.candidateExperienceLocationTypeId"
                item-value="id"
                :items="experienceLocationTypes"
                label="Location Type"
                class="mt-0 d-grid"
                :item-text="(item) => getExperienceLocationName(item.id)"
                hide-details
              />
            </v-col>

            <v-col
              v-if="experience.candidateExperienceLocationTypeId == physicalLocationTypeId"
              class="ma-0 mr-2 pa-0"
            >
              <v-text-field
                :key="'exp-loc-cit' + index"
                v-model="experience.address.city"
                hide-details
                class="mt-0 compact"
                :readonly="readOnly"
                :style="readOnly ? 'pointer-events: none' : ''"
              >
                <template #label>
                  City
                </template>
              </v-text-field>
            </v-col>

            <v-col
              v-if="experience.candidateExperienceLocationTypeId == physicalLocationTypeId"
              class="ma-0 mr-2 pa-0"
            >
              <v-select
                v-model="experience.address.countryId"
                item-value="id"
                :items="countries"
                label="Country"
                class="mt-0 d-grid"
                :item-text="(item) => getCountryName(item.id)"
                hide-details
              >
                <template #label>
                  Country
                </template>
              </v-select>
            </v-col>

            <v-col
              v-if="experience.candidateExperienceLocationTypeId == physicalLocationTypeId"
              class="ma-0 mr-2 pa-0"
            >
              <v-select
                v-model="experience.address.regionId"
                :disabled="experience.address.countryId == null"
                item-value="id"
                :items="filteredRegions(experience.address.countryId)"
                label="Region"
                class="mt-0 d-grid"
                :item-text="(item) => getRegionName(item.id)"
                hide-details
              >
                <template #label>
                  Region
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="ml-0 pl-0">
              <div class="mt-3">
                <span
                  class="subtitle-2 mb-4"
                  style="color: rgb(100,100,100); margin-top: 0px;"
                >Skills:</span>
                <template
                  v-if="experience.skills && experience.skills.length > 0"
                >
                  <v-chip
                    v-for="(skill, skillIndex) in experience.skills"
                    :key="'exp-' + index + '-skill-' + skillIndex"
                    small
                    class="ml-2"
                    close
                    color="primary"
                    @click:close="removeFilterChip(index, 'experience', skillIndex)"
                  >
                    {{ skillName(skill) }}
                  </v-chip>
                </template>
                <v-chip
                  small
                  class="ml-2"
                  color="primary"
                  outlined
                  @click="openSkillSelector(index, 'experience')"
                >
                  +
                </v-chip>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="1"
          class="d-flex justify-end"
        >
          <span>
            <v-icon
              color="red"
              class="mr-1 mb-1"
              @click="removeExperience(index)"
            >
              mdi-close-thick
            </v-icon>
          </span>
        </v-col>
      </v-row>
      <v-row class="mb-8">
        <v-col class="add-row-button">
          <v-btn
            outlined
            color="success"
            elevation="0"
            @click="addExperience()"
          >
            ADD Another Experience
          </v-btn>
        </v-col>
      </v-row>
      <span class="text-h5 font-weight-bold">Resume: </span><br>
      <!-- <validation-provider
        v-slot="{ errors}"
        :name="'ResumeValidator'"
        rules="required"
      >
        <v-text-field
          v-show="false"
          v-model="candidate.resumes"
        />
        <span
          v-if="errors.length > 0 "
          class="red--text"
        >
          Must upload a resume.
        </span>
      </validation-provider> -->
      <v-row
        v-if="candidate.resumes != null && candidate.resumes.length > 0"
        class="mb-2"
      >
        <v-col
          cols="9"
          class="ma-0 pa-0 mt-4 pl-5"
          style="display: flex; align-items: center;"
        >
          <v-icon
            color="red"
            class="mr-1 mb-0"
            @click="deleteFile(candidate.resumes[0].id)"
          >
            mdi-close-thick
          </v-icon>
          <a @click="loadFile(candidate.resumes[0].id, candidate.resumes[0].originalName)">{{ candidate.resumes[0].originalName }} &nbsp; </a>
          <span style="color: grey;"> (uploaded on {{ formatDate(candidate.resumes[0].uploadedOn) }})&nbsp;</span>
          <span>{{ privacyDescription(candidate.resumes[0].fileVisibilityId) }}</span>
        </v-col>
        <v-col
          cols="3"
          class="ma-0 pa-0 mt-4 pl-5 pb-1"
        >
          <v-select
            v-model="candidate.resumes[0].fileVisibilityId"
            :items="filePrivacyTypes"
            item-text="name"
            item-value="id"
            dense
            hide-details=""
            @input="(value) => setVisibility(candidate.resumes[0].id, value)"
          >
            <template #label>
              Visibility
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row
        v-else
        class="mb-8 mt-0"
      >
        <v-col
          cols="12"
          class="ma-0 pa-0 pl-5 pr-5"
        >
          <general-upload-widget
            :multiple="false"
            entity-type="candidate"
            :entity-id="candidate.id"
            file-type="resume"
            class="mt-5"
            @success="(result) => fileUploaded(result)"
          />
        </v-col>
      </v-row>
      <!--<span class="text-h5 font-weight-bold">Text only resume:</span>
      <v-row class="mt-3 mb-8">
        <v-col
          cols="11"
          class="ma-0 pa-0 pl-5"
        >
          <v-textarea
            v-model="submitCandidateQualifications.resume"
            hide-details
          />
        </v-col>
      </v-row>-->
    </validation-observer>
    <v-dialog
      v-if="editingSkillsIndex > -1"
      v-model="editSkillsDialog"
      width="500px"
    >
      <v-card
        fill-height
      >
        <v-container
          fluid
        >
          <v-row>
            <v-col>
              <span class="text-h4">Select Skills Learned</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-chip
                v-for="skill in candidateSkills"
                :key="skill.id"
                :class="skillSelected(skill) ? 'primary' : ''"
                small
                class="ml-2 mt-1"
                @click="selectSkill(skill)"
              >
                {{ skill.name }}
              </v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              class="text-end"
            >
              <v-btn
                class="mr-4 default"
                elevation="0"
                @click="closeForm"
              >
                Ok
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { required } from 'vee-validate/dist/rules';
import { mapState } from 'vuex';
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  // setInteractionMode,
} from 'vee-validate';
import GeneralUploadWidget from '@/components/general/GeneralUploadWidget';

// setInteractionMode('immediate');
extend('required', {
  ...required,
  message: 'Must select at least one {_field_}',
});
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    GeneralUploadWidget,
  },
  props:
    {
      readOnly: {
        type: Boolean,
        default: false,
      },
    },
  data: () => ({
    submitCandidateQualifications: {
      id: 0,
      candidateId: 0,
      candidateEducation: [],
      candidateExperience: [],
      resume: '',
    },
    loaded: false, // to prevent watcher from breaking the initial loading of education values
    activeStartDatePicker: -1,
    activeEndDatePicker: -1,
    editingSkillsIndex: -1,
    editSkillsDialog: false,
    editingEntryType: null,
    physicalLocationTypeId: 1,
  }),
  computed: {
    ...mapState({
      educationTypes: (state) => state.candidate.educationTypes,
      experienceContractTypes: (state) => state.candidate.experienceContractTypes,
      experienceLocationTypes: (state) => state.candidate.experienceLocationTypes,
      candidate: (state) => state.candidate.candidate,
      candidateQualifications: (state) => state.candidate.candidateQualifications,
      candidateSkills: (state) => state.candidate.candidateSkills,
      countries: (state) => state.general.countries,
      regions: (state) => state.general.regions,
      filePrivacyTypes: (state) => state.general.filePrivacyTypes,
    }),
  },

  watch: {
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      const emptyAddress = {
        id: null,
        address1: '',
        address2: '',
        city: '',
        regionId: null,
        countryId: null,
        code: '',
        userInformationId: null,
        charityId: null,
        corporationId: null,
      };
      this.submitCandidateQualifications = {
        ...this.candidateQualifications,
        candidateEducation: (
          this.candidateQualifications.candidateEducation != null && this.candidateQualifications.candidateEducation.length > 0
            ? this.candidateQualifications.candidateEducation.map((x) => ({
              ...x,
              start: x.start === '0001-01-01T00:00:00' || x.start === null ? null : this.$moment(x.start).format('YYYY-MM-DD'),
              end: x.end === '0001-01-01T00:00:00' || x.end === null ? null : this.$moment(x.end).format('YYYY-MM-DD'),
              skills: x.skills != null && x.skills.length > 0 ? x.skills.map((skill) => skill.id) : [],
            }))
            : []
            // : [{
            //   areaOfStudy: '',
            //   candidateEducationTypeId: null,
            //   candidateEducationTypeName: null,
            //   candidateQualificationsId: this.candidateQualifications.id,
            //   designation: '',
            //   start: null,
            //   end: null,
            //   id: null,
            //   image: null,
            //   imageId: null,
            //   institution: '',
            //   skills: [],
            // }]
        ),
        candidateExperience: (
          this.candidateQualifications.candidateExperience != null && this.candidateQualifications.candidateExperience.length > 0 ? this.candidateQualifications.candidateExperience.map((x) => ({
            ...x,
            start: x.start === '0001-01-01T00:00:00' || x.start === null ? null : this.$moment(x.start).format('YYYY-MM-DD'),
            end: x.end === '0001-01-01T00:00:00' || x.end === null ? null : this.$moment(x.end).format('YYYY-MM-DD'),
            address: x.address != null ? x.address : { ...emptyAddress },
            skills: x.skills != null && x.skills.length > 0 ? x.skills.map((skill) => skill.id) : [],
          })) : [{
            id: null,
            title: '',
            organizationName: '',
            description: '',
            candidateExperienceContractTypeId: null,
            candidateExperienceLocationTypeId: this.physicalLocationTypeId,
            address: {
              id: null,
              address1: '',
              address2: '',
              city: '',
              regionId: null,
              countryId: null,
              code: '',
              userInformationId: null,
              charityId: null,
              corporationId: null,
            },
            candidateQualificationsId: this.candidateQualifications.id,
            start: null,
            end: null,
            skills: [],
          }]
        ),
        resume: this.candidateQualifications.resume ? this.candidateQualifications.resume : '',
      };
    },
    async submit() {
      const res = await this.$api.candidateQualifications.update(this.submitCandidateQualifications);
      return res;
    },
    getEducationName(educationId) {
      const education = this.educationTypes.find((x) => x.id === educationId);
      if (education) return education.name;
      return '';
    },
    getExperienceContractName(contractId) {
      const contractType = this.experienceContractTypes.find((x) => x.id === contractId);
      if (contractType) return contractType.name;
      return '';
    },
    getExperienceLocationName(contractId) {
      const locationType = this.experienceLocationTypes.find((x) => x.id === contractId);
      if (locationType) return locationType.name;
      return '';
    },
    getCountryName(countryId) {
      return this.countries.find((x) => x.id === countryId).name;
    },
    filteredRegions(countryId) {
      if (countryId) return this.regions.filter((x) => x.countryId === countryId);
      return [];
    },
    getRegionName(regionId) {
      return this.regions.find((x) => x.id === regionId).name;
    },
    resetForm() {
      this.candidateSkills = [];
      this.candidateFocusAreas = [];
    },
    addEducation() {
      const newEducation = {
        areaOfStudy: '',
        candidateEducationTypeId: null,
        candidateEducationTypeName: null,
        candidateQualificationsId: this.submitCandidateQualifications.id,
        designation: '',
        start: null,
        end: null,
        id: null,
        image: null,
        imageId: null,
        institution: '',
        skills: [],
      };
      this.submitCandidateQualifications.candidateEducation.push(newEducation);
    },
    addExperience() {
      const newExperience = {
        id: null,
        title: '',
        organizationName: '',
        description: '',
        candidateExperienceContractTypeId: null,
        candidateExperienceLocationTypeId: this.physicalLocationTypeId,
        address: {
          id: null,
          address1: '',
          address2: '',
          city: '',
          regionId: null,
          countryId: null,
          code: '',
          userInformationId: null,
          charityId: null,
          corporationId: null,
        },
        candidateQualificationsId: this.submitCandidateQualifications.id,
        start: null,
        end: null,
        skills: [],
      };
      this.submitCandidateQualifications.candidateExperience.push(newExperience);
    },
    openSkillSelector(index, source) {
      this.editingEntryType = source;
      this.editingSkillsIndex = index;
      this.editSkillsDialog = true;
    },
    skillSelected(skill) {
      if (this.editingEntryType === 'education' && this.submitCandidateQualifications.candidateEducation[this.editingSkillsIndex].skills.findIndex((x) => x === skill.id) > -1) return true;
      if (this.editingEntryType === 'experience' && this.submitCandidateQualifications.candidateExperience[this.editingSkillsIndex].skills.findIndex((x) => x === skill.id) > -1) return true;
      return false;
    },
    selectSkill(skill) {
      if (this.editingEntryType === 'education') {
        const index = this.submitCandidateQualifications.candidateEducation[this.editingSkillsIndex].skills.findIndex((x) => x === skill.id);
        if (index === -1) this.submitCandidateQualifications.candidateEducation[this.editingSkillsIndex].skills.push(skill.id);
        else this.removeFilterChip(this.editingSkillsIndex, this.editingEntryType, index);
      } else if (this.editingEntryType === 'experience') {
        const index = this.submitCandidateQualifications.candidateExperience[this.editingSkillsIndex].skills.findIndex((x) => x === skill.id);
        if (index === -1) this.submitCandidateQualifications.candidateExperience[this.editingSkillsIndex].skills.push(skill.id);
        else this.removeFilterChip(this.editingSkillsIndex, this.editingEntryType, index);
      }
    },
    removeFilterChip(index, source, skillIndex) {
      if (source === 'education') this.submitCandidateQualifications.candidateEducation[index].skills.splice(skillIndex, 1);
      else if (source === 'experience') this.submitCandidateQualifications.candidateExperience[index].skills.splice(skillIndex, 1);
    },
    skillName(skillId) {
      const index = this.candidateSkills.findIndex((x) => x.id === skillId);
      if (index > -1) return this.candidateSkills[index].name;
      return 'unknown';
    },
    removeEducation(index) {
      this.submitCandidateQualifications.candidateEducation.splice(index, 1);
    },
    removeExperience(index) {
      this.submitCandidateQualifications.candidateExperience.splice(index, 1);
    },
    async validate(showError = true) {
      const res = await this.$refs.postingValObserver.validate();
      if (!res) {
        if (showError) this.$bus.$emit('showError', 'Please enter all required fields marked red');
        return false;
      }
      // if (this.submitCandidateQualifications.candidateEducation == null
      //   || this.submitCandidateQualifications.candidateEducation.length === 0) {
      //   if (showError) this.$bus.$emit('showError', 'Please add at least one education');
      //   return false;
      // }
      if (this.submitCandidateQualifications.candidateExperience == null
        || this.submitCandidateQualifications.candidateExperience.length === 0) {
        if (showError) this.$bus.$emit('showError', 'Please add at least one piece of work experience');
        return false;
      }
      /* let index = this.submitCandidateQualifications.candidateEducation.findIndex((x) => x.skills == null || x.skills.length === 0);
      if (index > -1) {
        if (showError) this.$bus.$emit('showError', `Please specify skills you learned when studying at ${this.submitCandidateQualifications.candidateEducation[index].institution}.`);
        return false;
      }
      index = this.submitCandidateQualifications.candidateExperience.findIndex((x) => x.skills == null || x.skills.length === 0);
      if (index > -1) {
        if (showError) this.$bus.$emit('showError', `Please specify skills you learned when working at at ${this.submitCandidateQualifications.candidateExperience[index].organizationName}.`);
        return false;
      } */
      // if (this.candidate.resumes == null
      //   || this.candidate.resumes.length === 0) {
      //   if (showError) this.$bus.$emit('showError', 'Please add your resume and set its visibility level.');
      //   return false;
      // }
      return true;
    },
    fileUploaded(result) {
      this.$set(this.candidate.resumes, 0, result);
      this.validate(false);
    },
    closeForm() {
      this.editingSkillsIndex = -1;
      this.editSkillsDialog = false;
    },
    formatDate(datetime) {
      return this.$moment(datetime).format('YYYY-MM-DD');
    },
    privacyDescription(fileVisibilityId) {
      if (this.filePrivacyTypes == null || this.filePrivacyTypes.length === 0) return '';

      const index = this.filePrivacyTypes.findIndex((x) => x.id === fileVisibilityId);
      if (index > -1) return ` - ${this.filePrivacyTypes[index].description}`;

      return '';
    },
    async loadFile(id, fileName) {
      await this.$api.file.getFile(id, fileName);
    },
    async deleteFile(id) {
      const res = await this.$api.file.softDelete(id);
      if (res === true) this.candidate.resumes = [];
    },
    async setVisibility(fileId, visibilityId) {
      const patchObjects = [{ op: 'replace', path: '/fileVisibilityId', value: visibilityId }];
      const res = await this.$api.file.patch(fileId, patchObjects);
      if (res) this.$set(this.candidate.resumes, 0, res);
    },
  },
};
</script>
<style scoped>
  .row-number{
    font-size: 22px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.87)  !important;
  }
  .row-number-col {
    display: grid !important;
    align-content: center !important;
  }
  .add-row-button {
    display: grid !important;
    justify-content: center !important;
  }
  .framed-content {
    border-style: solid;
    border-color: lightgrey;
    border-width: 1px;
    padding-top: 10px;
    margin-left: 1px;
    margin-right: 1px;
    padding-bottom: 20px;
  }
</style>
