<template>
  <v-container fluid>
    <validation-observer
      ref="contactObserver"
    >
      <v-row>
        <v-col
          v-if="showFirstName"
          class="pt-0 pb-0"
        >
          <validation-provider
            v-slot="{ errors }"
            name="First Name"
            rules="required"
          >
            <v-text-field
              v-model="contactData.firstName"
              required
              :error-messages="errors"
              label="First Name"
            />
          </validation-provider>
        </v-col>
        <v-col
          v-if="showLastName"
          class="pt-0 pb-0"
        >
          <validation-provider

            v-slot="{ errors }"
            name="Last Name"
            rules="required"
          >
            <v-text-field
              v-model="contactData.lastName"
              :error-messages="errors"
              label="Last Name"
              required
            />
          </validation-provider>
        </v-col>
        <v-col class="pt-0 pb-0">
          <validation-provider
            v-slot="{ errors }"
            name="Daytime Phone"
            rules="required"
          >
            <v-text-field
              v-model="contactData.daytimePhone"
              required
              :error-messages="errors"
              label="Daytime phone"
              type="number"
            >
              <template #label>
                Daytime phone<span class="red--text"><strong>* </strong></span>
              </template>
            </v-text-field>
          </validation-provider>
        </v-col>
        <v-col class="pt-0 pb-0">
          <validation-provider
            name="Ext."
          >
            <v-text-field
              v-model="contactData.extension"
              label="Ext."
            />
          </validation-provider>
        </v-col>
        <v-col class="pt-0 pb-0">
          <validation-provider
            v-slot="{ errors }"
            name="Title"
            rules="required"
          >
            <v-text-field
              v-model="contactData.title"
              required
              :error-messages="errors"
              label="Title"
            >
              <template #label>
                Title<span class="red--text"><strong>* </strong></span>
              </template>
            </v-text-field>
          </validation-provider>
        </v-col>
        <v-col class="pt-0 pb-0">
          <validation-provider
            v-if="showEmail"
            v-slot="{ errors }"
            name="Email"
            rules="required|email"
          >
            <v-text-field
              v-model="contactData.email"
              :error-messages="errors"
              label="Email"
              required
            />
          </validation-provider>
        </v-col>
      </v-row>
    </validation-observer>
  </v-container>
</template>

<script>
import { required, email } from 'vee-validate/dist/rules';
import {
  extend, ValidationObserver, ValidationProvider, setInteractionMode,
} from 'vee-validate';

setInteractionMode('eager');

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
});

extend('email', {
  ...email,
  message: 'Email must be valid',
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props:
  {
    contact: {
      type: Object,
      default: () => {},
    },
    showFirstName: {
      type: Boolean,
      default: true,
    },
    showLastName: {
      type: Boolean,
      default: true,
    },
    showEmail: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    contactData: null,
  }),
  watch: {
    contact: {
      immediate: true,
      handler(value) {
        this.contactData = value
        || {
          id: 0,
          firstName: '',
          lastName: '',
          daytimePhone: '',
          extension: '',
          title: '',
          email: '',
          isPrimary: 0,
          isSeniorStaff: 0,
        };
      },
    },
  },
  methods: {
    async validate() {
      const res = await this.$refs.contactObserver.validate();
      return res;
    },

    async submit(charityId = null, corporationId = null, isSeniorStaff = 0, isPrimaryContact = 0) {
      try {
        this.contactData.charityId = charityId;
        this.contactData.corporationId = corporationId;
        this.contactData.isSeniorStaff = isSeniorStaff;
        this.contactData.isPrimaryContact = isPrimaryContact;

        return this.contactData.id !== 0 ? await this.$api.contact.update(this.contactData) : await this.$api.contact.create(this.contactData);
      } catch (err) {
        console.log(err);
      }
      return null;
    },
    reset() {
      this.contactData = {
        id: 0,
        firstName: '',
        lastName: '',
        daytimePhone: '',
        extension: '',
        title: '',
        email: '',
        isPrimary: 0,
        isSeniorStaff: 0,
      };
    },
  },
};

</script>
