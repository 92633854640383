<template>
  <v-container
    fluid
    class="pa-12 pb-2"
  >
    <v-row>
      <v-col>
        <CandidateFAQForm />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import CandidateFAQForm from '@/components/infoForms/CandidateFAQForm';

export default {
  components: {
    CandidateFAQForm,
  },

  data: () => ({
    courseEnrollments: [],
  }),

  computed: {
    ...mapState({
      userId: (state) => state.user.nameId,
    }),
  },

  created() {
    this.init();
  },

  methods: {
    init() {
    },
  },
};
</script>
