<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col
          cols="7"
          class="mt-2 ml-1 mb-0 pb-0 pr-0 mr-0"
          style="display: grid; align-content: center;"
        >
          <div
            style="display: grid; align-content: center;"
          >
            <span
              class="font-weight-bold text-h5"
              style="margin-bottom: 5px;"
            >{{ title }}</span>
          </div>
        </v-col>
        <v-col
          cols="4"
          class="pa-0"
        >
          <v-select
            v-model="selectedFilter"
            :items="filters"
            item-text="name"
            item-value="id"
            clearable
            hide-details
          />
        </v-col>
      </v-row>
      <v-row
        v-for="(mandate, index) in boardMembers"
        :key="index"
        class="ml-1 mb-0 mt-2"
      >
        <v-col
          cols="3"
          class="pr-0"
        >
          <initials-portrait
            v-if="mandate.charityRepresentative && mandate.charityRepresentative.user && mandate.charityRepresentative.user.userInformation"
            :first-name="mandate.charityRepresentative.user.userInformation.firstName"
            :last-name="mandate.charityRepresentative.user.userInformation.lastName"
            :image="image(mandate.charityRepresentative.userId)"
            :colour="`#${mandate.charityRepresentative.user.profileColour}`"
            max-width="65px"
            max-height="65px"
            font-size="22px"
          />
        </v-col>
        <v-col
          cols="9"
        >
          <span
            class="font-weight-bold"
          >
            {{ fullName(mandate) }}</span>
          <br>

          {{ mandate.charityPosition.name }}
          <br>
          {{ status(mandate) }}
        </v-col>
      </v-row>
      <v-row
        v-if="boardMembers.length == 0"
      >
        <v-col
          cols="12"
          class="d-flex justify-center"
        >
          <span class="text-h6">
            None
          </span>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';
import { createPaginationObject, createSortObject } from '@/helpers/PaginationHelper.js';
import InitialsPortrait from '@/components/general/InitialsPortrait';

export default {
  components: {
    InitialsPortrait,
  },

  props:
  {
    charity: {
      type: Object,
      default: () => {},
    },
    editing: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    boardMembers: [],
    filters: ['Past', 'Current', 'Future'],
    selectedFilter: 'Current',
    userImages: [],
    currentStatusId: 2,
    pastStatusId: 3,
    futureStatusId: 4,
    title: 'Board Members',
    colors: ['green', 'purple', 'orange', 'blue', 'red', 'teal', 'brown'],
  }),
  computed: {
    ...mapState({
      storeCharity: (state) => state.charity.charity,
    }),
  },
  watch: {
    charity: {
      immediate: true,
      handler(value) {
        if (!value && !this.editable) return;
        this.loadBoardMembers();
      },
    },
    selectedFilter: {
      immediate: true,
      handler(value) {
        if (!value) return;
        this.loadBoardMembers();
      },
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
    },
    async loadBoardMembers() {
      const filters = [];
      const sort = [];
      filters.push({ name: 'charityId', values: [this.charity.id.toString()] });
      filters.push({ name: 'status', values: [this.statusId(this.selectedFilter).toString()] });
      filters.push({ name: 'boardPosition', values: [false] });
      sort.push(createSortObject('positionIsDefault', 'desc'));
      sort.push(createSortObject('positionSortOrder', 'asc'));
      const result = await this.$api.charityMandate.read(
        createPaginationObject({ sortBy: sort }, null, filters),
      );
      this.boardMembers = result.charityMandates;
      this.$emit('membersLoaded', [this.boardMembers]);
      this.loadUserImages(result.charityMandates.map((x) => x.charityRepresentative.user));
    },
    async loadUserImages(users) {
      this.userImages = [];
      users.forEach((user) => {
        this.loadUserImage(user);
      });
    },
    async loadUserImage(user) {
      const filters = [
        {
          name: 'entityId',
          values: [user.id.toString()],
        },
        {
          name: 'type',
          values: ['profile-image'],
        },
      ];
      const response = await this.$api.image.readImages('user', createPaginationObject(null, null, filters));
      this.userImages.push({ user, images: response.images });
    },
    fullName(mandate) {
      return `${mandate.charityRepresentative.user.userInformation.firstName} ${mandate.charityRepresentative.user.userInformation.lastName}`;
    },
    image(userId) {
      const image = this.userImages.find((x) => x.user.id === userId);
      if (image != null && image.images != null && image.images.length > 0) {
        return image.images[0].image;
      }
      return null;
    },
    status(mandate) {
      // if (!mandate.startDate) return 'Past Member';
      const start = this.$moment(mandate.startDate);
      if (start.diff(this.$moment(), 'days') >= 0) return `Starting on ${start.format('MMM DD, YYYY')}`;
      const end = mandate.endDate ? this.$moment(mandate.endDate) : null;
      if (end != null && end.diff(this.$moment(), 'days') <= 0) return `Ended at ${end.format('MMM DD, YYYY')}`;
      return 'Current Member';
    },
    statusId(selectedFilter) {
      let id;
      switch (selectedFilter) {
        case 'Current':
          id = 2;
          break;
        case 'Past':
          id = 3;
          break;
        case 'Future':
          id = 4;
          break;
        default:
          id = 2;
          break;
      }
      return id;
    },
  },
};
</script>
<style>
  .profile-picture-button{
    background-color: rgb(0,0,0,0.2) !important;
  }
  button.profile-picture-button-hover span i.v-icon.notranslate.mdi {
    width: 100px !important;
    height: 101px !important;
    font-size: 70px;
    background-color: transparent;
    color: rgb(0,0,0,0.5);
  }
  button.profile-picture-button span i.v-icon.notranslate.mdi {
    background-color: transparent;
    color: transparent;
  }
  .dark-grey {
    color: rgb(0,0,0,0.6)
  }
</style>
