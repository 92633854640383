var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('validation-observer',{ref:"contactObserver"},[_c('v-row',[(_vm.showFirstName)?_c('v-col',{staticClass:"pt-0 pb-0"},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"required":"","error-messages":errors,"label":"First Name"},model:{value:(_vm.contactData.firstName),callback:function ($$v) {_vm.$set(_vm.contactData, "firstName", $$v)},expression:"contactData.firstName"}})]}}],null,false,916582472)})],1):_vm._e(),(_vm.showLastName)?_c('v-col',{staticClass:"pt-0 pb-0"},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Last Name","required":""},model:{value:(_vm.contactData.lastName),callback:function ($$v) {_vm.$set(_vm.contactData, "lastName", $$v)},expression:"contactData.lastName"}})]}}],null,false,2655343112)})],1):_vm._e(),_c('v-col',{staticClass:"pt-0 pb-0"},[_c('validation-provider',{attrs:{"name":"Daytime Phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"required":"","error-messages":errors,"label":"Daytime phone","type":"number"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Daytime phone"),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,true),model:{value:(_vm.contactData.daytimePhone),callback:function ($$v) {_vm.$set(_vm.contactData, "daytimePhone", $$v)},expression:"contactData.daytimePhone"}})]}}])})],1),_c('v-col',{staticClass:"pt-0 pb-0"},[_c('validation-provider',{attrs:{"name":"Ext."}},[_c('v-text-field',{attrs:{"label":"Ext."},model:{value:(_vm.contactData.extension),callback:function ($$v) {_vm.$set(_vm.contactData, "extension", $$v)},expression:"contactData.extension"}})],1)],1),_c('v-col',{staticClass:"pt-0 pb-0"},[_c('validation-provider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"required":"","error-messages":errors,"label":"Title"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Title"),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,true),model:{value:(_vm.contactData.title),callback:function ($$v) {_vm.$set(_vm.contactData, "title", $$v)},expression:"contactData.title"}})]}}])})],1),_c('v-col',{staticClass:"pt-0 pb-0"},[(_vm.showEmail)?_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Email","required":""},model:{value:(_vm.contactData.email),callback:function ($$v) {_vm.$set(_vm.contactData, "email", $$v)},expression:"contactData.email"}})]}}],null,false,814125960)}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }