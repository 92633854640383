<template>
  <validation-observer ref="newPositionValObserver">
    <v-row>
      <v-col
        cols="5"
        class="d-flex justify-left pl-5"
      >
        <span class="text-h6">Position Name:</span><red-asterisk />
      </v-col>
      <v-col cols="7" />
      <v-col
        cols="12"
        class=" justify-left pl-5 pr-5"
      >
        <validation-provider
          v-slot="{ errors }"
          name="Notes"
          rules="required"
        >
          <v-text-field
            v-model="positionName"
            solo
            :error-messages="errors"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="8"
        class=" justify-left pl-5 pr-5"
      >
        <validation-provider
          v-slot="{ errors }"
          name="Notes"
          rules="required"
        >
          <v-checkbox
            v-model="isNotBoard"
            :label="`This is a Board Position`"
            :error-messages="errors"
          />
        </validation-provider>
      </v-col>
    </v-row>
  </validation-observer>
</template>

<script>
import { required } from 'vee-validate/dist/rules';
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from 'vee-validate';

setInteractionMode('eager');
extend('required', {
  ...required,
  message: '{_field_} can not be empty',
});
extend('select-required', {
  async validate(value) {
    return !!value.id;
  },
  message: '{_field_} can not be empty',
});
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props:
  {
    charity: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    positionName: '',
    data: { Name: '', charityId: null, isNotBoard: null },
    isNotBoard: true,
  }),
  watch: {

  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.positionName = '';
    },
    async submitNewPosition() {
      this.data = { Name: this.positionName, charityId: this.charity.id, isNotBoard: !this.isNotBoard };
      const res = await this.$api.charityPositions.create(this.data);
      if (!res) {
        this.$bus.$emit('showError', 'Can not add new position');
        return;
      }
      this.$emit('newPositionAdded');// document.location.reload();
      this.$bus.$emit('showSuccess', 'Position added Successfully!');
      this.positionName = '';
      this.$emit('closeNewPositionDialog');
    },

  },
};
</script>
