<template>
  <v-container
    fluid
    class="fill-height pa-12 pb-2"
  >
    <v-row class="text-left">
      <v-col cols="12">
        <h1>Search</h1>
      </v-col>
    </v-row>
    <v-row class="text-left">
      <v-col cols="8">
        <span class="font-italic">Search the list of Altruvest charities or available board positions.</span>
      </v-col>
    </v-row>
    <candidate-search-template ref="candidateSearchTemplate" />
  </v-container>
</template>

<script>
import CandidateSearchTemplate from '@/components/candidate/search/CandidateSearchTemplate';

export default {
  components: { CandidateSearchTemplate },
  data: () => ({
    searchRadioButton: 'charities',
    isShow: false,
  }),
  computed: {
    tabName() {
      return this.searchRadioButton.charAt(0).toUpperCase() + this.searchRadioButton.slice(1);
    },
    /* isShow() {
      return this.search ? true : false;
    } */
  },

  methods: {
    searchEntries() {
      this.isShow = true;
    },
  },
};
</script>

<style></style>
