<template>
  <div class="ma-0 pa-0 text-start">
    <validation-observer ref="postingValObserver">
      <v-row class="mt-3 mb-8">
        <v-col
          cols="12"
          class="pa-0"
        >
          <ValidationProvider
            v-slot="{ errors }"
            immediate
            name="candidateSkills"
            rules="required"
          >
            <v-text-field
              v-show="false"
              v-model="submitCandidatePreferences.selectedCandidateSkills"
              :error="errors.length > 0 && !readOnly"
            />
            <v-text-field
              v-show="false"
              v-model="submitCandidatePreferences.selectedCandidateFocusAreas"
              :error="errors.length > 0"
            />
          </ValidationProvider>
        </v-col>
        <!--<v-col
          v-for="(chbx, index) in candidateSkills"
          :key="index"
          cols="3"
          sm="2"
          class="ma-0 pa-0 pl-5"
        >
          <v-checkbox
            :key="'sel-' + index"
            v-model="submitCandidatePreferences.selectedCandidateSkills"
            hide-details
            :value="chbx.id"
            :label="chbx.name"
            class="mt-0 compact"
            :readonly="readOnly"
            :style="readOnly ? 'pointer-events: none' : ''"
          />
        </v-col>-->
        <v-col
          cols="6"
          class="pt-0"
        >
          <span
            class="text-h5 font-weight-bold"
          >Skills<red-asterisk /></span>
          <span
            v-if="!submitCandidatePreferences.selectedCandidateSkills || submitCandidatePreferences.selectedCandidateSkills.length == 0"
            class="red--text ml-2"
          >Must select at least 1 Skill</span>
          <br>
          <template
            v-if="submitCandidatePreferences.selectedCandidateSkills && submitCandidatePreferences.selectedCandidateSkills.length > 0"
          >
            <v-chip
              v-for="(skill, skillIndex) in submitCandidatePreferences.selectedCandidateSkills"
              :key="'position-matching-skill-' + skillIndex"
              medium
              class="ml-2 mt-2"
              close
              color="primary"
              @click:close="removeFilterChip(skillIndex, 'skill')"
            >
              {{ chipName('skill', skill) }}
            </v-chip>
          </template>
          <v-chip
            medium
            class="ml-2 mt-2"
            color="primary"
            @click="openChipSelector('skill')"
          >
            +
          </v-chip>
        </v-col>
        <v-divider vertical />
        <v-col
          cols="6"
          class="pt-0 pl-7"
        >
          <span class="text-h5 font-weight-bold">Focus Areas<red-asterisk /></span>
          <span
            v-if="!submitCandidatePreferences.selectedCandidateFocusAreas || submitCandidatePreferences.selectedCandidateFocusAreas.length == 0"
            class="red--text ml-2"
          >Must select at least 1 Focus Area</span>
          <br>
          <template
            v-if="submitCandidatePreferences.selectedCandidateFocusAreas && submitCandidatePreferences.selectedCandidateFocusAreas.length > 0"
          >
            <v-chip
              v-for="(focusArea, focusAreaIndex) in submitCandidatePreferences.selectedCandidateFocusAreas"
              :key="'position-matching-focus-area-' + focusAreaIndex"
              medium
              class="ml-2 mt-2"
              close
              color="success"
              @click:close="removeFilterChip(focusAreaIndex, 'focus-area')"
            >
              {{ chipName('focus-area', focusArea) }}
            </v-chip>
          </template>
          <v-chip
            medium
            color="success"
            class="ml-2 mt-2"
            @click="openChipSelector('focus-area')"
          >
            +
          </v-chip>
          <!--<v-col
            v-for="(chbx, index) in candidateFocusAreas"
            :key="index"
            cols="6"
            class="ma-0 pa-0 pl-5"
          >
            <v-checkbox
              :key="'sel-' + index"
              v-model="submitCandidatePreferences.selectedCandidateFocusAreas"
              multiple
              hide-details
              :value="chbx.id"
              :label="chbx.name"
              class="mt-0 compact"
              :readonly="readOnly"
              :style="readOnly ? 'pointer-events: none' : ''"
            />
          </v-col>-->
        </v-col>
      </v-row>
      <span
        class="text-h5 font-weight-bold"
        style="margin-bottom: 20px;"
      >History</span> <br><br>

      <!-- <ValidationProvider
        v-slot="{ errors }"
        immediate
        name="boardExperience"
        rules="required"
      >
        <span
          :class=" errors.length > 0 && !readOnly && validationTriggered ? 'red--text' : ''"
        >Please indicate your previous board experience:<red-asterisk /></span>
        <v-text-field
          v-show="false"
          v-model="submitCandidatePreferences.candidateBoardExperienceTypeId"
          :error="errors.length > 0 && !readOnly"
        />
      </ValidationProvider> -->

      <v-row class="mb-2">
        <v-col
          cols="12"
          class="ma-0 pa-0 pl-5"
        >
          <v-col cols="12" />
          <v-radio-group
            v-model="submitCandidatePreferences.candidateBoardExperienceTypeId"
            row
          >
            <v-radio
              v-for="type in boardExperienceTypes"
              :key="type.id"
              :label="type.name"
              :value="type.id"
              class="mr-10"
              :readonly="readOnly"
              :style="readOnly ? 'pointer-events: none' : ''"
            />
          </v-radio-group>
        </v-col>
      </v-row>
      <!-- <ValidationProvider
        v-slot="{ errors }"
        immediate
        name="boardExperience"
        rules="required"
      >
        <span
          :class=" errors.length > 0 && !readOnly && validationTriggered ? 'red--text' : ''"
        >Please describe any previous volunteer experience you have<red-asterisk />:</span>
        <v-text-field
          v-show="false"
          v-model="submitCandidatePreferences.volunteerExperienceTypeIds"
          :error="errors.length > 0 && !readOnly"
        />
      </ValidationProvider> -->
      <v-row class="mt-1 mb-8">
        <v-col
          v-for="(chbx, index) in volunteerExperienceTypes"
          :key="index"
          cols="3"
          :sm="chbx.id == otherVolunteerExperienceId ? '1' : '2'"
          class="mt-0 pt-0 mb-1 pl-5"
        >
          <v-checkbox
            :key="'sel-' + index"
            v-model="submitCandidatePreferences.volunteerExperienceTypeIds"
            hide-details
            :value="chbx.id"
            :label="chbx.name"
            class="mt-0 compact"
            :readonly="readOnly"
            :style="readOnly ? 'pointer-events: none' : ''"
          />
        </v-col>
        <v-col
          cols="3"
          sm="5"
          class="mt-0 pt-0"
        >
          <div>
            <!-- <ValidationProvider
              v-slot="{ errors }"
              name="volunteeringExperienceOther"
              :rules="showOtherExperience == true ? 'required' : ''"
            >
              <v-text-field
                v-if="showOtherExperience == true"
                v-model="submitCandidatePreferences.otherVolunteerExperience"
                class="mt-0 pt-0"
                :error="errors.length > 0 && !readOnly"
                hide-details
                label="Please specify"
                :readonly="readOnly"
                :style="readOnly ? 'pointer-events: none' : ''"
              />
            </ValidationProvider> -->
            <v-text-field
              v-if="showOtherExperience == true"
              v-model="submitCandidatePreferences.otherVolunteerExperience"
              class="mt-0 pt-0"
              hide-details
              label="Please specify"
              :readonly="readOnly"
              :style="readOnly ? 'pointer-events: none' : ''"
            />
          </div>
        </v-col>
      </v-row>
      <span class="text-h5 font-weight-bold">Additional Details</span>
      <br><br>

      <!-- <ValidationProvider
        v-slot="{ errors }"
        immediate
        name="boardExperience"
        rules="required"
      >
        <span
          :class=" errors.length > 0 && !readOnly && validationTriggered ? 'red--text' : ''"
        >Does your employer have any programs that support employee volunteer activities?<red-asterisk /></span>
        <v-text-field
          v-show="false"
          v-model="submitCandidatePreferences.candidateEmployerVolunteerProgramTypeId"
          :error="errors.length > 0 && !readOnly"
        />
      </ValidationProvider> -->
      <v-row class="mb-2">
        <v-col
          cols="12"
          class="ma-0 pa-0 pl-5"
        >
          <v-radio-group
            v-model="submitCandidatePreferences.candidateEmployerVolunteerProgramTypeId"
            row
          >
            <v-radio
              v-for="type in volunteerProgramTypes"
              :key="type.id"
              :label="type.name"
              :value="type.id"
              class="mr-10 mt-2"
              :readonly="readOnly"
              :style="readOnly ? 'pointer-events: none' : ''"
            />
          </v-radio-group>
        </v-col>
        <v-col cols="6">
          <!-- <ValidationProvider
            v-slot="{ errors }"
            immediate
            name="employerProgramOther"
            :rules="submitCandidatePreferences.candidateEmployerVolunteerProgramTypeId == employerProgramOtherTypeId ? 'required' : ''"
          >
            <v-text-field
              v-if="submitCandidatePreferences.candidateEmployerVolunteerProgramTypeId == employerProgramOtherTypeId"
              v-model="submitCandidatePreferences.candidateEmployerVolunteerProgramOther"
              class="mt-0 pt-0"
              :error="errors.length > 0 && !readOnly"
              hide-details
              label="Please specify"
              :readonly="readOnly"
              :style="readOnly ? 'pointer-events: none' : ''"
            />
          </ValidationProvider> -->
          <v-text-field
            v-if="submitCandidatePreferences.candidateEmployerVolunteerProgramTypeId == employerProgramOtherTypeId"
            v-model="submitCandidatePreferences.candidateEmployerVolunteerProgramOther"
            class="mt-0 pt-0"
            hide-details
            label="Please specify"
            :readonly="readOnly"
            :style="readOnly ? 'pointer-events: none' : ''"
          />
        </v-col>
      </v-row>

      <v-row class="mt-3 mb-1">
        <v-col
          cols="6"
          class="ma-0 pa-0 pl-5"
        >
          <span>Why are you interested in serving on a board of directors? </span><br>
          <span>What are your personal and professional goals that you feel serving on a Board may fill?</span><br>
          <span>Why now?</span>
          <!-- <span
            v-if="!submitCandidatePreferences.reasonsAndGoals"
            class="red--text ml-2"
          >Must fill reasons and goals</span>
          <br>
          <ValidationProvider
            v-slot="{ errors }"
            name="boardExperience"
            rules="required"
          >
            <v-textarea
              v-show="false"
              v-model="submitCandidatePreferences.reasonsAndGoals"
              :error="errors.length > 0 && !readOnly"
              outlined
              name="input-7-4"
              :readonly="readOnly"
              :style="readOnly ? 'pointer-events: none' : ''"
            />
          </ValidationProvider> -->
          <vue-editor
            v-model="submitCandidatePreferences.reasonsAndGoals"
            :editor-toolbar="customToolbar"
          />
        </v-col>
        <v-col
          cols="6"
          class="pr-4 pt-0"
        >
          <span /><br>
          <span /><br>
          <span /><br>
          Preview:
          <general-text-widget
            title="Why do I want to Volunteer?"
            class="mt-0"
            :html-text="submitCandidatePreferences.reasonsAndGoals"
            preview
          />
        </v-col>
      </v-row>
      <span>Please specify any preferences about the geographical area where you would like to volunteer:</span>
      <v-row class="mt-3 mb-8">
        <v-col
          cols="11"
          class="ma-0 pa-0 pl-5"
        >
          <!-- <ValidationProvider
            v-slot="{ errors }"
            name="geoPreference"
            rules="required"
          >
            <v-text-field
              v-model="submitCandidatePreferences.geographicalAreaPreference"
              :error="errors.length > 0 && !readOnly"
              hide-details
              label="Please specify"
              :readonly="readOnly"
              :style="readOnly ? 'pointer-events: none' : ''"
            />
          </ValidationProvider> -->
          <v-text-field
            v-model="submitCandidatePreferences.geographicalAreaPreference"
            hide-details
            label="Please specify"
            :readonly="readOnly"
            :style="readOnly ? 'pointer-events: none' : ''"
          />
        </v-col>
      </v-row>
      <span class="text-h5 font-weight-bold">Permissions</span>
      <br>
      <v-row class="mt-0">
        <v-col
          cols="11"
          class="ma-0 pa-0 pl-5"
        >
          <v-checkbox
            v-model="submitCandidatePreferences.permissionToViewInformation"
            label="Yes, I permit participating organizations in BoardMatch Fundamentals to view my registration information and background and invite me to interview with them."
            persistent-hint
            hint="The automated matching service notifies you via email of new positions that match your skills and interests."
            :readonly="readOnly"
            :style="readOnly ? 'pointer-events: none' : ''"
          />
        </v-col>
      </v-row>
      <v-row class="mt-3">
        <v-col
          cols="11"
          class="ma-0 pa-0 pl-5"
        >
          <v-checkbox
            v-model="submitCandidatePreferences.withdrawParticipation"
            label="I no longer wish to participate in the automated matching service."
            persistent-hint
            hint="The automated matching service notifies you via email of new positions that match your skills and interests."
            :readonly="readOnly"
            :style="readOnly ? 'pointer-events: none' : ''"
          />
        </v-col>
      </v-row>
    </validation-observer>
    <v-dialog
      v-model="editSkillsDialog"
      width="500px"
    >
      <v-card
        fill-height
      >
        <v-container
          fluid
        >
          <v-row>
            <v-col>
              <span class="text-h4">Select {{ dialogChipType == 'skill' ? 'Skills' : '' }}{{ dialogChipType == 'focus-area' ? 'Focus Areas' : '' }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              v-if="dialogChipType == 'skill'"
              class="ml-2"
            >
              <span>Please select 1 or more skills from the list below that you would like to be matched with:</span><br><br>
              <v-chip
                v-for="skill in candidateSkills"
                :key="skill.id"
                :class="chipSelected(skill) ? 'primary' : ''"
                small
                class="mt-1"
                @click="selectChip(skill)"
              >
                {{ skill.name }}
              </v-chip>
            </v-col>
            <v-col
              v-if="dialogChipType == 'focus-area'"
              class="ml-2"
            >
              <span>Please select 1 or more focus areas from the list below that you would like to be matched with:</span><br><br>
              <v-chip
                v-for="focusArea in candidateFocusAreas"
                :key="focusArea.id"
                :class="chipSelected(focusArea) ? 'success' : ''"
                small
                class="mt-1"
                @click="selectChip(focusArea)"
              >
                {{ focusArea.name }}
              </v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              class="text-end"
            >
              <v-btn
                class="mr-4 default"
                elevation="0"
                @click="closeForm"
              >
                Ok
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { required } from 'vee-validate/dist/rules';
import { mapState } from 'vuex';
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from 'vee-validate';
import { VueEditor } from 'vue2-editor';
import GeneralTextWidget from '@/components/general/GeneralTextWidget.vue';

setInteractionMode('immediate');
extend('required', {
  ...required,
  message: 'Must select at least one {_field_}',
});
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    GeneralTextWidget,
    VueEditor,
  },
  props:
    {
      readOnly: {
        type: Boolean,
        default: false,
      },
      candidate: {
        type: Object,
        default: () => null,
      },
    },
  data: () => ({
    submitCandidate: null,
    submitCandidatePreferences: {
      id: 0,
      candidateId: 0,
      selectedCandidateSkills: [],
      selectedCandidateFocusAreas: [],
      candidateBoardExperienceTypeId: null,
      candidateEmployerVolunteerProgramTypeId: null,
      volunteerExperienceTypeIds: [],
      geographicalAreaPreference: '',
      otherVolunteerExperience: '',
      candidateSourceOfIntroductionId: null,
      sourceOfIntroductionDetails: '',
      reasonsAndGoals: '',
      permissionToViewInformation: false,
      withdrawParticipation: false,
    },
    otherVolunteerExperienceId: 4,
    employerProgramOtherTypeId: 6,
    dialogChipType: '',
    editSkillsDialog: false,
    editingEntryType: null,
    customToolbar: [
      ['bold', 'italic', 'underline'],
    ],
    validationTriggered: false,
  }),
  computed: {
    ...mapState({
      candidateSkills: (state) => state.candidate.candidateSkills,
      candidateFocusAreas: (state) => state.candidate.candidateFocusAreas,
      volunteerProgramTypes: (state) => state.candidate.volunteerProgramTypes,
      volunteerExperienceTypes: (state) => state.candidate.volunteerExperienceTypes,
      boardExperienceTypes: (state) => state.candidate.boardExperienceTypes,
      storeCandidate: (state) => state.candidate.candidate,
      candidateMatchingPreferences: (state) => state.candidate.candidateMatchingPreferences,
    }),
    showOtherExperience() {
      const index = this.submitCandidatePreferences.volunteerExperienceTypeIds.findIndex((x) => x === this.otherVolunteerExperienceId);
      return index > -1;
    },
  },
  watch: {
    candidate: {
      immediate: false,
      handler(value) {
        if (this.loaded === true) {
          if (!value) this.submitCandidateQualifications.candidateEducation = [];
          else {
            const newEducations = value.map((newEduId) => {
              const existingIndex = this.submitCandidateQualifications.candidateEducation.findIndex((exEdu) => newEduId === exEdu.candidateEducationTypeId);
              if (existingIndex > -1) {
                return this.submitCandidateQualifications.candidateEducation[existingIndex];
              }
              return {
                candidateQualificationsId: this.candidateQualifications.id,
                candidateEducationTypeId: newEduId,
                institution: '',
                designation: '',
                areaOfStudy: '',
              };
            });
            this.submitCandidateQualifications.candidateEducation = newEducations;
          }
        }
      },
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.submitCandidate = this.candidate ? { ...this.candidate } : { ...this.storeCandidate };
      // update local submit model with data from the store
      if (this.submitCandidate.skills != null) this.submitCandidatePreferences.selectedCandidateSkills = this.submitCandidate.skills.map((skill) => skill.id);
      if (this.submitCandidate.focusAreas != null) this.submitCandidatePreferences.selectedCandidateFocusAreas = this.submitCandidate.focusAreas.map((skill) => skill.id);

      if (this.candidateMatchingPreferences != null) {
        this.submitCandidatePreferences.id = this.candidateMatchingPreferences.id;
        this.submitCandidatePreferences.candidateId = this.candidateMatchingPreferences.candidateId;
        this.submitCandidatePreferences.candidateBoardExperienceTypeId = this.candidateMatchingPreferences.candidateBoardExperienceTypeId;
        this.submitCandidatePreferences.candidateEmployerVolunteerProgramTypeId = this.candidateMatchingPreferences.candidateEmployerVolunteerProgramTypeId;
        this.submitCandidatePreferences.geographicalAreaPreference = this.candidateMatchingPreferences.geographicalAreaPreference ? this.candidateMatchingPreferences.geographicalAreaPreference : '';
        this.submitCandidatePreferences.otherVolunteerExperience = this.candidateMatchingPreferences.otherVolunteerExperience ? this.candidateMatchingPreferences.otherVolunteerExperience : '';
        this.submitCandidatePreferences.candidateSourceOfIntroductionId = this.candidateMatchingPreferences.candidateSourceOfIntroductionId ? this.candidateMatchingPreferences.candidateSourceOfIntroductionId : null;
        this.submitCandidatePreferences.sourceOfIntroductionDetails = this.candidateMatchingPreferences.sourceOfIntroductionDetails ? this.candidateMatchingPreferences.sourceOfIntroductionDetails : '';
        this.submitCandidatePreferences.reasonsAndGoals = this.candidateMatchingPreferences.reasonsAndGoals ? this.candidateMatchingPreferences.reasonsAndGoals : '';
        this.submitCandidatePreferences.permissionToViewInformation = this.candidateMatchingPreferences.permissionToViewInformation;
        this.submitCandidatePreferences.withdrawParticipation = this.candidateMatchingPreferences.withdrawParticipation;
        this.submitCandidatePreferences.volunteerExperienceTypeIds = this.candidateMatchingPreferences.volunteerExperienceTypes ? this.candidateMatchingPreferences.volunteerExperienceTypes.map((x) => x.id) : [];
        this.submitCandidatePreferences.candidateEmployerVolunteerProgramOther = this.candidateMatchingPreferences.candidateEmployerVolunteerProgramOther ? this.candidateMatchingPreferences.candidateEmployerVolunteerProgramOther : '';
      }
    },
    async submit() {
      const res = await this.$api.candidateMatchingPreferences.update(this.submitCandidatePreferences);
      return res;
    },
    resetForm() {
      this.candidateSkills = [];
      this.candidateFocusAreas = [];
    },
    async validate(showError = true) {
      const res = await this.$refs.postingValObserver.validate();
      if (!res) {
        if (showError) this.$bus.$emit('showError', 'Please enter all required fields marked red');
        this.validationTriggered = true;
        return false;
      }
      return res;
    },
    removeFilterChip(skillIndex, type) {
      if (type === 'skill') this.submitCandidatePreferences.selectedCandidateSkills.splice(skillIndex, 1);
      if (type === 'focus-area') this.submitCandidatePreferences.selectedCandidateFocusAreas.splice(skillIndex, 1);
    },
    openChipSelector(type) {
      this.dialogChipType = type;
      this.editSkillsDialog = true;
    },
    chipName(type, id) {
      let index = -1;
      if (type === 'skill') index = this.candidateSkills.findIndex((x) => x.id === id);
      if (type === 'focus-area') index = this.candidateFocusAreas.findIndex((x) => x.id === id);
      if (index > -1) {
        if (type === 'skill') return this.candidateSkills[index].name;
        if (type === 'focus-area') return this.candidateFocusAreas[index].name;
      }
      return 'unknown';
    },
    chipSelected(item) {
      if (this.dialogChipType === 'skill' && this.submitCandidatePreferences.selectedCandidateSkills.findIndex((x) => x === item.id) > -1) return true;
      if (this.dialogChipType === 'focus-area' && this.submitCandidatePreferences.selectedCandidateFocusAreas.findIndex((x) => x === item.id) > -1) return true;
      return false;
    },
    selectChip(item) {
      if (this.dialogChipType === 'skill') {
        const index = this.submitCandidatePreferences.selectedCandidateSkills.findIndex((x) => x === item.id);
        if (index === -1) this.submitCandidatePreferences.selectedCandidateSkills.push(item.id);
        else this.removeFilterChip(index, this.dialogChipType);
      }
      if (this.dialogChipType === 'focus-area') {
        const index = this.submitCandidatePreferences.selectedCandidateFocusAreas.findIndex((x) => x === item.id);
        if (index === -1) this.submitCandidatePreferences.selectedCandidateFocusAreas.push(item.id);
        else this.removeFilterChip(index, this.dialogChipType);
      }
    },
    closeForm() {
      this.editSkillsDialog = false;
    },
  },
};
</script>
