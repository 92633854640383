<template>
  <v-container
    fluid
    class="pa-2 pb-2 ma-2 mt-12"
    style="background-color: white; border-radius: 8px;"
  >
    <v-row class="text-left">
      <v-col
        cols="12"
        class="pl-6 pt-5"
      >
        <h1>Board</h1>
      </v-col>
    </v-row>
    <charity-position-template
      :charity="charity"
    />
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import CharityPositionTemplate from '@/components/charity/charityPosition/CharityPositionTemplate';

export default {
  components: {
    CharityPositionTemplate,
  },
  computed: {
    ...mapState({
      charity: (state) => state.charity.charity,
    }),
  },
};
</script>
