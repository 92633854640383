<template>
  <v-container fluid>
    <validation-observer
      ref="phoneValidateObserver"
    >
      <v-row v-if="phoneTypesLoaded">
        <v-col
          class="pt-0 pb-0 pl-0"
          :cols="12 / cols"
        >
          <validation-provider
            v-slot="{ errors }"
            name="Phone"
            :rules="isRequired ? 'required' : ''"
          >
            <v-text-field
              v-model="phoneData.number"
              :error-messages="errors"
              label="Phone"
              :readonly="isDisabled"
              :style="isDisabled ? 'pointer-events: none' : ''"
            >
              <template #label>
                {{ phoneLabel }} <span
                  v-if="isRequired"
                  class="red--text"
                ><strong>* </strong></span>
              </template>
            </v-text-field>
          </validation-provider>
        </v-col>

        <v-col
          v-show="showExtension"
          class="pt-0 pb-0"
          :cols="12 / cols"
        >
          <v-text-field
            v-model="phoneData.extension"
            label="Ext."
            :readonly="isDisabled"
            :style="isDisabled ? 'pointer-events: none' : ''"
          />
        </v-col>
      </v-row>
    </validation-observer>
  </v-container>
</template>

<script>

import { required } from 'vee-validate/dist/rules';
import {
  extend, ValidationObserver, ValidationProvider, setInteractionMode,
} from 'vee-validate';
import { mapState } from 'vuex';

setInteractionMode('eager');

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props:
    {
      phone: {
        type: Object,
        default: () => {},
      },
      isEverning: {
        type: Boolean,
        default: true,
      },
      isRequired: {
        type: Boolean,
        default: true,
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
      phoneType: {
        type: String,
        default: 'main',
      },
      cols: {
        type: Number,
        default: 2,
      },
      showExtension: {
        type: Boolean,
        default: true,
      },
    },
  data: () => ({
    phoneData: null,
  }),
  computed: {
    ...mapState({
      phoneTypes: (state) => state.general.phoneTypes,
    }),
    phoneLabel() {
      const index = this.phoneTypes.findIndex((x) => this.phoneType.toLowerCase() === x.name.toLowerCase());
      if (this.phoneData.phoneTypeId < 2 || index === -1 || this.phoneTypes[index].name === 'Main') return 'Phone';

      return `${this.phoneTypes[index].name} Phone`;
    },
    phoneTypesLoaded() {
      if (!this.phoneTypes.length) return false;

      const index = this.phoneTypes.findIndex((x) => x.name.toLowerCase() === this.phoneType.toLowerCase());
      if (index === -1) return false;
      return true;
    },
  },
  watch: {
    phone: {
      immediate: true,
      handler(value) {
        this.phoneData = value
          || {
            id: 0,
            number: '',
            extension: '',
          };
      },
    },
  },
  created() {
    if (!this.phoneTypes.length) this.$store.dispatch('general/getPhoneTypes');
  },
  methods: {
    async submit(charityId = null, userInformationId = null, corporationId = null) {
      try {
        this.phoneData.charityId = charityId;
        this.phoneData.userInformationId = userInformationId;
        this.phoneData.corporationId = corporationId;
        this.setPhoneType();
        return this.phoneData.id !== 0 ? await this.$api.phone.update(this.phoneData) : await this.$api.phone.create(this.phoneData);
      } catch (err) {
        console.log(err);
      }
      return null;
    },
    setPhoneType() {
      const index = this.phoneTypes.findIndex((x) => x.name.toLowerCase() === this.phoneType.toLowerCase());
      this.phoneData.phoneTypeId = index > -1 ? this.phoneTypes[index].id : 1;
    },

    async validate(showError = true) {
      if (this.phoneTypesLoaded === false) return false;
      // eslint-disable-next-line no-return-await
      const res = await this.$refs.phoneValidateObserver.validate();
      if (!res) {
        if (showError) this.$bus.$emit('showError', 'Please enter address required fields marked red');
        return false;
      }
      return res;
    },
    reset() {
      this.phoneData = {
        id: 0,
        number: '',
        extension: '',
      };
    },
  },
};
</script>
