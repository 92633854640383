<template>
  <v-container fluid>
    <validation-observer ref="userValidationObserver">
      <v-row>
        <v-col
          v-if="title != ''"
          class="text-h5 font-weight-bold"
          cols="12"
        >
          {{ title }}
        </v-col>
      </v-row>
      <!-- pointer-events: none -->
      <v-row>
        <v-col
          class="pt-0 pb-0 pl-0"
          :cols="rows == 2 ? 6 : ''"
        >
          <validation-provider
            v-slot="{ errors }"
            name="First Name"
            rules="required"
          >
            <v-text-field
              v-model="userData.userInformation.firstName"
              required
              :error-messages="errors"
              :readonly="isDisabled"
              :style="isDisabled ? 'pointer-events: none' : ''"
            >
              <template #label>
                First Name<red-asterisk v-if="!isDisabled" />
              </template>
            </v-text-field>
          </validation-provider>
        </v-col>
        <v-col
          class="pt-0 pb-0 pl-0"
          :cols="rows == 2 ? 6 : ''"
        >
          <validation-provider
            v-slot="{ errors }"
            name="Last Name"
            rules="required"
          >
            <v-text-field
              v-model="userData.userInformation.lastName"
              :error-messages="errors"
              required
              :readonly="isDisabled"
              :style="isDisabled ? 'pointer-events: none' : ''"
            >
              <template #label>
                Last Name<red-asterisk v-if="!isDisabled" />
              </template>
            </v-text-field>
          </validation-provider>
        </v-col>
        <v-col
          v-if="showPronoun"
          class="pt-0 pb-0 pl-0"
        >
          <v-select
            v-model="userData.userInformation.pronounId"
            :items="pronouns"
            item-text="name"
            item-value="id"
          >
            <template #label>
              Pronoun
            </template>
          </v-select>
        </v-col>
        <v-col
          v-if="userData.userInformation.pronounId === 4"
          class="pt-0 pb-0 pl-0"
        >
          <validation-provider
            v-slot="{ errors }"
            name="Pronoun Other"
            rules="required"
          >
            <v-text-field
              v-model="userData.userInformation.pronounOther"
              required
              :error-messages="errors"
              :readonly="isDisabled"
              :style="isDisabled ? 'pointer-events: none' : ''"
            >
              <template #label>
                Other<red-asterisk v-if="!isDisabled" />
              </template>
            </v-text-field>
          </validation-provider>
        </v-col>
        <v-col
          class="pt-0 pb-0 pl-0"
          :cols="rows == 2 ? 12 : 5"
        >
          <v-row>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                name="Email"
                :rules="
                  userData.id != 0 || isEmail
                    ? 'required|email'
                    : 'required|username|email'
                "
              >
                <v-text-field
                  v-model="userData.userInformation.email.name"
                  :error-messages="errors"
                  required
                  class="custom-disabled-gray"
                  :readonly="isDisabled"
                  :style="isDisabled ? 'pointer-events: none' : ''"
                  :disabled="(userData && userData.id > 0) || disableEmail"
                >
                  <template #label>
                    Email<red-asterisk v-if="!isDisabled" />
                  </template>
                </v-text-field>
                <!-- <a v-if="emailExists">userData with this email is in the system</a> -->
              </validation-provider>
            </v-col>
            <v-col class="pl-0">
              <v-row class="ml-0 mr-0">
                <v-col
                  :cols="showPasswordReset == true ? 7 : 12"
                  class="pr-0 pl-0"
                >
                  <v-row class="no-gutters">
                    <v-col :cols="showPassword ? 10 : 12">
                      <validation-provider
                        v-slot="{ errors }"
                        name="password"
                        :rules="isPasswordRequired && userData.id === 0 ? 'required|password-rule' : ''"
                      >
                        <v-text-field
                          v-model="userData.password"
                          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                          :error-messages="errors"
                          required
                          :type="showPassword ? 'text' : 'password'"
                          :readonly="isDisabled || hidePassword"
                          autocomplete="new-password"
                          :style="isDisabled || hidePassword ? 'pointer-events: none' : ''"
                          @click:append="showPassword = !showPassword"
                        >
                          <template #label>
                            Password
                            <red-asterisk v-if="isPasswordRequired && userData.id === 0" />
                          </template>
                        </v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col
                      v-if="showPassword"
                      class="d-flex justify-center align-start mt-5"
                    >
                      <v-tooltip
                        top
                      >
                        <template #activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-help-circle
                          </v-icon>
                        </template>
                        <span>Password must have one of each of the following:</span>
                        <br>
                        <ul>
                          <li>At least 8 characters</li>
                          <li>At least one upper-case character (A-Z)</li>
                          <li>At least one lower-case character (a-z)</li>
                          <li>At least one digit (0-9)</li>
                          <li>At least one special character (!@#$%^&*)</li>
                        </ul>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  v-if="showPasswordReset == true"
                  cols="4"
                  class="pl-0"
                >
                  <user-reset-password-btn
                    :user="user"
                  />
                </v-col>
              </v-row>
            </v-col>
            <!-- <v-col
              v-if="!hidePassword"
              :cols="rows == 2 ? 4 : 2"
            >
              <v-row class="ml-0 mr-0">
                <v-col
                  :cols="showPasswordReset == true ? 7 : 12"
                  class="pr-0 pl-0"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="confirm"
                    :rules="isPasswordRequired && userData.id === 0 ? 'required|password:@password' : ''"
                  >
                    <v-text-field
                      v-model="confirmation"
                      :append-icon="showConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
                      :error-messages="errors"
                      :type="showConfirmation ? 'text' : 'password'"
                      :readonly="isDisabled || hidePassword"
                      autocomplete="new-password"
                      :style="isDisabled || hidePassword ? 'pointer-events: none' : ''"
                      @click:append="showConfirmation = !showConfirmation"
                    >
                      <template #label>
                        Confirm Password
                        <red-asterisk v-if="isPasswordRequired && userData.id === 0" />
                      </template>
                    </v-text-field>
                  </validation-provider>
                </v-col>
                <v-col
                  v-if="showPasswordReset == true"
                  cols="4"
                  class="pl-0"
                >
                  <user-reset-password-btn
                    :user="user"
                  />
                </v-col>
              </v-row>
            </v-col> -->
          </v-row>
        </v-col>
      </v-row>
    </validation-observer>
  </v-container>
</template>

<script>
import { required, email } from 'vee-validate/dist/rules';
import { mapState } from 'vuex';

import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from 'vee-validate';
import UserResetPasswordBtn from '@/components/user/UserResetPasswordBtn';
import UserApi from '@/api/users/UserApi';

setInteractionMode('eager');

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
});

extend('password-rule', {
  validate(value) {
    const regex = new RegExp(/(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*?]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/);
    return regex.test(value);
  },
  message: 'Password Complexity not met. Hover the ? icon for details.',
});

extend('email', {
  ...email,
  message: 'Email must be valid',
});

extend('username', {
  async validate(value) {
    return UserApi.checkUserName(value);
  },
  message: 'Email is already registered. Please, try another one.',
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    UserResetPasswordBtn,
  },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    existingUsername: {
      type: String,
      default: '',
    },
    existingFirstname: {
      type: String,
      default: '',
    },
    existingLastname: {
      type: String,
      default: '',
    },
    existingPronounId: {
      type: Number,
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
    isEmail: {
      type: Boolean,
      default: false,
    },
    showPronoun: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isPasswordRequired: {
      type: Boolean,
      default: true,
    },
    rows: {
      type: Number,
      default: 1,
    },
    showPasswordReset: {
      type: Boolean,
      default: true,
    },
    hidePassword: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    showPassword: false,
    showConfirmation: false,
    disableEmail: false,
    userData: {
      id: 0,
      password: '',
      userName: '',
      userInformation: {
        firstName: '',
        lastName: '',
        email: {
          name: '',
        },
        pronounId: null,
        pronounOther: null,
      },
    },
    confirmation: '',
  }),
  computed: {
    ...mapState({
      pronouns: (state) => state.general.pronouns,
    }),
    credColumns() {
      let cols = 2;
      if (this.rows === 2) cols = 4;
      if (this.hidePassword === true) cols *= 1.5;
      return cols;
    },
  },

  watch: {
    user: {
      immediate: true,
      handler(value) {
        this.userData = value || {
          id: 0,
          password: '',
          userName: '',
          userInformation: {
            firstName: this.existingFirstname,
            lastName: this.existingLastname,
            email: {
              name: this.existingUsername,
            },
            pronounId: this.existingPronounId,
          },
        };
      },
    },
    existingUsername: {
      immediate: true,
      handler(value) {
        if (value) {
          this.userData.userInformation.email.name = value;
          this.disableEmail = true;
        }
      },
    },
    existingFirstname: {
      immediate: true,
      handler(value) {
        if (value) {
          this.userData.userInformation.firstName = value;
        }
      },
    },
    existingLastname: {
      immediate: true,
      handler(value) {
        if (value) {
          this.userData.userInformation.lastName = value;
        }
      },
    },
    existingPronounId: {
      immediate: true,
      handler(value) {
        if (value) {
          this.userData.userInformation.pronounId = value;
        }
      },
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.$route.query && this.$route.query.openCandidateReg) {
        this.userData.userInformation.email.name = this.$route.query.openCandidateReg;
      }
      this.loadPronouns();
    },
    async validate(showError = true) {
      const res = await this.$refs.userValidationObserver.validate();
      if (!res) {
        if (showError) this.$bus.$emit('showError', 'Please enter address required fields marked red');
        return false;
      }
      return res;
    },

    async submit() {
      try {
        return this.userData.id !== 0
          ? await this.$api.user.update(this.userData)
          : await this.$api.user.create(this.userData);
      } catch (err) {
        console.log(err);
      }
      return false;
    },
    async loadPronouns() {
      if (!this.pronouns.length) this.$store.dispatch('general/getPronoun');
    },
    reset() {
      this.$refs.userValidationObserver.reset();
      this.userData = {
        id: 0,
        password: '',
        userName: '',
        userInformation: {
          firstName: '',
          lastName: '',
          email: {
            name: '',
          },

          pronounId: null,
          pronounOther: null,
        },
      };
    },
  },
};
</script>
<style>
 .v-input--is-label-active.v-input--is-dirty.v-input--is-disabled.theme--light.v-text-field.v-text-field--is-booted div.v-input__control div.v-input__slot div.v-text-field__slot input{
  color: rgba(0,0,0,0.6);
 }
.v-input--is-label-active.v-input--is-dirty.v-input--is-disabled.theme--light.v-text-field.v-text-field--is-booted div.v-input__control div.v-input__slot div.v-text-field__slot label.v-label.v-label--active.v-label--is-disabled.theme--light{
  color: rgba(0,0,0,0.6);
}
</style>
