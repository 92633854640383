<template>
  <v-row>
    <v-col
      v-if="title"
      cols="12"
    >
      {{ title }}
    </v-col>
    <v-col>
      <v-data-table
        :headers="headers"
        :items="types"
        :footer-props="footerProps"
        :items-per-page="itemsPerPage"
        :loading="loading"
        :server-items-length="total"
        dense
        :option="options"
        style="width: 100%"
        @pagination="updatePagination"
        @update:options="updateOptions"
      >
        <template #[`item.image`]="{ item }">
          <v-img
            :key="item.id"
            width="35px"
            height="35px"
            contain
            class="mt-1 mb-1"
            :src="(item.image && item.image.image ? 'data:' + item.image.image.mimeType + ';base64,' + item.image.image.file : '')"
          />
        </template>
        <template #[`item.name`]="{ item }">
          <text-highlight :queries="search">
            {{ item.name }}
          </text-highlight>
        </template>
        <template #[`item.actions`]="{ item }">
          <slot :item="item" />
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import TextHighlight from 'vue-text-highlight';
import moment from 'moment';

export default {
  components: {
    TextHighlight,
  },
  props: {
    search: {
      default: '',
      type: String,
    },
    title: {
      default: '',
      type: String,
    },
    filters: {
      default: () => [],
      type: Array,
    },
  },
  data: () => ({
    types: [],
    footerProps: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    itemsPerPage: 20,
    loading: false,
    pagination: null,
    total: 0,
    options: null,
    requiredCourseIds: [1679471], // to-do: make this dynamic.
  }),
  computed: {
    headers() {
      const headers = [];
      headers.push({ text: 'Icon', value: 'image' });
      headers.push({ text: 'Name', value: 'name' });
      headers.push({
        text: 'Actions',
        value: 'actions',
        align: 'right',
        sortable: false,
        width: '15%',
      });

      return headers;
    },
  },
  watch: {
    options(value) {
      if (value) {
        this.getTypes();
      }
    },
  },

  methods: {
    async getTypes() {
      const result = await this.$api.socialMedia.getTypes();

      if (!result) {
        this.types = [];
        this.total = 0;
        this.loading = false;
        return;
      }
      this.types = result;
      // this.total = result.total;
      this.total = result.total;
      this.loading = false;
    },
    updatePagination(pagination) {
      this.pagination = pagination;
    },
    updateOptions(options) {
      this.options = options;
    },
    refresh() {
      this.getTypes();
    },
    moment() {
      return moment();
    },
  },
};
</script>

<style></style>
