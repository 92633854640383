<template>
  <v-img
    :src="image ? ('data:' + image.mimeType + ';base64,' + image.file) : ''"
    :style="`border-radius: 50%; background-color: ${colour};`"
    :max-height="maxHeight ? maxHeight : '65px'"
    :max-width="maxWidth ? maxWidth : '65px'"
    :height="maxHeight ? maxHeight : '65px'"
    :width="maxWidth ? maxWidth : '65px'"
  >
    <div
      v-if="image == null"
      class="initials-div"
      :style="`color: white; font-size: ${fontSize}`"
    >
      <span>{{ initials }}</span>
    </div>
  </v-img>
</template>

<script>

export default {
  components: {
  },

  props:
  {
    firstName: {
      type: String,
      default: '',
    },
    lastName: {
      type: String,
      default: '',
    },
    image: {
      type: Object,
      default: null,
    },
    maxHeight: {
      type: String,
      default: null,
    },
    maxWidth: {
      type: String,
      default: null,
    },
    colour: {
      type: String,
      default: '#555555',
    },
    isPlaceHolder: {
      type: Boolean,
      default: false,
    },
    fontSize: {
      type: String,
      default: '22px',
    },
  },
  data: () => ({
    colors: ['green', 'purple', '#c88100', 'blue', 'red', 'teal', 'brown'],
  }),
  computed: {
    initials() {
      return `${this.firstName.substring(0, 1).toUpperCase()}${this.lastName.substring(0, 1).toUpperCase()}`;
    },
    initialsColor() {
      const code = this.firstName.toUpperCase().charCodeAt(0);
      const colorArrayLength = this.colors.length;
      return this.colors[code % colorArrayLength];
    },
    dynamicFontSize() {
      if (this.maxWidth == null && this.maxHeight == null) return '40px';
      if (this.isPlaceHolder) return '85px';
      return '22px;';
    },
  },
  watch: {
  },
  methods: {
  },
};
</script>
<style scoped>
  .initials-div {
    font-weight: bold;
    text-align: center;
    height: 100%;
    display: grid;
    align-content: center;
  }
</style>
