<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col cols="4">
          <initials-portrait
            :first-name="candidate.user.userInformation.firstName"
            :last-name="candidate.user.userInformation.lastName"
            :image="userImage ? userImage : null"
            max-height="150px"
            max-width="150px"
            font-size="75px"
            style="border-radius: 125px !important;"
            class="mt-5 ml-5"
            :is-place-holder="true"
            :colour="userImage ? '' : `#${candidate.user.profileColour}`"
          >
            <!-- <v-tooltip
              v-if="editing"
              top
              nudge-top="-9px"
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  height="100%"
                  width="100%"
                  :class="'profile-picture-button'"
                  v-bind="attrs"
                  v-on="on"
                  @click="changeUserPhoto()"
                >
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>
                Update User Photo
              </span>
            </v-tooltip> -->
          </initials-portrait>
        </v-col>
        <v-col
          class="pa-5"
          style="display: grid; align-content: center;"
        >
          <div
            style="display: grid; align-content: center;"
          >
            <span
              class="font-weight-bold text-h4"
              style="margin-bottom: 5px;"
            >{{ fullName }}</span>
            <span>{{ pronoun }}</span>
            <!-- <br> -->
            <span>
              {{ workDetails(candidate) }}
            </span>
            <!-- <br> -->

            <template v-if="candidate.resumes && candidate.resumes.length">
              <a @click="loadFile(candidate.resumes[0].id, candidate.resumes[0].originalName)">Resume</a>
            </template>
            <!-- <br> -->

            <span
              v-if="location"
              style="margin-top: 5px;"
            >
              <v-icon
                small
                style="margin-bottom: 2px;"
              >
                mdi-crosshairs-gps
              </v-icon>
              {{ location }}
            </span>
            <span
              v-if="candidate.user.userInformation.phones"
              style="margin-top: 5px;"
            >
              <v-icon
                small
                style="margin-bottom: 2px;"
              >
                mdi-phone
              </v-icon>
              {{ phoneFormatter(candidate.user.userInformation.phones[0].number) }}
            </span>
            <span v-if="candidate.displayEmail && candidate.email">
              <v-icon
                small
                style="margin-bottom: 2px;"
              >
                mdi-email-outline
              </v-icon>
              <a
                :href="'mailto:' + candidate.email"
                class="text-decoration-none"
              >
                {{ candidate.email }}</a>
            </span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="ml-3"
          cols="12"
        >
          <span
            v-if="candidate.skills && candidate.skills.length"
            class="subtitle-3 font-weight-bold"
            style="color: rgb(100, 100, 100);"
          >Skills:</span>
          <v-chip
            v-for="(skill, index) in (editing ? candidateSubmit.skills: candidate.skills)"
            :key="'skill-chip-' + index"
            color="primary"
            class="mt-1 ml-1 mr-0 mb-1"
            @click:close="removeSkill(skill, index)"
          >
            {{ '#' + skill.name }}
          </v-chip><br>
        </v-col>
        <v-col class="ml-3 pt-1">
          <span
            v-if="candidate.focusAreas && candidate.focusAreas.length"
            class="subtitle-3 font-weight-bold"
            style="color: rgb(100, 100, 100);"
          >Focus Areas:</span>
          <v-chip
            v-for="(skill, index) in candidate.focusAreas"
            :key="'focus-area-chip-' + index"
            color="success"
            class="mt-1 ml-1 mr-0 mb-1"
            @click:close="removeFocusArea(skill, index)"
          >
            {{ '#' + skill.name }}
          </v-chip>
        </v-col>
      </v-row>
      <general-dialog
        v-if="candidate"
        ref="userImageSelectorDialog"
        title="Upload a new profile image"
        submit-button-label="Upload"
        dialog-width="600px"
        :submit-button-enabled="false"
        @submit="submitUserImageChange()"
      >
        <user-image-selector-template
          ref="userImageSelector"
          :user-id="candidate.userId"
          @success="loadUserImage()"
        />
      </general-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';
import GeneralDialog from '@/components/general/GeneralDialog';
import UserImageSelectorTemplate from '@/components/user/UserImageSelectorTemplate';
import { createPaginationObject } from '@/helpers/PaginationHelper.js';
import { phoneFormatter } from '@/helpers/FormatHelper.js';
import InitialsPortrait from '@/components/general/InitialsPortrait';

export default {
  components: {
    GeneralDialog,
    UserImageSelectorTemplate,
    InitialsPortrait,
  },

  props:
  {
    candidate: {
      type: Object,
      default: () => {},
    },
    editing: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    candidateSubmit: null,
    userImage: null,
  }),
  computed: {
    ...mapState({
      storeUser: (state) => state.user,
      storeCandidate: (state) => state.candidate.candidate,
      pronouns: (state) => state.general.pronouns,
    }),
    fullName() {
      if (!this.candidate || !this.candidate.user || !this.candidate.user.userInformation) return '';
      return `${this.candidate.user.userInformation.firstName} ${this.candidate.user.userInformation.lastName}`;
    },
    pronoun() {
      if (!this.candidate || !this.candidate.user || !this.candidate.user.userInformation || !this.candidate.user.userInformation.pronounId) return '';
      return `(${this.pronouns[this.candidate.user.userInformation.pronounId - 1].name})`;
    },
    location() {
      if (!this.candidate || !this.candidate.user || !this.candidate.user.userInformation || !this.candidate.user.userInformation.address) return '';
      return `${this.candidate.user.userInformation.address.city ? this.candidate.user.userInformation.address.city : ''} ${(this.candidate.user.userInformation.address.region ? this.candidate.user.userInformation.address.region.abbreviation : '')} ${this.candidate.user.userInformation.address.country ? this.candidate.user.userInformation.address.country.name : ''}`;
    },
  },
  watch: {
    candidate: {
      immediate: true,
      handler(value) {
        this.loadUserImage();
        if (!value && !this.editable) return;
        this.candidateSubmit = value;
      },
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.loadUserImage();
    },
    async removeSkill(skill, index) {
      if (this.candidateSubmit.skills.length < 2) {
        this.$bus.$emit('showError', 'Must have at least one Skill in your Candidate Profile');
        return;
      }
      if (!confirm(`Are you sure you want to unlink ${skill.name} from your profile?`)) return;
      const res = await this.$api.candidate.unlink(this.candidate.id, 'skill', skill.id);
      if (res === true) {
        this.candidateSubmit.skills = this.candidateSubmit.skills.splice(index, 1);
      }
    },
    async removeFocusArea(focusArea, index) {
      if (this.candidateSubmit.focusAreas.length < 2) {
        this.$bus.$emit('showError', 'Must have at least one Area of Focus in your Candidate Profile');
        return;
      }
      if (!confirm(`Are you sure you want to unlink ${focusArea.name} from your profile?`)) return;
      const res = await this.$api.candidate.unlink(this.candidate.id, 'focusArea', focusArea.id);
      if (res === true) {
        this.candidateSubmit.focusAreas = this.candidateSubmit.focusAreas.splice(index, 1);
      }
    },
    changeUserPhoto() {
      this.$refs.userImageSelectorDialog.openForm();
    },
    submitUserImageChange() {
      this.$refs.userImageSelector.submit();
    },
    phoneFormatter(str) {
      return phoneFormatter(str);
    },
    async loadUserImage() {
      const filters = [
        {
          name: 'entityId',
          values: [this.candidate.userId.toString()],
        },
        {
          name: 'type',
          values: ['profile-image'],
        },
      ];
      const response = await this.$api.image.readImages('user', createPaginationObject(null, null, filters));
      if (response.images == null || response.images.length === 0) {
        this.userImage = null;
        return;
      }
      const [firstImage] = response.images;
      this.userImage = firstImage.image;
      this.closeDialogs();
      if (this.storeUser && this.storeUser.nameId != null && this.candidate.userId.toString() === this.storeUser.nameId.toString()) {
        this.$store.dispatch('user/getProfileImage');
      }
    },
    closeDialogs() {
      this.$refs.userImageSelectorDialog.closeForm();
    },

    async loadFile(id, fileName) {
      await this.$api.file.getFile(id, fileName);
    },
    isDateBeforeToday(dateString) {
      const endDateTime = new Date(dateString).getTime();
      const today = new Date().setHours(0, 0, 0, 0);
      return endDateTime < today;
    },
    workDetails(candidate) {
      if (!candidate
        || !candidate.candidateQualifications
        || candidate.candidateQualifications.candidateExperience == null
        || !candidate.candidateQualifications.candidateExperience.length) return '';
      const presentExperience = candidate.candidateQualifications.candidateExperience.find((x) => x.end == null || !this.isDateBeforeToday(x.end));
      if (presentExperience == null) return '';
      if (presentExperience.title == null
      && presentExperience.organizationName == null) {
        return '';
      }
      return `${presentExperience.title} ${presentExperience.organizationName ? 'at' : ''}  ${presentExperience.organizationName}`;
    },
    formatDate(datetime) {
      return this.$moment(datetime).format('YYYY-MM-DD');
    },
  },
};
</script>
<style>
  .profile-picture-button{
    background-color: rgb(0,0,0,0.4) !important;
  }
  button.profile-picture-button-hover span i.v-icon.notranslate.mdi {
    width: 100px !important;
    height: 101px !important;
    font-size: 70px;
    background-color: transparent;
    color: rgb(0,0,0,0.5);
  }
  button.profile-picture-button span i.v-icon.notranslate.mdi {
    width: 100px !important;
    height: 101px !important;
    font-size: 70px;
    background-color: transparent;
    color: rgb(255,255,255,0.7);
  }
</style>
