<template>
  <v-container
    fluid
    class="pa-0"
    style="height: 100%"
  >
    <v-overlay
      :value="overlay"
      class="align-center justify-center"
    >
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      />
    </v-overlay>
    <candidate-profile-template
      v-if="candidate"
      ref="candidateProfileTemplate"
      :candidate-id="candidate.id"
      class="mb-10"
      @updateBackgroundImage="(entity, id) => updateBackgroundImage(entity, id)"
    />
  </v-container>
</template>

<script>

import { mapState } from 'vuex';
import CandidateProfileTemplate from '@/components/candidate/profile/CandidateProfileTemplate';

export default {
  components: {
    CandidateProfileTemplate,
  },

  data: () => ({
    user: null,
    overlay: false,
  }),

  computed: {
    ...mapState({
      userId: (state) => state.user.nameId,
      candidate: (state) => state.candidate.candidate,
    }),
  },

  watch: {
    overlay: {
      handler(value) {
        if (value) {
          setTimeout(() => {
            this.overlay = false;
          }, 1000);
        }
      },
    },
  },

  created() {
    this.overlay = true;
    this.init();
    this.clearReroute();
    this.overlay = !this.overlay;
  },

  methods: {
    init() {
    },
    clearReroute() {
      if (this.$route.query.forceReroute) {
        // send quyery parameters to router to let it decide whether special redirection is needed.
        const query = { ...this.$route.query };
        delete query.forceReroute;
        this.$router.replace({ query });
      }
    },
    updateBackgroundImage(entity, id) {
      // update background image on App level
      this.$emit('updateBackgroundImage', entity, id);
    },
  },
};
</script>
