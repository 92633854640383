<template>
  <div class="ma-0 pa-0">
    <v-row
      class="pt-0 mt-0"
    >
      <v-col cols="12">
        <candidate-qualifications-form
          ref="candidateQualificationsForm"
          :read-only="readOnly"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CandidateQualificationsForm from '@/components/candidate/qualifications/CandidateQualificationsForm.vue';

export default {
  components: {
    CandidateQualificationsForm,
  },
  props:
    {
      readOnly: {
        type: Boolean,
        default: false,
      },
    },
  data: () => ({
    candidateId: null,
  }),
  methods: {
    async submit() {
      const result = {
        success: false,
        completed: false,
      };
      result.completed = await this.validate();
      if (!result.completed) return result;

      this.$bus.$emit('loading', true);

      const res = await this.$refs.candidateQualificationsForm.submit();

      if (!res) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading', false);
        return result;
      }
      this.$bus.$emit('loading', false);
      this.$bus.$emit('showSuccess', 'Successfully updated your Qualifications.');
      this.$emit('registration-success');
      result.success = true;
      return result;
    },
    async validate(showError = true) {
      const validateComponentsResult = await Promise.all([
        this.$refs.candidateQualificationsForm.validate(showError),
      ]).then((values) => !values.includes(false));
      return validateComponentsResult;
    },
    resetForm() {
      this.$refs.userInformationForm.reset();
      this.$refs.generalPhoneForm.reset();
    },
  },
};
</script>
