<template>
  <general-dialog
    ref="boardMatchRepTemplateDialog"
    :title="title"
    :title-icon="titleIcon"
    dialog-width="800"
    :suppress-footer="false"
    @submit="submitUser"
    @closed="closed"
  >
    <boardmatch-rep-information-form
      ref="boardmatchRepInformationForm"
      :boardmatch-rep-data="user ? user : null"
    />
  </general-dialog>
</template>

<script>
import BoardmatchRepInformationForm from '@/components/boardmatchRep/BoardmatchRepInformationForm';
import GeneralDialog from '@/components/general/GeneralDialog';

export default {
  components: {
    BoardmatchRepInformationForm,
    GeneralDialog,
  },
  props:
  {
    user: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: '',
    },
    titleIcon: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    userDialog: false,
    isAddressRequired: false,
    isPhoneRequired: false,
  }),
  created() {
    this.init();
  },

  methods: {
    init() {

      // this.userRoles = [{name: 'Admin', id: 3}, {name: 'Altruvest Staff', id: 4}]

    },
    openUserForm() {
      this.$refs.boardMatchRepTemplateDialog.openForm();
    },
    async submitUser() {
      const rep = await this.$refs.boardmatchRepInformationForm.submit();
      this.$bus.$emit('loading', false);
      if (!rep) {
        this.$bus.$emit('showError');
        return;
      }
      this.closeForm();
    },
    closeForm() {
      this.$refs.boardmatchRepInformationForm.reset();
      this.$refs.boardMatchRepTemplateDialog.closeForm();
      this.$emit('notify-success', 'BoardMatch Representative invited successfully');
    },
    closed() {
      this.$refs.boardmatchRepInformationForm.reset();
    },
  },
};
</script>
