<template>
  <v-container
    fluid
    style="background-color: white;border-radius: 8px;"
  >
    <v-row class="pa-3">
      <v-col>
        <h1 class="text-left mb-3">
          FAQs for Candidates
        </h1>
        <h4 class="mb-3">
          How to use the BoardMatch® Fundamentals Canada System:
        </h4>
        <div class="ml-4">
          <p class="mb-0">
            1. <a @click="goto('howToQ1')">How can I apply to BoardMatch Fundamentals and board postings?</a>
          </p>
          <p class="mb-0">
            2. <a @click="goto('howToQ2')">Where does one find the online orientation on the website? </a>
          </p>
          <p class="mb-0">
            3. <a @click="goto('howToQ3')">Is the on-line registration system fully compatible with Macintosh computers?</a>
          </p>
          <p class="mb-0">
            4. <a @click="goto('howToQ4')">If I have forgotten my password how do I track it down? </a>
          </p>
          <p class="mb-0">
            5. <a @click="goto('howToQ5')">Why won't the site accept my email address? </a>
          </p>
          <p class="mb-0">
            6. <a @click="goto('howToQ6')">Is there a print option available for the online learning?</a>
          </p>
          <p class="mb-0">
            7. <a @click="goto('howToQ7')">How will a candidate know that his/her application has been forwarded by the system to the organization?</a>
          </p>
          <p class="mb-0">
            8. <a @click="goto('howToQ8')">I am attempting to complete the Final Quiz for the BoardMatch Fundamentals Candidates’ orientation but get an internal system error when I submit my responses. How do I complete the orientation?</a>
          </p>
          <p class="mb-0">
            9. <a @click="goto('howToQ9')">What does the following phrase mean? (Charity Name) has changed your application status for General Board Member position to Offer Extended?</a>
          </p>
          <p class="mb-0">
            10. <a @click="goto('howToQ10')">Can an organization search applicant profiles to see if there is an appropriate candidate that might meet its requirements, just as candidates can search organizations?</a>
          </p>
          <p class="mb-0">
            11. <a @click="goto('howToQ11')">What are my options if an organization offers me a committee position instead of a full board position?</a>
          </p>
          <!-- <p class="mb-0">
        12. <a @click="goto('howToQ12')">If I am not interested in receiving automatic ‘board position notifications’ that may match my interests and skills, how do I disable this feature?</a>
      </p> -->
          <p>
            13. <a @click="goto('howToQ13')">Whom can I turn to for support if I have questions about the BoardMatch Fundamentals Service?</a>
          </p>
        </div>

        <h4 class="mb-3">
          How to use the BoardMatch® Fundamentals Canada System:
        </h4>
        <div class="ml-4">
          <p class="mb-0">
            1. <a @click="goto('whatIsQ1')">What is BoardMatch Fundamentals Canada?</a>
          </p>
          <p class="mb-0">
            2. <a @click="goto('whatIsQ2')">What do board members do?</a>
          </p>
          <p class="mb-0">
            3. <a @click="goto('whatIsQ3')">What does board service require?</a>
          </p>
          <p class="mb-0">
            4. <a @click="goto('whatIsQ4')">Who qualifies?</a>
          </p>
          <p class="mb-0">
            5. <a @click="goto('whatIsQ5')">Why should I be a board member?</a>
          </p>
          <p class="mb-0">
            6. <a @click="goto('whatIsQ6')">Which charitable organizations can I apply to?</a>
          </p>
          <p>
            7. <a @click="goto('whatIsQ7')">What kind of orientation and resources does BoardMatch Fundamentals provide to board candidates? </a>
          </p>
        </div>

        <h3 class="text-center mb-3">
          How to use the BoardMatch Fundamentals System
        </h3>
        <h4 id="#howToQ1">
          Q1: How can I apply to BoardMatch Fundamentals Canada and board postings?
        </h4>
        <h4>
          A:
        </h4>
        <p class="ml-4">
          <ol>
            <li>
              The entire registration process including the required BoardMatch Fundamentals Orientation takes approximately 2 hours and can be split into several sessions if you prefer.
            </li>
            <li>
              Go to the BoardMatch Fundamentals Canada Website at <a href="https://altruvest.org/">www.altruvest.org</a>  or <a href="http://boardmatch2.altruvest.org/">www.boardmatch.org</a>.
            </li>
            <li>
              Click on "Login/Sign-Up" button on the menu bar at the top right side of the screen, then click on the "Sign up" button for charity/not-for-profit, at the top right section of the next page.
            </li>
            <li>
              Complete the brief Registration Form by providing the required information.
            </li>
            <li>
              Complete the Contact Information, Qualifications, Position Matching Information and Release Form pages. Cut and paste your resume onto the Qualifications Page where your Word document will automatically be converted to Text form.
            </li>
            <li>
              Complete the BoardMatch Fundamentals Candidates’ Orientation.
            </li>
            <li>
              After you have completed all registration steps, identify 3-4 board postings of organizations whose mission aligns with your interest and passion, and who you would like to interview with, and "Apply" to each of them. You may also receive by e-mail a ‘board position notification’ making you aware of a new posting that appears to meet your skills, interest and experience, which you may wish to apply to.
            </li>
          </ol>
        </p>
        <p>
          Once you have completed all the steps listed above, you will be contacted directly by the organizations that you have applied to. They will normally get back to you within 2 weeks. However, these organizations are usually extremely busy serving their clients, so if you do not hear from them immediately, please do not be discouraged. BoardMatch Fundamentals will tactfully remind agencies to contact you within a reasonable period of time. With your permission, agencies may review your profile/resume and invite you to interview with them. Please review their posting online and respond in a timely fashion.
        </p>
        <p>
          The entire registration process takes about 2 hours and can be broken up into the stages. Click <a href="">here</a> to see the registration process diagram.
        </p>
        <h4 id="#howToQ2">
          Q2: Where does one find the online orientation on the website?
        </h4>
        <h4>
          A:
        </h4>
        <p class="ml-4">
          <ol>
            <li>
              Go to the website <a href="https://altruvest.org/">www.altruvest.org</a> or <a href="http://boardmatch2.altruvest.org/">www.boardmatch.org</a>.
            </li>
            <li>
              Click on the BoardMatch Fundamentals program.
            </li>
            <li>
              Login from the top of any page.
            </li>
            <li>
              Click on the button Login.
            </li>
            <li>
              This will take you to your Candidate Portal.
            </li>
            <li>
              Click on the "Orientation" icon.
            </li>
          </ol>
        </p>
        <h4 id="#howToQ3">
          Q3: How I can retrieve a resume that you are sending to our organization?
        </h4>
        <p>
          <span class="font-weight-bold">A:</span> There should be no problem with the Macintosh or any other system as the program is web-based. If problems persist please report to us by clicking <a href="">here</a>.
        </p>
        <h4 id="#howToQ4">
          Q4: If I have forgotten my password how do I track it down?
        </h4>
        <p>
          <span class="font-weight-bold">A:</span> Go to the <a href="">Candidate Login</a> screen and click on the "Forgot your password?" link. Enter your email and click "Reset Password". The system will reset your password and send you an email with your new password.
        </p>
        <h4 id="#howToQ5">
          Q5: Why won't the site accept my email address?
        </h4>
        <p>
          <span class="font-weight-bold">A:</span> Contact your local BoardMatch Fundamentals office (see <a @click="$refs.contactUsForm.toggleDialog()">Contact Us</a>) if this happens.
        </p>
        <h4 id="#howToQ6">
          Q6: Is there a print option available for the online learning?
        </h4>
        <p>
          <span class="font-weight-bold">A:</span> Yes, the entire module can be printed or you may choose to print one page at a time. To print the entire module click "Printable Version (Entire Module)" at the bottom of the online training page. The entire module will open in a new window, Click on "Ctrl" and "P" at the same time, or click on "File," then "Print" on your browser (Internet Explorer, Netscape, AOL, etc.). To print a single page at a time click on "Print Page" at the bottom of the page, a print dialog will automatically pop up, click yes to print.
        </p>
        <h4 id="#howToQ7">
          Q7: How will a candidate know that his/her application has been forwarded by the system to the organization?
        </h4>
        <p>
          <span class="font-weight-bold">A:</span> After applying to a board position it will show up in the 'Board Activity' section of the candidate portal/home page. Each application in the 'Board Activity' section will have an associated status that will be updated by the organization upon reviewing your application.
        </p>
        <h4 id="#howToQ8">
          Q8: I am attempting to complete the Final Quiz for the BoardMatch Fundamentals orientation but get an internal system error when I submit my responses. How do I complete the orientation?
        </h4>
        <p>
          <span class="font-weight-bold">A:</span> Contact your local BoardMatch Fundamentals office (see <a @click="$refs.contactUsForm.toggleDialog()">Contact Us</a>) if this happens.
        </p>
        <h4 id="#howToQ9">
          Q9: What does the following phrase mean? (Charitable Organization Name) has changed your application status for General Board Member position to Offer Extended?
        </h4>
        <p>
          <span class="font-weight-bold">A:</span> The organization to which you applied and interviewed with, has now offered you a volunteer board position. Please login to your portal and change Offer Extended to Accept (or Decline) accordingly, and contact the organization directly to finalize your agreement.
        </p>
        <h4 id="#howToQ10">
          Q10: Can an organization search applicant profiles to see if there is an appropriate candidate that might meet its requirements, just as candidates can search organizations?
        </h4>
        <p>
          <span class="font-weight-bold">A:</span> Yes, as discussed above under How can I apply to BoardMatch Fundamentals Canada and board postings, as you work your way through the registration process, you have the option of enabling participating organizations to review your profile/resume and thereafter invite you to interview with them. The BoardMatch Fundamentals system will advise and direct you regarding such invitations. Again, please respond in a timely fashion.
        </p>
        <h4 id="#howToQ11">
          Q11: What are my options if an organization offers me a committee position instead of a full board position?
        </h4>
        <p>
          <span class="font-weight-bold">A:</span> BoardMatch Fundamentals candidates are oriented to the roles, responsibilities, duties and liabilities of a board member, and through the matching process, come to expect that any offer will be at a board level. BoardMatch Fundamentals is primarily mandated to place candidates on boards of directors versus board committees or as program volunteers. However, some organizations prefer to have new members apprentice on a board committee prior to appointment to the full board. You may choose to follow this path, or seek out a full board position with another organization. Please remember to login to your portal and change Offer Extended to Accept or Decline.
        </p>
        <!-- <h4 id="#howToQ12">
      Q12: If I am not interested in receiving automatic ‘board position notifications’ that may match my interests and skills, how do I disable this feature?(Inovex please direct user to proper web page and steps to disable
    </h4>
    <p>
      <span class="font-weight-bold">A:</span> You can withdraw from the automated 'board candidate notifications' at any time by doing the following:
    </p>
    <p class="ml-4">
      <ul>
        <li>
          Login to your Candidate Portal/Home Page.
        </li>
        <li>
          Click the 'position matching information' registration step.
        </li>
        <li>
          Scroll down and check the box 'I no longer wish to participate in the automated matching service'.
        </li>
        <li>
          Scroll to the bottom of the page and click the "Submit" button to submit your changes.
        </li>
        <li>
          You will no longer receive automatic notifications.
        </li>
      </ul>
    </p> -->
        <h4 id="#howToQ13">
          Q13: Whom can I turn to for support if I have questions about the BoardMatch Fundamentals Service?
        </h4>
        <p>
          <span class="font-weight-bold">A:</span> If you can't find the answer in the FAQs; you may contact your local BoardMatch Fundamentals Canada office via e-mail or by phone (see <a @click="$refs.contactUsForm.toggleDialog()">Contact Us</a>)
        </p>
        <p class="ml-4">
          <ol>
            <li>
              Go to the website: <a href="">www.boardmatch.org</a>
            </li>
            <li>
              Login to your organization portal.
            </li>
            <li>
              Click on the "Orientation" icon
            </li>
          </ol>
        </p>

        <h4 class="text-center">
          What is BoardMatch Fundamentals Canada?
        </h4>
        <h4 id="#whatIsQ1">
          Q1: What is BoardMatch Fundamentals Canada?
        </h4>
        <p>
          <span class="font-weight-bold">A:</span>
        </p>
        <p class="ml-4">
          <ol>
            <li>
              BoardMatch Fundamentals Canada is a service that enables talented and enthusiastic individuals to find positions on the volunteer boards of directors of charitable organizations in Calgary, Fredericton, Greater Toronto Area, Greater Vancouver, and Thunder Bay. Over the past 8 years, we have placed more than 2400 volunteers on the boards of over 650 charitable organizations.
            </li>
            <li>
              BoardMatch Fundamentals Canada is a program of Altruvest Charitable Services, a registered charity based in Toronto, Ontario. For a complete list of our Altruvest and BoardMatch Fundamentals Canada supporters please click on "Donors & Sponsors" on the menu bar. Altruvest is pleased to be collaborating with Volunteer Calgary and Volunteer Thunder Bay to bring BoardMatch Fundamentals to their respective cities.
            </li>
            <li>
              Further information about Altruvest Charitable Services can be found at <a href="https://altruvest.org/">www.altruvest.org</a>  and about the BoardMatch Fundamentals Canada program via <a href="">Program Overview</a> link.
            </li>
          </ol>
        </p>
        <h4 id="#whatIsQ2">
          Q2: What do board members do?
        </h4>
        <p>
          <span class="font-weight-bold">A:</span>
        </p>
        <p class="ml-4">
          <ul>
            <li>
              Board members are critical supporters of the charitable organization. Collectively, board members govern-carrying out strategic planning on long-term goals, manage, set out policies, fundraise, and enhance the organization's public image. Using their contacts and professional expertise in engineering, finance, human resources, information technology, law, marketing, operations management, public relations, etc. board members advocate, organize events, foster alliances between the agency and other groups, and serve as "in-house" consultants
            </li>
            <li>
              Board members hold a fiduciary responsibility. They hold the agency accountable to the public, including clients and funders. Through activities such as policy making, reviewing financial statements, strategic planning, human resource planning, organizational development, and hiring and evaluating the performance of the executive director, the board works to ensure the organization fulfills its legal, ethical and financial obligations.
            </li>
          </ul>
        </p>
        <h4 id="#whatIsQ3">
          Q3: What does board service require?
        </h4>
        <p>
          <span class="font-weight-bold">A:</span>
        </p>
        <p class="ml-4">
          <ul>
            <li>
              Commitments are dependent on the organization, typically 5 to 15 hours a month.
            </li>
            <li>
              Term of service varies from one to four years, again dependent on the organization.
            </li>
            <li>
              Dedication to the work of the agency and its vision for the future.
            </li>
            <li>
              Willingness to contribute your expertise and to work in different areas of agency governance, including committee work, cultivating contacts and fundraising.
            </li>
            <li>
              Tolerance of differing views-a sense of humour helps!
            </li>
          </ul>
        </p>
        <h4 id="#whatIsQ4">
          Q4: Who qualifies?
        </h4>
        <p>
          <span class="font-weight-bold">A:</span> Board candidates should possess the following:
        </p>
        <p class="ml-4">
          <ul>
            <li>
              Post-secondary degree or diploma with at least 2-3 years of experience (or equivalent post-graduate education) preferred, in the following fields: accounting, engineering, finance, human resources, information technology, law, marketing, social welfare, project/operations management, public relations, etc.
            </li>
            <li>
              Leadership and critical thinking skills.
            </li>
            <li>
              Ability to work effectively as part of a team.
            </li>
            <li>
              Previous volunteer experience is a plus but not required
            </li>
            <li>
              Youth to retirement age.
            </li>
            <li>
              Diversity of life experience and ethnicity.
            </li>
          </ul>
        </p>
        <h4 id="#whatIsQ5">
          Q5: Why should I be a board member?
        </h4>
        <p>
          <span class="font-weight-bold">A:</span>
        </p>
        <p class="ml-4">
          <ul>
            <li>
              Develop and enhance leadership skills.
            </li>
            <li>
              Expand personal networks.
            </li>
            <li>
              Develop, broaden and enhance business-related skills.
            </li>
            <li>
              Deepen your understanding and support of your community.
            </li>
            <li>
              Work alongside people you might otherwise never meet to fulfill a mission you believe in.
            </li>
          </ul>
        </p>
        <h4 id="#whatIsQ6">
          Q6: Which charitable organizations can I apply to?
        </h4>
        <p>
          <span class="font-weight-bold">A:</span> Board candidates should possess the following:
        </p>
        <p class="ml-4">
          <ol>
            <li>
              Registered charities of all sizes and missions from the Greater Toronto Area participate in BoardMatch Fundamentals Canada. Both charities and not-for-profits with charitable purposes participate in BoardMatch Fundamentals Canada in Calgary, Fredericton, Thunder Bay, and Vancouver.
            </li>
            <li>
              BoardMatch Fundamentals participating organizations provide a range of services including Addiction/Drug Education, Arts/Culture, Children, Community Development, Community Law and Advocacy, Disabled, Employment Training, Environment, Health, HIV/AIDS, Homelessness, Humane/Animal Welfare, Hunger, Immigrants/Refugees, International Aid, Literacy, Mental Health, Poverty, Seniors, Women, and Youth.
            </li>
            <li>
              Participating organizations include Big Brothers and Sisters, Boys & Girls Clubs, Canadian Cancer Society, Distress Centres, Habitat for Humanity, United Way, and Volunteer Centres. A complete <a href="">list of all participating organizations</a> is available on our website.
            </li>
          </ol>
        </p>
        <h4 id="#whatIsQ7">
          Q7: What kind of orientation and resources does BoardMatch Fundamentals Canada provide to board candidates?
        </h4>
        <p>
          <span class="font-weight-bold">A:</span>
        </p>
        <p class="ml-4">
          <ul>
            <li>
              BoardMatch Fundamentals Canada provides both online and in-person Candidate Orientation sessions.
              (in person orientation sessions in select locations)
            </li>
            <li>
              The online version is available to candidates who have completed the Contact Information, Qualifications, and Position Matching steps of the Registration process. To access this online orientation please go to the Home Page <a href="http://boardmatch2.altruvest.org/">www.boardmatch.org</a> , login and click on the 'Orientation' registration step in you candidate porta/home page.
            </li>
            <li>
              You can exit and return to the learning module at any time. In the future we will be adding new topics and learning modules that are of interest to Board members and candidates..
            </li>
            <li>
              For a schedule of available dates for local in-person Candidate Orientation sessions, please click <a @click="$refs.overviewForm.toggleDialog()">here</a>.
            </li>
          </ul>
        </p>
        <p
          class="text-center font-weight-bold"
          style="color: red;"
        >
          NOTE: BoardMatch Fundamentals Canada does not pre-screen nor verify the background information provided by prospective board candidates and participating organizations. Parties are encouraged and expected to engage in their own due diligence exercise as the matching process progresses.
        </p>
      </v-col>
    </v-row>
    <contact-us-form ref="contactUsForm" />
    <program-overview-form ref="overviewForm" />
  </v-container>
</template>

<script>
import ContactUsForm from '@/components/infoForms/ContactUsForm';
import ProgramOverviewForm from '@/components/infoForms/ProgramOverviewForm';

export default {
  components: {
    ContactUsForm,
    ProgramOverviewForm,
  },

  data: () => ({
  }),

  methods: {
    goto(elementId) {
      const el = document.getElementById(`#${elementId}`);

      if (el != null) el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    },
  },
};
</script>
