import axios from 'axios';
import Api from '../Api';

class UserApi extends Api {
  constructor() {
    super('users', 'users');
  }

  async getUserById(id) {
    const res = await this.getById(id);
    return res;
  }

  async getPronoun() {
    const res = await this.get('pronoun');
    return res;
  }

  async create(data) {
    const res = await this.createReturnData(data);
    return res;
  }

  async update(data) {
    const res = await this.updateReturnData(data);
    return res;
  }

  async checkUserName(data) {
    let res = {};
    const errorText = `Could not check ${this.model}`;
    try {
      res = await axios.post(`/${this.endpoint}/${data}`);
      if (res.status === 200) {
        res = res.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = false;
    }
    return res;
  }
}
const userApi = new UserApi();
export default userApi;
