/* eslint-disable consistent-return */
/* eslint-disable no-async-promise-executor */
/* eslint-disable no-param-reassign */
import UserStateApi from '@/api/users/UserStateApi';
import UserApi from '@/api/users/UserApi';
import ImageApi from '@/api/generals/ImageApi';
import UserInvolvementsApi from '@/api/users/UserInvolvementsApi';
import { createPaginationObject } from '@/helpers/PaginationHelper.js';

export default {
  namespaced: true,

  state: () => ({
    user: null,
    nameId: null,
    userInfo: null,
    selectedInv: null,
    userState: null,
    userInv: null,
    sortedUserInvs: [],
    profileImage: null,
    userPromise: null,
    invPromise: null,
  }),
  mutations: {

    storeUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    storeUserState(state, userState) {
      state.userState = userState;
    },
    storeSelectedInv(state, data) {
      state.selectedInv = data;
    },

    storeSortedUserInvs(state, data) {
      state.sortedUserInvs = data;
    },

    storeUserInv(state, data) {
      state.userInv = data;
    },
    storeProfileImage(state, data) {
      state.profileImage = data;
    },
    clearData(state) {
      state.user = null;
      state.nameId = null;
      state.userInfo = null;
      state.selectedInv = null;
      state.userState = null;
      state.userInv = null;
      state.sortedUserInvs = [];
      state.profileImage = null;
    },
  },
  actions: {
    setUserInfo({ commit, state }) {
      return new Promise(async (resolve) => {
        state.userPromise = UserApi.getUserById(state.nameId);
        const userInfo = await state.userPromise;
        state.userPromise = null;
        if (userInfo) commit('storeUserInfo', userInfo);

        resolve();
      });
    },

    getUserState({ commit, state }, userId = null) {
      return new Promise(async (resolve) => {
        const userState = await UserStateApi.getById(userId || state.nameId);
        if (userState) commit('storeUserState', userState);
        resolve();
      });
    },

    submitUserState({ state }) {
      if (!state.userState) return;
      return new Promise(async (resolve, reject) => {
        const userState = await UserStateApi.create(state.userState);

        if (!userState) reject();

        resolve();
      });
    },

    updateUserState({ commit, state }, userState) {
      if (!userState) return;
      return new Promise(async (resolve, reject) => {
        const result = await UserStateApi.update(userState);

        if (!result) reject();
        const newState = { ...state.userState, lastState: userState.lastState }; // copy the candidate/charity/admin state, and update the lastState
        commit('storeUserState', newState);
        resolve();
      });
    },

    getUserInv({ commit, state }) {
      return new Promise(async (resolve, reject) => {
        state.invPromise = UserInvolvementsApi.getById(state.nameId);
        const result = await state.invPromise;
        state.invPromise = null;

        if (!result) reject();
        commit('storeUserInv', result);
        resolve();
      });
    },
    deleteProfileImage({ commit }) {
      commit('storeProfileImage', null);
    },
    async getProfileImage({ commit, state }) {
      return new Promise(async (resolve) => {
        const filters = [
          {
            name: 'entityId',
            values: [state.nameId.toString()],
          },
          {
            name: 'type',
            values: ['profile-image'],
          },
        ];
        const response = await ImageApi.readImages('user', createPaginationObject(null, null, filters));
        if (!response || !response.images || response.images.length === 0) return;
        const [firstImage] = response.images;

        commit('storeProfileImage', firstImage.image);
        resolve();
      });
    },
  },
  getters: {
    getBaseUrl: (state) => {
      if (!state.userState) return '';
      if (state.userState.boardmatchRole) return 'boardmatch-candidates';
      if (state.userState.charityRole) return 'charity-profile';
      if (state.userState.candidateRole) return 'candidate-profile';
      return '';
    },
    getSelectedInv: (state) => {
      if (!state.selectedInv) {
        if (!state.userState) return;
        let tempInv = null;
        if (state.userState.boardmatchRole) {
          tempInv = state.sortedUserInvs.find((x) => x.involvement === 'boardmatch');
        } else if (state.userState.charityRole) {
          tempInv = state.sortedUserInvs.find((x) => x.involvement === 'charityRepresentative' && x.charityId === state.userState.charityId);
        } else if (state.userState.candidateRole) {
          tempInv = state.sortedUserInvs.find((x) => x.involvement === 'candidate');
        } else {
          [tempInv] = state.sortedUserInvs;
        }
        state.selectedInv = tempInv;
        return tempInv;
      }
      return state.selectedInv;
    },
    getUserInfo: (state) => state.userInfo,
    profileImage: (state) => state.profileImage,
  },
};
