<template>
  <v-container
    fluid
    class="pa-2 pb-12 ma-2 mt-12"
    style="background-color: white; border-radius: 8px;"
  >
    <v-row class="text-left pl-6 pt-5">
      <v-col cols="12">
        <h1>Admin</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-expansion-panels class="ml-0">
          <v-expansion-panel>
            <v-expansion-panel-header><h3>BoardMatch Users</h3></v-expansion-panel-header>
            <v-expansion-panel-content>
              <boardmatch-admin-users />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="mt-3">
            <v-expansion-panel-header><h3>Admin Roles</h3></v-expansion-panel-header>
            <v-expansion-panel-content>
              <admin-roles
                :roles="roles"
                :is-boardmatch="true"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="mt-3">
            <v-expansion-panel-header><h3>Social Media</h3></v-expansion-panel-header>
            <v-expansion-panel-content>
              <boardmatch-social-media />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import BoardmatchAdminUsers from '@/views/boardmatch/admin/BoardmatchAdminUsers.vue';
import BoardmatchSocialMedia from '@/views/boardmatch/BoardmatchSocialMedia.vue';
import AdminRoles from '@/views/user/AdminRoles';

export default {
  components: {
    BoardmatchAdminUsers,
    AdminRoles,
    BoardmatchSocialMedia,
  },
  data: () => ({
    roles: [],

  }),
  computed: {
    ...mapState({
      // boardmatchRep: (state) => state.boardmatchRep.boardmatchRep,
      currentUser: (state) => state.user,
    }),
  },
  created() {
    this.getRoles();
  },

  methods: {
    async getRoles() {
      // console.log(this.currentUser);
      // if (!this.boardmatchRep) return;
      // this.roles = await this.$api.boardmatchRepRoles.get(`permissions/${this.currentUser.nameId}`);
      this.roles = [{ name: 'Admin', description: 'Admin, has all permissions' }, { name: 'Staff', description: 'Has permissions to all sections except admin section' }];
    },
  },

};
</script>
