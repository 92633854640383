<template>
  <v-app
    id="inspire"
    :class="`${isLoginPage ? 'login-page' : 'regular-page'}`"
    :style="`height: 100vh; ${isLargeScreen ? '' : 'display: flex; justify-content: center; overflow-x: scroll;'} position: relative; min-height: 650px;`"
  >
    <template v-if="!isLoginPage && !isDebug">
      <the-snackbar />
      <the-loading />
    </template>
    <div
      ref="scrollDiv"
      style="height: 100vh; position:relative;"
    >
      <div
        v-if="!isLoginPage && !isDebug"
        :style="`width: 100%; display: flex; position:fixed; z-index: 90; height: 117px; left: ${-xScroll}px;`"
      >
        <the-navigation-menu
          ref="refHeader"
          style=" background-color: white;"
          @hideDrawer="hideDrawer"
          @triggerSearch="triggerSearch()"
        />
      </div>
      <div
        :class="isLoginPage ? 'background-white' : 'background-gray'"
        style="width: 100%; height: 100%; min-height: 100vh;"
      >
        <v-row
          style="width: 1279px; min-height: 100vh; position: absolute; left: 50%; margin: 0 0 0 -640px;"
        >
          <v-img
            v-if="backgroundImage"
            id="background-image"
            :src="`${baseUrl}images/${backgroundImage}`"
            alt=""
            :class="`background-image-${isLoggedIn ? 'logged-in' : 'logged-out'}`"
            width="1279px"
            height="400px"
          />
          <v-col
            cols="12"
            :class="`ma-0 pb-0 ${(!isLoginPage && !isDebug) ? 'pa-0 background-gray' : 'pa-0 background-white'}`"
            style="width: 1279px; min-height: 100vh; position: absolute"
          >
            <template v-if="!isLoginPage && !isDebug">
              <v-main
                :class="'' + (isLoggedIn ? 'pt-0  logged-in-view' : 'pt-0 logged-out-view')"
                style="min-height: calc(100vh - 160px)"
              >
                <router-view
                  ref="routerView"
                  @triggerSearch="triggerSearch()"
                  @updateBackgroundImage="(entity, id) => updateBackgroundImage(entity, id)"
                />
              </v-main>
            </template>

            <template v-else>
              <v-main
                class="pt-0"
                :style="isLoginPage ? 'height: 100vh !important;' : ''"
              >
                <v-container
                  fluid
                  class="pa-0 fill-height"
                >
                  <router-view />
                </v-container>
              </v-main>
            </template>
            <general-footer
              v-if="!isLoginPage"
              class="mt-4"
            />
          </v-col>
        </v-row>
      </div>
    </div>
  </v-app>
</template>

<script>
import { mapGetters, mapState } from 'vuex';// import { mapGetters, mapState, mapMutations } from 'vuex';
import axios from 'axios';
import TheNavigationMenu from '@/components/app/TheNavigationMenu';
import TheSnackbar from '@/components/app/TheSnackbar';
import TheLoading from '@/components/app/TheLoading';
import Router from '@/router/index.js';
import GeneralFooter from '@/components/general/GeneralFooter';

export default {
  name: 'App',
  components: {
    TheNavigationMenu,
    TheSnackbar,
    TheLoading,
    GeneralFooter,
  },
  data: () => ({
    drawer: true,
    user: null,
    backgroundImage: null,
    xScroll: 0,
  }),
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.auth.isLoggedIn,
      isSelf: (state) => state.auth.isSelf,
      isCharity: (state) => state.auth.isCharity,
      userInv: (state) => state.user.userInv,
      userState: (state) => state.user.userState,
      userId: (state) => state.user.nameId,
    }),
    ...mapGetters({
      getBaseUrl: 'user/getBaseUrl',
      getSelectedInv: 'user/getSelectedInv',
    }),
    isDebug() {
      return this.$route.path === '/debug';
    },
    isLoginPage() {
      return Router.app.$route.path === '/login';
    },
    baseUrl() {
      const baseURL = axios.defaults.baseURL.endsWith('/') ? axios.defaults.baseURL : `${axios.defaults.baseURL}/`;

      return baseURL;
    },
    isLargeScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return false;
        case 'sm': return false;
        case 'md': return false;
        case 'lg': return true;
        case 'xl': return true;
        default: return true;
      }
    },
    leftOffset() {
      return 0;
    },
  },
  watch: {
    $route(to) {
      this.layout = to.name !== 'Login' ? to.name : 'login';
      if (to.name !== 'candidate-profile' && to.name !== 'charity-profile') this.backgroundImage = null;
    },
    isLoggedIn: {
      handler(value) {
        if (!value) return;
        this.init();
      },
    },
    'userState.charityId': function (value) {
      if (value) this.$store.dispatch('charity/getCharity', value);
    },
  },
  created() {
    this.registerScrollEvent();
    if (!this.isLoggedIn) return;
    this.init();
  },
  unmounted() {
    const el = document.getElementById('inspire');
    el.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    init() {
      this.$store.dispatch('user/getUserState').then(() => {
        this.$store.dispatch('user/setUserInfo').then(() => {
          this.$store.dispatch('user/getUserInv').then(() => {
            if (!this.userInv) return;

            const sortedUserInvs = [];

            if (this.userInv.boardmatchRepresentative) {
              sortedUserInvs.push({
                id: sortedUserInvs.length, involvement: 'boardmatch', charityId: null, name: 'Altruvest Boardmatch',
              });
            }

            if (this.userInv.charityRepresentative) {
              this.userInv.charityRepresentative.forEach((charityRep) => {
                sortedUserInvs.push({
                  id: sortedUserInvs.length, involvement: 'charityRepresentative', charityRepId: charityRep.id, charityId: charityRep.charityId, name: `Charity ${charityRep.charity.name}`,
                });
              });
              /* const unique = this.userInv.charityRepresentative.filter((value, index, self) => index === self.findIndex((t) => (
                t.place === value.place && t.name === value.name
              )));
              unique.forEach((u) => {
                sortedUserInvs.push({
                  id: sortedUserInvs.length, involvement: 'charityRepresentative', charityId: u.charityId, name: `Charity '${u.charity.name}'`,
                });
              }); */
            }

            if (this.userInv.candidate) {
              sortedUserInvs.push({
                id: sortedUserInvs.length, involvement: 'candidate', charityId: null, name: 'Candidate',
              });
            }
            this.$store.commit('user/storeSortedUserInvs', sortedUserInvs);

            // const selectedInv = this.getSelectedInv;
            // if(selectedInv.involvement === 'candidate')
            //   this.$store.dispatch('candidate/getCandidate', this.userId)

            if (this.userState.charityRole === 1 && this.userState.charityId !== null) this.$store.dispatch('charity/getCharity', this.userState.charityId);

            let baseUrl = this.userState.lastState;

            if (!baseUrl) baseUrl = this.getBaseUrl;
            // loginAs issue fixed with this 2 statements
            if (this.isCharity && !this.isSelf) {
              baseUrl = '/charity-onboarding';
            }
            if (!this.isCharity && !this.isSelf) {
              baseUrl = '/candidate-onboarding';
            }
            // let selectedInv = sortedUserInvs.find((x) => (this.userState.candidateRole === 1 && x.involvement === 'candidate')
            //    || (this.userState.boardmatchRole === 1 && x.involvement === 'boardmatch')
            //    || (this.userState.charityRole === 1 && x.involvement === 'charityRepresentative' && x.charityId === this.userState.charityId));
            // if (selectedInv == null) [selectedInv] = sortedUserInvs;
            // this.storeSelectedInv(selectedInv);
            if (this.$route.path !== baseUrl) this.$router.push(`${baseUrl}`);
            this.$store.dispatch('user/getProfileImage');
          });
        });
      });
    },
    hideDrawer() {
      this.drawer = !this.drawer;
    },
    updateBackgroundImage(entity, id) {
      this.backgroundImage = !entity ? null : `background-image/${entity}/${id}?${new Date().getTime()}`; // add a time at the end to force refresh
    },
    triggerSearch() {
      if (this.$refs && this.$refs.routerView && this.$refs.routerView) {
        this.$refs.routerView.triggerSearch();
      }
    },
    /* ...mapMutations('user', [
      'storeSelectedInv',
    ]), */
    async registerScrollEvent() {
      await this.$nextTick();
      const el = document.getElementById('inspire');
      el.addEventListener('scroll', this.onScroll);
    },
    onScroll() {
      const el = document.getElementById('inspire');
      this.xScroll = el.scrollLeft; /* or: e.target.documentElement.scrollTop */
    },
  },
};
</script>
<style scoped>
.app-bar {
  border-bottom-style: solid;
  border-width: 1px;
  border-color: #e0e0e0 !important;
}
.logged-in-view{
  margin-top: 110px;
}
.logged-out-view{
  margin-top: 71px;
  height: 100%;
}
.background-gray{
  background-color: rgb(239, 239, 239);
}
.background-image-logged-in{
  z-index: 1;
  margin-top: 116px;
}
.background-image-logged-out{
  z-index: 1;
  margin-top: 82px;
}
.image-overlap {
  margin-top: -522px;
}

</style>
<style>
.regular-page .v-application--wrap {
  background-color: rgb(239, 239, 239) !important;

}
</style>
