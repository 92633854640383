<template>
  <div style="width: 100%;">
    <v-overlay
      :value="overlay"
      class="align-center justify-center"
    >
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      />
    </v-overlay>

    <v-row class="align-center mt-2">
      <v-col
        cols="11"
        class="pa-0 pl-2"
      >
        <div
          v-if="searchSnapShot || filterApplied"
          class="ml-1"
        >
          <span
            style="font-size: medium; font-weight: bold;"
          >
            Search Results For:
          </span>
          <span
            v-if="searchSnapShot"
            style="font-size: medium; font-weight: bold;"
          >
            " {{ searchSnapShot }} "
          </span>
        </div>
        <span
          v-else
          class="ml-1"
          style="font-size: medium; font-weight: bold;"
        >
          Showing All Available Results:
        </span>
      </v-col>
      <v-col
        cols="1"
        class="pa-0 pl-6"
      >
        <v-btn
          x-large
          outlined
          type="submit"
          color="default"
          class="pa-0 filter-button mt-0 ml-1"
          @click="toggleFilters()"
        >
          <v-icon>mdi-tune</v-icon>
        </v-btn>
        <v-btn
          v-if="searchSnapShot || filterApplied"
          x-large
          outlined
          type="submit"
          color="default"
          class="pa-0 filter-button mt-0 ml-1"
          @click="clearFilters()"
        >
          <v-icon color="error">
            mdi-delete
          </v-icon>
        </v-btn>
      </v-col>
      <v-col
        v-if="filterChips.length != 0 && filterApplied"
        class="pa-2"
      >
        <v-expand-transition>
          <v-card
            v-show="filtersExpanded && filterChips.length != 0"
            elevation="0"
            class="filter-chips-card"
          >
            <v-chip
              v-for="(chip, index) in filterChips"
              :key="`filter-chip-${index}`"
              :class="`mr-1 mt-1 ${chip.class} filter-chip`"
              close
              @click:close="removeFilter(chip)"
            >
              {{ chip.name }}
            </v-chip>
          </v-card>
        </v-expand-transition>
      </v-col>
    </v-row>
    <!-- Filters form -->
    <general-dialog
      ref="filterDialog"
      :title="`Search Filters`"
      submit-button-label="Save"
      dialog-width="1100"
      :submit-button-enabled="false"
      :suppress-footer="false"
      @submit="applyFilters()"
    >
      <v-row class="mt-0">
        <v-col class="pa-0">
          <v-expand-transition>
            <v-card
              v-show="filtersExpanded"
              elevation="0"
              class="filter-chips-card pa-3 pt-0 pb-0"
            >
              <v-row
                class="filter-section mt-2"
              >
                <v-col
                  v-if="charitySelected"
                  cols="4"
                >
                  <v-autocomplete
                    v-model.lazy="selectedCity"
                    :items="charityCities"
                    :label="'City'"
                    item-text="name"
                    item-value="name"
                    prepend-inner-icon="mdi-navigation-variant"
                    hide-details=""
                    x-large
                    outlined
                    multiple
                  />
                </v-col>
                <v-col
                  v-if="charitySelected"
                  cols="4"
                >
                  <v-select
                    v-model.lazy="selectedProvince"
                    :items="charityProvince"
                    :label="'Province'"
                    item-text="name"
                    item-value="name"
                    prepend-inner-icon="mdi-home-group"
                    hide-details=""
                    x-large
                    outlined
                    clearable
                    @clear="selectedProvince = ''"
                  >
                  </v-select>
                </v-col>
                <v-col
                  v-if="charitySelected || postingSelected"
                  cols="4"
                >
                  <v-select
                    v-model.lazy="selectedPositions"
                    :items="positions"
                    item-text="name"
                    item-value="name"
                    :label="'Positions'"
                    prepend-inner-icon="mdi-format-list-bulleted"
                    hide-details=""
                    x-large
                    outlined
                    multiple
                  />
                </v-col>
                <v-col
                  v-if="candidateSelected"
                  cols="4"
                >
                  <v-autocomplete
                    v-model.lazy="boardExperienceSelected"
                    :items="boardExperienceTypes"
                    :label="'Board Experience'"
                    item-text="name"
                    item-value="id"
                    prepend-inner-icon="mdi-briefcase"
                    hide-details=""
                    x-large
                    outlined
                  />
                </v-col>
                <v-col
                  v-if="postingSelected"
                  cols="4"
                >
                  <v-menu
                    ref="menu"
                    v-model="dateRangeMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateRangeText"
                        :label="'Posting Date Range'"
                        prepend-inner-icon="mdi-calendar"
                        hide-details=""
                        outlined
                        x-large
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template #append>
                          <v-icon
                            v-if="selectedPostingDateRange && selectedPostingDateRange.length"
                            @click="removeFilter({source: 'DateRange'})"
                          >
                            mdi-close
                          </v-icon>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      ref="postingDateRangePicker"
                      v-model="selectedPostingDateRange"
                      width="415px"
                      range
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="dateRangeMenu = false"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col
                  v-if="postingSelected || candidateSelected"
                  :cols="charitySelected || candidateSelected ? '12' : '6'"
                  class="pl-5"
                >
                  <span class="text-h6">Skills</span>
                  <v-chip-group
                    v-model="selectedSkills"
                    column
                    multiple
                    class="ml-0"
                  >
                    <v-chip
                      v-for="skill in skills"
                      :key="skill.id"
                      :value="skill.id"
                      class="primary"
                      :outlined="outlined(skill, 'skill')"
                    >
                      {{ skill.name }}
                    </v-chip>
                  </v-chip-group>
                </v-col>

                <v-divider
                  vertical
                  style="margin-top: 15px; margin-bottom: 15px;"
                />
                <v-col
                  v-if="charitySelected || candidateSelected"
                  :cols="postingSelected || candidateSelected ? '12' : '6'"
                  class="pl-8"
                >
                  <span class="text-h6">Focus Areas</span>
                  <v-chip-group
                    v-model="selectedFocusAreas"
                    column
                    multiple
                    class="ml-0"
                  >
                    <v-chip
                      v-for="focusArea in focusAreas"
                      :key="focusArea.id"
                      :value="focusArea.id"
                      class="success"
                      :outlined="outlined(focusArea, 'focusArea')"
                    >
                      {{ focusArea.name }}
                    </v-chip>
                  </v-chip-group>
                </v-col>
              </v-row>
            </v-card>
          </v-expand-transition>
        </v-col>
      </v-row>
    </general-dialog>
    <v-row
      v-if="postingSelected"
    >
      <v-col
        cols="12"
        class="mt-0 pa-2"
      >
        <v-divider
          class="grey"
        />
      </v-col>
      <v-col
        cols="12"
        class="mt-3"
      >
        <search-charity-posting-widget
          ref="searchCharityPostingWidget"
          :filters="filters"
          :search="search"
          :existing-applications="existingApplications"
          @showCharityPosting="(charityPostingId) => showCharityPosting(charityPostingId)"
        />
      </v-col>
    </v-row>
    <v-row
      v-if="charitySelected"
    >
      <v-col
        cols="12"
        class="mt-3"
      >
        <v-divider class="grey" />
      </v-col>
      <v-col
        cols="12"
        class="mt-3"
      >
        <search-charity-widget
          ref="searchCharityWidget"
          :filters="filters"
          :search="search"
          @showCharity="(charityId) => showCharity(charityId)"
          @showCharityPostingByCharity="(charityId) => showCharityPostingByCharity(charityId)"
        />
      </v-col>
    </v-row>
    <v-row
      v-if="candidateSelected && isCharityRep"
    >
      <v-col
        cols="12"
        class="mt-3"
      >
        <v-divider class="grey" />
      </v-col>
      <v-col
        cols="12"
        class="mt-3"
      >
        <search-candidate-widget
          ref="searchCandidateWidget"
          :filters="filters"
          :search="search"
          @showCandidate="(candidateId) => showCandidate(candidateId)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        class="mt-3"
      >
        <v-divider class="light-grey" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { createPaginationObject } from '@/helpers/PaginationHelper.js';
import SearchCharityWidget from '@/components/search/SearchCharityWidget';
import SearchCandidateWidget from '@/components/search/SearchCandidateWidget';
import SearchCharityPostingWidget from '@/components/search/SearchCharityPostingWidget';
import GeneralDialog from '@/components/general/GeneralDialog';

export default {
  components: {
    SearchCharityWidget,
    SearchCandidateWidget,
    SearchCharityPostingWidget,
    GeneralDialog,
  },
  props: {
    searchProp: {
      type: String,
      default: '',
    },
    existingApplications: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    searchRadioButton: 'charities',
    showAdvancedFilter: false,
    filtersExpanded: true,
    positions: [],
    searchSnapShot: '',
    selectedPositions: [],
    selectedCharity: null,
    selectedPosting: null,
    subTableOpen: false,
    subTable: 'positions',
    subTableFilters: [],
    charityCities: [],
    selectedCity: null,
    charityProvince: [],
    selectedProvince: null,
    boardExperience: [],
    filterApplied: false,
    dateRanges: [
      {
        value: -1,
        name: 'All Open Postings',
      },
      {
        value: 3,
        name: '3 months',
      },
      {
        value: 1,
        name: '1 month',
      },
      {
        value: 0,
        name: 'Today',
      },
    ],
    dateRangeMenu: false,
    selectedPostingDateRange: [],
    loadingData: null,
    selectedSkills: [],
    selectedFocusAreas: [],
    boardExperienceSelected: 1,
    overlay: false,
  }),
  computed: {
    ...mapGetters({
      getSelectedInv: 'user/getSelectedInv',
    }),
    isCharityRep() {
      return this.getSelectedInv.involvement === 'charityRepresentative';
    },
    selectedCategories: {
      get() {
        return this.chosenCategories;
      },
      set(value) {
        if (value != null) {
          this.$store.dispatch('navigation/selectCategories', value);
        }
      },
    },
    categories() {
      const cats = [
        {
          id: 2,
          name: 'Charities',
        },
        {
          id: 3,
          name: 'Positions',
        },
      ];
      if (this.isCharityRep) {
        cats.push({
          id: 1,
          name: 'Candidates',
        });
      }
      return cats;
    },
    search: {
      get() {
        return this.navigationSearch;
      },
      set(value) {
        if (value != null) {
          this.$store.dispatch('navigation/saveSearch', { search: value });
        }
      },
    },
    tabName() {
      return this.searchRadioButton.charAt(0).toUpperCase() + this.searchRadioButton.slice(1);
    },
    ...mapState({
      onboardingSteps: (state) => state.candidate.onboarding,
      navigationSearch: (state) => state.navigation.search,
      navigationFilters: (state) => state.navigation.filters,
      boardExperienceTypes: (state) => state.candidate.boardExperienceTypes,
      skills: (state) => state.candidate.candidateSkills,
      focusAreas: (state) => state.candidate.candidateFocusAreas,
      // filtersExpanded: (state) => state.navigation.expanded,
      chosenCategories: (state) => state.navigation.selectedCategories,
    }),
    // expandFilters: {
    //   get() {
    //     return this.filtersExpanded;
    //   },
    //   set(value) {
    //     if (value !== null) {
    //       this.$store.dispatch('navigation/expandFilters');
    //     }
    //   },
    // },
    onboardingCompleted() {
      return this.onboardingSteps != null && this.onboardingSteps.length > 0 && this.onboardingSteps.findIndex((x) => x.completedPercent !== 100) === -1;
    },
    subTableTitle() {
      if (this.subTable === '') return this.subTable;
      return this.subTable.charAt(0).toUpperCase() + this.subTable.slice(1);
    },
    candidateSelected() {
      return this.selectedCategories.findIndex((x) => x === 1) > -1;
    },
    charitySelected() {
      return this.selectedCategories.findIndex((x) => x === 2) > -1;
    },
    postingSelected() {
      return this.selectedCategories.findIndex((x) => x === 3) > -1;
    },
    filters() {
      const filters = [];
      // city
      if ((this.charitySelected || this.postingSelected) && this.selectedCity && this.selectedCity.length > 0) {
        filters.push({ name: 'City', values: this.selectedCity });
      }
      // province
      if ((this.charitySelected || this.postingSelected) && this.selectedProvince && this.selectedProvince.length > 0) {
        filters.push({ name: 'Province', values: [this.selectedProvince] });
      }
      // positions
      if ((this.charitySelected || this.postingSelected) && this.selectedPositions && this.selectedPositions.length > 0) {
        filters.push({ name: 'Positions', values: this.selectedPositions });
      }
      // date range
      if (this.postingSelected && this.selectedPostingDateRange.length > 0) {
        filters.push({ name: 'DateRange', values: this.selectedPostingDateRange });
      }
      // Board Experience
      if (this.candidateSelected) {
        filters.push({ name: 'BoardExperience', values: [this.boardExperienceSelected] });
      }
      // skills
      if ((this.candidateSelected || this.postingSelected) && this.selectedSkills && this.selectedSkills.length > 0) {
        filters.push({ name: 'skillSet', values: this.selectedSkills });
      }
      // focusAreas
      if ((this.candidateSelected || this.charitySelected) && this.selectedFocusAreas && this.selectedFocusAreas.length > 0) {
        filters.push({ name: 'focusAreas', values: this.selectedFocusAreas });
      }
      return filters;
    },
    filterChips() {
      const chips = [];
      // search string
      // if (this.searchSnapShot !== '') {
      //   chips.push({
      //     source: 'searchSnapShot', name: this.searchSnapShot, class: 'success',
      //   });
      // }
      this.filters.forEach((filter) => {
        if (filter.name === 'City') {
          filter.values.forEach((value) => {
            chips.push(
              {
                source: filter.name,
                id: value,
                name: value,
                class: 'accent',
              },
            );
          });
        } else if (filter.name === 'Province') {
          filter.values.forEach((value) => {
            chips.push(
              {
                source: filter.name,
                id: value,
                name: value,
                class: 'accent',
              },
            );
          });
        } else if (filter.name === 'Positions') {
          filter.values.forEach((value) => {
            chips.push({
              source: filter.name, id: value, name: value, class: 'accent',
            });
          });
        } else if (filter.name === 'DateRange') {
          const dateRangeId = filter.values[0];

          if (dateRangeId > -1) { // we don't want to add a filter chip if the date range is set to "all"
            const dateRange = this.dateRanges.find((x) => x.value === dateRangeId);
            if (dateRange) {
              chips.push({
                source: filter.name, id: dateRangeId, name: dateRange.name, class: 'warning',
              });
            }
          }
        } else if (filter.name === 'BoardExperience') {
          const boardExperienceId = filter.values[0];

          if (boardExperienceId > 1) { // we don't want to add a filter chip if the board experience is set to "None", which is id 1
            const boardExperience = this.boardExperienceTypes.find((x) => x.id === boardExperienceId);
            if (boardExperience) {
              chips.push({
                source: filter.name, id: boardExperienceId, name: boardExperience.name, class: 'info',
              });
            }
          }
        } else if (filter.name === 'skillSet') {
          const selectedSkills = this.skills.filter((skill) => filter.values.includes(skill.id));

          selectedSkills.forEach((skill) => {
            chips.push({
              source: filter.name, id: skill.id, name: skill.name, class: 'primary',
            });
          });
        } else if (filter.name === 'focusAreas') {
          const selectedFocusAreas = this.focusAreas.filter((focusArea) => filter.values.includes(focusArea.id));

          selectedFocusAreas.forEach((focusArea) => {
            chips.push({
              source: filter.name, id: focusArea.id, name: focusArea.name, class: 'success',
            });
          });
        }
      });
      return chips;
    },
    dateRangeText() {
      let rangeText = 'Not Specified';
      if (this.selectedPostingDateRange.length === 1) rangeText = `Since ${this.selectedPostingDateRange[0]}`;
      if (this.selectedPostingDateRange.length > 1) rangeText = `${this.selectedPostingDateRange[0]} - ${this.selectedPostingDateRange[1]}`;
      const dateTitle = this.$refs.postingDateRangePicker ? (this.$refs.postingDateRangePicker).$children[0].$children[0].$el.getElementsByClassName('v-picker__title__btn v-date-picker-title__date')[0] : null;
      if (dateTitle != null) dateTitle.textContent = rangeText;
      return rangeText;
    },
  },
  watch: {
    /* navigationSearch: {
      immediate: true,
      handler(value) {
        this.search = value;
        this.searchEntries(null, 'init');
      },
    }, */
    selectedCategories: {
      immediate: false,
      handler() {
        this.searchEntries(null, 'init');
      },
    },
    async search(value) {
      if (value && this.loadingData != null) {
        await this.loadingData;
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      if (this.$route.query.chid) {
        const charityId = Number(this.$route.query.chid); // pull from query param
        this.showCharity(charityId);
        const query = { ...this.$route.query };
        delete query.chid;
        this.$router.replace({ query });
      } else if (this.$route.query.cid) {
        const candidateId = Number(this.$route.query.cid); // pull from query param
        this.showCandidate(candidateId);
        const query = { ...this.$route.query };
        delete query.cid;
        this.$router.replace({ query });
      } else if (this.$route.query.pid) {
        const positionId = Number(this.$route.query.pid); // pull from query param
        this.showCharityPosting(positionId);
        const query = { ...this.$route.query };
        delete query.pid;
        this.$router.replace({ query });
      }
      this.overlay = true;
      this.loadingData = Promise.all(
        [
          await this.loadTypes(),
          await this.getPositions(),
          await this.getCharityCities(),
          await this.getCharityProvince(),
        ],
      );
      await this.loadingData;
      await this.searchEntries(null, 'init');
      this.overlay = false;
    },
    applyFilters() {
      if (this.filterChips.length > 0) {
        this.filterApplied = true;
      } else {
        this.filterApplied = false;
      }
      this.$refs.filterDialog.closeForm();
      this.overlay = true;
      this.$emit('triggerSearch');
    },
    async loadTypes() {
      const promises = [];
      if (!this.boardExperienceTypes.length) promises.push(this.$store.dispatch('candidate/getBoardExperienceTypes'));
      if (!this.skills.length) promises.push(this.$store.dispatch('candidate/getCandidateSkills'));
      if (!this.focusAreas.length) promises.push(this.$store.dispatch('candidate/getCandidateFocusAreas'));
      await Promise.all(promises);
    },
    async getPositions() {
      const positions = await this.$api.charityPositions.read(createPaginationObject(null, null, [{ name: 'isDisabled', values: [0] }]));
      this.positions = [];
      if (positions != null && positions.positions != null) {
        // sort
        positions.positions.sort((a, b) => {
          const x = a.name; const y = b.name;
          if (x < y) return -1;
          if (x > y) return 1;
          return 0;
        });
        positions.positions.forEach((position) => {
          const modifiedPosition = { value: position.name, name: position.name };
          if (this.positions.findIndex((x) => x.name === modifiedPosition.name) === -1) { this.positions.push(modifiedPosition); }
        });
      }
    },
    async getCharityCities() {
      const cities = await this.$api.charity.getCities();
      this.charityCities = [];
      cities.forEach((x) => {
        const splitName = x.split(' ');
        for (let i = 0; i < splitName.length; i += 1) {
          if (splitName[i][0]) splitName[i] = splitName[i][0].toUpperCase() + splitName[i].substr(1);
        }
        this.charityCities.push({ name: splitName.join(' '), value: x });
      });
    },
    async getCharityProvince() {
      const province = await this.$api.charity.getProvince();
      this.charityProvince = province.map((x) => {
        const splitName = x.split(' ');
        for (let i = 0; i < splitName.length; i += 1) {
          if (splitName[i][0]) splitName[i] = splitName[i][0].toUpperCase() + splitName[i].substr(1);
        }
        return splitName.join(' ');
      });
    },

    async searchEntries(input, source) {
      await this.loadingData;
      if (source === 'init' || source === 'button' || (source === 'textfield' && input.key === 'Enter')) {
        this.searchSnapShot = this.search;
        await Promise.all([
          this.charitySelected ? this.$refs.searchCharityWidget.searchCharities() : true,
          this.candidateSelected && this.$refs.searchCandidateWidget ? this.$refs.searchCandidateWidget.searchCandidates() : true,
          this.postingSelected ? this.$refs.searchCharityPostingWidget.searchCharityPostings() : true,
        ]);
      }
      this.overlay = false;
    },
    modalFilter() {
      this.$refs.filterDialog.openForm();
    },
    goto(elementId) {
      const el = document.getElementById(`#${elementId}`);
      if (el != null) el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    },
    notifySuccess(message) {
      this.$bus.$emit('showSuccess', message);
      this.$refs.postingTable.refresh();
    },
    clearFilters() {
      this.selectedCity = [];
      this.selectedProvince = [];
      this.selectedPositions = [];
      this.selectedPostingDateRange = [];
      this.boardExperienceSelected = 1;
      this.selectedSkills = [];
      this.selectedFocusAreas = [];
      this.search = '';
      this.searchSnapShot = '';
      this.filterApplied = false;

      this.$emit('triggerSearch');
    },
    filterChipClass(entity, type) {
      if (type === 'skill') {
        const index = this.selectedSkills.findIndex((x) => x === entity.id);
        if (index > -1) return 'primary';
      }
      if (type === 'focusArea') {
        const index = this.selectedFocusAreas.findIndex((x) => x === entity.id);
        if (index > -1) return 'success';
      }
      return '';
    },
    outlined(entity, type) {
      if (type === 'skill') {
        const index = this.selectedSkills.findIndex((x) => x === entity.id);
        return index === -1;
      }
      if (type === 'focusArea') {
        const index = this.selectedFocusAreas.findIndex((x) => x === entity.id);
        return index === -1;
      }
      return true;
    },
    toggleFilters() {
      // this.expandFilters = !this.expandFilters;
      this.modalFilter();
    },
    removeFilter(chip) {
      // see if we can find the filter
      if (chip.source === 'City') {
        const index = this.selectedCity.findIndex((x) => x === chip.id);
        if (index > -1) this.selectedCity.splice(index, 1);
      } else if (chip.source === 'Province') {
        this.selectedProvince = '';
      } else if (chip.source === 'Positions') {
        const index = this.selectedPositions.findIndex((x) => x === chip.id);
        if (index > -1) this.selectedPositions.splice(index, 1);
      } else if (chip.source === 'DateRange') {
        this.selectedPostingDateRange = [];
      } else if (chip.source === 'BoardExperience') {
        this.boardExperienceSelected = 1;
      } else if (chip.source === 'skillSet') {
        const index = this.selectedSkills.findIndex((x) => x === chip.id);
        if (index > -1) this.selectedSkills.splice(index, 1);
      } else if (chip.source === 'focusAreas') {
        const index = this.selectedFocusAreas.findIndex((x) => x === chip.id);
        if (index > -1) this.selectedFocusAreas.splice(index, 1);
      } else if (chip.source === 'searchSnapShot') {
        this.searchSnapShot = '';
        this.search = '';
      }
    },
    showCharity(id) {
      this.$emit('showCharity', id);
    },
    showCandidate(id) {
      this.$emit('showCandidate', id);
    },
    showCharityPosting(id) {
      this.$emit('showCharityPosting', id);
    },
    showCharityPostingByCharity(id) {
      this.$emit('showCharityPostingByCharity', id);
    },
  },
};
</script>

<style>
.filter-section {
  background-color: white;
  border-radius: 5px;
}
.filter-button {
  max-width: 35px !important;
  min-width: 35px !important;
  max-height: 35px !important;
  min-height: 35px !important;
  margin-top: 18px;
  border: thin solid #f0f0f0;
}
.filter-chips-card {
  background-color: transparent;
}
.filter-chips-card.v-card.v-sheet {
  background-color: transparent;

}
.filter-chip span.v-chip__content button.v-chip__close {
  color: white;
}
</style>
