import axios from 'axios';
import Api from '../Api';

class CharityOnboardingApi extends Api {
  constructor() {
    super('charities/onboarding', 'charity onboarding');
  }

  async complete(charityId, onboardingId, completed) {
    let res;
    const errorText = `Could not refresh onboarding for charity id ${charityId}`;
    try {
      const url = `/${this.endpoint}/complete?charityId=${charityId}&onboardingId=${onboardingId}&completed=${completed}`;
      res = await axios.get(url);
      if (res.status === 200) {
        res = res.data;
      } else {
        console.error(errorText);
        res = false;
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = false;
    }
    return res;
  }
}

const charityOnboardingApi = new CharityOnboardingApi();
export default charityOnboardingApi;
