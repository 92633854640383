<template>
  <v-container
    fluid
    class="mb-5"
  >
    <h2
      v-if="showHeader == true"
      class="mb-5"
    >
      Candidate
    </h2>
    <!-- name and address -->
    <v-row>
      <v-col
        sm="1"
        class="pb-0"
      >
        <h3>
          Name:
        </h3>
      </v-col>
      <v-col
        sm="2"
        class="pb-0"
      >
        <span>
          {{
            fullName
          }}
        </span>
      </v-col>
      <v-col
        sm="1"
        class="pb-0"
      >
        <h3>
          Address:
        </h3>
      </v-col>
      <v-col
        sm="5"
        class="pb-0"
      >
        <span>
          {{
            address
          }}
        </span>
      </v-col>
    </v-row>
    <!-- contact info -->
    <v-row>
      <v-col
        sm="1"
        class="pb-0"
      >
        <h3>
          Email:
        </h3>
      </v-col>
      <v-col
        sm="2"
        class="pb-0"
      >
        <span>
          {{
            email

          }}
        </span>
      </v-col>
      <v-col
        sm="1"
        class="pb-0"
      >
        <h3>
          Phone:
        </h3>
      </v-col>
      <v-col
        sm="2"
        class="pb-0"
      >
        <span>
          {{
            phone
          }}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        sm="1"
        class="pb-0"
      >
        <h3>
          Skills:
        </h3>
      </v-col>
      <v-col
        sm="2"
        class="pb-0"
      >
        <span>
          {{ stringifyArray(skills) }}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        sm="1"
        class="pb-0"
      >
        <h3>
          Focus Areas:
        </h3>
      </v-col>
      <v-col
        sm="2"
        class="pb-0"
      >
        <span>
          {{ stringifyArray(focusAreas) }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  props:
  {
    candidate: {
      type: Object,
      default: () => {},
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    showHeader: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    skills: [],
    focusAreas: [],
    candidateData: null,
  }),

  computed: {
    fullName() {
      return this.candidate
        && this.candidate.user
        && this.candidate.user.userInformation
        ? `${this.candidate.user.userInformation.firstName} ${this.candidate.user.userInformation.lastName}`
        : '';
    },
    address() {
      if (this.candidate
        && this.candidate.user
        && this.candidate.user.userInformation
        && this.candidate.user.userInformation.address) {
        const { address } = this.candidate.user.userInformation;
        // let res = address.address1;
        let res = '';
        if (address.address2 != null && address.address2 !== '') res += `, ${address.address2}`;
        if (address.city != null && address.city !== '') res += `${address.city}`;
        if (address.region != null && address.code != null && address.code !== '') res += `, ${address.region.abbreviation} ${address.code}`;
        if (address.country != null) res += `, ${address.country.name}`;
        return res;
      }
      return '';
    },
    email() {
      return this.candidate
        && this.candidate.user
        && this.candidate.user.userInformation
        && this.candidate.user.userInformation.email
        ? this.candidate.user.userInformation.email.name
        : '';
    },
    phone() {
      return this.candidate
            && this.candidate.user
            && this.candidate.user.userInformation
            && this.candidate.user.userInformation.phone
            && this.candidate.user.userInformation.phone.number != null && this.candidate.user.userInformation.phone.number !== ''
        ? this.candidate.user.userInformation.phone.number
              + (this.candidate.user.userInformation.phone.extension != null
              && this.candidate.user.userInformation.phone.extension !== ''
                ? ` Ext: ${this.candidate.user.userInformation.phone.extension}` : '')
        : '';
    },
  },
  watch: {
    candidate: {
      immediate: true,
      handler(value) {
        if (value) {
          this.candidateData = value;
          this.skills = value.skills.map((s) => s.name);
          this.focusAreas = value.focusAreas.map((s) => s.name);
        } else {
          this.candidateData = {
            id: 0,
            skillList: [],
            focusAreasList: [],
            userId: 0,
            joinData: '',
            candidateRole: [{ id: 1, name: 'Base' }],
          };
        }
      },
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
    },
    reset() {
      this.candidateData = {
        id: 0,
        skillList: [],
        focusAreasList: [],
        userId: 0,
      };
    },
    stringifyArray(array) {
      let first = true;
      let result = '';
      if (array == null) return result;
      array.forEach((x) => {
        if (first !== true) result += ', ';
        result += x;
        first = false;
      });
      return result;
    },
  },
};
</script>
