import Api from '../Api';

class CandidateFocusAreasApi extends Api {
  constructor() {
    super('candidates/preference-matching/focus-areas', 'candidatefocusareas');
  }
}

const candidateFocusAreasApi = new CandidateFocusAreasApi();
export default candidateFocusAreasApi;
