<template>
  <v-container fluid>
    <validation-observer ref="repValObserver">
      <v-row>
        <v-col
          cols="12"
        >
          <span style="font-size: x-large; font-weight: 450;">Charity Representative Information</span>
        </v-col>
        <v-col cols="6">
          <validation-provider
            v-slot="{ errors }"
            name="Board Position"
            rules="required"
          >
            <v-autocomplete
              v-model="submitData.charityRepresentativeRole"
              label="Select Role"
              :error-messages="errors"
              :items="roles"
              item-text="name"
              required
              return-object
            >
              <template #selection="{ item }">
                <span class="black--text">
                  {{ item.name }}
                </span>
              </template>
              <template #label>
                Select Role<span class="red--text"><strong>* </strong></span>
              </template>
              <template #item="{ item }">
                <span class="black--text">
                  {{ item.name }}
                </span>
              </template>
            </v-autocomplete>
          </validation-provider>
        </v-col>
        <v-col
          cols="6"
        >
          <validation-provider
            v-slot="{ errors }"
            name="Email"
            :rules="'required|email'"
          >
            <v-text-field
              v-model="submitData.email"
              :error-messages="errors"
              required
              :disabled="charityRep && charityRep.id != null"
            >
              <template #label>
                Email<red-asterisk v-if="charityRep && charityRep.id == null" />
              </template>
            </v-text-field>
          </validation-provider>
        </v-col>
        <v-row class="pa-3">
          <v-col
            cols="6"
          >
            <validation-provider
              v-slot="{ errors }"
              name="First Name"
              rules="required"
            >
              <v-text-field
                v-model="submitData.firstName"
                required
                :error-messages="errors"
                :disabled="charityRep && charityRep.id != null"
              >
                <template #label>
                  First Name<red-asterisk v-if="charityRep && charityRep.id == null" />
                </template>
              </v-text-field>
            </validation-provider>
          </v-col>
          <v-col
            cols="6"
          >
            <validation-provider
              v-slot="{ errors }"
              name="Last Name"
              rules="required"
            >
              <v-text-field
                v-model="submitData.lastName"
                :error-messages="errors"
                required
                :disabled="charityRep && charityRep.id != null"
              >
                <template #label>
                  Last Name<red-asterisk v-if="charityRep && charityRep.id == null" />
                </template>
              </v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
      </v-row>
    </validation-observer>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import {
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props:
  {
    charityRep: {
      type: Object,
      default: () => {},
    },
    charity: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    charityRep: {
      immediate: true,
      handler(value) {
        if (!value) {
          this.submitData = {
            firstName: '',
            lastName: '',
            email: '',
            charityRepresentativeRole: [],
          };
        } else {
          this.submitData = {
            firstName: value.user && value.user.userInformation ? value.user.userInformation.firstName : '',
            lastName: value.user && value.user.userInformation ? value.user.userInformation.lastName : '',
            email: value.user ? value.user.userName : '',
            charityRepresentativeRole: value.charityRepresentativeRole && value.charityRepresentativeRole.length ? value.charityRepresentativeRole[0] : null,
          };
        }
      },
    },
  },
  data: () => ({
    roles: [],
    submitData: {
      firstName: '',
      lastName: '',
      email: '',
      charityRepresentativeRole: [],
    },
  }),
  mounted() {
    this.getRoles();
  },
  created() {
  },
  methods: {
    ...mapState({
      charityRepRoles: (state) => state.charityRep.charityRepRoles,
      currentUser: (state) => state.user,
      userState: (state) => state.user.userState,
      userInvolvements: (state) => state.user.sortedUserInvs,
    }),
    async getRoles() {
      if (!this.charity) {
        return;
      }
      this.roles = await this.$api.charityRepRoles.getById(`${this.charity.id}`);
    },
    async submit(charityId = null, userId = null, roles = []) {
      if (charityId != null && userId != null && roles && roles.length) {
        const submitData = {
          userId,
          charityId,
          charityRepresentativeRole: roles,
        };
        const res = await this.$api.charityRep.create(submitData);
        return res;
      }

      if (!this.charity) return null;
      let rep = null;
      if (this.charityRep && this.charityRep.id) {
        const submitObject = { ...this.charityRep, charityRepresentativeRole: [this.submitData.charityRepresentativeRole] };
        rep = await this.$api.charityRep.update(submitObject);
      } else {
        const submitObject = { ...this.submitData, charityId: this.charity.id, charityRepresentativeRoleId: this.submitData.charityRepresentativeRole.id };
        rep = await this.$api.charityRep.sendInvite(submitObject);
      }
      return rep;
    },
    async validate() {
      const res = await this.$refs.repValObserver.validate();
      return res;
    },

  },
};
</script>
