<template>
  <v-row>
    <v-col>
      <general-upload-widget
        ref="uploadWidget"
        :multiple="false"
        :is-image="true"
        :image-preview-max-height="400"
        entity-type="user"
        :entity-id="userId"
        file-type="profile-image"
        class="mt-5"
        :suppress-actions="true"
        @success="uploadSuccessful()"
      />
    </v-col>
  </v-row>
</template>

<script>
import GeneralUploadWidget from '@/components/general/GeneralUploadWidget';

export default {
  components: {
    GeneralUploadWidget,
  },

  props:
  {
    userId: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    image: null,
  }),
  computed: {
    defaultImages() {
      if (this.thumbnails.length === 0) return [];
      return [];
    },
  },
  watch: {
  },
  created() {
  },
  methods: {
    async submit() {
      this.$refs.uploadWidget.upload();
    },
    uploadSuccessful() {
      this.$emit('success');
    },
  },
};
</script>
<style>
</style>
