import Api from '@/api/Api';

class CharityRepRolesApi extends Api {
  constructor() {
    super('charities/representatives/roles', 'charityrepresentativeroles');
  }
}

const charityRepRolesApi = new CharityRepRolesApi();
export default charityRepRolesApi;
