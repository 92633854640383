<template>
  <div style="background-color: #efefef; height: 100%; width: 100%; padding-top: 10px">
    <v-overlay
      :value="overlay"
      class="align-center justify-center"
    >
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      />
    </v-overlay>
    <!--FAB control-->
    <!-- <v-row
      v-if="editable"
    >
      <v-col
        class="d-flex justify-end"
      >
        <v-tooltip left>
          <template #activator="{ on, attrs }">
            <v-btn
              v-if="editable"
              fab
              large
              class="candidate-fab"
              :color="editing ? 'success' : 'primary'"
              v-bind="attrs"
              v-on="on"
              @click="editFABClicked()"
            >
              <v-icon>
                {{ editing ? 'mdi-check' : 'mdi-pencil' }}
              </v-icon>
            </v-btn>
          </template>
          <span>
            {{ editing ? 'Done Editing' : 'Edit Profile' }}
          </span>
        </v-tooltip>
      </v-col>
    </v-row> -->
    <!--Edit Background button-->
    <!-- <v-row
      class=""
    >
      <v-col
        class="d-flex justify-center pt-0"
        :style="`height: 130px; z-index: ${(editing ? '3' : '-1')};`"
      >
        <v-tooltip
          v-if="editing"
          top
        >
          <template #activator="{ on, attrs }">
            <v-btn
              class="edit-background-button"
              v-bind="attrs"
              style="margin-top: -55px"
              @click="editBackground()"
              v-on="on"
            >
              <v-img
                width="125px"
                height="125px"
                contain
                :src="require('@/assets/edit-image-icon.png')"
              />
            </v-btn>
          </template>
          <span>Set Background Image</span>
        </v-tooltip>
      </v-col>
    </v-row> -->
    <div class="content-div">
      <v-row>
        <!--Social Media Ribbon-->
        <v-col
          class="ma-0 pb-0 pt-0 ml-0"
        >
          <template v-if="links.length > 0">
            <div class="social-media-widget white">
              <general-social-media-widget
                style="z-index: 4;"
                :links="links"
              />
            </div>
          </template>
        </v-col>
      </v-row>
      <v-row class="mt-3">
        <v-col
          cols="6"
        >
          <v-row>
            <!--Title Card-->
            <v-col
              v-if="candidate && candidate.user"
              cols="12"
            >
              <div :class="`${(links.length > 0 ? 'title-widget' : 'title-widget-no-social-media')} white`">
                <candidate-title-widget
                  :candidate="candidate"
                  :editing="editing"
                  :editable="editable"
                />
              </div>
            </v-col>
            <!--About Candidate-->
            <v-col
              v-if="candidate && candidate.about"
              cols="12"
            >
              <div class="white widget">
                <general-text-widget
                  :title="'About Me'"
                  :html-text="candidate.about"
                />
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="6"
        >
          <v-row>
            <!--Reason for Volunteering-->
            <v-col
              v-if="candidate && candidate.candidateMatchingPreferences && candidate.candidateMatchingPreferences.reasonsAndGoals"
              cols="12"
            >
              <div class="white widget">
                <general-text-widget
                  :title="'Why do I want to Volunteer?'"
                  :html-text="candidate.candidateMatchingPreferences.reasonsAndGoals"
                />
              </div>
            </v-col>
            <!--Candidate Education-->
            <v-col
              v-if="candidate && candidate.candidateQualifications && candidate.candidateQualifications.candidateEducation && candidate.candidateQualifications.candidateEducation.length > 0"
              cols="12"
            >
              <div class="white widget">
                <candidate-education-widget
                  :candidate="candidate"
                />
              </div>
            </v-col>
            <!--Candidate Experience-->
            <v-col
              v-if="candidate && candidate.candidateQualifications && candidate.candidateQualifications.candidateExperience && candidate.candidateQualifications.candidateExperience.length > 0"
              cols="12"
            >
              <div class="white widget">
                <candidate-experience-widget
                  :candidate="candidate"
                />
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <!--Image Upload popup-->
    <general-dialog
      v-if="candidate"
      ref="backgroundImageSelectorDialog"
      title="Select or Upload Background Image"
      submit-button-label="Apply Selected Background"
      dialog-width="1750px"
      :submit-button-enabled="false"
      @submit="submitBackgroundImageChange()"
    >
      <candidate-background-image-selector-template
        ref="backgroundImageSelector"
        :candidate="candidate"
        @close="closeDialogs()"
        @backgroundChanged="loadBackgroundImage()"
      />
    </general-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CandidateTitleWidget from '@/components/candidate/profile/CandidateTitleWidget';
import CandidateEducationWidget from '@/components/candidate/profile/CandidateEducationWidget';
import CandidateExperienceWidget from '@/components/candidate/profile/CandidateExperienceWidget';
// import CandidateCharityAffiliationsWidget from '@/components/candidate/profile/CandidateCharityAffiliationsWidget'; // to-do: uncomment this when charity affiliations widget is complete
import GeneralTextWidget from '@/components/general/GeneralTextWidget';
import GeneralDialog from '@/components/general/GeneralDialog';
import CandidateBackgroundImageSelectorTemplate from '@/components/candidate/profile/CandidateBackgroundImageSelectorTemplate';
import { createPaginationObject } from '@/helpers/PaginationHelper.js';
import GeneralSocialMediaWidget from '@/components/general/GeneralSocialMediaWidget.vue';

export default {
  components: {
    CandidateTitleWidget,
    CandidateEducationWidget,
    CandidateExperienceWidget,
    // CandidateCharityAffiliationsWidget, // to-do: uncomment this when charity affiliations widget is complete, and add below "about me" section
    GeneralTextWidget,
    GeneralDialog,
    CandidateBackgroundImageSelectorTemplate,
    GeneralSocialMediaWidget,
  },
  props: {
    candidateId: {
      type: Number,
      default: 0,
    },
  },

  data: () => ({
    candidate: null,
    editing: false,
    externalCandidateId: null, // used in case the user is a candidate and is viewing another candidate's profile
    candidatePromise: null,
    externalPostingId: null,
    overlay: false,
  }),
  computed: {
    ...mapState({
      storeUser: (state) => state.user,
      storeCandidate: (state) => state.candidate.candidate,
      socialMediaTypes: (state) => state.general.socialMediaTypes,
      pronouns: (state) => state.general.pronouns,
      isLoggedIn: (state) => state.auth.isLoggedIn,
    }),
    editable() {
      // for populating edit controls.
      if (this.candidate && this.storeCandidate) {
        return this.candidate.id === this.storeCandidate.id;
      } return false;
    },
    links() {
      let links = [];
      if (this.candidate && this.candidate.user && this.candidate.user.socialMedia && this.candidate.user.socialMedia.length > 0) links = this.candidate.user.socialMedia.filter(((x) => x.url != null && x.url !== ''));
      // if (this.candidate && this.candidate.displayEmail && this.candidate.email) {
      //   links.push({
      //     id: -1, socialMediaTypeId: 1, url: this.candidate.email, socialMediaType: { name: 'Email' },
      //   });
      // }
      return links;
    },
  },
  watch: {
    candidateId: {
      immediate: true,
      handler(value) {
        if (value) {
          this.loadCandidate();
          this.loadBackgroundImage();
        }
      },
    },
  },

  async created() {
    this.overlay = true;
    this.checkExternalCandidateId();
    this.checkExternalPostingId();
    await this.init();
    this.overlay = false;
  },

  methods: {
    async init() {
      await this.loadTypes();
      await this.loadCandidate();
      await this.loadPronouns();
      await this.loadBackgroundImage();
    },
    async loadCandidate() {
      if (!this.externalCandidateId && !this.candidateId) return;
      const filters = [];
      filters.push({ name: 'Id', values: [this.externalCandidateId ? this.externalCandidateId : this.candidateId] });
      if (this.externalPostingId) {
        filters.push({ name: 'PostingId', values: [this.externalPostingId] });
      }

      if (this.candidatePromise == null) {
        this.candidatePromise = this.isLoggedIn
          ? this.$api.candidate.read(
            createPaginationObject(null, null, filters),
          )
          : this.$api.candidate.readPublic(
            createPaginationObject(null, null, filters),
          );
      } else return;
      const response = await this.candidatePromise;
      this.candidatePromise = null;

      [this.candidate] = response.candidates;
    },
    async loadTypes() {
      if (!this.socialMediaTypes.length) this.$store.dispatch('general/getSocialMediaTypes');
    },
    async loadBackgroundImage() {
      if (!this.externalCandidateId && !this.candidateId) return;
      /* const filters = [
        {
          name: 'entityId',
          values: [this.externalCandidateId ? this.externalCandidateId.toString() : this.candidateId.toString()],
        },
        {
          name: 'type',
          values: ['background-image'],
        },
      ];
      const response = await this.$api.image.readImages('candidate', createPaginationObject(null, null, filters));
      const [firstImage] = response.images;
      // the background image is rendered at the app level, so we emit it up the chain
      console.log(firstImage); */
      this.$emit('updateBackgroundImage', 'candidate', (this.externalCandidateId ? this.externalCandidateId.toString() : this.candidateId.toString()));
    },
    async loadPronouns() {
      if (!this.pronouns.length) this.$store.dispatch('general/getPronoun');
    },
    editFABClicked() {
      if (this.editing) {
        this.loadCandidate();
        this.loadBackgroundImage();
      }
      this.editing = !this.editing;
    },
    editBackground() {
      this.$refs.backgroundImageSelectorDialog.openForm();
    },
    submitBackgroundImageChange() {
      this.$refs.backgroundImageSelector.submit();
    },
    closeDialogs() {
      if (this.$refs.backgroundImageSelectorDialog) this.$refs.backgroundImageSelectorDialog.closeForm();
      if (this.$refs.userImageSelectorDialog) this.$refs.userImageSelectorDialog.closeForm();
    },
    checkExternalCandidateId() {
      if (this.$route.query.cid) {
        this.externalCandidateId = this.$route.query.cid;
        const query = { ...this.$route.query };
        delete query.cid;
        // this.$router.replace({ query });
      } else this.externalCandidateId = null;
    },
    checkExternalPostingId() {
      if (this.$route.query.pid) {
        this.externalPostingId = this.$route.query.pid;
        const query = { ...this.$route.query };
        delete query.pid;
        // this.$router.replace({ query });
      } else this.externalPostingId = null;
    },
  },
};
</script>
<style>
.status-tile {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
  border-radius: 8px;
  padding: 10px;
  margin:12px;
  width: fit-content;
  font-family: "Roboto", sans-serif;
}
.title-widget-no-social-media{
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  padding: 12px;
}
.social-media-widget{
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  width: fit-content;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: -12px;
}
.title-widget {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  padding: 12px;
}
.content-div {
  margin-top: 270px !important;
  margin-left: 2.5%;
  margin-right: 2.5%;
  z-index: 2;
  position: relative;
}
.widget {
  border-radius: 8px;
  padding: 12px;
}
.candidate-fab {
  z-index: 3;
}
.edit-background-button {
  border-radius: 16px;
  border: 3px solid lightgray;
  background-color: transparent !important;
  width: 800px;
  height: 175px !important;
}

</style>
