import axios from 'axios';
import Api from '../Api';

class ImageApi extends Api {
  constructor() {
    super('images', 'images');
  }

  async readImages(entityType, options) {
    let res;
    let errorText = 'Could not fetch images';
    try {
      const url = `/${this.endpoint}/read/${entityType}`;
      res = await axios.post(url, options);
      if (res.status === 200) {
        res = res.data;
      } else if (res.status === 204) {
        errorText = 'server is missing images';
        console.error(errorText);
      } else {
        console.error(errorText);
        res = false;
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = false;
    }
    return res;
  }

  async setActive(entityType, entityId, imageId) {
    let res;
    let errorText = 'Could not set background image';
    try {
      const url = `/${this.endpoint}/activate/${entityType}/${entityId}/${imageId}`;
      res = await axios.get(url);
      if (res.status === 200) {
        res = res.data;
      } else if (res.status === 204) {
        errorText = 'Could not set background image';
        console.error(errorText);
      } else {
        console.error(errorText);
        res = false;
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = false;
    }
    return res;
  }

  async upload(entityType, entityId, imageType, file, onUploadProgress) {
    // eslint-disable-next-line prefer-const
    let formData = new FormData();
    formData.append('FileContent', file);

    let errorText = 'Could not upload image';
    let res;
    try {
      res = await axios.post(`${this.endpoint}/upload/${entityType}/${entityId}/${imageType}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress,
      });
      if (res.status === 200) {
        res = res.data;
      } else if (res.status === 204) {
        errorText = 'Could not upload image';
        console.error(errorText);
      } else {
        console.error(errorText);
        res = false;
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = false;
    }
    return res;
  }
}
const imageApi = new ImageApi();
export default imageApi;
