<template>
  <v-container
    fluid
    class="pa-0 ma-0"
  >
    <v-row>
      <v-col class="text-h4">
        Candidate Registration
      </v-col>
      <v-col class="text-right">
        <v-btn
          icon
          @click="redirectLogin"
        >
          <v-icon large>
            mdi-close
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <span class="text-h5 font-weight-bold mb-3">
          Login Information
        </span>
        <a
          v-if="!userId"
          @click="openLogInForm(1)"
        >Already have an account at Boardmatch?</a>
      </v-col>
    </v-row>
    <user-information-form
      v-if="!userId"
      ref="userInformationForm"
    />
    <user-used-title
      v-else
      :username="userName"
    />
    <v-row v-if="!userId">
      <v-col>
        <general-phone-form
          ref="generalPhoneForm"
          phone-type="main"
        />
      </v-col>
      <v-col>
        <general-phone-form
          ref="eveningPhoneForm"
          phone-type="evening"
          :is-required="false"
        />
      </v-col>
    </v-row>
    <general-address-form
      ref="generalAddressForm"
      :address="address"
    />
    <candidate-information-form
      ref="candidateInformationForm"
      :is-signup="true"
    />
    <v-row>
      <v-col class="text-end">
        <v-btn
          class="mr-4"
          text
          @click="redirectLogin"
        >
          cancel
        </v-btn>
        <v-btn
          class="mr-4"
          type="submit"
          color="success"
          @click="submitUser"
        >
          submit
        </v-btn>
      </v-col>
    </v-row>

    <user-login-dialog
      ref="userLoginDialog"
      :title="'Login'"
      @log-in-success="logInSuccess"
    />
    <snackbar ref="snackbar" />
  </v-container>
</template>

<script>
import UserInformationForm from '@/components/user/UserInformationForm.vue';
import GeneralPhoneForm from '@/components/general/GeneralPhoneForm';
import GeneralAddressForm from '@/components/general/GeneralAddressForm';
import CandidateInformationForm from '@/components/candidate/CandidateInformationForm';
import UserLoginDialog from '@/components/user/UserLoginDialog';
import UserUsedTitle from '@/components/user/UserUsedTitle.vue';

export default {
  components: {
    UserInformationForm,
    GeneralPhoneForm,
    GeneralAddressForm,
    CandidateInformationForm,
    UserLoginDialog,
    UserUsedTitle,
  },
  data: () => ({
    userId: null,
    address: null,
    userName: '',
  }),
  methods: {
    async submitUser() {
      if (this.userId) {
        this.submitLoggedInUser();
        return;
      }
      const validateComponentsResult = await Promise.all([
        this.$refs.userInformationForm.validate(),
        this.$refs.generalAddressForm.validate(),
        this.$refs.generalPhoneForm.validate(),
      ]).then((values) => !values.includes(false));

      if (!validateComponentsResult) return;

      this.$bus.$emit('loading', true);

      const userResponse = await this.$refs.userInformationForm.submit(1);

      if (!userResponse) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading', false);
        return;
      }

      const candidateResponse = await this.$refs.candidateInformationForm.submit(
        userResponse.id,
      );
      const addressResponse = await this.$refs.generalAddressForm.submit(
        null,
        userResponse.userInformation.id,
      );
      const phoneResponse = await this.$refs.generalPhoneForm.submit(
        null,
        userResponse.userInformation.id,
      );

      if (!candidateResponse && !addressResponse && !phoneResponse) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading', false);
        return;
      }
      this.$bus.$emit('showError');
      // this.$bus.$emit('showSuccess', "Registered Successfully! Welcome to Boardmatch!")
      this.$bus.$emit('loading', false);
      // Work around to show Snackbar on login page
      this.$store.commit(
        'auth/storeRegSuccess',
        'Registered Successfully! Welcome to Boardmatch!',
      );
      this.redirectLogin();
    },

    redirectLogin() {
      this.$router.push('/login');
    },

    // For Logged In user
    openLogInForm() {
      this.$refs.userLoginDialog.openForm();
    },
    async submitLoggedInUser() {
      if (!this.userId) return;
      const validateComponentsResult = await Promise.all([
        this.$refs.generalAddressForm.validate(),
      ]).then((values) => !values.includes(false));

      if (!validateComponentsResult) return;
      this.$bus.$emit('loading', true);

      const candidateResponse = await this.$refs.candidateInformationForm.submit(
        this.userId,
      );
      // var addressResponse =  await this.$refs.addressComponent.submit(null, userResponse.userInformation.id)
      if (!candidateResponse) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading', false);
        return;
      }

      this.$bus.$emit('showSuccess', 'New Candidate is Registered Successfully! Welcome to Boardmatch!');
      this.$bus.$emit('loading', false);
      // Work around to show Snackbar on login page
      // this.$store.commit(
      //   "auth/storeRegSuccess",
      //   "New Candidate is Registered Successfully! Welcome to Boardmatch!"
      // );
      this.redirectLogin();
    },
    logInSuccess(userData) {
      if (!userData) return;
      if (userData.candidate) {
        this.$refs.snackbar.showError('Candidate for this user is already created.');
        // this.$bus.$emit('showError', "Candidate for this user is already created.")
        return;
      }
      this.$refs.snackbar.showSuccess();
      this.userId = userData.id;
      this.userName = `${userData.userInformation.firstName} ${userData.userInformation.lastName}`;
      this.address = userData.userInformation.address;
    },
  },
};
</script>
