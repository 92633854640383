<template>
  <v-container
    fluid
    class="pa-0"
  >
    <charity-profile-template
      v-if="charity.id"
      ref="charityProfileTemplate"
      :charity-id="charity.id"
      class="mb-10"
      @updateBackgroundImage="(entity, id) => updateBackgroundImage(entity, id)"
    />
  </v-container>
</template>

<script>

import { mapState } from 'vuex';
import CharityProfileTemplate from '@/components/charity/profile/CharityProfileTemplate';

export default {
  components: {
    CharityProfileTemplate,
  },

  data: () => ({
    user: null,
  }),

  computed: {
    ...mapState({
      userId: (state) => state.user.nameId,
      charity: (state) => state.charity.charity,
    }),
  },

  created() {
    this.init();
    this.clearReroute();
  },

  methods: {
    init() {
    },
    clearReroute() {
      if (this.$route.query.forceReroute) {
        const query = { ...this.$route.query };
        delete query.forceReroute;
        this.$router.replace({ query });
      }
    },
    updateBackgroundImage(entity, id) {
      this.$emit('updateBackgroundImage', entity, id);
    },
  },
};
</script>
