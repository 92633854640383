import Api from '../Api';

class BoardmatchRepRolesApi extends Api {
  constructor() {
    super('boardmatch-rep/roles', 'boardmatchreproles');
  }
}

const boardmatchRepRolesApi = new BoardmatchRepRolesApi();
export default boardmatchRepRolesApi;
